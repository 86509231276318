import {
    IonContent,
    IonFooter,
    IonButton,
    IonItem,
    IonList,
    IonPage,
    IonToolbar,
    IonLabel,
    IonInput,
    IonSelect,
    IonSelectOption
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import {
    setAppLanguage
} from '../../redux/actions/AppAction';

import { languages } from '../../config/App';

//Import helpers
import UsersHelper from '../../helpers/UsersHelper';
import LocalStorageHelper from '../../helpers/LocalStorageHelper';

//Import components
import StandartHeader from './components/header/StandartHeader';
import Picker from './components/picker/Picker';


class AccountDetails extends React.Component {

    constructor(props){
        super(props);

        this.UsersHelper = new UsersHelper();
        this.state = {
            email: '',
            name: '',
            lastName: '',
            phone: '',
            lang: '',
            errors: {}
        }
    }

    componentDidMount = async () => {

        this.setState({
            email: await LocalStorageHelper.get('user.email'),
            name: await LocalStorageHelper.get('user.firstName'),
            lastName: await LocalStorageHelper.get('user.lastName'),
            //phone: LocalStorageHelper.get('user.phone'),    
            lang: await LocalStorageHelper.get('user.lang'),    
        })
    }

    confirm = async () => {
        try {

            await this.UsersHelper.accountSettings({
                email: this.state.email,
                name: this.state.name,
                lastName: this.state.lastName,
                //phone: this.state.phone,
                newLang: this.state.lang,
            });

            LocalStorageHelper.set('user.email', this.state.email);
            LocalStorageHelper.set('user.firstName', this.state.name);
            LocalStorageHelper.set('user.lastName', this.state.lastName);
            //LocalStorageHelper.set('user.phone', this.state.phone);
            LocalStorageHelper.set('user.lang', this.state.lang);

            if (this.state.lang == 'he') {
                document.getElementById('root').firstChild.style.direction = "rtl";
            } else {
                //document.getElementById('root').style.direction = "";
                document.getElementById('root').firstChild.style.direction = "";
            }

            //Set App Lang
            this.props.i18n.changeLanguage(this.state.lang);
            this.props.setAppLanguage(this.state.lang);

            this.props.history.goBack();

        } catch (e) {

            this.setState({
                errors: e.errors
            })
        }
    }

    render = () => {

        const { t, i18n } = this.props;

        return (
        <IonPage>
            
            <StandartHeader name={t('account_details')}/>
            

            <IonContent className="ion-padding">
            <IonList>
                <IonItem lines="full" className={`form-list__item ${'email' in this.state.errors ? 'is-invalid' : ''}`}>
                    <IonLabel position="stacked" color="primary" className="form-label">{t('email')}</IonLabel>
                    <IonInput value={this.state.email} type="email" className="form-input" onIonInput={ e => this.setState({email: e.detail.value})}/>
                    {'email' in this.state.errors ? (<div className="invalid-message">{this.state.errors.email}</div>) : null}
                </IonItem>
                <IonItem lines="full" className={`form-list__item ${'name' in this.state.errors ? 'is-invalid' : ''}`}>
                    <IonLabel position="stacked" color="primary" className="form-label">{t('your_name')}</IonLabel>
                    <IonInput value={this.state.name} type="text" className="form-input" onIonInput={ e => this.setState({name: e.detail.value})}/>
                    {'name' in this.state.errors ? (<div className="invalid-message">{this.state.errors.name}</div>) : null}
                </IonItem>
                <IonItem lines="full" className={`form-list__item ${'lastName' in this.state.errors ? 'is-invalid' : ''}`}>
                    <IonLabel position="stacked" color="primary" className="form-label">{t('your_last_name')}</IonLabel>
                    <IonInput  value={this.state.lastName} type="text" className="form-input" onIonInput={ e => this.setState({lastName: e.detail.value})}/>
                    {'lastName' in this.state.errors ? (<div className="invalid-message">{this.state.errors.lastName}</div>) : null}
                </IonItem>
                {/* <IonItem lines="full" className={`form-list__item ${'phone' in this.state.errors ? 'is-invalid' : ''}`}>
                    <IonLabel position="stacked" color="primary" className="form-label">{t('your_phone')}</IonLabel>
                    <PhoneInput
                        value={this.state.phone}
                        onChange={value => {this.setState({phone: value})}}
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="BG"
                    />
                    {'phone' in this.state.errors ? (<div className="invalid-message">{this.state.errors.phone}</div>) : null}
                </IonItem> */}
                {/* //TO DO: Ask for language */}
                <IonItem lines="full" className={`form-list__item`}>
                    <IonLabel position="stacked" color="primary" className="form-label">{t('lang')}</IonLabel>
                    <IonSelect value={this.state.lang} onIonChange={e => {this.setState({lang: e.detail.value})}} cancel-text={t('cancel')}>
                        {languages.map((lang, idx) => {
                            return <IonSelectOption value={lang} key={`${lang}-${idx}`}>{t(lang)}</IonSelectOption>
                        })}
                    </IonSelect> 
                </IonItem>
            </IonList>

            <Picker
                isOpen={this.state.pickerIsOpen}
                onCancel={() => {
                    this.setState({
                        pickerIsOpen: false
                    });
                }}
                onSave={(_value) => {

                    const {language: {value: lang}} = _value;

                    i18n.changeLanguage(lang);
                    this.props.setAppLanguage(lang);

                    this.setState({
                        pickerIsOpen: false
                    });
                }}
            />

            </IonContent>
            
            <IonFooter className="app-footer ion-no-border">
                <IonToolbar>
                <div className="buttons-list">
                    <div className="buttons-list__item">
                        <IonButton expand="block" shape="round" size="large" onClick={this.confirm}>{t('confirm')}</IonButton>
                    </div>
                </div>
                </IonToolbar>
            </IonFooter>
        </IonPage>
        );
    }

};

const mapStateToProps = state => {
    return {
        app: state.app
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        setAppLanguage
    })
)(AccountDetails);
  