import store from '../redux/createStore';

class ProgramHelper
{

    constructor(mac) {

        this.mac = mac;

        const { devices } = store.getState();
        const {state: {program}} = devices[mac];
        
        this.program = program;
    }

    validateProgram = (from, to, day) => {
        
        if (!this.validateToAndFrom(from, to)) {
            return false;
        }

        if (!this.validateTimeBetweenRange(from, day)) {
            return false;
        }
       
        if (!this.validateTimeBetweenRange(to, day)) {
            return false;
        }

        if (!this.validateTimeRangeBetweenRange(from, to, day)) {
            return false;
        }

        return true;
    }

    validateToAndFrom = (from, to) => {

        const fromToMin = this.convertTimeToMinutes(from);
        const toToMin = this.convertTimeToMinutes(to);

        if (toToMin <= fromToMin) {
            return false;
        }

        return true;
    } 

    validateTimeRangeBetweenRange = (from, to, day) => {

        let status = true;

        for (const key in this.program) {
            
            const {from: program_from, to: program_to, day: program_day } = this.program[key];

            if (program_day === day) {

                const fromToMin = this.convertTimeToMinutes(from);
                const toToMin = this.convertTimeToMinutes(to);
                const programFromToMin = this.convertTimeToMinutes(program_from);
                const programToToMin = this.convertTimeToMinutes(program_to);

                if (fromToMin <= programFromToMin && programToToMin <= toToMin) {
                    status = false;
                    break;
                }
            }
        }

        return status;
    }

    validateTimeBetweenRange = (time, day) => {
       
        let status = true;

        for (const key in this.program) {
            
            const {from: program_from, to: program_to, day: program_day } = this.program[key];

            if (program_day === day) {

                const timeToMin = this.convertTimeToMinutes(time);
                const programFromToMin = this.convertTimeToMinutes(program_from);
                const programToToMin = this.convertTimeToMinutes(program_to);

                if (timeToMin > programFromToMin && timeToMin < programToToMin) {
                    status = false;
                    break;
                }
            }
        }

        return status;
    }

    convertTimeToMinutes = time => {

        let [hour, min] = time.split(":");
        hour = Number(hour);
        min = Number(min);

        return hour*60 + min;
    }
}

export default ProgramHelper;