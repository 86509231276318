import {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonIcon
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import arrowNext from '../../assets/images/arrow-list.svg';
import StandartHeader from './components/header/StandartHeader';
  
const DeviceSettings = props => {

    const { match: {params: {mac_address}}, devices} = props;
    const device = devices[mac_address];

    const settings_menu = [
        {
            title: props.t('eco'),
            icon: 'eco-second',
            url: `/eco-temp/${mac_address}`
        },
        {
            title: props.t('comfort'),
            icon: 'comfort-second',
            url: `/comfort-temp/${mac_address}`
        },
        {
            title: props.t('sleep_mode'),
            icon: 'moon-outline',
            url: `/sleep/${mac_address}`
        },
        // {
        //   title: props.t('delayed_start'),
        //   icon: 'clock-second',
        //   url: `/delayed-start/${mac_address}`
        // },
        {
            title: props.t('change_network'),
            icon: 'wifi',
            url: `/change-device-wifi/${mac_address}`
        },
        {
            title: props.t('t_controller'),
            icon: 'correction',
            url: `/t-controller/${mac_address}`
        },
        {
            title: props.t('world_clock'),
            icon: 'world-clock',
            url: `/world-clock/${mac_address}`
        }
    ];

    return (
        <IonPage>

        <StandartHeader name={props.t('settings')}/>

        <IonContent className="ion-padding">        
            <IonList className="manuals-list">
            {settings_menu.map(({ title, icon, url }, i) => (
                <IonItem 
                    key={i} 
                    button 
                    className="manuals-list__item" 
                    routerLink={url}
                    disabled={(device.hasInternet === false && device.localUsage === false) ? true : false}
                >
                    <div slot="start">
                        <svg className="manuals-list__icon"><use xlinkHref={`#icon-${icon}`}></use></svg>
                    </div>
                    {title}
                    <IonIcon icon={arrowNext} slot="end"></IonIcon>
                </IonItem>
            ))}
            </IonList>
            {(device.hasInternet === false && device.localUsage === false) &&
                <div className="popover__text">
                    <p>{props.t('device_settings_text')}</p>
                </div>
            }
            <div className="ion-content-push"></div>
        </IonContent>
        </IonPage>
    );
};

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(DeviceSettings);