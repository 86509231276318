import React from 'react';
import { withTranslation } from 'react-i18next';

import './offline.scss';
import offlineIcon from '../../../../assets/images/offline.svg';

const Offline = (props) => {

  const { t } = props;
  return (
    <React.Fragment>
      <div className="offline">
        <figure>
          <img src={offlineIcon} alt="Offline" />
          <figcaption>
            <p>{t('device_state')}</p>
            <p className="text-red">
                {props.waitingForConnection === true ? t('waiting_for_connection') : props.localControl === true ? t('local_usage_dashboard') : t('offline')}
            </p>
          </figcaption>
        </figure>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Offline);