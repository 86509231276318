import {
    IonContent,
    IonFooter,
    IonButton,
    IonItem,
    IonList,
    IonPage,
    IonToolbar,
    IonLabel,
    IonInput,
  } from '@ionic/react';
  
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

  //Import components
import StandartHeader from './components/header/StandartHeader';
import ToastMessage from './components/toast/ToastMessage';
import Loading from './components/loading/Loading';

//Import helpers
import UsersHelper from '../../helpers/UsersHelper';

//Import Redux Actions
import { setAppMessage } from '../../redux/actions/AppAction';
  
class ForgottenPassword extends React.Component {
  
    constructor (props) {

        super(props);
    
        this.UsersHelper = new UsersHelper();
        this.state = {
            email: '',
            errors: {},
            showSuccessText: false,
            showLoading: false
        }
    }

    

    recoverPassword = async () => {

        this.setState({showLoading: true});

        try {

            const response = await this.UsersHelper.recoverPassword(this.state.email);

            this.setState({showLoading: false});
            this.props.setAppMessage(this.props.t('success_recover_pass'));

            this.setState({
                errors: {}
            })

        } catch (e) {

            this.setState({showLoading: false});

            this.setState({
                errors: e.errors
            })
        }
    }

    render = () => {

        const { t } = this.props;

        return (
            <IonPage>
                <StandartHeader name={t('forgotten_password')} hideHome={true}/>
        
                <IonContent className="ion-padding">
                    <IonList>
                        <IonItem lines="full" className={`form-list__item ${'email' in this.state.errors ? 'is-invalid' : ''}`}>
                            <IonLabel position="stacked" color="primary" className="form-label" >{t('email')}</IonLabel>
                            <IonInput type="email" className="form-input" onIonInput={ e => this.setState({email: e.detail.value})} />
                            {'email' in this.state.errors ? (<div className="invalid-message">{this.state.errors.email}</div>) : null}
                        </IonItem>
                    </IonList>

                    <ToastMessage color={"success"}/>
                    <Loading show={this.state.showLoading}/>
                </IonContent>
                
                <IonFooter className="app-footer ion-no-border">
                    <IonToolbar>
                    <div className="buttons-list">
                        <div className="buttons-list__item">
                            <IonButton expand="block" shape="round" size="large" onClick={this.recoverPassword}>{t('recover_password')}</IonButton>
                        </div>
                        <div className="buttons-list__item">
                            <IonButton expand="block" fill="clear" color="link" size="small" routerLink="/login">{t('signin')}</IonButton>
                        </div>
                    </div>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        );
    }
  };
  
  export default compose(
      withTranslation(),
      connect(null, {setAppMessage})
)(ForgottenPassword);
  