import {
    IonButton,
    IonLabel
} from '@ionic/react';
import React from 'react';
import { withTranslation } from 'react-i18next';

const DeviceTabButton = props => {

    return (
        <IonButton 
            className={`device-button ${props.status === 'on' ? 'device-button--active' : ''}`} 
            onClick={() => props.handleStatus(props.status === 'on' ? 'off' : 'on')}
            disabled={props.disabled}
        >
            <div className="device-button__inner">
                <div className="device-button__icon">
                    <svg className="device-button__icon-icon"><use xlinkHref={`#icon-${props.icon}`}></use></svg>
                    <div className="device-button__icon-label">{props.t('on')}</div>
                </div>
                <IonLabel className="device-button__text">{props.name}</IonLabel>
            </div>
        </IonButton>
    )
}

export default withTranslation()(DeviceTabButton);