import {
    IonContent,
    IonPage,
    IonSpinner,
} from '@ionic/react';
import './waitingScreen.scss';
import { withTranslation } from 'react-i18next';

const WaitingScreen = (props) => {
    return (
        <IonPage>
            <IonContent classNamе="ion-padding">
                <div className="wrapper-container">
                    <div className="container">
                        <div className="back"> </div>

                        <div className="loading-wrapper ion-overlay-wrapper sc-ion-loading-ios">
                            <div className="loading-spinner sc-ion-loading-ios">
                                <IonSpinner />
                            </div>
                            <div className="loading-content sc-ion-loading-ios" id="loading-16-msg">{props.t('please_wait')}</div>
                        </div>
                    </div>
                </div>

            </IonContent>
        </IonPage>
    )
}

export default withTranslation()(WaitingScreen);