import {
    IonPage,
    IonContent,
    IonToolbar,
    IonButton,
    IonList,
    IonItem,
    IonFooter,
    IonSelect,
    IonSelectOption
} from '@ionic/react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

import HeatPumpHelper from "../../../helpers/HeatPumpHelper";
import HeatPumpMqttHelper from "../../../helpers/mqqt/HeatPumpMqttHelper";
import ProgramHelper from './helpers/ProgramHelper';
import ToastMessage from '../components/toast/ToastMessage';
import RangeStartTemperature from '../components/range/RangeStartTemperature';
import StandartHeader from '../components/header/StandartHeader';
import TwoButtons from '../components/footer/TwoButtons';


import { setAppMessage } from '../../../redux/actions/AppAction';
import {
    updateDeviceState,
} from '../../../redux/actions/DevicesAction';

const CreateProgram = props => {

    const { 
        devices, 
        match: {
            params: {
                mac_address: mac, 
                day,
                model,
                zone
            }
        } 
    } = props;
    const device = devices[mac];
    const {state: deviceState} = device;
    const HeatPumpHelperOBJ = new HeatPumpHelper(device);
    const HeatPumpMqttHelperOBJ = new HeatPumpMqttHelper(mac);
    const {minTemp, maxTemp} = HeatPumpHelperOBJ.getCurrentModeSettings("hp");

    const { t } = useTranslation();
    const [from, setFrom] = useState("00:00");
    const [to, setTo] = useState("23:59");
    const [temp, setTemp] = useState(minTemp);
    const [mode, setMode] = useState("hp");
    const [modeMinTemp, setModeMinTemp] = useState(minTemp);
    const [modeMaxTemp, setModeMaxTemp] = useState(maxTemp);
    const [timeOptions, setTimeOptions] = useState([
        '00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00',
        '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '23:59'
    ]);

    //IT"S FOR EDIT PROGRAM
    // useEffect(() =>{
    //     if (zone != undefined) {
            
    //         const programHelperOBJ = new ProgramHelper(mac);
    //         const programforTheChoosedDay = programHelperOBJ.getProgramForTheDay(day);
    //         const zoneData = programforTheChoosedDay[zone];

    //         setFrom(zoneData.from);
    //         setTo(zoneData.to);
    //         changeMode(zoneData.mode);
    //         setTemp(zoneData.temp);
    //     }
    // }, []);

    const saveProgram = () => {

        let programHelperOBJ = new ProgramHelper(mac);

        let {program: currentProgram} = deviceState;

        //Check if we have existing program
        if (!currentProgram) {
            currentProgram = programHelperOBJ.createEmptyProgram();
            props.updateDeviceState(currentProgram, "program", mac);
            programHelperOBJ = new ProgramHelper(mac);
        }

        if (programHelperOBJ.getZones(day) < 4) {

            if (programHelperOBJ.validateProgram(from, to, Number(day))) {

               
                const getProgramDays = programHelperOBJ.getProgramDays();
                const programForTheDay = currentProgram[getProgramDays[day]];
                for (const zone in programForTheDay) {
                    const {from: zoneFrom, to: zoneTo} = programForTheDay[zone];

                    if (zoneFrom == "00:00" && zoneTo == "00:00") {
                        currentProgram[getProgramDays[day]][zone] = {to, from, temp, mode}
                        break;    
                    }
                }

                currentProgram[getProgramDays[day]] = programHelperOBJ.sortProgramForTheDay(currentProgram[getProgramDays[day]]);

                HeatPumpMqttHelperOBJ.request('setWeeklyProgram', {weeklyDay: currentProgram});
    
                props.history.push(`/device/program/${device.model.toLowerCase()}/${mac}/${day}`);
            } else {
                props.setAppMessage(t('invalid_program'));
            }
        } else {
            props.setAppMessage(t('invalid_program_allowed_4_zones'));
        }
        
    }

    const changeMode = mode => {
        setMode(mode);

        const {minTemp: newModeMinTemp, maxTemp: newModeMaxTemp} = HeatPumpHelperOBJ.getCurrentModeSettings(mode);
        setModeMinTemp(newModeMinTemp);
        setModeMaxTemp(newModeMaxTemp);
    }

    return (
        <IonPage>

            <StandartHeader name={t('program')}/>
            <IonContent className="ion-padding">            

                <div className="preferences-list__wrapper">
                    <IonList className="preferences-list preferences-list--editable">

                        <IonItem className="preferences-list__item" lines="none">
                            <IonSelect 
                                placeholder={t('time_from')}
                                onIonChange={e => setFrom(e.detail.value)}
                                cancel-text={t('cancel')}
                                value={from}
                            >
                            {timeOptions.map((value, i) => (
                                <IonSelectOption value={String(value)} key={i}>{value}</IonSelectOption>
                            ))}
                            </IonSelect>
                        </IonItem>
                        
                        <IonItem className="preferences-list__item" lines="none">
                            <IonSelect 
                                placeholder={t('time_to')}
                                onIonChange={e => setTo(e.detail.value)}
                                cancel-text={t('cancel')}
                                value={to}
                            >
                            {timeOptions.map((value, i) => (
                                <IonSelectOption value={String(value)} key={i}>{value}</IonSelectOption>
                            ))}
                            </IonSelect>

                        </IonItem>

                        <IonItem className="preferences-list__item" lines="none">
                            <IonSelect 
                                placeholder={t('hp_choose_mode')}
                                onIonChange={e => changeMode(e.detail.value)}
                                cancel-text={t('cancel')}
                                value={mode}
                            >
                                <IonSelectOption value="hp">{t('hp_mode_hp')}</IonSelectOption>
                                <IonSelectOption value="eh">{t('hp_mode_eh')}</IonSelectOption>
                                <IonSelectOption value="au">{t('hp_mode_au')}</IonSelectOption>
                                {/* <IonSelectOption value="sm">{t('hp_mode_sm')}</IonSelectOption> */}
                            </IonSelect>
                        </IonItem>

                        <IonItem className="preferences-list__item" lines="none">
                        <div className="preferences-list__inner">
                            <div className="preferences-list__item-middle">
                                <RangeStartTemperature 
                                    data-min={modeMinTemp} 
                                    data-max={modeMaxTemp} 
                                    data-start={temp} 
                                    data-step={1} 
                                    data-label={t('degree_unit')} 
                                    setTemp={temp => setTemp(temp)}
                                />
                            </div>
                        </div>
                        </IonItem>
                    </IonList>
                </div>
                <div className="ion-content-push"></div>

                <ToastMessage />
            </IonContent>
        
            <TwoButtons 
                redirect={`/device/program/${model}/${mac}/${day}`}
                save={() => saveProgram()}
            />
        </IonPage>
    );
}

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    connect(mapStateToProps, {
        setAppMessage,
        updateDeviceState
    })
)(CreateProgram);