import {
    IonToolbar,
    IonButton,
    IonFooter,
} from '@ionic/react';
import React from 'react';
import { withRouter } from "react-router";
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'

const TwoButtons = props => {
    return (
        <IonFooter className="app-footer ion-no-border">
            <IonToolbar>
                <div className="buttons-list">
                    <div className="buttons-list__item">
                        <IonButton expand="block" shape="round" size="large" onClick={props.save}>{props.nameOfSaveButton !== undefined ? props.nameOfSaveButton : props.t('save')}</IonButton>
                    </div>
                    <div className="buttons-list__item">
                        <IonButton expand="block" shape="round" size="large" color="help" onClick={() => props.history.goBack()}>{props.t('cancel')}</IonButton>
                    </div>
                </div>
            </IonToolbar>
        </IonFooter>
    )
}

export default compose(
    withTranslation(),
)(withRouter(TwoButtons));