import React from 'react';

import {
  IonPage,
  IonContent,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonCheckbox
} from '@ionic/react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import HeaderWelcome from './components/header/HeaderWelcome';
import Footer from './components/footer/Footer';
import ToastMessage from './components/toast/ToastMessage';
import Loader from './components/loading/Loading';
import InitialSetttings from '../../init';

//import '../../assets/css/webApp/components/welcome/welcome.scss';
import welcomeImage from '../../temp-files/webApp/welcome-image.jpg';

//Import helpers
import UsersHelper from '../../helpers/UsersHelper';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

//Import Redux Actions
import { setAppMessage } from '../../redux/actions/AppAction';

class Login extends React.Component {

    constructor(props) {
        super(props);

        this.UsersHelper = null;

        this.state = {
            showLoading: false,
            loginPasswordShown: false,
            signupPasswordShown: false,
            signupConfirmPasswordShown: false,
            login: {
                email: '',
                password: ''
            },
            loginEmail: '',
            loginPassword: '',
            signup: {
                email: '',
                password: '',
                confirmPassword: '',
                name: '',
                lastName: '',
                phone: '',
                accept_terms_conditios: true,
                receive_emails: false,
            },
            errors: []
        }
    }

    componentDidMount = () => {
        this.UsersHelper = new UsersHelper();
    }

    toggleLoginPasswordVisiblity = () => {
        this.setState({loginPasswordShown: this.state.loginPasswordShown ? false : true});
    };

    toggleSignupPasswordVisiblity = () => {
        this.setState({signupPasswordShown: this.state.signupPasswordShown ? false : true});
    };

    toggleSignupConfirmPasswordVisiblity = () => {
        this.setState({signupConfirmPasswordShown: this.state.signupConfirmPasswordShown ? false : true});
    };

    login = async () => {

        this.setState({showLoading: true});

        try {
    

            await this.UsersHelper.login({
                email: this.state.loginEmail,
                password: this.state.loginPassword,
            });

            const initialSetttingsInstance = new InitialSetttings();
            initialSetttingsInstance.init();
        
            this.setState({showLoading: false});
            //this.props.history.push('/dashboard');
            window.location.href = '/';
    
        } catch (e) {
           
            this.setState({showLoading: false});

            this.setState({
                errors: e.errors
            })

            if ('global' in e.errors) {
                this.props.setAppMessage(e.errors.global);
            }
        }
    }

    signup = async () => {
        
        if (this.state.signup.accept_terms_conditios === true) {

            this.setState({showLoading: true});
            
            try {
        
                await this.UsersHelper.signup({
                    email: this.state.signup.email,
                    name: this.state.signup.name,
                    lastName: this.state.signup.lastName,
                    //phone: this.state.signup.phone,
                    password: this.state.signup.password,
                    confirmPassword: this.state.signup.confirmPassword,
                    lang: this.props.app.lang,
                    subscribe: this.state.signup.receive_emails
                });

                this.setState({showLoading: false});
            
                //this.props.history.push('/login/0');
                window.location.href = '/';
        
            } catch (e) {
        
                this.setState({showLoading: false});

                this.setState({
                    errors: e.errors
                })
        
            }

        } else {
            this.props.setAppMessage(this.props.t('accept_terms_and_conditions'));
        }
    }
    render = () => {

        const { match: {params: {index} }, t } = this.props;
        
        return (
            <IonPage>
                <HeaderWelcome />
                <IonContent className="ion-no-padding">
                    <div className="welcome">
                        <div className="welcome__start">
                            <img src={welcomeImage} className="welcome__start-image" width="" height="" alt="" />
                        </div>
                        <div className="welcome__end">
                            <div className="welcome__end-inner">
                                <Tabs forceRenderTabPanel defaultIndex={Number(index)}>
                                    <TabList>
                                        <Tab>{t('signin_text')}</Tab>
                                        <Tab >{t('signup')}</Tab>
                                    </TabList>
                    
                                    <TabPanel>
                                        <div className="wrapper wrapper--xs">
                                            <IonList>
                                                <IonItem lines="none"  className={`form-list__item ${'email' in this.state.errors ? 'is-invalid' : ''}`}>
                                                    <IonLabel position="stacked" color="primary" className="form-label">{t('email')}</IonLabel>
                                                    <IonInput type="email" className="form-input" onIonInput={ e => this.setState({loginEmail: e.detail.value})} />
                                                    {'email' in this.state.errors ? (<div className="invalid-message">{this.state.errors.email}</div>) : null}
                                                </IonItem>
                                                <IonItem lines="none" className={`form-list__item ${'password' in this.state.errors ? 'is-invalid' : ''}`}>
                                                    <IonLabel position="stacked" color="primary" className="form-label">{t('password')}</IonLabel>
                                                    <div className="form-password">
                                                        <IonInput type={this.state.loginPasswordShown ? "text" : "password"} className="form-input" onIonInput={ e => this.setState({loginPassword: e.detail.value})}/>
                                                        <button type="button" ion-button="true" className="form-password__button" onClick={this.toggleLoginPasswordVisiblity}>
                                                            <svg className="form-password__button-icon"><use xlinkHref={`#icon-eye-${this.state.loginPasswordShown ? 'closed' : 'open'}`}></use></svg>
                                                        </button>
                                                    </div>
                                                    {'password' in this.state.errors ? (<div className="invalid-message">{this.state.errors.password}</div>) : null}
                                                </IonItem>
                                                <IonItem lines="none" className="form-list__item">
                                                    <IonButton color="link" slot="end" routerLink="/forgotten-password">{t('forgot_password')}</IonButton>
                                                </IonItem>
                                                <IonItem lines="none" className="form-list__item">
                                                    <div className="ion-text-center ion-padding-top" style={{ width: '100%' }}>
                                                        <IonButton color="primary" onClick={this.login}>
                                                            <span className="button__text">{t('login')}</span>
                                                        </IonButton>
                                                    </div>
                                                </IonItem>
                                            </IonList>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="wrapper wrapper--xs">
                                        <IonList>
                                            <IonItem lines="none" className={`form-list__item ${'email' in this.state.errors ? 'is-invalid' : ''}`}>
                                                <IonLabel position="stacked" color="primary" className="form-label">{t('email')}</IonLabel>
                                                <IonInput type="email" className="form-input" onIonInput={ e => this.setState({signup: {...this.state.signup, email:e.detail.value}})}/>
                                                {'email' in this.state.errors ? (<div className="invalid-message">{this.state.errors.email}</div>) : null}
                                            </IonItem>
                                            <IonItem lines="none" className={`form-list__item ${'password' in this.state.errors ? 'is-invalid' : ''}`}>
                                                <IonLabel position="stacked" color="primary" className="form-label">{t('password')}</IonLabel>
                                                <div className="form-password">
                                                    <IonInput type={this.state.signupPasswordShown ? "text" : "password"} className="form-input" onIonInput={ e => this.setState({signup: {...this.state.signup, password:e.detail.value}})}/>
                                                    <button type="button" ion-button="true" className="form-password__button" onClick={this.toggleSignupPasswordVisiblity}>
                                                        <svg className="form-password__button-icon">
                                                            <use xlinkHref={`#icon-eye-${this.state.signupPasswordShown ? 'closed' : 'open'}`}></use>
                                                        </svg>
                                                    </button>
                                                </div>
                                                {'password' in this.state.errors ? (<div className="invalid-message">{this.state.errors.password}</div>) : null}
                                            </IonItem>
                                            <IonItem lines="none" className={`form-list__item ${'confirmPassword' in this.state.errors ? 'is-invalid' : ''}`}>
                                                <IonLabel position="stacked" color="primary" className="form-label">{t('confirm_password')}</IonLabel>
                                                <div className="form-password">
                                                    <IonInput type={this.state.signupConfirmPasswordShown ? "text" : "password"} className="form-input" onIonInput={ e => this.setState({signup: {...this.state.signup, confirmPassword:e.detail.value}})}/>
                                                    <button type="button" ion-button="true" className="form-password__button" onClick={this.toggleSignupConfirmPasswordVisiblity}>
                                                        <svg className="form-password__button-icon">
                                                            <use xlinkHref={`#icon-eye-${this.state.signupConfirmPasswordShown ? 'closed' : 'open'}`}></use>
                                                        </svg>
                                                    </button>
                                                </div>
                                                {'confirmPassword' in this.state.errors ? (<div className="invalid-message">{this.state.errors.confirmPassword}</div>) : null}
                                            </IonItem>
                                            <IonItem lines="none" className={`form-list__item ${'name' in this.state.errors ? 'is-invalid' : ''}`}>
                                                <IonLabel position="stacked" color="primary" className="form-label">{t('your_name')}</IonLabel>
                                                <IonInput type="text" className="form-input" onIonInput={ e => this.setState({signup: {...this.state.signup, name:e.detail.value}})} />
                                                {'name' in this.state.errors ? (<div className="invalid-message">{this.state.errors.name}</div>) : null}
                                            </IonItem>
                                            <IonItem lines="full" className={`form-list__item ${'lastName' in this.state.errors ? 'is-invalid' : ''}`}>
                                                <IonLabel position="stacked" color="primary" className="form-label">{t('your_last_name')}</IonLabel>
                                                <IonInput type="text" className="form-input" onIonInput={ e => this.setState({signup: {...this.state.signup, lastName:e.detail.value}})}/>
                                                {'lastName' in this.state.errors ? (<div className="invalid-message">{this.state.errors.lastName}</div>) : null}
                                            </IonItem>
                                            {/* <IonItem lines="full" className={`form-list__item ${'phone' in this.state.errors ? 'is-invalid' : ''}`}>
                                                <IonLabel position="stacked" color="primary" className="form-label">{t('your_phone')}</IonLabel>
                                                <PhoneInput
                                                    value={this.state.phone}
                                                    onChange={value => this.setState({signup: {...this.state.signup, phone: value}})}
                                                    international
                                                    countryCallingCodeEditable={false}
                                                    defaultCountry="BG"
                                                />
                                                {'phone' in this.state.errors ? (<div className="invalid-message">{this.state.errors.phone}</div>) : null}
                                            </IonItem> */}
                                            <IonItem lines="none" className="form-list__item">
                                                {/* TO DO: Ask for terms and conditions */}
                                                <div>
                                                    <IonLabel className="form-checkbox">
                                                        <IonCheckbox color="primary" className="form-checkbox__element" name="checkbox_1" checked={this.state.signup.accept_terms_conditios} onIonChange={ e => this.setState({signup: {...this.state.signup, accept_terms_conditios:e.detail.checked}})}/>
                                                        <div className="form-checkbox__text">
                                                            {t('accept')} &nbsp; <IonButton style={{position: "relative", zIndex: "9999"}} color="link" href={`https://tesy.bg/redirect/tesyme/${this.props.app.lang}/terms-and-conditions/`}>{t('terms_conditions')}</IonButton>
                                                        </div>
                                                    </IonLabel>
                        
                                                    <IonLabel className="form-checkbox">
                                                        <IonCheckbox color="primary" className="form-checkbox__element" name="checkbox_2" onIonChange={ e => this.setState({signup: {...this.state.signup, receive_emails:e.detail.checked}})}/>
                                                        <div className="form-checkbox__text">{t('receive_emails')}</div>
                                                    </IonLabel>
                                                </div>
                                            </IonItem>
                                            <IonItem lines="none" className="form-list__item">
                                                <div className="ion-text-center" style={{ width: '100%' }}>
                                                    <IonButton color="primary" onClick={this.signup}>
                                                        <span className="button__text">{t('signup')}</span>
                                                    </IonButton>
                                                </div>
                                            </IonItem>
                                        </IonList>
                                        </div>
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                    <ToastMessage />
                </IonContent>
                <Footer />
                <Loader show={this.state.showLoading}/>
            </IonPage>
        );    
    }
   
};

const mapStateToProps = state => {
    return {
      app: state.app
    }
  }

export default compose(
    withTranslation(),
    connect(mapStateToProps, {setAppMessage})
)(Login);