import {
    IonAlert,
    IonRow,
    IonCol,
    IonToolbar,
    IonButtons,
    IonButton,
    IonLabel
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import CurrentModeInfo from './CurrentModeInfo';
import ChangeDeviceMode from './ChangeDeviceMode';

import HeatPumpHelper from "../../../../helpers/HeatPumpHelper";
import HeatPumpMqttHelper from "../../../../helpers/mqqt/HeatPumpMqttHelper";

const ProgramMode = props => {

    const HeatPumpHelperOBJ = new HeatPumpHelper(props.device);
    const { t } = useTranslation();
    const {state: deviceState} = props.device;
    const deviceMode = HeatPumpHelperOBJ.getMode();

    const [mode, setMode] = useState(null);

    let current = null;
    let next = null;
    if (props.device.state.program && props.device.state.program_status == "on") {
        
        current = HeatPumpHelperOBJ.getProgramCurrentPeriod();
        next = HeatPumpHelperOBJ.getProgramNextPeriod();
    }

    const changeMode = program_status => {
        if (program_status == "on") {
            setMode("hp");
        } else {
            setMode("program");
        }
    }

    const renderCurrentPeriod = () => {

        if (current) {
            return (
                <div className="program-status__col">
                    <div className="program-status__title program-status__title--primary">{t('hp_program_current_period')}</div>
                    <div className="program-status__subtitle program-status__subtitle--primary">{t(`hp_mode_${current.mode}`)}</div>
                    <div className="program-status__list">
                        <div className="program-status__list-item program-status__list-item--primary">
                            <div className="program-status__list-top program-status__list-top">
                                <svg className="program-status__list-icon"><use xlinkHref="#icon-idle"></use></svg> {current.temp}°
                            </div>
                            <div className="program-status__list-bottom">{current.from} - {current.to}</div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    const renderNextPeriod = () => {
        if (next) {
            return (
                <div className="program-status__col">
                    <div className="program-status__title">{t('hp_program_next_period')}</div>
                    <div className="program-status__subtitle">{t(`hp_mode_${next.mode}`)}</div>
                    <div className="program-status__list program-status__list--alt">
                        <div className="program-status__list-item">
                            <div className="program-status__list-top">
                                <svg className="program-status__list-icon"><use xlinkHref="#icon-idle"></use></svg> {next.temp}°
                            </div>
                            <div className="program-status__list-bottom">{next.from} - {next.to}</div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <React.Fragment>
            {props.device.state.program_status == "off" && 
                <IonRow className="ion-align-items-center">
                    
                    <IonCol size='12' sizeMd='4'>
                        <CurrentModeInfo mode={HeatPumpHelperOBJ.getMode()} icon={HeatPumpHelperOBJ.getModeIcon()} disableDevice={props.disableDevice}/>
                    </IonCol>

                    <IonCol size='12' sizeMd='8'>
                        <div className="ion-text-center ion-text-md-left">
                            <div className={`device-mode__title mb-10`}>{t('hp_choose_your_program')}</div>
                            <IonButton color="primary" id="activate-alert" onClick={e => changeMode(props.device.state.program_status)}>
                                <span className="button__text">{props.device.state.program_status == "on" ? t('deactivate') : t('activate')}</span>
                            </IonButton>
                            <IonButton color="tertiary" routerLink={`/device/program/${props.device.model.toLowerCase()}/${props.mac}`}>
                                <span className="button__text">{t('edit')}</span>
                            </IonButton>
                        </div>
                    </IonCol>
                </IonRow>
            }

            {props.device.state.program_status == "on" &&
                <>
                    <div className="program-status program-status--alt">
                        {renderCurrentPeriod()}
                        {renderNextPeriod()}
                    </div>
                    <div className="ion-text-center ion-padding-top">
                        <IonButton color="primary" id="activate-alert" onClick={e => changeMode(props.device.state.program_status)}>
                            <span className="button__text">{props.device.state.program_status == "on" ? t('deactivate') : t('activate')}</span>
                        </IonButton>
                        <IonButton color="tertiary" routerLink={`/device/program/${props.device.model.toLowerCase()}/${props.mac}`}>
                            <span className="button__text">{t('edit')}</span>
                        </IonButton>
                    </div>
                </>
            } 

            <ChangeDeviceMode 
                device={props.device} 
                newMode={mode} 
                mac={props.mac}
                cancelChange={mode => setMode(mode)}
                changeMainMode={mode => props.changeMainMode(mode)}
            />

        </React.Fragment>
    )
}

export default ProgramMode;