import {
    IonButton,
    IonList,
    IonItem
} from '@ionic/react';

import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';


const Week = props => {

    let weekData = {
        MO: {
            'day': props.t('Monday'),
            'isSelected': true
        },
        TU: {
            'day': props.t('Tuesday'),
            'isSelected': false
        },
        WE: {
            'day': props.t('Wednesday'),
            'isSelected': false
        },
        TH: {
            'day': props.t('Thursday'),
            'isSelected': false
        },
        FR: {
            'day': props.t('Friday'),
            'isSelected': false
        },
        SA: {
            'day': props.t('saturday'),
            'isSelected': false
        },
        SU: {
            'day': props.t('Sunday'),
            'isSelected': false
        }
    }

    const [week, setWeek] = useState(weekData);

    useEffect(() => {

        if (props.defaultDay !== undefined) {

            for (const dayIndex in weekData) {
    
                if (dayIndex === props.defaultDay) {
                    weekData[dayIndex].isSelected = true;  
                } else {
                    weekData[dayIndex].isSelected = false;  
                }
            }

            setWeek({...weekData});
        }

    }, [props['defaultDay']]);

    const handleDayChange = e => {

        const selectedDay = e.currentTarget.dataset.value;

        for (const dayIndex in week) {

            if (dayIndex === selectedDay) {
                week[dayIndex].isSelected = true;
            } else {
                week[dayIndex].isSelected = false;
            }
        }
        
        setWeek({...week});
        props.setSelectedDay(selectedDay);
    }

    const renderWeek = () => {
        const days = [];

        for (const dayIndex in week) {
            
            const {isSelected, day} = week[dayIndex];

            days.push(
                <IonItem className="day-picker__item" lines="none" key={`day_${dayIndex}`}>
                    <IonButton type="button" data-value={dayIndex} className={`day-picker__button ${isSelected ? 'is-active' : ''}`} onClick={ (e) =>   handleDayChange(e) }>{day}</IonButton>
                </IonItem>
            );
        }

        return days;
    }

    return (
        <div className="day-picker__wrapper" style={{ marginTop: '18px', marginBottom: '18px' }}>
            <IonList className="day-picker">
                {renderWeek()}
            </IonList>
        </div>
    );
}

export default withTranslation()(Week);