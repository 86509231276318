import {
    IonPage,
    IonContent,
    IonIcon
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import StandartHeader from './components/header/StandartHeader';
import convectors from '../../assets/images/convectors_new.jpg';
import boilers from '../../assets/images/boilers_new.jpg';
import arrowNext from '../../assets/images/arrow-list.svg';
import heatPumps from '../../assets/images/Heat_pump.jpg';

const DevicesTutorials = props => {

    return (
        <IonPage>
            <StandartHeader name={props.t('user_manual')}/>

            <IonContent className="ion-padding">
                <div className="device-list">
                    <div className="device-list__item" onClick={() => props.history.push('/convectors-tutorials')}>
                        <div className="device-list__preview">
                            <img src={convectors} alt="photo" />
                        </div>
                        <div className="device-list__content">
                            <div className="device-list__title">{props.t('convectors')}</div>
                        </div>
                        <IonIcon icon={arrowNext} slot="end"></IonIcon>
                    </div>
                    <div className="device-list__item" onClick={() => props.history.push('/boilers-tutorials')}>
                        <div className="device-list__preview">
                            <img src={boilers} alt="photo" />
                        </div>
                        <div className="device-list__content">
                            <div className="device-list__title">{props.t('boilers')}</div>
                        </div>
                        <IonIcon icon={arrowNext} slot="end"></IonIcon>
                    </div>
                    {/* <div className="device-list__item" onClick={() => props.history.push('/heat-pumps-tutorials')}>
                        <div className="device-list__preview">
                            <img src={heatPumps} alt="photo" />
                        </div>
                        <div className="device-list__content">
                            <div className="device-list__title">{props.t('hp_heat_pumps')}</div>
                        </div>
                        <IonIcon icon={arrowNext} slot="end"></IonIcon>
                    </div> */}
                </div>
            </IonContent>
        </IonPage>
    );
};
  
  export default withTranslation()(DevicesTutorials); 