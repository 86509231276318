class ProgramHelper
{
    weekDays = {
        MO: 'Monday',
        TU: 'Tuesday',
        WE: 'Wednesday',
        TH: 'Thrusday',
        FR: 'Friday',
        SA: 'Saturday',
        SU: 'Sunday',
    };

    dayHours = [
        '00:00',
        '01:00',
        '02:00',
        '03:00',
        '04:00',
        '05:00',
        '06:00',
        '07:00',
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00',
        '24:00',
    ];

    dayHoursHalf = [
        '00:00',
        '00:30',
        '01:00',
        '01:30',
        '02:00',
        '02:30',
        '03:00',
        '03:30',
        '04:00',
        '04:30',
        '05:00',
        '05:30',
        '06:00',
        '06:30',
        '07:00',
        '07:30',
        '08:00',
        '08:30',
        '09:00',
        '09:30',
        '10:00',
        '10:30',
        '11:00',
        '11:30',
        '12:00',
        '12:30',
        '13:00',
        '13:30',
        '14:00',
        '14:30',
        '15:00',
        '15:30',
        '16:00',
        '16:30',
        '17:00',
        '17:30',
        '18:00',
        '18:30',
        '19:00',
        '19:30',
        '20:00',
        '20:30',
        '21:00',
        '21:30',
        '22:00',
        '22:30',
        '23:00',
        '23:30',
        '24:00',
    ];

    defaultHexValues = {
        FE: 1,
        FD: 2,
        FC: 3,
        FB: 4
    }

    defaultValuesHex = {
        0: 'FF',
        1: 'FE',
        2: 'FD',
        3: 'FC',
        4: 'FB'
    }

    createProgram  = (program, index, halfHours = false) => {

        let programData = {};

        for (const day in this.weekDays) {
            
            const data = this.splitProgramString(program[`prgP${index}${day}`]);

            programData[day] = [];
            let programIndex = 0;

            const hours = halfHours == true ? this.dayHoursHalf : this.dayHours;

            data.map(num => {

                const from  = hours[programIndex];
                const to  = hours[programIndex+1];
                const temp = this.convertToInt(num);
                const programLength = programData[day].length;

                if (programData[day][programLength-1] != undefined) {

                    if(programData[day][programLength-1]['temp'] == temp) {
                        programData[day][programLength-1]['to'] = to;    
                    } else {
                        programData[day].push({from, to, temp})    
                    }
                } else {
                    programData[day].push({from, to, temp})
                }


                programIndex++;
            })
            
            
        }

        return programData;
    }

    createApiv1Program = (program, programInterval, index = 1) => {

        const newProgram = this.prepareProgram(program, programInterval, index);
        let programString = '';
        
        for (const i in newProgram) {
            programString += newProgram[i];
        }

        return programString;
    }

    createApiv2Program = (program, programInterval, index = 1) => this.prepareProgramV2(program, programInterval, index);

    prepareProgramV2 = (program, {day, from, to, temp}, index = 1) => {

        let currentProgram = this.splitProgramString(program[`prgP${index}${day}`]);

        for (let i = from; i < to; i++) {
            currentProgram[i] = this.convertToHex(temp);
        }

        return currentProgram.join("");
    }


    prepareProgram = (program, {day, from, to, temp}, index = 1) => {

        global.debug(program, program[`prgP${index}${day}`]);
        let currentProgram = this.splitProgramString(program[`prgP${index}${day}`]);
 
        for (let i = from; i < to; i++) {
            currentProgram[i] = this.convertToHex(temp);
        }

        program[`prgP${index}${day}`] = currentProgram.join("");
        let newProgram = {};

        for (const day in this.weekDays) {
            newProgram[`prgP${index}${day}`] = program[`prgP${index}${day}`];    
        }

        return newProgram;
    }

    prepareVacationApiv2 = vac => {
        let currentVac = this.splitProgramString(vac);

        currentVac = currentVac.map(hex => this.convertToInt(hex))
        const [temp, hour, date, weekDay, month, year] = currentVac;

        return {
            temp, hour, date, weekDay, month, year
        }
    }

    createVacationApiv2 = vacArray => {
        const hexVacArray = vacArray.map(value => {

            global.debug(value);
            let hex = value.toString(16);
            if (hex.length == 1){
                hex = `0${hex}`;
            }

            global.debug(hex);
            return hex;
        });

        return hexVacArray.join("");
    }


    convertToInt = hexString => {

        const defaultHex = ['FF'];

        if (defaultHex.includes(hexString)){
            return 0;
        }

        if (this.defaultHexValues[hexString] != undefined) {
            return this.defaultHexValues[hexString];
        }

        if (hexString.length == 1){
            hexString = `0${hexString}`;
        }

        return parseInt(hexString, 16);
    }
    
    convertToHex = number => {

        if (this.defaultValuesHex[number] != undefined) {
            return this.defaultValuesHex[number];
        }

        let hex = number.toString(16);
        if (hex.length == 1){
            hex = `0${hex}`;
        }

        return hex;
    }

    splitProgramString = programString => {

        return programString != undefined ? programString.match(/(..)/g) : [];
    }

    validateProgram = (from, to, useHaflHours = false) => {
        
        from = useHaflHours == true ? this.dayHoursHalf[from] : this.dayHours[from];
        to = useHaflHours == true ? this.dayHoursHalf[to] : this.dayHours[to];

        if (!this.validateToAndFrom(from, to)) {
            return false;
        }

        return true;
    }

    getHourKey = (hour, useHaflHours = false) => {

        const hours = useHaflHours == true ? this.dayHoursHalf : this.dayHours;   

        let key = 0;

        hours.map( (h, i) => {
            if (h == hour) {
                key = i;
            }
        })

        return key;
    }

    validateToAndFrom = (from, to) => {

        const fromToMin = this.convertTimeToMinutes(from);
        const toToMin = this.convertTimeToMinutes(to);

        if (toToMin <= fromToMin) {
            return false;
        }

        return true;
    } 

    convertTimeToMinutes = time => {

        let [hour, min] = time.split(":");
        hour = Number(hour);
        min = Number(min);

        return hour*60 + min;
    }
}

export default new ProgramHelper();