import {
    IonButton,
    IonAlert
} from '@ionic/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import HeatPumpMqttHelper from "../../../../helpers/mqqt/HeatPumpMqttHelper";

const PowerButton = props => {

    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const HeatPumpMqttHelperOBJ = new HeatPumpMqttHelper(props.mac);
    const { t } = useTranslation();

    const setPower = status => {
        if (status == "off") {
            setAlertIsOpen(true);
        } else {
            HeatPumpMqttHelperOBJ.request('powerOnOff', {state: status})
        }
    }

    return (
        <React.Fragment>
            <IonButton 
                color="transparent" 
                className={`${props.class != undefined ? props.class : ''} button-switch ${props.power === 'off' ? "button-switch--off" : "button-switch--on"}`} 
                onClick={() => setPower(props.power == 'on' ? 'off' : 'on')}
            >
                <svg className="button-switch__icon"><use xlinkHref="#icon-switch"></use></svg>
            </IonButton>
            
            <IonAlert
                isOpen={alertIsOpen}
                onDidDismiss={() => setAlertIsOpen(false)}
                header={t('hp_set_power_off_header')}
                message={t('hp_set_power_off_text')}
                buttons={
                    [{
                        text: t('cancel'),
                        handler: () => {
                            setAlertIsOpen(false)
                        }
                    },
                    {
                        text: t('ok'),
                        handler: () => {
                            HeatPumpMqttHelperOBJ.request('powerOnOff', {state: "off"})
                        }
                    }]
                }
            />
        </React.Fragment>
    )
}

export default PowerButton;