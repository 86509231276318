
import {
    FETCH_DEVICES,
    UPDATE_DEVICE_STATE,
    ADD_DEVICE_PROGRAM,
    DELETE_DEVICE_PROGRAM,
    DELETE_ALL_DEVICE_PROGRAMS,
    UPDATE_DEVICE_TIME,
    UPDATE_DEVICE_INETERNET_CONNECTION,
    UPDATE_DEVICE_WIFI,
    SET_DEVICE_LOCAL_USAGE,
    UPDATE_DEVICE_WAITING_FOR_CONNECTION,
    UPDATE_DEVICE_MODE_TIME_REMAINING
} from '../types/DeviceTypes';

import TimeHelper from '../../helpers/TimeHelper';

const devicesReducer = (devices = {}, action) => {
    //global.debug('Device state:', devices);
    switch(action.type) {
        case FETCH_DEVICES:
            return action.payload

        case UPDATE_DEVICE_TIME:

            const {timezone, date, time} = action.payload;
            devices[action.payload.macAddress].time = JSON.stringify({
                "date": date,
                "time": time
            });
            devices[action.payload.macAddress].timezone = timezone;

            return {...devices};

        case UPDATE_DEVICE_INETERNET_CONNECTION:

            if (devices[action.payload.macAddress].localUsage === false ) {
                
                devices[action.payload.macAddress].hasInternet = action.payload.status;
                devices[action.payload.macAddress].last_connection = new Date();
            }

            return {...devices};

        case UPDATE_DEVICE_WAITING_FOR_CONNECTION:

            devices[action.payload.macAddress].waitingForConnection = action.payload.status;

            return {...devices};

        case SET_DEVICE_LOCAL_USAGE:

            global.debug('Device local control: ', action.payload.status, action.payload.macAddress);
            devices[action.payload.macAddress].localUsage = action.payload.status;

            return {...devices};

        case UPDATE_DEVICE_WIFI:

            const { wifiData: { wifiSSID, wifiPass }} = action.payload;

            devices[action.payload.macAddress].wifi_ssid = wifiSSID;
            devices[action.payload.macAddress].wifi_pass = wifiPass;

            return {...devices};

        case UPDATE_DEVICE_STATE:

            let {state, field, macAddress: mac} = action.payload;
            devices[mac].state[field]= state;

            return {...devices};

        case UPDATE_DEVICE_MODE_TIME_REMAINING:

            devices[action.payload.macAddress].state['modeTime'] = TimeHelper.addTimeInMinutes(devices[action.payload.macAddress].timezone, action.payload.time);

            return {...devices};

        case ADD_DEVICE_PROGRAM:
            
            let { program: deviceProgram } = devices[action.payload.macAddress].state;
            let {program, key} = action.payload;
            devices[action.payload.macAddress].state.program  = {...deviceProgram,  [key]: {...program}}
            
            return {...devices};

        case DELETE_DEVICE_PROGRAM:

            let { program: dProgram } = devices[action.payload.macAddress].state;
            let { key: programKey } = action.payload;

            delete dProgram[programKey];
            devices[action.payload.macAddress].state.program  = {...dProgram}

            return {...devices};

        case DELETE_ALL_DEVICE_PROGRAMS:
            devices[action.payload.macAddress].state.program  = {}

            return {...devices};
        default: 
            return devices;
    }
}

export default devicesReducer;