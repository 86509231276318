export const SET_LANG = 'SET_LANG';
export const SET_PUSH_NOTIOFICATIONS = 'SET_PUSH_NOTIOFICATIONS';
export const SET_PUSH_NOTIOFICATION = 'SET_PUSH_NOTIOFICATION';
export const READ_PUSH_NOTIOFICATION = 'READ_PUSH_NOTIOFICATION';
export const DELETE_PUSH_NOTIOFICATION = 'DELETE_PUSH_NOTIOFICATION';
export const DELETE_ALL_PUSH_NOTIOFICATIONS = 'DELETE_ALL_PUSH_NOTIOFICATIONS';
export const SET_APP_MESSAGE = 'SET_APP_MESSAGE';
export const HIDE_APP_MESSAGE = 'HIDE_APP_MESSAGE';
export const SET_APP_LOG = 'SET_APP_LOG';
export const SET_APP_DOCUMENTS = 'SET_APP_DOCUMENTS';
export const CLEAR_APP_LOG = 'CLEAR_APP_LOG';
export const OLD_DEVICES_LOADER = 'OLD_DEVICES_LOADER';
export const SET_APP_PROCESSING = 'SET_APP_PROCESSING';
export const SET_APP_NETWORK = 'SET_APP_NETWORK';
export const SET_APP_SETTINGS = 'SET_APP_SETTINGS';
export const SHOW_REQUEST_LOADER = 'SHOW_REQUEST_LOADER';
export const HIDE_REQUEST_LOADER = 'HIDE_REQUEST_LOADER';