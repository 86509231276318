import {
    IonContent,
    IonFooter,
    IonButton,
    IonPage,
    IonToolbar,
    IonText,
} from '@ionic/react';

import React from 'react';
import { useTranslation } from 'react-i18next';

import StandartHeader from '../../components/header/StandartHeader';
import Loading from '../../components/loading/Loading';
import ModecoC21_1 from '../../../../files/2000_1.svg';
import ModecoC21_2 from '../../../../files/2000_2.svg';
import ModecoC21_3 from '../../../../files/2000_3.svg';


const HPWH4_11_260 = props => {

    const { t } = useTranslation();

    return (
        <IonPage>        
            
            <StandartHeader name={t('hp_HPWH4_11_260')}/>

            <IonContent className="ion-padding">
                <div className="text">
                    <div style={{ margin: '16px 0 32px', textAlign: 'center' }}>
                        <img src={ModecoC21_1} />
                    </div>
                    <p>{t('hp_add_device_step_1')}</p>
                    <p>{t('hp_add_device_step_2')}</p>
                    <div style={{ margin: '32px 0 16px', textAlign: 'center' }}>
                        <img src={ModecoC21_2} />
                    </div>
                    <p>{t('hp_add_device_step_3')}</p>
                    <div style={{ margin: '32px 0 16px', textAlign: 'center' }}>
                        <img src={ModecoC21_3} />
                    </div>
                    <p>{t('hp_add_device_step_4')}</p>
                    <p>{t('hp_add_device_step_5')}</p>
                    <p>{t('hp_add_device_step_6')}</p>
                    <p>{t('hp_add_device_step_7')}</p>
                    <p>{t('hp_add_device_step_8')}</p>
                    <p>{t('hp_add_device_step_9')}</p>
                    <p>{t('hp_add_device_step_10')}</p>
                    <p>{t('hp_add_device_step_11')}</p>

                </div>
            </IonContent>
            
            <IonFooter className="app-footer ion-no-border">
                <IonToolbar>
                    <div className="buttons-list">
                        <div className="buttons-list__item">
                            <IonButton expand="block" shape="round" size="large" routerLink={`/connect/hpwh4_11_260/choose-device`}>{t('continue')}</IonButton>
                        </div>
                    </div>
                </IonToolbar>
            </IonFooter>
        </IonPage>
    );
};

export default HPWH4_11_260;
  