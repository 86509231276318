import {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonInput
} from '@ionic/react';

import React, {useState, useEffect} from 'react';
import { withTranslation } from 'react-i18next';
import { Device } from '@capacitor/device';
import AppHelper from '../../helpers/AppHelper';

import StandartHeader from './components/header/StandartHeader';
import WaitingScreen from './components/waitingScreen/WaitingScreen';

class AppInfo extends React.Component
{
    state = {
        info: null,
        build: null,
        version: null
    }

    componentDidMount = async () => {

        const info = await Device.getInfo();
        const {build , version} = await AppHelper.getAppVersion();

        this.setState({
            info: info,
            build,
            version
        })
    }

    render = () => {

        if (!this.state.info) {
            return <WaitingScreen />;
        }

        const { t } = this.props;

        return (
            <IonPage>
            <StandartHeader name={t('app_info')}/>
    
            <IonContent className="ion-padding">        
                <IonList className="notifications-list">
    
                    <IonItem lines="full" className="form-list__item">
                        <IonLabel position="stacked" color="primary" className="form-label">{t('app_version')}</IonLabel>
                        <IonInput value={`${this.state.version} (${this.state.build})`} readonly/>
                    </IonItem>

                    <IonItem lines="full" className="form-list__item">
                        <IonLabel position="stacked" color="primary" className="form-label">{t('app_platform')}</IonLabel>
                        <IonInput value={this.state.info.platform} readonly/>
                    </IonItem>

                    <IonItem lines="full" className="form-list__item">
                        <IonLabel position="stacked" color="primary" className="form-label">{t('os_version')}</IonLabel>
                        <IonInput value={this.state.info.osVersion} readonly/>
                    </IonItem>

                    <IonItem lines="full" className="form-list__item">
                        <IonLabel position="stacked" color="primary" className="form-label">{t('phone')}</IonLabel>
                        <IonInput value={`${this.state.info.manufacturer} ${this.state.info.model}`} readonly/>
                    </IonItem>

    

    
                </IonList>
                <div className="ion-content-push"></div>
            </IonContent>
            </IonPage>
        );
    }

    
};

export default withTranslation()(AppInfo);  