import {
    IonPage,
    IonContent,
    IonIcon
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import StandartHeader from '../../components/header/StandartHeader';
import convectors from '../../../../assets/images/convectors_new.jpg';
import boilers from '../../../../assets/images/boilers_new.jpg';
import heatPumps from '../../../../assets/images/Heat_pump.jpg';
import arrowNext from '../../../../assets/images/arrow-list.svg';

const AddDevice = props => {

    return (
        <IonPage>
            <StandartHeader name={props.t('choose_device_group')}/>

            <IonContent className="ion-padding">
                <div className="device-list">
                    <div className="device-list__item" onClick={() => props.history.push('/production-mode/add-convector')}>
                        <div className="device-list__preview">
                            <img src={convectors} alt="photo" />
                        </div>
                        <div className="device-list__content">
                            <div className="device-list__title">{props.t('convectors')}</div>
                        </div>
                        <IonIcon icon={arrowNext} slot="end"></IonIcon>
                    </div>
                    <div className="device-list__item" onClick={() => props.history.push('/production-mode/add-boiler')}>
                        <div className="device-list__preview">
                            <img src={boilers} alt="photo" />
                        </div>
                        <div className="device-list__content">
                            <div className="device-list__title">{props.t('boilers')}</div>
                        </div>
                        <IonIcon icon={arrowNext} slot="end"></IonIcon>
                    </div>
                    {/* <div className="device-list__item" onClick={() => props.history.push('/production-mode/add-heat-pump')}>
                        <div className="device-list__preview">
                            <img src={heatPumps} alt="photo" />
                        </div>
                        <div className="device-list__content">
                            <div className="device-list__title">{props.t('hp_heat_pumps')}</div>
                        </div>
                        <IonIcon icon={arrowNext} slot="end"></IonIcon>
                    </div> */}
                </div>
            </IonContent>
        </IonPage>
    );
};
  
  export default withTranslation()(AddDevice); 