import {
    IonPage,
    IonContent,
    IonButton,
} from '@ionic/react';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import error_image from '../../../assets/images/error.svg';
import {oldModels} from '../../../config/App';
import E32Helper from '../../../helpers/E32Helper';
import PageHeader from '../components/header/PageHeader';

class Errors extends React.Component {

    render = () => {

        const { t } = this.props;
        
        const device = this.props.devices[this.props.match.params.mac_address];
        const { deviceName } = device;
        const E32HelperOBJ = new E32Helper(device);

        return (
            <IonPage>
                <Header />
                <IonContent className="ion-no-padding">
                    <div className="dashboard">
                        <div className="dashboard__wrapper">
                            <aside className="dashboard__aside">
                                <div className="dashboard__aside-inner">
                                    <IonButton color="back-secondary" onClick={() => this.props.history.goBack()}>
                                        <svg className="icon"><use xlinkHref="#icon-arrow-back"></use></svg>
                                        <span className="button__text">{t('back')}</span>
                                    </IonButton>
                                    <div className="device-preview">
                                        <div className="device-preview__preview">
                                            <img src={`${E32HelperOBJ.getImage()}`} className="device-preview__preview-image" alt="Device name" />
                                        </div>
                                        <div className="device-preview__text">
                                            {deviceName}
                                        </div>
                                    </div>
                                </div>
                            </aside>
                            <div className="dashboard__content">

                                <PageHeader name={t('error')}/>

                                <div className="dashboard-box">
                                    <div className="device-error">
                                        <div className="device-error__icon">
                                            <img src={error_image} alt="" />
                                        </div>
                                        <div className="device-error__text">
                                            {t('device_has_error')}
                                            <br /><br />
                                            <a href="https://tesy.com/contact-us/service-network" target="_blank">{t('find_service')}</a>
                                            <div className="device-error__id ion-padding-top">{t('device_id')}: {this.props.match.params.mac_address}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </IonContent>
                <Footer />
                    
            </IonPage>
        )
    }
}

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(Errors);