import basicModel from '../basicModel';
import {
    updateDeviceState,
    addDeviceProgram,
    deleteAllDevicePrograms,
    deleteDeviceProgram,
    updateDeviceModeTimeRemaining
} from '../../redux/actions/DevicesAction';

export default class cn05Basic extends basicModel {

    onOff() {
        const { payload: { status } } = this.payload;
        this.store.dispatch(updateDeviceState(status, this.table_fields.status, this.macAddr));

        global.debug(`SET ON OFF: ${status}.....`);
    }

    setTemp() {
        const { payload: { temp } } = this.payload;
        this.store.dispatch(updateDeviceState(temp, this.table_fields.temp, this.macAddr));

        global.debug(`SET TEMP ${temp}....`);
    }

    setOpenedWindow() {
        const { payload: { status } } = this.payload;
        this.store.dispatch(updateDeviceState(status, this.table_fields.openedWindow, this.macAddr));

        global.debug(`SET Opened Window ${status}....`);
    }

    setDelayedStart() {
        let { payload: { time, temp } } = this.payload;

        this.store.dispatch(updateDeviceState({time, temp}, this.table_fields.delayedStart, this.macAddr));
        this.store.dispatch(updateDeviceState(temp, this.table_fields.temp, this.macAddr));

        global.debug(`SET Delayed Start ${time}....`);
    
    }

    setDelayedStop() {
        let { payload: { time } } = this.payload;

        time = time / 60;
        this.store.dispatch(updateDeviceState(time, this.table_fields.delayedStop, this.macAddr));

        global.debug(`SET Delayed Stop ${time}....`);
    
    }

    setTCorrection() {
        const { payload: { temp } } = this.payload;
        this.store.dispatch(updateDeviceState(temp, this.table_fields.tCorrection, this.macAddr));

        global.debug(`SET TCorrection ${temp}....`);
    }

    setAntiFrost() {
        const { payload: { status } } = this.payload;
        this.store.dispatch(updateDeviceState(status, this.table_fields.antiFrost, this.macAddr));

        global.debug(`SET Anti-Frost: ${status}.....`);
    }

    setComfortTemp() {
        const { payload: { temp } } = this.payload;
        this.store.dispatch(updateDeviceState({temp}, this.table_fields.comfortTemp, this.macAddr));

        global.debug(`SET Comfort Temp ${temp}....`);
    }

    setEcoTemp() {
        const { payload: { temp, time } } = this.payload;
        this.store.dispatch(updateDeviceState({temp, time}, this.table_fields.ecoTemp, this.macAddr));

        global.debug(`SET Eco Temp ${temp}....`);
    }

    setSleepTemp() {
        const { payload: { time } } = this.payload;
        this.store.dispatch(updateDeviceState({time}, this.table_fields.sleepMode, this.macAddr));

        global.debug(`SET Sleep Time ${time}....`);
    }

    setUV() {
        const { payload: { status } } = this.payload;
        this.store.dispatch(updateDeviceState(status, this.table_fields.uv, this.macAddr));

        global.debug(`SET UV ${status}....`);
    }

    setLockDevice() {
        const { payload: { status } } = this.payload;
        this.store.dispatch(updateDeviceState(status, this.table_fields.lockDevice, this.macAddr));

        global.debug(`SET Lock Device ${status}....`);
    }

    setWatt() {
        const { payload: { watt } } = this.payload;
        this.store.dispatch(updateDeviceState(watt, this.table_fields.watt, this.macAddr));

        global.debug(`SET Watt ${watt}....`);
    }

    setProgram() {
        const { payload: { program } } = this.payload;
        this.store.dispatch(updateDeviceState(program, this.table_fields.program, this.macAddr));

        global.debug(`SET Program ${program}....`);
    }

    setTempStatistic() {

        const {payload: { currentTemp, heating, target, timeRemaining }} = this.payload;

        this.store.dispatch(updateDeviceState(currentTemp, this.table_fields.current_temp, this.macAddr));
        this.store.dispatch(updateDeviceState(heating, this.table_fields.heating, this.macAddr));
        this.store.dispatch(updateDeviceState(target, this.table_fields.target, this.macAddr));
        this.store.dispatch(updateDeviceState(timeRemaining, this.table_fields.timeRemaining, this.macAddr));
        this.store.dispatch(updateDeviceModeTimeRemaining(timeRemaining, this.macAddr));

        global.debug(`SET Current temp ${currentTemp}....`);
        global.debug(`SET Current target ${target}....`);
        global.debug(`SET Current timeRemaining ${timeRemaining}....`);
    }

    setAdaptiveStart() {

        const {payload: { status }} = this.payload;

        this.store.dispatch(updateDeviceState(status, this.table_fields.adaptiveStart, this.macAddr));

        global.debug(`SET Adaptive Start ${status}....`);
    }

    setMode() {

        const {payload: { name }} = this.payload;

        this.store.dispatch(updateDeviceState(name, this.table_fields.mode, this.macAddr));

        global.debug(`SET Mode ${name}....`);
    }

    setProgramStatus() {

        const {payload: { status }} = this.payload;

        this.store.dispatch(updateDeviceState(status, this.table_fields.programStatus, this.macAddr));

        global.debug(`setProgramStatus ${status}....`);
    }

    setProgram() {
        const {payload: { day, from, to, temp, program_number }} = this.payload;

        this.store.dispatch(addDeviceProgram(this.macAddr, program_number, {day, from, to, temp, program_number}));

        global.debug(`SET Program....`, { day, from, to, temp, program_number });
    }

    deleteProgram() { 
        const {payload: { program_number }} = this.payload;

        this.store.dispatch(deleteDeviceProgram(this.macAddr, program_number));

        global.debug(`Delete Program....`, program_number);
    }

    deleteAllPrograms() { 
        const {payload: { program_number }} = this.payload;

        this.store.dispatch(deleteAllDevicePrograms(this.macAddr));

        global.debug(`Delete All Programs....`);
    }

    wifiinfo() {
        const {payload: { wifiinfo }} = this.payload;

        this.store.dispatch(updateDeviceState(wifiinfo, 'wifiInfo', this.macAddr));

        global.debug(`Update Wifi Info ${wifiinfo}....`);
    }

    getSSID() {
        const {payload: { wifiinfo }} = this.payload;

        if (wifiinfo !== undefined) {
            
            this.store.dispatch(updateDeviceState(wifiinfo, 'wifiInfo', this.macAddr));

            global.debug(`Update Wifi Info ${wifiinfo}....`);
        }
        
    }

    getStatus() {

        const { payload } = this.payload;
        
        for (const command in payload) {

            this.command = command;
            this.payload = {code: 200, ...payload[command]};
            this.executeModelCommand();
        }
    }
}