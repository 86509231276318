import React from 'react';

import {
    IonPage,
    IonContent,
    IonButton
} from '@ionic/react';
import { useTranslation } from 'react-i18next';

import HeaderWelcome from './components/header/HeaderWelcome';
import Footer from './components/footer/Footer';

// import '../../assets/css/webApp/components/welcome/welcome.scss';
import welcomeImage from '../../temp-files/webApp/welcome-image.jpg';

const Welcome = () => {

    const { t } = useTranslation();
    
    return (
        <IonPage>
            <HeaderWelcome />      
            {/* <IonContent className="ion-no-padding">
                <div className="welcome">
                    <div className="welcome__start">
                        <img src={welcomeImage} className="welcome__start-image" width="" height="" alt="" />
                    </div>
                    <div className="welcome__end">
                        <div className="welcome__end-inner">
                            <h1 className="welcome__title">{t('welcome')}</h1>
                            <h2 className="welcome__sub-title">{t('keep_your_home')}</h2>
                            <div className="pt-40"></div>
                            <IonButton color="secondary" routerLink="/login/0">{t('signin_text')}</IonButton>
                            <IonButton color="primary" routerLink="/login/1">{t('signup')}</IonButton>
                        </div>
                    </div>
                </div>
            </IonContent> */}
            <IonContent className="ion-no-padding">
                <div className="welcome">
                    <div className="welcome__start">
                        <img src={welcomeImage} className="welcome__start-image" width="" height="" alt="" />
                    </div>
                    <div className="welcome__end">
                        <div className="welcome__end-inner">
                            <div className="welcome__end-inner-top">
                                <h1 className="welcome__title">{t('welcome')}</h1>
                                <h2 className="welcome__sub-title">{t('keep_your_home')}</h2>
                                <div className="pt-40">
                                    <IonButton color="secondary" routerLink="/login/0">{t('signin_text')}</IonButton>
                                    <IonButton color="primary" routerLink="/login/1">{t('signup')}</IonButton>
                                </div>
                            </div>
                            <div className="welcome__end-inner-bottom">
                                {t('web_tesycloud_with')} <a href="https://www.mytesy.com/v3"><strong>tesyCloud</strong></a> {t('tesycloud_conv_boil')}
                            </div>
                        </div>
                    </div>
                </div>
            </IonContent>
            <Footer />
        </IonPage>
    );
};

export default Welcome;
