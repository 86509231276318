import {
    FETCH_OLD_DEVICES,
    UPDATE_OLD_DEVICE_STATE
} from '../types/OldDevicesTypes';

export const fetchOldDevices = devices => {
    return {
        type: FETCH_OLD_DEVICES,
        payload: devices
    }
}

export const updateOldDeviceState = (state, field, DeviceSerial) => {
    return {
        type: UPDATE_OLD_DEVICE_STATE,
        payload: {
            state,
            DeviceSerial,
            field
        }
    }
}