export const FETCH_DEVICES = 'FETCH_DEVICES';
export const UPDATE_DEVICE_STATE = 'UPDATE_DEVICE_STATE';
export const UPDATE_DEVICE_PROGRAM_ADAPTIVE_START = 'UPDATE_DEVICE_PROGRAM_ADAPTIVE_START';
export const ADD_DEVICE_PROGRAM = 'ADD_DEVICE_PROGRAM';
export const FETCH_MY_DEVICES = 'FETCH_MY_DEVICES';
export const DELETE_DEVICE_PROGRAM = 'DELETE_DEVICE_PROGRAM';
export const DELETE_ALL_DEVICE_PROGRAMS = 'DELETE_ALL_DEVICE_PROGRAMS';
export const UPDATE_DEVICE_TIME = 'UPDATE_DEVICE_TIME';
export const UPDATE_DEVICE_INETERNET_CONNECTION = 'UPDATE_DEVICE_INETERNET_CONNECTION';
export const UPDATE_DEVICE_WIFI = 'UPDATE_DEVICE_WIFI';
export const SET_DEVICE_LOCAL_USAGE = 'SET_DEVICE_LOCAL_USAGE';
export const UPDATE_DEVICE_WAITING_FOR_CONNECTION = 'UPDATE_DEVICE_WAITING_FOR_CONNECTION';
export const UPDATE_DEVICE_MODE_TIME_REMAINING = 'UPDATE_DEVICE_MODE_TIME_REMAINING';