import React, {useState, useEffect} from 'react';
import {
    Link
} from 'react-router-dom';

import {
    IonHeader,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonItem,
    IonPopover,
    IonList
} from '@ionic/react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'
import Select from 'react-select';
import { withRouter} from 'react-router-dom';

import { ReactComponent as Logo } from '../../../../assets/images/webApp/logo.svg';

import LocalStorageHelper from '../../../../helpers/LocalStorageHelper';
import UsersHelper from '../../../../helpers/UsersHelper';
import { fetchDevices } from '../../../../redux/actions/DevicesAction';
import { fetchGroups } from '../../../../redux/actions/GroupsAction';
import {
    userLogin
} from '../../../../redux/actions/UserAction';
import { languages } from '../../../../config/App';
import {
    setAppLanguage
} from '../../../../redux/actions/AppAction';

const Header = (props) => {


    const [langState, setLangState] = useState(null);

    useEffect(() => {


        async function setLang() {
            
            setTimeout(async () => {
                const langValue = await LocalStorageHelper.get('user.lang');
                setLangState(langValue);
            }, 1000)
        }

        setLang();
        
    }, []);

    const logOut = () => {

        LocalStorageHelper.set('user.id', null);
        LocalStorageHelper.set('user.password', null);
        LocalStorageHelper.set('user.email', null);
        LocalStorageHelper.set('user.firstName', null);
        LocalStorageHelper.set('user.lastName', null);
        LocalStorageHelper.set('user.phone', null);
        LocalStorageHelper.set('user.lang', null);

        props.userLogin(false);
        props.fetchDevices({});
        props.fetchGroups({});

        props.history.push('/welcome');
    }

    const setLanguage = async lang => {

        const usHelper = new UsersHelper();

        await usHelper.accountSettings({
            email: await LocalStorageHelper.get('user.email'),
            name: await LocalStorageHelper.get('user.firstName'),
            lastName:  await LocalStorageHelper.get('user.lastName'),
            phone:  await LocalStorageHelper.get('user.phone'),
            newLang: lang,
        });

        LocalStorageHelper.set('user.lang', lang);

        //Set App Lang
        props.i18n.changeLanguage(lang);
        props.setAppLanguage(lang);

    }
    
    const [profilePopoverState, setProfilePopover] = useState({ showPopover: false, event: undefined });


    let unreadMessages = 0;

    const {push_notifications_messages: messages} = props.app;
    const { i18n } = props;

    for (const i in messages) {
        if (messages[i].is_read === 0) {
            unreadMessages++;  
        }
    }

    const toggleMenuClass = () => {	
        document.documentElement.classList.toggle('menu-open');
    };

    if (!langState) {
        return null;
    }

    return (
        <>
            <IonHeader className="app-header ion-no-border">
                <div className="app-header__wrapper">
                    <div slot="start">
                        <Link to="/dashboard">
                            <Logo className="app-header__logo"/>
                        </Link>
                    </div>
                    <div slot="end">
                        <IonGrid>
                            <IonRow className="ion-align-items-center">
                                {/* <IonCol className="ion-padding-start">
                                    <IonButton color="icon">
                                        <svg className="icon"><use xlinkHref="#icon-add"></use></svg>
                                        <span className="button__text">Add device</span>
                                    </IonButton>
                                </IonCol> */}
                                <IonCol className="ion-padding-start">
                                    <IonButton color="icon-only" onClick={
                                        (e) => {
                                            e.persist();
                                            setProfilePopover({ showPopover: true, event: e })
                                        }}
                                    >
                                        <svg className="icon icon-24"><use xlinkHref="#icon-account-circle"></use></svg>
                                    </IonButton>
                                    <IonPopover
                                        cssClass='popover'
                                        event={profilePopoverState.event}
                                        isOpen={profilePopoverState.showPopover}
                                        onDidDismiss={() => setProfilePopover({ showPopover: false, event: undefined })}
                                    >
                                        <IonList>
                                            <IonItem
                                                lines="full"
                                                button
                                                routerLink="/account-details"
                                                onClick={(e) =>
                                                    setTimeout(() => {
                                                        setProfilePopover({
                                                            showPopover: false,
                                                            event: undefined
                                                        })
                                                    }, 1)
                                                }
                                            >
                                                <svg><use xlinkHref="#icon-pencil"></use></svg>
                                                <strong>{props.t('account_details')}</strong>
                                            </IonItem>
                                            <IonItem
                                                lines="full"
                                                button
                                                onClick={(e) => {
                                                    setTimeout(() => {
                                                        setProfilePopover({
                                                            showPopover: false,
                                                            event: undefined
                                                        })
                                                    }, 1);

                                                    setTimeout(()=>{
                                                        logOut();
                                                    }, 100)
                                                    
                                                }
                                                }
                                            >
                                                <IonButton color="icon">
                                                    <span className="button__text">{props.t('logout')}</span>
                                                    <svg className="icon"><use xlinkHref="#icon-logout"></use></svg>
                                                </IonButton>
                                            </IonItem>
                                        </IonList>
                                    </IonPopover>
                                </IonCol>
                                <IonCol className="ion-padding-start">
                                    <IonButton color="icon-only" routerLink="/notifications">
                                        <svg className="icon icon-24"><use xlinkHref="#icon-notifications"></use></svg>
                                        {unreadMessages > 0 && 
                                            <span className="button__count">{unreadMessages}</span>
                                        }
                                    </IonButton>
                                </IonCol>
                                <IonCol className="ion-padding-start">
                                    <Select
                                        options={languages.map(lang => {
                                            return {value: lang, label: props.t(`${lang}_s`)};
                                        })}
                                        className="language-selector"
                                        defaultValue={{ label: props.t(`${langState}_s`), value: langState}}
                                        isSearchable={false}
                                        onChange={value => setLanguage(value.value)}
                                    />                                    
                                </IonCol>
                                <IonCol className="menu-button-col">
                                    <button className="menu-button" onClick={toggleMenuClass} aria-label="Меню">
                                        <span className="menu-button__line"></span>
                                        <span className="menu-button__line"></span>
                                        <span className="menu-button__line"></span>
                                    </button>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </div>
                </div>
            </IonHeader>
        </>
    );
};

const mapStateToProps = state => {
    return {
        app: state.app,
    }
}


export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        userLogin,
        fetchDevices,
        fetchGroups,
        setAppLanguage
    }),
    withRouter
)(Header);