import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonFooter, IonToolbar, IonButton } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { setupIonicReact } from '@ionic/react';
import { connect } from 'react-redux';
import { compose } from 'redux'
import { withTranslation } from 'react-i18next';

//Import pages
import Welcome from './pages/phoneApp/Welcome';
import SingUp from './pages/phoneApp/SignUp';
import Login from './pages/phoneApp/Login';
import ForgottenPassword from './pages/phoneApp/ForgottenPassword';
import ChangePassword from './pages/phoneApp/ChangePassword';
import Dashboard from './pages/phoneApp/Dashboard';
import DeviceCN05UV from './pages/phoneApp/DeviceCN05UV';
import DeviceCN05UVSettings from './pages/phoneApp/DeviceCN05UVSettings';
import DeviceCN05UVProgram from './pages/phoneApp/DeviceCN05UVProgram';
import TController from './pages/phoneApp/TController';
import WorldClock from './pages/phoneApp/WorldClock';
import AddProgram from './pages/phoneApp/AddProgram';
import EditProgram from './pages/phoneApp/EditProgram';
import AddDevice from './pages/phoneApp/AddDevice';
import ChooseDeviceGroup from './pages/phoneApp/ChooseDeviceGroup';
import AddConvector from './pages/phoneApp/AddConvector';
import AddBoiler from './pages/phoneApp/AddBoiler';
import AddHeatPump from './pages/phoneApp/AddHeatPump';
import ChooseDevice from './pages/phoneApp/ChooseDevice';
import SetupChecklist from './pages/phoneApp/SetupChecklist';
import UnsuccessfulRegstriration from './pages/phoneApp/UnsuccessfulRegstriration';
import LocationServices from './pages/phoneApp/LocationServices';
import ConnectToWifi from './pages/phoneApp/ConnectToWifi';
import WifiPassword from './pages/phoneApp/WifiPassword';
import ChangeWifi from './pages/phoneApp/ChangeWifi';
import DeviceSettings from './pages/phoneApp/DeviceSettings';
import Finish from './pages/phoneApp/Finish';
import Statistics from './pages/phoneApp/Statistics';
import NameDevice from './pages/phoneApp/NameDevice';
import DeleteDevice from './pages/phoneApp/DeleteDevice';
import AddToGroup from './pages/phoneApp/AddToGroup';
import EcoTemp from './pages/phoneApp/EcoTemp';
import ComfortTemp from './pages/phoneApp/ComfortTemp';
import SleepMode from './pages/phoneApp/SleepMode';
import DelayedStart from './pages/phoneApp/DelayedStart';
import ChangeDeviceWifi from './pages/phoneApp/ChangeDeviceWifi';
import Settings from './pages/phoneApp/Settings';
import AccountDetails from './pages/phoneApp/AccountDetails';
import OldAppDetails from './pages/phoneApp/OldAppDetails';
import OldAppForgottenPassword from './pages/phoneApp/OldAppForgottenPassword';
import PasswordDetails from './pages/phoneApp/PasswordDetails';
import Notifications from './pages/phoneApp/Notifications';
import ViewNotification from './pages/phoneApp/ViewNotification';
import CreateGroup from './pages/phoneApp/CreateGroup';
import EditGroup from './pages/phoneApp/EditGroup';
import Debug from './pages/phoneApp/Debug';
import DeviceInfo from './pages/phoneApp/DeviceInfo';
import LocalUsage from './pages/phoneApp/LocalUsage';
import StopLocalUsage from './pages/phoneApp/StopLocalUsage';
import Tutorials from './pages/phoneApp/Tutorials';
import DevicesTutorials from './pages/phoneApp/DevicesTutorials';
import ConvectorsTutorials from './pages/phoneApp/ConvectorsTutorials';
import BoilersTutorials from './pages/phoneApp/BoilersTutorials';
import ModelTutorials from './pages/phoneApp/ModelTutorials';
import HeatPumpsTutorials from './pages/phoneApp/HeatPumpsTutorials';
import AppInfo from './pages/phoneApp/AppInfo';

import ConvectorApiV1 from './pages/phoneApp/oldDevices/convectors/apiv1/ConvectorApiV1'
import BoilerApiV1 from './pages/phoneApp/oldDevices/boilers/apiv1/BoilerApiV1'
import BoilerApiV2 from './pages/phoneApp/oldDevices/boilers/apiv2/BoilerApiV2'

import NameOldDevice from './pages/phoneApp/oldDevices/NameDevice'
import StatisticsOldDevice from './pages/phoneApp/oldDevices/Statistics'
import ErrorsOldDevice from './pages/phoneApp/oldDevices/Errors'
import CalculatorGraphicOldDevice from './pages/phoneApp/oldDevices/CalculatorGraphic'
import CalculatorStatisticsOldDevice from './pages/phoneApp/oldDevices/CalculatorStatistics'
import OldDeviceInfo from './pages/phoneApp/oldDevices/DeviceInfo'
import OldDeviceSettings from './pages/phoneApp/oldDevices/OldDeviceSettings'
import OldDeviceWorldClock from './pages/phoneApp/oldDevices/WorldClock'
import OldDeviceTController from './pages/phoneApp/oldDevices/TController'
import OldDeviceProgram from './pages/phoneApp/oldDevices/Program'
import OldDeviceAddProgram from './pages/phoneApp/oldDevices/AddProgram'
import OldDeviceSleepMode from './pages/phoneApp/oldDevices/SleepMode'
import OldDeviceDelayedStart from './pages/phoneApp/oldDevices/DelayedStart'
import OldDeviceVacation from './pages/phoneApp/oldDevices/Vacation'

import OldDeviceInfoAPIV2 from './pages/phoneApp/oldDevices/boilers/apiv2/DeviceInfoAPIV2'
import OldDeviceSettingsAPIV2 from './pages/phoneApp/oldDevices/boilers/apiv2/OldDeviceSettingsAPIV2'
import OldDeviceVacationAPIV2 from './pages/phoneApp/oldDevices/boilers/apiv2/VacationAPIV2'
import OldDeviceWorldClockAPIV2 from './pages/phoneApp/oldDevices/boilers/apiv2/WorldClockAPIV2'
import OldDeviceProgramAPIV2 from './pages/phoneApp/oldDevices/boilers/apiv2/ProgramAPIV2'
import OldDeviceAddProgramAPIV2 from './pages/phoneApp/oldDevices/boilers/apiv2/AddProgramAPIV2'
import CalculatorGraphicAPIV2 from './pages/phoneApp/oldDevices/boilers/apiv2/CalculatorGraphic'
import CalculatorStatisticsAPIV2 from './pages/phoneApp/oldDevices/boilers/apiv2/CalculatorStatistics'

import CN06ChooseDevice from './pages/phoneApp/addDevice/CN06/ChooseDevice';
import CN06ChangeWifi from './pages/phoneApp/addDevice/CN06/ChangeWifi';
import CN06WifiPassword from './pages/phoneApp/addDevice/CN06/WifiPassword';
import CN06ConnectToWifi from './pages/phoneApp/addDevice/CN06/ConnectToWifi';
import CN06DeviceSettings from './pages/phoneApp/addDevice/CN06/DeviceSettings';
import CN06Finish from './pages/phoneApp/addDevice/CN06/Finish';

import V1ChooseDevice from './pages/phoneApp/addDevice/V1/ChooseDevice';
import V1ChangeWifi from './pages/phoneApp/addDevice/V1/ChangeWifi';
import V1WifiPassword from './pages/phoneApp/addDevice/V1/WifiPassword';
import V1DeviceSettings from './pages/phoneApp/addDevice/V1/DeviceSettings';
import V1ProtectPassword from './pages/phoneApp/addDevice/V1/ProtectPassword';

import V2ChooseDevice from './pages/phoneApp/addDevice/V2/ChooseDevice';
import V2ChangeWifi from './pages/phoneApp/addDevice/V2/ChangeWifi';
import V2WifiPassword from './pages/phoneApp/addDevice/V2/WifiPassword';
import V2DeviceSettings from './pages/phoneApp/addDevice/V2/DeviceSettings';

import OldAppChooseDevice from './pages/phoneApp/addDevice/oldApp/ChooseDevice';
import OldAppAccountSettings from './pages/phoneApp/addDevice/oldApp/OldAppAccountSettings';
import Bellislimo from './pages/phoneApp/addDevice/oldApp/Bellislimo';
import HeatEcoConvector from './pages/phoneApp/addDevice/oldApp/HeatEcoConvector';
import ModecoC21 from './pages/phoneApp/addDevice/oldApp/ModecoC21';
import ModecoC22 from './pages/phoneApp/addDevice/oldApp/ModecoC22';

//Helpers
import InitialSetttings from './init';
import Loader from './pages/phoneApp/components/loading/Loading';
import LocalStorage from './helpers/LocalStorageHelper';

//IMPORT ALL EXTERNAL ROUTES
import HPWH411260Routes from './pages/phoneApp/HPWH4_11_260/Routes';
import E32Routes from './pages/phoneApp/E32/Routes';
import B15Routes from './pages/phoneApp/B15/Routes';
import ProductionModeRoutes from './pages/phoneApp/addDevice/productionMode/Routes';
import ProvisioningError from './pages/phoneApp/ProvisioningError';

setupIonicReact({
  mode: 'ios',
  swipeBackEnabled: false 
});

class App extends React.Component {

  state = {
      lang: null
  }

  componentDidMount = () => {

    const initialSetttings = new InitialSetttings();
    initialSetttings.init();  
    initialSetttings.initAppListeners();

    setTimeout(async () => {
        const lng = await LocalStorage.get('user.lang');
        this.setState({lang: lng});
    }, 1000)
   
  }

  render = () => {

    const { isLogged } = this.props.user; 

    let rtl = "";
    if (this.state.lang && this.state.lang == 'he') {
        rtl = "rtl";
    }

    return (
      <React.Fragment>
      <IonApp className="scanner-hide" style={{direction: rtl}}>
        <IonReactRouter>

            <HPWH411260Routes />
            <E32Routes/>
            <B15Routes/>
            <ProductionModeRoutes/>

            <Switch>

                <Route exact path="/welcome" component={Welcome}/>
                <Route exact path="/signup" component={SingUp}/>
                <Route exact path="/login" component={Login}/>
                <Route exact path="/forgotten-password" component={ForgottenPassword}/>
                <Route exact path="/changePassword/:token" component={ChangePassword} />
                <Route exact path="/dashboard" component={Dashboard}/>
                <Route exact path="/add-device" component={AddDevice}/>
                <Route exact path="/choose-device-group" component={ChooseDeviceGroup}/>
                <Route exact path="/add-convector" component={AddConvector}/>
                <Route exact path="/add-boiler" component={AddBoiler}/>
                <Route exact path="/add-heat-pump" component={AddHeatPump}/>
                <Route exact path="/choose-device" component={ChooseDevice}/>
                <Route exact path="/setup-checklist" component={SetupChecklist}/>
                <Route exact path="/unsuccessful-regstriration" component={UnsuccessfulRegstriration}/>
                <Route exact path="/provisioning-error" component={ProvisioningError}/>
                <Route exact path="/location-services" component={LocationServices}/>
                <Route exact path="/connect-to-wifi" component={ConnectToWifi}/>
                <Route exact path="/change-wifi/:ssid?" component={ChangeWifi}/>
                <Route exact path="/wifi-password" component={WifiPassword}/>
                <Route exact path="/device-settings" component={DeviceSettings}/>
                <Route exact path="/finish" component={Finish}/>
                <Route exact path="/settings" component={Settings}/>
                <Route exact path="/app-info" component={AppInfo}/>
                <Route exact path="/account-details" component={AccountDetails}/>
                <Route exact path="/old-app-details" component={OldAppDetails}/>
                <Route exact path="/old-app-forgotten-password" component={OldAppForgottenPassword}/>
                <Route exact path="/password-details" component={PasswordDetails}/>
                <Route exact path="/notifications" component={Notifications}/>
                <Route exact path="/notification/:message_id" component={ViewNotification}/>
                <Route exact path="/create-group" component={CreateGroup}/>
                <Route exact path="/edit-group/:id" component={EditGroup}/>
                <Route exact path="/debug" component={Debug}/>
                <Route exact path="/tutorials/:groupID?/:modelID?" component={Tutorials}/>
                <Route exact path="/devices-tutorials" component={DevicesTutorials}/>
                <Route exact path="/convectors-tutorials" component={ConvectorsTutorials}/>
                <Route exact path="/boilers-tutorials" component={BoilersTutorials}/>
                <Route exact path="/heat-pumps-tutorials" component={HeatPumpsTutorials}/>
                <Route exact path="/model-tutorials/:model" component={ModelTutorials}/>

                {/* Add Device */}

                {/* CN06 */}
                <Route exact path="/cn06/choose-device" component={CN06ChooseDevice}/>
                <Route exact path="/cn06/change-wifi/:ssid?" component={CN06ChangeWifi}/>
                <Route exact path="/cn06/wifi-password" component={CN06WifiPassword}/>
                <Route exact path="/cn06/connect-to-wifi" component={CN06ConnectToWifi}/>
                <Route exact path="/cn06/device-settings" component={CN06DeviceSettings}/>
                <Route exact path="/cn06/finish" component={CN06Finish}/>

                {/* V1 */}
                <Route exact path="/v1/choose-device" component={V1ChooseDevice}/>
                <Route exact path="/v1/change-wifi/:ssid?" component={V1ChangeWifi}/>
                <Route exact path="/v1/wifi-password" component={V1WifiPassword}/>
                <Route exact path="/v1/protect-password" component={V1ProtectPassword}/>
                <Route exact path="/v1/device-settings" component={V1DeviceSettings}/>

                {/* V2 */}
                <Route exact path="/v2/choose-device" component={V2ChooseDevice}/>
                <Route exact path="/v2/change-wifi/:ssid?" component={V2ChangeWifi}/>
                <Route exact path="/v2/wifi-password" component={V2WifiPassword}/>
                <Route exact path="/v2/device-settings" component={V2DeviceSettings}/>

                {/* Old Device */}
                <Route exact path="/old/choose-device" component={OldAppChooseDevice}/>
                <Route exact path="/old/old-app-account-settings" component={OldAppAccountSettings}/>
                <Route exact path="/old/bellislimo" component={Bellislimo}/>
                <Route exact path="/old/heatecoconvector" component={HeatEcoConvector}/>
                <Route exact path="/old/modecoc21" component={ModecoC21}/>
                <Route exact path="/old/modecoc22" component={ModecoC22}/>

                {/* TO DO: These routes are for model cn05uv */}
                <Route exact path="/device/cn05uv/:mac_address" component={DeviceCN05UV}/>
                <Route exact path="/device/program/cn05uv/:mac_address/:day?" component={DeviceCN05UVProgram}/>
                <Route exact path="/device/program/add-program/:model/:mac_address/:day" component={AddProgram}/>
                <Route exact path="/device/program/edit-program/:model/:mac_address/:key" component={EditProgram}/>

                
                {/* Routes for convector apiv1 */}
                <Route exact path="/convector/apiv1/:model_name/:id" component={ConvectorApiV1}/>

                {/* Routes for boiler apiv1 */}
                <Route exact path="/boiler/apiv1/:model_name/:id" component={BoilerApiV1}/>

                {/* Routes for boiler apiv2 */}
                <Route exact path="/boiler/apiv2/:model_name/:id" component={BoilerApiV2}/>
                <Route exact path="/old/apiv2/device-info/:model_name/:id" component={OldDeviceInfoAPIV2}/>
                <Route exact path="/old/apiv2/device-settings/:model_name/:id" component={OldDeviceSettingsAPIV2}/>
                <Route exact path="/old/apiv2/vacation/:model_name/:id" component={OldDeviceVacationAPIV2}/>  
                <Route exact path="/old/apiv2/world-clock/:model_name/:id" component={OldDeviceWorldClockAPIV2}/>
                <Route exact path="/old/apiv2/program/:model_name/:id/:programsCount/:index/:day?" component={OldDeviceProgramAPIV2}/>
                <Route exact path="/old/apiv2/program/add-program/:model_name/:id/:day/:programsCount/:index" component={OldDeviceAddProgramAPIV2}/>
                <Route exact path="/old/apiv2/calculator-graphic/:model_name/:id" component={CalculatorGraphicAPIV2}/>
                <Route exact path="/old/apiv2/calculator-statistics/:model_name/:id" component={CalculatorStatisticsAPIV2}/>
                

                {/* Routes for all old devices */}
                <Route exact path="/old/name-device/:model_name/:id" component={NameOldDevice}/>
                <Route exact path="/old/statistics/:model_name/:id" component={StatisticsOldDevice}/>
                <Route exact path="/old/error/:model_name/:id" component={ErrorsOldDevice}/>
                <Route exact path="/old/calculator-graphic/:model_name/:id" component={CalculatorGraphicOldDevice}/>
                <Route exact path="/old/calculator-statistics/:model_name/:id" component={CalculatorStatisticsOldDevice}/>
                <Route exact path="/old/device-info/:model_name/:id" component={OldDeviceInfo}/>
                <Route exact path="/old/device-settings/:model_name/:id" component={OldDeviceSettings}/>
                <Route exact path="/old/world-clock/:model_name/:id" component={OldDeviceWorldClock}/>
                <Route exact path="/old/t-controller/:model_name/:id" component={OldDeviceTController}/>
                <Route exact path="/old/sleep/:model_name/:id" component={OldDeviceSleepMode}/>
                <Route exact path="/old/delayed-start/:model_name/:id" component={OldDeviceDelayedStart}/>   
                <Route exact path="/old/vacation/:model_name/:id" component={OldDeviceVacation}/>   
                <Route exact path="/old/program/:model_name/:id/:programsCount/:index/:day?" component={OldDeviceProgram}/>
                <Route exact path="/old/program/add-program/:model_name/:id/:day/:programsCount/:index" component={OldDeviceAddProgram}/>

                {/* TO DO: These routes are for all devices maybe ? */}
                <Route exact path="/device-settings/:mac_address" component={DeviceCN05UVSettings}/>
                <Route exact path="/t-controller/:mac_address" component={TController}/>
                <Route exact path="/world-clock/:mac_address" component={WorldClock}/>
                <Route exact path="/change-device-wifi/:mac_address" component={ChangeDeviceWifi}/>
                <Route exact path="/statistics/:mac_address" component={Statistics}/>
                <Route exact path="/name-device/:mac_address" component={NameDevice}/>
                <Route exact path="/delete-device/:mac_address" component={DeleteDevice}/>
                <Route exact path="/add-to-group/:action/:mac_address" component={AddToGroup}/>
                <Route exact path="/eco-temp/:mac_address" component={EcoTemp}/>
                <Route exact path="/comfort-temp/:mac_address" component={ComfortTemp}/>
                <Route exact path="/sleep/:mac_address" component={SleepMode}/>
                <Route exact path="/delayed-start/:mac_address" component={DelayedStart}/>
                <Route exact path="/device-info/:mac_address" component={DeviceInfo}/>
                <Route exact path="/local-usage/:mac_address" component={LocalUsage}/>
                <Route exact path="/stop-local-usage/:mac_address" component={StopLocalUsage}/>

           
            </Switch>
            {isLogged === true ? <Redirect exact path="/" to="/dashboard" /> : <Redirect exact path="/" to="/welcome" />}
        
        </IonReactRouter>

        <Loader show={this.props.app.old_devices_loader}/>
        <Loader show={this.props.app.show_request_loader}/>
      </IonApp>

        <div className="scanner-ui">
            <div className="buttons-list" style={{marginTop: '10px'}}>
                <div className="buttons-list__item">
                    <IonButton expand="block" shape="round" size="large" onClick={()=> document.body.classList.remove("qrscanner")}>{this.props.t('close_camera')}</IonButton>
                </div>
            </div>
        </div>
      </React.Fragment>  
    );
  }
  
};

const mapStateToProps = state => {

  return {
    user: state.user,
    app: state.app
  }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(App);
