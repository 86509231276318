import {
    IonPage,
    IonContent,
    IonFooter,
    IonToolbar,
    IonButton,
    IonAlert
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import HeaderWithPopoverMenu2 from './components/header/HeaderWithPopoverMenu2';
import ToastMessage from './components/toast/ToastMessage';
import Loading from './components/loading/Loading';

import RestHelper from '../../helpers/RestHelper';
import InitialSetttings from '../../init';
import { setAppMessage } from '../../redux/actions/AppAction';

class AddToGroup extends React.Component {

    constructor(props) {
        super(props);

        this.groups = null;
        this.mac = null;
        this.action = null;
        this.RestHelper = null;
        this.InitialSetttings = null;
        this.state = {
            messageColor: "warning",
            showAlert: false,
            groupName: "",
            groupID: 0,
            showLoading: false
        }
    }

    componentDidMount = () => {
        this.RestHelper = new RestHelper();
        this.InitialSetttings = new InitialSetttings();
    }

    handleAdd = async group_id => {

        this.setState({showLoading: true});

        const resp = await this.RestHelper.post('/add-device-to-group', {
            group_id,
            mac: this.mac
        });

        if (resp.data.success === false) {

            this.setState({
                messageColor: "warning"
            });  

            this.props.setAppMessage(this.props.t('cannot_add_device_to_group'));  
            
        } else {

            this.setState({
                messageColor: "success"
            });

            this.props.setAppMessage(this.props.t('successfully_addedd_device_to_group'));  

            await this.InitialSetttings.getMyGroups();
        }
        
        this.setState({showLoading: false});
    };

    handleDelete = (id, name) => {

        this.setState({
            showAlert: true,
            groupName: name,
            groupID: id
        })
    }

    renderGroups = () => {
        const groups = [];

        for (const i in this.groups ) {

            const { name, id, devices, image } = this.groups[i];

            groups.push(
                <div className="group-list__item" key={i}>
                    <header className="group-list__header">
                        <div className="group-list__header-left">
                            <img src={image} alt="" />
                        </div>
                        <div className="group-list__header-right">
                            <div className="group-list__title">{name}</div>
                            <div className="group-list__sub-title">
                                {devices.length} {(devices.length > 1 || devices.length === 0)? this.props.t('g_devices') : this.props.t('g_device')} 
                            </div>
                        </div>

                        {(!devices.includes(this.mac) && this.action === 'add') 
                            ? <IonButton color="transparent" className="group-list__button" onClick={ () => this.handleAdd(id) }>
                                <svg className="group-list__button-icon"><use xlinkHref="#icon-plus"></use></svg>
                            </IonButton> 
                            : null 
                        }

                        {this.action === 'edit' && 
                            <IonButton color="transparent" className="group-list__button" routerLink={`/edit-group/${id}`}>
                                <svg className="group-list__button-icon"><use xlinkHref="#icon-pencil"></use></svg>
                            </IonButton> 
                        }

                        {this.action === 'delete' && 
                            <IonButton color="transparent" className="group-list__button" onClick={ () => this.handleDelete(id, name) }>
                                <svg className="group-list__button-icon fill-red"><use xlinkHref="#icon-delete"></use></svg>
                            </IonButton> 
                        }
                    </header>
                </div>
            )
        }

        return groups;
    }

    render = () => {

        const { t, match: {params: { mac_address: mac, action }}, groups } = this.props;

        this.groups = groups;
        this.mac = mac;
        this.action = action;

        const popoverMenu = [
            {
                'title' : t('delete_group'),
                'icon' : 'delete',
                'href': `/add-to-group/delete/${mac}`
            },
            {
                'title' : t('edit_group'),
                'icon' : 'pencil',
                'href': `/add-to-group/edit/${mac}`
            }
        ];

        return (
            <IonPage>
                
                <HeaderWithPopoverMenu2 name={t('add_to_group')} popoverMenu={popoverMenu}/>
                <IonContent className="ion-padding">        
                    
                    <div className="group-list">
                        {this.renderGroups()}
                    </div>
                    
                    <div className="ion-content-push"></div>

                    <ToastMessage color={this.state.messageColor}/>

                    <IonAlert
                        isOpen={this.state.showAlert}
                        onDidDismiss={() => this.setState({showAlert: false})}
                        header={t('delete_group')}
                        message={`${t('do_you_want_to_delete')} ${this.state.groupName}</p>`}
                        buttons={
                            [
                                {
                                    text: t('cancel'),
                                    handler: () => {
                                        this.setState({
                                            showAlert: false
                                        })
                                    }
                                },
                                {
                                    text: t('confirm'),
                                    handler: async () => {

                                        await this.RestHelper.post('/delete-group', {
                                            id: this.state.groupID    
                                        });

                                        this.InitialSetttings.getMyGroups();

                                    }
                                }
                            ]
                        }
                    />
                    <Loading show={this.state.showLoading}/>
                </IonContent>

                <IonFooter className="app-footer ion-no-border">
                    <IonToolbar>
                        <div className="buttons-list">
                            <div className="buttons-list__item">
                                <IonButton expand="block" shape="round" size="large" routerLink='/create-group'>{t('create_group')}</IonButton>
                            </div>
                        </div>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        );
    }
    
};

const mapStateToProps = state => {
    return {
        groups: state.groups
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        setAppMessage
    })
)(AddToGroup)