import {
    IonPage,
    IonContent,
    IonList,
    IonItem,
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import StandartHeader from './components/header/StandartHeader';
import TwoButtons from './components/footer/TwoButtons';
import RangeTime from './components/range/RangeTime';
import RangeStartTemperature from './components/range/RangeStartTemperature';
import DeviceSettingsHelper from '../../helpers/DeviceSettingsHelper';
import {cn05uvSettings} from '../../config/App';

class delayedStart extends React.Component {

    constructor(props) {

        super(props);

        this.mac = null;
        this.device = null;
        this.DeviceSettingsHelper = null

        this.state = {
            time: cn05uvSettings.min_delayed_time,
            temp: cn05uvSettings.min_temp
        }
    
    }

    componentDidMount = () => {
        this.DeviceSettingsHelper = new DeviceSettingsHelper(this.mac);    

        if (this.device !== null && this.device !== undefined && this.device.state.delayedStart) {

            this.setState({
                time: this.device.state.delayedStart.time,
                temp: this.device.state.delayedStart.temp,
            })
        }
    }

    setTime = time => {
        let [hour, min] = time.split(":");
        hour = Number(hour);
        min = Number(min);

        this.setState({
            time: hour*60 + min
        })
    }

    save = () => {

        this.DeviceSettingsHelper.handleDelayedStart({
            time: this.state.time,
            temp: this.state.temp
        }); 
        
        //this.props.history.push(`/device-settings/${this.mac}`);
        this.props.history.goBack();
    }

    render = () => {

        const { t } = this.props;
        this.mac = this.props.match.params.mac_address;

        const {devices} = this.props;

        this.device = devices[this.mac];
        
        if (!this.device || this.device === undefined) {
            return null;
        }

        const { state: {delayedStart} } = this.device;

        return (
            <IonPage>
                
                <StandartHeader name={t('delayed_start')}/>
                <IonContent className="ion-padding"> 

                    <div className="ion-text-center ">
                        <svg className="size-80 mx-auto fill-light-blue mt-20">
                            <use xlinkHref="#icon-clock"></use>
                        </svg>
                    </div>

                    <div className="preferences-list__wrapper">
                        <IonList className="preferences-list preferences-list--editable">
                        <IonItem className="preferences-list__item" lines="none">
                            <div className="preferences-list__inner">
                                <div className="preferences-list__item-middle">
                                    <RangeStartTemperature 
                                        data-min={cn05uvSettings.min_temp} 
                                        data-max={cn05uvSettings.max_temp}  
                                        data-start={(delayedStart && delayedStart.temp !== undefined) ? delayedStart.temp : cn05uvSettings.min_temp} 
                                        data-step="1" 
                                        data-label={t('degree_unit')}
                                        setTemp={temp => this.setState({temp})}
                                    />
                                </div>
                            </div>
                        </IonItem>
                        <IonItem className="preferences-list__item" lines="none">
                            <div className="preferences-list__inner">
                            <div className="preferences-list__item-middle">
                                <RangeTime 
                                    data-min={cn05uvSettings.min_delayed_time} 
                                    data-max={cn05uvSettings.max_delayed_time} 
                                    data-start={(delayedStart && delayedStart.time !== undefined) ? delayedStart.time * 60 : cn05uvSettings.min_delayed_time} 
                                    data-step="3600" 
                                    data-label={t('start_after')} 
                                    setTime={time => this.setTime(time)}
                                />
                            </div>
                            </div>
                        </IonItem>
                        </IonList>
                    </div>
                    <div className="ion-content-push"></div>
                </IonContent>
                <TwoButtons 
                    redirect={`/device-settings/${this.mac}`}
                    save={() => this.save()}
                />
                
            </IonPage>
        );         
    }
    
};

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(delayedStart);