import {
    IonPage,
    IonContent,
    IonButton,
    IonLabel,
    IonList,
    IonItem,
    IonText,
    IonRow,
    IonCol,
    IonModal,
    IonToolbar,
    IonInput
} from '@ionic/react';

import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Collapsible from 'react-collapsible';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'


import Header from './components/header/Header';
import PageHeader from './components/header/PageHeader';
import Footer from './components/footer/Footer';
import TemperatureSlider from './components/range/TemperatureSlider';
import RangeTime from './components/range/RangeTime';
import DeviceCN05UVMenu from './components/menu/DeviceCN05UVMenu';
import ToastMessage from './components/toast/ToastMessage';

import DeviceSettingsHelper from '../../helpers/DeviceSettingsHelper';
import RestHelper from '../../helpers/RestHelper';
import Timezones from '../../config/TimeZones.json';

import {
    updateDeviceState
} from '../../redux/actions/DevicesAction';
import {cn05uvSettings} from '../../config/App';
import { setAppMessage } from '../../redux/actions/AppAction';

import dayjs from 'dayjs';
import timezonePlugin from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';

class DeviceSettings extends React.Component {

    constructor(props) {

        super(props);

        this.mac = null;
        this.device = null;
        this.DeviceSettingsHelper = null
        this.RestHelper = null

        this.state = {
            showDegreesPopupModal: false,
            showPowerPopupModal: false,
            eco: {
                time: 0,
                temp: 0
            },
            comfort: {
                temp: 0
            },
            sleep: {
                time: 0
            },
            delayStart: {
                time: 0,
                temp: 0
            },
            tController: {
                degree: '',
                power: ''
            },
            timeZone: {
                zone: '',
                date: '',
                time: '',
                day: 0
            },
            wifi: {
                passwordShown: false,
                network: '',
                password: null
            }
        }
    }

    componentDidMount = () => {
        this.DeviceSettingsHelper = new DeviceSettingsHelper(this.mac);   
        this.RestHelper = new RestHelper();
    }

    saveEcoMode = () => {

        this.DeviceSettingsHelper.handleEcoTemp({
            temp: this.state.eco.temp !== 0 ? this.state.eco.temp : this.device.state.ecoTemp ? this.device.state.ecoTemp.temp : cn05uvSettings.min_eco_temp,
            time: this.state.eco.time !== 0 ? this.state.eco.time : this.device.state.ecoTemp ? this.device.state.ecoTemp.temp : 0
        }); 

        this.props.setAppMessage(this.props.t('successful_action'));
    }

    saveComfortMode = () => {

        this.DeviceSettingsHelper.handleComfortTemp({
            temp: this.state.comfort.temp ? this.state.comfort.temp : this.device.state.comfortTemp ? this.device.state.comfortTemp.temp : cn05uvSettings.min_comfort_temp
        }); 

        this.props.setAppMessage(this.props.t('successful_action'));
    }

    saveSleepMode = () => {

        this.DeviceSettingsHelper.handleSleepMode({
            time: this.state.sleep.time !== 0 ? this.state.sleep.time : this.device.state.sleepMode ? this.device.state.sleepMode.temp : cn05uvSettings.min_sleep_time / 60,
        });
        
        this.props.setAppMessage(this.props.t('successful_action'));
    }

    saveDelayStartMode = () => {

        this.DeviceSettingsHelper.handleDelayedStart({
            temp: this.state.delayStart.temp !== 0 ? this.state.delayStart.temp : this.device.state.delayedStart ? this.device.state.delayedStart.temp : cn05uvSettings.min_temp,
            time: this.state.delayStart.time !== 0 ? this.state.delayStart.time : this.device.state.delayedStart ? this.device.state.delayedStart.time : cn05uvSettings.min_delayed_time
        }); 

        this.props.setAppMessage(this.props.t('successful_action'));
    }

    saveTcontroller = () => {

        const degree = this.state.tController.degree !== '' ? this.state.tController.degree : this.device.state.TCorrection;
        const power = this.state.tController.power !== '' ? this.state.tController.power : this.device.state.watt;

       this.DeviceSettingsHelper.handleTCorrection(degree);

       this.RestHelper.post('/update-device-power', {
            mac: this.mac,
            power
        });
        
        this.props.setAppMessage(this.props.t('successful_action'));
    }

    saveTimeZoneData = () => {
        
        global.debug(this.state.timeZone);
        let day = this.state.timeZone.day;
        let time = this.state.timeZone.time;

        if (this.state.timeZone.zone === '') {
            const { timezone } = this.props.devices[this.mac];   
            
            const timeZoneObj = this.catchTimeZoneObj(timezone);
            day = timeZoneObj.weekday();
            time = timeZoneObj.format("HH:mm");

        } else {
            //SEND THE NEW TIMEZONE TO CLOUD
            this.RestHelper.post('/update-device-settings', {
                mac: this.mac,
                timezone: this.state.timeZone.zone
            });
        }

        this.DeviceSettingsHelper.handleTimezone({
            day: day,
            time: time,
            timezone: this.state.timeZone.zone !== '' ? this.state.timeZone.zone : this.props.devices[this.mac].timezone,
            date: this.state.timeZone.date !== '' ? this.state.timeZone.date : this.deviceTime.date,
        })

        this.props.setAppMessage(this.props.t('successful_action'));
    }

    saveWifiData = () => {

        this.DeviceSettingsHelper.handleDeviceWifi({
            wifiSSID: this.state.wifi.network !== '' ? this.state.wifi.network : this.wifi_ssid,
            wifiPass: this.state.wifi.password !== null ? this.state.wifi.password : this.wifi_pass,
        })

        this.props.setAppMessage(this.props.t('successful_action'));
    }

    handleTimzoneChange = timezone => {

        const timeZoneObj = this.catchTimeZoneObj(timezone);
         
        this.setState({
            timeZone: {
                ...this.state.timeZone, 
                zone:timezone,
                date: timeZoneObj.format("MMMM D, YYYY"),
                time: timeZoneObj.format("HH:mm"),
                day: timeZoneObj.weekday()
            }
        });
    }

    catchTimeZoneObj = timezone => {

        dayjs.Ls.en.weekStart = 1;
        dayjs.extend(utc);
        dayjs.extend(timezonePlugin);
        dayjs.extend(weekday);
        dayjs.locale('en');

        const utcTime = dayjs.utc().format("YYYY-MM-DD HH:mm");
        const localTime = dayjs().format("YYYY-MM-DD HH:mm");

        const timeZoneObj = dayjs(localTime).tz(timezone);

        return timeZoneObj;
    }


    setDelayStartTime = time => {
        time = this.convertTime(time);
        this.setState({delayStart: {...this.state.delayStart, time}});
    }

    setEcoTime = time => {
        time = this.convertTime(time);
        this.setState({eco: {...this.state.eco, time}});
    }

    setSleepTime = time => {
        time = this.convertTime(time);
        this.setState({sleep: {...this.state.sleep, time}});     
    }

    convertTime = time => {
        let [hour, min] = time.split(":");
        hour = Number(hour);
        min = Number(min);

        return hour*60 + min;
    }

    checkTabSelection = index => {
        if (index === 1) {
            this.DeviceSettingsHelper.handleGetSSID();
        }
    }

    render = () => {
        const { t } = this.props;
        this.mac = this.props.match.params.mac_address;

        const {devices} = this.props;

        this.device = devices[this.mac];
        
        if (!this.device || this.device === undefined) {
            return null;
        }

        const { state: {ecoTemp, comfortTemp, sleepMode, delayedStart, TCorrection, watt, wifiInfo} } = this.device;
        const power = this.device.model_watt.split(",");
        let tControllerDegereeOptions = [];
        let powerOptions = [];
        let timeZoneOptions = [];
        this.wifi_ssid = this.device.wifi_ssid;
        this.wifi_pass = this.device.wifi_pass;
        let wifiInfoLisp = [];
        let wifiOptions = [];

        if (wifiInfo !== undefined) {
            wifiInfoLisp = wifiInfo.split(',');

            wifiInfoLisp.map((value, i) => {
                wifiOptions.push({
                    value,
                    label: value
                })    
            })

            global.debug('WIFI OPTIONS', wifiOptions);
        }
        

        try {
            this.deviceTime = JSON.parse(this.device.time);
        } catch (e) {
            global.debug("Cannot parse device time", e);
        }

        cn05uvSettings.TController.map((value, i) => {
            tControllerDegereeOptions.push({
                value,
                label: `${value} ${t('degree_unit')}`
            })    
        })

        power.map((value, i) => {
            powerOptions.push({
                value,
                label: `${value} ${t('power_unit')}`
            })    
        })

        for (const zone in Timezones) {
            for (const timezone in Timezones[zone]) {
                timeZoneOptions.push({
                    value: timezone,
                    label: timezone    
                })
            }
        }

        return (
            <IonPage>
                <Header />
                <IonContent className="ion-no-padding">
                    <div className="dashboard">
                        <div className="dashboard__wrapper">
                            
                            <DeviceCN05UVMenu activeMenu="deviceSettings" mac={this.props.match.params.mac_address}/>

                            <div className="dashboard__content">
                            
                                <PageHeader name={t('device_settings')} image="settings"/>
        
                                <Tabs forceRenderTabPanel defaultIndex={0} onSelect={index => this.checkTabSelection(index)} className="react-tabs react-tabs--alt">
                                    <TabList>
                                        <Tab>{t('modes')}</Tab>
                                        {/* TO DO: Ask for this option */}
                                        <Tab>{t('change_wifi_network')}</Tab>
                                        <Tab>{t('t_controller')}</Tab>
                                        <Tab>{t('world_clock')}</Tab>
                                    </TabList>
                                    <div className="react-tabs__box">
                                        <TabPanel>
                                            <Collapsible
                                                trigger={`${t('mode')}: ${t('eco')}`}
                                                transitionTime={300}
                                                open={true}
                                            >
                                                <div className="preferences-list preferences-list--sm pt-32 pb-32">
                                                    <div className="preferences-list__wrapper">
                                                        <div className="ion-text-center">
                                                            <svg className="size-80 fill-light-green">
                                                                <use xlinkHref="#icon-eco"></use>
                                                            </svg>
                                                        </div>
                                                        <IonList className="preferences-list preferences-list--editable">
                                                            <IonItem className="preferences-list__item" lines="none">
                                                            <div className="preferences-list__inner">
                                                                <div className="preferences-list__item-middle">
                                                                    <TemperatureSlider 
                                                                        label={t('degree_unit')}
                                                                        disabled={false}
                                                                        currentTemp={(ecoTemp && ecoTemp.temp !== undefined) ? ecoTemp.temp : cn05uvSettings.min_eco_temp} 
                                                                        minTemperature={cn05uvSettings.min_eco_temp}
                                                                        maxTemperature={cn05uvSettings.max_eco_temp}
                                                                        stepTemperature={1}
                                                                        handleTemperature={temp => this.setState({eco: {...this.state.eco, temp}})}
                                                                    />
                                                                </div>
                                                            </div>
                                                            </IonItem>
                                                            <IonItem className="preferences-list__item" lines="none">
                                                            <div className="preferences-list__inner">
                                                                <div className="preferences-list__item-middle">
                                                                    <RangeTime 
                                                                        data-min={cn05uvSettings.min_eco_temp_time} 
                                                                        data-max={cn05uvSettings.max_eco_temp_time} 
                                                                        data-start={(ecoTemp && ecoTemp.time !== undefined) ? ecoTemp.time * 60 : 0} 
                                                                        data-step="1800" 
                                                                        data-label={t('stop_after')} 
                                                                        setTime={time =>  this.setEcoTime(time)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            </IonItem>
                                                            <IonItem className="preferences-list__item" lines="none">
                                                            <div className="ion-text-center" style={{ width: '100%' }}>
                                                                <IonButton color="primary" onClick={this.saveEcoMode}>{t('save')}</IonButton>
                                                            </div>
                                                            </IonItem>
                                                        </IonList>
                                                    </div>
                                                </div>
                                            </Collapsible>
                                            <Collapsible
                                                trigger={`${t('mode')}: ${t('comfort')}`}
                                                transitionTime={300}
                                                open={true}
                                            >
                                                <div className="preferences-list preferences-list--sm pt-32 pb-32">
                                                    <div className="preferences-list__wrapper">
                                                        <div className="ion-text-center">
                                                            <svg className="size-80 fill-light-red">
                                                            <use xlinkHref="#icon-comfort"></use>
                                                            </svg>
                                                        </div>
                                                        <IonList className="preferences-list preferences-list--editable">
                                                            <IonItem className="preferences-list__item" lines="none">
                                                                <div className="preferences-list__inner">
                                                                    <div className="preferences-list__item-middle">
                                                                        <TemperatureSlider 
                                                                            label={t('degree_unit')}
                                                                            disabled={false}
                                                                            currentTemp={(comfortTemp && comfortTemp.temp !== undefined) ? comfortTemp.temp : cn05uvSettings.min_comfort_temp} 
                                                                            minTemperature={cn05uvSettings.min_comfort_temp}
                                                                            maxTemperature={cn05uvSettings.max_comfort_temp}
                                                                            stepTemperature={1}
                                                                            handleTemperature={temp => this.setState({comfort: {...this.state.comfort, temp}})}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </IonItem>
                                                            <IonItem className="preferences-list__item" lines="none">
                                                                <div className="ion-text-center" style={{ width: '100%' }}>
                                                                    <IonButton color="primary" onClick={this.saveComfortMode}>{t('save')}</IonButton>
                                                                </div>
                                                            </IonItem>
                                                        </IonList>
                                                    </div>
                                                </div>
                                            </Collapsible>
                                            <Collapsible
                                                trigger={`${t('mode')}: ${t('sleep')}`}
                                                transitionTime={300}
                                                open={true}
                                            >
                                                <div className="preferences-list preferences-list--sm pt-32 pb-32">
                                                    <div className="preferences-list__wrapper">
                                                        <div className="ion-text-center">
                                                            <svg className="size-80 fill-light-blue">
                                                                <use xlinkHref="#icon-moon"></use>
                                                            </svg>
                                                        </div>
                                                        <IonList className="preferences-list preferences-list--editable">
                                                            <IonItem className="preferences-list__item" lines="none">
                                                                <div className="preferences-list__inner">
                                                                    <div className="preferences-list__item-middle">
                                                                        <RangeTime 
                                                                            data-min={cn05uvSettings.min_sleep_time} 
                                                                            data-max={cn05uvSettings.max_sleep_time} 
                                                                            data-start={(sleepMode && sleepMode.time !== undefined) ? sleepMode.time * 60 : cn05uvSettings.min_sleep_time} 
                                                                            data-step="1800" 
                                                                            data-label={t('stop_after')} 
                                                                            setTime={time => this.setSleepTime(time)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </IonItem>
                                                            <IonItem className="preferences-list__item" lines="none">
                                                                <div className="ion-text-center" style={{ width: '100%' }}>
                                                                    <IonButton color="primary" onClick={this.saveSleepMode}>{t('save')}</IonButton>
                                                                </div>
                                                            </IonItem>
                                                        </IonList>
                                                    </div>
                                                </div>
                                            </Collapsible>
                                            <Collapsible
                                                trigger={`${t('mode')}: ${t('delayed_start')}`}
                                                transitionTime={300}
                                                open={true}
                                            >
                                                <div className="preferences-list preferences-list--sm pt-32 pb-32">
                                                    <div className="preferences-list__wrapper">
                                                        <div className="ion-text-center">
                                                            <svg className="size-80 fill-light-purple">
                                                                <use xlinkHref="#icon-clock"></use>
                                                            </svg>
                                                        </div>
                                                        <IonList className="preferences-list preferences-list--editable">
                                                            <IonItem className="preferences-list__item" lines="none">
                                                            <div className="preferences-list__inner">
                                                                <div className="preferences-list__item-middle">
                                                                    <TemperatureSlider 
                                                                        label={t('degree_unit')}
                                                                        disabled={false}
                                                                        currentTemp={(delayedStart && delayedStart.temp !== undefined) ? delayedStart.temp : cn05uvSettings.min_temp} 
                                                                        minTemperature={cn05uvSettings.min_temp}
                                                                        maxTemperature={cn05uvSettings.max_temp}
                                                                        stepTemperature={1}
                                                                        handleTemperature={temp => this.setState({delayStart: {...this.state.delayStart, temp}})}
                                                                    />
                                                                </div>
                                                            </div>
                                                            </IonItem>
                                                            <IonItem className="preferences-list__item" lines="none">
                                                            <div className="preferences-list__inner">
                                                                <div className="preferences-list__item-middle">
                                                                    <RangeTime 
                                                                        data-min={cn05uvSettings.min_delayed_time} 
                                                                        data-max={cn05uvSettings.max_delayed_time} 
                                                                        data-start={(delayedStart && delayedStart.time !== undefined) ? delayedStart.time * 60 : cn05uvSettings.min_delayed_time} 
                                                                        data-step="3600" 
                                                                        data-label={t('start_after')} 
                                                                        setTime={time => this.setDelayStartTime(time)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            </IonItem>
                                                            <IonItem className="preferences-list__item" lines="none">
                                                            <div className="ion-text-center" style={{ width: '100%' }}>
                                                                <IonButton color="primary" onClick={this.saveDelayStartMode}>{t('save')}</IonButton>
                                                            </div>
                                                            </IonItem>
                                                        </IonList>
                                                    </div>
                                                </div>
                                            </Collapsible>
                                        </TabPanel>
                                    <TabPanel>
                                        <div className="gray-box">
                                            <div className="gray-box__header gray-box__header--alt">
                                                {t('wifi_network')}
                                            </div>
                                            <div className="gray-box__body gray-box__body--transparent">
                                                <IonText className="ion-text-center">
                                                    <p style={{ fontSize: '16px', lineHeight: '17px', color: 'black', margin: '0' }}>{t('connect_to_network')}</p>
                                                </IonText>
                                                <div className="pt-20"></div>
                                                <div style={{ maxWidth: '400px', margin: '0 auto' }}>
                                                    <div className="pb-16">
                                                        <IonLabel position="stacked" color="primary" className="form-label">{t('wifi_network')}</IonLabel>
                                                        <IonRow className="ion-align-items-center ion-justify-content-between">
                                                        <IonCol className="p-0">
                                                            <Select
                                                            options={wifiOptions}
                                                            defaultValue={{ 
                                                                label: this.state.wifi.network !== '' ? this.state.wifi.network  : this.wifi_ssid, 
                                                                value: this.state.wifi.network !== '' ? this.state.wifi.network  : this.wifi_ssid
                                                            }}
                                                            isSearchable={false}
                                                            onChange={value => this.setState({wifi: {...this.state.wifi, network: value.value}})}
                                                            />
                                                        </IonCol>
                                                        <IonCol size="2" className="p-0">
                                                            <IonButton color="circle" onClick={() => this.DeviceSettingsHelper.handleGetSSID()}>
                                                                <span className="sr"></span>
                                                                <svg className="icon"><use xlinkHref="#icon-reload"></use></svg>
                                                            </IonButton>
                                                        </IonCol>
                                                        </IonRow>
                                                    </div>
                                                    <div className="pb-16">
                                                        <IonLabel position="stacked" color="primary" className="form-label">{t('password')}</IonLabel>
                                                        <div className="form-password">
                                                            <IonInput 
                                                                type={this.state.wifi.passwordShown ? "text" : "password"} 
                                                                className="form-input" 
                                                                value={this.state.wifi.password !== null ? this.state.wifi.password : this.wifi_pass}
                                                                onIonInput={e => this.setState({wifi: { ...this.state.wifi, password: e.detail.value}})}
                                                            />
                                                            <button type="button" ion-button="true" className="form-password__button" onClick={() => this.setState({wifi: {...this.state.wifi, passwordShown: !this.state.wifi.passwordShown} })}>
                                                                <svg className="form-password__button-icon"><use xlinkHref={`#icon-eye-${this.state.wifi.passwordShown ? 'closed' : 'open'}`}></use></svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="ion-text-center">
                                                        <IonButton color="primary" onClick={this.saveWifiData}>{t('save')}</IonButton>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="gray-box">
                                            <div className="gray-box__header gray-box__header--alt">
                                                {t('t_controller')}
                                            </div>
                                            <div className="gray-box__body gray-box__body--transparent">
                                                <div style={{ maxWidth: '400px', margin: '0 auto' }}>
                                                    <div className="pb-16">
                                                            <IonLabel position="stacked" color="primary" className="form-label">
                                                            {t('degree_celsius')}
                                                            <IonButton color="transparent" onClick={() => this.setState({showDegreesPopupModal: true})}>
                                                                <svg className="size-20 fill-green ml-5"><use xlinkHref="#icon-info"></use></svg>
                                                            </IonButton>
                                                        </IonLabel>
                                                        <Select
                                                            options={tControllerDegereeOptions}
                                                            defaultValue={{
                                                                label: `${this.state.tController.degree != '' ? this.state.tController.degree : TCorrection} ${t('degree_unit')}`,
                                                                value: this.state.tController.degree != '' ? this.state.tController.degree : TCorrection
                                                            }}
                                                            isSearchable={false}
                                                            onChange={value => this.setState({tController: {...this.state.tController, degree: value.value}})}
                                                        />
                                                        <IonModal isOpen={this.state.showDegreesPopupModal}>
                                                            <IonToolbar style={{ paddingRight: '10px' }}>
                                                                <div slot="end">
                                                                <IonButton color="icon-only" onClick={() => this.setState({showDegreesPopupModal: false})}>
                                                                    <svg className="size-14"><use xlinkHref="#icon-close"></use></svg>
                                                                </IonButton>
                                                                </div>
                                                            </IonToolbar>
                                                            {/* TO DO: Ask for text */}
                                                            <div className="ion-padding">
                                                                <IonText>
                                                                <h2 className="ion-text-center">Title</h2>
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dui arcu, dictum laoreet justo quis, sollicitudin hendrerit sapien. Nam velit sem, iaculis sit amet sem a, malesuada accumsan mauris. Nunc elementum elit ac velit fringilla, a dictum magna finibus. Maecenas aliquet vel mauris vitae dictum. Proin nec facilisis lectus, eget malesuada lorem. Nunc ornare, arcu a rhoncus pellentesque, massa diam posuere libero, quis venenatis nunc est quis diam. Etiam sodales placerat velit, lacinia convallis turpis faucibus non. Donec id ipsum dui. Sed ut mauris ipsum. Donec imperdiet lectus turpis, vitae fermentum turpis tristique consequat. Cras at posuere libero. Donec volutpat mauris et turpis ornare mollis. Vestibulum quis tellus eu nulla iaculis ullamcorper eget eget libero. Vivamus id erat vitae enim viverra accumsan.</p>
                                                                </IonText>
                                                            </div>
                                                        </IonModal>
                                                    </div>
                                                    <div className="pb-16">
                                                        <IonLabel position="stacked" color="primary" className="form-label">
                                                            {t('rated_power')} ({t('power_unit')})
                                                            <IonButton color="transparent" onClick={() => this.setState({showPowerPopupModal: true})}>
                                                                <svg className="size-20 fill-green ml-5"><use xlinkHref="#icon-info"></use></svg>
                                                            </IonButton>
                                                        </IonLabel>
                                                        <Select
                                                            options={powerOptions}
                                                            defaultValue={{
                                                                label: `${this.state.tController.power != '' ? this.state.tController.power : watt} ${t('power_unit')}`,
                                                                value: this.state.tController.power != '' ? this.state.tController.power : watt
                                                            }}
                                                            isSearchable={false}
                                                            onChange={value => this.setState({tController: {...this.state.tController, power: value.value}})}
                                                        />
                                                        <IonModal isOpen={this.state.showPowerPopupModal}>
                                                            <IonToolbar style={{ paddingRight: '10px' }}>
                                                                <div slot="end">
                                                                    <IonButton color="icon-only" onClick={() => this.setState({showPowerPopupModal: false})}>
                                                                        <svg className="size-14"><use xlinkHref="#icon-close"></use></svg>
                                                                    </IonButton>
                                                                </div>
                                                            </IonToolbar>
                                                            {/* TO DO: Ask for text */}
                                                            <div className="ion-padding">
                                                                <IonText>
                                                                    <h2 className="ion-text-center">Title 2</h2>
                                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dui arcu, dictum laoreet justo quis, sollicitudin hendrerit sapien. Nam velit sem, iaculis sit amet sem a, malesuada accumsan mauris. Nunc elementum elit ac velit fringilla, a dictum magna finibus. Maecenas aliquet vel mauris vitae dictum. Proin nec facilisis lectus, eget malesuada lorem. Nunc ornare, arcu a rhoncus pellentesque, massa diam posuere libero, quis venenatis nunc est quis diam. Etiam sodales placerat velit, lacinia convallis turpis faucibus non. Donec id ipsum dui. Sed ut mauris ipsum. Donec imperdiet lectus turpis, vitae fermentum turpis tristique consequat. Cras at posuere libero. Donec volutpat mauris et turpis ornare mollis. Vestibulum quis tellus eu nulla iaculis ullamcorper eget eget libero. Vivamus id erat vitae enim viverra accumsan.</p>
                                                                </IonText>
                                                            </div>
                                                        </IonModal>
                                                    </div>
                                                    <div className="ion-text-center">
                                                        <IonButton color="primary" onClick={this.saveTcontroller}>{t('save')}</IonButton>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="gray-box">
                                            <div className="gray-box__header gray-box__header--alt">
                                                {t('world_clock')}
                                            </div>
                                            <div className="gray-box__body gray-box__body--transparent">
                                                <div style={{ maxWidth: '400px', margin: '0 auto' }}>
                                                    <div className="pb-16">
                                                        <IonLabel position="stacked" color="primary" className="form-label">{t('time_zone')}</IonLabel>
                                                        <Select
                                                            options={timeZoneOptions}
                                                            defaultValue={{ 
                                                                value: this.state.timeZone.zone !== '' ? this.state.timeZone.zone : this.device.timezone,
                                                                label: this.state.timeZone.zone !== '' ? this.state.timeZone.zone : this.device.timezone
                                                            }}
                                                            isSearchable={false}
                                                            onChange={e => this.handleTimzoneChange(e.value)}
                                                        />
                                                    </div>
                                                    <div className="pb-16">
                                                        <IonLabel position="stacked" color="primary" className="form-label">{t('current_date')}</IonLabel>
                                                        <IonInput type="text" className="form-input" value={this.state.timeZone.date !== '' ? this.state.timeZone.date : this.deviceTime.date} readonly={true} disabled={true} />
                                                    </div>
                                                    <div className="pb-16">
                                                        <IonLabel position="stacked" color="primary" className="form-label">{t('current_time')}</IonLabel>
                                                        <IonInput type="text" className="form-input" value={this.state.timeZone.time !== '' ? this.state.timeZone.time : this.deviceTime.time} readonly={true} disabled={true} />
                                                    </div>
                                                    <div className="ion-text-center">
                                                        <IonButton color="primary" onClick={this.saveTimeZoneData}>{t('save')}</IonButton>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    </div>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                    <ToastMessage color="success"/>
                </IonContent>
                <Footer />
            </IonPage>
        );
    }    
};

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        updateDeviceState,
        setAppMessage
    })
)(DeviceSettings);