import {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonIcon
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import Header from './components/header/Header';
import PageHeader from './components/header/PageHeader';
import Footer from './components/footer/Footer';
import DashboardMenu from './components/menu/DashboardMenu';
import arrowNext from '../../assets/images/arrow-list.svg';
// import deviceImage_4 from '../../temp-files/2000.jpeg';
// import deviceImage_5 from '../../temp-files/2004.jpeg';
// import deviceImage_6 from '../../temp-files/2002.jpeg';

import deviceImage_4 from '../../temp-files/C21.png';
import deviceImage_5 from '../../temp-files/C22.png';
import deviceImage_6 from '../../temp-files/E31.png';
import deviceImage_7 from '../../temp-files/E32.png';
import deviceImage_8 from '../../temp-files/B15.png';

const BoilersTutorials = props => {

    return (
        <IonPage>
            <Header />
            <IonContent className="ion-no-padding">
                <div className="dashboard">
                    <div className="dashboard__wrapper">

                        <DashboardMenu activeMenu="manuals" />

                        <div className="dashboard__content">
                            
                            <PageHeader name={props.t('choose_boiler')} image="manuals"/>
                            
                            <div className="dashboard-box">
                                <div className="dashboard-box__body">
                                    <IonList className="manuals-list">
                                        <IonItem button routerLink="/model-tutorials/b15" className="manuals-list__item">
                                            <div slot="start">
                                                <img src={deviceImage_8}/>
                                            </div>
                                            {props.t('Boiler B15')}
                                            <IonIcon icon={arrowNext} slot="end"></IonIcon>
                                        </IonItem>
                                        {/* <IonItem button routerLink="/model-tutorials/e32" className="manuals-list__item">
                                            <div slot="start">
                                                <img src={deviceImage_7}/>
                                            </div>
                                            {props.t('Boiler E32')}
                                            <IonIcon icon={arrowNext} slot="end"></IonIcon>
                                        </IonItem> */}
                                        <IonItem button routerLink="/model-tutorials/modecoC21" className="manuals-list__item">
                                            <div slot="start">
                                                <img src={deviceImage_4}/>
                                            </div>
                                            {props.t('Boiler ModEco C21')}
                                            <IonIcon icon={arrowNext} slot="end"></IonIcon>
                                        </IonItem>
                                        <IonItem button routerLink="/model-tutorials/modecoC22" className="manuals-list__item">
                                            <div slot="start">
                                                <img src={deviceImage_5}/>
                                            </div>
                                            {props.t('Boiler ModEco C22')}
                                            <IonIcon icon={arrowNext} slot="end"></IonIcon>
                                        </IonItem>
                                        <IonItem button routerLink="/model-tutorials/belislimo" className="manuals-list__item">
                                            <div slot="start">
                                                <img src={deviceImage_6}/>
                                            </div>
                                            {props.t('Boiler BelliSlimo')}
                                            <IonIcon icon={arrowNext} slot="end"></IonIcon>
                                        </IonItem>
                                    </IonList>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </IonContent>
            <Footer />
        </IonPage>
    );
};

const mapStateToProps = state => {
    return {
        app: state.app
    }
}


export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(BoilersTutorials);
  