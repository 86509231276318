import {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonIcon
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'
import StandartHeader from './components/header/StandartHeader';

import arrowNext from '../../assets/images/arrow-list.svg';

const models = {
    modecoC21: {
        app_instruction: 'https://tesy.com/products',
        appliance_manual: 'https://tesy.com/products/archive/gcv-80-47-24d-c21-ecw',
        trans_key: 'Boiler ModEco C21',
    },
    modecoC22: {
        app_instruction: 'https://tesy.com/products',
        appliance_manual: 'https://tesy.com/products/electric-water-heaters/modeco-series/modeco-cloud',
        trans_key: 'Boiler ModEco C22',
    },
    belislimo: {
        app_instruction: 'https://tesy.com/products',
        appliance_manual: 'https://tesy.com/products/electric-water-heaters/bellislimo-series/bellislimo-cloud',
        trans_key: 'Boiler BelliSlimo',
    },
    e32: {
        app_instruction: 'https://tesy.com/products',
        appliance_manual: '',
        trans_key: 'Boiler E32',
    },
    b15: {
        app_instruction: 'https://tesy.com/products',
        appliance_manual: '',
        trans_key: 'Boiler B15',
    },
    cn06: {
        app_instruction: 'https://tesy.com/products',
        appliance_manual: 'https://tesy.com/web/files/richeditor/iot/cn06as/206403-Instructions-CN06-EA-AS-W-EN_BG_RO_RS-A3.pdf',
        trans_key: 'Convector',
    },
    cn031: {
        app_instruction: 'https://tesy.com/products',
        appliance_manual: 'https://tesy.com/products/heating-appliances/electric-panel-convectors/heateco-cloud-with-control-via-internet-cn-031',
        trans_key: 'Convector CN031',
    },
    cn051: {
        app_instruction: 'https://tesy.com/products',
        appliance_manual: 'https://tesy.com/products/heating-appliances/electric-panel-convectors/liveco-cloud-with-airsafe-cn-051',
        trans_key: 'Convector CN051',
    },
    cn052: {
        app_instruction: 'https://tesy.com/products',
        appliance_manual: 'https://tesy.com/products/heating-appliances/floor-standing-convectors-and-heaters/flooreco-cloud-cn-052',
        trans_key: 'Convector CN052',
    },
    cn03: {
        app_instruction: 'https://tesy.com/products',
        appliance_manual: 'https://tesy.com/products/archive/cn-024-050-eis-wi-fi',
        trans_key: 'Convector HeatEco CN03',
    },
    cn04: {
        app_instruction: 'https://tesy.com/products',
        appliance_manual: 'https://tesy.com/products/heating-appliances/electric-panel-convectors/conveco-with-an-electronic-thermoregulator-cn-04',
        trans_key: '​​​​​​​Convector ConvEco CN04',
    },
    hpwh4_11_260: {
        app_instruction: 'https://tesy.com/products',
        appliance_manual: '',
        trans_key: 'hp_model_AquaThermica',
    }
};

const ModelTutorials = props => {

    const model = models[props.match.params.model];

    return (
        <IonPage>
            <StandartHeader name={props.t('user_manual')}/>
            <IonContent className="ion-padding">

                <IonList className="manuals-list">
                    <IonItem  button href={model.appliance_manual} target="_blank" className="manuals-list__item">
                        <div slot="start">
                            <svg className="manuals-list__icon manuals-list__icon--md"><use xlinkHref={`#icon-file-pdf`}></use></svg>
                        </div>
                        {props.t(model.trans_key)}: {props.t('appliance_manual')}
                        <IonIcon icon={arrowNext} slot="end"></IonIcon>
                    </IonItem>
                </IonList>
                
            </IonContent>
        </IonPage>
    );
};

const mapStateToProps = state => {
    return {
        app: state.app
    }
}


export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(ModelTutorials);
  