
import {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonInput
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Device } from '@capacitor/device';

import OldDevicesSettingsHelper from '../../../helpers/OldDevicesSettingsHelper';
import ConvectorApiV1Menu from './convectors/apiv1/ConvectorApiV1Menu';
import BoilerApiV1Menu from './boilers/apiv1/BoilerApiV1Menu';
import {oldModels} from '../../../config/App';

import Header from '../components/header/Header';
import PageHeader from '../components/header/PageHeader';
import Footer from '../components/footer/Footer';

class DeviceInfo extends React.Component 
{
    state = {
        deviceInfo: {
            MAC: "",
            wsw: ""
        },
        info: null,
        timezone: ''
    }

    componentDidMount = async () => {

        this.OldDevicesSettingsHelper = new OldDevicesSettingsHelper(this.props.match.params.id);
        
        const {data: deviceData} = await this.OldDevicesSettingsHelper.setDeviceStatus('getDevInfo', '');
        const {data: {zonename}} = await this.OldDevicesSettingsHelper.setDeviceStatus('getTZ','');

        const info = await Device.getInfo();

        this.setState({
            deviceInfo: deviceData,
            timezone: zonename,
            info
        })
    }

    render = () => {
        
        if (!this.state.info) {
            return null;
        }

        this.model_name = this.props.match.params.model_name;
        this.model = oldModels[this.model_name]
        
        const { t, devices, match: {params: { id } } } = this.props;
        const device = this.props.devices[id];

        return (
            <IonPage>
                <Header />
                <IonContent className="ion-no-padding">
                    <div className="dashboard">
                        <div className="dashboard__wrapper">

                            {this.model.type == 'boiler' ? 
                                <BoilerApiV1Menu 
                                    activeMenu="deviceInfo" 
                                    model_name={this.props.match.params.model_name} 
                                    device={this.props.devices[this.props.match.params.id]} 
                                    model={oldModels[this.props.match.params.model_name]}
                                />
                            :
                                <ConvectorApiV1Menu 
                                    activeMenu="deviceInfo" 
                                    model_name={this.props.match.params.model_name} 
                                    device={this.props.devices[this.props.match.params.id]} 
                                    model={oldModels[this.props.match.params.model_name]}
                                />
                            }
                            

                            <div className="dashboard__content">
                               
                                <PageHeader name={t('device_info')} image="info"/>

                                <div className="dashboard-box">

                                    <div className="dashboard-box__body">
                            
                                        <IonList>
                                            <IonItem lines="full" className="form-list__item">
                                                <IonLabel position="stacked" color="primary" className="form-label">{t('device_id')}</IonLabel>
                                                <IonInput value={id} readonly/>
                                            </IonItem>

                                            <IonItem lines="full" className="form-list__item">
                                                <IonLabel position="stacked" color="primary" className="form-label">{t('mac_address')}</IonLabel>
                                                <IonInput value={this.state.deviceInfo.MAC} readonly/>
                                            </IonItem>

                                            <IonItem lines="full" className="form-list__item">
                                                <IonLabel position="stacked" color="primary" className="form-label">{t('firmware_version')}</IonLabel>
                                                <IonInput value={this.state.deviceInfo.wsw} readonly/>
                                            </IonItem>

                                            <IonItem lines="full" className="form-list__item">
                                                <IonLabel position="stacked" color="primary" className="form-label">{t('time_zone')}</IonLabel>
                                                <IonInput value={this.state.timezone} readonly/>
                                            </IonItem>
                                        </IonList>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </IonContent>
                    
                <Footer />
                    
            </IonPage>
        ); 
    }
    
}

const mapStateToProps = state => {
    return {
        devices: state.oldDevices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(DeviceInfo);