export const detectDeviceType = model => {

    const heatPumpModels = ['HPWH4_11_200', 'HPWH4_11_260'];

    if (heatPumpModels.includes(model)) {
        return 'HeatPump';
    }

    if (model == 'E32') {
        return 'Boiler_E32';
    }

    if (model == 'B15') {
        return 'Boiler_B15';
    }

    return 'Convector';
}