export const E32_Settings = {
    modes: {
        manual: "man",
        ecoSmart: "eco0",
        ecoComfort: "eco1",
        ecoNight: "eco2",
        p1: "p1",
        p2: "p2",
        p3: "p3",
        vacation: "vac"
    },
    match_modes_to_tesycloud: {
        man: "Manual",
        eco0: "Eco Smart",
        eco1: "Eco Comfort",
        eco2: "Eco Night",
        p1: "Program P1",
        p2: "Program P2",
        p3: "Program P3",
        vac: "Vacation"
    },
    modes_icons: {
        eco0: "eco",
        eco1: "eco-2",
        eco2: "eco-1",
        vac: "palm"
    },
    ecoFunctions: ['eco0', 'eco1', 'eco2'],
}