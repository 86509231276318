import {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonInput,
    IonFooter,
    IonToolbar,
    IonButton,
    IonText,
    IonLabel
} from '@ionic/react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Label,
    Brush,
    ResponsiveContainer
} from "recharts";
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'
import ScrollContainer from 'react-indiana-drag-scroll';

import StandartHeader from '../components/header/StandartHeader';
import { setAppMessage } from '../../../redux/actions/AppAction';
import OldDevicesSettingsHelper from '../../../helpers/OldDevicesSettingsHelper';
import { oldModels, oldMoldel } from '../../../config/App';

const ColorConfig = {
    "red": "#eb445a",
    "green": "#7fb439",
    "gray": "#3b3b3b",
    "gray2": "#92949c"
}

class Statistics extends React.Component {

    state = {
        statistics: {
            temp: [],
            state: []
        }
    }

    componentDidMount = async () => {

        this.OldDevicesSettingsHelper = new OldDevicesSettingsHelper(this.mac);

        const {data: {graph}} = await this.OldDevicesSettingsHelper.getStatistics();
        const statistics = graph[this.mac];

        let tempData = [];
        let stateData = [];

        const stateDataValuesV1 = {
            "HEATING": 2,
            "READY": 1,
            "OFF": 0
        }

        const stateDataValuesV2 = {
            1: "HEATING",
            0: "READY",
        }

        const model = oldModels[this.props.match.params.model_name];
 
        if (statistics != undefined) {
            
            statistics.map(el => {
                let {Temp, Power, state, Time} = el;
                const timeInfo = Time.split(":");

                //tempData.push({name: Time, name_round: `${timeInfo[0]}:00`, pv: Temp});
                tempData.push({name: Time, name_round: Time, temp: Temp});

                //stateData.push({state: stateDataValues[state] ?? state, Time}); 

                if (model.apiVersion == 'apiv2') {

                    let stateName = stateDataValuesV2[state];
                    if (Power == '0') {
                        stateName = 'OFF';
                    }

                    stateData.push({name: Time, name_round: Time, uv: stateDataValuesV1[stateName], am: stateName}); 
                } else {

                    if (Power == 'off') {
                        state = 'OFF';
                    }

                    stateData.push({name: Time, name_round: Time, uv: stateDataValuesV1[state], am: state}); 
                }
                
            }) 

            this.setState({statistics: {temp: tempData, state: stateData}});
        }
    }

    temperatureFormatter = value => {
        return value + "°C";
    };

    stateFormatter = value => {
        global.debug(value);
    }

    CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <div className="custom-tooltip">
              <div className="label">{`${label} / ${payload[0].value}`}</div>
            </div>
          );
        }
      
        return null;
    };
 
    render = () => {

        this.mac = this.props.match.params.id;
        const { t } = this.props;

        const chartHeight = 400;
        const chartLineWidth = 60;
        const chartWidth = this.state.statistics.temp.length * chartLineWidth;
        global.debug(this.state.statistics.state); 
        return (
            <IonPage>
                <StandartHeader name={`${t('statistics')}`}/>
    
                <IonContent className="ion-padding">
                    <ResponsiveContainer debounce={1} width={'100%'} height={chartHeight}>
                        <LineChart
                            width={500}
                            height={chartHeight}
                            data={this.state.statistics.temp}
                            margin={{
                                top: 10,
                                right: 0,
                                left: 0,
                                bottom: 0,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" strokeWidth=".5" vertical={false} />
                            <XAxis 
                                xAxisId={0}
                                dataKey="name"
                                hide={true}
                            />
                            <XAxis 
                                xAxisId={1}
                                height={50}
                                dataKey="name_round"
                                angle={-45}
                                textAnchor="end"
                                fontSize={12}
                                dy={5}
                                dx={2}
                            />
                            <YAxis 
                                yAxisId="left"
                                width={40}
                                dataKey="temp" 
                                unit=" C°"
                                allowDataOverflow={false}
                                tick={{ fontSize: 12, fontWeight: 300, fill: ColorConfig['gray'] }}
                            />
                            <YAxis 
                                hide={true}
                                yAxisId="right"
                                dataKey="uv"
                                orientation="right"
                                allowDataOverflow={false}
                                tick={{ fontSize: 12, fontWeight: 300, fill: ColorConfig['gray'] }}
                            />
                            <Line yAxisId="left" type="monotone" dataKey="temp" dot={false} stroke={ColorConfig['red']} />
                            <Brush dataKey="name_round" height={30} travellerWidth={30} stroke={ColorConfig['green']} />
                            <Tooltip />
                        </LineChart>
                    </ResponsiveContainer>

                    <br /> <br />

                    <ResponsiveContainer debounce={1} width={'100%'} height={chartHeight}>
                        <LineChart
                            width={500}
                            height={chartHeight}
                            data={this.state.statistics.state}
                            class="hide-brush-texts"
                            margin={{
                                top: 10,
                                right: 0,
                                left: 0,
                                bottom: 0,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" strokeWidth=".5" vertical={false} />
                            <XAxis 
                                height={50}
                                dataKey="name"
                                angle={-45}
                                textAnchor="end"
                                fontSize={12}
                                dy={5}
                                dx={2}
                            />
                            <YAxis 
                                yAxisId="right"
                                dataKey="uv"
                                orientation="left"
                                allowDataOverflow={false}
                                tick={{ fontSize: 0 }}
                            >
                                <Label
                                    style={{
                                        fontSize: "12px",
                                        fontWeight: 300, 
                                        fill: ColorConfig['gray']
                                    }}
                                    position="insideTop"
                                    value={"HEATING"} />
                                <Label
                                    style={{
                                        fontSize: "12px",
                                        fontWeight: 300, 
                                        fill: ColorConfig['gray']
                                    }}
                                    position="middle"
                                    value={"READY"} />
                                <Label
                                    style={{
                                        fontSize: "12px",
                                        fontWeight: 300, 
                                        fill: ColorConfig['gray']
                                    }}
                                    position="insideBottom"
                                    value={"OFF"} />
                            </YAxis>
                            <Line yAxisId="right" type="stepAfter" dataKey="uv" dot={false} stroke={ColorConfig['green']} />
                            <Brush dataKey="name" height={30} travellerWidth={30} stroke={ColorConfig['green']} />
                        </LineChart>
                    </ResponsiveContainer>

                    {/* <ResponsiveContainer width={'100%'} height={chartHeight}>
                        <LineChart
                            width={500}
                            height={chartHeight}
                            data={this.state.statistics.state}
                            margin={{
                                top: 10,
                                right: 0,
                                left: 0,
                                bottom: 0,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" strokeWidth=".5" vertical={false} />
                            <XAxis 
                                xAxisId={0}
                                dataKey="name"
                                hide={true}
                            />
                            <XAxis 
                                xAxisId={1}
                                height={50}
                                dataKey="name_round"
                                angle={-45}
                                textAnchor="end"
                                fontSize={12}
                                dy={5}
                                dx={2}
                                allowDuplicatedCategory={false}
                            />
                            <YAxis 
                                hide={true}
                                yAxisId="left"
                                dataKey="uv"
                                orientation="left"
                                allowDataOverflow={false}
                                tick={{ fontSize: 12, fontWeight: 300, fill: ColorConfig['gray'] }}
                            />
                            <YAxis 
                                yAxisId="right2"
                                dataKey="am"
                                type="category"
                                orientation="left"
                                allowDataOverflow={false}
                                allowDuplicatedCategory={false}
                                tickLine={false}
                                tick={{ fontSize: 11, fontWeight: 300, fill: ColorConfig['gray'] }}
                            />
                            <Line yAxisId="left" type="stepBefore" dataKey="uv" dot={false} stroke={ColorConfig['green']} />
                            <Brush dataKey="name_round" height={30} travellerWidth={30} stroke={ColorConfig['green']} />
                        </LineChart>
                    </ResponsiveContainer> */}

                    {/* <ScrollContainer vertical={false}>
                        <LineChart
                            height={chartHeight}
                            width={chartWidth}
                            data={this.state.statistics.temp}
                            margin={{ top: 20, right: 0, left: 0, bottom: 0 }} 
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="Time"
                                domain={["auto", "auto"]}
                            />
                            <YAxis domain={['dataMin-2', 'dataMax+2']} tickFormatter={this.temperatureFormatter} />
                            <Tooltip
                                formatter={this.temperatureFormatter}
                            />
                            <Legend
                                payload={[
                                    // може да се превежда и тва
                                    {
                                        value: this.props.t('currrent_temperature'),
                                        type: "line",
                                        id: "Temp",
                                        color: "#d6bad5"
                                    },
                                ]}
                                style={{padding: '20px'}}
                            />
                            <Line
                                type="monotone"
                                dataKey="Temp"
                                stroke="#d6bad5"
                                activeDot={{ r: 4 }}
                            />
                        </LineChart>
                    </ScrollContainer>   

                    <ScrollContainer vertical={false}>
                        <LineChart
                            height={chartHeight}
                            width={chartWidth}
                            data={this.state.statistics.state}
                            margin={{ top: 20, right: 0, left: 0, bottom: 0 }} 
                        >
                            <CartesianGrid strokeDasharray="3 3" strokeWidth="1"  vertical={false}/>
                            <XAxis
                                dataKey="Time"
                                domain={["auto", "auto"]}
                            />
                            <YAxis dataKey="state" type="category" width={70}/>
                            <Line
                                type="monotone"
                                dataKey="state"
                                stroke="#7fb439"
                                activeDot={{ r: 4 }}
                            />
                            <Tooltip />
                        </LineChart>
                    </ScrollContainer>  */}
                </IonContent>
                    
            </IonPage>
        )
    }
}

const mapStateToProps = state => {
    return {
        devices: state.oldDevices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        setAppMessage
    })
)(Statistics);