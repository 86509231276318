import {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonIcon
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import Header from './components/header/Header';
import PageHeader from './components/header/PageHeader';
import Footer from './components/footer/Footer';
import DashboardMenu from './components/menu/DashboardMenu';
import arrowNext from '../../assets/images/arrow-list.svg';
import convectors from '../../assets/images/convectors_new.jpg';
import boilers from '../../assets/images/boilers_new.jpg';
import heatPumps from '../../assets/images/Heat_pump.jpg';

const DevicesTutorials = props => {

    return (
        <IonPage>
            <Header />
            <IonContent className="ion-no-padding">
                <div className="dashboard">
                    <div className="dashboard__wrapper">

                        <DashboardMenu activeMenu="manuals" />

                        <div className="dashboard__content">
                            
                            <PageHeader name={props.t('user_manual')} image="manuals"/>
                            
                            <div className="dashboard-box">
                                <div className="dashboard-box__body">
                                    <IonList className="manuals-list">
                                        <IonItem button routerLink="/convectors-tutorials" className="manuals-list__item">
                                            <div slot="start">
                                                <img src={convectors}/>
                                            </div>
                                            {props.t('convectors')}
                                            <IonIcon icon={arrowNext} slot="end"></IonIcon>
                                        </IonItem>
                                        <IonItem button routerLink="/boilers-tutorials" className="manuals-list__item">
                                            <div slot="start">
                                                <img src={boilers}/>
                                            </div>
                                            {props.t('boilers')}
                                            <IonIcon icon={arrowNext} slot="end"></IonIcon>
                                        </IonItem>
                                        {/* <IonItem button routerLink="/heat-pumps-tutorials" className="manuals-list__item">
                                            <div slot="start">
                                                <img src={heatPumps}/>
                                            </div>
                                            {props.t('hp_heat_pumps')}
                                            <IonIcon icon={arrowNext} slot="end"></IonIcon>
                                        </IonItem> */}
                                    </IonList>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </IonContent>
            <Footer />
        </IonPage>
    );
};

const mapStateToProps = state => {
    return {
        app: state.app
    }
}


export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(DevicesTutorials);
  