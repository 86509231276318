import {
    IonButton,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import ChangeDeviceMode from './ChangeDeviceMode';

const ProgramModeBody = ({disableDevice, device, mac, program, changeMainMode}) => {

    const { t } = useTranslation();

    const {state: deviceState} = device;

    const [mode, setMode] = useState(null);

    const changeMode = (program) => {
        if (deviceState.mode === program ) {
            setMode("deactivate_current_mode");
        } else {
            setMode(program);
        }
    }

    return (
        <div className="range-buttons js-range-buttons">
            <div className="range-buttons__item">
                <IonButton 
                    shape="round" 
                    size="large" 
                    onClick={() => changeMode(program)}
                >
                    {deviceState.mode === program ? t('deactivate') : t('activate')}
                </IonButton>
            </div>
            <div className="range-buttons__item">
                <IonButton 
                    shape="round" 
                    size="large" 
                    color="help" 
                    routerLink={`/device/program/${device.model.toLowerCase()}/${mac}/${program}`}
                >
                    {t('edit')}
                </IonButton>
            </div>

            <ChangeDeviceMode 
                device={device} 
                newMode={mode} 
                mac={mac}
                cancelChange={mode => setMode(mode)}
                changeMainMode={mode => changeMainMode(mode)}
            />

        </div>
    )
}

export default ProgramModeBody;