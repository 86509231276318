import store from '../../redux/createStore';
import requestToDevice from '../../services/serviceFactory';

class MqqtRequestHelper 
{
    constructor(mac) {

        this.mac = mac;

        const { devices } = store.getState();
        this.device = devices[mac];
    }

    request = (command, payload = {}, requestType = 'request') => {

        global.debug(payload)
        requestToDevice({
            device: this.device,
            macAddr: this.mac,
            payload,
            command,
            requestType
        })
    }
}

export default MqqtRequestHelper;