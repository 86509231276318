import {E32_Settings} from '../config/E32';

export default class E32Helper
{

    constructor(device) {
        this.device = device;
    }

    getMainMode = () => {
        const currentMode = this.device.state.mode;
        const {modes} = E32_Settings;

        if ([modes.p1, modes.p2, modes.p3].includes(currentMode)) {
            return currentMode;
        }

        return modes.manual;
    }

    getImage() {
        const {state: {device_config}} = this.device;

        if (device_config.mount == "H") {
            return `${process.env.PUBLIC_URL}/assets/images/bellislimo-horizontal.jpg`;
        }

        return `${process.env.PUBLIC_URL}/assets/images/bellislimo-vertical.jpg`;
    }

    getShowers = () => {
        const showers = {
            30: {
                H: 3,
                V: 4
            },
            50: {
                H: 2,
                V: 2
            },
            80: {
                H: 3,
                V: 4
            },
            100: {
                H: 3,
                V: 4
            },
        }; 

        const {device_config: {capacity, mount}} = this.device.state;

        return showers[capacity][mount];
    }

    renderShowers = () => {

        let showers = [];

        for (let i = 1; i <= this.device.state.heat_level; i++) {
            showers.push(<svg className="shower" key={`shower_${i}`}><use xlinkHref="#icon-shower"></use></svg>);
        }

        return showers;
    }

    renderShowersProgress = (inner = false) => {

        const { state } = this.device;
        const maxShowers = this.getShowers();
        let showers = [];
        const initialClass = inner ? 'range-shower__item' : 'shower';

        for (let i = 1; i <= maxShowers; i++) {

            let progressClassName = inner ? "" : "shower--grey";

            if (state.stand_by == "no") {
        
                if (state.current_heat_level >= i) {
                    progressClassName = 'range-shower__item--green';
                } else if (state.heat_level >= i && state.heater_state === 'on') {
                    progressClassName = inner ? 'range-shower__item--red' : 'shower--red'
                }
            }

            showers.push(<svg className={`${initialClass} ${progressClassName}`} key={`progress_shower_${i}`}><use xlinkHref="#icon-shower"></use></svg>);
        }

        return showers;
    }

    renderSLiderDigits = () => {

        const maxShowers = this.getShowers();
        let digits = [];

        for (let i = 0; i <= maxShowers; i++) {

            digits.push(<div className={`range-circle__didgit range-circle__didgit--${i}`} key={`slider-digits-${i}`}>{i}</div>);
        }

        return digits;
    }

    getSliderAngles = () => {
        const angles = {
            2: [
                { 'temp': 0, 'angle': -49 },
                { 'temp': 1, 'angle': 45 },
                { 'temp': 2, 'angle': 137 },
            ],
            3: [
                { 'temp': 0, 'angle': -49 },
                { 'temp': 1, 'angle': 45 },
                { 'temp': 2, 'angle': 136 },
                { 'temp': 3, 'angle': 230 },
            ],
            4: [
                { 'temp': 0, 'angle': -49 },
                { 'temp': 1, 'angle': 16.5 },
                { 'temp': 2, 'angle': 90 },
                { 'temp': 3, 'angle': 164 },
                { 'temp': 4, 'angle': 230 },
            ]
        }

        return angles[this.getShowers()];
    }

}