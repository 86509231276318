import {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonTextarea,
    IonButton
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Device } from '@capacitor/device';

import RestHelper from '../../helpers/RestHelper';
import { setAppMessage, clearLogger } from '../../redux/actions/AppAction';

import StandartHeader from './components/header/StandartHeader';
import ToastMessage from './components/toast/ToastMessage';

class Debug extends React.Component {

    showDebugText = text => {

        let textData = '';

        text.map((message, i) => {
            textData += message+"\n\n" ;
        })

        return textData;
    }

    sendLog = async text => {

        const rest = new RestHelper();
        const info = await Device.getInfo();

        const resp = await rest.post('/user-device-log', {
            log: JSON.stringify(text),
            manufacturer: info.manufacturer,
            model: info.model,
            operatingSystem: info.operatingSystem,
            osVersion: info.osVersion,
        });

        this.props.setAppMessage('Log is saved succesfully');  
    }

    render = () => {

        const { t, app: { log } } = this.props;
        const logReverse = log.reverse();

        return (
            <IonPage>
                
                <StandartHeader name="Debug"/>
                <IonContent className="ion-padding"> 
                    <IonList className="notifications-list">

                        {/* {logReverse.map((message, i) => (

                            <IonItem key={i} className="notifications-list__item">
                                <span slot="start">{message}</span>
                            </IonItem>
                        ))} */}

                        <IonItem className="notifications-list__item">
                            <IonButton expand="block" shape="round" size="large" onClick={() => this.sendLog(logReverse)}>{t('save')}</IonButton>
                            <IonButton expand="block" style={{marginLeft: '10px'}} shape="round" size="large" onClick={() => this.props.clearLogger()}>{t('clear_log')}</IonButton>
                        </IonItem>
                        <IonItem className="notifications-list__item">
                            <IonTextarea rows="200" readonly placeholder="Debug message....." value={this.showDebugText(logReverse)}></IonTextarea>
                        </IonItem>
                        
                    </IonList>``
                    <div className="ion-content-push"></div>

                    <ToastMessage color="success"/>
                </IonContent>
            </IonPage>
        );         
    }
    
};

const mapStateToProps = state => {
    return {
        app: state.app
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        setAppMessage,
        clearLogger
    })
)(Debug);