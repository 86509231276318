import {
    IonRefresher,
    IonRefresherContent
} from '@ionic/react';
import React from 'react';
import { chevronDownCircleOutline } from 'ionicons/icons';

const Refresher = props => {
    return (
        <IonRefresher slot="fixed" onIonRefresh={props.doRefresh}>
            <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            refreshingSpinner="circles">
          </IonRefresherContent>
        </IonRefresher> 
    )
}

export default Refresher;