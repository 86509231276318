import {
    CHOOSE_DEVICE,
    CHOOSE_NETWORK,
    CHOOSE_NETWORK_ORIGINAL,
    CHOOSE_NETWORK_PASS,
    SET_MODEL_POWER,
    SET_DEVICE_MAC_ADDRESS,
    SET_DEVICE_MODEL,
    SET_DEVICE_NAME,
    SET_DEVICE_POWER,
    DELETE_NEW_DEVICE,
    SET_DEVICE_AP_PASS,
    SET_DEVICE_WIFI_ARRAY,
    SET_DEVICE_VOLUME
} from '../types/NewDeviceTypes';


export const chooseDevice = ssid => {
    return {
        type: CHOOSE_DEVICE,
        payload: {ssid}
    }
}

export const setDeviceWifiArray = wifi => {
    return {
        type: SET_DEVICE_WIFI_ARRAY,
        payload: {wifi}
    }
}

export const chooseNetwork = ssid => {
    return {
        type: CHOOSE_NETWORK,
        payload: ssid
    }
}

export const chooseNetworkOriginal = ssid => {

    return {
        type: CHOOSE_NETWORK_ORIGINAL,
        payload: {ssid}
    }
}


export const chooseNetworkPass = (password, no_password) => {
    return {
        type: CHOOSE_NETWORK_PASS,
        payload: {password, no_password}
    }
}

export const setModelPower = power => {
    return {
        type: SET_MODEL_POWER,
        payload: {power}
    }
}

export const setDeviceApPassword = password => {
    return {
        type: SET_DEVICE_AP_PASS,
        payload: {password}
    }
}


export const setDeviceMacAddress = mac => {
    return {
        type: SET_DEVICE_MAC_ADDRESS,
        payload: {mac}
    }
}

export const setDeviceModel = model => {
    return {
        type: SET_DEVICE_MODEL,
        payload: {model}
    }
}

export const setDeviceName = name => {
    return {
        type: SET_DEVICE_NAME,
        payload: {name}
    }
}

export const setDevicePower = power => {
    return {
        type: SET_DEVICE_POWER,
        payload: {power}
    }
}

export const setDeviceVolume = volume => {
    return {
        type: SET_DEVICE_VOLUME,
        payload: {volume}
    }
}

export const deleteNewDevice = () => {
    return {
        type: DELETE_NEW_DEVICE,
        payload: {}
    }
}