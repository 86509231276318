import {
    IonContent,
    IonFooter,
    IonButton,
    IonPage,
    IonToolbar,
    IonText,
    IonSelect,
    IonSelectOption,
    IonLabel,
    IonItem,
    IonInput,
} from '@ionic/react';

import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { Device } from '@capacitor/device';
import dayjs from 'dayjs';


import {
    chooseDevice,
    setDeviceWifiArray,
    chooseNetwork
} from '../../../../redux/actions/NewDeviceAction';
import { setAppMessage, logger } from '../../../../redux/actions/AppAction';

import StandartHeader from '../../components/header/StandartHeader';
import ToastMessage from '../../components/toast/ToastMessage';
import Loading from '../../components/loading/Loading';
import WifiHelper from '../../../../helpers/WifiHelper';
import AppHelper from '../../../../helpers/AppHelper';
import chooseDeviceImage from '../../../../files/chooseDevice.svg';

class ChooseDevice extends React.Component {

    state = {
        networks: [],
        selectedNetwork: "",
        appInfo: {},
        build: null,
        version: null,
        showLoading: false
    }

    componentDidMount = async () => {
        //this.scanForNetworks();
        const info = await Device.getInfo();
        const {build, version} = await AppHelper.getAppVersion();

        this.setState({
            appInfo: info,
            build,
            version
        })
    }

    scanForNetworks = async () => {
        try {
            const wifiNetworksResult = await WifiHelper.scanForWifiNetworks();

            this.setState({
                networks: wifiNetworksResult
            });
        } catch (e) {
            //TO DO: Ask what to do here IOS 
            global.debug("Can't scan for networks", e);
        }
    }


    /**
     * Remember the origin app netwokr
     * Connect to static device wifi name (IOS / Android)
     * Extract wifi array from device
     * Set device data into redux state 
     */

    continue = async () => {

        this.props.logger(dayjs().format("HH:mm:ss")+" STEP 1: Connect to device AP (Tesy-CN06AS) \n\n");
        this.setState({
            showLoading: true
        });

        //TO DO: Extract the right data from settings for the right device

        if (this.state.selectedNetwork !== '') {

            this.props.chooseDevice(this.state.selectedNetwork);
            
            try {

                this.props.logger(`1.Trying to connect from phone: ${this.state.appInfo.manufacturer} ${this.state.appInfo.model}, os version: ${this.state.appInfo.osVersion}, app version: ${this.state.version} (${this.state.build})`);

                //await WifiHelper.connectToNetwork(this.state.selectedNetwork, "");

                if (this.state.appInfo.platform === 'ios') {
                    await WifiHelper.connectToNetworkIOS(this.state.selectedNetwork);
                } else {
                    await WifiHelper.connectToNetwork(this.state.selectedNetwork, "");
                }

                this.setState({
                    showLoading: false
                });

                this.props.history.push('/v2/change-wifi');

            } catch (e) {

                this.setState({
                    showLoading: false
                })

                this.props.logger(`ChooseDevice: Cannot connect to device network: ${e}`);
                this.props.setAppMessage(this.props.t('device_connection_problem'));
            }
            
        } else {
            this.props.setAppMessage('Моля попълнете данните за устройството');
        }

    }

    renderNetworksList = () => {
        
        if (this.state.networks.length === 0) {
            return null;
        }

        let ssidArray = [];
        return this.state.networks.map((n, k) => {

            const {SSID} = n;

            if (!ssidArray.includes(SSID)) {

                if (SSID.indexOf("Tesy") >= 0) {

                    ssidArray.push(SSID);
                    
                    return <IonSelectOption value={SSID} key={k}>{SSID}</IonSelectOption>
                }
            }
        })
    }

    doRefresh = event =>{

        setTimeout(() => {

            this.scanForNetworks()
            event.detail.complete();

        }, 2000);
    }

    render = () => {
        const { t } = this.props;

        return (
            <IonPage>        
                
                <StandartHeader name={t('choose_device')} />

                <IonContent className="ion-padding">
                    <div className="circle-image">
                        <img src={chooseDeviceImage} />
                    </div>                
                    
                    <IonLabel color="primary">{t('device')}</IonLabel>
                    
                    <IonItem lines="full" className="form-list__item">
                        <IonLabel position="stacked" color="primary" className="form-label"></IonLabel>
                        <IonInput 
                            type="text" 
                            className="form-input" 
                            placeholder='TCH_xxxxx' 
                            onIonInput={ e => this.setState({selectedNetwork: e.detail.value !== '' ? `${e.detail.value}` : ''})}
                        />
                    </IonItem>


                    <ToastMessage />
                    {/* <Refresher doRefresh={event => this.doRefresh(event)}/> */}
                    <Loading show={this.state.showLoading}/>

                </IonContent>
                
                <IonFooter className="app-footer ion-no-border">
                    <IonToolbar>
                        <div className="buttons-list">
                            <div className="buttons-list__item">
                                <IonButton expand="block" shape="round" size="large" onClick={this.continue}>{t('continue')}</IonButton>
                            </div>
                        </div>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        );
    }
    
};

export default compose(
    withTranslation(),
    connect(null, {
        chooseDevice,
        setAppMessage,
        setDeviceWifiArray,
        chooseNetwork,
        logger
    })
)(ChooseDevice);
  