import {
    IonPage,
    IonContent,
    IonFooter,
    IonToolbar,
    IonButton,
    IonList,
    IonItem,
    IonLabel,
    IonInput
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import StandartHeader from './components/header/StandartHeader';
import ToastMessage from './components/toast/ToastMessage';
import RestHelper from '../../helpers/RestHelper';
import InitialSetttings from '../../init';
import Loading from './components/loading/Loading';
//Import Redux Actions
import { setAppMessage } from '../../redux/actions/AppAction';

class NewGroup extends React.Component {

    constructor(props) {
        super(props);

        this.RestHelper = null;
        this.InitialSetttings = null;

        this.state = {
            name: '',
            showLoading: false
        }
    }


    componentDidMount = () => {
        this.RestHelper = new RestHelper();
        this.InitialSetttings = new InitialSetttings();
    }
    

    saveGroup = async () => {
        if (this.state.name !== '') {

            this.setState({showLoading: true});

            await this.RestHelper.post('/create-group', {
                name: this.state.name
            });

            await this.InitialSetttings.getMyGroups();

            this.setState({showLoading: false});
            this.props.history.goBack()

        } else {
            this.props.setAppMessage(this.props.t('required_name'));
        }
    }

    render = () => {

        const { t } = this.props;

        return (
            <IonPage>
                
                <StandartHeader name={t('new_group')}/>

                <IonContent className="ion-padding">        
                    
                    <IonList>
                        <IonItem lines="full" className="form-list__item">
                            <IonLabel position="stacked" color="primary" className="form-label">{t('group_name')}</IonLabel>
                            <IonInput type="text" className="form-input" placeholder="Ex.: Vacation house" onIonInput={ e => this.setState({name: e.detail.value})}/>
                        </IonItem>
                    </IonList>
                    
                    <div className="ion-content-push"></div>
                    <ToastMessage />
                    <Loading show={this.state.showLoading}/>
                </IonContent>

                <IonFooter className="app-footer ion-no-border">
                    <IonToolbar>
                        <div className="buttons-list">
                            <div className="buttons-list__item">
                                <IonButton expand="block" shape="round" size="large" onClick={this.saveGroup}>{t('save')}</IonButton>
                            </div>
                            <div className="buttons-list__item">
                                <IonButton expand="block" shape="round" size="large" color="help" onClick={() => this.props.history.goBack()}>{t('cancel')}</IonButton>
                            </div>
                        </div>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        );
    }
    
};

export default compose(
    withTranslation(),
    connect(null, {
        setAppMessage
    })
)(NewGroup); 