import React, {useState} from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { compose } from 'redux'

import {
    IonContent,
  } from '@ionic/react';

import CN04 from '../../../../../src/assets/images/cn04.jpeg';
import CN06 from '../../../../../src/assets/images/cn06.jpg';
import MODECO from '../../../../../src/assets/images/modeco.png';
import UsersHelper from '../../../../helpers/UsersHelper';
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardContent} from '@ionic/react';
import deviceImage_1 from '../../../../temp-files/device_1.jpg';
import deviceImage_2 from '../../../../temp-files/device_2.jpg';
import deviceImage_3 from '../../../../temp-files/device_3.jpg';
import deviceImage_4 from '../../../../temp-files/device_4.jpg';
import deviceImage_5 from '../../../../temp-files/device_5.jpg';
import deviceImage_6 from '../../../../temp-files/device_6.jpg';
import Loading from '../../components/loading/Loading';

import './add-device.scss';

const AddDevice = props => {

    const [showLoading, setShowLoading] = useState(false);

    return (
        <React.Fragment>

        <IonContent className="ion-padding ion-gray-backgroud">
            <div className="device-list">
                
                <div className="device-list__item" onClick={() => props.history.push('/cn06/choose-device')}>
                    <div className="device-list__preview">
                        <img src={deviceImage_1} alt="photo" />
                    </div>
                    <div className="device-list__content">
                        <div className="device-list__title">{props.t('Convector')}</div>
                    </div>
                </div>

                <div className="device-list__item" onClick={() => props.history.push('/old/heatecoconvector')}>
                    <div className="device-list__preview">
                        <img src={deviceImage_2} alt="photo" />
                    </div>
                    <div className="device-list__content">
                        <div className="device-list__title">{props.t('Convector HeatEco CN03')}</div>
                    </div>
                </div>

                <div className="device-list__item" onClick={() => props.history.push('/old/heatecoconvector')}>
                    <div className="device-list__preview">
                        <img src={deviceImage_3} alt="photo" />
                    </div>
                    <div className="device-list__content">
                        <div className="device-list__title">{props.t('​​​​​​​Convector ConvEco CN04')}</div>
                    </div>
                </div>

                <div className="device-list__item" onClick={() => props.history.push('/old/modecoc21')}>
                    <div className="device-list__preview">
                        <img src={deviceImage_4} alt="photo" />
                    </div>
                    <div className="device-list__content">
                        <div className="device-list__title">​​​​​​​{props.t('Boiler ModEco C21')}</div>
                    </div>
                </div>

                <div className="device-list__item" onClick={() => props.history.push('/old/modecoc22')}>
                    <div className="device-list__preview">
                        <img src={deviceImage_5} alt="photo" />
                    </div>
                    <div className="device-list__content">
                        <div className="device-list__title">{props.t('​​​​​​​Boiler ModEco C22')}</div>
                    </div>
                </div>

                <div className="device-list__item" onClick={() => props.history.push('/old/bellislimo')}>
                    <div className="device-list__preview">
                        <img src={deviceImage_6} alt="photo" />
                    </div>
                    <div className="device-list__content">
                        <div className="device-list__title">{props.t('Boiler BelliSlimo')}</div>
                    </div>
                </div>
            </div>
            <Loading show={showLoading}/>
        </IonContent>

        </React.Fragment>
    );
};

export default compose(
  withTranslation(),
  withRouter
)(AddDevice);