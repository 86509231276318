import LocalStorage from '../helpers/LocalStorageHelper';
import createModelInstance from '../models/modelsFactory';
import store from '../redux/createStore';
import { logger } from '../redux/actions/AppAction';

class MainService {

    constructor() {
        //global.debug(store.getState());
        
        // this.devices = LocalStorage.get('devices');
        this.createAppId();
    }

    createAppId =  () => {
        setTimeout(async () => {
            this.app_id = await LocalStorage.get('application_id') ?? Math.random().toString(36).substring(6);
        }, 1000)
        
    }

    publishMessage(){

    }

    messageProcess(topic, payload) {

        const [version, macAddr, requestType, model, token, command] = topic.split("/");

        const matchCommands = {
            getStatusResponse: "getStatus",    
            timeRequest: "time",    
            pingRequest: "ping"  
        }

        let commandName = command;
        if (requestType !== 'response' && requestType !== 'request') {
            commandName = matchCommands[requestType];
        } 

        try {
            const object = createModelInstance(model, {macAddr, token, payload, command:commandName, model});
            object.executeModelCommand();
        } catch (e) {
            //store.dispatch(logger(`MainService: Functionality doesn't exists ${commandName}: ${e.message}`));
            global.debug(`Functionality doesn't exists ${commandName}: ${e.message}`, '\n');
        }    
    }
}

export default MainService;