import React from 'react';
import {
    IonContent,
    IonToolbar,
    IonPage,
    IonFooter,
    IonButtons,
    IonButton,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonAlert
} from '@ionic/react';
import Slider from '@mui/material/Slider';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import HeaderWithPopoverMenu from './components/HeaderWithPopoverMenu';
import ManualModeBody from './components/ManualModeBody';
import ProgramModeBody from './components/ProgramModeBody';
import EcoVacationModeBody from './components/EcoVacationModeBody';
import ChangeDeviceMode from './components/ChangeDeviceMode';
import DeviceTabButton from '../components/ionTabs/DeviceTabButton';

import E32MqttHelper from "../../../helpers/mqqt/E32MqttHelper";
import E32Helper from "../../../helpers/E32Helper";

import {E32_Settings} from '../../../config/E32';

class ManagePage extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {
            deviceMode: null,
            deviceInitialMode: null,
            changeToNewMode: null
        }
    }

    componentDidMount = () => {

        this.E32MqttHelperOBJ = new E32MqttHelper(this.mac);
        
        if (this.device) {
            this.setState({
                deviceMode: this.E32HelperOBJ.getMainMode(),
                deviceInitialMode: this.E32HelperOBJ.getMainMode()
            })
        }
        
    }

    componentDidUpdate = () => {

        if (this.device) {

            const currMode = this.E32HelperOBJ.getMainMode();

            //WHEN MODE IS CHANGED FROM DEVICE
            if (this.state.deviceInitialMode != currMode) {
                this.setState({
                    deviceMode: currMode,
                    deviceInitialMode: currMode,
                })
            }
        }
    }

    changeDeviceControl = control => {

        if (control === E32_Settings.modes.manual && this.programsArray.includes(this.device.state.mode)) {
            this.setState({changeToNewMode: control});
        } else {
            this.setState({deviceMode: control})
        }
    }

    handleDeviceMode = (status, mode) => {
        if (status === 'on') {
            this.setState({changeToNewMode: mode});
        } else {
            this.setState({changeToNewMode: "deactivate_current_mode"});
        }
    }

    render = () => {

        const { t } = this.props;
        const {devices} = this.props;

        this.mac = this.props.match.params.mac_address;
        this.device = devices[this.mac];
        this.E32HelperOBJ = new E32Helper(this.device);

        if (!this.device || !this.state.deviceMode) {
            return null;
        }

        const {state: deviceState} = this.device;

        let disableDevice = false;
        if (deviceState.stand_by === 'yes' || this.device.hasInternet == false) {
            disableDevice = true;
        }

        this.programsArray = [E32_Settings.modes.p1, E32_Settings.modes.p2, E32_Settings.modes.p3];

        let showCircleGraphic = false;
        if (
            (deviceState.mode === E32_Settings.modes.manual ||  this.programsArray.includes(deviceState.mode) || 
            this.programsArray.includes(this.state.deviceMode) || 
            deviceState.stand_by === 'yes')
        ) {
            showCircleGraphic = true;
        }

        const anglesArray = this.E32HelperOBJ.getSliderAngles();
        const currentTempAngle = anglesArray.filter(x => x.temp == parseInt(deviceState.current_heat_level))[0] !== undefined ? anglesArray.filter(x => x.temp == parseInt(deviceState.current_heat_level))[0].angle : 270;

        return (
            <IonPage>

                <HeaderWithPopoverMenu 
                    name={this.device.deviceName} 
                    mac={this.mac}
                    device = {this.device}
                />

                <IonContent className="ion-padding">

                    <IonToolbar>
                        <IonSegment 
                            value={this.state.deviceMode} 
                            onIonChange={e => this.changeDeviceControl(e.detail.value)}
                            disabled={disableDevice ? true : false}
                        >
                            <IonSegmentButton value={E32_Settings.modes.manual} className={this.state.deviceMode == E32_Settings.modes.manual  && deviceState.mode != E32_Settings.modes.manual ? 'button-grey' : ''}>
                                <IonLabel>{t('ewh_manual')}</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value={E32_Settings.modes.p1}>
                                <IonLabel>{t('P1')}</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value={E32_Settings.modes.p2}>
                                <IonLabel>{t('P2')}</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value={E32_Settings.modes.p3}>
                                <IonLabel>{t('P3')}</IonLabel>
                            </IonSegmentButton>
                        </IonSegment>
                    </IonToolbar>
                

                    <div className="device-controls">

                        {deviceState.mode != E32_Settings.modes.vacation && 
                            <div className="program-buttons__item">
                                <IonButton 
                                    color="transparent" 
                                    className={`button-lock ${deviceState.boost == 'on' ? "button-lock--on" : "button-lock--off"}`} 
                                    onClick={() => this.E32MqttHelperOBJ.request('setBoost', {state: deviceState.boost == 'on' ? 'off' : 'on'})}
                                    disabled= { disableDevice ? true : false}
                                >
                                    <svg className="button-lock__icon"><use xlinkHref="#icon-boost"></use></svg>
                                    <div className="button-lock__label">{t('on')}</div>
                                </IonButton>
                            </div>
                        }

                        <div className={`device-controls__label ${this.device.hasInternet !== true ? 'active' : ''}`}> {t('offline')}</div>

                        <IonButton 
                            color="transparent" 
                            className={`button-switch ${deviceState.stand_by == 'no' ? "button-switch--on" : "button-switch--off"}`} 
                            onClick={() => this.E32MqttHelperOBJ.request("setStandby", {setState: deviceState.stand_by == 'no' ? 'yes' : 'no'})} 
                        >
                            <svg className="button-switch__icon"><use xlinkHref="#icon-switch"></use></svg>
                        </IonButton>

                    </div>

                    {showCircleGraphic &&
                        <div 
                            className={
                                `range-circle ${deviceState.heater_state == 'on' ? 'range-circle--heating' : 'range-circle--ready'} 
                                ${(disableDevice || deviceState.boost == "on") ? 'disabled' : ''}
                            `}
                        >
                                
                            <div className="range-circle__inner">
                                <div className="range-circle__status">
                                    {deviceState.stand_by == "no" ? deviceState.heater_state == 'on' ? t('ewh_heating') : t('ewh_ready') : t('ewh_inactive_status')}
                                </div>
                                <div className="range-shower">
                                    {this.E32HelperOBJ.renderShowersProgress(true)}
                                </div>
                                {/* <div className="range-circle__timer">
                                    <svg className="range-circle__timer-icon"><use xlinkHref="#icon-timer"></use></svg>
                                    {TimeHelper.addTimeInMinutes(this.zonename, status.cdt)}
                                </div> */}
                            </div>

                            <div className={`range-circle__didgits range-circle__didgits--${this.E32HelperOBJ.getShowers()}`}>
                                {this.E32HelperOBJ.renderSLiderDigits()}
                            </div>

                            <div className="range-circle__dot-handle" style={{ transform: 'rotate(' + currentTempAngle + 'deg)' }}>
                                <div className="range-circle__dot"></div>
                            </div>
                            <div className="range-circle__dot-handle range-circle__dot-handle--current" style={{ transform: 'rotate(' + anglesArray.filter(x => x.temp == parseInt(deviceState.heat_level))[0].angle + 'deg)' }}>
                                <div className="range-circle__dot"></div>
                            </div>           
                        </div>
                    }

                    {((this.state.deviceMode == E32_Settings.modes.manual && deviceState.mode == E32_Settings.modes.manual) || disableDevice) &&
                        <ManualModeBody 
                            disableDevice={disableDevice} 
                            device={this.device} 
                            mac={this.mac}
                        />  
                    }

                    {this.programsArray.includes(this.state.deviceMode) && 
                        <ProgramModeBody 
                            disableDevice={disableDevice} 
                            device={this.device} 
                            mac={this.mac} 
                            program={this.state.deviceMode}
                            changeMainMode={mode => this.setState({deviceMode: mode})}
                        />
                    }

                    {(this.state.deviceMode == E32_Settings.modes.manual && deviceState.mode != E32_Settings.modes.manual) &&
                        <EcoVacationModeBody 
                            disableDevice={disableDevice} 
                            device={this.device} 
                            mac={this.mac} 
                        />
                    }

                    <ChangeDeviceMode 
                        device={this.device} 
                        newMode={this.state.changeToNewMode} 
                        mac={this.mac}
                        cancelChange={mode => this.setState({changeToNewMode: null})}
                        changeMainMode={mode => this.setState({changeToNewMode: null})}
                    />

                </IonContent>

                <IonFooter className={`app-footer ion-no-border`}>
                    <IonToolbar>
                        <IonButtons className="ion-justify-content-between">

                            {E32_Settings.ecoFunctions.map(eco => (
                                <DeviceTabButton 
                                    key={eco}
                                    name={t(E32_Settings.match_modes_to_tesycloud[eco])}
                                    icon={E32_Settings.modes_icons[eco]} 
                                    handleStatus={status => this.handleDeviceMode(status, eco)}
                                    status={(deviceState.mode === eco) ?  'on' : 'off'}
                                    disabled= {disableDevice ? true : false}
                                />
                            ))}

                            <DeviceTabButton 
                                name={t("Vacation")}
                                icon={'palm'} 
                                handleStatus={newStatus => {
                                    deviceState.mode === E32_Settings.modes.vacation ? 
                                        this.handleDeviceMode(newStatus, E32_Settings.modes.vacation)
                                    :
                                        this.props.history.push(`/device/vacation/${this.device.model.toLowerCase()}/${this.mac}`)
                                }}
                                status={(deviceState.mode === E32_Settings.modes.vacation) ?  'on' : 'off'}
                                disabled= {disableDevice ? true : false}
                            />
                        </IonButtons>
                    </IonToolbar>
                </IonFooter>

            </IonPage>
        )

    }
}

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(ManagePage);