import Slider from '@mui/material/Slider';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import E32MqttHelper from "../../../../helpers/mqqt/E32MqttHelper";
import E32Helper from "../../../../helpers/E32Helper";
import { Device } from '@capacitor/device';

const ManualModeBody = ({mac, device, disableDevice}) => {

    const E32MqttHelperOBJ = new E32MqttHelper(mac);
    const E32HelperOBJ = new E32Helper(device);

    const {state: deviceState} = device;
    const minTemp = 0;
    const maxTemp = E32HelperOBJ.getShowers();

    const { t } = useTranslation();
    const [temp, setTemp] = useState(deviceState.heat_level);
    const [platform, setPlatform] = useState("android")

    useEffect(() => {
        setTemp(deviceState.heat_level);
    }, [deviceState.heat_level])

    useEffect(async () => {
        const {platform} = await Device.getInfo();
        setPlatform(platform)
    }, [])

    return (
        <div className="range-slider__wrapper">
            <header className={`range-slider__header ${(disableDevice || deviceState.boost == 'on') ? 'disabled' : ''}`}>
                <div className="range-slider__header-item">
                <div className="range-slider__header-label">{t('target_showers')}</div>
                </div>
                <div className="range-slider__header-item">
                <div className="range-slider__output">{temp}</div>
                </div>
            </header>
            <Slider
                value={temp}
                min={minTemp}
                max={maxTemp}
                step={1}
                track="inverted"
                onChange={(event, value) => {

                    if (platform == "ios" && event.type == "mousedown") {
                        return;
                    }
                    setTemp(value)
                }}
                onChangeCommitted={(event, value) => {

                    if (platform == "ios" && event.type == "mouseup") {
                        return;
                    }

                    E32MqttHelperOBJ.request('setMode', {mode: deviceState.mode, heatLevel: temp})
                }}
                disabled={ (disableDevice || deviceState.boost == 'on') ? true : false}
                valueLabelDisplay="off" 
                className="range-slider range-slider--temperature device-range-slider"
            />
        </div>
    )
}

export default ManualModeBody;