export const CHOOSE_DEVICE = 'CHOOSE_DEVICE';
export const CHOOSE_NETWORK = 'CHOOSE_NETWORK';
export const CHOOSE_NETWORK_PASS = 'CHOOSE_NETWORK_PASS';
export const SET_MODEL_POWER = 'SET_MODEL_POWER';
export const SET_DEVICE_MAC_ADDRESS = 'SET_DEVICE_MAC_ADDRESS';
export const SET_DEVICE_MODEL = 'SET_DEVICE_MODEL';
export const SET_DEVICE_NAME = 'SET_DEVICE_NAME';
export const SET_DEVICE_POWER = 'SET_DEVICE_POWER';
export const DELETE_NEW_DEVICE = 'DELETE_NEW_DEVICE';
export const SET_DEVICE_AP_PASS = 'SET_DEVICE_AP_PASS';
export const SET_DEVICE_WIFI_ARRAY = 'SET_DEVICE_WIFI_ARRAY';
export const CHOOSE_NETWORK_ORIGINAL = 'CHOOSE_NETWORK_ORIGINAL';
export const SET_DEVICE_VOLUME = 'SET_DEVICE_VOLUME';