import {
    IonAlert,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

import B15MqttHelper from "../../../../helpers/mqqt/B15MqttHelper";
import { B15_Settings } from '../../../../config/B15';

const ChangeDeviceMode = ({device, newMode, mac, cancelChange, changeMainMode, changeMode}) => {

    const { t } = useTranslation();

    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [alertHeader, setAlertHeader] = useState();
    const [alertBody, setAlertBody] = useState("");
    
    const B15MqttHelperOBJ = new B15MqttHelper(mac);

    useEffect(() => {

        if (newMode) {
            setAlertIsOpen(true);

            if (newMode == "deactivate_current_mode") {
                setAlertHeader(t(`${B15_Settings.match_modes_to_tesycloud['man']}_mode_header`));
                setAlertBody(`"${t(B15_Settings.match_modes_to_tesycloud[device.state.mode])}" ${t('switch_off_control')}`);
            } else {
                setAlertHeader(t(`${B15_Settings.match_modes_to_tesycloud[newMode]}_mode_header`));
                setAlertBody(`"${t(B15_Settings.match_modes_to_tesycloud[device.state.mode])}" ${t('switch_off_control')}`);
            }
        }

    }, [newMode]);

    const closeAlert = () => {
        setAlertIsOpen(false);
        cancelChange(null)
    }

    return (
        <IonAlert
            isOpen={alertIsOpen}
            onDidDismiss={closeAlert}
            header={alertHeader}
            message={alertBody}
            buttons={
                [{
                    text: t('cancel'),
                    handler: () => {
                        closeAlert();
                    }
                },
                {
                    text: t('ok'),
                    handler: () => {

                        if (changeMode != undefined) {
                            changeMode();
                        } else {

                            if (newMode == "deactivate_current_mode") {
                                B15MqttHelperOBJ.request('setMode', {mode: B15_Settings.modes.manual, temp: 45})
                            } else {

                                if (newMode == B15_Settings.modes.manual) {
                                    B15MqttHelperOBJ.request('setMode', {mode: newMode, temp: device.state.set_temp})
                                } else {
                                    B15MqttHelperOBJ.request('setMode', {mode: newMode})
                                }
                            }
                            
                            if (newMode == B15_Settings.modes.manual && changeMainMode != undefined) {
                                changeMainMode(B15_Settings.modes.manual)
                            }

                        }
                        
                    }
                }]
            }
        />
    )
}

export default ChangeDeviceMode;