import { combineReducers } from 'redux';

import devicesReducer from './DevicesReducer';
import NewDeviceReducer from './NewDeviceReducer';
import UserReducer from './UserReducer';
import AppReducer from './AppReducer';
import GroupsReducer from './GroupsReducer';
import OldDevicesReducer from './OldDevicesReducer';

export default combineReducers({
    devices: devicesReducer,
    oldDevices: OldDevicesReducer,
    newDevice: NewDeviceReducer,
    user: UserReducer,
    app: AppReducer,
    groups: GroupsReducer
})