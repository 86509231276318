import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
    IonContent,
    IonToolbar,
    IonPage,
    IonButtons,
    IonButton,
    IonLabel,
    IonAlert,
    IonGrid,
    IonCol,
    IonRow
} from '@ionic/react';
import Slider from '@mui/material/Slider';

import {oldModels} from '../../../../../config/App';
import OldDevicesSettingsHelper from '../../../../../helpers/OldDevicesSettingsHelper';
import TimeHelper from '../../../../../helpers/TimeHelper';
import ConvectorApiV1Menu from './ConvectorApiV1Menu';
import {updateOldDeviceState} from '../../../../../redux/actions/OldDevicesAction';
import DeviceTabButton from '../../../components/ionTabs/DeviceTabButton';

import Header from '../../../components/header/Header';
import PageHeader from '../../../components/header/PageHeader';
import Footer from '../../../components/footer/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import TemperatureSlider from '../../../components/range/TemperatureSlider';

class ConvectorApiV1 extends React.Component 
{

    constructor(props) {
        super(props);

        this.state = {
            programState: 'manual',
            newDeviceMode: '',
            lockState: false,
            deviceState: true,
            popoverMenu: [],
            popoverState: { showPopover: false, event: undefined },
            alert: {
                isOpen: false,
                header: '',
                message: ''
            }
        }
    }

    componentDidMount = async () => {

        this.OldDevicesSettingsHelper = new OldDevicesSettingsHelper(this.mac);

        const {data: {zonename}} = await this.OldDevicesSettingsHelper.setDeviceStatus('getTZ','');
        this.zonename = zonename;

        if (this.device !== null && this.device !== undefined) {

            let mode = 'manual';

            if (this.device.DeviceStatus.mode === this.model.modes.Program) {
                mode = 'program';
            }

            this.setState({
                programState: mode   
            });
        }
    }

    changeDeviceControl = control => {

        if (control === 'manual' && this.device.DeviceStatus.mode === this.model.modes.Program) {
            this.handleDeviceMode('on', this.model.modes.Manual);
        } else {
            this.setState({programState: control})
        }
    }

    handleDeviceMode = (status, mode) => {

        if (status === 'on') {

            this.setState({
                newDeviceMode: mode,
                alert: {
                    isOpen: true,
                    header: this.props.t(`${mode}_mode_header`),
                    message: this.props.t(`${mode}_mode_message`)
                }
            })

        } else {

            this.handleDeviceMode('on', this.model.modes.Manual);
        }
    }

    render = () => {

        this.mac = this.props.match.params.id;
        const { t } = this.props;
        const {devices} = this.props;

        this.device = devices[this.mac];
        this.model = oldModels[this.props.match.params.model_name]
        this.model_name = this.props.match.params.model_name
        
        if (!this.device || this.device === undefined) {
            return null;
        }

        const { DeviceStatus: status, DeviceOnline} = this.device;

        const mode = Object.keys(this.model.modes).find(key => this.model.modes[key] === status[this.model.commands.mode]);

        return (
            <IonPage>
                <Header />
                <IonContent className="ion-no-padding">
                    <div className="dashboard">
                        <div className="dashboard__wrapper">
                            
                            <ConvectorApiV1Menu activeMenu="controlPanel" model_name={this.props.match.params.model_name} device={this.device} model={this.model}/>

                            <div className="dashboard__content">
                                
                                <PageHeader name={t('control_panel')}/>

                                <div className="dashboard-box">
                                    <div className="dashboard-box__header">

                                        {DeviceOnline === 0 && 
                                            <div className="ion-text-center pb-20">
                                                <div className="device-controls__label active">{t('offline')}</div>
                                            </div>
                                        }

                                        <IonGrid className="px-0 py-0">
                                            <IonRow className="ion-justify-content-between">
                                                <IonCol size-xs="12" size-md="auto" className="px-0 py-0">
                                                    <IonRow>
                                                        <IonCol size-xs="6" size-md="auto" className="py-0 ion-padding-end">
                                                            <div className="text-bold text-black mb-10">{t('currrent_temperature')}</div>
                                                            <div className={`device-temperature device-temperature--alt ${status[this.model.commands.power] === this.model.powerOn ? '' : 'device-temperature--off'}`}>
                                                                {status[this.model.commands.tempValueCurrent] ? status[this.model.commands.tempValueCurrent] : 0}{t('degree_unit')}
                                                            </div>
                                                        </IonCol>
                                                        <IonCol size-xs="6" size-md="auto" className="py-0 ion-padding-end">
                                                            <div className="text-bold text-black mb-10">{t('status')}</div>
                                                            <div className={`device-temperature ${status[this.model.commands.power] === this.model.powerOn ? '' : 'device-temperature--off'}`}>
                                                                <svg className="device-temperature__icon"><use xlinkHref="#icon-heating-alt"></use></svg>
                                                                {t(`${mode}_dashboard`)}
                                                            </div>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                                <IonCol size-xs="12" size-md="auto" className="px-0 py-0">
                                                    <IonRow>
                                                        {this.model.functions.includes('Lock Keys') && 
                                                            <IonCol size-xs="6" size-md="auto" className="py-0 ion-padding-end">
                                                                <IonButton 
                                                                    className={`device-button ${status.lockB === 'on' ? "device-button--active" : "device-button--lock"}`} 
                                                                    onClick={ e => this.OldDevicesSettingsHelper.setDeviceStatus('lockB', status.lockB === 'on' ? 'off' : 'on') }
                                                                >
                                                                    <div className="device-button__inner">
                                                                        <div className="device-button__icon">
                                                                            <svg className="device-button__icon-icon"><use xlinkHref="#icon-locker"></use></svg>
                                                                            <div className="device-button__icon-label">{t('on')}</div>
                                                                        </div>
                                                                        <IonLabel className="device-button__text">{t('lock_display')}</IonLabel>
                                                                    </div>
                                                                </IonButton>
                                                            </IonCol>
                                                        }
                                                        <IonCol size-xs="6" size-md="auto" className="py-0 ion-padding-end">
                                                            <IonButton 
                                                                className={`device-button ${status[this.model.commands.power] === this.model.powerOn ? "device-button--active" : "device-button--lock"}`} 
                                                                onClick={ e => this.OldDevicesSettingsHelper.setDeviceStatus(this.model.commands.power, status[this.model.commands.power] == this.model.powerOn ? this.model.powerOff : this.model.powerOn) }
                                                            >
                                                                <div className="device-button__inner">
                                                                    <div className="device-button__icon">
                                                                        <svg className="device-button__icon-icon"><use xlinkHref="#icon-switch"></use></svg>
                                                                        <div className="device-button__icon-label">{t('on')}</div>
                                                                    </div>
                                                                    <IonLabel className="device-button__text">
                                                                        {status[this.model.commands.power] === this.model.powerOn ? t('active_status') : t('inactive_status')}    
                                                                    </IonLabel>
                                                                </div>
                                                            </IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </div>
                                    <div className="dashboard-box__body">
                                        <Tabs forceRenderTabPanel defaultIndex={status.mode === this.model.modes.Program ? 1 : 0}>
                                            <TabList>
                                                <Tab  
                                                    disabled={(status[this.model.commands.power] === this.model.powerOff || (DeviceOnline === 0)) ? true : false}
                                                    onClick={() => this.changeDeviceControl('manual')}
                                                >
                                                    {t('convector_manual')}
                                                </Tab>
                                                <Tab  
                                                    disabled={(status[this.model.commands.power] === this.model.powerOff || (DeviceOnline === 0)) ? true : false}
                                                    onClick={() => this.changeDeviceControl('program')}
                                                >
                                                    {t('program')}
                                                </Tab>
                                            </TabList>
    
                                            <TabPanel>
                                                
                                                {(status.mode === this.model.modes.Manual || status[this.model.commands.power] == this.model.powerOff) ?
                                                    <div className="pt-20">
                                                        <div className="wrapper wrapper--xs">
                                                            <TemperatureSlider 
                                                                label={t('target_temperature')}
                                                                disabled={(status[this.model.commands.power] === this.model.powerOff || (DeviceOnline === 0)) ? true : false}
                                                                currentTemp={status[this.model.commands.tempValue] ? Number(status[this.model.commands.tempValue]) : this.model.minTemp}
                                                                minTemperature={this.model.minTemp}
                                                                maxTemperature={this.model.maxTemp}
                                                                stepTemperature={1}
                                                                handleTemperature={value => this.OldDevicesSettingsHelper.setDeviceStatus(this.model.commands.temp, value)}
                                                            />
                                                        </div>
                                                    </div>
                                                :
                                                    <div className="pt-20">
                                                        <div className="device-mode">
                                                            <div className="device-mode__start">
                                                                <svg className={`device-mode__icon ${this.model.modesSettings[status.mode].class}`}>
                                                                    <use xlinkHref={this.model.modesSettings[status.mode].image}></use>
                                                                </svg>
                                                            </div>
                                                            <div className="device-mode__end">
                                                                <div className="device-mode__title">{`${t('your_device_is_in')} "${t(mode)}"`}</div>
                                                                <div className="target-list">
                                                                    { status.dtime > 0 &&
                                                                        <div className="target-list__item">
                                                                            <div className="target__label">{t(`until`)}: {TimeHelper.addTimeInMinutes(this.zonename, status.dtime)}</div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <IonButton color="tertiary" routerLink={`${this.model.modesSettings[status.mode].path_web}/${this.model_name}/${this.mac}`}>
                                                                    <span className="button__text">{t('edit')}</span>
                                                                </IonButton>
                                                                <IonButton color="primary" onClick={() => this.handleDeviceMode('on', this.model.modes.Manual)}>
                                                                    <span className="button__text">{t('deactivate')}</span>
                                                                </IonButton>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                <div className="pt-20">
                                                    <IonToolbar className="bordered">
                                                        <IonButtons className="ion-justify-content-between">

                                                            {this.model.modes.Sleep !== undefined &&
                                                                <DeviceTabButton 
                                                                    name={t('sleep_mode')} 
                                                                    icon={'moon'} 
                                                                    handleStatus={status => this.handleDeviceMode(status, this.model.modes.Sleep)}
                                                                    status={(status.mode === this.model.modes.Sleep) ?  'on' : 'off'}
                                                                    disabled= {(status[this.model.commands.power] == this.model.powerOff || DeviceOnline !== 1) ? true : false}
                                                                />
                                                            }

                                                            {this.model.modes.Delay !== undefined &&
                                                                <DeviceTabButton 
                                                                    name={t('delayed_start')} 
                                                                    icon={'clock'} 
                                                                    handleStatus={status => this.handleDeviceMode(status, this.model.modes.Delay)}
                                                                    status={(status.mode === this.model.modes.Delay) ?  'on' : 'off'}
                                                                    disabled= {(status[this.model.commands.power] == this.model.powerOn && DeviceOnline == 1) ? true : false}
                                                                />
                                                            }
                                                        
                                                        </IonButtons>
                                                       
                                                        <IonAlert
                                                            isOpen={this.state.alert.isOpen}
                                                            onDidDismiss={() => this.setState({alert: {...this.state.alert, isOpen: false}, newDeviceMode: ''})}
                                                            header={this.state.alert.header}
                                                            message={`"${t(status.mode)}" ${t('switch_off_control')}`}
                                                            buttons={
                                                                [{
                                                                    text: t('cancel'),
                                                                    handler: () => {
                                                                        this.setState({
                                                                            alert: {...this.state.alert, isOpen: false},
                                                                            newDeviceMode: '',
                                                                        })
                                                                    }
                                                                },
                                                                {
                                                                    text: t('ok'),
                                                                    handler: () => {

                                                                        this.setState({
                                                                            programState: this.state.newDeviceMode !== this.model.modes.Program ? 'manual' : this.state.programState
                                                                        });
                                    
                                                                        this.OldDevicesSettingsHelper.setDeviceStatus(this.model.commands.mode, this.state.newDeviceMode.toUpperCase());
                                                                    }
                                                                }]
                                                            }
                                                        />

                                                    </IonToolbar>
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                {/* TO DO: Ask what to do here */}
                                                <div className="pt-20">
                                                    <div className="program-status">
                                                        <div className="program-status__col">
                                                            <IonRow className="ion-text-center">
                                                                <IonCol>
                                                                    <IonButton routerLink={`/old/program/${this.model_name}/${this.mac}/1/P1`}>{t('edit_program')}</IonButton>
                                                                </IonCol>
                                                                <IonCol>
                                                                    <IonButton 
                                                                        color="tertiary"
                                                                        onClick={() => this.handleDeviceMode((status.mode === this.model.modes.Program) ? 'off' : 'on', this.model.modes.Program)}
                                                                    >
                                                                        <span className="button__text">{(status.mode === this.model.modes.Program) ? t('deactivate') : t('activate')}</span>
                                                                    </IonButton>
                                                                </IonCol>
                                                            </IonRow>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </IonContent>
                <Footer />
            </IonPage>
        );
    }
}


const mapStateToProps = state => {
    return {
        devices: state.oldDevices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps,{
        updateOldDeviceState
    })
)(ConvectorApiV1);