import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
    IonContent,
    IonToolbar,
    IonPage,
    IonFooter,
    IonButtons,
    IonButton,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonAlert
} from '@ionic/react';
import Slider from '@mui/material/Slider';

import {oldModels} from '../../../../../config/App';
import OldDevicesSettingsHelper from '../../../../../helpers/OldDevicesSettingsHelper';
import TimeHelper from '../../../../../helpers/TimeHelper';
import HeaderWithPopoverMenu from './HeaderWithPopoverMenu';
import {updateOldDeviceState} from '../../../../../redux/actions/OldDevicesAction';
import DeviceTabButton from '../../../components/ionTabs/DeviceTabButton';
import WaitingScreen from '../../../components/waitingScreen/WaitingScreen';

const anglesArray = [
    { 'temp': 0, 'angle': -49 },
    { 'temp': 0.5, 'angle': -44 },
    { 'temp': 1, 'angle': -39 },
    { 'temp': 1.5, 'angle': -34 },
    { 'temp': 2, 'angle': -31 },
    { 'temp': 2.5, 'angle': -28 },
    { 'temp': 3, 'angle': -24 },
    { 'temp': 3.5, 'angle': -20 },
    { 'temp': 4, 'angle': -15 },
    { 'temp': 4.5, 'angle': -12 },
    { 'temp': 5, 'angle': -4 },
    { 'temp': 5.5, 'angle': 0 },
    { 'temp': 6, 'angle': 4 },
    { 'temp': 6.5, 'angle': 8 },
    { 'temp': 7, 'angle': 12 },
    { 'temp': 7.5, 'angle': 18 },
    { 'temp': 8, 'angle': 23 },
    { 'temp': 8.5, 'angle': 28 },
    { 'temp': 9, 'angle': 32 },
    { 'temp': 9.5, 'angle': 35 },
    { 'temp': 10, 'angle': 40 },
    { 'temp': 10.5, 'angle': 45 },
    { 'temp': 11, 'angle': 50 },
    { 'temp': 11.5, 'angle': 55 },
    { 'temp': 12, 'angle': 60 },
    { 'temp': 12.5, 'angle': 65 },
    { 'temp': 13, 'angle': 70 },
    { 'temp': 13.5, 'angle': 75 },
    { 'temp': 14, 'angle': 80 },
    { 'temp': 14.5, 'angle': 85 },
    { 'temp': 15, 'angle': 90 },
    { 'temp': 15.5, 'angle': 95 },
    { 'temp': 16, 'angle': 100 },
    { 'temp': 16.5, 'angle': 105 },
    { 'temp': 17, 'angle': 110 },
    { 'temp': 17.5, 'angle': 115 },
    { 'temp': 18, 'angle': 120 },
    { 'temp': 18.5, 'angle': 125 },
    { 'temp': 19, 'angle': 130 },
    { 'temp': 19.5, 'angle': 135 },
    { 'temp': 20, 'angle': 140 },
    { 'temp': 20.5, 'angle': 145 },
    { 'temp': 21, 'angle': 150 },
    { 'temp': 21.5, 'angle': 155 },
    { 'temp': 22, 'angle': 160 },
    { 'temp': 22.5, 'angle': 165 },
    { 'temp': 23, 'angle': 170 },
    { 'temp': 23.5, 'angle': 174 },
    { 'temp': 24, 'angle': 178 },
    { 'temp': 24.5, 'angle': 182.5 },
    { 'temp': 25, 'angle': 185 },
    { 'temp': 25.5, 'angle': 190 },
    { 'temp': 26, 'angle': 195 },
    { 'temp': 26.5, 'angle': 200 },
    { 'temp': 27, 'angle': 205 },
    { 'temp': 27.5, 'angle': 208 },
    { 'temp': 28, 'angle': 211 },
    { 'temp': 28.5, 'angle': 216 },
    { 'temp': 29, 'angle': 221 },
    { 'temp': 29.5, 'angle': 226 },
    { 'temp': 30, 'angle': 230 },
]

class ConvectorApiV1 extends React.Component 
{

    constructor(props) {
        super(props);

        this.state = {
            programState: null,
            newDeviceMode: '',
            lockState: false,
            deviceState: true,
            popoverMenu: [],
            popoverState: { showPopover: false, event: undefined },
            alert: {
                isOpen: false,
                header: '',
                message: ''
            },
            stopUpdateSlider: false,
            sliderTemp: null
        }
        this.sliderTemp = null;
    }

    componentDidMount = async () => {

        this.OldDevicesSettingsHelper = new OldDevicesSettingsHelper(this.mac);

        const {data: {zonename}} = await this.OldDevicesSettingsHelper.setDeviceStatus('getTZ','');
        this.zonename = zonename;

        if (this.device !== null && this.device !== undefined) {

            let mode = 'manual';

            if (this.device.DeviceStatus.mode === this.model.modes.Program) {
                mode = 'program';
            }

            this.setState({
                programState: mode   
            });
        }

        this.setState({sliderTemp: this.device.DeviceStatus[this.model.commands.tempValue]})
    }

    changeDeviceControl = control => {

        if (control === 'manual' && this.device.DeviceStatus.mode === this.model.modes.Program) {
            this.handleDeviceMode('on', this.model.modes.Manual);
        } else {
            this.setState({programState: control})
        }
    }

    handleDeviceMode = (status, mode) => {

        if (status === 'on') {

            this.setState({
                newDeviceMode: mode,
                alert: {
                    isOpen: true,
                    header: this.props.t(`${mode}_mode_header`),
                    message: this.props.t(`${mode}_mode_message`)
                }
            })

        } else {

            this.handleDeviceMode('on', this.model.modes.Manual);
        }
    }

    render = () => {

        this.mac = this.props.match.params.id;
        const { t } = this.props;
        const {devices} = this.props;

        this.device = devices[this.mac];
        this.model = oldModels[this.props.match.params.model_name]
        this.model_name = this.props.match.params.model_name
        
        if (!this.device || this.device === undefined || this.state.programState == null) {
            return <WaitingScreen />;
        }

        const { DeviceStatus: status, DeviceOnline} = this.device;

        const currentTempAngle = anglesArray.filter(x => x.temp == status[this.model.commands.tempValueCurrent])[0] !== undefined ? anglesArray.filter(x => x.temp == status[this.model.commands.tempValueCurrent])[0].angle : 270;


        if (!this.state.stopUpdateSlider) {
            this.sliderTemp = status[this.model.commands.tempValue] ? Number(status[this.model.commands.tempValue]) : this.model.minTemp;
        } else {
            this.sliderTemp = this.state.sliderTemp;
        }

        return (
            <IonPage>
                
                <HeaderWithPopoverMenu 
                    name={`${this.device.DeviceShortName ?? this.mac}`}
                    mac={this.mac}
                    device = {this.device}
                    model = {this.model}
                    model_name = {this.props.match.params.model_name}
                />

                <IonContent className="ion-padding">

                    <IonToolbar>
                        <IonSegment 
                            value={this.state.programState} 
                            onIonChange={e => this.changeDeviceControl(e.detail.value)}
                            disabled={(status[this.model.commands.power] === this.model.powerOff || (DeviceOnline === 0)) ? true : false}
                        >
                            <IonSegmentButton value="manual">
                                <IonLabel>{t('convector_manual')}</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="program">
                                <IonLabel>{t('program')}</IonLabel>
                            </IonSegmentButton>
                        </IonSegment>
                    </IonToolbar>

                    <div className="device-controls">

                        {this.model.functions.includes('Lock Keys') && 

                            <IonButton 
                                color="transparent" 
                                className={`button-lock ${status.lockB === 'on' ? "button-lock--on" : "button-lock--off"}`} 
                                onClick={ e => this.OldDevicesSettingsHelper.setDeviceStatus('lockB', status.lockB === 'on' ? 'off' : 'on') }
                            >
                                <svg className="button-lock__icon"><use xlinkHref="#icon-locker"></use></svg>
                                <div className="button-lock__label">{t('on')}</div>
                            </IonButton>
                        }

                        <div className={`device-controls__label ${DeviceOnline !== 1 ? 'active' : ''}`}> {this.device.localUsage === true ? t('local_usage') : t('offline')}</div>
    
                        <IonButton 
                            color="transparent" 
                            className={`button-switch ${status[this.model.commands.power] === this.model.powerOn ? "button-switch--on" : "button-switch--off"}`} 
                            onClick={ e => this.OldDevicesSettingsHelper.setDeviceStatus(this.model.commands.power, status[this.model.commands.power] == this.model.powerOn ? this.model.powerOff : this.model.powerOn) }
                        >
                            <svg className="button-switch__icon"><use xlinkHref="#icon-switch"></use></svg>
                        </IonButton>
                    </div>

                    {((status.mode === this.model.modes.Manual || status.mode === this.model.modes.Program || this.state.programState === 'program' || status[this.model.commands.power] == this.model.powerOff) && status.mode !== this.model.modes.Delay) &&

                        <div className={`range-circle ${status.heater_state === 'HEATING' ? 'range-circle--heating' : 'range-circle--ready'} ${(status[this.model.commands.power] == this.model.powerOff || DeviceOnline !== 1) ? 'disabled' : ''}`}>
                            <div className="range-circle__inner">
                                <div><svg className="range-circle__icon"><use xlinkHref="#icon-heating-alt"></use></svg></div>
                                <div className="range-circle__temperature">{status[this.model.commands.tempValueCurrent] ? status[this.model.commands.tempValueCurrent] : 0}°C</div>
                                <div className="range-circle__label">{t('currrent_temperature')}</div>
                                {/* TO DO: Check the heating status */}
                                <div className="range-circle__status">
                                    {status[this.model.commands.power] === this.model.powerOn ? status.heater_state === 'HEATING' ? t('heating') : t('ready') : t('inactive_status')}
                                </div>
                            </div>
                            <div className="range-circle__didgits">
                                <div className="range-circle__didgit range-circle__didgit--0">0</div>
                                <div className="range-circle__didgit range-circle__didgit--5">5</div>
                                <div className="range-circle__didgit range-circle__didgit--10">10</div>
                                <div className="range-circle__didgit range-circle__didgit--15">15</div>
                                <div className="range-circle__didgit range-circle__didgit--20">20</div>
                                <div className="range-circle__didgit range-circle__didgit--25">25</div>
                                <div className="range-circle__didgit range-circle__didgit--30">30</div>
                            </div>

                            <div className="range-circle__dot-handle" style={{ transform: 'rotate(' + currentTempAngle + 'deg)' }}>
                                <div className="range-circle__dot"></div>
                            </div>
                            <div className="range-circle__dot-handle range-circle__dot-handle--current" style={{ transform: 'rotate(' + anglesArray.filter(x => x.temp == status[this.model.commands.tempValue])[0].angle + 'deg)' }}>
                                <div className="range-circle__dot"></div>
                            </div>           
                        </div>
                    }

                    
                    {this.state.programState === 'manual' ? 

                    ((status.mode === this.model.modes.Manual || status[this.model.commands.power] == this.model.powerOff) && status.mode !== this.model.modes.Delay) ?

                        <React.Fragment>
                            <div className="range-slider__wrapper">
                                <header className={`range-slider__header ${(status[this.model.commands.power] == this.model.powerOff || DeviceOnline !== 1) ? 'disabled' : ''}`}>
                                    <div className="range-slider__header-item">
                                    <div className="range-slider__header-label">{t('target_temperature')}</div>
                                    </div>
                                    <div className="range-slider__header-item">
                                    <div className="range-slider__output">{this.sliderTemp}°C</div>
                                    </div>
                                </header>
                                <Slider
                                    value={this.sliderTemp}
                                    min={this.model.minTemp}
                                    max={this.model.maxTemp}
                                    step={1}
                                    track="inverted"
                                    onChange={(event, value) => {
                                        this.setState({stopUpdateSlider: true, sliderTemp: value});
                                    }}
                                    onChangeCommitted={(event, value) => {
                                        this.OldDevicesSettingsHelper.setDeviceStatus(this.model.commands.temp, this.state.sliderTemp)
                                        this.props.updateOldDeviceState(value, this.model.commands.tempValue, this.device.DeviceSerial)
                                        this.setState({stopUpdateSlider: false});
                                    }}
                                    disabled={ (status[this.model.commands.power] == this.model.powerOff || DeviceOnline !== 1) ? true : false}
                                    valueLabelDisplay="off" 
                                    className="range-slider range-slider--temperature device-range-slider"
                                />
                            </div>

                            {status.mode === 'Delay' &&

                                <div className="target-list" style={{marginTop: '10px'}}>
                                    { status.dtime > 0 &&
                                        <div className="target-list__item">
                                            <div className="target__label">{t(`${status.mode}_until`)}: {TimeHelper.addTimeInMinutes(this.zonename, status.dtime)}</div>
                                        </div>
                                    }
                                </div>
                            }
                        </React.Fragment>
                    :

                        <React.Fragment>
                            <div className="device-mode">
                                <div className="device-mode__start">
                                    <svg className={`device-mode__icon ${this.model.modesSettings[status.mode].class}`}>
                                        <use xlinkHref={this.model.modesSettings[status.mode].image}></use>
                                    </svg>
                                </div>
                                <div className="device-mode__end">
                                    <div className={`range-circle range-circle--alt ${status.heater_state === 'HEATING' ? '' : 'range-circle--ready'} ${(status[this.model.commands.power] == this.model.powerOff || DeviceOnline !== 1) ? 'disabled' : ''}`}> 
                                        <div className="range-circle__inner">
                                            <div>
                                                <svg className="range-circle__icon">
                                                    <use xlinkHref="#icon-heating-alt"></use>
                                                </svg>
                                            </div>
                                            <div className="range-circle__temperature">{status[this.model.commands.tempValueCurrent] ? status[this.model.commands.tempValueCurrent] : 0}°C</div>
                                            <div className="range-circle__label">{t('currrent_temperature')}</div>
                                            <div className="range-circle__status">
                                                {status[this.model.commands.power] == this.model.powerOn ? status.heater_state === 'HEATING' ? t('heating') : t('ready') : t('inactive_status')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="target-list">
                                {/* {d_state.target > 0 &&

                                    <div className="target-list__item">
                                        <div className="target__label">{t('target_temperature')} {d_state.target}°C</div>
                                    </div>
                                } */}
                                { status.dtime > 0 &&
                                    <div className="target-list__item">
                                        <div className="target__label">{t(`${status.mode}_until`)}: {TimeHelper.addTimeInMinutes(this.zonename, status.dtime)}</div>
                                    </div>
                                }
                            </div>

                            <div className="range-buttons js-range-buttons">
                                <div className="range-buttons__item">
                                    <IonButton shape="round" size="large" onClick={() => this.handleDeviceMode('on', this.model.modes.Manual)}>{t('deactivate')}</IonButton>
                                </div>
                                <div className="range-buttons__item">
                                    <IonButton 
                                        shape="round" 
                                        size="large" 
                                        color="help" 
                                        onclick={() => this.props.history.push(`${this.model.modesSettings[status.mode].path}/${this.model_name}/${this.mac}`)}
                                    >
                                        {t('edit')
                                    }</IonButton>
                                </div>
                            </div>
                            
                        </React.Fragment>
                        
                    :
                        <div className="range-buttons js-range-buttons">
                            <div className="range-buttons__item">
                                <IonButton 
                                    shape="round" 
                                    size="large" 
                                    onClick={() => this.handleDeviceMode((status.mode === this.model.modes.Program) ? 'off' : 'on', this.model.modes.Program)}
                                >
                                    {(status.mode === this.model.modes.Program) ? t('deactivate') : t('activate')}
                                </IonButton>
                            </div>
                            <div className="range-buttons__item">
                                <IonButton 
                                    shape="round" 
                                    size="large" 
                                    color="help" 
                                    onclick={() => this.props.history.push(`/old/program/${this.model_name}/${this.mac}/1/P1`)}
                                >
                                    {t('edit')}
                                </IonButton>
                            </div>
                        </div>
                    }

                    <IonAlert
                        isOpen={this.state.alert.isOpen}
                        onDidDismiss={() => this.setState({alert: {...this.state.alert, isOpen: false}, newDeviceMode: ''})}
                        header={this.state.alert.header}
                        message={`"${t(status.mode)}" ${t('switch_off_control')}`}
                        buttons={
                            [{
                                text: t('cancel'),
                                handler: () => {
                                    this.setState({
                                        alert: {...this.state.alert, isOpen: false},
                                        newDeviceMode: '',
                                    })
                                }
                            },
                            {
                                text: t('ok'),
                                handler: () => {

                                    this.setState({
                                        programState: this.state.newDeviceMode !== this.model.modes.Program ? 'manual' : this.state.programState
                                    });

                                    this.OldDevicesSettingsHelper.setDeviceStatus(this.model.commands.mode, this.state.newDeviceMode.toUpperCase());
                                }
                            }]
                        }
                    />
    
                    <div className="ion-content-push"></div>

                </IonContent>

                <IonFooter className={`app-footer ion-no-border ${this.state.programState === 'program' ? 'ion-hide' : ''}`}>
                    <IonToolbar>
                        <IonButtons className="ion-justify-content-between">

                            {this.model.modes.Sleep !== undefined &&
                                <DeviceTabButton 
                                    name={t('sleep_mode')} 
                                    icon={'moon'} 
                                    handleStatus={status => this.handleDeviceMode(status, this.model.modes.Sleep)}
                                    status={(status.mode === this.model.modes.Sleep) ?  'on' : 'off'}
                                    disabled= {(status[this.model.commands.power] == this.model.powerOff || DeviceOnline !== 1) ? true : false}
                                />
                            }

                            {this.model.modes.Delay !== undefined &&
                                <DeviceTabButton 
                                    name={t('delayed_start')} 
                                    icon={'clock'} 
                                    handleStatus={status => this.handleDeviceMode(status, this.model.modes.Delay)}
                                    status={(status.mode === this.model.modes.Delay) ?  'on' : 'off'}
                                    disabled= {(status[this.model.commands.power] == this.model.powerOn && DeviceOnline == 1) ? true : false}
                                />
                            }
                        </IonButtons>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        );
    }
}


const mapStateToProps = state => {
    return {
        devices: state.oldDevices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps,{
        updateOldDeviceState
    })
)(ConvectorApiV1);