import {
    IonContent,
    IonFooter,
    IonButton,
    IonItem,
    IonList,
    IonPage,
    IonToolbar,
    IonLabel,
    IonInput,
  } from '@ionic/react';
  
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

  //Import components
import StandartHeader from './components/header/StandartHeader';
import ToastMessage from './components/toast/ToastMessage';

//Import helpers
import UsersHelper from '../../helpers/UsersHelper';

//Import Redux Actions
import { setAppMessage } from '../../redux/actions/AppAction';
  
class ForgottenPassword extends React.Component {
  
    constructor (props) {

        super(props);
    
        this.UsersHelper = new UsersHelper();
        this.state = {
            password: '',
            confirmPassword: '',
            errors: [],
            toastMessageColor: "warning"
        }

        const {match: {params: {token}}} = props;
        this.token = token;
    }

    changePassword = async () => {
        try {

            await this.UsersHelper.changePassword({
                token: this.token,
                password: this.state.password,
                confirmPassword: this.state.confirmPassword,
            });

            this.setState({
                errors: {},
                toastMessageColor: "success"
            });

            this.props.setAppMessage(this.props.t('success_change_pass'));

        } catch (e) {

            this.setState({
                errors: e.errors,
                toastMessageColor: "warning"
            });

            if ('global' in e.errors) {
                this.props.setAppMessage(e.errors.global);
            }
        }
    }

    togglePasswordVisiblity = () => {
        this.setState({
            passwordShown: this.state.passwordShown ? false : true  
        });
    };
    
    toggleConfirmPasswordVisiblity = () => {
        this.setState({
            passwordConfirmShown: this.state.passwordConfirmShown ? false : true  
        });
    };

    render = () => {

        const { t } = this.props;

        return (
            <IonPage>
                <StandartHeader name={t('change_password')}/>
        
                <IonContent className="ion-padding">
                <IonList>
                    <IonItem lines="full" className={`form-list__item ${'password' in this.state.errors ? 'is-invalid' : ''}`}>
                        <IonLabel position="stacked" color="primary" className="form-label">{t('password')}</IonLabel>
                        <div className="form-password">
                            <IonInput type={this.state.passwordShown ? "text" : "password"} className="form-input" onIonInput={ e => this.setState({password: e.detail.value})}/>
                            <button type="button" ion-button="true" className="form-password__button" onClick={this.togglePasswordVisiblity}>
                            <svg className="form-password__button-icon"><use xlinkHref={`#icon-eye-${this.state.passwordShown ? 'closed' : 'open'}`}></use></svg>
                            </button>
                        </div>
                        {'password' in this.state.errors ? (<div className="invalid-message">{this.state.errors.password}</div>) : null}
                    </IonItem>
                    <IonItem lines="full" className={`form-list__item ${'confirmPassword' in this.state.errors ? 'is-invalid' : ''}`}>
                        <IonLabel position="stacked" color="primary" className="form-label">{t('confirm_password')}</IonLabel>
                        <div className="form-password">
                            <IonInput type={this.state.passwordConfirmShown ? "text" : "password"} className="form-input" onIonInput={ e => this.setState({confirmPassword: e.detail.value})}/>
                            <button type="button" ion-button="true" className="form-password__button" onClick={this.toggleConfirmPasswordVisiblity}>
                            <svg className="form-password__button-icon"><use xlinkHref={`#icon-eye-${this.state.passwordConfirmShown ? 'closed' : 'open'}`}></use></svg>
                            </button>
                        </div>
                        {'confirmPassword' in this.state.errors ? (<div className="invalid-message">{this.state.errors.confirmPassword}</div>) : null}
                    </IonItem>
                </IonList>

                <ToastMessage color={this.state.toastMessageColor}/>
                </IonContent>
                
                <IonFooter className="app-footer ion-no-border">
                    <IonToolbar>
                    <div className="buttons-list">
                        <div className="buttons-list__item">
                            <IonButton expand="block" shape="round" size="large" onClick={this.changePassword}>{t('change_password')}</IonButton>
                        </div>
                        <div className="buttons-list__item">
                            <IonButton expand="block" fill="clear" color="link" size="small" routerLink="/login">{t('login')}</IonButton>
                        </div>
                    </div>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        );
    }
};
  
export default compose(
    withTranslation(),
    connect(null, {setAppMessage})
)(ForgottenPassword);
  