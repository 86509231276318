import {
    IonGrid,
    IonRow,
    IonCol
} from '@ionic/react';

const PageSubHeader = props => {
    return (
        <div className="dashboard-box__header">
            <IonGrid className="px-0 py-0">
                <IonRow className="ion-justify-content-between">
                    <IonCol size="auto" className="py-0 ion-padding-end">
                        {/* <IonButton color="back-primary">
                            <svg className="icon"><use xlinkHref="#icon-arrow-back"></use></svg>
                            <span className="button__text">Back</span>
                        </IonButton> */}
                    </IonCol>
                    <IonCol size="7" className="py-0 ion-padding-end">
                        <div className="dashboard-box__title">{props.title}</div>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    )
}

export default PageSubHeader;