import {
    IonPage,
    IonContent,
    IonFooter,
    IonToolbar,
    IonButton,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonRow,
    IonCol,
    IonAlert
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import StandartHeader from './components/header/StandartHeader';
import ToastMessage from './components/toast/ToastMessage';
import RestHelper from '../../helpers/RestHelper';
import InitialSetttings from '../../init';

//Import Redux Actions
import { setAppMessage } from '../../redux/actions/AppAction';

class EditGroup extends React.Component {

    constructor(props) {
        super(props);

        this.RestHelper = null;
        this.InitialSetttings = null;
        this.id = null;
        this.group = null;

        this.state = {
            name: '',
            groupDevices: [],
            showAlert: false,
            groupName: '',
            groupID: 0
        }
    }


    componentDidMount = () => {
        this.RestHelper = new RestHelper();
        this.InitialSetttings = new InitialSetttings();

        this.setState({
            name: this.props.group.name,
            groupDevices: this.props.group.devices
        })
    }
    

    saveGroup = async () => {
        if (this.state.name !== '') {

            await this.RestHelper.post('/edit-group', {
                name: this.state.name,
                devices: this.state.groupDevices,
                id: this.id
            });

            await this.InitialSetttings.getMyGroups();

            this.props.history.goBack()

        } else {
            this.props.setAppMessage(this.props.t('required_name'));
        }
    }

    handleDeleteDevice = macAddr => {
        this.setState({
            groupDevices: this.state.groupDevices.filter(mac => mac !== macAddr)    
        })
    }

    handleDelete = (id, name) => {

        this.setState({
            showAlert: true,
            groupName: name,
            groupID: id
        })
    }

    renderDevices = () => {

        const { devices } = this.props;

        return this.state.groupDevices.map((mac, k) => {
            
            const device = devices[mac];

            return (
                <div className="group-list__item" key={k}>
                    <header className="group-list__header">
                        <div className="group-list__header-left">
                            <img src="" alt="" />
                        </div>
                        <div className="group-list__header-right">
                            <div className="group-list__title">{device.deviceName}</div>
                        </div>
                       
                        <IonButton color="transparent" className="group-list__button" onClick={ () => this.handleDeleteDevice(mac) }>
                            <svg className="group-list__button-icon fill-red"><use xlinkHref="#icon-delete"></use></svg>
                        </IonButton> 
                        
                    </header>
                </div>
            )
        })
    }

    render = () => {

        const { t, group, match: {params: { id }} } = this.props;
        this.id = id;
        
        if (!group || group === undefined) {
            return null;
        }

        return (
            <IonPage>
                
                <StandartHeader name={t('edit_group')}/>

                <IonContent className="ion-padding">        

                    <IonList>
                        <IonItem lines="full" className="form-list__item">
                            <IonLabel position="stacked" color="primary" className="form-label">{t('group_name')}</IonLabel>
                            <IonRow style={{width: '100%' }} className="ion-align-items-center ion-justify-content-between">
                                <IonCol className="p-0">
                                    <IonInput type="text" className="form-input" value={this.state.name} onIonInput={ e => this.setState({name: e.detail.value})}/>
                                </IonCol>
                                <IonCol size="1" className="p-1">
                                    <svg className="group-list__button-icon fill-red" onClick={ (e) => this.handleDelete(id, this.state.name) }>
                                        <use xlinkHref="#icon-delete"></use>
                                    </svg>
                                </IonCol>
                            </IonRow>
                        </IonItem>
                    </IonList>

                    <div className="group-list" style={{marginTop: '16px'}}>
                        {this.renderDevices()}
                    </div>
                    
                    <div className="ion-content-push"></div>
                    <ToastMessage />

                    <IonAlert
                        isOpen={this.state.showAlert}
                        onDidDismiss={() => this.setState({showAlert: false})}
                        header={t('delete_group')}
                        message={`${t('do_you_want_to_delete')} ${this.state.groupName}`}
                        buttons={
                            [
                                {
                                    text: t('cancel'),
                                    handler: () => {
                                        this.setState({
                                            showAlert: false
                                        })
                                    }
                                },
                                {
                                    text: t('confirm'),
                                    handler: async () => {

                                        await this.RestHelper.post('/delete-group', {
                                            id: this.state.groupID    
                                        });

                                        this.InitialSetttings.getMyGroups();
                                        this.props.history.goBack();
                                    }
                                }
                            ]
                        }
                    />
                </IonContent>

                <IonFooter className="app-footer ion-no-border">
                    <IonToolbar>
                        <div className="buttons-list">
                            <div className="buttons-list__item">
                                <IonButton expand="block" shape="round" size="large" onClick={this.saveGroup}>{t('save')}</IonButton>
                            </div>
                            <div className="buttons-list__item">
                                <IonButton expand="block" shape="round" size="large" color="help" onClick={() => this.props.history.goBack()}>{t('cancel')}</IonButton>
                            </div>
                        </div>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        );
    }
};

const mapStateToProps = (state, ownProps) => {
   
    const { match: {params: { id: param_group_id }} } = ownProps; 
    let group = {};


    for (const i in state.groups) {
        const { id } = state.groups[i];

        if (id == param_group_id) {
            group =  state.groups[i];   
        }
    }

    return {
        devices: state.devices,
        group: group
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        setAppMessage
    })
)(EditGroup); 