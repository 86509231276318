import {
    IonContent,
    IonFooter,
    IonButton,
    IonPage,
    IonToolbar,
    IonText,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonRow,
    IonCol,
    IonSelect,
    IonSelectOption
} from '@ionic/react';

import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';

import StandartHeader from '../../components/header/StandartHeader';
import ToastMessage from '../../components/toast/ToastMessage';
import Loading from '../../components/loading/Loading';
import WifiHelper from '../../../../helpers/WifiHelper';
import LocalStorageHelper from '../../../../helpers/LocalStorageHelper';

import {
    setDeviceName,
    setDevicePower,
    deleteNewDevice
} from '../../../../redux/actions/NewDeviceAction';
import { setAppMessage, setAppProcessing } from '../../../../redux/actions/AppAction';
import { HTTP } from '@awesome-cordova-plugins/http';
import InitialSettings from '../../../../init';
import { Device } from '@capacitor/device';

class NameDevice extends React.Component {

    state = {
        email: '',
        desc: '',
        details: '',
        showLoading: false
    }

    componentDidMount = async () => {

        this.setState({
            email: await LocalStorageHelper.get('user.oldAppEmail'),
        })
    }


    continue = () => {

        if (this.state.email != '' && this.state.desc !== '') {

            this.setState({showLoading: true});

            global.debug({
                '_': Date.now(),
                email: this.state.email,
                mydescr: this.state.desc,
                mydetail: this.state.details !== '' ? this.state.details : 'none',
            });

            HTTP.post("http://10.0.0.1/mtData", {
                '_': Date.now(),
                email: this.state.email,
                mydescr: this.state.desc,
                mydetail: this.state.details !== '' ? this.state.details : 'none',
            }, {}).then( async ({data}) => {

                this.setState({showLoading: false});
                const {err} = JSON.parse(data);

                if (err != undefined && err == 1) {
                    this.props.setAppMessage(this.props.t('cant_send_data_to_device'));
                } else {   
                    this.props.history.push('/v1/protect-password');
                }
            })
            .catch(error => {
                this.setState({showLoading: false});
                this.props.setAppMessage(this.props.t('cant_send_data_to_device'));
            });
            
        } else {
            this.props.setAppMessage(this.props.t('all_data_required'));
        }
    }


    render = () => {

        const { t } = this.props;
        
        return (
            <IonPage>

                <StandartHeader name={t('device_settings_tesycloud')} />
        
                <IonContent className="ion-padding">
        
                    <IonList>
                        <IonItem lines="full" className="form-list__item">
                            <IonLabel position="stacked" color="primary" className="form-label">{t('account_email')}</IonLabel>
                            <IonInput type="text" className="form-input" onIonInput={e => this.setState({email: e.detail.value})}/>
                        </IonItem>
                        <IonItem lines="full" className="form-list__item">
                            <IonLabel position="stacked" color="primary" className="form-label">{t('short_description')}</IonLabel>
                            <IonInput type="text" className="form-input" onIonInput={e => this.setState({desc: e.detail.value})}/>
                        </IonItem>
                        <IonItem lines="full" className="form-list__item">
                            <IonLabel position="stacked" color="primary" className="form-label">{t('details')}</IonLabel>
                            <IonInput type="text" className="form-input" onIonInput={e => this.setState({details: e.detail.value})}/>
                        </IonItem>
                    </IonList>

                    <ToastMessage />
                    <Loading show={this.state.showLoading}/>
                </IonContent>
                
                <IonFooter className="app-footer ion-no-border">
                    <IonToolbar>
                    <div className="buttons-list">
                        <div className="buttons-list__item">
                        <IonButton expand="block" shape="round" size="large" onClick={this.continue}>{t('continue')}</IonButton>
                        </div>
                    </div>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        );
    }
};

const mapStateToProps = state => {
    return {
        newDevice: state.newDevice
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        setDeviceName,
        setDevicePower,
        deleteNewDevice,
        setAppMessage,
        setAppProcessing
    })
)(NameDevice);
  