import React, {useEffect, useState} from "react";
import Slider from '@mui/material/Slider';
import { Device } from '@capacitor/device';

//import './range.scss';

const RangeStartTemperature = (props) => {

    const [startTemperature, setStartTemperature] = React.useState( Number(props['data-start']) );
    const [platform, setPlatform] = useState("android")

    const handleStartTemperatureChange = (event, value) => {
        if (platform == "ios" && event.type == "mousedown") {
            return;
        }
        setStartTemperature(value);
    }

    useEffect(() => {
        setStartTemperature(Number(props['data-start']));
    }, [props['data-start']])

    useEffect(async () => {
        const {platform} = await Device.getInfo();
        setPlatform(platform)
    }, [])

    const handleStartTemperatureChangeComitted = (event, value) => {
        if (platform == "ios" && event.type == "mouseup") {
            return;
        }
       props.setTemp(value);
    }

    let degreeUnit = '°C';
    if (props.model != undefined && (props.model.indexOf('2002') >= 0 || props.model.indexOf('e32') >= 0)) {
        degreeUnit = '';
    }

    return (
        <div className="range-slider__wrapper">
            <header className="range-slider__header">
                <div className="range-slider__header-item">
                    <div className="range-slider__header-label">{props['data-label']}</div>
                </div>
                <div className="range-slider__header-item">
                    <div className="range-slider__output">{startTemperature}{degreeUnit}</div>
                </div>
            </header>
            <Slider
                value={typeof startTemperature === 'number' ? startTemperature : Number(props['data-min'])}
                min={ Number(props['data-min']) }
                max={ Number(props['data-max']) }
                step={ Number(props['data-step']) }
                track="inverted"
                onChange={handleStartTemperatureChange}
                onChangeCommitted={handleStartTemperatureChangeComitted}
                valueLabelDisplay="off"
                className="range-slider range-slider--temperature"
            />
        </div>
    );
};

export default RangeStartTemperature;