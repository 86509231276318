import {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonIcon
} from '@ionic/react';

import React, {useState, useEffect} from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { App } from '@capacitor/app';

import {
    userLogin
  } from '../../redux/actions/UserAction';

import StandartHeader from './components/header/StandartHeader';
import LocalStorageHelper from '../../helpers/LocalStorageHelper';
import arrowNext from '../../assets/images/arrow-list.svg';
import { fetchDevices } from '../../redux/actions/DevicesAction';
import { fetchGroups } from '../../redux/actions/GroupsAction';
import InitialSetttings from '../../init';

const Settings = props => {
  
    const [appVersion, setAppVersion] = useState(0);
    const [appBuild, setAppBuild] = useState(0);
    const [debug_menu, setDebugMenuState] = useState(false);
    const [is_tester, setIsTesterState] = useState(0);

    useEffect(() => {

        async function getDeviceInfo() {

            try {
                const {build, version} = await App.getInfo();
    
                setAppVersion(build);
                setAppBuild(version);
                
            } catch(e) {
                global.debug("Not supported");
            }
        }

        getDeviceInfo();

        async function setDebugMenu() {
            const debug_menu = await LocalStorageHelper.get('user.debug_menu');
            setDebugMenuState(debug_menu);
        }

        async function setIsTester() {
            const is_tester = await LocalStorageHelper.get('user.is_tester');
            setIsTesterState(is_tester);
        }

        setIsTester();
        setDebugMenu();
        
    }, []);

    const logOut = () => {

        LocalStorageHelper.set('user.id', null);
        LocalStorageHelper.set('user.password', null);
        LocalStorageHelper.set('user.email', null);
        LocalStorageHelper.set('user.firstName', null);
        LocalStorageHelper.set('user.lastName', null);
        LocalStorageHelper.set('user.phone', null);
        LocalStorageHelper.set('user.lang', null);
        LocalStorageHelper.set('user.debug_menu', null);
        LocalStorageHelper.set('user.oldAppEmail', null);
        LocalStorageHelper.set('user.oldAppPassword', null);
        LocalStorageHelper.set('user.is_tester', null);

        props.userLogin(false);
        props.fetchDevices({});
        props.fetchGroups({});

        const initialSetttingsInstance = new InitialSetttings();
        initialSetttingsInstance.destroyOldData();

        props.history.push('/welcome');
    }

    return (
        <IonPage>
        <StandartHeader name={props.t('settings')}/>

        <IonContent className="ion-padding">        
            <IonList className="manuals-list">

                <IonItem button className="manuals-list__item" routerLink="/account-details" >
                    <div slot="start">
                        <svg className="manuals-list__icon"><use xlinkHref={`#icon-account`}></use></svg>
                    </div>
                    {props.t('account_details')}
                    <IonIcon icon={arrowNext} slot="end"></IonIcon>
                </IonItem>

                <IonItem button className="manuals-list__item" routerLink="/password-details" >
                    <div slot="start">
                        <svg className="manuals-list__icon"><use xlinkHref={`#icon-account`}></use></svg>
                    </div>
                    {props.t('change_password')}
                    <IonIcon icon={arrowNext} slot="end"></IonIcon>
                </IonItem>

                <IonItem button className="manuals-list__item" routerLink="/old-app-details" >
                    <div slot="start">
                        <svg className="manuals-list__icon"><use xlinkHref={`#icon-account`}></use></svg>
                    </div>
                    tesyCloud
                    <IonIcon icon={arrowNext} slot="end"></IonIcon>
                </IonItem>

                <IonItem button className="manuals-list__item" routerLink="/devices-tutorials" >
                    <div slot="start">
                        <svg className="manuals-list__icon"><use xlinkHref={`#icon-settings`}></use></svg>
                    </div>
                    {props.t('tutorials')}
                    <IonIcon icon={arrowNext} slot="end"></IonIcon>
                </IonItem>

                <IonItem button className="manuals-list__item" routerLink="/app-info" >
                    <div slot="start">
                        <svg className="manuals-list__icon"><use xlinkHref={`#icon-settings`}></use></svg>
                    </div>
                    {props.t('app_info')}
                    <IonIcon icon={arrowNext} slot="end"></IonIcon>
                </IonItem>

                {is_tester != 0 &&
                    <IonItem button className="manuals-list__item" routerLink="/production-mode/choose-device-group" >
                        <div slot="start">
                            <svg className="manuals-list__icon"><use xlinkHref={`#icon-add`}></use></svg>
                        </div>
                        {props.t('short_procedure_add_device')}
                        <IonIcon icon={arrowNext} slot="end"></IonIcon>
                    </IonItem>
                }

                <IonItem button className="manuals-list__item" onClick={logOut} >
                    <div slot="start">
                        <svg className="manuals-list__icon"><use xlinkHref={`#icon-logout`}></use></svg>
                    </div>
                    {props.t('logout')}
                    <IonIcon icon={arrowNext} slot="end"></IonIcon>
                </IonItem>


                {/* TO DO: add check for production */}
                {debug_menu &&
                <IonItem button className="manuals-list__item" routerLink="/debug" >
                    {props.t('debug')}
                    <IonIcon icon={arrowNext} slot="end"></IonIcon>
                </IonItem>
                }

                {/* TO DO: add check for production */}
                <IonItem button className="manuals-list__item" >
                    {props.t('app_version')}: {appVersion} ({appBuild})
                </IonItem>
            </IonList>
            <div className="ion-content-push"></div>
        </IonContent>
        </IonPage>
    );
};

export default compose(
    withTranslation(),
    connect(null, {
        userLogin,
        fetchDevices,
        fetchGroups
    })
)(Settings);  