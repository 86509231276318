import welcomeImage from '../../../../temp-files/webApp/welcome-image.png';

const Welcome = () => {

    return (
        <div className="welcome__start">
            <img src={welcomeImage} className="welcome__start-image" width="" height="" alt="" />
        </div>
    )
}

export default Welcome;