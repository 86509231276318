
import { App } from '@capacitor/app';

import LocalStorage from './helpers/LocalStorageHelper';
import RestHelper from './helpers/RestHelper';
import UsersHelper from './helpers/UsersHelper';
import DeviceSettingsHelper from './helpers/DeviceSettingsHelper';
import PushNotificationsHelper from './helpers/PushNotificationsHelper';
import { isPlatform } from '@ionic/react';
import { Network } from '@capacitor/network';

import {
    fetchDevices,
    updateDeviceInternet,
    updateDeviceState,
    updateDeviceWaitingForConnection
} from './redux/actions/DevicesAction';
import {
    fetchOldDevices,
} from './redux/actions/OldDevicesAction';

import {
    userLogin,
    userOldAppLogin
} from './redux/actions/UserAction';
import {
    setPushNofications,
    setAppDocuments,
    setAppLanguage,
    setOldDevicesLoader,
    setAppProcessing,
    setAppNetwork,
    setAppSettings
} from './redux/actions/AppAction';
import i18n from './lang/langFactory';

import {
    fetchGroups
} from './redux/actions/GroupsAction';

import store from './redux/createStore';
import {
    appMqttVersion
} from './config/App';
import Mqtt from './services/Mqtt';

const CapApp = App;

let wifiTimer = null;
let pingTimer = null;
let getStatusTimer = null;
let waitingForConnectionTimer = null;
let notificationsTimer = null;
let oldDevicesTimer = null;
let networkTimer = null;

class InitialSettings {

    constructor() {
        global.debug(`Initialize...............................................`);
        this.restHelper = new RestHelper();
        this.usersHelper = new UsersHelper();
        this.PushNotificationsHelper = new PushNotificationsHelper();
    }

    destroyOldData = () => {
        
        global.debug("CLEAR DATA");
        global.debug("CLEAR PING TIMER", pingTimer);
        //Unsubscribe device
        const { devices } = store.getState();

        for (const mac in devices) {
            Mqtt.unsubscribeForDevice(mac);    
        }

        //Remove capacitor listeners
        //CapApp.removeAllListeners();

        if (isPlatform('desktop') !== true) {
            //Remove push notification listeners
            this.PushNotificationsHelper.removeListeners();
        }
        

        //Remove wifi check
        clearInterval(wifiTimer);

        //Destroy ping check
        clearInterval(pingTimer);

        //Destroy getStatus timer
        clearInterval(getStatusTimer);

        //Destroy waitingForConnectionTimer
        clearInterval(waitingForConnectionTimer);

        //Destroy notificationsTimer
        clearInterval(notificationsTimer);

        //Destroy oldDevicesTimer
        //store.dispatch(setOldDevicesLoader(false));
        clearInterval(oldDevicesTimer);
    }

    init = async () => {

        //CHECK IF DEVICE HAS INTERNET OR NO. IF NO THEN STOP FUNCTION FROM EXECUTION
        const {connected} = await Network.getStatus();
        if (!connected) {
            store.dispatch(setAppNetwork(false));   
            return;
        }

        //SET TIMER FOR CHECKING INTERNET CONNECTION
        this.initNetworkTimer();

        global.debug("INIT APP")
        //SET APP IN PROCESSING MODE
        store.dispatch(setAppProcessing(true));

        //Destroy all timers, listeners, unsubscribe from Mqtt topics
        this.destroyOldData();

        const userID = await LocalStorage.get('user.id');

        global.debug("UserID", userID);

        if (userID != 'null' && userID != undefined) {

            const lng = await LocalStorage.get('user.lang');
            i18n.changeLanguage(lng);
            //Check if user has access to cloud
            this.hasAccessToCloud();

            //store.dispatch(fetchOldDevices({}));

            global.debug("init user data");
            store.dispatch(userLogin(true));

            //Get All MyTesy Devices and set in Redux
            const my_devices = await this.getMyDevices();
            LocalStorage.set('my_devices', my_devices.data);
            store.dispatch(fetchDevices(my_devices.data));

            //Check if user has credentials for tesyCloud and get all devices from tesyCloud
            await this.getMyOldDevices();

            //Get All app messages and set in Redux
            this.getMyMessages();
            
            //Init MQTT and subscribe each MyTesy device for topics
            Mqtt.init();

            if (my_devices.data) {
                for (const mac in my_devices.data) {
                    Mqtt.subscribeForDevice(mac);
                }
            }

            //Crate app id and set in Local storage
            LocalStorage.set("application_id", Math.random().toString(36).substring(6));

            //Set Mqtt topics version in Local storage
            LocalStorage.set("appVersion", appMqttVersion);

            //this.PushNotificationsHelper.register(userID);

            //If running on mobile device register Firebase notifications and listeners
            // if (isPlatform('desktop') !== true) {

            //     this.PushNotificationsHelper.register(userID);
            //     this.PushNotificationsHelper.initListeners();

            // } else {

                
            // }

            this.getNotifications();

            this.getUserInfo();
            this.getMyGroups();
            this.checkDevicesInternet();
            this.waitingForConnection();
            this.pingDevices();
            this.getDocuments();
            this.getStatusForLocalUsageDevices();
            this.getStatusForAllDevices();
            this.getGeneralSettings();
            
            global.debug("end init user data");

            this.usersHelper.saveUserLog('dashboard');
            
        } else {
            store.dispatch(userLogin(false));
            //LocalStorage.set('my_devices', {});
            //window.location.href = '/welcome';
        }


        //Init event for deep links via email
        
    }

    getGeneralSettings = async () => {
        const {data}  = await this.restHelper.get('/get-general-settings');
        store.dispatch(setAppSettings(data));
    }

    initAppListeners = () => {

        CapApp.removeAllListeners();
    
        CapApp.addListener('appUrlOpen', (data) => {
            global.debug('matched url: ',data.url);
    
            const slug = data.url.split('.dev').pop();
            if (slug) {
                global.debug(`PUSH TO: ${slug}`);
                window.location.href = slug;
            }
            
        });

        CapApp.addListener('appStateChange', state => {

            global.debug("appStateChange", state);
            
            if (!state.isActive) {

                global.debug("APP IS IN BACKGROUND MODE");
                this.destroyOldData();

            } else {
                
                this.init();  
                global.debug("APP IS LIVE AGAIN");
            }

        });
    }

    initNetworkTimer = async () => {

        clearInterval(networkTimer);

        const timeInSeconds = 5;

        networkTimer = setInterval(async () => {

            const {connected} = await Network.getStatus();
            store.dispatch(setAppNetwork(connected));   
            
        }, timeInSeconds * 1000)
    }

    getMyGroups = async () => {
        const my_groups = await this.restHelper.get('/get-my-groups');
        store.dispatch(fetchGroups(my_groups.data));         
    }

    getTestDevices = () => this.restHelper.get('/get-test-devices');

    getMyDevices = () => this.restHelper.get('/get-my-devices');

    getMyOldDevices = async () => {

        clearInterval(oldDevicesTimer);
        global.debug("GET TESYCLOUD DEVICES");

        if (await LocalStorage.get('user.oldAppEmail') && await LocalStorage.get('user.oldAppPassword')) {

            //store.dispatch(setOldDevicesLoader(true));

            try {
                const {data: data1} = await this.restHelper.post('/old-app-login',{email: await LocalStorage.get('user.oldAppEmail'), password: await LocalStorage.get('user.oldAppPassword')});

                //store.dispatch(setOldDevicesLoader(false));

                if (data1.error == undefined) {

                    //const {ALT, CURRENT_SESSION, PHPSESSID, last_login_username} = data1;
                    const {acc_alt, acc_session} = data1;
                    
                    const ALT = acc_alt;
                    const PHPSESSID = acc_session;
                    const last_login_username = await LocalStorage.get('user.oldAppEmail');
                    const CURRENT_SESSION = null;
        
                    const {data: {device}} = await this.restHelper.post('/old-app-devices', {
                        ALT,
                        CURRENT_SESSION,
                        PHPSESSID,
                        last_login_username   
                    }); 
        
                    store.dispatch(userOldAppLogin({
                        ALT,
                        CURRENT_SESSION,
                        PHPSESSID,
                        last_login_username   
                    }));

                    store.dispatch(setAppProcessing(false));
                    store.dispatch(fetchOldDevices(device));
                    
                    const timeInSeconds = 5.7; 
        
                    oldDevicesTimer = setInterval(async () => {
        
                        const {data: {device}} = await this.restHelper.post('/old-app-devices', {
                            ALT,
                            CURRENT_SESSION,
                            PHPSESSID,
                            last_login_username   
                        }); 
                
                        store.dispatch(setOldDevicesLoader(false));

                        if (device != undefined && device) {
                            store.dispatch(fetchOldDevices(device));
                        }
        
                    }, timeInSeconds * 1000);

                } else {

                    store.dispatch(setAppProcessing(false));
                    store.dispatch(fetchOldDevices({}));
                }

            } catch {

                store.dispatch(setAppProcessing(false));
                store.dispatch(fetchOldDevices({}));
                //store.dispatch(setOldDevicesLoader(false));
                global.debug("can`t log in into TesyCloud");
            }

        } else {

            store.dispatch(setAppProcessing(false));
            store.dispatch(fetchOldDevices({}));
        }
    }

    getMyMessages = async () => {
        const messages = await this.restHelper.get('/get-my-messages');
        store.dispatch(setPushNofications(messages.data));
    }

    getDocuments = async () => {
        const documents = await this.restHelper.get('/documents');
        store.dispatch(setAppDocuments(documents.data));     
    }

    getUserInfo = async () => {
            const { data: userInfo } = await this.restHelper.get('/get-user-info');    

            if (Object.keys(userInfo).length != 0) {
                LocalStorage.set('user.email', userInfo.email);
                LocalStorage.set('user.firstName', userInfo.firstName);
                LocalStorage.set('user.lastName', userInfo.lastName);
                LocalStorage.set('user.lang', userInfo.lang);
                store.dispatch(setAppLanguage(userInfo.lang));
            }
    } 

    getNotifications = () => {

        clearInterval(notificationsTimer);

        const timeInSeconds = 10;  
        
        notificationsTimer = setInterval( async () => {

            this.getMyMessages();

        }, timeInSeconds * 1000);
    }

    // IF user doesn't have an access to cloud: logout 
    hasAccessToCloud = async() => {

        const { data: hasAccess } = await this.restHelper.get('/has-access-to-cloud');      
    
        //hasAccess == true if user has access to cloud otherwise returns an object with error
        if (Object.keys(hasAccess).length != 0) {

            LocalStorage.set('user.id', null);
            LocalStorage.set('user.password', null);
            LocalStorage.set('user.email', null);
            LocalStorage.set('user.firstName', null);
            LocalStorage.set('user.lastName', null);
            LocalStorage.set('user.lang', null);


            store.dispatch(userLogin(false));
            store.dispatch(fetchDevices({}));
            store.dispatch(fetchGroups({}));

            window.location.href = '/welcome'
        }   
    }

    //CHECK IF DEVICE HAS A CONNECTION OR NOT
    checkDevicesInternet = () => {

        clearInterval(wifiTimer);

        const timeInSeconds = 20; 
       
        wifiTimer = setInterval(() => {

            const { devices } = store.getState();

            for (const mac_address in devices) {
                
                const { last_connection,  localUsage} = devices[mac_address];

                if (localUsage === false) {
                    
                    if (last_connection !== undefined) {

                        const date1 = new Date(last_connection);
                        const date2 = new Date();
                        const diffTimeSec = Math.ceil(( Math.abs(date2 - date1) ) / 1000);
    
                        if (diffTimeSec > timeInSeconds) {
                            
                            //store.dispatch(updateDeviceState("off", 'status', mac_address));
                            store.dispatch(updateDeviceInternet(false, mac_address));
                        }
    
                    } else {
                        //store.dispatch(updateDeviceState("off", 'status', mac_address));
                        store.dispatch(updateDeviceInternet(false, mac_address));
                    }
                }
                
            }

        }, timeInSeconds * 1000);
    }

    //CHECK Device interenet connection
    //Detect when comming from background mode 
    pingDevices = () => {

        clearInterval(pingTimer);
        global.debug('INIT NEW PING PROCESS');

        const timeInSeconds = 5; 
        const inactiveTimeInSec = 60;
       
        pingTimer = setInterval(() => {

            const { devices } = store.getState();

            for (const mac_address in devices) {
                
                const { localUsage, model, token, last_connection } = devices[mac_address];

                const date1 = new Date(last_connection);
                const date2 = new Date();
                const diffTimeSec = Math.ceil(( Math.abs(date2 - date1) ) / 1000);

                //Background mode
                if (diffTimeSec > inactiveTimeInSec) {
                    clearInterval(pingTimer);
                    //global.debug("App was unactive for ", diffTimeSec, 'Reinit the app');
                    this.init();
                }

                //global.debug("App was unactive for ", diffTimeSec);
                
                if (localUsage === false) {

                    Mqtt.publishMessage({
                        model,
                        token,
                        macAddr: mac_address,
                        payload: {},
                        requestType: 'request',
                        command: 'ping'
                    });    

                    // this.restHelper.post('/app-log', {
                    //     mac: mac_address,
                    //     command: "ping",
                    //     payload: {}
                    // });

                }
            }

        }, timeInSeconds * 1000);    
    }

    //Get Device Status for all devices in local control
    getStatusForLocalUsageDevices = () => {

        clearInterval(getStatusTimer);

        const timeInSeconds = 60; 
       
        getStatusTimer = setInterval(() => {

            const { devices } = store.getState();

            for (const mac_address in devices) {
                
                const { localUsage } = devices[mac_address];

                if (localUsage === true) {

                    global.debug(`Get status for mac: ${mac_address}`);
                    const deviceSettingsObject = new DeviceSettingsHelper(mac_address);
                    deviceSettingsObject.handleGetStatus();    
                }
            }

        }, timeInSeconds * 1000);        
    }

    getStatusForAllDevices = () => {

        const { devices } = store.getState();   

        for (const mac_address in devices) {

            global.debug(`Get status for mac: ${mac_address}`);
            const deviceSettingsObject = new DeviceSettingsHelper(mac_address);
            deviceSettingsObject.handleGetStatus();    
        }
    }

    //Set initial message after device loading from Rest
    waitingForConnection = () => {

        clearInterval(waitingForConnectionTimer);

        const timeInSeconds = 30; 
       
        waitingForConnectionTimer = setInterval(() => {

            const { devices } = store.getState();

            for (const mac_address in devices) {
                
                const { waitingForConnection } = devices[mac_address];

                if (waitingForConnection === true) {
                    store.dispatch(updateDeviceWaitingForConnection(false, mac_address));     
                }

                clearInterval(waitingForConnectionTimer);
            }

        }, timeInSeconds * 1000);   
    }
}

export default InitialSettings;
  