import {
    IonContent,
    IonPage,
    IonList,
    IonItem,
    IonIcon,
    IonButton,
    IonAlert
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import {
    setPushNofications,
    readPushNofication,
    deletePushNofication,
    deleteAllPushNofications
} from '../../redux/actions/AppAction';
import RestHelper from '../../helpers/RestHelper';
import StandartHeader from './components/header/StandartHeader';
import InitialSettings from '../../init';

import arrowNext from '../../assets/images/arrow-list.svg';

class Notofications extends React.Component{

    constructor(props) {
        super(props);

        this.RestHelper = null;

        this.state = {
            showDeleteAlert: false,
            showDeleteAllAlert: false,
            notificationID: 0
        }
    }

    componentDidMount = async () => {
        
        this.RestHelper = new RestHelper();
        this.initialSettings = new InitialSettings();

        const messages = await this.RestHelper.get('/get-my-messages');
        this.props.setPushNofications(messages.data);
    }

    renderMessages = messages => {
        const data = [];

        for (const i in messages) {

            const { title, message_id, is_read, id, date } = messages[i];
            const [model, title_key] = title.split(': ');

            // onClick={() => this.readMessage(message_id)}
            data.push(
                <IonItem key={i} button className={`notifications-list__item ${is_read != 1 ? 'unread' : ''}`}>
                    <span slot="start" onClick={() => this.readNotification(id)}>{date} <br/>{model}: {this.props.t(title_key)}</span>
                    <IonButton color="icon" onClick={() => this.setState({showDeleteAlert: true, notificationID: id})} slot="end">
                        <svg><use xlinkHref="#icon-delete" className="fill-red"></use></svg>
                    </IonButton>
                 
                </IonItem>
            )
        }

        return data;    
    }

    readNotification = message_id => {

        this.RestHelper.post('/read-message', {
            message_id: message_id    
        });

        this.props.readPushNofication(message_id);

        this.props.history.push(`/notification/${message_id}`)
    }

    deleteNotification = async () => {

        this.RestHelper.post('/delete-message', {
            message_id: this.state.notificationID    
        });

        this.props.deletePushNofication(this.state.notificationID);
        this.setState({showDeleteAlert: true, notificationID: 0});   
    }


    deleteAllNotifications = () => {
        this.RestHelper.post('/delete-all-messages');

        this.props.deleteAllPushNofications();
        this.props.history.push("/dashboard");
        this.setState({showDeleteAllAlert: false});        
    }

    render = () => {

        const { app: { push_notifications_messages }, t } = this.props;

        return (
            <IonPage>
                <StandartHeader name={t('notifications')}/>
        
                <IonContent className="ion-padding">
        
                    <div className="ion-text-right pb-10">
                        <IonButton color="delete" onclick={() => this.setState({showDeleteAllAlert: true})}>{this.props.t('delete_program')}</IonButton>
                    </div>
                    <IonList className="notifications-list">
                        {this.renderMessages(push_notifications_messages)}   
                    </IonList>
        
                    <div className="ion-content-push"></div>

                    <IonAlert
                        isOpen={this.state.showDeleteAlert}
                        onDidDismiss={() => this.setState({showDeleteAlert: false})}
                        cssClass='my-custom-class'
                        header={this.props.t('confirm')}
                        message={this.props.t('confirm_notification_delete')}
                        buttons={[
                            {
                                text: this.props.t('cancel'),
                                role: 'cancel',
                                cssClass: 'secondary',
                                handler: blah => {
                                    this.setState({showDeleteAlert: false});
                                }
                            },
                            {
                                text: this.props.t('ok'),
                                handler: () => {
                                    this.deleteNotification()
                                }
                            }
                        ]}
                    />

                    <IonAlert
                        isOpen={this.state.showDeleteAllAlert}
                        onDidDismiss={() => this.setState({showDeleteAllAlert: false})}
                        cssClass='my-custom-class'
                        header={this.props.t('confirm')}
                        message=""
                        buttons={[
                            {
                                text: this.props.t('cancel'),
                                role: 'cancel',
                                cssClass: 'secondary',
                                handler: blah => {
                                    this.setState({showDeleteAllAlert: false});
                                }
                            },
                            {
                                text: this.props.t('ok'),
                                handler: () => {
                                    this.deleteAllNotifications()
                                }
                            }
                        ]}
                    />
                </IonContent>
            </IonPage>
        );
    }
};

const mapStateToProps = state => {
    return {
        app: state.app
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        setPushNofications,
        readPushNofication,
        deletePushNofication,
        deleteAllPushNofications
    })
)(Notofications);  