import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
    IonContent,
    IonToolbar,
    IonPage,
    IonFooter,
    IonButtons,
    IonButton,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonAlert,
    IonCol,
    IonRow,
    IonGrid
} from '@ionic/react';
import Slider from '@mui/material/Slider';

import {oldModels} from '../../../../../config/App';
import OldDevicesSettingsHelper from '../../../../../helpers/OldDevicesSettingsHelper';
import BelislimoHelper from '../../../../../helpers/BelislimoHelper';
import TimeHelper from '../../../../../helpers/TimeHelper';
import BoilerApiV2Menu from './BoilerApiV2Menu';
import {updateOldDeviceState} from '../../../../../redux/actions/OldDevicesAction';
import DeviceTabButton from '../../../components/ionTabs/DeviceTabButton';
import TimeZoneHelper from '../../helpers/TimeZoneHelper';

import Header from '../../../components/header/Header';
import PageHeader from '../../../components/header/PageHeader';
import Footer from '../../../components/footer/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import TemperatureSlider from '../../../components/range/TemperatureSlider';

class BoilerApiV2 extends React.Component 
{

    constructor(props) {
        super(props);

        this.state = {
            programState: 'manual',
            newDeviceMode: '',
            popoverMenu: [],
            popoverState: { showPopover: false, event: undefined },
            alert: {
                isOpen: false,
                header: '',
                message: ''
            }
        }
    }

    componentDidMount = async () => {

        this.OldDevicesSettingsHelper = new OldDevicesSettingsHelper(this.mac);

        if (this.device !== null && this.device !== undefined) {

            let mode = 'manual';

            if (this.device.DeviceStatus.mode === this.model.modes['Program P1']) {
                mode = 'P1';
            }

            if (this.device.DeviceStatus.mode === this.model.modes['Program P2']) {
                mode = 'P2';
            }

            if (this.device.DeviceStatus.mode === this.model.modes['Program P3']) {
                mode = 'P3';
            }

            const { DeviceStatus: {extr}} = this.device;
            const extrData = TimeZoneHelper.decodeExtr(extr);
            this.zonename = extrData.tzname;

            this.setState({
                programState: mode   
            });
        }
    }

    changeDeviceControl = control => {

        if (control === 'manual' && this.programsArray.includes(this.device.DeviceStatus.mode)) {
            this.handleDeviceMode('on', this.model.modes.Manual);
        } else {
            this.setState({programState: control})
        }
    }

    handleDeviceMode = (status, mode) => {

        const modeName = Object.keys(this.model.modes).find(key => this.model.modes[key] === mode);

        if (status === 'on') {

            this.setState({
                newDeviceMode: mode,
                alert: {
                    isOpen: true,
                    header: this.props.t(`${modeName}_mode_header`),
                    message: this.props.t(`${modeName}_mode_message`)
                }
            })

        } else {

            this.handleDeviceMode('on', this.model.modes.Manual);
        }
    }

    render = () => {

        this.mac = this.props.match.params.id;
        const { t } = this.props;
        const {devices} = this.props;

        this.device = devices[this.mac];
         
        if (!this.device || this.device === undefined) {
            return null;
        }

        this.model = oldModels[this.props.match.params.model_name]
        this.model_name = this.props.match.params.model_name
        this.programsArray = [this.model.modes['Program P1'], this.model.modes['Program P2'] , this.model.modes['Program P3']];

        const { DeviceStatus: status, DeviceOnline} = this.device;

        let minTemp = null;
        let maxTemp = null;
        let bHelper = null;
        const isBelislimo = this.model_name.indexOf('2002-') >= 0 ? true : false;

        if (isBelislimo) {

            bHelper = new BelislimoHelper(this.device);
            minTemp = 0;
            maxTemp = bHelper.getShowers();

        } else {
            minTemp = this.model.minTemp;
            maxTemp = this.model.maxTemp;
        }   

        if (status.vac !== undefined && status.vac == "1") {
            status.mode = "10";
        }

       const StatusModeName = Object.keys(this.model.modes).find(key => this.model.modes[key] === status.mode);
       const mode = Object.keys(this.model.modes).find(key => this.model.modes[key] === status[this.model.commands.mode]);
       const defaultIndex = {
            0: 0,
            1: 1,
            2: 2,
            3: 3
        }

        return (
            <IonPage>
                <Header />
                <IonContent className="ion-no-padding">
                    <div className="dashboard">
                        <div className="dashboard__wrapper">
                            
                            <BoilerApiV2Menu activeMenu="controlPanel" model_name={this.props.match.params.model_name} device={this.device} model={this.model}/>

                            <div className="dashboard__content">
                                
                                <PageHeader name={t('control_panel')}/>

                                <div className="dashboard-box">
                                    <div className="dashboard-box__header">

                                        {DeviceOnline === 0 && 
                                            <div className="ion-text-center pb-20">
                                                <div className="device-controls__label active">{t('offline')}</div>
                                            </div>
                                        }

                                        <IonGrid className="px-0 py-0">
                                            <IonRow className="ion-justify-content-between">
                                                <IonCol size-xs="12" size-md="auto" className="px-0 py-0">
                                                    <IonRow>
                                                        <IonCol size-xs="6" size-md="auto" className="py-0 ion-padding-end">
                                                            <div className="text-bold text-black mb-10">{t('ewh_currrent_temperature')}</div>
                                                            <div className={`device-temperature device-temperature--alt ${status[this.model.commands.power] === this.model.powerOn ? '' : 'device-temperature--off'}`}>
                                                                {status[this.model.commands.tempValueCurrent] ? status[this.model.commands.tempValueCurrent] : 0}{t('degree_unit')}
                                                            </div>
                                                        </IonCol>
                                                        <IonCol size-xs="6" size-md="auto" className="py-0 ion-padding-end">
                                                            <div className="text-bold text-black mb-10">{t('status')}</div>
                                                            <div className={`device-temperature ${status[this.model.commands.power] === this.model.powerOn ? '' : 'device-temperature--off'}`}>
                                                                <svg className="device-temperature__icon"><use xlinkHref="#icon-heating-alt"></use></svg>
                                                                {t(`${mode}_dashboard`)}
                                                            </div>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                                <IonCol size-xs="12" size-md="auto"className="px-0 py-0">
                                                    <IonRow>
                                                        
                                                        {status.mode != this.model.modes['Vacation'] && 
                                                            <IonCol size-xs="6" size-md="auto" className="py-0 ion-padding-end">
                                                                <IonButton 
                                                                    className={`device-button ${status.bst === '1' ? "device-button--active" : "device-button--lock"}`} 
                                                                    onClick={ e => this.OldDevicesSettingsHelper.setDeviceStatus('bst', status.bst === '1' ? '0' : '1') }
                                                                    disabled= {(status[this.model.commands.power] == this.model.powerOff || DeviceOnline !== 1) ? true : false}
                                                                >
                                                                    <div className="device-button__inner">
                                                                        <div className="device-button__icon">
                                                                            <svg className="device-button__icon-icon"><use xlinkHref="#icon-boost"></use></svg>
                                                                            <div className="device-button__icon-label">{t('on')}</div>
                                                                        </div>
                                                                        <IonLabel className="device-button__text">{t('boost')}</IonLabel>
                                                                    </div>
                                                                </IonButton>
                                                            </IonCol>
                                                        }
                                                        
                                                        <IonCol size-xs="6" size-md="auto" className="py-0 ion-padding-end">
                                                            <IonButton 
                                                                className={`device-button ${status[this.model.commands.power] === this.model.powerOn ? "device-button--active" : "device-button--lock"}`} 
                                                                onClick={ e => this.OldDevicesSettingsHelper.setDeviceStatus(this.model.commands.power, status[this.model.commands.power] == this.model.powerOn ? this.model.powerOff : this.model.powerOn) }
                                                            >
                                                                <div className="device-button__inner">
                                                                    <div className="device-button__icon">
                                                                        <svg className="device-button__icon-icon"><use xlinkHref="#icon-switch"></use></svg>
                                                                        <div className="device-button__icon-label">{t('on')}</div>
                                                                    </div>
                                                                    <IonLabel className="device-button__text">
                                                                        {status[this.model.commands.power] === this.model.powerOn ? t('ewh_active_status') : t('ewh_inactive_status')}    
                                                                    </IonLabel>
                                                                </div>
                                                            </IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </div>
                                    <div className="dashboard-box__body">
                                        <Tabs forceRenderTabPanel defaultIndex={defaultIndex[status.mode] != undefined ? defaultIndex[status.mode] : 0}>
                                            <TabList>
                                                <Tab  
                                                    disabled={(status[this.model.commands.power] === this.model.powerOff || (DeviceOnline === 0)) ? true : false}
                                                    onClick={() => this.changeDeviceControl('manual')}
                                                >
                                                    {t('ewh_manual')}
                                                </Tab>
                                                <Tab  
                                                    disabled={(status[this.model.commands.power] === this.model.powerOff || (DeviceOnline === 0)) ? true : false}
                                                    onClick={() => this.changeDeviceControl('P1')}
                                                >
                                                    {t('P1')}
                                                </Tab>
                                                <Tab  
                                                    disabled={(status[this.model.commands.power] === this.model.powerOff || (DeviceOnline === 0)) ? true : false}
                                                    onClick={() => this.changeDeviceControl('P2')}
                                                >
                                                    {t('P2')}
                                                </Tab>
                                                <Tab  
                                                    disabled={(status[this.model.commands.power] === this.model.powerOff || (DeviceOnline === 0)) ? true : false}
                                                    onClick={() => this.changeDeviceControl('P3')}
                                                >
                                                    {t('P3')}
                                                </Tab>
                                            </TabList>
    
                                            <TabPanel>
                                                
                                                {(status.mode === this.model.modes.Manual || status[this.model.commands.power] == this.model.powerOff) ?
                                                    <div className="pt-20">
                                                        <div className="wrapper wrapper--xs">
                                                            <TemperatureSlider 
                                                                model={this.model_name}
                                                                label={!isBelislimo ? t('ewh_target_temperature') : t('target_showers')}
                                                                disabled={(status[this.model.commands.power] === this.model.powerOff || DeviceOnline === 0 || status[this.model.commands.boost] == 1) ? true : false}
                                                                currentTemp={status[this.model.commands.tempValue] ? Number(status[this.model.commands.tempValue]) : this.model.minTemp}
                                                                minTemperature={minTemp}
                                                                maxTemperature={maxTemp}
                                                                stepTemperature={1}
                                                                handleTemperature={value => this.OldDevicesSettingsHelper.setDeviceStatus(this.model.commands.temp, value)}
                                                            />
                                                        </div>
                                                    </div>
                                                :
                                                    <div className="pt-20">
                                                        <div className="device-mode">
                                                            <div className="device-mode__start">
                                                                <svg className={`device-mode__icon ${this.model.modesSettings[status.mode].class}`}>
                                                                    <use xlinkHref={this.model.modesSettings[status.mode].image}></use>
                                                                </svg>
                                                            </div>
                                                            <div className="device-mode__end">
                                                                <div className="device-mode__title">{`${t('your_device_is_in')} "${t(mode)}"`}</div>
                                                                <div className="target-list">
                                                                    { status.dtime > 0 &&
                                                                        <div className="target-list__item">
                                                                            <div className="target__label">{t(`until`)}: {TimeHelper.addTimeInMinutes(this.zonename, status.dtime)}</div>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                {status.mode === this.model.modes['Vacation'] &&
                                                                    <div>
                                                                        <IonButton color="tertiary" routerLink={`/old/apiv2/device-settings/${this.model_name}/${this.mac}`}>
                                                                            <span className="button__text">{t('edit')}</span>
                                                                        </IonButton>
                                                                        <IonButton color="primary" onClick={() => this.handleDeviceMode('on', this.model.modes.Manual)}>
                                                                            <span className="button__text">{t('deactivate')}</span>
                                                                        </IonButton>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                <div className="pt-20">
                                                    <IonToolbar className="bordered">
                                                        <IonButtons className="ion-justify-content-between">

                                                            {this.model.ecoFunctions.map(eco => (
                                                                <DeviceTabButton 
                                                                    key={eco}
                                                                    name={t(eco)}
                                                                    icon={this.model.ecoIcons[eco]} 
                                                                    handleStatus={status => this.handleDeviceMode(status, this.model.modes[eco])}
                                                                    status={(status.mode === this.model.modes[eco]) ?  'on' : 'off'}
                                                                    disabled= {(status[this.model.commands.power] == this.model.powerOff || DeviceOnline !== 1) ? true : false}
                                                                />
                                                            ))}

                                                            <DeviceTabButton 
                                                                name={t("Vacation")}
                                                                icon={'palm'} 
                                                                //handleStatus={status => this.handleDeviceMode(status, this.model.modes['Vacation'])}
                                                                handleStatus={newStatus => {
                                                                    status.mode === this.model.modes['Vacation'] ? 
                                                                        this.handleDeviceMode(newStatus, this.model.modes['Vacation'])
                                                                    :
                                                                        this.props.history.push(`/old/apiv2/device-settings/${this.model_name}/${this.mac}`)
                                                                }}
                                                                status={(status.mode === this.model.modes['Vacation']) ?  'on' : 'off'}
                                                                disabled= {(status[this.model.commands.power] == this.model.powerOff || DeviceOnline !== 1) ? true : false}
                                                            />
                                                        
                                                        </IonButtons>
                                                       
                                                        <IonAlert
                                                            isOpen={this.state.alert.isOpen}
                                                            onDidDismiss={() => this.setState({alert: {...this.state.alert, isOpen: false}, newDeviceMode: ''})}
                                                            header={this.state.alert.header}
                                                            message={`"${t(StatusModeName)}" ${t('switch_off_control')}`}
                                                            buttons={
                                                                [{
                                                                    text: t('cancel'),
                                                                    handler: () => {
                                                                        this.setState({
                                                                            alert: {...this.state.alert, isOpen: false},
                                                                            newDeviceMode: '',
                                                                        })
                                                                    }
                                                                },
                                                                {
                                                                    text: t('ok'),
                                                                    handler: () => {

                                                                        this.props.updateOldDeviceState( this.state.newDeviceMode, this.model.commands.mode, this.device.DeviceSerial);

                                                                        this.setState({
                                                                            programState: !this.programsArray.includes(this.state.newDeviceMode) ? 'manual' : this.state.programState
                                                                        });

                                                                        
                                                                        this.OldDevicesSettingsHelper.setDeviceStatus(this.model.commands.mode, this.state.newDeviceMode.toUpperCase());
                                                                    }
                                                                }]
                                                            }
                                                        />

                                                    </IonToolbar>
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                {/* TO DO: Ask what to do here */}
                                                <div className="pt-20">
                                                    <div className="program-status">
                                                        <div className="program-status__col">
                                                            <IonRow className="ion-text-center">
                                                                <IonCol>
                                                                    <IonButton routerLink={`/old/apiv2/program/${this.model_name}/${this.mac}/3/P1`}>{t('edit_program')}</IonButton>
                                                                </IonCol>
                                                                <IonCol>
                                                                    <IonButton 
                                                                        color="tertiary"
                                                                        onClick={() => this.handleDeviceMode((status.mode === this.model.modes[`Program ${this.state.programState}`]) ? 'off' : 'on', this.model.modes[`Program ${this.state.programState}`])}
                                                                    >
                                                                        <span className="button__text">{(status.mode === this.model.modes[`Program ${this.state.programState}`]) ? t('deactivate') : t('activate')}</span>
                                                                    </IonButton>
                                                                </IonCol>
                                                            </IonRow>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel>
                                                {/* TO DO: Ask what to do here */}
                                                <div className="pt-20">
                                                    <div className="program-status">
                                                        <div className="program-status__col">
                                                            <IonRow className="ion-text-center">
                                                                <IonCol>
                                                                    <IonButton routerLink={`/old/apiv2/program/${this.model_name}/${this.mac}/3/P2`}>{t('edit_program')}</IonButton>
                                                                </IonCol>
                                                                <IonCol>
                                                                    <IonButton 
                                                                        color="tertiary"
                                                                        onClick={() => this.handleDeviceMode((status.mode === this.model.modes[`Program ${this.state.programState}`]) ? 'off' : 'on', this.model.modes[`Program ${this.state.programState}`])}
                                                                    >
                                                                        <span className="button__text">{(status.mode === this.model.modes[`Program ${this.state.programState}`]) ? t('deactivate') : t('activate')}</span>
                                                                    </IonButton>
                                                                </IonCol>
                                                            </IonRow>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel>
                                                {/* TO DO: Ask what to do here */}
                                                <div className="pt-20">
                                                    <div className="program-status">
                                                        <div className="program-status__col">
                                                            <IonRow className="ion-text-center">
                                                                <IonCol>
                                                                    <IonButton routerLink={`/old/apiv2/program/${this.model_name}/${this.mac}/3/P3`}>{t('edit_program')}</IonButton>
                                                                </IonCol>
                                                                <IonCol>
                                                                    <IonButton 
                                                                        color="tertiary"
                                                                        onClick={() => this.handleDeviceMode((status.mode === this.model.modes[`Program ${this.state.programState}`]) ? 'off' : 'on', this.model.modes[`Program ${this.state.programState}`])}
                                                                    >
                                                                        <span className="button__text">{(status.mode === this.model.modes[`Program ${this.state.programState}`]) ? t('deactivate') : t('activate')}</span>
                                                                    </IonButton>
                                                                </IonCol>
                                                            </IonRow>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </IonContent>
                <Footer />
            </IonPage>
        );
    }
}


const mapStateToProps = state => {
    return {
        devices: state.oldDevices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps,{
        updateOldDeviceState
    })
)(BoilerApiV2);