import {
    IonContent,
    IonFooter,
    IonButton,
    IonPage,
    IonToolbar,
    IonText,
    IonSelect,
    IonSelectOption,
    IonLabel,
    IonItem,
    IonInput,
} from '@ionic/react';

import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { Device } from '@capacitor/device';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import dayjs from 'dayjs';

import {
    chooseDevice,
    setDeviceWifiArray,
    chooseNetwork
} from '../../../../redux/actions/NewDeviceAction';
import { setAppMessage, logger } from '../../../../redux/actions/AppAction';

import StandartHeader from '../../components/header/StandartHeader';
import ToastMessage from '../../components/toast/ToastMessage';
import Loading from '../../components/loading/Loading';
import WifiHelper from '../../../../helpers/WifiHelper';
import DeviceRestHelper from '../../../../helpers/DeviceRestHelper';
import AppHelper from '../../../../helpers/AppHelper';
import chooseDeviceImage from '../../../../files/chooseDevice.svg';
import { modelsSettings } from '../../../../config/App';

class ChooseDevice extends React.Component {

    state = {
        networks: [],
        selectedNetwork: "",
        appInfo: {},
        build: null,
        version: null,
        showLoading: false
    }

    componentDidMount = async () => {

        const info = await Device.getInfo();
        const {build , version} = await AppHelper.getAppVersion();

        this.setState({
            appInfo: info,
            build,
            version,
            //selectedNetwork: this.props.newDevice.deviceSSID
            selectedNetwork: "HPWH-c8c8"
        });

        document.removeEventListener('ionBackButton', (ev) => {
            document.body.classList.remove("qrscanner");
        });

        document.addEventListener('ionBackButton', (ev) => {
            document.body.classList.remove("qrscanner");
        });
    }


    /**
     * Remember the origin app netwokr
     * Connect to static device wifi name (IOS / Android)
     * Extract wifi array from device
     * Set device data into redux state 
     */

    continue = async () => {


        this.props.logger(dayjs().format("HH:mm:ss")+" STEP 1: Connect to device AP (Tesy-CN06AS) \n\n");
        this.setState({
            showLoading: true
        });

        //TO DO: Extract the right data from settings for the right device
        const { http_api_url, ap_name } = modelsSettings.HPWH4_11_260

        const deviceRest = new DeviceRestHelper(http_api_url);

        this.props.chooseDevice(ap_name);
        
        try {

            this.props.logger(`1.Trying to connect from phone: ${this.state.appInfo.manufacturer} ${this.state.appInfo.model}, os version: ${this.state.appInfo.osVersion}, app version: ${this.state.version} (${this.state.build})`);

            if (this.state.appInfo.platform === 'ios') {
                await WifiHelper.connectToNetworkIOS(ap_name);
            } else {
                await WifiHelper.connectToNetwork(ap_name, "");
            }

            deviceRest.post('/wifi', {
                command: 'wifi'
            })
            .then(async res => {

                let {connected, wifi} = res.data;
                this.props.setDeviceWifiArray(wifi);

                this.setState({
                    showLoading: false
                });

                this.props.history.push('/connect/hpwh4_11_260/change-wifi');

            })
            .catch (error => {

                this.setState({
                    showLoading: false
                })
                
                this.props.logger(`ChooseDevice: Cannot take the wifi response from device: ${error.message}`);
                this.props.setAppMessage(this.props.t('device_connection_problem'));
            })

        } catch (e) {

            this.setState({
                showLoading: false
            })

            this.props.logger(`ChooseDevice: Cannot connect to device network: ${e}`);
            this.props.setAppMessage(this.props.t('device_connection_problem'));
        }
    }

    scanQR = async () => {
        // Check camera permission
        // This is just a simple example, check out the better checks below

        const {asked, granted} = await BarcodeScanner.checkPermission({ force: true });

        if (granted !== undefined && granted === true) {


            // make background of WebView transparent
            // note: if you are using ionic this might not be enough, check below
            BarcodeScanner.hideBackground();

            document.body.classList.add("qrscanner"); 

            const result = await BarcodeScanner.startScan(); // start scanning and wait for a result

            document.body.classList.remove("qrscanner");
            // if the result has content
            if (result.hasContent) {

                const {SSID} = JSON.parse(result.content);
                this.setState({selectedNetwork: SSID});
                global.debug(SSID, "SSID");

            } else {
                this.props.setAppMessage(this.props.t("cant_scan_qr"));
            }
    
        } else {
            this.props.setAppMessage(this.props.t('camera_permissions_not_allowed'));
        }
    }

    render = () => {
        const { t } = this.props;

        return (
            <IonPage>        
                
                <StandartHeader name={t('choose_device')} />

                <IonContent className="ion-padding">
                    <div className="circle-image">
                        <img src={chooseDeviceImage} />
                    </div>                
                    
                    {/* <IonLabel color="primary">{t('device')}</IonLabel>
                    
                    <IonItem lines="full" className="form-list__item">
                        <IonLabel position="stacked" color="primary" className="form-label"></IonLabel>
                        <IonInput 
                            type="text" 
                            className="form-input" 
                            placeholder='HPWH-XXXXX' 
                            value={this.state.selectedNetwork}
                            onIonInput={ e => this.setState({selectedNetwork: e.detail.value !== '' ? `${e.detail.value}` : ''})}
                        />
                    </IonItem>


                    <ToastMessage /> */}

                    <hr />
                    {/* TO DO: Ask for texts */}
                    <IonText>
                    <p>{t('ch_dev_text1')}</p>
                        <p>{t('ch_dev_text2')}</p>
                        <ul>
                            <li>{t('ch_dev_text3')}</li>
                            <li>{t('ch_dev_text4')}</li>
                            <li>{t('ch_dev_text5')}</li>
                            <li>{t('ch_dev_text6')}</li>
                            <li>{t('ch_dev_text7')}</li>
                        </ul>
                    </IonText>

                    <Loading show={this.state.showLoading}/>

                </IonContent>
                
                <IonFooter className="app-footer ion-no-border">
                    <IonToolbar>
                        <div className="buttons-list">
                            <div className="buttons-list__item">
                                <IonButton expand="block" shape="round" size="large" onClick={this.continue}>{t('continue')}</IonButton>
                            </div>
                            {/* <div className="buttons-list__item">
                                <IonButton expand="block" shape="round" size="large" onClick={this.scanQR}>{t('scanQR')}</IonButton>
                            </div> */}
                        </div>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        );
    }
    
};

const mapStateToProps = state => {
    return {
        newDevice: state.newDevice
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        chooseDevice,
        setAppMessage,
        setDeviceWifiArray,
        chooseNetwork,
        logger
    })
)(ChooseDevice);
  