import {
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonPage,
    IonFooter,
    IonButtons,
    IonButton,
    IonBackButton
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import RestHelper from '../../../helpers/RestHelper';
import B15MqttHelper from '../../../helpers/mqqt/B15MqttHelper';
import Header from '../components/header/Header';
import Menu from './components/Menu';
import PageHeader from '../components/header/PageHeader';
import Footer from '../components/footer/Footer';

const DeviceCalculator = props => {

    const { t } = useTranslation();
    const [totalKwh, setTotalKwh] = useState(0);

    const { 
        devices, 
        match: {
            params: {
                mac_address: mac
            }
        } 
    } = props;

    const device = devices[mac];
    const restHelperOBJ = new RestHelper();
    const B15MqttHelperOBJ = new B15MqttHelper(mac);

    useEffect(() => {

        getDivicePowerStat();
    
    }, [])

    const getDivicePowerStat = async () => {
        const {data} = await restHelperOBJ.get('/get-device-power-stat', {
            model: device.model_id,
            timezone: device.timezone,
            activity: "total",
            mac: mac     
        });

        setTotalKwh(data.total);
    }

    const reset = () => {
        B15MqttHelperOBJ.request('resetEnergyConsumption');

        getDivicePowerStat();
    }


    return (
        <IonPage>
            
            <Header />

            <IonContent className="ion-no-padding">
                <div className="dashboard">
                    <div className="dashboard__wrapper">

                        
                        <Menu activeMenu="calculator" mac={props.match.params.mac_address}/>

                        <div className="dashboard__content">
                            
                            <PageHeader name={t('calculator_button')} image="calc"/>

                            <div className="dashboard-box">

                                <div className="dashboard-box__body">

                                    <div className={`range-circle`}>
                                        <div className="range-circle__inner">
                                            <div className="range-circle__metric">{t('kWh')}</div>
                                            <div className="range-circle__value">{totalKwh}</div>
                                            {device.state.energyCalcReset && 
                                                <div className="range-circle__label"> {t('since')} {device.state.energyCalcReset}</div>
                                            }

                                            {/* ТО DO: How to extract the info */}
                                            {/* <div className="range-circle__option">{device.state.device_config.capacity}{t('hp_calc_model_size_unit')}</div> */}
                                        </div>
                                    </div>
                                    
                                    <div className="range-buttons js-range-buttons">
                                        <div className="range-buttons__item">
                                            <IonButton shape="round" size="default" onClick={reset}>{t('reset')}</IonButton>
                                        </div>
                                        <div className="range-buttons__item">
                                        <IonButton shape="round" size="default" fill="outline" color="medium" routerLink={`/device/calculator-history/${device.model.toLowerCase()}/${mac}`}>{t('history')}</IonButton>
                                        </div>
                                    </div>

                                    <div className="ion-content-push"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </IonContent>
            <Footer />
        </IonPage>
    );
};

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    connect(mapStateToProps, {})
)(DeviceCalculator);
