import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { AreaChart, Area, XAxis, YAxis, Tooltip } from 'recharts';
import { withTranslation } from 'react-i18next';
import './calculator-chart.scss';

const ColorConfig = {
  "green": "#7cae2e",
  "gray": "#e8e8e8",
  "gray2": "#4a4a4a",
  "gray3": "#9b9b9b",
  "gray4": "#f3f4f2",
  "white": "#fff"
}

const CalculatorChart = props => {

  const calculator_data = [
    { year: '', month: '', consumption: 0 },
    { year: '2020', month: 'Dec', consumption: 1.2000 },
    { year: '2020', month: 'Jan', consumption: 3.0059 },
    { year: '2020', month: 'Feb', consumption: 1.0590 },
    { year: '2020', month: 'Mar', consumption: 2.7812 },
    { year: '2020', month: 'Apr', consumption: 0.5044 },
    { year: '2020', month: 'May', consumption: 1.2219 },
    { year: '2020', month: 'Jun', consumption: 2.3093 },
    { year: '2020', month: 'Jul', consumption: 1.2455  },
    { year: '2020', month: 'Jan', consumption: 3.0059 },
    { year: '2020', month: 'Feb', consumption: 0.000001 },
    { year: '2020', month: 'Mar', consumption: 2.7812 },
    { year: '2020', month: 'Apr', consumption: 2.2244 },
    { year: '2020', month: 'May', consumption: 0.1 },
    { year: '2020', month: 'Jun', consumption: 0.4493 },
    { year: '2020', month: 'Jul', consumption: 1.2455  },
  ]

  const chartHeight = 310;
  const chartLineWidth = 60;
  const chartWidth = props.data.length * chartLineWidth;

  const fixChartLabels = () => {
    const calculatorChart = document.querySelector('.calculator-chart');
    const calculatorChartLabels = calculatorChart.querySelectorAll('.recharts-label');
    
    calculatorChartLabels.forEach((calculatorChartLabel) => {
      let calculatorChartLabelX = Number(calculatorChartLabel.querySelector('tspan').getAttribute('x')) - chartLineWidth/2;
      let calculatorChartLabelY = Number(calculatorChartLabel.querySelector('tspan').getAttribute('dy').replace('em', '')) - 1.177;
      
      calculatorChartLabel.querySelector('tspan').setAttribute('x', `${calculatorChartLabelX}`);
      calculatorChartLabel.querySelector('tspan').setAttribute('dy', `${calculatorChartLabelY}em`);

    });
  };

  return (
    <React.Fragment>
      <div className="calculator-chart__wrapper">
        <ScrollContainer vertical={false}>
          <AreaChart
            height={chartHeight}
            width={chartWidth}
            data={props.data}
            margin={{ top: 130, right: 0, left: 0, bottom: 0 }}
            className="calculator-chart"
            >
            <defs>
                <linearGradient id="fillGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="50%" stopColor={ColorConfig["gray4"]} stopOpacity={1}/>
                  <stop offset="100%" stopColor={ColorConfig["white"]} stopOpacity={0}/>
                </linearGradient>
            </defs>

            <Area type="stepBefore" dataKey="consumption" stroke={ColorConfig["green"]} strokeWidth="1" label={{ fill: ColorConfig["green"], fontSize: 13}} fill="url(#fillGradient)" onAnimationEnd={fixChartLabels} />

            <YAxis domain={['dataMin-2', 'dataMax+2']} />
            <XAxis xAxisId={0} dataKey="main_label" axisLine={{ stroke: ColorConfig["gray"] }} tickLine={{ stroke: ColorConfig["green"] }} tick={{fontSize: 14, fontWeight: 300, fill: ColorConfig["gray2"]}} dx={-chartLineWidth/2} dy={3} />
            <XAxis xAxisId={1} dataKey="sub_label" axisLine={false} tickLine={false} tick={{fontSize: 12, fontWeight: 300, fill: ColorConfig["gray3"]}} dx={-chartLineWidth/2} dy={-10} />
            <Tooltip />
          </AreaChart>
        </ScrollContainer>
        <div className="calculator-chart__yaxis">
          <div className="calculator-chart__yaxis-value">{props.t('KWh')}</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(CalculatorChart);