import {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonButton,
    IonAlert
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import Header from './components/header/Header';
import PageHeader from './components/header/PageHeader';
import Footer from './components/footer/Footer';
import DashboardMenu from './components/menu/DashboardMenu';

import RestHelper from '../../helpers/RestHelper';
import InitialSettings from '../../init';

import {
    setPushNofications,
    readPushNofication,
    deletePushNofication,
    deleteAllPushNofications
} from '../../redux/actions/AppAction';

class Notifications extends React.Component {

    constructor(props) {
        super(props);

        this.RestHelper = null;

        this.state = {
            showDeleteAlert: false,
            showDeleteAllAlert: false,
            notificationID: 0
        }
    }

    componentDidMount = async () => {
        
        this.RestHelper = new RestHelper();

        const messages = await this.RestHelper.get('/get-my-messages');
        this.props.setPushNofications(messages.data);
    }

    renderMessages = messages => {
        const data = [];

        for (const i in messages) {

            const { title, message_id, is_read, id, date } = messages[i];
            const [model, title_key] = title.split(': ');

            data.push(
                <IonItem key={i} button className={`notifications-list__item ${is_read ? '' : 'unread'}`}>
                    <span slot="start" onClick={() => this.readNotification(id)}>{date} <br/> {model}: {this.props.t(title_key)}</span>
                    <IonButton color="transparent" className="group-list__button" onClick={() => this.setState({showDeleteAlert: true, notificationID: id})} slot="end">
                        <svg className="group-list__button-icon fill-red"><use xlinkHref="#icon-delete"></use></svg>
                    </IonButton>
                </IonItem>
            )
        }

        return data;    
    }

    readNotification = message_id => {

        this.RestHelper.post('/read-message', {
            message_id: message_id    
        });

        this.props.readPushNofication(message_id);

        this.props.history.push(`/notification/${message_id}`)
    }

    deleteNotification = async () => {

        this.RestHelper.post('/delete-message', {
            message_id: this.state.notificationID    
        });

        this.props.deletePushNofication(this.state.notificationID);
        this.setState({showDeleteAlert: false, notificationID: 0});   
    }

    deleteAllNotifications = () => {
        this.RestHelper.post('/delete-all-messages');

        this.props.deleteAllPushNofications();
        this.props.history.push("/dashboard");
        this.setState({showDeleteAllAlert: false});        
    }

    render = () => {

        const { app: { push_notifications_messages }, t } = this.props;

        return (
            <IonPage>
                <Header />
                <IonContent className="ion-no-padding">
                    <div className="dashboard">
                        <div className="dashboard__wrapper">

                            <DashboardMenu />

                            <div className="dashboard__content">

                                <PageHeader name={t('notifications')} image="notifications"/>

                                {push_notifications_messages.length > 0 && 
                                    <div className="dashboard-box">
                                        <div className="dashboard-box__body">
                                            <div className="ion-text-right pb-10">
                                                <IonButton color="delete" onclick={() => this.setState({showDeleteAllAlert: true})}>{this.props.t('delete_program')}</IonButton>
                                            </div>
                                            <IonList className="notifications-list">
                                                {this.renderMessages(push_notifications_messages)}
                                            </IonList>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <IonAlert
                        isOpen={this.state.showDeleteAlert}
                        onDidDismiss={() => this.setState({showDeleteAlert: false})}
                        cssClass='my-custom-class'
                        header={this.props.t('confirm')}
                        message={this.props.t('confirm_notification_delete')}
                        buttons={[
                            {
                                text: this.props.t('cancel'),
                                role: 'cancel',
                                cssClass: 'secondary',
                                handler: blah => {
                                    this.setState({showDeleteAlert: false});
                                }
                            },
                            {
                                text: this.props.t('ok'),
                                handler: () => {
                                    this.deleteNotification()
                                }
                            }
                        ]}
                    />
                    <IonAlert
                        isOpen={this.state.showDeleteAllAlert}
                        onDidDismiss={() => this.setState({showDeleteAllAlert: false})}
                        cssClass='my-custom-class'
                        header={this.props.t('confirm')}
                        message=""
                        buttons={[
                            {
                                text: this.props.t('cancel'),
                                role: 'cancel',
                                cssClass: 'secondary',
                                handler: blah => {
                                    this.setState({showDeleteAllAlert: false});
                                }
                            },
                            {
                                text: this.props.t('ok'),
                                handler: () => {
                                    this.deleteAllNotifications()
                                }
                            }
                        ]}
                    />
                </IonContent>
                <Footer />
            </IonPage>
        );
    }
};

const mapStateToProps = state => {
    return {
        app: state.app
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        setPushNofications,
        readPushNofication,
        deletePushNofication,
        deleteAllPushNofications
    })
)(Notifications);  