import { useTranslation } from 'react-i18next';

const CurrentModeInfo = props => {

    const { t } = useTranslation();

    return (
        <div className="ion-text-center">
            <svg className={`icon-118 fill-light-green ${props.disableDevice ? 'unactive' : ''}`}>
                <use xlinkHref={`#${props.icon}`}></use>
            </svg>
            <div className={`text-grey text-12 ${props.disableDevice ? 'unactive' : ''}`}>{t('hp_current_mode')} {t(`hp_mode_${props.mode}`)}</div>
        </div>
    )
}

export default CurrentModeInfo;