import basicModel from '../basicModel';
import {
    updateDeviceState,
} from '../../redux/actions/DevicesAction';

const VACATION_MODE = "vac";
const MANUAL_MODE = "man";

export default class B15 extends basicModel {

    constructor(deviceData) {

        super(deviceData);

        this.table_fields = {
            id: "id",
            dateUpdate: "updated_at",
            dateEnter: "created_at",
            macAddr: "mac",
            standBy: "stand_by",
            mode: "mode",
            setTemp: "set_temp", //Set TEMP
            currentTemp: "current_temp",
            boost: "boost",
            vacation: "vacation",
            vacDaysCn: "vacDaysCn",
            programP1: "program_p1",
            programP2: "program_p2",
            programP3: "program_p3",
            weekProgDay: "weekProgDay",
            programCurrentSlot: "program_current_slot",
            disinfection: "disinfection",
            antifrost: "antifrost",
            heatLevelReached: "heat_level_reached",
            heaterState: "heater_state",
            energyCalcReset: "energyCalcReset",
            errorInd: "errorInd",
            fw_version: "fw_version"
        }
    }

    async getStatus() {
        const {payload: { 
            currMode,
            standBy,
            boost,
            disinfection,
            antifrost,
            vacDaysCntr,
            weekProgDay,
            weekProgTimeSlot,
            setTemp,
            currTemp,
            heatLevelReached,
            heaterState,
            energyCalcReset,
            errorInd,
            version
        }} = this.payload;

        this.store.dispatch(updateDeviceState(standBy, this.table_fields.standBy, this.macAddr));
        this.store.dispatch(updateDeviceState(currMode, this.table_fields.mode, this.macAddr));
        this.store.dispatch(updateDeviceState(boost, this.table_fields.boost, this.macAddr));
        this.store.dispatch(updateDeviceState(disinfection, this.table_fields.disinfection, this.macAddr));
        this.store.dispatch(updateDeviceState(antifrost, this.table_fields.antifrost, this.macAddr));
        this.store.dispatch(updateDeviceState(vacDaysCntr, this.table_fields.vacDaysCn, this.macAddr));
        this.store.dispatch(updateDeviceState(weekProgDay, this.table_fields.weekProgDay, this.macAddr));
        this.store.dispatch(updateDeviceState(weekProgTimeSlot, this.table_fields.programCurrentSlot, this.macAddr));
        this.store.dispatch(updateDeviceState(setTemp, this.table_fields.setTemp, this.macAddr));
        this.store.dispatch(updateDeviceState(currTemp, this.table_fields.currentTemp, this.macAddr));
        this.store.dispatch(updateDeviceState(heatLevelReached, this.table_fields.heatLevelReached, this.macAddr));
        this.store.dispatch(updateDeviceState(heaterState, this.table_fields.heaterState, this.macAddr));
        this.store.dispatch(updateDeviceState(energyCalcReset, this.table_fields.energyCalcReset, this.macAddr));
        this.store.dispatch(updateDeviceState(errorInd, this.table_fields.errorInd, this.macAddr));   
        this.store.dispatch(updateDeviceState(version, this.table_fields.fw_version, this.macAddr));   
    }

    setMode() {
        const {payload: { mode,  temp, endDate, endHour}} = this.payload;

        if (mode == VACATION_MODE) {
            this.store.dispatch(updateDeviceState({mode,  temp, endDate, endHour}, this.table_fields.vacation, this.macAddr));
        } else if (mode == MANUAL_MODE) {
            this.store.dispatch(updateDeviceState(mode, this.table_fields.mode, this.macAddr));
            this.store.dispatch(updateDeviceState(temp, this.table_fields.setTemp, this.macAddr));
        } else {
            this.store.dispatch(updateDeviceState(mode, this.table_fields.mode, this.macAddr));
        }
    }

    setStandby() {
        const {payload: { setState}} = this.payload;

        this.store.dispatch(updateDeviceState(setState, this.table_fields.standBy, this.macAddr));
    }

    setBoost() {
        const {payload: { state}} = this.payload;

        this.store.dispatch(updateDeviceState(state, this.table_fields.boost, this.macAddr));
    }

    setWeeklyProgram() {
        const {payload: {weekday, progID}} = this.payload;
        this.store.dispatch(updateDeviceState({...weekday}, `program_${progID}`, this.macAddr));
    }
}