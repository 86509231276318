import {
    IonButton,
    useIonAlert,
    IonLabel,
    IonToggle
} from '@ionic/react';
import React, {useState} from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { Link } from 'react-router-dom';
import { compose } from 'redux'
import { connect } from 'react-redux'

import RestHelper from '../../../../helpers/RestHelper';
import E32Helper from "../../../../helpers/E32Helper";
import Loading from '../../components/loading/Loading';

import InitialSettings from '../../../../init';

const E32Menu = props => {

    const [showLoading, setShowLoading] = useState(false);
    const {devices} = props;
    const device = devices[props.mac];
    const E32HelperOBJ = new E32Helper(device);

    const [present] = useIonAlert();

    if (props.mac !== undefined && (!device || device === undefined)) {
        return null;
    }
    
    const menuList = [
        {
            'title': props.t('dashboard'),
            'icon': 'dashboard',
            'active': props.activeMenu === 'dashboard' ? true : false,
            'link': '/dashboard'
        },
        {
            'title': props.t('control_panel'),
            'icon': 'dashboard',
            'active': props.activeMenu === 'controlPanel' ? true : false,
            'link': `/device/${device.model}/${props.mac}`
        },
        {
            'title' : props.t('calculator_button'),
            'icon' : 'calc',
            'link' : `/device/calculator/${device.model.toLowerCase()}/${props.mac}`,
            'active': props.activeMenu === 'calculator' ? true : false,
        },
        {
            'title' : props.t('statistics'),
            'icon' : 'statistic',
            'link' : `/device/statistics/${device.model.toLowerCase()}/${props.mac}`,
            'active': props.activeMenu === 'statistic' ? true : false,
        },
        {
            'title' : props.t('add_to_group'),
            'icon' : 'add-to-folder',
            'link' : `/add-to-group/${props.mac}`,
            'active': props.activeMenu === 'deviceGroup' ? true : false,
        },
        {
            'title' : props.t('device_info'),
            'icon' : 'device-info',
            'link' : `/device/device-info/${device.model.toLowerCase()}/${props.mac}`,
            'active': props.activeMenu === 'deviceInfo' ? true : false,
        },
        {
            'title' : props.t('world_clock'),
            'icon' : 'world-clock',
            'link' : `/device/world-clock/${device.model.toLowerCase()}/${props.mac}`,
            'active': props.activeMenu === 'worldClock' ? true : false,
        },
    ];

    const deleteDevice = () => {

        setShowLoading(true);

        const restHelper = new RestHelper();
        const initialSettingsOBJ = new InitialSettings();
        
        restHelper.post('/delete-user-device', {
            mac: props.mac
        })
        .then (async res => {

            await initialSettingsOBJ.init();

            setShowLoading(false);
            props.history.push('/dashboard');
            
        })
        .catch(error => {
            global.debug("Cannot delete device from server", error);
        })
        
    }
 
    return (
        <aside className="dashboard__aside">
            <div className="dashboard__aside-inner">
                <IonButton color="back-secondary" onClick={() => props.history.goBack()}>
                    <svg className="icon"><use xlinkHref="#icon-arrow-back"></use></svg>
                    <span className="button__text">{props.t('back')}</span>
                </IonButton>

                {props.mac !== undefined &&
                    <div className="device-preview">
                        <div className="device-preview__preview">
                            <img src={E32HelperOBJ.getImage()} className="device-preview__preview-image" alt="Device name" />
                        </div>
                        <div className="device-preview__text">
                            {device.deviceName}
                            <IonButton color="icon-only-transparent ion-margin-end" onClick={() =>
                                present({
                                    header: props.t('confirm_device_delete'),
                                    buttons: [
                                        { text: props.t('ok'), handler: (d) => deleteDevice() },
                                        { text: props.t('cancel'), handler: (d) => global.debug('no pressed') },
                                    ],
                                    //onDidDismiss: (e) => global.debug('did dismiss'),
                                })
                                }>
                                <svg className="icon icon-18"><use xlinkHref="#icon-delete"></use></svg>
                            </IonButton>
                            <IonButton color="icon-only-transparent" routerLink={`/name-device/${props.mac}`}>
                                <svg className="icon icon-18"><use xlinkHref="#icon-pencil"></use></svg>
                            </IonButton>
                        </div>
                    </div>
                }
                    
                <div className="aside-nav">
                    {menuList.map(({ active, title, icon, link }, i) => (
                        <div className="aside-nav__item" key={i}>
                            <Link to={link} className={`aside-nav__button ${active ? 'aside-nav__button--active' : ''}`}>
                                <svg className="aside-nav__button-icon"><use xlinkHref={`#icon-${icon}`}></use></svg>
                                {title}
                            </Link>
                        </div>
                    ))}
                </div>
            </div>

            <Loading show={showLoading}/>
        </aside>
    )
}

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
} 

export default compose(
    withTranslation(),
    withRouter,
    connect(mapStateToProps)
)(E32Menu);