import {
  IonContent,
  IonFooter,
  IonButton,
  IonItem,
  IonList,
  IonPage,
  IonToolbar,
  IonLabel,
  IonInput,
  IonCheckbox
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { InAppBrowser } from '@ionic-native/in-app-browser';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

//Import helpers
import UsersHelper from '../../helpers/UsersHelper';

//Import components
import StandartHeader from './components/header/StandartHeader';
import ToastMessage from './components/toast/ToastMessage';
import Loading from './components/loading/Loading';

//Import Redux Actions
import { setAppMessage } from '../../redux/actions/AppAction';

class SignUp extends React.Component {

  constructor(props){
    super(props);

    this.UsersHelper = new UsersHelper();
    this.state = {
        showLoading: false,
        passwordShown: false,
        passwordConfirmShown: false,
        email: '',
        password: '',
        confirmPassword: '',
        name: '',
        lastName: '',
        phone: '',
        accept_terms_conditios: false,
        receive_emails: false,
        errors: {}
    }
  }

  

  togglePasswordVisiblity = () => {
    this.setState({
      passwordShown: this.state.passwordShown ? false : true  
    });
  };

  toggleConfirmPasswordVisiblity = () => {
    this.setState({
      passwordConfirmShown: this.state.passwordConfirmShown ? false : true  
    });
  };

  signup = async () => {
    if (this.state.accept_terms_conditios === true) {

        this.setState({showLoading: true});

        try {

            await this.UsersHelper.signup({
              email: this.state.email,
              name: this.state.name,
              lastName: this.state.lastName,
              //phone: this.state.phone,
              password: this.state.password,
              confirmPassword: this.state.confirmPassword,
              lang: this.props.app.lang,
              subscribe: this.state.receive_emails
            });
      
            this.setState({showLoading: false});

            //TO DO MAKE DIRECT LOGIN

            //this.props.history.push('/login');
            window.location.href = '/';
      
          } catch (e) {
      
            this.setState({showLoading: false});
            
            this.setState({
              errors: e.errors
            })
      
          }
    } else {
        this.props.setAppMessage(this.props.t('accept_terms_and_conditions'));
    }
    
  }

  render = () => {

    const { t } = this.props;

    return (
      <IonPage>
        
        <StandartHeader name={t('signup')} hideHome={true}/>
  
        <IonContent className="ion-padding">
          <IonList>
            <IonItem lines="full" className={`form-list__item ${'email' in this.state.errors ? 'is-invalid' : ''}`}>
              <IonLabel position="stacked" color="primary" className="form-label">{t('email')}</IonLabel>
              <IonInput type="email" className="form-input" onIonInput={ e => this.setState({email: e.detail.value})}/>
              {'email' in this.state.errors ? (<div className="invalid-message">{this.state.errors.email}</div>) : null}
            </IonItem>
            <IonItem lines="full" className={`form-list__item ${'password' in this.state.errors ? 'is-invalid' : ''}`}>
              <IonLabel position="stacked" color="primary" className="form-label">{t('password')}</IonLabel>
              <div className="form-password">
                <IonInput type={this.state.passwordShown ? "text" : "password"} className="form-input" onIonInput={ e => this.setState({password: e.detail.value})}/>
                <button type="button" ion-button="true" className="form-password__button" onClick={this.togglePasswordVisiblity}>
                  <svg className="form-password__button-icon"><use xlinkHref={`#icon-eye-${this.state.passwordShown ? 'closed' : 'open'}`}></use></svg>
                </button>
              </div>
              {'password' in this.state.errors ? (<div className="invalid-message">{this.state.errors.password}</div>) : null}
            </IonItem>
            <IonItem lines="full" className={`form-list__item ${'confirmPassword' in this.state.errors ? 'is-invalid' : ''}`}>
              <IonLabel position="stacked" color="primary" className="form-label">{t('confirm_password')}</IonLabel>
              <div className="form-password">
                <IonInput type={this.state.passwordConfirmShown ? "text" : "password"} className="form-input" onIonInput={ e => this.setState({confirmPassword: e.detail.value})}/>
                <button type="button" ion-button="true" className="form-password__button" onClick={this.toggleConfirmPasswordVisiblity}>
                  <svg className="form-password__button-icon"><use xlinkHref={`#icon-eye-${this.state.passwordConfirmShown ? 'closed' : 'open'}`}></use></svg>
                </button>
              </div>
              {'confirmPassword' in this.state.errors ? (<div className="invalid-message">{this.state.errors.confirmPassword}</div>) : null}
            </IonItem>
            <IonItem lines="full" className={`form-list__item ${'name' in this.state.errors ? 'is-invalid' : ''}`}>
              <IonLabel position="stacked" color="primary" className="form-label">{t('your_name')}</IonLabel>
              <IonInput type="text" className="form-input" onIonInput={ e => this.setState({name: e.detail.value})}/>
              {'name' in this.state.errors ? (<div className="invalid-message">{this.state.errors.name}</div>) : null}
            </IonItem>
            <IonItem lines="full" className={`form-list__item ${'lastName' in this.state.errors ? 'is-invalid' : ''}`}>
              <IonLabel position="stacked" color="primary" className="form-label">{t('your_last_name')}</IonLabel>
              <IonInput type="text" className="form-input" onIonInput={ e => this.setState({lastName: e.detail.value})}/>
              {'lastName' in this.state.errors ? (<div className="invalid-message">{this.state.errors.lastName}</div>) : null}
            </IonItem>
            {/* <IonItem lines="full" className={`form-list__item ${'phone' in this.state.errors ? 'is-invalid' : ''}`}>
              <IonLabel position="stacked" color="primary" className="form-label">{t('your_phone')}</IonLabel>
              <PhoneInput
                value={this.state.phone}
                onChange={value => this.setState({phone: value})}
                international
                countryCallingCodeEditable={false}
                defaultCountry="BG"
              />
              {'phone' in this.state.errors ? (<div className="invalid-message">{this.state.errors.phone}</div>) : null}
            </IonItem> */}
            <IonItem lines="none" className="form-list__item">
              <div>
                {/* TO DO: Ask for terms and conditions */}
                <IonLabel className="form-checkbox">
                  <IonCheckbox color="primary" className="form-checkbox__element" name="checkbox_1" checked={this.state.accept_terms_conditios} onIonChange={e => this.setState({accept_terms_conditios: e.detail.checked})}/>
                  <div className="form-checkbox__text">
                      {t('accept')} &nbsp;<IonButton style={{position: "relative", zIndex: "9999"}} fill="clear" color="link" onClick={() => InAppBrowser.create(`https://tesy.bg/redirect/tesyme/${this.props.app.lang}/terms-and-conditions/`)}>{t('terms_conditions')}</IonButton>
                  </div>
                </IonLabel>
  
                <IonLabel className="form-checkbox">
                  <IonCheckbox color="primary" className="form-checkbox__element" name="checkbox_2" onIonChange={e => this.setState({receive_emails: e.detail.checked})}/>
                  <div className="form-checkbox__text">{t('receive_emails')}</div>
                </IonLabel>
              </div>
            </IonItem>
          </IonList>

          <ToastMessage />
          <Loading show={this.state.showLoading}/>
        </IonContent>
        
        <IonFooter className="app-footer ion-no-border">
            <IonToolbar>
              <div className="buttons-list">
                <div className="buttons-list__item">
                  <IonButton expand="block" shape="round" size="large" onClick={this.signup}>{t('signup')}</IonButton>
                </div>
                <div className="buttons-list__item">
                  <IonButton expand="block" fill="clear" color="link" size="small" routerLink="/login">{t('signin')}</IonButton>
                </div>
              </div>
            </IonToolbar>
        </IonFooter>
      </IonPage>
    );
  }
  
};

const mapStateToProps = state => {
  return {
    app: state.app
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
      setAppMessage
  })
)(SignUp);
