import React, { useState } from 'react';

import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonButtons,
  IonButton,
  IonFooter,
  IonText,
} from '@ionic/react';

import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import welcomeImage from '../../temp-files/welcome-placeholder.jpg';
import { useTranslation } from 'react-i18next';

import Picker from './components/picker/Picker';

import {
  setAppLanguage
} from '../../redux/actions/AppAction';
import { connect } from 'react-redux'

const Welcome = (props) => {

  if (window.location.pathname === '/welcome') {
    window.history.pushState(null, document.title, window.location.href);
  }

  const [pickerIsOpen, setPickerIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  let {lang} = props.app;

  if (lang == undefined) {
    lang = "en";
  }

  lang = lang.toUpperCase();

  return (
    <IonPage>
      <IonHeader className="app-header app-header--welcome ion-no-border">
        <IonToolbar className="ion-no-padding">
          <div slot="start">
            <Logo />
          </div>
          <IonButtons slot="end">
            <button type="button" ion-button="true" className="app-header__button-language" onClick={() => { setPickerIsOpen(true); }} >
              {lang}
            </button>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">

        <div className="circle-image circle-image--md">
          <img src={welcomeImage} className="circle-image__photo" alt="" />
        </div>
        <IonText className="ion-text-center">
          <h1 style={{ fontSize: '28px', fontWeight: 300 }}>{t('welcome')}</h1>
          <p style={{ color: '#989898' }}>{t('keep_your_home')}</p>
        </IonText>

        <Picker
          isOpen={pickerIsOpen}
          onCancel={() => {
            setPickerIsOpen(false);
          }}
          onSave={(_value) => {

            const {language: {value: lang}} = _value;

            i18n.changeLanguage(lang);
            props.setAppLanguage(lang);

            setPickerIsOpen(false);
          }}
        />
      </IonContent>
      <IonFooter className="app-footer ion-no-border">
          <IonToolbar>
            <div className="buttons-list">
              <div className="buttons-list__item">
                <IonButton expand="block" shape="round" size="large" routerLink="/signup">{t('signup')}</IonButton>
              </div>
              <div className="buttons-list__item">
                <IonButton expand="block" shape="round" color="link" size="small" fill="clear" routerLink="/login">{t('signin')}</IonButton>
              </div>
            </div>
          </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

const mapStateToProps = state => {
  return {
    app: state.app
  }
}

export default connect(mapStateToProps, {
  setAppLanguage  
})(Welcome);
