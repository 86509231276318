import {
    IonPage,
    IonContent,
    IonToolbar,
    IonButton,
    IonList,
    IonItem,
    IonFooter,
    IonSelect,
    IonSelectOption,
    IonRow,
    IonCol
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import {oldModels} from '../../../config/App';
import { setAppMessage } from '../../../redux/actions/AppAction';

import OldDevicesSettingsHelper from '../../../helpers/OldDevicesSettingsHelper';
import BelislimoHelper from '../../../helpers/BelislimoHelper';
import ProgramHelper from './helpers/ProgramHelper';
import ToastMessage from '../components/toast/ToastMessage';

import BoilerApiV2Menu from './boilers/apiv2/BoilerApiV2Menu';

import Header from '../components/header/Header';
import PageHeader from '../components/header/PageHeader';
import Footer from '../components/footer/Footer';
import PageSubHeader from '../components/header/PageSubHeader';
import TemperatureSlider from '../components/range/TemperatureSlider';

class AddProgram extends React.Component{

    constructor(props){
        super(props);

        this.mac = null;
        this.model = null;
        this.OldDevicesSettingsHelper = null;

        this.state = {
            selectedDay: 0,
            from: "00:00",
            to: "23:59",
            temp: 10,
            program: null
        }
    }

    componentDidMount = async () => {
        this.OldDevicesSettingsHelper = new OldDevicesSettingsHelper(this.mac);

        const {DeviceStatus: status} = this.device;

        let programStatus = {};
        for (const pr in status) {

            if (pr.indexOf('prgP') >= 0) {
                
                programStatus = {...programStatus, [pr]: status[pr]};    
            }
        }

        this.setState({
            program: programStatus
        })
    }

    saveProgram = () => {

        if (ProgramHelper.validateProgram(this.state.from, this.state.to, this.model.program.useHaflHours)) {

            const newProgram = ProgramHelper.createApiv2Program(
                this.state.program, 
                {
                    day: this.props.match.params.day,
                    from: this.state.from,
                    to: this.state.to,
                    temp: this.state.temp,
                }, 
                this.index
            );

            this.OldDevicesSettingsHelper.setDeviceStatus(`prgP${this.index}${this.props.match.params.day}`, newProgram);
            
            //this.props.history.goBack();
            this.props.history.push(`/old/apiv2/program/${this.model_name}/${this.mac}/${this.props.match.params.programsCount}/${this.props.match.params.index}/${this.props.match.params.day}`);
        } else {
            this.props.setAppMessage(this.props.t('invalid_program'));
        }
    }

    render = () => {
        //TO DO: fix the program state when save and immediately after that try to add new program

        this.mac = this.props.match.params.id;
        this.model_name = this.props.match.params.model_name;
        this.model = oldModels[this.model_name]
        this.index = this.props.match.params.index.slice(1);
        this.device = this.props.devices[this.mac];

        const { DeviceStatus: status} = this.device;

        let minTemp = null;
        let maxTemp = null;

        if (this.model_name == '2002-v1') {
            
            const bHelper = new BelislimoHelper(this.device );
            minTemp = 0;
            maxTemp = bHelper.getShowers();

        } else {

            minTemp = this.model.program.minTemp;
            maxTemp = this.model.program.maxTemp;
        }

        const {t} = this.props;

        return (
            <IonPage>
                <Header />
                <IonContent className="ion-no-padding">
                    <div className="dashboard">
                        <div className="dashboard__wrapper">

                            <BoilerApiV2Menu 
                                activeMenu="controlPanel" 
                                model_name={this.props.match.params.model_name} 
                                device={this.props.devices[this.props.match.params.id]} 
                                model={oldModels[this.props.match.params.model_name]}
                            />


                            <div className="dashboard__content">
                               
                                <PageHeader name={t('control_panel')}/>

                                <div className="dashboard-box">
                                    
                                    <PageSubHeader title={t('add_preference')}/>

                                    <div className="dashboard-box__body">

                                        <div className="gray-box">
                                            <div className="gray-box__body">
                                                <div className="wrapper wrapper--400">
                                                    <IonRow className="ion-justify-content-between">
                                                        <IonCol size="6" className="p-0">
                                                            <IonRow className="ion-align-items-center">
                                                                <IonCol size="5" className="p-0"><strong>{t('time_from')}:</strong></IonCol>
                                                                <IonCol size="6" className="p-0">
                                                                    <IonSelect 
                                                                        placeholder={t('time_from')} 
                                                                        value={this.state.from}
                                                                        onIonChange = {e => this.setState({from: e.detail.value})}
                                                                    >
                                                                        {
                                                                            this.model.program.useHaflHours == false ?
                                                                            ProgramHelper.dayHours.map((day, index)=> <IonSelectOption key={`${day}_${index}`} value={index}>{day}</IonSelectOption>) :
                                                                            ProgramHelper.dayHoursHalf.map((day, index)=> <IonSelectOption key={`${day}_${index}`} value={index}>{day}</IonSelectOption>)
                                                                        }
                                                                    </IonSelect>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonCol>
                                                        <IonCol size="6" className="p-0">
                                                            <IonRow className="ion-align-items-center">
                                                                <IonCol size="5" className="p-0"><strong>{t('time_to')}:</strong></IonCol>
                                                                <IonCol size="6" className="p-0">
                                                                <IonSelect 
                                                                    placeholder={t('time_to')} 
                                                                    value={this.state.to}
                                                                    onIonChange = {e => this.setState({to: e.detail.value})}
                                                                >
                                                                    {
                                                                        this.model.program.useHaflHours == false ?
                                                                        ProgramHelper.dayHours.map((day, index)=> <IonSelectOption key={`${day}_${index}`} value={index}>{day}</IonSelectOption>) :
                                                                        ProgramHelper.dayHoursHalf.map((day, index)=> <IonSelectOption key={`${day}_${index}`} value={index}>{day}</IonSelectOption>)
                                                                    }
                                                                </IonSelect>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonCol>
                                                    </IonRow>
    
                                                    <div className="ion-padding-top"></div>
                                                    <div className="ion-padding-top"></div>
    
                                                    <TemperatureSlider 
                                                        label={t('target_temperature')}
                                                        model={this.model_name}
                                                        disabled={false}
                                                        currentTemp={minTemp}
                                                        minTemperature={minTemp}
                                                        maxTemperature={maxTemp}
                                                        stepTemperature={1}
                                                        handleTemperature={temp => this.setState({temp})}
                                                    />
    
                                                    <div className="ion-padding-top"></div>
                                                    
                                                    <div className="ion-text-center">
                                                        <IonButton color="primary" onClick={this.saveProgram}>
                                                            <span className="button__text">{t('save')}</span>
                                                        </IonButton>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ToastMessage />
                </IonContent>
                <Footer />
            </IonPage>
        );
    }
};

const mapStateToProps = state => {
    return {
        devices: state.oldDevices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        setAppMessage
    })
)(AddProgram);