import {
    IonContent,
    IonPage,
    IonButton,
} from '@ionic/react';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import OldDevicesSettingsHelper from '../../../helpers/OldDevicesSettingsHelper';
import BelislimoHelper from '../../../helpers/BelislimoHelper';
import ConvectorApiV1Menu from './convectors/apiv1/ConvectorApiV1Menu';
import BoilerApiV1Menu from './boilers/apiv1/BoilerApiV1Menu';

import Header from '../components/header/Header';
import PageHeader from '../components/header/PageHeader';
import Footer from '../components/footer/Footer';
import {oldModels} from '../../../config/App';

class CalculatorGraphic extends React.Component {

    state = {
        kwh: 0,
        resetDate: null,
        power: null
    }

    componentDidMount = () => {

        this.OldDevicesSettingsHelper = new OldDevicesSettingsHelper(this.mac);

        this.getCalculatorValues();
        
    }

    // getCalculatorValues = async () => {

    //     const {data: {sum, volume, watt, resetDate, kwh}} = await this.OldDevicesSettingsHelper.setDeviceStatus('calcRes', '');

    //     if (kwh != undefined) {

    //         this.setState({
    //             kwh: kwh,
    //             resetDate,
    //             power: `${volume} L` 
    //         });
            
    //     } else {
    //         this.setState({
    //             kwh: ((sum * watt) / 3600000).toFixed(3),
    //             resetDate,
    //             power: `${watt} W`  
    //         });
    //     }
    // }

    getCalculatorValues = async () => {

        const {data: {sum, volume, watt, resetDate, kwh}} = await this.OldDevicesSettingsHelper.setDeviceStatus('calcRes', '');
        const model = oldModels[this.props.match.params.model_name ];

        let power = `${watt} W`;

        if (model.type == 'boiler') {

            let volumeValue = volume;

            if (this.props.match.params.model_name == '2002-v1') {

                const bHelper = new BelislimoHelper(this.device);
                volumeValue = bHelper.getVolume();
            }

            power = `${volumeValue} L`; 
        }

        this.setState({
            kwh: kwh != undefined ? kwh : ((sum * watt) / 3600000).toFixed(3),
            resetDate,
            power 
        });
    }

    reset = async () => {

        await this.OldDevicesSettingsHelper.setDeviceStatus('pwc_u', 'reset');

        this.getCalculatorValues();
    }

    render = () => {

        this.mac = this.props.match.params.id;
        const { t } = this.props;
        this.model_name = this.props.match.params.model_name;
        this.model = oldModels[this.model_name];
        this.device = this.props.devices[this.props.match.params.id];

        const { DeviceShortName } = this.device;

        return (
            <IonPage>
            
                <Header />

                <IonContent className="ion-no-padding">
                    <div className="dashboard">
                        <div className="dashboard__wrapper">

                            {this.model.type == 'boiler' ? 
                                <BoilerApiV1Menu 
                                    activeMenu="calculator" 
                                    model_name={this.props.match.params.model_name} 
                                    device={this.props.devices[this.props.match.params.id]} 
                                    model={oldModels[this.props.match.params.model_name]}
                                />
                            :
                                <ConvectorApiV1Menu 
                                    activeMenu="calculator" 
                                    model_name={this.props.match.params.model_name} 
                                    device={this.props.devices[this.props.match.params.id]} 
                                    model={oldModels[this.props.match.params.model_name]}
                                />
                            }
                            

                            <div className="dashboard__content">
                               
                                <PageHeader name={t('calculator_button')} image="calc"/>

                                <div className="dashboard-box">

                                    <div className="dashboard-box__body">

                                        <div className={`range-circle`}>
                                            <div className="range-circle__inner">
                                                <div className="range-circle__metric">{t('kWh')}</div>
                                                <div className="range-circle__value">{this.state.kwh}</div>
                                                {this.state.resetDate != 'NONE' && 
                                                    <div className="range-circle__label">{t('since')} {this.state.resetDate}</div>
                                                }
                                                <div className="range-circle__value range-circle__value--alt">{this.state.power}</div>
                                            </div>
                                        </div>
                                        
                                        <div className="range-buttons js-range-buttons">
                                            <div className="range-buttons__item">
                                                <IonButton shape="round" size="default" onClick={this.reset}>{t('reset')}</IonButton>
                                            </div>
                                            <div className="range-buttons__item">
                                                <IonButton shape="round" size="default" fill="outline" color="medium" onClick={()=>this.props.history.push(`/old/calculator-statistics/${this.props.match.params.model_name}/${this.props.match.params.id}`)}>{t('history')}</IonButton>
                                            </div>
                                        </div>

                                        <div className="ion-content-push"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </IonContent>
                <Footer />
            </IonPage>
        );
    }
}

const mapStateToProps = state => {
    return {
        devices: state.oldDevices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(CalculatorGraphic);