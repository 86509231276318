export const parseJson = (json) => {
    try {
        return JSON.parse(json);
    } catch (e) {
        global.debug(`JSON ERROR: ${e.message}`);
    }
}

export const extractJson = (json) => {
    return json.toString() ? parseJson(json) : null;
}