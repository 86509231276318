import {
    IonContent,
    IonFooter,
    IonButton,
    IonPage,
    IonToolbar,
    IonText
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { Device } from '@capacitor/device';

import StandartHeader from './components/header/StandartHeader';
import setupChecklistImage from '../../files/setupChecklist.svg';

class SetUpChecklist extends React.Component {

    state = {
        appInfo: {}
    }

    componentDidMount = async () => {

        const info = await Device.getInfo();

        this.setState({
            appInfo: info
        })
    }

    render = () => {
        return (
            <IonPage>

            <StandartHeader name={this.props.t('setup_checklist')} />

            <IonContent className="ion-padding">
                <div className="circle-image circle-image--lg">
                  
                    <img src={setupChecklistImage}/>
                </div>
                <IonText>
                {/* TO DO: Ask for text*/}
                <h1>{this.props.t('setup_checklist_text1')} </h1>
                <ul className="ul-list">
                    <li className="ul-list__item">{this.props.t('setup_checklist_text2')}</li>
                    <li className="ul-list__item">{this.props.t('setup_checklist_text3')}</li>
                    <li className="ul-list__item">{this.props.t('setup_checklist_text4')}</li>
                </ul>
                </IonText>
            </IonContent>
            
            <IonFooter className="app-footer ion-no-border">
                <IonToolbar>
                    <div className="buttons-list">
                    <div className="buttons-list__item">
                        <IonButton 
                            expand="block" 
                            shape="round" 
                            size="large" 
                            // routerLink={this.state.appInfo.platform === 'ios' ? "/choose-device-group" : "/location-services"}
                            routerLink="choose-device-group"
                        >
                            {this.props.t('get_started')}
                        </IonButton>
                    </div>
                    {/* TO DO: Ask for this button */}
                    {/* <div className="buttons-list__item">
                        <IonButton expand="block" shape="round" size="large" color="help">{this.props.t('need_help')}</IonButton>
                    </div> */}
                    </div>
                </IonToolbar>
            </IonFooter>
            </IonPage>
        );
    }
};

export default withTranslation()(SetUpChecklist);
  