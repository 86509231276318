import {
    IonPage,
    IonContent,
    IonToolbar,
    IonButton,
    IonList,
    IonItem,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonToggle,
    IonFooter,
    IonGrid,
    IonRow,
    IonCol,
    IonModal,
    IonButtons,
    IonText,
    IonLabel,
    IonCheckbox,
    IonAlert
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'
import dayjs from 'dayjs';
import {cn05uvSettings} from '../../config/App';

// import '../../assets/css/components/day-picker/day-picker.scss';
// import '../../assets/css/components/preferences/preferences.scss';
// import '../../assets/css/components/program/program.scss';
// import '../../assets/css/components/card/card.scss';
//import './components/range/range.scss';

import StandartHeader from './components/header/StandartHeader';
import Week from './components/week/Week';
import Loading from './components/loading/Loading';
import DeviceSettingsHelper from '../../helpers/DeviceSettingsHelper';


class DeviceCN05UVProgram extends React.Component {

    constructor(props) {
        super(props);

        this.mac = null;
        this.DeviceSettingsHelper = null;

        this.state = {
            showDeleteAlert: false,
            selectedDay: 0,
            weekDays: {
                0: {
                  'name': props.t('monday_full'),
                  'checked': true
                },
                1: {
                  'name': props.t('tuesday_full'),
                  'checked': true
                },
                2: {
                  'name': props.t('wednesday_full'),
                  'checked': true
                },
                3: {
                  'name': props.t('thursday_full'),
                  'checked': true
                },
                4: {
                  'name': props.t('friday_full'),
                  'checked': true
                },
                5: {
                  'name': props.t('saturday_full'),
                  'checked': true
                },
                6: {
                  'name': props.t('sunday_full'),
                  'checked': true
                }
            },
            showWeekModal: false,
            showLoading: false,
            alert: {
                isOpen: false,
                header: '',
                message: ''
            }
        }

        //TO DO: Check for problem. From time to time it gives an errors when you start editing / adding / deleting a program
    }

    componentDidMount = () => {
        this.DeviceSettingsHelper = new DeviceSettingsHelper(this.mac)

        let currentDay = dayjs().day();
        const daysOfWeek = [6, 0, 1, 2, 3, 4, 5];

        this.setState({
            selectedDay: this.props.match.params.day != undefined ? this.props.match.params.day : daysOfWeek[currentDay]
        })
    }

    handleDeviceMode = (status, mode) => {

        if (status === 'on') {

            this.setState({
                newDeviceMode: mode,
                alert: {
                    isOpen: true,
                    header: this.props.t(`${mode}_mode_header`),
                    message: this.props.t(`${mode}_mode_message`)
                }
            })

        } else {

            this.handleDeviceMode('on', cn05uvSettings.modes.heating);
        }
    }

    renderProgram = (program, model) => {

        
        const programList = [];

        for (const key in program) {
            const {from, to, day, temp } = program[key];

            if (day === Number(this.state.selectedDay)) {
                programList.push(
                    <IonItem className="preferences-list__item" lines="none" key={key}>
                        <div className="preferences-list__inner">
                            <div className="preferences-list__item-start">
                            <div className="preferences-list__time">
                                <svg className="preferences-list__time-icon"><use xlinkHref="#icon-heating"></use></svg>
                                {from}{this.props.t('hour_abbr')} - {to}{this.props.t('hour_abbr')}
                            </div>
                            </div>
                            <div className="preferences-list__item-middle">
                            <div className="preferences-list__temperature">
                                <svg className="preferences-list__temperature-icon"><use xlinkHref="#icon-idle"></use></svg>
                                {temp}°C
                            </div>
                            </div>
                            <div className="preferences-list__item-end">
                            {/* <IonButton 
                                color="icon" 
                                style={{marginRight: '12px'}} 
                                routerLink={`/device/program/edit-program/${model}/${this.mac}/${key}`}
                            >
                                <svg><use xlinkHref="#icon-pencil" className="fill-green"></use></svg>
                            </IonButton> */}
                            {/* TO DO: add confirm text when delete button is clicked */}
                            <IonButton color="icon" onClick={() => this.DeviceSettingsHelper.handleDeleteProgram(key)}>
                                <svg><use xlinkHref="#icon-delete" className="fill-red"></use></svg>
                            </IonButton>
                            </div>
                        </div>
                    </IonItem>
                );
            }
        }

        return programList;
    }

    checkDay = (selectedDay, status) => {
        this.setState({weekDays: {...this.state.weekDays, [selectedDay]:{...this.state.weekDays[selectedDay], checked: status}}});
    }

    renderWeek = () => {

        const days = [];

        for (const day in this.state.weekDays) {

            let {checked, name} = this.state.weekDays[day];

            if (this.state.selectedDay == day) {
                checked = false;
            }

            days.push(
                <IonItem lines="full" key={day}>
                    <IonLabel className="form-checkbox">
                        <IonCheckbox color="secondary" className="form-checkbox__element" name="checkboxName" checked={checked} onIonChange={e => this.checkDay(day, e.detail.checked)}/>
                        <div className="form-checkbox__text">{name}</div>
                    </IonLabel>
                </IonItem>
            );
        }

        return days;
    }

    timer = ms => new Promise(res => setTimeout(res, ms))

    //First Delete old program for all checked days
    //Copy the program from selected day to all Checked days from Modal
    copyProgram = async () => {

        this.setState({
            showLoading: true
        })

        const { state: {program} } = this.props.devices[this.mac];

        //Delete old program
        for (const wDay in this.state.weekDays) {

            const { checked } = this.state.weekDays[wDay];

            if (checked === true && this.state.selectedDay != wDay) {
                
                for (const key in program) {
                    const { day } = program[key];   

                    if (Number(day) === Number(wDay)){
                        
                        this.DeviceSettingsHelper.handleDeleteProgram(key);   
                        await this.timer(1000);
                    }
                }       
            }
        }

        //Copy program
        for (const key in program) {
            const {from, to, day, temp } = program[key];

            if (day === Number(this.state.selectedDay)) {

                for (const wDay in this.state.weekDays) {
                    const { checked } = this.state.weekDays[wDay];

                    if (checked === true) {

                
                        this.DeviceSettingsHelper.handleAddDeviceProgram({
                            day: Number(wDay),
                            from: from,
                            to: to,
                            temp: temp
                        });
                        
                        await this.timer(1000);
                    }
                }
            }
        }

        this.setState({
            showWeekModal: false,
            showLoading: false
        })
    }

    render = () => {

        this.mac = this.props.match.params.mac_address;

        const { t } = this.props;
        const {devices} = this.props;

        const device = devices[this.mac];
        
        if (!device || device === undefined) {
            return null;
        }   

        const { state : d_state } = device;
        const { program } = d_state;
        const { modes: device_modes } = cn05uvSettings;

        return (
            <IonPage>
                <StandartHeader name={this.props.t('program')}/>
        
                <IonContent className="ion-padding">
        
                    <IonCard>
                        <IonCardHeader className="card-list__header">
                        <div className="card-list__header-left">
                            <div style={{ marginTop: '0' }}>
                            <img src={device.model_image} alt="" />
                            </div>
                            <div>
                            <div className="card-list__title">{device.deviceName}</div>
                            </div>
                        </div>
                        <div className="card-list__header-right"></div>
                        </IonCardHeader>
                        <IonCardContent className="card-list__content">
                        <div className="device-box ion-align-items-center">
                            <div className="device-box__left">{t('adaptive_start')}</div>
                            <div className="device-box__right">
                                <IonToggle 
                                    checked={d_state.adaptiveStart === 'on' ? true : false} 
                                    onClick={e => this.DeviceSettingsHelper.handleAdaptiveStart(d_state.adaptiveStart === 'on' ? 'off' : 'on')}
                                />
                            </div>
                        </div>
                        </IonCardContent>
                    </IonCard>
                    
                    <Week setSelectedDay={day => this.setState({selectedDay: day})} defaultDay={this.state.selectedDay}/>
                    
                    {/* <div className="ion-text-right">
                        <IonButton color="delete" onClick={() => this.DeviceSettingsHelper.handleDeleteAllDeviceprograms(this.mac)}>{t('delete_program')}</IonButton>
                    </div> */}

                    <IonGrid>
                        <IonRow>
                            <IonCol className="ion-text-start">
                                <IonButton color="confirm" onClick={() => this.setState({showWeekModal: true})}>{t('save_porgram_to_all_days')}</IonButton>
                                <IonModal isOpen={this.state.showWeekModal} onDidDismiss={() => this.setState({showWeekModal: false})}>
                                    <IonToolbar>
                                        <div slot="end">
                                        <IonButtons>
                                            <IonButton onClick={() => this.setState({showWeekModal: false})}>
                                            <svg className="size-14"><use xlinkHref="#icon-close"></use></svg>
                                            </IonButton>
                                        </IonButtons>
                                        </div>
                                    </IonToolbar>
                                    <div className="ion-padding">
                                        <IonText>
                                        <h2 className="ion-text-center">{t('choose_days_to_copy_program')}</h2>

                                        <IonList className="ion-padding-top">
                                            {this.renderWeek()}
                                        </IonList>
                                        </IonText>
                                    </div>
                                    {/* TO DO: Need то check with Vladi  */}
                                    <IonFooter className="app-footer ion-no-border">
                                        <IonToolbar>
                                            <div className="buttons-list">
                                                <div className="buttons-list__item">
                                                    <IonButton expand="block" shape="round" size="large" onClick={this.copyProgram}>{t('save')}</IonButton>
                                                </div>
                                            </div>
                                        </IonToolbar>
                                    </IonFooter>
                                </IonModal>
                            </IonCol>
                            <IonCol className="ion-text-end">
                                <IonButton color="delete" onClick={() => this.setState({showDeleteAlert: true})}>{t('delete_program')}</IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
            
                    <div className="preferences-list__wrapper">
                        <IonList className="preferences-list">
                            {this.renderProgram(program, device.model.toLowerCase())}   
                        </IonList>
                    </div>
                    <div className="ion-content-push"></div>

                    <Loading show={this.state.showLoading}/>

                    <IonAlert
                        isOpen={this.state.showDeleteAlert}
                        onDidDismiss={() => this.setState({showDeleteAlert: false})}
                        cssClass='my-custom-class'
                        header={this.props.t('confirm')}
                        message={this.props.t('confirm_programs_delete')}
                        buttons={[
                            {
                                text: this.props.t('cancel'),
                                role: 'cancel',
                                cssClass: 'secondary',
                                handler: blah => {
                                    this.setState({showDeleteAlert: false})
                                }
                            },
                            {
                                text: this.props.t('ok'),
                                handler: () => {
                                    this.DeviceSettingsHelper.handleDeleteAllDeviceprograms(this.mac)
                                }
                            }
                        ]}
                    />

                    <IonAlert
                        isOpen={this.state.alert.isOpen}
                        onDidDismiss={() => this.setState({alert: {...this.state.alert, isOpen: false}, newDeviceMode: ''})}
                        header={this.state.alert.header}
                        message={`"${t(d_state.mode.toLowerCase())}" ${t('switch_off_control')}`}
                        buttons={
                            [{
                                text: t('cancel'),
                                handler: () => {
                                    this.setState({
                                        alert: {...this.state.alert, isOpen: false},
                                        newDeviceMode: '',
                                    })
                                }
                            },
                            {
                                text: t('ok'),
                                handler: () => {

                                    this.setState({
                                        programState: this.state.newDeviceMode !== device_modes.program ? 'manual' : this.state.programState
                                    });

                                    this.DeviceSettingsHelper.handleMode(this.state.newDeviceMode);
                                }
                            }]
                        }
                    />

                </IonContent>
        
                <IonFooter className="app-footer ion-no-border">
                    <IonToolbar>
                        <div className="buttons-list">
                            <div className="buttons-list__item">
                                <IonButton expand="block" shape="round" size="large" routerLink={`/device/program/add-program/${device.model.toLowerCase()}/${this.mac}/${this.state.selectedDay}`}>{t('add_preference')}</IonButton>
                            </div>
                            <div className="buttons-list__item">
                                <IonButton 
                                    expand="block"
                                    shape="round" 
                                    size="large" 
                                    onClick={() => this.handleDeviceMode((d_state.mode &&  d_state.mode === device_modes.program) ? 'off' : 'on', device_modes.program)}
                                >
                                    {(d_state.mode &&  d_state.mode === device_modes.program) ? t('deactivate') : t('activate')}
                                </IonButton>
                            </div>
                        </div>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        );
    }
};
const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(DeviceCN05UVProgram);