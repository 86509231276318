import timezonePlugin from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import dayjs from 'dayjs';


class TimeHelper {

    catchTimeZoneObj = timezone => {

        dayjs.Ls.en.weekStart = 1;
        dayjs.extend(utc);
        dayjs.extend(timezonePlugin);
        dayjs.extend(weekday);
        dayjs.locale('en');

        const utcTime = dayjs.utc().format("YYYY-MM-DD HH:mm");
        const localTime = dayjs().format("YYYY-MM-DD HH:mm");

        let timeZoneObj = null;

        try {
            timeZoneObj = dayjs(localTime).tz(timezone);
        } catch (e) {
            timeZoneObj = dayjs(localTime).tz("Europe/Sofia");
        }

        return timeZoneObj;
    }

    addTimeInMinutes = (timezone, time, format = "D MMM HH:mm") => {
        const timeObject = this.catchTimeZoneObj(timezone);
        return timeObject.second(0).millisecond(0).add(time, "minute").format(format);
    }
    
}


export default new TimeHelper();