import React from 'react';
import { Route, Switch} from 'react-router-dom';
import ManagePage from './ManagePage';
import Program from './Program';
import CreateProgram from './CreateProgram';
import Vacation from './Vacation';
import DeviceInfo from './DeviceInfo';
import Calculator from './Calculator';
import CalculatorHistory from './CalculatorHistory';
import WorldClock from './WorldClock';

const Routes = () => {

    //MUST BE IN LOWER CASE
    const MODEL = "hpwh4_11_260";

    return (
        <Switch>
            <Route exact path={`/device/${MODEL}/:mac_address`} component={ManagePage}/>
            <Route exact path={`/device/program/${MODEL}/:mac_address/:day?`} component={Program}/>
            <Route exact path={`/device/program/create-program/${MODEL}/:mac_address/:day/:zone?`} component={CreateProgram}/>
            <Route exact path={`/device/vacation/${MODEL}/:mac_address`} component={Vacation}/>
            <Route exact path={`/device/hp/calculator/${MODEL}/:mac_address`} component={Calculator}/>
            <Route exact path={`/device/hp/calculator-history/${MODEL}/:mac_address`} component={CalculatorHistory}/>
            <Route exact path={`/device/hp/device-info/${MODEL}/:mac_address`} component={DeviceInfo}/>
            <Route exact path={`/device/hp/world-clock/${MODEL}/:mac_address`} component={WorldClock}/>
        </Switch>
    );
}

export default Routes;