import {
    IonPage,
    IonContent,
    IonButton,
    IonList,
    IonItem,
    IonLabel,
    IonInput
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Header from './components/header/Header';
import PageHeader from './components/header/PageHeader';
import Footer from './components/footer/Footer';
import DashboardMenu from './components/menu/DashboardMenu';
import FooterTwoButtons from './components/footer/FooterTwoButtons';
import InitialSettings from '../../init';
import tesyCloudLogo from '../../files/TesyCloud-Logo.svg';

import {
    setAppLanguage
  } from '../../redux/actions/AppAction';

//Import helpers
import UsersHelper from '../../helpers/UsersHelper';
import LocalStorageHelper from '../../helpers/LocalStorageHelper';


import PhoneInput from 'react-phone-number-input';

class AccountDetails extends React.Component {

    constructor(props){
        super(props);

        this.UsersHelper = new UsersHelper();
        this.state = {
            email: '',
            name: '',
            lastName: '',
            phone: '',
            lang: '',
            errors: {},
            newPasswordShown: false,
            newConfirmPasswordShown: false,
            tesyCloudEmail: '',
            tesyCloudPassword: '',
            tesyCloudPasswordShown: false
        }
    }

    componentDidMount = async () => {

        this.setState({
            email: await LocalStorageHelper.get('user.email'),
            name: await LocalStorageHelper.get('user.firstName'),
            lastName: await LocalStorageHelper.get('user.lastName'),
            //phone: LocalStorageHelper.get('user.phone'),    
            lang: await LocalStorageHelper.get('user.lang'),    
            tesyCloudEmail: await LocalStorageHelper.get('user.oldAppEmail'),
            tesyCloudPassword: await LocalStorageHelper.get('user.oldAppPassword'),
        })
    }

    saveAccountDetails = async () => {
        try {

            await this.UsersHelper.accountSettings({
                email: this.state.email,
                name: this.state.name,
                lastName: this.state.lastName,
                phone: this.state.phone,
                newLang: this.state.lang,
            });

            LocalStorageHelper.set('user.email', this.state.email);
            LocalStorageHelper.set('user.firstName', this.state.name);
            LocalStorageHelper.set('user.lastName', this.state.lastName);
            LocalStorageHelper.set('user.phone', this.state.phone);
            LocalStorageHelper.set('user.lang', this.state.lang);

            //Set App Lang
            this.props.i18n.changeLanguage(this.state.lang);
            this.props.setAppLanguage(this.state.lang);

            this.props.history.goBack();

        } catch (e) {

            this.setState({
                errors: e.errors
            })
        }
    }

    savePasswordDetails = async () => {
        try {
    
            await this.UsersHelper.passwordSettings({
                newPassword: this.state.newPassword,
                confirmPassword: this.state.confirmPassword,
            });

            LocalStorageHelper.set('user.password', this.state.newPassword)

            this.props.history.goBack();
    
        } catch (e) {
    
            this.setState({
                errors: e.errors
            })
        }
    }

    saveTesyCloudLoginDetails = async () => {
        try {

            await this.UsersHelper.oldAppSettings({
                email: this.state.tesyCloudEmail,
                password: this.state.tesyCloudPassword,
            });

            const InitialSettingsOBJ = new InitialSettings();
            InitialSettingsOBJ.init();

            this.props.history.goBack();

        } catch (e) {

            this.setState({
                errors: e.errors
            })
        }
    }

    toggleNewPasswordVisiblity = () => {
        this.setState({newPasswordShown: !this.state.newPasswordShown});
    };

    toggleTesyCloudPasswordVisiblity = () => {
        this.setState({tesyCloudPasswordShown: !this.state.tesyCloudPasswordShown});
    }

    toggleNewConfirmPasswordVisiblity = () => {
        this.setState({newConfirmPasswordShown: !this.state.newConfirmPasswordShown});
    };

    render = () => {

        const { t } = this.props;

        return (
            <IonPage>
                <Header />
                <IonContent className="ion-no-padding">
                    <div className="dashboard">
                    <div className="dashboard__wrapper">
                        
                        <DashboardMenu />

                        <div className="dashboard__content">
                        
                        <PageHeader name={t('account_details')} image="pencil"/>

                        <Tabs forceRenderTabPanel defaultIndex={0}>
                            <TabList>
                            <Tab>{t('account_details')}</Tab>
                            <Tab>{t('password_details')}</Tab>
                            <Tab>tesyCloud</Tab>
                            </TabList>
                            <div className="react-tabs__box">
                            <div style={{ maxWidth: '400px', margin: '0 auto' }}>
                                <TabPanel>
                                    <IonList>
                                        <IonItem lines="none" className={`form-list__item ${'email' in this.state.errors ? 'is-invalid' : ''}`}>
                                            <IonLabel position="stacked" color="primary" className="form-label">{t('email')}</IonLabel>
                                            <IonInput value={this.state.email} type="email" className="form-input" onIonInput={ e => this.setState({email: e.detail.value})}/>
                                            {'email' in this.state.errors ? (<div className="invalid-message">{this.state.errors.email}</div>) : null}
                                        </IonItem>
                                        <IonItem lines="none" className={`form-list__item ${'name' in this.state.errors ? 'is-invalid' : ''}`}>
                                            <IonLabel position="stacked" color="primary" className="form-label">{t('your_name')}</IonLabel>
                                            <IonInput value={this.state.name} type="text" className="form-input" onIonInput={ e => this.setState({name: e.detail.value})}/>
                                            {'name' in this.state.errors ? (<div className="invalid-message">{this.state.errors.name}</div>) : null}
                                        </IonItem>
                                        <IonItem lines="none" className={`form-list__item ${'lastName' in this.state.errors ? 'is-invalid' : ''}`}>
                                            <IonLabel position="stacked" color="primary" className="form-label">{t('your_last_name')}</IonLabel>
                                            <IonInput  value={this.state.lastName} type="text" className="form-input" onIonInput={ e => this.setState({lastName: e.detail.value})}/>
                                            {'lastName' in this.state.errors ? (<div className="invalid-message">{this.state.errors.lastName}</div>) : null}
                                        </IonItem>
                                        {/* <IonItem lines="none" className={`form-list__item ${'phone' in this.state.errors ? 'is-invalid' : ''}`}>
                                            <IonLabel position="stacked" color="primary" className="form-label">{t('your_phone')}</IonLabel>
                                            <div className="form-edit">
                                                <PhoneInput
                                                    className="form-input"
                                                    value={this.state.phone}
                                                    onChange={value => {this.setState({phone: value})}}
                                                    international
                                                    countryCallingCodeEditable={false}
                                                    defaultCountry="BG"
                                                />
                                            </div>
                                            {'phone' in this.state.errors ? (<div className="invalid-message">{this.state.errors.phone}</div>) : null}
                                        </IonItem> */}
                                        <IonItem lines="none" className="form-list__item pt-20">
                                            <FooterTwoButtons firtsButtonName={t('cancel')} save={this.saveAccountDetails} />   
                                        </IonItem>
                                    </IonList>
                                </TabPanel>
                                <TabPanel>
                                    <IonList>
                                        <IonItem lines="none" className={`form-list__item ${'newPassword' in this.state.errors ? 'is-invalid' : ''}`}>
                                            <IonLabel position="stacked" color="primary" className="form-label">{t('new_password')}</IonLabel>
                                            <div className="form-password">
                                            <IonInput type={this.state.newPasswordShown ? "text" : "password"} className="form-input" onIonInput={ e => this.setState({newPassword: e.detail.value})}/>
                                            <button type="button" ion-button="true" className="form-password__button" onClick={this.toggleNewPasswordVisiblity}>
                                                <svg className="form-password__button-icon"><use xlinkHref={`#icon-eye-${this.state.newPasswordShown ? 'closed' : 'open'}`}></use></svg>
                                            </button>
                                            </div>
                                            {'newPassword' in this.state.errors ? (<div className="invalid-message">{this.state.errors.newPassword}</div>) : null}
                                        </IonItem>
                                        <IonItem lines="none" className={`form-list__item ${'confirmPassword' in this.state.errors ? 'is-invalid' : ''}`}>
                                            <IonLabel position="stacked" color="primary" className="form-label">{t('confirm_password')}</IonLabel>
                                            <div className="form-password">
                                            <IonInput type={this.state.passwordConfirmShown ? "text" : "password"} className="form-input" onIonInput={ e => this.setState({confirmPassword: e.detail.value})}/>
                                            <button type="button" ion-button="true" className="form-password__button" onClick={this.toggleConfirmPasswordVisiblity}>
                                                <svg className="form-password__button-icon"><use xlinkHref={`#icon-eye-${this.state.passwordConfirmShown ? 'closed' : 'open'}`}></use></svg>
                                            </button>
                                            </div>
                                            {'confirmPassword' in this.state.errors ? (<div className="invalid-message">{this.state.errors.confirmPassword}</div>) : null}
                                        </IonItem>
                                        <IonItem lines="none" className="form-list__item pt-20">
                                            <FooterTwoButtons save={this.savePasswordDetails} />   
                                        </IonItem>
                                    </IonList>
                                </TabPanel>
                                <TabPanel>
                                    <IonItem lines="none" className={`form-list__item ${'email' in this.state.errors ? 'is-invalid' : ''}`}>
                                        <IonLabel position="stacked" color="primary" className="form-label">{t('email')}</IonLabel>
                                        <IonInput value={this.state.tesyCloudEmail} type="email" className="form-input" onIonInput={ e => this.setState({tesyCloudEmail: e.detail.value})}/>
                                        {'email' in this.state.errors ? (<div className="invalid-message">{this.state.errors.email}</div>) : null}
                                    </IonItem>

                                    <IonItem lines="none" className={`form-list__item ${'password' in this.state.errors ? 'is-invalid' : ''}`}>
                                        <IonLabel position="stacked" color="primary" className="form-label">{t('password')}</IonLabel>
                                        <div className="form-password">
                                            <IonInput type={this.state.tesyCloudPasswordShown ? "text" : "password"} value={this.state.tesyCloudPassword} className="form-input" onIonInput={ e => this.setState({tesyCloudPassword: e.detail.value})}/>
                                            <button type="button" ion-button="true" className="form-password__button" onClick={this.toggleTesyCloudPasswordVisiblity}>
                                                <svg className="form-password__button-icon"><use xlinkHref={`#icon-eye-${this.state.tesyCloudPasswordShown ? 'closed' : 'open'}`}></use></svg>
                                            </button>
                                        </div>
                                        {'password' in this.state.errors ? (<div className="invalid-message">{this.state.errors.password}</div>) : null}
                                    </IonItem>

                                    <div style={{ float: "right" }}>
                                        <IonItem lines="none">
                                            <IonButton fill="clear" color="link" size="small" routerLink="/old-app-forgotten-password">{t('forgot_password')}</IonButton>
                                        </IonItem>
                                    </div>

                                    <IonItem lines="none" className="form-list__item pt-20">
                                        <FooterTwoButtons save={this.saveTesyCloudLoginDetails} />   
                                    </IonItem>

                                    <div className="text" style={{ marginTop: '56px', marginBottom: '40px' }}>
                                        {t('tesycloud_with')} <span className="text-green">MyTESY</span> {t('tesycloud_you_can')} <span className="text-purple">tesyCloud</span> {t('tesycloud_conv_boil')}.
                                    </div>
                                    <div style={{ textAlign: "center" }}>
                                        <img src={tesyCloudLogo} />
                                    </div>
                                </TabPanel>
                            </div>
                            </div>
                        </Tabs>
                        </div>
                    </div>
                    </div>
                </IonContent>
                <Footer />
            </IonPage>
        );
    }
    
};

const mapStateToProps = state => {
    return {
        app: state.app
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        setAppLanguage
    })
)(AccountDetails);