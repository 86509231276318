import {
    IonPage,
    IonContent,
    IonToolbar,
    IonButton,
    IonList,
    IonItem,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonToggle,
    IonFooter,
    IonGrid,
    IonRow,
    IonCol,
    IonModal,
    IonButtons,
    IonText,
    IonLabel,
    IonCheckbox,
    IonAlert
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'
import dayjs from 'dayjs';

import StandartHeader from '../../../components/header/StandartHeader';
import Week from '../../Week';
import OldDevicesSettingsHelper from '../../../../../helpers/OldDevicesSettingsHelper';
import {updateOldDeviceState} from '../../../../../redux/actions/OldDevicesAction';
import {oldModels} from '../../../../../config/App';
import ProgramHelper from '../../helpers/ProgramHelper';

let programTimer = null;

class Program extends React.Component {

    constructor(props) {
        super(props);

        this.mac = null;
        this.OldDevicesSettingsHelper = null;

        this.state = {
            showDeleteAlert: false,
            selectedDay: 'MO',
            weekDays: {
                MO: {
                  'name': props.t('monday_full'),
                  'checked': true
                },
                TU: {
                  'name': props.t('tuesday_full'),
                  'checked': true
                },
                WE: {
                  'name': props.t('wednesday_full'),
                  'checked': true
                },
                TH: {
                  'name': props.t('thursday_full'),
                  'checked': true
                },
                FR: {
                  'name': props.t('friday_full'),
                  'checked': true
                },
                SA: {
                  'name': props.t('saturday_full'),
                  'checked': true
                },
                SU: {
                  'name': props.t('sunday_full'),
                  'checked': true
                }
            },
            showWeekModal: false,
            showLoading: false,
            program: null,
            alert: {
                isOpen: false,
                header: '',
                message: ''
            },
        }

        //TO DO: Check for problem. From time to time it gives an errors when you start editing / adding / deleting a program
    }

    componentDidMount = async () => {
        this.OldDevicesSettingsHelper = new OldDevicesSettingsHelper(this.mac)

        this.getProgram();
        
        let currentDay = dayjs().day();
        const daysOfWeek = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];

        this.setState({
            selectedDay: this.props.match.params.day != undefined ? this.props.match.params.day : daysOfWeek[currentDay]
        })

        programTimer  = setInterval(async () => {

            this.getProgram();
             
        }, 5000)
    }

    getProgram = () => {

        const {DeviceStatus: status} = this.device;

        let programStatus = {};
        for (const pr in status) {
            if (pr.indexOf('prgP') >= 0) {
                
                programStatus = {...programStatus, [pr]: status[pr]};    
            }
        }

        this.setState({
            program: programStatus
        })

    }

    componentWillUnmount = () => {
        clearInterval(programTimer);
    }


    renderProgram = () => {
        let schedule = [];

        let degreeUnit = '°C';
        if (this.props.match.params.model_name.indexOf('2002') >= 0) {
            degreeUnit = '';
        }


        if (this.state.program) {

            const index = this.props.match.params.index.slice(1);
            const programData = ProgramHelper.createProgram(this.state.program, index, this.model.program.useHaflHours);  

            for (const p in programData[this.state.selectedDay]) {

                const {from, to, temp} = programData[this.state.selectedDay][p];
                //schedule.push(<p key={`${day}_${p}`}>{from} - {to} :: {temp} °CC</p>)    
                schedule.push(
                    <IonItem className="preferences-list__item" lines="none" key={`${this.state.selectedDay}_${p}`}>
                        <div className="preferences-list__inner">
                            <div className="preferences-list__item-start">
                            <div className="preferences-list__time">
                                <svg className="preferences-list__time-icon"><use xlinkHref="#icon-heating"></use></svg>
                                {from}{this.props.t('hour_abbr')} - {to}{this.props.t('hour_abbr')}
                            </div>
                            </div>
                            <div className="preferences-list__item-middle">

                                {temp == 0 ? 
                                    <div className="preferences-list__temperature">
                                        ✖
                                    </div>
                                    
                                : (temp < this.model.minTemp) ?

                                    <div className="preferences-list__temperature">
                                        <svg className="preferences-list__temperature-icon"><use xlinkHref="#icon-snowflake"></use></svg>
                                    </div>
                                    :
                                    <div className="preferences-list__temperature">
                                        <svg className="preferences-list__temperature-icon"><use xlinkHref="#icon-idle"></use></svg>
                                        {temp}{degreeUnit}
                                    </div>
                                }
                            </div>
                            <div className="preferences-list__item-end">
                            {/* <IonButton 
                                color="icon" 
                                style={{marginRight: '12px'}} 
                                routerLink={`/device/program/edit-program/${model}/${this.mac}/${key}`}
                            >
                                <svg><use xlinkHref="#icon-pencil" className="fill-green"></use></svg>
                            </IonButton> */}
                            {/* TO DO: add confirm text when delete button is clicked */}
                            <IonButton color="icon" onClick={() => this.deleteProgram(from, to, index)}>
                                <svg><use xlinkHref="#icon-delete" className="fill-red"></use></svg>
                            </IonButton>
                            </div>
                        </div>
                    </IonItem>
                )    
            }
            
        }
         
        return schedule;
    }

    deleteProgram = (from, to, index) => {

        from = ProgramHelper.getHourKey(from, this.model.program.useHaflHours);
        to = ProgramHelper.getHourKey(to, this.model.program.useHaflHours);

        const newProgram = ProgramHelper.createApiv2Program(
            this.state.program, 
            {
                day: this.state.selectedDay,
                from,
                to,
                temp: this.model.minTemp > 0 ? this.model.minTemp - 1 : 0,
            }, 
            index
        );

        this.OldDevicesSettingsHelper.setDeviceStatus(`prgP${index}${this.state.selectedDay}`, newProgram);
    }

    checkDay = (selectedDay, status) => {
        this.setState({weekDays: {...this.state.weekDays, [selectedDay]:{...this.state.weekDays[selectedDay], checked: status}}});
    }

    renderWeek = () => {

        const days = [];

        for (const day in this.state.weekDays) {

            let {checked, name} = this.state.weekDays[day];

            if (this.state.selectedDay == day) {
                checked = false;
            }

            days.push(
                <IonItem lines="full" key={day}>
                    <IonLabel className="form-checkbox">
                        <IonCheckbox color="secondary" className="form-checkbox__element" name="checkboxName" checked={checked} onIonChange={e => this.checkDay(day, e.detail.checked)}/>
                        <div className="form-checkbox__text">{name}</div>
                    </IonLabel>
                </IonItem>
            );
        }

        return days;
    }

    timer = ms => new Promise(res => setTimeout(res, ms))

    //First Delete old program for all checked days
    //Copy the program from selected day to all Checked days from Modal
    copyProgram = async () => {

        this.setState({
            showLoading: true
        })

        const copyFromProgram = this.state.program[`prg${this.props.match.params.index}${this.state.selectedDay}`];

        for (const wDay in this.state.weekDays) {
            const { checked } = this.state.weekDays[wDay];

            if (checked === true) {
                this.OldDevicesSettingsHelper.setDeviceStatus(`prg${this.props.match.params.index}${wDay}`, copyFromProgram);
            }

        }

        this.setState({
            showWeekModal: false,
            showLoading: false
        })
    }

    handleDeviceMode = (status, mode) => {

        const modeName = Object.keys(this.model.modes).find(key => this.model.modes[key] === mode);

        if (status === 'on') {

            this.setState({
                newDeviceMode: mode,
                alert: {
                    isOpen: true,
                    header: this.props.t(`${modeName}_mode_header`),
                    message: this.props.t(`${modeName}_mode_message`)
                }
            })

        } else {

            this.handleDeviceMode('on', this.model.modes.Manual);
        }
    }


    render = () => {

        this.mac = this.props.match.params.id;

        const { t } = this.props;
        const {devices} = this.props;
        this.model = oldModels[this.props.match.params.model_name]

        const device = devices[this.mac];
        this.device = device;
        
        if (!device || device === undefined) {
            return null;
        }   

        const { DeviceStatus: status} = device;
        const StatusModeName = Object.keys(this.model.modes).find(key => this.model.modes[key] === status.mode);
        this.programsArray = [this.model.modes['Program P1'], this.model.modes['Program P2'] , this.model.modes['Program P3']];

        return (
            <IonPage>
                <StandartHeader name={`${this.props.t('program')} ${this.props.match.params.index}`}/>
        
                <IonContent className="ion-padding">
        
                    <IonCard>
                        <IonCardHeader className="card-list__header">
                        <div className="card-list__header-left">
                            <div style={{ marginTop: '0' }}>
                            <img src="" alt="" />
                            </div>
                            <div>
                            <div className="card-list__title">{`${device.DeviceShortName ?? this.mac}`}</div>
                            </div>
                        </div>
                        <div className="card-list__header-right"></div>
                        </IonCardHeader>
                        {this.model.functions.includes('Adaptive Start') && 
                            <IonCardContent className="card-list__content">
                            <div className="device-box ion-align-items-center">
                                <div className="device-box__left">{t('adaptive_start')}</div>
                                <div className="device-box__right">
                                    <IonToggle 
                                        checked={status.adaptive_start_enable == "1" ? true : false} 
                                        onClick={e => {
                                            this.OldDevicesSettingsHelper.setDeviceStatus('adaptive_start_enable', status.adaptive_start_enable  == "1" ? 'off' : 'on')
                                            this.props.updateOldDeviceState(status.adaptive_start_enable == "1" ? '0' : '1', 'adaptive_start_enable', device.DeviceSerial)
                                        } } 
                                    />
                                </div>
                            </div>
                            </IonCardContent>
                        }
                    </IonCard>
                    
                    <Week defaultDay={this.state.selectedDay} setSelectedDay={day => this.setState({selectedDay: day})}/>
                    
                    {/* <div className="ion-text-right">
                        <IonButton color="delete" onClick={() => this.DeviceSettingsHelper.handleDeleteAllDeviceprograms(this.mac)}>{t('delete_program')}</IonButton>
                    </div> */}

                    <IonGrid>
                        <IonRow>
                            <IonCol className="ion-text-start">
                                <IonButton color="confirm" onClick={() => this.setState({showWeekModal: true})}>{t('save_porgram_to_all_days')}</IonButton>
                                <IonModal isOpen={this.state.showWeekModal} onDidDismiss={() => this.setState({showWeekModal: false})}>
                                    <IonToolbar>
                                        <div slot="end">
                                        <IonButtons>
                                            <IonButton onClick={() => this.setState({showWeekModal: false})}>
                                            <svg className="size-14"><use xlinkHref="#icon-close"></use></svg>
                                            </IonButton>
                                        </IonButtons>
                                        </div>
                                    </IonToolbar>
                                    <div className="ion-padding">
                                        <IonText>
                                        <h2 className="ion-text-center">{t('choose_days_to_copy_program')}</h2>

                                        <IonList className="ion-padding-top">
                                            {this.renderWeek()}
                                        </IonList>
                                        </IonText>
                                    </div>
       
                                    <IonFooter className="app-footer ion-no-border">
                                        <IonToolbar>
                                            <div className="buttons-list">
                                                <div className="buttons-list__item">
                                                    <IonButton expand="block" shape="round" size="large" onClick={this.copyProgram}>{t('save')}</IonButton>
                                                </div>
                                            </div>
                                        </IonToolbar>
                                    </IonFooter>
                                </IonModal>
                            </IonCol>
                            {/* <IonCol className="ion-text-end">
                                <IonButton color="delete" onClick={() => this.setState({showDeleteAlert: true})}>{t('delete_program')}</IonButton>
                            </IonCol>  */}
                        </IonRow>
                    </IonGrid>
            
                    <div className="preferences-list__wrapper">
                        <IonList className="preferences-list">
                            {this.renderProgram()}   
                        </IonList>
                    </div>
                    <div className="ion-content-push"></div>

                    {/* <Loading show={this.state.showLoading}/>*/}

                    <IonAlert
                        isOpen={this.state.alert.isOpen}
                        onDidDismiss={() => this.setState({alert: {...this.state.alert, isOpen: false}, newDeviceMode: ''})}
                        header={this.state.alert.header}
                        message={`"${t(StatusModeName)}" ${t('switch_off_control')}`}
                        buttons={
                            [{
                                text: t('cancel'),
                                handler: () => {
                                    this.setState({
                                        alert: {...this.state.alert, isOpen: false},
                                        newDeviceMode: '',
                                    })
                                }
                            },
                            {
                                text: t('ok'),
                                handler: () => {

                                    this.props.updateOldDeviceState( this.state.newDeviceMode, this.model.commands.mode, this.device.DeviceSerial);
                                    
                                    this.setState({
                                        programState: !this.programsArray.includes(this.state.newDeviceMode) ? 'manual' : this.state.programState
                                    });

                                    if (this.state.newDeviceMode == 10) {
                                        this.OldDevicesSettingsHelper.setDeviceStatus(
                                            'vac', 
                                            1
                                        ); 
                                    }
                                    
                                    this.OldDevicesSettingsHelper.setDeviceStatus(this.model.commands.mode, this.state.newDeviceMode.toUpperCase());
                                }
                            }]
                        }
                    />
                </IonContent>
        
                <IonFooter className="app-footer ion-no-border">
                    <IonToolbar>
                        <div className="buttons-list">
                            <div className="buttons-list__item">
                                <IonButton expand="block" shape="round" size="large" routerLink={`/old/apiv2/program/add-program/${this.props.match.params.model_name}/${this.mac}/${this.state.selectedDay}/${this.props.match.params.programsCount}/${this.props.match.params.index}`}>{t('add_preference')}</IonButton>
                            </div>
                            <div className="buttons-list__item">
                                <IonButton
                                    expand="block" 
                                    shape="round" 
                                    size="large" 
                                    onClick={() => this.handleDeviceMode((status.mode === this.model.modes[`Program ${this.props.match.params.index}`]) ? 'off' : 'on', this.model.modes[`Program ${this.props.match.params.index}`])}
                                >
                                    {(status.mode === this.model.modes[`Program ${this.props.match.params.index}`]) ? t('deactivate') : t('activate')}
                                </IonButton>
                            </div>
                        </div>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        );
    }
};
const mapStateToProps = state => {
    return {
        devices: state.oldDevices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        updateOldDeviceState
    })
)(Program);