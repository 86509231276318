export const HPWH4_11_260_Settings = {
    modes: {
        hp: {
            hasFan: true,
            hasBoost: true,
            minTemp: 10,
            maxTemp: 65
        },
        eh: {
            hasFan: true,
            hasBoost: false,
            minTemp: 10,
            maxTemp: 75
        },
        au: {
            hasFan: true,
            hasBoost: true,
            minTemp: 10,
            maxTemp: 75
        },
        sm: { //NEED to be discussed
            hasFan: true,
            hasBoost: true,
            minTemp: 10,
            maxTemp: 75
        },
        va: {
            hasFan: true,
            hasBoost: false,
            minTemp: 10,
            maxTemp: 75
        },
        program: {
            hasFan: true,
            hasBoost: true,
            minTemp: 10,
            maxTemp: 75
        }
    }
};