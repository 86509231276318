import {
    IonPage,
    IonContent,
    IonToolbar,
    IonButton,
    IonList,
    IonItem,
    IonFooter,
    IonSelect,
    IonSelectOption
} from '@ionic/react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

import B15MqttHelper from "../../../helpers/mqqt/B15MqttHelper";
import ToastMessage from '../components/toast/ToastMessage';
import RangeStartTemperature from '../components/range/RangeStartTemperature';
import StandartHeader from '../components/header/StandartHeader';
import TwoButtons from '../components/footer/TwoButtons';
import ChangeDeviceMode from './components/ChangeDeviceMode';
import Loader from '../components/loading/Loading';

import { setAppMessage } from '../../../redux/actions/AppAction';
import { B15_Settings } from '../../../config/B15';

const Vacation = props => {

    const { 
        devices, 
        match: {
            params: {
                mac_address: mac
            }
        } 
    } = props;

    const device = devices[mac];
    const {state: deviceState} = device;
    const B15MqttHelperOBJ = new B15MqttHelper(mac);
    const minTemp = B15_Settings.minTemp;
    const maxTemp = B15_Settings.maxTemp;

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    const { t } = useTranslation();
    const [year, setYear] = useState(currentYear);
    const [month, setMonth] = useState(currentDate.getMonth() + 1);
    const [date, setDate] = useState(currentDate.getDate());
    const [hour, setHour] = useState(currentDate.getHours());
    const [temp, setTemp] = useState(minTemp);

    const [deviceNewMode, setDeviceNewMode] = useState(null);
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        const {vacation: vac} = deviceState;

        if (vac && Object.keys(vac).length != 0 ) {
            const vacDate = new Date(vac.endDate);

            setYear(vacDate.getFullYear());
            setMonth(vacDate.getMonth() + 1);
            setDate(vacDate.getDate());
            setTemp(vac.temp);
            
            const [vacHour, vacMins] = vac.endHour.split(":");
            //global.debug("HOUTR", vacHour);
            setHour(parseInt(vacHour));
        }
        
    }, [])

    const save = () => {

        const newDate = new Date(year, month - 1, date, hour);
        if (newDate.getTime() > currentDate.getTime()) {
            
            const diffDays = getDiffDays();
            if (diffDays <= 99) {
                setDeviceNewMode(B15_Settings.modes.vacation);
            }else {
                props.setAppMessage(t('hp_vacation_max_days_issue'));  
            }
           

        } else {
            props.setAppMessage(t('b15_vacation_date_cant_be_in_pass'));
        }
    }

    const getDiffDays = () => {

        const newDate = new Date(year, month - 1, date);

        const diffTime = Math.abs(newDate - currentDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

        return diffDays;
    }

    const setVacationMode = () => {

        setShowLoading(true);

        const newDate = new Date(year, month - 1, date);

        B15MqttHelperOBJ.request('setMode', {
            mode: B15_Settings.modes.vacation,
            temp: temp,
            endDate: `${year}-${newDate.getMonth() + 1}-${date}`,
            endHour: `${hour}:00`
        });

        setTimeout(() => {
            setShowLoading(true);
            props.history.goBack();
        }, 2000)
    }

    const renderMonthDays = () => {

        const monthDays = [];

        const daysInMoth = {
            1: 31,
            2: year % 4 == 0 ? 29 : 28,
            3: 31,
            4: 30,
            5: 31,
            6: 30,
            7: 31,
            8: 31,
            9: 30,
            10: 31,
            11: 30,
            12: 31
        }

        for (let i = 1; i <= daysInMoth[month]; i++) {
            monthDays.push(<IonSelectOption key={i} value={i}>{i}</IonSelectOption>);
        }

        return monthDays;
    }

    const renderYears = () => {

        const years = [];
        [currentYear, currentYear + 1, currentYear + 2].map(year => {
            years.push(<IonSelectOption key={`year-${year}`} value={year}>{year}</IonSelectOption>);
        })

        return years;
    }

    const renderHours = () => {

        const hours = [];

        for (let i = 0; i < 24; i++) {
            hours.push(<IonSelectOption key={i} value={i}>{i}:00</IonSelectOption>);
        }

        return hours;
    }

    return (
        <IonPage>

            <StandartHeader name={t('Vacation')}/>
            <IonContent className="ion-padding">   

                <div className="ion-text-center mt-20 mb-20">
                    <svg className="size-80 mx-auto fill-light-blue">
                        <use xlinkHref="#icon-plane"></use>
                    </svg>
                </div>
         
                <div className="preferences-list__wrapper">
                    <IonList className="preferences-list preferences-list--editable">

                        <IonItem className="preferences-list__item" lines="none">
                            <IonSelect 
                                placeholder={t('hp_vacation_year')}
                                onIonChange={e => setYear(e.detail.value)}
                                cancel-text={t('cancel')}
                                value={year}
                            >
                                {renderYears()}   
                            </IonSelect>
                        </IonItem>

                        <IonItem className="preferences-list__item" lines="none">
                            <IonSelect 
                                placeholder={t('month')}
                                onIonChange={e => setMonth(e.detail.value)}
                                cancel-text={t('cancel')}
                                value={month}
                            >
                                <IonSelectOption value={1}>{t('January')}</IonSelectOption>
                                <IonSelectOption value={2}>{t('February')}</IonSelectOption>
                                <IonSelectOption value={3}>{t('March')}</IonSelectOption>
                                <IonSelectOption value={4}>{t('April')}</IonSelectOption>
                                <IonSelectOption value={5}>{t('May')}</IonSelectOption>
                                <IonSelectOption value={6}>{t('June')}</IonSelectOption>
                                <IonSelectOption value={7}>{t('July')}</IonSelectOption>
                                <IonSelectOption value={8}>{t('August')}</IonSelectOption>
                                <IonSelectOption value={9}>{t('September')}</IonSelectOption>
                                <IonSelectOption value={10}>{t('October')}</IonSelectOption>
                                <IonSelectOption value={11}>{t('November')}</IonSelectOption>
                                <IonSelectOption value={12}>{t('December')}</IonSelectOption>
                            </IonSelect>
                        </IonItem>

                        <IonItem className="preferences-list__item" lines="none">
                            <IonSelect 
                                placeholder={t('monthDay')}
                                onIonChange={e => setDate(e.detail.value)}
                                cancel-text={t('cancel')}
                                value={date}
                            >
                                {renderMonthDays()}    
                            </IonSelect>
                        </IonItem>

                        <IonItem className="preferences-list__item" lines="none">
                            <IonSelect 
                                placeholder={`${t('hour')}....`}
                                value={hour}
                                onIonChange = {e => setHour(e.detail.value)}
                            >
                                {renderHours()}    
                            </IonSelect>
                        </IonItem>

                        <IonItem className="preferences-list__item" lines="none">
                            <div className="preferences-list__inner">
                                <div className="preferences-list__item-middle">
                                    <RangeStartTemperature 
                                        model={device.model.toLowerCase()}
                                        data-min={minTemp} 
                                        data-max={maxTemp} 
                                        data-start={temp} 
                                        data-step={1} 
                                        data-label={t('ewh_target_temperature')} 
                                        setTemp={temp => setTemp(temp)}
                                    />
                                </div>
                            </div>
                        </IonItem>
                    </IonList>
                </div>
                <div className="ion-content-push"></div>

                <ToastMessage />

                <ChangeDeviceMode 
                    device={device} 
                    newMode={deviceNewMode} 
                    mac={props.mac} 
                    cancelChange={mode => setDeviceNewMode(mode)}
                    changeMode={() => setVacationMode()}
                />
            </IonContent>
        
            <TwoButtons save={() => save()} nameOfSaveButton={t('activate')}/>

            <Loader show={showLoading}/>
        </IonPage>
    );
}

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    connect(mapStateToProps, {
        setAppMessage
    })
)(Vacation);