import React from 'react';
import {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonBackButton,
    IonPopover,
    IonList,
    IonListHeader,
    IonItem,
    IonToggle,
    IonLabel,
    IonAlert,
    IonButton,
} from '@ionic/react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import arrowBack from '../../../../../assets/images/arrow-back.svg';
import OldDevicesSettingsHelper from '../../../../../helpers/OldDevicesSettingsHelper';
import BelislimoHelper from '../../../../../helpers/BelislimoHelper';
import { withRouter} from 'react-router-dom';
import {updateOldDeviceState} from '../../../../../redux/actions/OldDevicesAction';
import { Link } from 'react-router-dom';

class BoilerApiV1Menu extends React.Component {

    state = {
        popoverState: { showPopover: false, event: undefined },
        showDeleteAlert: false,
    }

    componentDidMount = () => {
        this.OldDevicesSettingsHelper = new OldDevicesSettingsHelper(this.props.device.DeviceSerial);
    }

    render = () => {

        const { DeviceStatus: status, DeviceOnline} = this.props.device;
        const { model } = this.props;

        const menuList = [
            {
                'title': this.props.t('dashboard'),
                'icon': 'dashboard',
                'active': this.props.activeMenu === 'dashboard' ? true : false,
                'link': '/dashboard'
            },
            {
                'title': this.props.t('control_panel'),
                'icon': 'dashboard',
                'active': this.props.activeMenu === 'controlPanel' ? true : false,
                'link': `/boiler/apiv2/${this.props.model_name}/${this.props.device.DeviceSerial}`
            },
            {
                'title': this.props.t('statistics'),
                'icon': 'statistic',
                'active': this.props.activeMenu === 'statistic' ? true : false,
                'link': `/old/statistics/${this.props.model_name}/${this.props.device.DeviceSerial}`
            },
            {
                'title': this.props.t('calculator_button'),
                'icon': 'calc',
                'active': this.props.activeMenu === 'calculator' ? true : false,
                'link': `/old/apiv2/calculator-graphic/${this.props.model_name}/${this.props.device.DeviceSerial}`
            },
            {
                'title': this.props.t('device_settings'),
                'icon': 'settings',
                'active': this.props.activeMenu === 'deviceSettings' ? true : false,
                'link': `/old/apiv2/device-settings/${this.props.model_name}/${this.props.device.DeviceSerial}`
            },
            {
                'title': this.props.t('device_info'),
                'icon': 'info',
                'active': this.props.activeMenu === 'deviceInfo' ? true : false,
                'link': `/old/apiv2/device-info/${this.props.model_name}/${this.props.device.DeviceSerial}`
            }
        ];
        
        let bHelper = null;
        const isBelislimo = this.props.model_name .indexOf('2002-') >= 0 ? true : false;

        if (isBelislimo) {
            bHelper = new BelislimoHelper(this.props.device);
        }

        return (
            <aside className="dashboard__aside">
                <div className="dashboard__aside-inner">
                    <IonButton color="back-secondary" onClick={() => this.props.history.goBack()}>
                        <svg className="icon"><use xlinkHref="#icon-arrow-back"></use></svg>
                        <span className="button__text">{this.props.t('back')}</span>
                    </IonButton>

                    
                    <div className="device-preview">
                        <div className="device-preview__preview">
                            <img src={`${process.env.PUBLIC_URL}${isBelislimo ? bHelper.getImage() : model.image}`} className="device-preview__preview-image" alt="Device name" />
                        </div>
                        <div className="device-preview__text">
                            {`${this.props.device.DeviceShortName ?? this.props.device.DeviceSerial}`}
                            <IonButton color="icon-only-transparent ion-margin-end" onClick={() =>this.setState({showDeleteAlert: true})}>
                                <svg className="icon icon-18"><use xlinkHref="#icon-delete"></use></svg>
                            </IonButton>
                            <IonButton color="icon-only-transparent" routerLink={`/old/name-device/${this.props.model_name}/${this.props.device.DeviceSerial}`}>
                                <svg className="icon icon-18"><use xlinkHref="#icon-pencil"></use></svg>
                            </IonButton>
                        </div>
                    </div>
                    
                        
                    <div className="aside-nav">
                        {menuList.map(({ active, title, icon, link }, i) => (
                            <div className="aside-nav__item" key={i}>
                                <Link to={link} className={`aside-nav__button ${active ? 'aside-nav__button--active' : ''}`}>
                                    <svg className="aside-nav__button-icon"><use xlinkHref={`#icon-${icon}`}></use></svg>
                                    {title}
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>

                <IonAlert
                    isOpen={this.state.showDeleteAlert}
                    onDidDismiss={() => this.setState({showDeleteAlert: false})}
                    cssClass='my-custom-class'
                    header={this.props.t('confirm')}
                    message={this.props.t('confirm_device_delete')}
                    buttons={[
                        {
                            text: this.props.t('cancel'),
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: blah => {
                                this.setState({showDeleteAlert: false});
                            }
                        },
                        {
                            text: this.props.t('ok'),
                            handler: () => {
                                this.OldDevicesSettingsHelper.deleteDevice();
                                this.props.history.push('/dashboard');
                            }
                        }
                    ]}
                />
            </aside>
        );
    }
}

export default compose(
    withTranslation(),
    connect(null, {
        updateOldDeviceState
    }),
    withRouter
)(BoilerApiV1Menu);