import {
    IonPage,
    IonContent,
    IonRow,
    IonCol,
    IonButton,
    IonToolbar,
    IonButtons,
    IonLabel,
    IonAlert
} from '@ionic/react';

import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import PageHeader from '../components/header/PageHeader';
import '../components/range/range.scss';
import HeatPumpMenu from './components/HeatPumpMenu';
import PowerButton from './components/PowerButton';
import ManualMode from './components/ManualMode';
import ProgramMode from './components/ProgramMode';
import VacationMode from './components/VacationMode';

import HeatPumpMqttHelper from "../../../helpers/mqqt/HeatPumpMqttHelper";
import HeatPumpHelper from "../../../helpers/HeatPumpHelper";

const ManagePage = props => {

    const mac = props.match.params.mac_address;
    const { t } = props;
    const {devices} = props;
    const device = devices[mac];
    const {state: deviceState} = device;
    const HeatPumpMqttHelperOBJ = new HeatPumpMqttHelper(mac);
    const HeatPumpHelperOBJ = new HeatPumpHelper(device);
    const {hasFan, hasBoost} =  HeatPumpHelperOBJ.getCurrentModeSettings();

    const modes = {
        manual: 0,
        program: 1,
        vacation: 2,
    }

    const [deviceMode, setDeviceMode] = useState(modes[HeatPumpHelperOBJ.getMainMode()]);
    const [deviceInitialMode, setDeviceInitialMode] = useState(HeatPumpHelperOBJ.getMainMode());

    useEffect(() => {
        
        const currMode = HeatPumpHelperOBJ.getMainMode();
        
        //WHEN MODE IS CHANGED FROM DEVICE
        if (deviceInitialMode != currMode) {
            setDeviceMode(modes[currMode])
            setDeviceInitialMode(currMode)
        }

    }, [props.devices])


    let disableDevice = false;
    if (deviceState.power_status === 'off' || device.hasInternet == false) {
        disableDevice = true;
    }
    

    return (
        <IonPage>
            <Header />
            <IonContent className="ion-no-padding">
                <div className="dashboard">
                    <div className="dashboard__wrapper">

                        <HeatPumpMenu activeMenu="controlPanel" mac={props.match.params.mac_address}/>

                        <div className="dashboard__content">

                            <PageHeader name={t('control_panel')}/>

                            <div className="dashboard-box">
                                <div className="dashboard-box__header">
                                    <div className="device-row">
                                        <div className="device-row__item">
                                            <div className="device-row-item">
                                                <div className="text-bold text-black mb-10">{t('hp_manage_page_current_temp')}</div>
                                                <div className={`device-state device-state--lg ${deviceState.heating == "on" ? 'text-red' : 'text-green'} ${disableDevice ? 'unactive' : ''}`}>
                                                {deviceState.current_temp}° C
                                                </div>
                                            </div>
                                            <div className="device-row-item">
                                                <div className="text-bold text-black mb-10">{t('hp_status')}</div>
                                                <div className={`device-state ${deviceState.heating == "on" ? 'text-red' : 'text-green'}${disableDevice ? 'unactive' : ''}`}>
                                                    <svg className={`icon-18 ${deviceState.shower == "on" ? 'fill-green' : 'unactive'}`}>
                                                        <use xlinkHref="#icon-shower"></use>
                                                    </svg>
                                                    <svg className={`icon-18 ${deviceState.heating == "on" ? "fill-red" : "fill-green"}`}>
                                                        <use xlinkHref="#icon-idle"></use>
                                                    </svg>
                                                    {deviceState.heating == "on" ? t('heating') : t('ready')}
                                                </div>
                                            </div>
                                            <div className="device-row-item">
                                                <div className="text-bold text-black mb-10">{t('hp_indication')}</div>
                                                <div className="device-row-list">
                                                    <div className="device-row-list__item">
                                                        <svg className={`icon-20 ${deviceState.defrost == "on" ? "fill-blue" : "unactive"} ${disableDevice ? 'unactive' : ''}`}>
                                                            <use xlinkHref="#icon-desfrost"></use>
                                                        </svg>
                                                    </div>
                                                    <div className="device-row-list__item">
                                                        <svg className={`icon-20 ${deviceState.eHeaterInd == "on" ? "fill-red" : "unactive"} ${disableDevice ? 'unactive' : ''}`}>
                                                            <use xlinkHref="#icon-el-pv"></use>
                                                        </svg>
                                                    </div>
                                                    <div className="device-row-list__item">
                                                        <svg className={`icon-20 ${deviceState.connectivity == "on" ? "fill-black" : "unactive"} ${disableDevice ? 'unactive' : ''}`}>
                                                            <use xlinkHref="#icon-solar-boiler"></use>
                                                        </svg>
                                                    </div>
                                                </div>
                                                {deviceState.disinfection == "on" && 
                                                    <div className={`ion-text-center text-14 text-red ${disableDevice ? 'text-grey unactive' : ''}`}>{t('hp_anti_legionela')}</div>
                                                }
                                            </div>
                                        </div>
                                        <div className="device-row__item">
                                            <div className="device-row-item">
                                                <IonRow>
                                                    <IonCol className="pt-0">
                                                        <div className="control-button-wrapper">
                                                            <IonButton 
                                                                color="transparent" 
                                                                className={`control-button ${deviceState.boost == "on" ? "active": ""}`} 
                                                                disabled={(hasBoost == false || disableDevice) ? true : false}
                                                                onClick={() => HeatPumpMqttHelperOBJ.request('setBoost', {state: deviceState.boost == 'on' ? 'off' : 'on'})}
                                                            >
                                                                <svg className="control-button__icon"><use xlinkHref="#icon-boost"></use></svg>
                                                            </IonButton>
                                                            <div className="control-button__text">{t('hp_boost')}</div>
                                                        </div>
                                                    </IonCol>
                                                    <IonCol className="pt-0">
                                                        <div className="control-button-wrapper">
                                                            <IonButton 
                                                                color="transparent" 
                                                                className={`control-button ${deviceState.fan == "on" ? "active": ""}`}
                                                                disabled={(hasFan == false || disableDevice) ? true : false}
                                                                onClick={() => HeatPumpMqttHelperOBJ.request('setFan', {state: deviceState.fan == 'on' ? 'off' : 'on'})}
                                                            >
                                                                <svg className="control-button__icon"><use xlinkHref="#icon-fan"></use></svg>
                                                            </IonButton>
                                                            <div className="control-button__text">{t('hp_fan')}</div>
                                                        </div>
                                                    </IonCol>
                                                </IonRow>
                                            </div>
                                            <div className="device-row-item">
                                                <div className="button-switch-wrapper">
                                                    <PowerButton mac={mac} power={deviceState.power_status} />
                                                    <div className="button-switch__text">{deviceState.power_status == "on" ? t('hp_active_status') : t('hp_inactive_status')}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="dashboard-box__body">
                                    <Tabs forceRenderTabPanel selectedIndex={deviceMode} onSelect={(index) => setDeviceMode(index)} className="react-tabs react-tabs--mod">
                                        <TabList>
                                            <Tab
                                                disabled= {disableDevice ? true : false} 
                                                
                                            >
                                                {t('hp_manage_page_manual_control')}
                                            </Tab>
                                            <Tab
                                                disabled= {disableDevice ? true : false} 
                                                
                                            >
                                                {t('hp_manage_page_program')}
                                            </Tab>
                                            <Tab
                                                disabled= {disableDevice ? true : false} 
                                                
                                            >
                                                {t('hp_manage_page_vacation')}
                                            </Tab>
                                        </TabList>

                                        <TabPanel>
                                            <ManualMode disableDevice={disableDevice} device={device} mac={mac}/>
                                        </TabPanel>
                                        <TabPanel>
                                            <ProgramMode disableDevice={disableDevice} device={device} mac={mac} changeMainMode={mode => setDeviceMode(modes[mode])}/>
                                        </TabPanel>
                                        <TabPanel>
                                            <VacationMode disableDevice={disableDevice} device={device} mac={mac} changeMainMode={mode => setDeviceMode(modes[mode])}/>
                                        </TabPanel>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </IonContent>
            <Footer />
        </IonPage>
    );
};

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, { })
)(ManagePage);