import {
    IonContent,
    IonFooter,
    IonButton,
    IonPage,
    IonToolbar,
    IonText,
    IonSelect,
    IonSelectOption,
    IonLabel,
    IonItem,
    IonInput,
} from '@ionic/react';

import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { Device } from '@capacitor/device';
import dayjs from 'dayjs';


import {
    chooseDevice,
    setDeviceWifiArray,
    chooseNetworkOriginal
} from '../../../../redux/actions/NewDeviceAction';
import { setAppMessage, logger } from '../../../../redux/actions/AppAction';

import StandartHeader from '../../components/header/StandartHeader';
import ToastMessage from '../../components/toast/ToastMessage';
import Loading from '../../components/loading/Loading';
import WifiHelper from '../../../../helpers/WifiHelper';
import DeviceRestHelper from '../../../../helpers/DeviceRestHelper';
import AppHelper from '../../../../helpers/AppHelper';
import { modelsSettings } from '../../../../config/App';
import chooseDeviceImage from '../../../../files/chooseDevice.svg';

class ChooseDevice extends React.Component {

    state = {
        networks: [],
        selectedNetwork: "",
        appInfo: {},
        build: null,
        version: null,
        showLoading: false
    }

    componentDidMount = async () => {
        //this.scanForNetworks();
        const info = await Device.getInfo();
        const {build , version} = await AppHelper.getAppVersion();
        
        this.setState({
            appInfo: info,
            build,
            version,
            selectedNetwork: this.props.newDevice.deviceSSID
        })
    }

    scanForNetworks = async () => {
        try {
            const wifiNetworksResult = await WifiHelper.scanForWifiNetworks();

            this.setState({
                networks: wifiNetworksResult
            });
        } catch (e) {
            //TO DO: Ask what to do here IOS 
            global.debug("Can't scan for networks", e);
        }
    }

    /**
     * Remember the origin app netwokr
     * Connect to static device wifi name (IOS / Android)
     * Extract wifi array from device
     * Set device data into redux state 
     */

    continue = async () => {

        this.props.logger(dayjs().format("HH:mm:ss")+" STEP 1: Connect to device AP (Tesy-CN06AS) \n\n");
        this.setState({
            showLoading: true
        });

        //TO DO: Extract the right data from settings for the right device
        const { ap_name, http_api_url } = modelsSettings.cn06as

        const deviceRest = new DeviceRestHelper(http_api_url);

        this.props.chooseDevice(ap_name);
        
        try {

            this.props.logger(`1.Trying to connect from phone: ${this.state.appInfo.manufacturer} ${this.state.appInfo.model}, os version: ${this.state.appInfo.osVersion}, app version: ${this.state.version} (${this.state.build})`);
            //let appWifi = '';

            // try {
            //     appWifi = await WifiHelper.getConnectedSSID();
            //     global.debug("My network is", appWifi);
            //     this.props.chooseNetworkOriginal(appWifi);
            // } catch (e) {
            //     global.debug(`can't take current SSID`, e);
            // }

            if (this.state.appInfo.platform === 'ios') {
                await WifiHelper.connectToNetworkIOS(ap_name);
            } else {
                const connectResp = await WifiHelper.connectToNetwork(ap_name, "");
                global.debug("Connect resp", connectResp);
            }

            deviceRest.post('/wifi', {
                command: 'wifi'
            })
            .then(async res => {

                let {connected, wifi} = res.data;
                this.props.setDeviceWifiArray(wifi);

                this.setState({
                    showLoading: false
                });

                // if (appWifi !== '' && appWifi !== ap_name) {
                //     this.props.history.push('/cn06/connect-to-wifi');
                // } else {
                //     this.props.history.push('/cn06/change-wifi');
                // }

                this.props.history.push('/cn06/change-wifi');

            })
            .catch (error => {

                this.setState({
                    showLoading: false
                })
                
                this.props.logger(`ChooseDevice: Cannot take the wifi response from device: ${error.message}`);
                this.props.setAppMessage(this.props.t('device_connection_problem'));
            })

        } catch (e) {

            this.setState({
                showLoading: false
            })

            this.props.logger(`ChooseDevice: Cannot connect to device network: ${e}`);
            this.props.setAppMessage(this.props.t('device_connection_problem'));
        }


    }

    renderNetworksList = () => {
        
        if (this.state.networks.length === 0) {
            return null;
        }

        let ssidArray = [];
        return this.state.networks.map((n, k) => {

            const {SSID} = n;

            if (!ssidArray.includes(SSID)) {

                if (SSID.indexOf("Tesy") >= 0) {

                    ssidArray.push(SSID);
                    
                    return <IonSelectOption value={SSID} key={k}>{SSID}</IonSelectOption>
                }
            }
        })
    }

    doRefresh = event =>{

        setTimeout(() => {

            this.scanForNetworks()
            event.detail.complete();

        }, 2000);
    }

    render = () => {
        const { t } = this.props;

        return (
            <IonPage>        
                
                <StandartHeader name={t('choose_device')} />

                <IonContent className="ion-padding">
                    <div className="circle-image">
                        <img src={chooseDeviceImage} />
                    </div>                
                    
                    {/* <IonLabel color="primary">{t('device')}</IonLabel>
                    {this.state.appInfo.platform === 'android' ? 
                        <IonItem lines="none" className="ion-no-padding ion-no-border">
                            <IonLabel hidden>{t('devices')}</IonLabel>
                            <IonSelect placeholder={t('select_device')} onIonChange={e => this.setState({selectedNetwork: e.detail.value})}>
                                {this.renderNetworksList()}
                            </IonSelect> 
                        </IonItem> :
                        <IonItem lines="full" className="form-list__item">
                            <IonLabel position="stacked" color="primary" className="form-label"></IonLabel>
                            {/* TO DO: fix model CN06AS 
                            <IonInput 
                                type="text" 
                                className="form-input" 
                                placeholder={t('select_device_ios')} 
                                onIonChange={ e => this.setState({selectedNetwork: e.detail.value !== '' ? `Tesy-CN06AS-${e.detail.value}` : ''})}
                            />
                        </IonItem>
                    } */}

                    <hr />
                    {/* TO DO: Ask for texts */}
                    <IonText>
                    <p>{t('ch_dev_text1')}</p>
                        <p>{t('ch_dev_text2')}</p>
                        <ul>
                            <li>{t('ch_dev_text3')}</li>
                            <li>{t('ch_dev_text4')}</li>
                            <li>{t('ch_dev_text5')}</li>
                            <li>{t('ch_dev_text6')}</li>
                            <li>{t('ch_dev_text7')}</li>
                            <li>{t('ch_dev_text8')}</li>
                        </ul>
                    </IonText>

                    <ToastMessage />
                    {/* <Refresher doRefresh={event => this.doRefresh(event)}/> */}
                    <Loading show={this.state.showLoading}/>

                </IonContent>
                
                <IonFooter className="app-footer ion-no-border">
                    <IonToolbar>
                        <div className="buttons-list">
                            <div className="buttons-list__item">
                                <IonButton expand="block" shape="round" size="large" onClick={this.continue}>{t('continue')}</IonButton>
                            </div>
                        </div>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        );
    }
    
};

const mapStateToProps = state => {
    return {
        newDevice: state.newDevice
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        chooseDevice,
        setAppMessage,
        setDeviceWifiArray,
        chooseNetworkOriginal,
        logger
    })
)(ChooseDevice);
  