import {
    IonPage,
    IonContent,
    IonRow,
    IonCol,
    IonButton,
    IonSelect,
    IonSelectOption,
    IonAlert
} from '@ionic/react';

import React, { useState } from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';

import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import Menu from './components/Menu';
import PageHeader from '../components/header/PageHeader';
import PageSubHeader from '../components/header/PageSubHeader';
import ToastMessage from '../components/toast/ToastMessage';

import B15MqttHelper from "../../../helpers/mqqt/B15MqttHelper";
import ProgramHelper from './helpers/ProgramHelper';
import { B15_Settings } from '../../../config/B15';

import { setAppMessage } from '../../../redux/actions/AppAction';
import {
    updateDeviceState,
} from '../../../redux/actions/DevicesAction';

import Slider from '@mui/material/Slider';
import '../components/range/range.scss';

const CreateProgram = props => {

    const { 
        devices, 
        match: {
            params: {
                mac_address: mac, 
                day,
                model, 
                program 
            }
        } 
    } = props;
    const device = devices[mac];
    const {state: deviceState} = device;
    const B15MqttHelperOBJ = new B15MqttHelper(mac);

    const { t } = useTranslation();
    const [from, setFrom] = useState("00:00");
    const [to, setTo] = useState("23:59");
    const [temp, setTemp] = useState(B15_Settings.minTemp);
    const [timeOptions, setTimeOptions] = useState([
        '00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00',
        '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '23:59'
    ]);

    const saveProgram = () => {

        let programHelperOBJ = new ProgramHelper(mac, program);

        let currentProgram = deviceState[`program_${program}`];

        //Check if we have existing program
        if (!currentProgram) {
            currentProgram = programHelperOBJ.createEmptyProgram();
            props.updateDeviceState(currentProgram, `program_${program}`, mac);
            programHelperOBJ = new ProgramHelper(mac, program);
        }

        if (programHelperOBJ.getZones(day) < 4) {

            if (programHelperOBJ.validateProgram(from, to, Number(day))) {

               
                const getProgramDays = programHelperOBJ.getProgramDays();
                const programForTheDay = currentProgram[getProgramDays[day]];
                for (const zone in programForTheDay) {
                    const {from: zoneFrom, to: zoneTo} = programForTheDay[zone];

                    if (zoneFrom == "00:00" && zoneTo == "00:00") {
                        currentProgram[getProgramDays[day]][zone] = {from, to, temp: temp}
                        break;    
                    }
                }

                currentProgram[getProgramDays[day]] = programHelperOBJ.sortProgramForTheDay(currentProgram[getProgramDays[day]]);

                B15MqttHelperOBJ.request('setWeeklyProgram', {progID: program, weekday: currentProgram});
    
                props.history.push(`/device/program/${device.model.toLowerCase()}/${mac}/${program}/${day}`);
            } else {
                props.setAppMessage(t('invalid_program'));
            }
        } else {
            props.setAppMessage(t('dt2_invalid_program_allowed_4_zones'));
        }
        
    }

    return (
        <IonPage>
            <Header />
            <IonContent className="ion-no-padding">
                <div className="dashboard">
                    <div className="dashboard__wrapper">

                        <Menu activeMenu="controlPanel" mac={props.match.params.mac_address}/>

                        <div className="dashboard__content">

                            <PageHeader name={t('control_panel')}/>

                            <div className="dashboard-box">
                                
                                <PageSubHeader title={`${t('program')} ${program.toUpperCase()}`}/>

                                <div className="dashboard-box__body">                                    
                                    <div className="gray-box">
                                        <div className="gray-box__body">
                                            <div className="wrapper">
                                                <IonRow className="ion-justify-between">
                                                    <IonCol size='12' sizeMd='6' sizeLg='4' className="pt-16 ion-padding-horizontal">
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <strong style={{ minWidth: '60px', paddingRight: '12px' }}>{t('time_from')}:</strong>
                                                            <IonSelect
                                                                label={t('time_from')}
                                                                placeholder={t('time_from')} 
                                                                okText={t('ok')}
                                                                cancelText={t('cancel')}
                                                                onIonChange={e => setFrom(e.detail.value)}
                                                                value={from}
                                                            >
                                                                {timeOptions.map((value, i) => (
                                                                    <IonSelectOption value={String(value)} key={i}>{value}</IonSelectOption>
                                                                ))}
                                                            </IonSelect>
                                                        </div>
                                                    </IonCol>
                                                    <IonCol size='12' sizeMd='6' sizeLg='4' className="pt-16 ion-padding-horizontal">
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <strong style={{ minWidth: '60px', paddingRight: '12px' }}>{t('time_to')}:</strong>
                                                            <IonSelect
                                                                label={t('time_to')}
                                                                placeholder={t('time_to')}
                                                                okText={t('ok')}
                                                                cancelText={t('cancel')}
                                                                onIonChange={e => setTo(e.detail.value)}
                                                                value={to}
                                                            >
                                                                {timeOptions.map((value, i) => (
                                                                    <IonSelectOption value={String(value)} key={i}>{value}</IonSelectOption>
                                                                ))}
                                                            </IonSelect>
                                                        </div>
                                                    </IonCol>
                                                </IonRow>
                                            </div>
                                            <div className="wrapper wrapper--400">
                                                <IonRow className="ion-justify-content-between">
                                                    <IonCol size='12' className="pt-32">
                                                        <div className="range-slider__wrapper">
                                                            <header className="range-slider__header">
                                                                <div className="range-slider__header-item">
                                                                    <div className="range-slider__header-label">{t("ewh_target_temperature")}</div>
                                                                </div>
                                                                <div className="range-slider__header-item">
                                                                    <div className="range-slider__output">{temp}</div>
                                                                </div>
                                                            </header>
                                                            <Slider
                                                                value={temp}
                                                                min={B15_Settings.minTemp}
                                                                max={B15_Settings.maxTemp}
                                                                step={1}
                                                                track="inverted"
                                                                onChange={(event, value) => setTemp(value)}
                                                                onChangeCommitted={(event, value) => setTemp(value)}
                                                                valueLabelDisplay="off" 
                                                                className="range-slider range-slider--temperature device-range-slider"
                                                            />
                                                        </div>
                                                    </IonCol>
                                                    <IonCol size='12' className="pt-16">
                                                        <div className="ion-text-center">
                                                            <IonButton color="primary" id="save-alert" onClick={saveProgram}>
                                                                <span className="button__text">{t('save')}</span>
                                                            </IonButton>
                                                        </div>
                                                    </IonCol>
                                                </IonRow>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastMessage />
            </IonContent>
            <Footer />
        </IonPage>
    );
};

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    connect(mapStateToProps, {
        setAppMessage,
        updateDeviceState
    })
)(CreateProgram);