import store from '../redux/createStore';
import RestHelper from './RestHelper';
import {PushNotifications} from '@capacitor/push-notifications';


import {setPushNofication} from '../redux/actions/AppAction';

class PushNotificationsHelper {

    constructor() {
        this.restHelper = new RestHelper();

        this.PushNotifications = PushNotifications;

    }

    register = userID => {

        this.PushNotifications.requestPermissions().then(result => {
            if (result.receive === 'granted') {
                // Register with Apple / Google to receive push via APNS/FCM
                global.debug("Push Not Permissions", result)
                this.PushNotifications.register();
            } else {
                global.debug("Push Not Permissions Error", result)
            }
        });
        

        this.PushNotifications.addListener('registration', token => {
            //global.debug('New Push Token', token.value);
            
            this.restHelper.post('/app-user-pushnotionficationId', {
                token: token.value,
                newUserID: userID
            });
        });
    }

    initListeners = () => {
        // Show us the notification payload if the app is open on our device
        this.PushNotifications.addListener('pushNotificationReceived', notification => {

            const {id: message_id, title, body} = notification;
            
            global.debug('pushNotificationReceived: ', {message_id, title, body}, notification );
            store.dispatch(setPushNofication({message_id, title, body, is_read: 0}));
        });

        // Method called when tapping on a notification
        this.PushNotifications.addListener('pushNotificationActionPerformed', notification => {

            const {notification: {id: message_id, data: {title, body}}} = notification;

            global.debug('pushNotificationActionPerformed: ', {message_id, title, body, is_read: 0} );
            window.location.href = '/notifications';
            //store.dispatch(setPushNofication({message_id, title, body, is_read: 0}));
        });
    }

    removeListeners = () => {
        this.PushNotifications.removeAllListeners();
    }
}

export default PushNotificationsHelper;