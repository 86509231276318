import {
    IonPage,
    IonContent,
    IonToolbar,
    IonButton,
    IonList,
    IonItem,
    IonFooter,
    IonSelect,
    IonSelectOption
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import RangeTime from './components/range/RangeTime';
import IonDateTime from './components/ionDateTime/IonDateTime';
import RangeStartTemperature from './components/range/RangeStartTemperature';
import StandartHeader from './components/header/StandartHeader';
import {cn05uvSettings} from '../../config/App';
import {
    addDeviceProgram
} from '../../redux/actions/DevicesAction';
import { setAppMessage } from '../../redux/actions/AppAction';

// import '../../assets/css/components/day-picker/day-picker.scss';
// import '../../assets/css/components/preferences/preferences.scss';
// import '../../assets/css/components/program/program.scss';

import DeviceSettingsHelper from '../../helpers/DeviceSettingsHelper';
import ProgramHelper from '../../helpers/ProgramHelper';
import ToastMessage from './components/toast/ToastMessage';


class AddProgram extends React.Component{

    constructor(props){
        super(props);

        this.mac = null;
        this.model = null;
        this.DeviceSettingsHelper = null;

        this.state = {
            selectedDay: 0,
            from: "00:00",
            to: "23:59",
            temp: cn05uvSettings.start_temp,
            timeOptions: [
                '00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00',
                '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30',
                '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', 
                '22:00', '22:30', '23:00', '23:30', '24:00'
            ]
        }
    }

    componentDidMount = () => {
        this.DeviceSettingsHelper = new DeviceSettingsHelper(this.mac);
    }

    saveProgram = () => {

        const programHelper = new ProgramHelper(this.mac);

        if (programHelper.validateProgram(this.state.from, this.state.to, Number(this.props.match.params.day))) {

            this.DeviceSettingsHelper.handleAddDeviceProgram({
                //day: Number(this.state.selectedDay),
                day: Number(this.props.match.params.day),
                from: this.state.from,
                to: this.state.to,
                temp: this.state.temp
            });

            this.props.history.push(`/device/program/${this.model}/${this.mac}/${this.props.match.params.day}`);
            //this.props.history.goBack();
        } else {
            this.props.setAppMessage(this.props.t('invalid_program'));
        }
    }

    render = () => {
        //TO DO: fix the program state when save and immediately after that try to add new program

        this.mac = this.props.match.params.mac_address;
        this.model = this.props.match.params.model;

        const {t} = this.props;

        return (
            <IonPage>

                <StandartHeader name={t('program')}/>
                <IonContent className="ion-padding">            
                    
                    {/* <Week setSelectedDay={day => this.setState({selectedDay: day})} /> */}

                    <div className="preferences-list__wrapper">
                    <IonList className="preferences-list preferences-list--editable">
                        <IonItem className="preferences-list__item" lines="none">
                            <IonSelect 
                                placeholder={t('time_from')}
                                onIonChange={e => this.setState({from: e.detail.value})}
                                cancel-text={t('cancel')}
                            >
                            {this.state.timeOptions.map((value, i) => (
                                <IonSelectOption value={String(value)} key={i}>{value}</IonSelectOption>
                            ))}
                            </IonSelect>
                            {/* <div className="preferences-list__inner">
                                <div className="preferences-list__item-middle">
                                    <RangeTime 
                                        data-min="0" 
                                        data-max="86399" 
                                        data-start="1800" 
                                        data-step="1800" 
                                        data-label={t('time_from')}
                                        setTime={time => this.setState({from: time})} 
                                    />
                                </div>
                            </div> */}
                            {/* <IonDateTime 
                                data-label={t('time_from')}    
                                setTime={time => this.setState({from: time})} 
                            /> */}
                        </IonItem>
                        <IonItem className="preferences-list__item" lines="none">
                            <IonSelect 
                                placeholder={t('time_to')}
                                onIonChange={e => this.setState({to: e.detail.value})}
                                cancel-text={t('cancel')}
                            >
                            {this.state.timeOptions.map((value, i) => (
                                <IonSelectOption value={String(value)} key={i}>{value}</IonSelectOption>
                            ))}
                            </IonSelect>
                            {/* <div className="preferences-list__inner">
                                <div className="preferences-list__item-middle">
                                    <RangeTime 
                                        data-min="0" 
                                        data-max="86399" 
                                        data-start="86340" 
                                        data-step="1800" 
                                        data-label={t('time_to')} 
                                        setTime={time => this.setState({to: time})}
                                    />
                                </div>
                            </div> */}
                            {/* <IonDateTime 
                                data-label={t('time_to')}    
                                setTime={time => this.setState({to: time})} 
                            /> */}
                        </IonItem>
                        <IonItem className="preferences-list__item" lines="none">
                        <div className="preferences-list__inner">
                            <div className="preferences-list__item-middle">
                                <RangeStartTemperature 
                                    data-min={cn05uvSettings.min_temp} 
                                    data-max={cn05uvSettings.max_temp} 
                                    data-start={cn05uvSettings.start_temp} 
                                    data-step={cn05uvSettings.step_temp} 
                                    data-label={t('degree_unit')} 
                                    setTemp={temp => this.setState({temp})}
                                />
                            </div>
                        </div>
                        </IonItem>
                    </IonList>
                    </div>
                    <div className="ion-content-push"></div>

                    <ToastMessage />
                </IonContent>
            
                <IonFooter className="app-footer ion-no-border">
                    <IonToolbar>
                        <div className="buttons-list">
                        <div className="buttons-list__item">
                            <IonButton expand="block" shape="round" size="large" onClick={this.saveProgram}>{t('save')}</IonButton>
                        </div>
                        <div className="buttons-list__item">
                            <IonButton expand="block" shape="round" size="large" color="help" routerLink={`/device/program/${this.model}/${this.mac}/${this.props.match.params.day}`}>{t('cancel')}</IonButton>
                        </div>
                        </div>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        );
    }
};

export default compose(
    withTranslation(),
    connect(null, {
        addDeviceProgram,
        setAppMessage
    })
)(AddProgram);