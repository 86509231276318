import React from 'react';
import {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonBackButton,
    IonPopover,
    IonList,
    IonListHeader,
    IonItem,
    IonToggle,
    IonLabel,
    IonAlert
} from '@ionic/react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import arrowBack from '../../../../../assets/images/arrow-back.svg';
import OldDevicesSettingsHelper from '../../../../../helpers/OldDevicesSettingsHelper';
import { withRouter} from 'react-router-dom';
import {updateOldDeviceState} from '../../../../../redux/actions/OldDevicesAction';

class HeaderWithPopoverMenu extends React.Component {

    state = {
        popoverState: { showPopover: false, event: undefined },
        showDeleteAlert: false,
        
    }

    componentDidMount = () => {
        this.OldDevicesSettingsHelper = new OldDevicesSettingsHelper(this.props.mac);
    }

    deleteDevice = () => {
        this.OldDevicesSettingsHelper.deleteDevice();
        this.props.history.push('/dashboard');
    }

    render = () => {

        const { DeviceStatus: status, DeviceOnline} = this.props.device;
        const { model } = this.props;

        const popoverMenu = [
                {
                    'title' : this.props.t('rename_device'),
                    'icon' : 'pencil',
                    'url' : `old/name-device/${this.props.model_name}/${this.props.device.DeviceSerial}`,
                    'disabled': false
                },
                {
                    'title' : this.props.t('device_info'),
                    'icon' : 'device-info',
                    'url' : `old/apiv2/device-info/${this.props.model_name}/${this.props.device.DeviceSerial}`,
                    'disabled': false
                },
                {
                    'title' : this.props.t('statistics'),
                    'icon' : 'statistic',
                    'url' : `old/statistics/${this.props.model_name}/${this.props.device.DeviceSerial}`,
                    'disabled': false
                },
                {
                    'title' : this.props.t('calculator_button'),
                    'icon' : 'calc',
                    'url' : `old/apiv2/calculator-graphic/${this.props.model_name}/${this.props.device.DeviceSerial}`,
                    'disabled': false
                },
                {
                    'title' : this.props.t('settings'),
                    'icon' : 'settings',
                    'url' : `old/apiv2/device-settings/${this.props.model_name}/${this.props.device.DeviceSerial}`,
                    'disabled': false
                },
            ]

        return (
            <IonHeader className="app-header">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton icon={arrowBack} text=""></IonBackButton>
                    </IonButtons>
                    <IonTitle>{this.props.name}</IonTitle>
                    <IonButtons slot="end" onClick={() => this.props.history.push(`/dashboard`)}>
                        <svg className="size-18 mr-16">
                            <use xlinkHref="#icon-home"></use>
                        </svg>
                    </IonButtons>
                    <div slot="end" onClick={
                        (e) => {
                            e.persist();
                            this.setState({popoverState: { 
                                showPopover: true, 
                                event: e 
                            }})
                        }}
                        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                    >
                        <svg className="size-24"><use xlinkHref="#icon-dots"></use></svg>
                        <IonPopover
                            dismissOnSelect={true}
                            event={this.state.popoverState.event}
                            isOpen={this.state.popoverState.showPopover}
                            onDidDismiss={e => this.setState({popoverState: { 
                                showPopover: false, 
                                event: e 
                            }})}
                        >
                            <IonList>
                                <IonListHeader>{this.props.t('more')}</IonListHeader>

                                {popoverMenu.map(({ title, icon, url, disabled }, i) => (
                                    <IonItem 
                                        lines="full" 
                                        button 
                                        key={i}  
                                        routerLink={`/${url}`} 
                                        disabled={disabled}
                                    >
                                        <svg><use xlinkHref={`#icon-${icon}`}></use></svg>{title}
                                    </IonItem>
                                ))}

                                <IonItem 
                                    lines="full" 
                                    button 
                                    onClick={(e) => this.setState({showDeleteAlert: true,})}
                                >
                                    <svg><use xlinkHref={`#icon-delete`}></use></svg>{this.props.t('delete_device')}
                                </IonItem>
                            </IonList>
                        </IonPopover>
                    </div>
                </IonToolbar>

                <IonAlert
                    isOpen={this.state.showDeleteAlert}
                    onDidDismiss={() => this.setState({showDeleteAlert: false})}
                    cssClass='my-custom-class'
                    header={this.props.t('confirm')}
                    message={this.props.t('confirm_device_delete')}
                    buttons={[
                        {
                            text: this.props.t('cancel'),
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: blah => {
                                this.setState({showDeleteAlert: false});
                            }
                        },
                        {
                            text: this.props.t('ok'),
                            handler: () => {
                                this.deleteDevice()
                            }
                        }
                    ]}
                />
            </IonHeader>
        );
    }
}

export default compose(
    withTranslation(),
    connect(null, {
        updateOldDeviceState
    }),
    withRouter
)(HeaderWithPopoverMenu);