import {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonInput,
    IonButton
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Collapsible from 'react-collapsible';

import {oldModels} from '../../../config/App'; 
import OldDevicesSettingsHelper from '../../../helpers/OldDevicesSettingsHelper'; 
import BelislimoHelper from '../../../helpers/BelislimoHelper'; 

import BoilerApiV1Menu from './boilers/apiv1/BoilerApiV1Menu';
import Header from '../components/header/Header';
import PageHeader from '../components/header/PageHeader';
import Footer from '../components/footer/Footer';
import TemperatureSlider from '../components/range/TemperatureSlider';
import {updateOldDeviceState} from '../../../redux/actions/OldDevicesAction';

import dayjs from 'dayjs';
import Timezones from '../../../config/TimeZones.json';
import timezonePlugin from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
  
class BoilerApiV1Settings extends React.Component 
{

    constructor(props) {

        super(props);

        this.mac = null;
        this.device = null;
        this.OldDevicesSettingsHelper = null

        this.state = {
            month: 1,
            monthDay: 1,
            hour: 0,
            vacTemp: 0,
            selectedTimeZone: '',
            date: '',
            time: '',
            day: 0,
            deviceTimezone: ''
        }
    }

    componentDidMount = () => {
        this.OldDevicesSettingsHelper = new OldDevicesSettingsHelper(this.mac);  
        this.getVacation();
        
    }
   
    getVacation = () => {

        const {vacDate, vacTemp} = this.device.DeviceStatus;

        if (vacDate != undefined && vacTemp != undefined) {

            const date = new Date(vacDate);    

            
            this.setState({
                month: date.getMonth() + 1,
                monthDay: date.getDate(),
                hour: date.getHours(),
                vacTemp: vacTemp
            });

        } else {

            const currentDate = new Date();
            const nextWeekDate = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);

            this.setState({
                month: nextWeekDate.getMonth() + 1,
                monthDay: nextWeekDate.getDate(),
                hour: nextWeekDate.getHours(),
            });
        }
    }


    getTimeZone = async () => {
        const {data: {zonename}} = await this.OldDevicesSettingsHelper.setDeviceStatus('getTZ','');

        this.handleTimzoneChange(zonename);

        this.setState({
            deviceTimezone: zonename
        });
    }

    handleTimzoneChange = timezone => {

        this.setState({
            selectedTimeZone: timezone
        });

        const timeZoneObj = this.catchTimeZoneObj(timezone);
         
        this.setState({
            date: timeZoneObj.format("MMMM D, YYYY"),
            time: timeZoneObj.format("HH:mm"),
            day: timeZoneObj.weekday()
        });
    }

    catchTimeZoneObj = timezone => {

        dayjs.Ls.en.weekStart = 1;
        dayjs.extend(utc);
        dayjs.extend(timezonePlugin);
        dayjs.extend(weekday);
        dayjs.locale('en');

        const utcTime = dayjs.utc().format("YYYY-MM-DD HH:mm");
        const localTime = dayjs().format("YYYY-MM-DD HH:mm");

        const timeZoneObj = dayjs(localTime).tz(timezone);

        return timeZoneObj;
    }

    checkTabSelection = index => {

        if (index === 1) {// Time Zone Tab
            this.getTimeZone();
        } else {
            this.getVacation();
        }
    }

    setTime = time => {
        let [hour, min] = time.split(":");
        hour = Number(hour);
        min = Number(min);

        this.setState({
            time: hour*60 + min
        })
    }

    saveTimeZoneData = () => {
        this.OldDevicesSettingsHelper.setDeviceStatus('tz', this.state.selectedTimeZone );
    }

    renderTimezones = () => {
        const timezonesList = [];
        for (const zone in Timezones) {
            for (const timezone in Timezones[zone]) {
                timezonesList.push(<IonSelectOption value={timezone} key={`${zone}_${timezone}`}>{timezone}</IonSelectOption>)
            }
        }

        return timezonesList;
    }

    renderMonthDays = () => {

        const currentDate = new Date();
        const monthDays = [];

        const daysInMoth = {
            1: 31,
            2: currentDate.getFullYear() % 4 == 0 ? 29 : 28,
            3: 31,
            4: 30,
            5: 31,
            6: 30,
            7: 31,
            8: 31,
            9: 30,
            10: 31,
            11: 30,
            12: 31
        }

        for (let i = 1; i <= daysInMoth[this.state.month]; i++) {
            monthDays.push(<IonSelectOption key={i} value={i}>{i}</IonSelectOption>);
        }

        return monthDays;
    }

    renderHours = () => {

        const hours = [];

        for (let i = 0; i < 24; i++) {
            hours.push(<IonSelectOption key={i} value={i}>{i}:00</IonSelectOption>);
        }

        return hours;
    }

    saveVacation = () => {

        const currentDate = new Date();

        const date = new Date(currentDate.getFullYear(), (this.state.month - 1), this.state.monthDay);
        const weekDay = date.getDay();

        this.OldDevicesSettingsHelper.setDeviceStatus(
            'setVacation', 
            `vYear%3D${currentDate.getFullYear().toString().substr(-2)}%26vMonth%3D${this.state.month}%26vMDay%3D${this.state.monthDay}%26vWDay%3D${weekDay}%26vHour%3D${this.state.hour}%26vTemp%3D${this.state.vacTemp}`
        );

        this.props.updateOldDeviceState( this.model.modes['Vacation'], this.model.commands.mode, this.device.DeviceSerial);
        
        this.OldDevicesSettingsHelper.setDeviceStatus(this.model.commands.mode, this.model.modes['Vacation']);
    }


    render = () => 
    {
        const { match: {params: {id, model_name}}, devices} = this.props;
        this.mac = id;
        this.device = devices[id];
        this.model = oldModels[model_name];
        const {t} = this.props;
        const { DeviceStatus: status} = this.device;

        let minTemp = null;
        let maxTemp = null;

        if (model_name == '2002-v1') {

            const bHelper = new BelislimoHelper(this.device );
            minTemp = 0;
            maxTemp = bHelper.getShowers();

        } else {

            minTemp = this.model.minTemp;
            maxTemp = this.model.maxTemp;
        }  
        
        let vacTemp = this.device.DeviceStatus.vacTemp != undefined ? this.device.DeviceStatus.vacTemp : minTemp;
        if (vacTemp > maxTemp) {
            vacTemp = maxTemp;
        }


        return (
            <IonPage>
                <Header />
                <IonContent className="ion-no-padding">
                    <div className="dashboard">
                        <div className="dashboard__wrapper">
                            
                            <BoilerApiV1Menu 
                                activeMenu="deviceSettings" 
                                model_name={this.props.match.params.model_name} 
                                device={this.props.devices[this.props.match.params.id]} 
                                model={oldModels[this.props.match.params.model_name]}
                            />

                            <div className="dashboard__content">
                            
                                <PageHeader name={t('device_settings')} image="settings"/>

                                <Tabs forceRenderTabPanel defaultIndex={0} onSelect={index => this.checkTabSelection(index)} className="react-tabs react-tabs--alt">

                                    <TabList>
                                        <Tab>{t('modes')}</Tab>
                                        <Tab>{t('world_clock')}</Tab>
                                    </TabList>

                                    <div className="react-tabs__box">

                                    <TabPanel>

                                            <Collapsible
                                                trigger={`${t('Vacation')} ${t('mode')}`}
                                                transitionTime={300}
                                                open={true}
                                            >
                                                <div className="preferences-list preferences-list--sm pt-32 pb-32">
                                                    <div className="preferences-list__wrapper">
                                                        <div className="ion-text-center">
                                                            <svg className="size-80 fill-light-purple">
                                                                <use xlinkHref="#icon-plane"></use>
                                                            </svg>
                                                            <p style={{fontSize: '16px', color: 'var(--color-gray-19)'}}>{t('coming_from_vacation')}</p>
                                                        </div>
                                                        <IonList className="preferences-list preferences-list--editable">

                                                            <IonItem className="preferences-list__item" lines="none">
                                                                <div className="preferences-list__inner">
                                                                    <div className="preferences-list__item-middle">
                                                                        <IonSelect 
                                                                            placeholder={`${t('month')}....`}
                                                                            value={this.state.month}
                                                                            onIonChange = {e => this.setState({month:e.detail.value})}
                                                                        >
                                                                            <IonSelectOption value={1}>{t('January')}</IonSelectOption>
                                                                            <IonSelectOption value={2}>{t('February')}</IonSelectOption>
                                                                            <IonSelectOption value={3}>{t('March')}</IonSelectOption>
                                                                            <IonSelectOption value={4}>{t('April')}</IonSelectOption>
                                                                            <IonSelectOption value={5}>{t('May')}</IonSelectOption>
                                                                            <IonSelectOption value={6}>{t('June')}</IonSelectOption>
                                                                            <IonSelectOption value={7}>{t('July')}</IonSelectOption>
                                                                            <IonSelectOption value={8}>{t('August')}</IonSelectOption>
                                                                            <IonSelectOption value={9}>{t('September')}</IonSelectOption>
                                                                            <IonSelectOption value={10}>{t('October')}</IonSelectOption>
                                                                            <IonSelectOption value={11}>{t('November')}</IonSelectOption>
                                                                            <IonSelectOption value={12}>{t('December')}</IonSelectOption>
                                                                        </IonSelect>
                                                                    </div>
                                                                </div>
                                                            </IonItem>

                                                            <IonItem className="preferences-list__item" lines="none">
                                                                <div className="preferences-list__inner">
                                                                    <div className="preferences-list__item-middle">
                                                                        <IonSelect 
                                                                            placeholder={`${t('monthDay')}....`}
                                                                            value={this.state.monthDay}
                                                                            onIonChange = {e => this.setState({monthDay: e.detail.value})}
                                                                        >
                                                                            {this.renderMonthDays()}    
                                                                        </IonSelect>
                                                                    </div>
                                                                </div>
                                                            </IonItem>

                                                            <IonItem className="preferences-list__item" lines="none">
                                                                <div className="preferences-list__inner">
                                                                    <div className="preferences-list__item-middle">
                                                                        <IonSelect 
                                                                            placeholder={`${t('hour')}....`}
                                                                            value={this.state.hour}
                                                                            onIonChange = {e => this.setState({hour: e.detail.value})}
                                                                        >
                                                                            {this.renderHours()}    
                                                                        </IonSelect>
                                                                    </div>
                                                                </div>
                                                            </IonItem>

                                                            <IonItem className="preferences-list__item" lines="none">
                                                                <div className="preferences-list__inner">
                                                                    <div className="preferences-list__item-middle">
                                                                        <TemperatureSlider 
                                                                            model={model_name}
                                                                            label={model_name == '2002-v1' ? t('ewh_number_of_showers') : t('ewh_target_temperature')}
                                                                            disabled={false}
                                                                            currentTemp={vacTemp} 
                                                                            minTemperature={minTemp}
                                                                            maxTemperature={maxTemp}
                                                                            stepTemperature={1}
                                                                            handleTemperature={temp => this.setState({vacTemp: temp})}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </IonItem>

                                                            <IonItem className="preferences-list__item" lines="none">
                                                                <div className="ion-text-center" style={{ width: '100%' }}>
                                                                    <IonButton color="primary" onClick={this.saveVacation}>{t('save')}</IonButton>
                                                                </div>
                                                            </IonItem>
                                                        </IonList>
                                                    </div>
                                                </div>
                                            </Collapsible>
                                        </TabPanel>

                                        <TabPanel>
                                            <div className="gray-box">
                                                <div className="gray-box__header gray-box__header--alt">
                                                    {t('world_clock')}
                                                </div>
                                                <div className="gray-box__body gray-box__body--transparent">
                                                    <div style={{ maxWidth: '400px', margin: '0 auto' }}>
                                                        <div className="pb-16">
                                                            <IonLabel position="stacked" color="primary" className="form-label">{t('time_zone')}</IonLabel>
                                                            <IonSelect 
                                                                placeholder={String(this.state.selectedTimeZone !== '' ? this.state.selectedTimeZone : this.state.deviceTimezone)} 
                                                                value={String(this.state.selectedTimeZone !== '' ? this.state.selectedTimeZone : this.state.deviceTimezone)} 
                                                                onIonChange={e => this.handleTimzoneChange(e.detail.value)}
                                                                cancel-text={t('cancel')}
                                                            >
                                                                {this.renderTimezones()}
                                                            </IonSelect>
                                                        </div>
                                                        <div className="pb-16">
                                                            <IonLabel position="stacked" color="primary" className="form-label">{t('current_date')}</IonLabel>
                                                            <IonInput type="text" className="form-input" value={this.state.date} readonly={true} disabled={true} />
                                                        </div>
                                                        <div className="pb-16">
                                                            <IonLabel position="stacked" color="primary" className="form-label">{t('current_time')}</IonLabel>
                                                            <IonInput type="text" className="form-input" value={this.state.time} readonly={true} disabled={true} />
                                                        </div>
                                                        <div className="ion-text-center">
                                                            <IonButton color="primary" onClick={this.saveTimeZoneData}>{t('save')}</IonButton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                    </div>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </IonContent>
                <Footer />
            </IonPage>
        );
    }
};

const mapStateToProps = state => {
    return {
        devices: state.oldDevices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        updateOldDeviceState
    })
)(BoilerApiV1Settings);