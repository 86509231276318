import {
    IonContent,
    IonFooter,
    IonButton,
    IonPage,
    IonToolbar,
    IonText,
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';

import StandartHeader from '../../../components/header/StandartHeader';
import E32_1 from '../../../../../files/E32_1.svg';
import E32_2 from '../../../../../files/E32_2.svg';

class Е32 extends React.Component {


    render = () => {
        const { t } = this.props;

        return (
            <IonPage>        
                
                <StandartHeader name={t('Boiler E32')} />

                <IonContent className="ion-padding">

                    <div className="text">
                        <div style={{ margin: '16px 0 32px', textAlign: 'center' }}>
                            <img src={E32_1} />
                        </div>
                        <p>1. {t('e32_text1')} <svg className="size-16 fill-green"><use xlinkHref="#icon-icons-16-px-16-001"></use></svg> {t('e32_text2')}</p>
                        <p>2. {t('e32_text3')}</p>
                        <div style={{ margin: '32px 0 16px', textAlign: 'center' }}>
                            <img src={E32_2} />
                        </div>
                        <p>3. {t('e32_text4')}</p>
                        <p>4. {t('e32_text5')}</p>
                        <p>&nbsp;</p>
                        <p><a href="">{t('e32_text6')}</a></p>
                        <p>{t('e32_text7')}</p>
                    </div>

                </IonContent>
                
                <IonFooter className="app-footer ion-no-border">
                    <IonToolbar>
                        <div className="buttons-list">
                            <div className="buttons-list__item">
                                <IonButton expand="block" shape="round" size="large" routerLink={`/production-mode/e32/choose-device`}>{t('continue')}</IonButton>
                            </div>
                        </div>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        );
    }
    
};

export default withTranslation()(Е32);
  