import {
    IonAlert,
    IonRow,
    IonCol,
    IonToolbar,
    IonButtons,
} from '@ionic/react';
import Slider from '@mui/material/Slider';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import HeatPumpHelper from "../../../../helpers/HeatPumpHelper";
import HeatPumpMqttHelper from "../../../../helpers/mqqt/HeatPumpMqttHelper";

import CurrentModeInfo from './CurrentModeInfo';
import ChangeDeviceMode from './ChangeDeviceMode';
import DeviceTabButton from '../../components/ionTabs/DeviceTabButton';

const ManualMode = props => {

    const HeatPumpMqttHelperOBJ = new HeatPumpMqttHelper(props.mac);
    const HeatPumpHelperOBJ = new HeatPumpHelper(props.device);

    const {minTemp, maxTemp} = HeatPumpHelperOBJ.getCurrentModeSettings();
    const {state: deviceState} = props.device;
    const deviceMode = HeatPumpHelperOBJ.getMode();

    const [deviceNewMode, setDeviceNewMode] = useState(null);
    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [tempAlertIsOpen, setTempAlertIsOpen] = useState(false);
    const { t } = useTranslation();
    
    const [temp, setTemp] = useState(deviceState.temp);

    useEffect(() => {
        setTemp(deviceState.temp);
    }, [deviceState.temp])

    const setTempToDevice = () => {

        if (temp > 65) {
            setTempAlertIsOpen(true);
        }

        HeatPumpMqttHelperOBJ.request('setMode', {mode: HeatPumpHelperOBJ.getMode(), setTemp: temp})
    }
        
    const handleDeviceMode = (status, mode) => {

        if (status === 'on') {
            setDeviceNewMode(mode);
        }
    }

    return (
        <React.Fragment>
            <IonRow className="ion-align-items-center">

                <IonCol size='12' sizeMd='4'>
                    <CurrentModeInfo mode={HeatPumpHelperOBJ.getMode()} icon={HeatPumpHelperOBJ.getModeIcon()} disableDevice={props.disableDevice}/>
                </IonCol>

                {(HeatPumpHelperOBJ.getMode() != "program" && HeatPumpHelperOBJ.getMode() != "va") && 
                    <IonCol size='12' sizeMd='8'>
                        <div className="range-slider__wrapper">
                            <header className={`range-slider__header ${props.disableDevice ? 'disabled' : ''}`}>
                                <div className="range-slider__header-item">
                                    <div className="range-slider__header-label">
                                        {t("hp_start_temperature")}

                                        {(temp > deviceState.current_max_temp) &&
                                            <svg className="size-24 fill-orange" style={{ cursor: "pointer", verticalAlign: "middle", marginTop: "-1px" }} disabled={props.disableDevice ? true : false} onClick={() => setAlertIsOpen(true)}>
                                                <use xlinkHref="#icon-warning"></use>
                                            </svg>
                                        }
                                    </div>
                                </div>
                                <div className="range-slider__header-item">
                                    <div className="range-slider__output">{temp}°</div>
                                </div>

                                <IonAlert
                                    isOpen={alertIsOpen}
                                    onDidDismiss={() => setAlertIsOpen(false)}
                                    header={t('hp_temp_issue_header')}
                                    message={t('hp_temp_issue_text', {temp: deviceState.current_max_temp})}
                                    buttons={
                                        [{
                                            text: 'OK',
                                            handler: () => {
                                                setAlertIsOpen(false)
                                            }
                                        }]
                                    }
                                />
                                <IonAlert
                                    isOpen={tempAlertIsOpen}
                                    onDidDismiss={() => setTempAlertIsOpen(false)}
                                    header={t('hp_temp_watch_out_header')}
                                    message={t('hp_temp_watch_out_text')}
                                    buttons={
                                        [{
                                            text: 'OK',
                                            handler: () => {
                                                setTempAlertIsOpen(false)
                                            }
                                        }]
                                    }
                                />
                            </header>

                            <Slider
                                value={temp}
                                min={minTemp}
                                max={maxTemp}
                                step={1}
                                track="inverted"
                                onChange={(event, value) => setTemp(value)}
                                onChangeCommitted={(event, value) => setTempToDevice()}
                                disabled={props.disableDevice}
                                valueLabelDisplay="off" 
                                className="range-slider range-slider--temperature device-range-slider"
                            />
                        </div>
                    </IonCol>
                }
            </IonRow>
            <div className="pt-32">
                <IonToolbar className="bordered">
                    <IonButtons className="ion-justify-content-between">
                        
                        <DeviceTabButton 
                            name={t('hp_mode_hp')} 
                            icon={'heat-pump'} 
                            handleStatus={status => handleDeviceMode(status, "hp")}
                            status={(deviceMode == "hp") ?  'on' : 'off'}
                            disabled= {props.disableDevice ? true : false}
                        />

                        <DeviceTabButton 
                            name={t('hp_mode_eh')} 
                            icon={'heater'} 
                            handleStatus={status => handleDeviceMode(status, "eh")}
                            status={(deviceMode == "eh") ?  'on' : 'off'}
                            disabled= {props.disableDevice ? true : false}
                        />

                        <DeviceTabButton 
                            name={t('hp_mode_au')} 
                            icon={'auto'} 
                            handleStatus={status => handleDeviceMode(status, "au")}
                            status={(deviceMode == "au") ?  'on' : 'off'}
                            disabled= {props.disableDevice ? true : false}
                        />

                        {/* <DeviceTabButton 
                            name={t('hp_mode_sm')} 
                            icon={'smart'} 
                            handleStatus={status => handleDeviceMode(status, "sm")}
                            status={(deviceMode == "sm") ?  'on' : 'off'}
                            disabled= {props.disableDevice ? true : false}
                        /> */}

                    </IonButtons>
                    
                    <ChangeDeviceMode 
                        device={props.device} 
                        newMode={deviceNewMode} 
                        mac={props.mac} 
                        cancelChange={mode => setDeviceNewMode(mode)}
                    />

                </IonToolbar>
            </div>
        </React.Fragment>
    );
}

export default ManualMode;

