import React from "react";
import { IonPicker } from "@ionic/react";
import { useTranslation } from 'react-i18next';

import { languages } from '../../../../config/App';

import './picker.scss';


const Picker = ({onSave, onCancel, isOpen}) => {

    const { t } = useTranslation();

    const pickerOptions = () => {
        return {
            name: "language",
            options: languages.map(lang => {
                return {text: t(lang), value: lang };    
            })
        };
    };

    return (
        <React.Fragment>
            <IonPicker
                isOpen={isOpen}
                columns={[pickerOptions()]}
                buttons={[
                    {
                        text: t("cancel"),
                        role: "cancel",
                        handler: value => {
                            onCancel()
                        }
                    },
                    {
                        text: t("confirm"),
                        handler: value => {
                            onSave(value)
                        }
                    }
                ]}
            ></IonPicker>
        </React.Fragment>
    );
};

export default Picker;