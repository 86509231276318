import {
    IonPage,
    IonContent
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';

import StandartHeader from '../../components/header/StandartHeader';
//import deviceImage_4 from '../../temp-files/2000.jpeg';
import deviceImage_4 from '../../../../temp-files/C21.png';
//import deviceImage_5 from '../../temp-files/2004.jpeg';
import deviceImage_5 from '../../../../temp-files/C22.png';
//import deviceImage_6 from '../../temp-files/2002.jpeg';
import deviceImage_6 from '../../../../temp-files/E31.png';
import deviceImage_7 from '../../../../temp-files/E32.png';
import deviceImage_8 from '../../../../temp-files/B15.png';

const AddDevice = props => {

    return (
        <IonPage>
            <StandartHeader name={props.t('choose_boiler')}/>

            <IonContent className="ion-gray-backgroud">
                <div className="device-list">

                    <div className="device-list__item" onClick={() => props.history.push('/production-mode/b15')}>
                        <div className="device-list__preview">
                            <img src={deviceImage_8} alt="photo" />
                        </div>
                        <div className="device-list__content">
                            <div className="device-list__title">​​​​​​​{props.t('Boiler B15')}</div>
                        </div>
                    </div>

                    <div className="device-list__item" onClick={() => props.history.push('/production-mode/e32')}>
                        <div className="device-list__preview">
                            <img src={deviceImage_7} alt="photo" />
                        </div>
                        <div className="device-list__content">
                            <div className="device-list__title">​​​​​​​{props.t('Boiler E32')}</div>
                        </div>
                    </div>
                    
                    <div className="device-list__item" onClick={() => props.history.push('/production-mode/modecoc21')}>
                        <div className="device-list__preview">
                            <img src={deviceImage_4} alt="photo" />
                        </div>
                        <div className="device-list__content">
                            <div className="device-list__title">​​​​​​​{props.t('Boiler ModEco C21')}</div>
                        </div>
                    </div>

                    <div className="device-list__item" onClick={() => props.history.push('/production-mode/modecoc22')}>
                        <div className="device-list__preview">
                            <img src={deviceImage_5} alt="photo" />
                        </div>
                        <div className="device-list__content">
                            <div className="device-list__title">{props.t('Boiler ModEco C22')}</div>
                        </div>
                    </div>

                    <div className="device-list__item" onClick={() => props.history.push('/production-mode/bellislimo')}>
                        <div className="device-list__preview">
                            <img src={deviceImage_6} alt="photo" />
                        </div>
                        <div className="device-list__content">
                            <div className="device-list__title">{props.t('Boiler BelliSlimo')}</div>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};
  
  export default withTranslation()(AddDevice); 