import {
    IonContent,
    IonFooter,
    IonButton,
    IonPage,
    IonToolbar,
    IonText,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonRow,
    IonCol,
    IonSelect,
    IonSelectOption
} from '@ionic/react';

import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';

import StandartHeader from '../../components/header/StandartHeader';
import ToastMessage from '../../components/toast/ToastMessage';

import {
    setDeviceName,
    setDevicePower,
    deleteNewDevice
} from '../../../../redux/actions/NewDeviceAction';
import { setAppMessage } from '../../../../redux/actions/AppAction';

class NameDevice extends React.Component {

    state = {
        deviceName: '',
        devicePower: ''
    }

    componentDidMount = () => {
        this.setState({
            deviceName: this.props.newDevice.name,
            devicePower: this.props.newDevice.power    
        });    
    }

    continue = () => {
        if (this.state.deviceName !== '' && this.state.devicePower !== '') {

            this.props.setDeviceName(this.state.deviceName);
            this.props.setDevicePower(this.state.devicePower);
            
            this.props.history.push('/cn06/finish');

        } else {
            this.props.setAppMessage(this.props.t('required_device_settings'));
        }
    }

    render = () => {

        const { t } = this.props;
        let { modelPower } = this.props.newDevice;
        modelPower = modelPower.split(',');
        
        return (
            <IonPage>

                <StandartHeader name={t('device_settings')} />
        
                <IonContent className="ion-padding">
                    <IonText className="ion-text-center">
                        <h1>{t('device_name')}</h1>
                    </IonText>
        
                    <IonList>
                        <IonItem lines="full" className="form-list__item">
                            <IonLabel position="stacked" color="primary" className="form-label">{t('name')}</IonLabel>
                            <IonInput type="text" value={this.state.deviceName} className="form-input" onIonInput={e => this.setState({deviceName: e.detail.value})}/>
                        </IonItem>
                        <IonItem lines="full" className="form-list__item">
                            <IonRow style={{ width: '100%'}}>
                            <IonCol size="12" style={{ padding: '0' }}>
                                <IonLabel position="stacked" color="primary" className="form-label">{t('rated_power')}</IonLabel>
                            </IonCol>
                            <IonCol size="12" style={{ padding: '0' }}>
                                {/* <IonLabel hidden>{t('select_rated_power')}</IonLabel> */}
                                <IonSelect 
                                    placeholder={t('select_rated_power')} 
                                    value={String(this.state.devicePower)} 
                                    onIonChange={e => this.setState({devicePower: e.detail.value})}
                                    cancel-text={t('cancel')}
                                >
                                {modelPower.map((value, i) => (
                                    <IonSelectOption value={String(value)} key={i}>{value} {t('power_unit')}</IonSelectOption>
                                ))}
                                </IonSelect>
                            </IonCol>
                            </IonRow>
                        </IonItem>
                    </IonList>

                    <ToastMessage />
                </IonContent>
                
                <IonFooter className="app-footer ion-no-border">
                    <IonToolbar>
                    <div className="buttons-list">
                        <div className="buttons-list__item">
                        <IonButton expand="block" shape="round" size="large" onClick={this.continue}>{t('continue')}</IonButton>
                        </div>
                    </div>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        );
    }
};

const mapStateToProps = state => {
    return {
        newDevice: state.newDevice
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        setDeviceName,
        setDevicePower,
        deleteNewDevice,
        setAppMessage
    })
)(NameDevice);
  