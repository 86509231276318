import {
    IonContent,
    IonPage,
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import {
    readPushNofication
} from '../../redux/actions/AppAction';

import RestHelper from '../../helpers/RestHelper';
import StandartHeader from './components/header/StandartHeader';

class ViewNotification extends React.Component{


    render = () => {

        const { app: { push_notifications_messages }, t } = this.props;

        let notification = {};

        for (const i in push_notifications_messages) {
            const { message_id, id } = push_notifications_messages[i];
            
            if (id == this.props.match.params.message_id) {
                notification = push_notifications_messages[i]; 
            }
        }

        const [model, title_key] = notification.title.split(': ');
        const [modelBody, body_key] = notification.body.split(': ');
        
        return (
            <IonPage>
                <StandartHeader name={t('notifications')}/>
        
                <IonContent className="ion-padding">
        
                    <div className="notification ion-text">
                        <div className="ion-text-center">
                        <h1 className="notification__title">{model}: {t(title_key)}</h1>
                        <div className="notification__date">{notification.date}</div>
                        </div>
                        <div className="notification__content">{model}: {t(body_key)}</div>
                    </div>
        
                    <div className="ion-content-push"></div>
                </IonContent>
            </IonPage>
        );
    }
};

const mapStateToProps = state => {
    return {
        app: state.app
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        readPushNofication
    })
)(ViewNotification);  