import {
    IonContent,
    IonFooter,
    IonButton,
    IonPage,
    IonToolbar,
    IonText,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonCheckbox,
    IonRow
  } from '@ionic/react';
  
import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { Device } from '@capacitor/device';
import dayjs from 'dayjs';

import {
    chooseNetworkPass,
    setModelPower,
    setDeviceMacAddress,
    setDeviceModel,
    setDeviceApPassword
} from '../../../../redux/actions/NewDeviceAction';
import { setAppMessage, logger } from '../../../../redux/actions/AppAction';

import StandartHeader from '../../components/header/StandartHeader';
import ToastMessage from '../../components/toast/ToastMessage';
import Loading from '../../components/loading/Loading';

class WiFiPassword extends React.Component {

    state = {
        passwordShown: false,
        password: "",
        no_password: false,
        showLoading: false,
        appInfo: {}
    }

    componentDidMount = async () => {

        const info = await Device.getInfo();
        this.setState({
            appInfo: info
        })
    }

    //Connect to device network and send data
    continue = async () => {

        if (this.state.password !== "") {

            this.props.logger(dayjs().format("HH:mm:ss")+" STEP 2: Send Wifi and Pass to device \n\n");

            // this.setState({
            //     showLoading: true
            // })

            this.props.chooseNetworkPass(this.state.password, this.state.no_password);

            global.debug(this.props.newDevice.networkSSID, this.props.newDevice);
            const {ssid, channel, enc} = JSON.parse(this.props.newDevice.networkSSID);

            global.debug({
                '_': Date.now(),
                ssid,
                channel,
                enc,
                key: this.state.password,
            });

            this.props.history.push('/v2/device-settings');

            // HTTP.post("http://10.0.0.1/wifiset", {
            //     '_': Date.now(),
            //     essid,
            //     channel,
            //     enc,
            //     key: this.state.password,
            // }, {}).then( data => {

            //     this.setState({showLoading: false});
            //     this.props.history.push('/device-settings');
               
            // })
            // .catch(error => {
            //     this.setState({showLoading: false});
            //     this.props.setAppMessage('Грешка при изпращане. Моля, опитайте отново');
            // });

        } else {
            this.props.setAppMessage(this.props.t('required_wifi_password'));
        }

    }

    render = () => {

        const { t } = this.props;
        const {essid} = JSON.parse(this.props.newDevice.networkSSID);
        return (
            <IonPage>
            
                <StandartHeader name={t('wifi_password')}/>

                <IonContent className="ion-padding">
                    <IonText className="ion-text-center">
                        <h1>{t('enter_wifi_pass')}:</h1>
                        <IonRow className="ion-align-items-center ion-justify-content-center ion-nowrap" style={{ marginTop: '8px', fontSize: '16px', wordBreak: 'break-all' }}>
                            <svg className="size-24 fill-green" style={{ marginRight: '11px'}}><use xlinkHref="#icon-wifi"></use></svg>
                            <span>{essid}</span>
                        </IonRow>
                    </IonText>

                    <IonList>
                        <IonItem lines="full" className="form-list__item">
                            <IonLabel position="stacked" color="primary" className="form-label">{t('password')}</IonLabel>
                            <div className="form-password">
                                <IonInput 
                                    type={this.state.passwordShown ? "text" : "password"} 
                                    className="form-input" 
                                    onIonInput={e => this.setState({password: e.detail.value})}
                                    value={this.state.password}
                                />
                                <button type="button" ion-button="true" className="form-password__button" onClick={() => this.setState({passwordShown: this.state.passwordShown ? false : true})}>
                                <svg className="form-password__button-icon"><use xlinkHref={`#icon-eye-${this.state.passwordShown ? 'closed' : 'open'}`}></use></svg>
                                </button>
                            </div>
                        </IonItem>
                        {/* <IonItem lines="none" style={{ paddingTop: '10px'}}>
                            <IonLabel className="form-checkbox">
                                <IonCheckbox 
                                    color="primary" 
                                    className="form-checkbox__element" 
                                    onIonChange={e => this.setState({no_password: e.detail.checked})}
                                />
                                <div className="form-checkbox__text">{t('network_no_pass')}</div>
                            </IonLabel>
                        </IonItem> */}
                    </IonList>

                    <ToastMessage />
                    <Loading show={this.state.showLoading}/>
                </IonContent>
                
                <IonFooter className="app-footer ion-no-border">
                    <IonToolbar>
                        <div className="buttons-list">
                            <div className="buttons-list__item">
                                <IonButton expand="block" shape="round" size="large" onClick={this.continue}>{t('continue')}</IonButton>
                            </div>
                            {/* TO DO: Ask for these settings */}
                            {/* <div className="buttons-list__item">
                                <IonButton expand="block" shape="round" size="large" color="help">Advanced network settings</IonButton>
                            </div> */}
                        </div>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        );
    }
};

const mapStateToProps = state => {
    return {
        newDevice: state.newDevice
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        chooseNetworkPass,
        setDeviceModel,
        setDeviceMacAddress,
        setModelPower,
        setDeviceApPassword,
        setAppMessage,
        logger
    })
)(WiFiPassword);
  