import {
    IonContent,
    IonFooter,
    IonButton,
    IonPage,
    IonToolbar,
    IonText,
} from '@ionic/react';

import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { Device } from '@capacitor/device';
import utc from 'dayjs/plugin/utc';
import timezonePlugin from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';

import ToastMessage from './components/toast/ToastMessage';
import StandartHeader from './components/header/StandartHeader';
import RestHelper from '../../helpers/RestHelper';
import WifiHelper from '../../helpers/WifiHelper';
import { setAppMessage, logger } from '../../redux/actions/AppAction';
import InitialSettings from '../../init';
import Loading from './components/loading/Loading';

class EnablingWiFi extends React.Component{

    state = {
        appInfo: {},
    }

    componentDidMount = async () => {
        this.restHelper = new RestHelper();
        this.initialSettings = new InitialSettings();

        const info = await Device.getInfo();
        this.setState({
            appInfo: info,
            showLoading: false
        })
    }

    finishProcess = async () => {
        

        this.setState({showLoading: true});

        setTimeout(async () => {

            this.props.logger(dayjs().format("HH:mm:ss")+ " STEP 3: Finish the process for mac address: "+ this.props.newDevice.macAddress+"  \n\n");
            
            //Connect to previous network
            try {
                
                //TO DO: Ask for which network should connect the device after disconnect from device network
                // if (this.state.appInfo.platform === 'ios') {
                //     await WifiHelper.connectToNetworkIOS(this.props.newDevice.networkSSID, this.props.newDevice.networkPassword);
                // } else {
                //     await WifiHelper.connectToNetwork(this.props.newDevice.networkSSID, this.props.newDevice.networkPassword);
                // }


                const { data } = await this.restHelper.get('/check-device-registration', {
                    mac: this.props.newDevice.macAddress   
                })

                this.props.logger(`Device has registration: ${data.success}`);

                if (data.success === true) {

                    //TO DO: Handle when the app is offline and online
                    dayjs.extend(utc)
                    dayjs.extend(timezonePlugin)
                    const timeZone = dayjs.tz.guess();

                    this.restHelper.post('/add-new-user-device', {
                        wifiSSID: this.props.newDevice.networkSSID,
                        //wifiPass: this.props.newDevice.networkPassword,
                        wifiPass: '',
                        ap_pass: this.props.newDevice.apPassword,
                        deviceSSID: this.props.newDevice.deviceSSID,
                        mac: this.props.newDevice.macAddress,
                        model: this.props.newDevice.model,
                        name: this.props.newDevice.name,
                        watt: this.props.newDevice.power,
                        timeZone: timeZone
                    }). then(res => {
                        const {success, message} = res.data;

                        this.props.logger(
                            `Sending to cloud: 
                            wifiSSID: ${this.props.newDevice.networkSSID},
                            wifiPass: '',
                            ap_pass: ${this.props.newDevice.apPassword},
                            deviceSSID: ${this.props.newDevice.deviceSSID},
                            mac: ${this.props.newDevice.macAddress},
                            model: ${this.props.newDevice.model},
                            name: ${this.props.newDevice.name},
                            watt: ${this.props.newDevice.power},
                            timeZone: ${timeZone}
                            `
                        );
                        
                        this.setState({showLoading: false});
                        if (success === true) {

                            //TO DO: thinnk about this.INITILIZE ALL DATA 
                            this.initialSettings.init();

                            //TO DO: Show Alert
                            this.props.history.push('/dashboard');
                        }
                    }).catch (err => {
                        //TO DO: what to do here
                        global.debug("ERROR", err);
                        this.setState({showLoading: false});
                        this.props.setAppMessage(this.props.t('connection_problem'));
                    }) ;

                } else {

                    this.setState({showLoading: false});
                    this.props.history.push('/unsuccessful-regstriration');    
                }

                

            } catch (e) {
                this.props.setAppMessage(this.props.t('connection_problem'));
            }
        }, 10000)
       
    }

    render = () => {

        const { t } = this.props;

        return (
            <IonPage>

                <StandartHeader name={t('finish')} />
        
                <IonContent className="ion-padding">
                    <div className="vertical-center">
                        <IonText className="ion-text-center">
                            <h1>{t('device_successfully_added')}</h1>
                            {/* TO DO: Ask for text */}
                            {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text</p> */}
                        </IonText>
                    </div>
                    <ToastMessage duration={10000}/>
                    <Loading show={this.state.showLoading} message={t('waiting_for_cloud')}/>
                </IonContent>
                
                <IonFooter className="app-footer ion-no-border">
                    <IonToolbar>
                    <div className="buttons-list">
                        <div className="buttons-list__item">
                        <IonButton expand="block" shape="round" size="large" onClick={this.finishProcess}>{t('finish')}</IonButton>
                        </div>
                    </div>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
            );
    }

};

const mapStateToProps = state => {
    return {
        newDevice: state.newDevice
    }
}


export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        setAppMessage,
        logger
    })
)
(EnablingWiFi);
  