import { debug } from './helpers/AppHelper';

import React from 'react';
import ReactDOM from 'react-dom';
import PhoneApp from './PhoneApp';
import WebApp from './WebApp';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { SplashScreen } from '@capacitor/splash-screen';
import store from './redux/createStore';
import { Provider } from 'react-redux';
import { isPlatform } from '@ionic/react';

//React 18
//import { createRoot } from 'react-dom/client';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import './lang/langFactory';

//IMPORTANT: When ionic build comment unnecessary css

//import "./assets/css/mobileApp/app.scss";
import "./assets/css/webApp/app.scss";   
global.debug = debug;




//const { SplashScreen, Device } = Plugins;

//React 18
// const container = document.getElementById('root');
// const root = createRoot(container);

// root.render(
//   <Provider store={store}>

//     {/* {isPlatform('desktop') !== true ? 
//       <PhoneApp /> 
//     :
//       <WebApp />
//     } */}
//     <PhoneApp />
//   </Provider>
// );


ReactDOM.render(
    <Provider store={store}>
  
      {/* {isPlatform('desktop') !== true ? 
        <PhoneApp /> 
      :
        <WebApp />
      } */}
      <WebApp />
    </Provider>,
    document.getElementById('root')
  );

SplashScreen.hide();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
