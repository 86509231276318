import React from 'react';
import {
    IonButton,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import ChangeDeviceMode from './ChangeDeviceMode';
import { B15_Settings } from '../../../../config/B15';


const EcoVacationModeBody = ({disableDevice, device, mac}) => {

    const { t } = useTranslation();
    const {state: deviceState} = device;

    const [mode, setMode] = useState(null);

    return (
        <React.Fragment>
            <div className="device-mode">
                <div className="device-mode__start">
                    <svg className={`device-mode__icon fill-light-green`}>
                        <use xlinkHref={`#icon-${B15_Settings.modes_icons[deviceState.mode]}`}></use>
                    </svg>
                </div>
                {(deviceState.mode != B15_Settings.modes.vacation) &&
                    <div className="device-mode__end">
                        <div className={`range-circle range-circle--alt ${deviceState.heater_state === 'on' ? '' : 'range-circle--ready'} ${disableDevice ? 'disabled' : ''}`}> 
                            <div className="range-circle__inner">
                                <div>
                                    <svg className="range-circle__icon">
                                        <use xlinkHref="#icon-heating-alt"></use>
                                    </svg>
                                </div>
                                <div className="range-circle__temperature">
                                    {`${deviceState.current_temp}°C`}
                                </div>
                                <div className="range-circle__label">{t('ewh_currrent_temperature')}</div>
                                <div className="range-circle__status">
                                    {deviceState.stand_by == "no" ? deviceState.heater_state === 'on' ? t('ewh_heating') : t('ewh_ready') : t('ewh_inactive_status')}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>

            <div className="target-list"></div>

            <div className="range-buttons js-range-buttons">
                <div className="range-buttons__item">
                    <IonButton shape="round" size="large" onClick={() => setMode('deactivate_current_mode')}>{t('deactivate')}</IonButton>
                </div>

                {deviceState.mode == B15_Settings.modes.vacation && 
                    <div className="range-buttons__item">
                        <IonButton 
                            shape="round" 
                            size="large" 
                            color="help" 
                            routerLink={`/device/vacation/${device.model.toLowerCase()}/${mac}`}
                        >
                            {t('edit')
                        }</IonButton>
                    </div>
                }
            </div>

            <ChangeDeviceMode 
                device={device} 
                newMode={mode} 
                mac={mac}
                cancelChange={mode => setMode(mode)}
            />
            
        </React.Fragment>
    )
}

export default EcoVacationModeBody;