import {
    IonContent,
    IonPage,
    IonToolbar,
    IonList,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonRow,
    IonCol,
    IonFooter,
    IonButton,
    IonInput
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import StandartHeader from './components/header/StandartHeader';
import RestHelper from '../../helpers/RestHelper';
import DeviceSettingsHelper from '../../helpers/DeviceSettingsHelper';
import Timezones from '../../config/TimeZones.json';
import timezonePlugin from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/isoWeek';
import weekday from 'dayjs/plugin/weekday';
import en from 'dayjs/locale/en';
import es from 'dayjs/locale/es';
import dayjs from 'dayjs';
import B15MqttHelper from '../../helpers/mqqt/B15MqttHelper';

import {
    updateDeviceTime,
} from '../../redux/actions/DevicesAction';

class WorldClock extends React.Component {

    constructor(props){
        super(props);

        this.mac = null;
        this.deviceTime = null;
        this.state = {
            selectedTimeZone: '',
            date: '',
            time: '',
            day: 0,
            date_formated: ''
        }
    }

    componentDidMount = () => {
        this.DeviceSettingsHelper = new DeviceSettingsHelper(this.mac);
        this.restHelper = new RestHelper();
    }

    handleTimzoneChange = timezone => {

        this.setState({
            selectedTimeZone: timezone
        });

        const timeZoneObj = this.catchTimeZoneObj(timezone);
         
        this.setState({
            date: timeZoneObj.format("MMMM D, YYYY"),
            time: timeZoneObj.format("HH:mm"),
            day: timeZoneObj.weekday(),
            date_formated: timeZoneObj.format("DD.MM.YYYY"),
        });
    }

    catchTimeZoneObj = timezone => {

        dayjs.Ls.en.weekStart = 1;
        dayjs.extend(utc);
        dayjs.extend(timezonePlugin);
        dayjs.extend(weekday);
        dayjs.locale('en');

        const utcTime = dayjs.utc().format("YYYY-MM-DD HH:mm");
        const localTime = dayjs().format("YYYY-MM-DD HH:mm");

        const timeZoneObj = dayjs(localTime).tz(timezone);

        return timeZoneObj;
    }

    confirmChanges = () => {
        
        let day = this.state.day;
        let time = this.state.time;

        if (this.state.selectedTimeZone === '') {
            const { timezone } = this.props.devices[this.mac];   
            
            const timeZoneObj = this.catchTimeZoneObj(timezone);
            day = timeZoneObj.weekday();
            time = timeZoneObj.format("HH:mm");

        } else {
            //SEND THE NEW TIMEZONE TO CLOUD
            this.restHelper.post('/update-device-settings', {
                mac: this.mac,
                timezone: this.state.selectedTimeZone
            });
        }

        const B15MqttHelperOBJ = new B15MqttHelper(this.mac);

        const timeZoneObjDate = this.catchTimeZoneObj(this.state.selectedTimeZone === '' ? this.props.devices[this.mac].timezone : this.state.selectedTimeZone)

        this.props.updateDeviceTime(
            this.state.selectedTimeZone !== '' ? this.state.selectedTimeZone : this.props.devices[this.mac].timezone, 
            this.state.date !== '' ? this.state.date : this.deviceTime.date, 
            time, 
            this.mac
        );

        B15MqttHelperOBJ.request('timeResponse', {
            day: day,
            time: time,
            date: timeZoneObjDate.format("DD.MM.YYYY"),
            payload: {},
            code: 200,
            message: "OK",
            command: "time",
        }, "timeResponse");

        // this.DeviceSettingsHelper.handleTimezone({
        //     day: day,
        //     time: time,
        //     timezone: this.state.selectedTimeZone !== '' ? this.state.selectedTimeZone : this.props.devices[this.mac].timezone,
        //     date: this.state.date !== '' ? this.state.date : this.deviceTime.date,
        // })

        //this.props.history.push(`/device-settings/${this.mac}`);
        this.props.history.goBack();
    }

    renderTimezones = () => {
        const timezonesList = [];
        for (const zone in Timezones) {
            for (const timezone in Timezones[zone]) {
                timezonesList.push(<IonSelectOption value={timezone} key={`${zone}_${timezone}`}>{timezone}</IonSelectOption>)
            }
        }

        return timezonesList;
    }

    calculateDeviceTime = timezone => {
        const timeZoneObj = this.catchTimeZoneObj(timezone);
        return timeZoneObj.format("HH:mm");
    }

    calculateDeviceDate = timezone => {
        const timeZoneObj = this.catchTimeZoneObj(timezone);
        return timeZoneObj.format("MMMM D, YYYY");
    }

    render = () => {

        //TO DO: Add search in timezone select
        this.mac = this.props.match.params.mac_address;
        
        const { t } = this.props;
        const {devices} = this.props;

        const device = devices[this.mac];
        
        if (!device || device === undefined) {
            return null;
        }

        const { state: d_state } = device;
        try {
             this.deviceTime = JSON.parse(device.time);
        } catch (e) {
            global.debug("Cannot parse device time", e);
        }

        return (
            <IonPage>
    
            <StandartHeader name={t('world_clock')}/>

            <IonContent className="ion-padding">
                <IonList>
                <IonItem lines="full" className="form-list__item">
                    <IonRow style={{ width: '100%'}}>
                    <IonCol size="12" style={{ padding: '0' }}>
                        <IonLabel position="stacked" color="primary" className="form-label">{t('time_zone')}</IonLabel>
                    </IonCol>
                    <IonCol size="12" style={{ padding: '0' }}>
                        {/* <IonLabel hidden>{t('select_time_zone')}</IonLabel> */}
                        <IonSelect 
                            placeholder={String(this.state.selectedTimeZone !== '' ? this.state.selectedTimeZone : device.timezone)} 
                            value={String(this.state.selectedTimeZone !== '' ? this.state.selectedTimeZone : device.timezone)} 
                            onIonChange={e => this.handleTimzoneChange(e.detail.value)}
                            cancel-text={t('cancel')}
                        >
                        {this.renderTimezones()}
                        </IonSelect>
                    </IonCol>
                    </IonRow>
                </IonItem>
                <IonItem lines="full" className="form-list__item">
                    <IonLabel position="stacked" color="primary" className="form-label">{t('current_date')}</IonLabel>
                    <IonInput value={this.state.date !== '' ? this.state.date : this.calculateDeviceDate(device.timezone)} readonly/>
                </IonItem>
                <IonItem lines="full" className="form-list__item">
                    <IonLabel position="stacked" color="primary" className="form-label">{t('current_time')}</IonLabel>
                    <IonInput value={this.state.time !== '' ? this.state.time : this.calculateDeviceTime(device.timezone)} readonly/>
                </IonItem>
                </IonList>
            </IonContent>
    
            <IonFooter className="app-footer ion-no-border">
                <IonToolbar>
                    <div className="buttons-list">
                    <div className="buttons-list__item">
                        <IonButton expand="block" shape="round" size="large" onClick={this.confirmChanges}>{t('confirm')}</IonButton>
                    </div>
                    </div>
                </IonToolbar>
            </IonFooter>
            </IonPage>
        );
    }
};

const mapStateToProps = state => ({devices: state.devices})

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        updateDeviceTime
    })
)
(WorldClock);
  