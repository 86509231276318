import {
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonPage,
    IonFooter,
    IonButtons,
    IonButton,
    IonBackButton
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import StandartHeader from '../components/header/StandartHeader';

import RestHelper from '../../../helpers/RestHelper';
import E32MqttHelper from '../../../helpers/mqqt/E32MqttHelper';

const DeviceCalculator = props => {

    const { t } = useTranslation();
    const [totalKwh, setTotalKwh] = useState(0);

    const { 
        devices, 
        match: {
            params: {
                mac_address: mac
            }
        } 
    } = props;

    const device = devices[mac];
    const restHelperOBJ = new RestHelper();
    const E32MqttHelperOBJ = new E32MqttHelper(mac);

    useEffect(() => {

        getDivicePowerStat();
    
    }, [])

    const getDivicePowerStat = async () => {
        const {data} = await restHelperOBJ.get('/get-device-power-stat', {
            model: device.model_id,
            timezone: device.timezone,
            activity: "total",
            mac: mac     
        });

        setTotalKwh(data.total);
    }

    const reset = () => {
        E32MqttHelperOBJ.request('resetEnergyConsumption');

        getDivicePowerStat();
    }


    return (
        <IonPage>
            <StandartHeader name={t('calculator')}/>

            <IonContent className="ion-padding">
                <div className="ion-padding">
                    <div className={`range-circle`}>
                        <div className="range-circle__inner">
                            <div className="range-circle__metric">{t('kWh')}</div>
                            <div className="range-circle__value">{totalKwh}</div>
                            {device.state.energyCalcReset && 
                                <div className="range-circle__label"> {t('since')} {device.state.energyCalcReset}</div>
                            }

                            {/* ТО DO: How to extract the info */}
                            <div className="range-circle__option">{device.state.device_config.capacity}L</div>
                        </div>
                    </div>

                    <div className="range-buttons js-range-buttons">
                            <div className="range-buttons__item">
                            <IonButton shape="round" size="default" onClick={reset}>{t('reset')}</IonButton>
                        </div> 
                        <div className="range-buttons__item">
                            <IonButton shape="round" size="default" fill="outline" color="medium" routerLink={`/device/calculator-history/${device.model.toLowerCase()}/${mac}`}>{t('history')}</IonButton>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    connect(mapStateToProps, {})
)(DeviceCalculator);
