import {
    IonContent,
    IonFooter,
    IonButton,
    IonPage,
    IonToolbar,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonCheckbox
} from '@ionic/react';

import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';

import StandartHeader from '../../components/header/StandartHeader';
import ToastMessage from '../../components/toast/ToastMessage';
import Loading from '../../components/loading/Loading';
import WifiHelper from '../../../../helpers/WifiHelper';

import {
    setDeviceName,
    setDevicePower,
    deleteNewDevice
} from '../../../../redux/actions/NewDeviceAction';
import { setAppMessage, setAppProcessing } from '../../../../redux/actions/AppAction';
import { HTTP } from '@awesome-cordova-plugins/http';
import InitialSettings from '../../../../init';
import { Device } from '@capacitor/device';

class ProtectPassword extends React.Component {

    state = {
        showLoading: false,
        protectWifi: false,
        wifiPass: ''
    }

    continue = async () => {

        this.setState({showLoading: true});

        if (this.state.protectWifi) {

            global.debug( {
                '_': Date.now(),
                newenc: 'psk2',
                newkey: this.state.wifiPass,
            })

            try {
                await HTTP.post("http://10.0.0.1/apset", {
                    '_': Date.now(),
                    newenc: 'psk2',
                    newkey: this.state.wifiPass,
                }, {})
            } catch (e) {
                global.debug("APPSET ERROR", e);
            }
        } 

        const info = await Device.getInfo();

        if (info.platform === 'ios') {
            WifiHelper.disconnectFromNetworkIOS(this.props.newDevice.deviceSSID);
        } else {
            WifiHelper.disconnect(this.props.newDevice.deviceSSID);
        }

        setTimeout(()=>{
            
            this.props.setAppProcessing(false);
            const initialSettingsODJ = new InitialSettings();
            initialSettingsODJ.init();

            this.setState({showLoading: false});
            this.props.history.push('/dashboard');
            
        }, 10000)
    }


    render = () => {

        const { t } = this.props;
        
        return (
            <IonPage>

                <StandartHeader name={t('device_settings_tesycloud')} />
        
                <IonContent className="ion-padding">
        
                    <IonList>
                        <IonItem lines="full" className="form-list__item">
                            <IonLabel className="form-checkbox">
                                <IonCheckbox color="primary" className="form-checkbox__element" name="checkbox_1" checked={this.state.protectWifi} onIonChange={e => this.setState({protectWifi: e.detail.checked})}/>
                                <div className="form-checkbox__text">
                                    {t('tesyap_wifi_ecription')}
                                </div>
                            </IonLabel>
                        </IonItem>
                        <IonItem lines="full" className="form-list__item">
                            <IonLabel position="stacked" color="primary" className="form-label">{t('tesyap_encryption_key')}</IonLabel>
                            <IonInput disabled={this.state.protectWifi == true ? false : true} type="text" className="form-input" onIonInput={e => this.setState({wifiPass: e.detail.value})}/>
                        </IonItem>
                    </IonList>

                    <ToastMessage />
                    <Loading show={this.state.showLoading}/>
                </IonContent>
                
                <IonFooter className="app-footer ion-no-border">
                    <IonToolbar>
                    <div className="buttons-list">
                        <div className="buttons-list__item">
                            <IonButton expand="block" disabled={(this.state.protectWifi && this.state.wifiPass.length < 8) ? true : false} shape="round" size="large" onClick={this.continue}>{t('continue')}</IonButton>
                        </div>
                    </div>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        );
    }
};

const mapStateToProps = state => {
    return {
        newDevice: state.newDevice
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        setDeviceName,
        setDevicePower,
        deleteNewDevice,
        setAppMessage,
        setAppProcessing
    })
)(ProtectPassword);
  