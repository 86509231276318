import {
    IonContent,
    IonFooter,
    IonButton,
    IonPage,
    IonToolbar,
    IonText,
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';

import StandartHeader from '../../components/header/StandartHeader';
import Loading from '../../components/loading/Loading';
import UsersHelper from '../../../../helpers/UsersHelper';
import LocalStorageHelper from '../../../../helpers/LocalStorageHelper';
import ModecoC21_1 from '../../../../files/2000_1.svg';
import ModecoC21_2 from '../../../../files/2000_2.svg';
import ModecoC21_3 from '../../../../files/2000_3.svg';

class ModecoC21 extends React.Component {

    state = {
        showLoading: false
    }
    
    continue = async () => {

        const userHelperOBJ = new UsersHelper();

        if (await LocalStorageHelper.get('user.oldAppEmail') && await LocalStorageHelper.get('user.oldAppPassword')) {

            this.setState({showLoading: true});

            try {

                await userHelperOBJ.loginTesyCloud({email: await LocalStorageHelper.get('user.oldAppEmail'), password: await LocalStorageHelper.get('user.oldAppPassword')});

                this.setState({showLoading: false});
                this.props.history.push('/old/choose-device')
            } catch (e) {
                
                this.setState({showLoading: false});
                this.props.history.push('/old/old-app-account-settings')
            }

        } else {

            this.props.history.push('/old/old-app-account-settings')
        }
    }


    render = () => {
        const { t } = this.props;

        return (
            <IonPage>        
                
                <StandartHeader name={t('Boiler ModEco C21')}/>

                <IonContent className="ion-padding">
                    <div className="text">
                        <div style={{ margin: '16px 0 32px', textAlign: 'center' }}>
                            <img src={ModecoC21_1} />
                        </div>
                        <p>1. {t('modecoc21_text1')} <svg className="size-16 fill-green"><use xlinkHref="#icon-icons-16-px-16-001"></use></svg> {t('modecoc21_text2')}</p>
                        <p>2. {t('modecoc21_text3')}</p>
                        <div style={{ margin: '32px 0 16px', textAlign: 'center' }}>
                            <img src={ModecoC21_2} />
                        </div>
                        <p>3. {t('modecoc21_text4')}</p>
                        <div style={{ margin: '32px 0 16px', textAlign: 'center' }}>
                            <img src={ModecoC21_3} />
                        </div>
                        <p>4. {t('modecoc21_text5')}</p>
                        <p>&nbsp;</p>
                        <p><a href="">{t('modecoc21_text6')}</a></p>
                        <p>{t('modecoc21_text7')}</p>
                    </div>

                    <Loading show={this.state.showLoading}/>

                </IonContent>
                
                <IonFooter className="app-footer ion-no-border">
                    <IonToolbar>
                        <div className="buttons-list">
                            <div className="buttons-list__item">
                                <IonButton expand="block" shape="round" size="large" onClick={this.continue}>{t('continue')}</IonButton>
                            </div>
                        </div>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        );
    }
    
};

export default withTranslation()(ModecoC21);
  