import {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonInput,
    IonFooter,
    IonToolbar,
    IonButton,
    IonText,
    IonLabel
} from '@ionic/react';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import Header from '../components/header/Header';
import PageHeader from '../components/header/PageHeader';
import Footer from '../components/footer/Footer';
import Menu from './components/Menu';

class DeviceInfo extends React.Component {

    render = () => {
        
        const { t, devices, match: {params: { mac_address } } } = this.props;
        const device = devices[mac_address];

        return (
            <IonPage>
                <Header />
                <IonContent className="ion-no-padding">
                    <div className="dashboard">
                        <div className="dashboard__wrapper">

                            <Menu activeMenu="deviceInfo"  mac={mac_address}/>

                            <div className="dashboard__content">
                               
                                <PageHeader name={t('device_info')} image="info"/>

                                <div className="dashboard-box">

                                    <div className="dashboard-box__body">
                            
                                        <IonList>
                                            <IonItem lines="none" className="form-list__item">
                                                <IonLabel position="stacked" color="primary" className="form-label">{t('mac_address')}</IonLabel>
                                                <IonInput 
                                                    type="text" 
                                                    className="form-input" 
                                                    value={mac_address}
                                                    readonly
                                                />
                                            </IonItem>
                                            <IonItem lines="none" className="form-list__item">
                                                <IonLabel position="stacked" color="primary" className="form-label">{t('firmware_version')}</IonLabel>
                                                <IonInput 
                                                    type="text" 
                                                    className="form-input" 
                                                    value={device.state.fw_version}
                                                    readonly
                                                />
                                            </IonItem>
                                            <IonItem lines="none" className="form-list__item">
                                                <IonLabel position="stacked" color="primary" className="form-label">{t('time_zone')}</IonLabel>
                                                <IonInput 
                                                    type="text" 
                                                    className="form-input" 
                                                    value={device.timezone}
                                                    readonly
                                                />
                                            </IonItem>
                                        </IonList>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </IonContent>
                    
                <Footer />
                    
            </IonPage>
        )
    }
}

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(DeviceInfo);