import {
    IonContent,
    IonPage,
    useIonAlert,
    IonLabel,
    IonItem,
    IonSelect,
    IonSelectOption
} from '@ionic/react';

import {
    ResponsiveContainer,
    XAxis,
    YAxis,
    BarChart,
    Bar,
    Legend,
    Tooltip,
    Brush,
    CartesianGrid,
    LabelList
} from 'recharts';

import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'

import RestHelper from '../../../helpers/RestHelper';

import '../components/charts/statistics-chart.scss';
import Header from '../components/header/Header';
import Menu from './components/Menu';
import Footer from '../components/footer/Footer';
import PageHeader from '../components/header/PageHeader';



const CalculatorHistory = props => {
    
    const { t } = useTranslation();
    const [fullStat, setFullStat] = useState(null);
    const [activityData, setActivityData] = useState(null);
    const chartHeight = 310;
    const ColorConfig = {
        "red": "#EB445A",
        "green": "#7cae2e",
        "gray": "#3b3b3b",
        "gray2": "#92949c",
        "purple": "#9360DA"
    }

    const { 
        devices, 
        match: {
            params: {
                mac_address: mac
            }
        } 
    } = props;

    const device = devices[mac];
    const restHelperOBJ = new RestHelper();

    useEffect(async () => {

        const {data} = await restHelperOBJ.get('/get-device-power-stat', {
            model: device.model_id,
            timezone: device.timezone,
            activity: "full",
            mac: mac     
        });
        setFullStat(data);

        getActivityData("annual");
    
    }, [])

    const getActivityData = async (period) => {

        const {data} = await restHelperOBJ.get('/get-device-power-stat', {
            model: device.model_id,
            timezone: device.timezone,
            activity: period,
            mac: mac     
        });

        setActivityData(data);
    }

    const renderCustomizedLabel = (props) => {
        const { x, y, width, height, value } = props;
      
        return (
            <g transform={`translate(${x},${y})`}>
                <text 
                    x={-height + 10}
                    y={width / 2 + 5} 
                    fill="white"
                    transform="rotate(-90)"
                >
                    {value} kwH
                </text>
            </g>
        );
    };

    return (
        <IonPage>
            
            <Header />

            <IonContent className="ion-no-padding">
                <div className="dashboard">
                    <div className="dashboard__wrapper">

                        <Menu activeMenu="calculator" mac={props.match.params.mac_address}/>

                        <div className="dashboard__content">
                            
                            <PageHeader name={t('calculator_button')} image="calc"/>

                            <div className="dashboard-box">

                                <div className="dashboard-box__body">

                                    {fullStat && 
                    
                                        <div className="consumption">
                                            <div className="consumption__item">
                                                <div className="consumption__title">{t('KWh')}</div>
                                                <div className="consumption__value">{fullStat.daily}</div>
                                                <div className="consumption__title consumption__title--sm">{t('today')}</div>
                                            </div>
                                            <div className="consumption__item">
                                                <div className="consumption__title">{t('KWh')}</div>
                                                <div className="consumption__value">{fullStat.yesterday}</div>
                                                <div className="consumption__title consumption__title--sm">{t('yesterday')}</div>
                                            </div>
                                            <div className="consumption__item">
                                                <div className="consumption__title">{t('KWh')}</div>
                                                <div className="consumption__value">{fullStat.yearly}</div>
                                                <div className="consumption__title consumption__title--sm">{t('thisyear')}</div>
                                            </div>
                                        </div>
                                    
                                    }

                                    {activityData &&
                                        <div className="calculator-chart__wrapper">
                                            <ResponsiveContainer width={'100%'} height={chartHeight}>
                                                <BarChart 
                                                    data={activityData} 
                                                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                                                    barSize={11} barGap={1} 
                                                    className="hide-brush-texts"
                                                >
                                                
                                                    <XAxis
                                                        height={50}
                                                        dataKey="name"
                                                        interval={0}
                                                        angle={-45}
                                                        textAnchor="end"
                                                        fontSize={12}
                                                        dy={5}
                                                        dx={2}
                                                    />
                                                    
                                                    <Legend iconType={'square'} iconSize={11} />
                                                    <Bar dataKey="kwH" barSize={80} fill={ColorConfig['green']} background={{ fill: '#e6e6ec' }} textAnchor="bottom">
                                                        <LabelList
                                                            dy={-35}
                                                            dx={-4}
                                                            content={renderCustomizedLabel}
                                                        />
                                                    </Bar>
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </div>
                                        }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </IonContent>
            <Footer />
        </IonPage>
    );
        
}

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    connect(mapStateToProps, {})
)(CalculatorHistory);