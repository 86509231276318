export const settings = {
    host: 'mqtt.tesy.com',
    port: 8083,
    username: 'client1',
    password: '123'
}

export const development_settings = {
    host: 'staging.mqtt.tesy.com',
    port: 8083,
    username: 'client1',
    password: '123'
}

//New connection data
// u: system
// p: gUfnt5qDje4DkweWE