import {
    IonContent,
    IonFooter,
    IonButton,
    IonPage,
    IonToolbar,
    IonList,
    IonItem,
    IonRadioGroup,
    IonLabel,
    IonRadio,
  } from '@ionic/react';
  
import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';

import {
    chooseNetwork
} from '../../../../redux/actions/NewDeviceAction';
import { setAppMessage } from '../../../../redux/actions/AppAction';

import StandartHeader from '../../components/header/StandartHeader';
import ToastMessage from '../../components/toast/ToastMessage';
import { HTTP } from '@awesome-cordova-plugins/http';
import Loading from '../../components/loading/Loading';

class ChangeWiFi extends React.Component {
    
    state = {
        networks: [],
        myNetwork: null,
        showLoading: false,
        showContinueButton: false
    }

    componentDidMount = () => {
        this.scanForNetworks();

        this.setState({
            myNetwork: this.props.newDevice.networkSSID
        })
    }

    scanForNetworks = async () => {

        this.setState({showLoading: true});
        this.setState({showContinueButton: false});

        HTTP.post("http://192.168.82.1/wscan", {}, {}).then(({data}) => {

            const networks = JSON.parse(data);
            global.debug(JSON.parse(data), data);

            this.setState({networks})
            this.setState({showLoading: false});
            this.setState({showContinueButton: true});
        })
        .catch(error => {

            this.props.setAppMessage(this.props.t('phone_interruption_problem'));
            this.setState({showLoading: false});
            this.props.history.push('/old/choose-device');
        });

    }

    confirm = () => {
        if (this.state.myNetwork !== '') {

            global.debug(this.state.networks, this.state.myNetwork, this.state.networks[this.state.myNetwork]);
            this.props.chooseNetwork(this.state.networks[this.state.myNetwork]);
            this.props.history.push("/v2/wifi-password");

        } else {
            this.props.setAppMessage(this.props.t('required_network'));
        }
        
    }

    renderWifiNetworks = () => {

        const wifiList = [];

        global.debug('Networks state', this.state.networks);
        
        for (const i in this.state.networks) {
            wifiList.push(<IonItem key={i}>
                                <IonLabel>{this.state.networks[i].ssid}</IonLabel>
                                <IonRadio slot="start" color="secondary" value={i} />
                        </IonItem>) 
        }

        return wifiList;
    }

    render = () => {

        const { t } = this.props;

        return (
            <IonPage>
            
            <StandartHeader name={t('change_wifi')}/>
            <IonContent className="ion-padding">
                <IonList>
                    <IonRadioGroup 
                        onIonChange={e => this.setState({myNetwork: e.detail.value})} 
                        value={this.state.myNetwork}
                    >
                        {this.renderWifiNetworks()}
                    </IonRadioGroup>
                </IonList>

                <ToastMessage />
                <Loading show={this.state.showLoading}/>
            </IonContent>
            
            <IonFooter className="app-footer ion-no-border">
                <IonToolbar>
                    <div className="buttons-list">
                        {this.state.showContinueButton &&
                            <div className="buttons-list__item">
                                <IonButton expand="block" shape="round" size="large" onClick={this.confirm}>{t('confirm')}</IonButton>
                            </div>
                        }
                        <div className="buttons-list__item">
                            <IonButton expand="block" shape="round" size="large" color="help" onClick={this.scanForNetworks}>{t('scan_for_wifi_networks')}</IonButton>
                        </div>
                    </div>
                </IonToolbar>
            </IonFooter>
            </IonPage>
        );
    }
};

const mapStateToProps = state => {
    return {
        newDevice: state.newDevice
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        chooseNetwork,
        setAppMessage
    })
)
(ChangeWiFi);
  