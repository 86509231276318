import {
    IonPage,
    IonContent,
    IonList,
    IonItem,
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import StandartHeader from '../components/header/StandartHeader';
import TwoButtons from '../components/footer/TwoButtons';
import RangeTime from '../components/range/RangeTime';
import OldDevicesSettingsHelper from '../../../helpers/OldDevicesSettingsHelper';
import {oldModels} from '../../../config/App';

class SleepMode extends React.Component {

    constructor(props) {

        super(props);

        this.mac = null;
        this.device = null;
        this.OldDevicesSettingsHelper = null

        this.state = {
            time: 1800,
        }
    
    }

    componentDidMount = () => {
        this.OldDevicesSettingsHelper = new OldDevicesSettingsHelper(this.mac);  
    }

    setTime = time => {
        let [hour, min] = time.split(":");
        hour = Number(hour);
        min = Number(min);

        this.setState({
            time: hour*60 + min
        })
    }

    save = () => {

        this.OldDevicesSettingsHelper.setDeviceStatus('dtime', this.state.time); 
        this.props.history.goBack();
    }

    render = () => {

        const { t } = this.props;
        this.mac = this.props.match.params.id;

        const {devices} = this.props;

        this.device = devices[this.mac];
        this.model = oldModels[this.props.match.params.model_name];
        
        if (!this.device || this.device === undefined) {
            return null;
        }

        const { DeviceStatus: {dtime} } = this.device;

        return (
            <IonPage>
                
                <StandartHeader name={t('sleep')}/>
                <IonContent className="ion-padding"> 

                    <div className="ion-text-center ">
                        <svg className="size-80 mx-auto fill-light-blue mt-20">
                            <use xlinkHref="#icon-moon"></use>
                        </svg>
                    </div>
                    
                    <div className="preferences-list__wrapper">
                        <IonList className="preferences-list preferences-list--editable">
                            <IonItem className="preferences-list__item" lines="none">
                                <div className="preferences-list__inner">
                                <div className="preferences-list__item-middle">
                                    <RangeTime 
                                        data-min={this.model.min_sleep_time} 
                                        data-max={this.model.max_sleep_time} 
                                        data-start={(dtime > 0) ? dtime * 60 : this.model.min_sleep_time} 
                                        data-step="1800" 
                                        data-label={t('stop_after')} 
                                        setTime={time => this.setTime(time)}
                                    />
                                </div>
                                </div>
                            </IonItem>
                        </IonList>
                    </div>
                    
                    <div className="popover__text">
                        <p>{t('sleep_mode_text_1')}</p>
                        <p>{t('sleep_mode_text_2')}</p>
                    </div>
                    <div className="ion-content-push"></div>
                </IonContent>
                <TwoButtons 
                    redirect={`/old/device-settings/${this.props.match.params.model_name}/${this.mac}`}
                    save={() => this.save()}
                />
                
            </IonPage>
        );         
    }
    
};

const mapStateToProps = state => {
    return {
        devices: state.oldDevices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(SleepMode);