import {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonInput,
    IonFooter,
    IonToolbar,
    IonButton,
    IonText,
    IonLabel
} from '@ionic/react';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import StandartHeader from './components/header/StandartHeader';
import { setAppMessage } from '../../redux/actions/AppAction';
import ToastMessage from './components/toast/ToastMessage';
import RestHelper from '../../helpers/RestHelper';
import InitialSettings from '../../init';

class NameDevice extends React.Component {

    state = {
        deviceName: ''
    }

    componentDidMount = async () => {
        this.restHelper = new RestHelper();
        this.initialSettings = new InitialSettings();

        const { deviceName } = this.props.devices[this.mac];
        this.state.deviceName = deviceName;
    }

    confirmAction = async () => {
        if (this.state.deviceName !== '') {

            if (this.state.deviceName.length < 30) {
                try {
                    await this.restHelper.post('/update-device-settings', {
                        mac: this.mac,
                        deviceName: this.state.deviceName
                    });

                    this.initialSettings.init();
                    //this.props.history.replace("/dashboard");
                    this.props.history.goBack();
                } catch (e) {
                    global.debug("Can't update device settings", e);
                } 
            } else {
                this.props.setAppMessage(this.props.t('device_name_max_size'));
            } 

        } else {
            this.props.setAppMessage(this.props.t('required_device_name'));
        }
    }

    render = () => {

        const { t } = this.props;
        this.mac = this.props.match.params.mac_address;
        
        const { deviceName } = this.props.devices[this.mac];

        return (
            <IonPage>
                <StandartHeader name={t('rename_device')}/>
    
                <IonContent className="ion-padding">
                    <IonText className="ion-text-center">
                        <h1>{t('device_name')}</h1>
                    </IonText>
        
                    <IonList>
                        <IonItem lines="full" className="form-list__item">
                            <IonLabel position="stacked" color="primary" className="form-label">{t('name')}</IonLabel>
                            <IonInput 
                                type="text" 
                                className="form-input" 
                                onIonInput={e => this.setState({deviceName: e.detail.value})} 
                                value={this.state.deviceName}
                            />
                        </IonItem>
                    </IonList>
    
                    <ToastMessage />
                </IonContent>
                
                <IonFooter className="app-footer ion-no-border">
                    <IonToolbar>
                        <div className="buttons-list">
                        <div className="buttons-list__item">
                            <IonButton expand="block" shape="round" size="large" onClick={this.confirmAction}>{t('confirm')}</IonButton>
                        </div>
                        </div>
                    </IonToolbar>
                </IonFooter>
                    
            </IonPage>
        )
    }
}

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        setAppMessage
    })
)(NameDevice);