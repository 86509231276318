import {B15_Settings} from '../config/B15';

export default class B15Helper
{

    constructor(device) {
        this.device = device;
    }

    getMainMode = () => {
        const currentMode = this.device.state.mode;
        const {modes} = B15_Settings;

        if ([modes.p1, modes.p2, modes.p3].includes(currentMode)) {
            return currentMode;
        }

        return modes.manual;
    }


    getShowers = () => {
        const showers = {
            30: {
                H: 3,
                V: 4
            },
            50: {
                H: 2,
                V: 2
            },
            80: {
                H: 3,
                V: 4
            },
            100: {
                H: 3,
                V: 4
            },
        }; 

        const {device_config: {capacity, mount}} = this.device.state;

        return showers[capacity][mount];
    }

    renderShowers = () => {

        let showers = [];

        for (let i = 1; i <= this.device.state.heat_level; i++) {
            showers.push(<svg className="shower" key={`shower_${i}`}><use xlinkHref="#icon-shower"></use></svg>);
        }

        return showers;
    }

    renderShowersProgress = (inner = false) => {

        const { state } = this.device;
        const maxShowers = this.getShowers();
        let showers = [];
        const initialClass = inner ? 'range-shower__item' : 'shower';

        for (let i = 1; i <= maxShowers; i++) {

            let progressClassName = inner ? "" : "shower--grey";

            if (state.stand_by == "no") {
        
                if (state.current_heat_level >= i) {
                    progressClassName = 'range-shower__item--green';
                } else if (state.heat_level >= i && state.heater_state === 'on') {
                    progressClassName = inner ? 'range-shower__item--red' : 'shower--red'
                }
            }

            showers.push(<svg className={`${initialClass} ${progressClassName}`} key={`progress_shower_${i}`}><use xlinkHref="#icon-shower"></use></svg>);
        }

        return showers;
    }

    renderSLiderDigits = () => {

        const maxShowers = this.getShowers();
        let digits = [];

        for (let i = 0; i <= maxShowers; i++) {

            digits.push(<div className={`range-circle__didgit range-circle__didgit--${i}`} key={`slider-digits-${i}`}>{i}</div>);
        }

        return digits;
    }

    getSliderAngles = () => {
        const angles = [
            { 'temp': 0, 'angle': -49 },
            { 'temp': 1, 'angle': -45.2 },
            { 'temp': 2, 'angle': -41.4 },
            { 'temp': 3, 'angle': -37.6 },
            { 'temp': 4, 'angle': -33.8 },
            { 'temp': 5, 'angle': -30 },
            { 'temp': 6, 'angle': -26.2 },
            { 'temp': 7, 'angle': -22.4 },
            { 'temp': 8, 'angle': -18.6 },
            { 'temp': 9, 'angle': -14.8 },
            { 'temp': 10, 'angle': -11 },
            { 'temp': 11, 'angle': -7.2 },
            { 'temp': 12, 'angle': -3.4 },
            { 'temp': 13, 'angle': 0.41 },
            { 'temp': 14, 'angle': 3.39 },
            { 'temp': 15, 'angle': 8 },
            { 'temp': 16, 'angle': 12.06 },
            { 'temp': 17, 'angle': 16.12 },
            { 'temp': 18, 'angle': 20.18 },
            { 'temp': 19, 'angle': 24.24 },
            { 'temp': 20, 'angle': 28.3 },
            { 'temp': 21, 'angle': 32.36 },
            { 'temp': 22, 'angle': 36.42 },
            { 'temp': 23, 'angle': 40.48 },
            { 'temp': 24, 'angle': 44.54 },
            { 'temp': 25, 'angle':  48.6},
            { 'temp': 26, 'angle': 52.66 },
            { 'temp': 27, 'angle': 56.72 },
            { 'temp': 28, 'angle': 60.75 },
            { 'temp': 29, 'angle': 64.84 },
            { 'temp': 30, 'angle': 69 },
            { 'temp': 31, 'angle': 73.2 },
            { 'temp': 32, 'angle': 77.4 },
            { 'temp': 33, 'angle': 81.6 },
            { 'temp': 34, 'angle': 85.8 },
            { 'temp': 35, 'angle': 90 },
            { 'temp': 36, 'angle': 94.2 },
            { 'temp': 37, 'angle': 98.4 },
            { 'temp': 38, 'angle': 102.6 },
            { 'temp': 39, 'angle': 106.8 },
            { 'temp': 40, 'angle': 111 },
            { 'temp': 41, 'angle': 115.2 },
            { 'temp': 42, 'angle': 119.4 },
            { 'temp': 43, 'angle': 123.6 },
            { 'temp': 44, 'angle': 127.8 },
            { 'temp': 45, 'angle': 132 },        
            { 'temp': 46, 'angle': 127.56 },
            { 'temp': 47, 'angle': 131.22 },
            { 'temp': 48, 'angle': 134.88 },
            { 'temp': 49, 'angle': 138.54 },
            { 'temp': 50, 'angle': 142.2 },
            { 'temp': 51, 'angle': 145.86 },
            { 'temp': 52, 'angle': 149.52 },
            { 'temp': 53, 'angle': 153.18 },
            { 'temp': 54, 'angle': 156.84 },
            { 'temp': 55, 'angle': 160.5 },
            { 'temp': 56, 'angle': 164.16 },
            { 'temp': 57, 'angle': 167.82 },
            { 'temp': 58, 'angle': 171.48 },
            { 'temp': 59, 'angle': 175.14 },
            { 'temp': 60, 'angle': 179 },
            { 'temp': 61, 'angle': 182.4 },
            { 'temp': 62, 'angle': 185.8 },
            { 'temp': 63, 'angle': 189.2 },
            { 'temp': 64, 'angle': 192.6 },
            { 'temp': 65, 'angle': 196 },
            { 'temp': 66, 'angle': 199.4 },
            { 'temp': 67, 'angle': 202.8 },
            { 'temp': 68, 'angle': 206.2 },
            { 'temp': 69, 'angle': 209.6 },
            { 'temp': 70, 'angle': 213 },
            { 'temp': 71, 'angle': 216.4 },
            { 'temp': 72, 'angle': 219.8 },
            { 'temp': 73, 'angle': 223.2 },
            { 'temp': 74, 'angle': 226.6 },
            { 'temp': 75, 'angle': 230 }
        ]

        return angles;
    }

}