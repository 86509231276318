import {
    IonPage,
    IonContent,
    IonRow,
    IonCol,
    IonToggle,
    IonButton,
    IonLabel,
    IonCheckbox,
    IonModal,
    IonToolbar,
    IonText,
    IonList,
    IonItem,
    IonAlert
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'
import dayjs from 'dayjs';

import Header from './components/header/Header';
import PageHeader from './components/header/PageHeader';
import PageSubHeader from './components/header/PageSubHeader';
import Footer from './components/footer/Footer';
import Loading from './components/loading/Loading';
import DeviceCN05UVMenu from './components/menu/DeviceCN05UVMenu';
import DeviceSettingsHelper from '../../helpers/DeviceSettingsHelper';

class DeviceCN05UVProgram extends React.Component {

    state = {
        showDeleteAlert: false,
        showModalSecond: false,
        showPopupModal: false,
        showLoading: false,
        weekData: {
            0: {
                'day': this.props.t('Monday'),
                'isSelected': true
            },
            1: {
                'day': this.props.t('Tuesday'),
                'isSelected': false
            },
            2: {
                'day': this.props.t('Wednesday'),
                'isSelected': false
            },
            3: {
                'day': this.props.t('Thursday'),
                'isSelected': false
            },
            4: {
                'day': this.props.t('Friday'),
                'isSelected': false
            },
            5: {
                'day': this.props.t('saturday'),
                'isSelected': false
            },
            6: {
                'day': this.props.t('Sunday'),
                'isSelected': false
            }
        },
        weekDays: {
            0: {
              'name': this.props.t('monday_full'),
              'checked': true
            },
            1: {
              'name': this.props.t('tuesday_full'),
              'checked': true
            },
            2: {
              'name': this.props.t('wednesday_full'),
              'checked': true
            },
            3: {
              'name': this.props.t('thursday_full'),
              'checked': true
            },
            4: {
              'name': this.props.t('friday_full'),
              'checked': true
            },
            5: {
              'name': this.props.t('saturday_full'),
              'checked': true
            },
            6: {
              'name': this.props.t('sunday_full'),
              'checked': true
            }
        },
        selectedDay: 0
    }

    componentDidMount = () => {
        this.DeviceSettingsHelper = new DeviceSettingsHelper(this.mac)

        //Set selected day to be current or choosen
        let currentDay = dayjs().day();
        const daysOfWeek = [6, 0, 1, 2, 3, 4, 5];
        let weekData = this.state.weekData;
        const selectedDay = this.props.match.params.day != undefined ? this.props.match.params.day : daysOfWeek[currentDay];
        for (const dayIndex in weekData) {
            
            if (dayIndex == selectedDay) {
                weekData[dayIndex].isSelected = true;  
            } else {
                weekData[dayIndex].isSelected = false;  
            }
        }

        this.setState({
            selectedDay,
            weekData
        })
    }

    renderWeekday = () => {

        const days = [];

        for (const dayIndex in this.state.weekData) {
            
            const {isSelected, day} = this.state.weekData[dayIndex];

            days.push(
                <th key={`w_day_${dayIndex}`}>
                    <IonButton 
                        color="transparent" 
                        className={`week-table__button ${isSelected ? 'active' : ''}`}
                        onClick={ () => this.handleDayChange(dayIndex) }
                    >
                        {day}
                    </IonButton>
                </th>
            );
        }

        return days;
    }

    handleDayChange = selectedDay => {

        const week = this.state.weekData
        for (const dayIndex in week) {

            if (dayIndex === selectedDay) {
                week[dayIndex].isSelected = true;
            } else {
                week[dayIndex].isSelected = false;
            }
        }
        
        this.setState({
            weekData: week,
            selectedDay: selectedDay   
        })
    }

    renderProgram = (program) => {
        
        const programList = [];

        for (const key in program) {
            const {from, to, day, temp } = program[key];

            if (day === Number(this.state.selectedDay)) {
                programList.push(
                    <IonItem className="preferences-list__item" lines="none" key={key}>
                        <div className="preferences-list__inner">
                            <div className="preferences-list__item-start">
                                <div className="preferences-list__time">
                                    <svg className="preferences-list__time-icon"><use xlinkHref="#icon-heating"></use></svg>
                                    {from}{this.props.t('hour_abbr')} - {to}{this.props.t('hour_abbr')}
                                </div>
                            </div>
                            <div className="preferences-list__item-middle">
                                <div className="preferences-list__temperature">
                                    <svg className="preferences-list__temperature-icon"><use xlinkHref="#icon-idle"></use></svg>
                                    {temp}°C
                                </div>
                            </div>
                            <div className="preferences-list__item-end">
                                {/* TO DO: add confirm text when delete button is clicked */}
                                <IonButton color="icon-action" onClick={() => this.DeviceSettingsHelper.handleDeleteProgram(key)}>
                                    <svg className="icon"><use xlinkHref="#icon-delete" className="fill-red"></use></svg>
                                </IonButton>
                            </div>
                        </div>
                    </IonItem>
                );
            }
        }

        return programList;
    }

    renderWeek = () => {

        const days = [];

        for (const day in this.state.weekDays) {

            let {checked, name} = this.state.weekDays[day];

            if (this.state.selectedDay == day) {
                checked = false;
            }

            days.push(
                <IonItem lines="full" key={day}>
                    <IonLabel className="form-checkbox">
                        <IonCheckbox color="secondary" className="form-checkbox__element" name="checkboxName" checked={checked} onIonChange={e => this.checkDay(day, e.detail.checked)}/>
                        <div className="form-checkbox__text">{name}</div>
                    </IonLabel>
                </IonItem>
            );
        }

        return days;
    }

    checkDay = (selectedDay, status) => {
        this.setState({weekDays: {...this.state.weekDays, [selectedDay]:{...this.state.weekDays[selectedDay], checked: status}}});
    }

    //First Delete old program for all checked days
    //Copy the program from selected day to all Checked days from Modal
    copyProgram = async () => {

        this.setState({
            showLoading: true
        })

        const { state: {program} } = this.props.devices[this.mac];

        //Delete old program
        for (const wDay in this.state.weekDays) {

            const { checked } = this.state.weekDays[wDay];

            if (checked === true && this.state.selectedDay != wDay) {
                
                for (const key in program) {
                    const { day } = program[key];   

                    if (Number(day) === Number(wDay)){
                        
                        this.DeviceSettingsHelper.handleDeleteProgram(key);   
                        await this.timer(1000);
                    }
                }       
            }
        }

        //Copy program
        for (const key in program) {
            const {from, to, day, temp } = program[key];

            if (day === Number(this.state.selectedDay)) {

                for (const wDay in this.state.weekDays) {
                    const { checked } = this.state.weekDays[wDay];

                    if (checked === true) {

                
                        this.DeviceSettingsHelper.handleAddDeviceProgram({
                            day: Number(wDay),
                            from: from,
                            to: to,
                            temp: temp
                        });
                        
                        await this.timer(1000);
                    }
                }
            }
        }

        this.setState({
            showModalSecond: false,
            showLoading: false
        })
    }

    timer = ms => new Promise(res => setTimeout(res, ms))


    render = () => {

        this.mac = this.props.match.params.mac_address;

        const { t } = this.props;
        const {devices} = this.props;

        const device = devices[this.mac];
        
        if (!device || device === undefined) {
            return null;
        }   

        const { state : d_state } = device;
        const { program } = d_state;

        return (
            <IonPage>
                <Header />
                <IonContent className="ion-no-padding">
                    <div className="dashboard">
                        <div className="dashboard__wrapper">

                            <DeviceCN05UVMenu activeMenu="controlPanel" mac={this.props.match.params.mac_address}/>

                            <div className="dashboard__content">

                                <PageHeader name={t('control_panel')}/>

                                <div className="dashboard-box">
                                    
                                    <PageSubHeader title={t('edit_program')}/>
                                    
                                    <div className="dashboard-box__body">
                                        <IonRow className="ion-align-items-center ion-justify-content-end">
                                            <IonCol size="auto py-0">
                                                <IonButton color="transparent" onClick={() => this.setState({showPopupModal: true})}>
                                                    {t('adaptive_start')}
                                                    <svg className="size-24 fill-green ml-5"><use xlinkHref="#icon-info"></use></svg>
                                                </IonButton>
                                                <IonModal isOpen={this.state.showPopupModal}>
                                                    <IonToolbar style={{ paddingRight: '10px' }}>
                                                        <div slot="end">
                                                            <IonButton color="icon-only" onClick={() => this.setState({showPopupModal: false})}>
                                                                <svg className="size-14"><use xlinkHref="#icon-close"></use></svg>
                                                            </IonButton>
                                                        </div>
                                                    </IonToolbar>
                                                    <div className="ion-padding">
                                                        {/* TO DO: Ask for text */}
                                                        <IonText>
                                                            <h2 className="ion-text-center">Adaptive start</h2>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dui arcu, dictum laoreet justo quis, sollicitudin hendrerit sapien. Nam velit sem, iaculis sit amet sem a, malesuada accumsan mauris. Nunc elementum elit ac velit fringilla, a dictum magna finibus. Maecenas aliquet vel mauris vitae dictum. Proin nec facilisis lectus, eget malesuada lorem. Nunc ornare, arcu a rhoncus pellentesque, massa diam posuere libero, quis venenatis nunc est quis diam. Etiam sodales placerat velit, lacinia convallis turpis faucibus non. Donec id ipsum dui. Sed ut mauris ipsum. Donec imperdiet lectus turpis, vitae fermentum turpis tristique consequat. Cras at posuere libero. Donec volutpat mauris et turpis ornare mollis. Vestibulum quis tellus eu nulla iaculis ullamcorper eget eget libero. Vivamus id erat vitae enim viverra accumsan.</p>
                                                            <p>In eu condimentum neque. Mauris in ligula justo. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Suspendisse gravida rutrum metus, ac ornare ex mollis eu. Sed in porttitor diam. Vivamus condimentum rhoncus ultrices. Maecenas sodales enim ex, vel convallis sapien congue et. Aenean in vestibulum diam, eu dictum magna. Vivamus magna mi, feugiat ac dolor id, cursus placerat sapien. Mauris quis nibh ullamcorper, mollis tellus ut, feugiat nulla. Vestibulum a nibh at felis euismod rutrum non bibendum quam. Ut pharetra dolor nec faucibus venenatis. Integer posuere, ipsum vel varius consequat, sapien velit ullamcorper erat, varius ultrices velit eros at nibh. Fusce eget lorem quam. Aenean ac nibh a nisl aliquam tristique et nec metus. </p>
                                                        </IonText>
                                                    </div>
                                                </IonModal>
                                            </IonCol>
                                            <IonCol size="auto py-0">
                                                <IonToggle 
                                                    checked={d_state.adaptiveStart === 'on' ? true : false} 
                                                    onClick={e => this.DeviceSettingsHelper.handleAdaptiveStart(d_state.adaptiveStart === 'on' ? 'off' : 'on')} 
                                                />
                                            </IonCol>
                                        </IonRow>
                                        
                                        <div className="gray-box">
                                            <div className="gray-box__header">
                                                <IonRow className="ion-align-items-center ion-justify-content-between">
                                                    <IonCol size="auto" className="pl-0 py-0">
                                                        <table className="week-table">
                                                            <thead>
                                                                <tr>
                                                                    {this.renderWeekday()}
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                    </IonCol>
                                                    <IonCol size="auto" className="pr-0 py-0">
                                                        <IonButton color="circle" className="ion-no-margin" routerLink={`/device/program/add-program/${device.model.toLowerCase()}/${this.mac}/${this.state.selectedDay}`}>
                                                            <span className="sr">{t('add')}</span>
                                                            <svg className="icon"><use xlinkHref="#icon-plus"></use></svg>
                                                        </IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </div>
                                            <div className="gray-box__body gray-box__body--transparent">
                                                <IonRow className="ion-justify-content-between">
                                                    <IonCol size="auto" className="ion-text-start p-0">
                                                        <IonButton color="confirm" onClick={() => this.setState({showModalSecond: true})}>{t('save_porgram_to_all_days')}</IonButton>
                                                        <IonModal isOpen={this.state.showModalSecond}>
                                                            <IonToolbar style={{ paddingRight: '10px' }}>
                                                                <div slot="end">
                                                                    <IonButton color="icon-only" onClick={() => this.setState({showModalSecond: false})}>
                                                                        <svg className="size-14"><use xlinkHref="#icon-close"></use></svg>
                                                                    </IonButton>
                                                                </div>
                                                            </IonToolbar>
                                                            <div className="ion-padding">
                                                                <IonText>
                                                                    <h2 className="ion-text-center">{t('choose_days_to_copy_program')}</h2>
    
                                                                    <IonList className="ion-padding-top">
                                                                        {this.renderWeek()}
                                                                    </IonList>
                                                                </IonText>
                                                            </div>
                                                            <div className="ion-padding ion-text-center">
                                                                <IonButton onClick={this.copyProgram}>{t('save')}</IonButton>
                                                            </div>
                                                        </IonModal>
                                                    </IonCol>
                                                    <IonCol size="auto" className="ion-text-end p-0">
                                                        <IonButton color="delete" onClick={() => this.setState({showDeleteAlert: true})}>{t('delete_program')}</IonButton>
                                                    </IonCol>
                                                </IonRow>
    
                                                <div className="preferences-list__wrapper">
                                                    <IonList className="preferences-list">
                                                        {this.renderProgram(program)}
                                                    </IonList>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Loading show={this.state.showLoading}/>

                    <IonAlert
                        isOpen={this.state.showDeleteAlert}
                        onDidDismiss={() => this.setState({showDeleteAlert: false})}
                        cssClass='my-custom-class'
                        header={this.props.t('confirm')}
                        message={this.props.t('confirm_programs_delete')}
                        buttons={[
                            {
                                text: this.props.t('cancel'),
                                role: 'cancel',
                                cssClass: 'secondary',
                                handler: blah => {
                                    this.setState({showDeleteAlert: false})
                                }
                            },
                            {
                                text: this.props.t('ok'),
                                handler: () => {
                                    this.DeviceSettingsHelper.handleDeleteAllDeviceprograms(this.mac)
                                }
                            }
                        ]}
                    />
                </IonContent>
                <Footer />
            </IonPage>
        );
    }
    
};

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(DeviceCN05UVProgram);