import {
    LOGIN_USER,
    OLD_APP_LOGIN
} from '../types/UserTypes';

const userStructure = {
    isLogged: true,
    oldAppLogin: {}
}

const UserReducer = (user = userStructure, action) => {
    
    switch(action.type) {

        case LOGIN_USER:
            user.isLogged = action.payload.isLogged;
            return {...user}
        
        case OLD_APP_LOGIN:
            //user.oldAppLogin = {...action.payload.oldAppLogin};
            return {...user, oldAppLogin: {...action.payload.oldAppLogin}}
            
        default: 
            return user;
    }
}

export default UserReducer;
