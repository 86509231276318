import {
    IonContent,
    IonFooter,
    IonButton,
    IonPage,
    IonToolbar,
    IonText,
    IonSelect,
    IonSelectOption,
    IonLabel,
    IonItem,
    IonInput,
} from '@ionic/react';

import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { Device } from '@capacitor/device';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import dayjs from 'dayjs';

import {
    chooseDevice,
    setDeviceWifiArray,
    chooseNetwork
} from '../../../../../redux/actions/NewDeviceAction';
import { setAppMessage, logger } from '../../../../../redux/actions/AppAction';

import StandartHeader from '../../../components/header/StandartHeader';
import ToastMessage from '../../../components/toast/ToastMessage';
import Loading from '../../../components/loading/Loading';
import WifiHelper from '../../../../../helpers/WifiHelper';
import AppHelper from '../../../../../helpers/AppHelper';
import chooseDeviceImage from '../../../../../files/chooseDevice.svg';

import { HTTP } from '@awesome-cordova-plugins/http';
import InitialSettings from '../../../../../init';

const SSID = "tesystand";
const SSID_PASS = "verysecret";
const EMAIL = "deian.petkov@studiox.bg";

class ChooseDevice extends React.Component {

    state = {
        networks: [],
        selectedNetwork: "",
        appInfo: {},
        build: null,
        version: null,
        showLoading: false
    }

    componentDidMount = async () => {

        const info = await Device.getInfo();
        const {build , version} = await AppHelper.getAppVersion();

        this.setState({
            appInfo: info,
            build,
            version,
            selectedNetwork: this.props.newDevice.deviceSSID
        });

        document.removeEventListener('ionBackButton', (ev) => {
            document.body.classList.remove("qrscanner");
        });

        document.addEventListener('ionBackButton', (ev) => {
            document.body.classList.remove("qrscanner");
        });
    }


    /**
     * Remember the origin app netwokr
     * Connect to static device wifi name (IOS / Android)
     * Extract wifi array from device
     * Set device data into redux state 
     */

    sendDataToDeviceV1 = () => {


        this.props.logger(dayjs().format("HH:mm:ss")+" STEP 2: Send Wifi and Pass to device \n\n");
        this.setState({
            showLoading: true
        })

        this.props.chooseNetworkPass(this.state.password, this.state.no_password);
        global.debug(this.props.newDevice.networkSSID, this.props.newDevice);
        const {essid, channel, enc} = JSON.parse(this.props.newDevice.networkSSID);


        HTTP.post("http://10.0.0.1/wifiset", {
            '_': Date.now(),
            essid: SSID,
            channel,
            enc,
            key: SSID_PASS,
        }, {}).then( data => {


            HTTP.post("http://10.0.0.1/mtData", {
                '_': Date.now(),
                email: EMAIL,
                mydescr: 'none',
                mydetail: 'none',
            }, {}).then( async ({data}) => {

                const {err} = JSON.parse(data);

                if (err != undefined && err == 1) {
                    this.props.setAppMessage(this.props.t('cant_send_data_to_device'));
                } else {   
                    
                    HTTP.post("http://10.0.0.1/apset", {
                        '_': Date.now(),
                        newenc: 'psk2',
                        newkey: '',
                    }, {}).then( async ({data}) => {

                        const info = await Device.getInfo();

                        if (info.platform === 'ios') {
                            WifiHelper.disconnectFromNetworkIOS(SSID);
                        } else {
                            WifiHelper.disconnect(SSID);
                        }

                        setTimeout(()=>{
                            
                            this.props.setAppProcessing(false);
                            const initialSettingsODJ = new InitialSettings();
                            initialSettingsODJ.init();

                            this.setState({showLoading: false});
                            this.props.history.push('/dashboard');
                            
                        }, 10000)

                    })
                    .catch(error => {
                        this.setState({showLoading: false});
                        this.props.setAppMessage(this.props.t('cant_send_data_to_device'));
                    });
                }
            })
            .catch(error => {
                this.setState({showLoading: false});
                this.props.setAppMessage(this.props.t('cant_send_data_to_device'));
            });

            
        })
        .catch(error => {

            this.setState({showLoading: false});
            this.props.setAppMessage(this.props.t('cant_send_data_to_device'));
        });


    }

    sendDataToDeviceV2 = () => {

            HTTP.get("http://192.168.82.1/addDev", {
                ssid: SSID,
                pass: SSID_PASS,
                profile: EMAIL,
                tz: '',
            }, {}).then( async ({data}) => {

                
                const info = await Device.getInfo();

                if (info.platform === 'ios') {
                    WifiHelper.disconnectFromNetworkIOS(SSID);
                } else {
                    WifiHelper.disconnect(SSID);
                }

                setTimeout(()=>{

                    const {set} = JSON.parse(data);
                    global.debug('DATA', JSON.parse(data), "SET", set);
                    this.setState({showLoading: false});

                    if (set != undefined && set == 'OK') {

                        this.props.setAppProcessing(false);
                        const initialSettingsODJ = new InitialSettings();
                        initialSettingsODJ.init();

                        this.props.history.push('/dashboard');
                    } else {
                        this.props.setAppMessage(this.props.t('cant_send_data_to_device'));
                    }

                }, 10000)

            })
            .catch(error => {
                global.debug(error);
                this.setState({showLoading: false});
                this.props.setAppMessage(this.props.t('cant_send_data_to_device'));
            });
    }

    continue = async () => {

        this.props.logger(dayjs().format("HH:mm:ss")+" STEP 1: Connect to device AP (Tesy-CN06AS) \n\n");
        this.setState({
            showLoading: true
        });

        //TO DO: Extract the right data from settings for the right device

        if (this.state.selectedNetwork !== '') {

            this.props.chooseDevice(this.state.selectedNetwork);
            
            try {

                this.props.logger(`1.Trying to connect from phone: ${this.state.appInfo.manufacturer} ${this.state.appInfo.model}, os version: ${this.state.appInfo.osVersion}, app version: ${this.state.version} (${this.state.build})`);

                if (this.state.appInfo.platform === 'ios') {
                    await WifiHelper.connectToNetworkIOS(this.state.selectedNetwork);
                } else {
                    await WifiHelper.connectToNetwork(this.state.selectedNetwork, "");
                }

                if (this.state.selectedNetwork.includes('TCH_')) {
                    this.sendDataToDeviceV2();
                } else {
                    this.sendDataToDeviceV1();
                }

            } catch (e) {

                this.setState({
                    showLoading: false
                })

                this.props.logger(`ChooseDevice: Cannot connect to device network: ${e}`);
                this.props.setAppMessage(this.props.t('device_connection_problem'));
            }
            
        } else {
            this.props.setAppMessage(this.props.t('select_network_ios'));
        }

    }

    renderNetworksList = () => {
        
        if (this.state.networks.length === 0) {
            return null;
        }

        let ssidArray = [];
        return this.state.networks.map((n, k) => {

            const {SSID} = n;

            if (!ssidArray.includes(SSID)) {

                if (SSID.indexOf("Tesy") >= 0) {

                    ssidArray.push(SSID);
                    
                    return <IonSelectOption value={SSID} key={k}>{SSID}</IonSelectOption>
                }
            }
        })
    }

    scanQR = async () => {
        // Check camera permission
        // This is just a simple example, check out the better checks below

        const {asked, granted} = await BarcodeScanner.checkPermission({ force: true });

        if (granted !== undefined && granted === true) {


            // make background of WebView transparent
            // note: if you are using ionic this might not be enough, check below
            BarcodeScanner.hideBackground();

            document.body.classList.add("qrscanner"); 

            const result = await BarcodeScanner.startScan(); // start scanning and wait for a result

            document.body.classList.remove("qrscanner");
            // if the result has content
            if (result.hasContent) {

                const {SSID} = JSON.parse(result.content);
                this.setState({selectedNetwork: SSID});
                global.debug(SSID, "SSID");

            } else {
                this.props.setAppMessage(this.props.t("cant_scan_qr"));
            }
    
        } else {
            this.props.setAppMessage(this.props.t('camera_permissions_not_allowed'));
        }
    }

    render = () => {
        const { t } = this.props;

        return (
            <IonPage>        
                
                <StandartHeader name={t('choose_device')} />

                <IonContent className="ion-padding">
                    <div className="circle-image">
                        <img src={chooseDeviceImage} />
                    </div>                
                    
                    <IonLabel color="primary">{t('device')}</IonLabel>
                    
                    <IonItem lines="full" className="form-list__item">
                        <IonLabel position="stacked" color="primary" className="form-label"></IonLabel>
                        <IonInput 
                            type="text" 
                            className="form-input" 
                            placeholder='TCHxxxxx / TCH_xxxxx' 
                            value={this.state.selectedNetwork}
                            onIonInput={ e => this.setState({selectedNetwork: e.detail.value !== '' ? `${e.detail.value}` : ''})}
                        />
                    </IonItem>


                    <ToastMessage />
                    <Loading show={this.state.showLoading}/>

                </IonContent>
                
                <IonFooter className="app-footer ion-no-border">
                    <IonToolbar>
                        <div className="buttons-list">
                            <div className="buttons-list__item">
                                <IonButton expand="block" shape="round" size="large" onClick={this.continue}>{t('continue')}</IonButton>
                            </div>
                            <div className="buttons-list__item">
                                <IonButton expand="block" shape="round" size="large" onClick={this.scanQR}>{t('scanQR')}</IonButton>
                            </div>
                        </div>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        );
    }
    
};

const mapStateToProps = state => {
    return {
        newDevice: state.newDevice
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        chooseDevice,
        setAppMessage,
        setDeviceWifiArray,
        chooseNetwork,
        logger
    })
)(ChooseDevice);
  