import RestHelper from './RestHelper';
import LocalStorageHelper from './LocalStorageHelper';
import AppHelper from './AppHelper';
import InitialSetttings from '../init';
import i18n from '../lang/langFactory';
import { Device } from '@capacitor/device';
import { netkom_secret_code } from '../config/App';

class UsersHelper {

    constructor() {
        this.restHelper = new RestHelper();
    }

    // login = async data => {

    //     const {isMyTesyUser, loginMyTesy, useTesyCloudCredentials, loginTesyCloud} = await this.userFlow(data);

    //     if (isMyTesyUser && loginMyTesy && !useTesyCloudCredentials && loginTesyCloud) {
    //         await this.oldAppSettings({email: data.email, password: data.password});
    //     }

    //     if (!isMyTesyUser && !useTesyCloudCredentials && loginTesyCloud) {

    //         await this.signup({
    //             email: data.email,
    //             name: data.email,
    //             lastName: data.email,
    //             password: data.password,
    //             confirmPassword: data.password,
    //             lang: 'en',
    //         });

    //         await this.loginMyTesy(data);

    //         this.restHelper = new RestHelper();

    //         await this.oldAppSettings({email: data.email, password: data.password});
    //     }

    //     return {isMyTesyUser, loginMyTesy, useTesyCloudCredentials, loginTesyCloud}
    // }

    login = async data => {
        
        let loginInfo = {};

        try {
            const userHasMyTesyRegistration = await this.userHasMyTesyRegistration({email: data.email})

            if (userHasMyTesyRegistration) { //User is MyTesy 

                loginInfo.isMyTesyUser = true;
                loginInfo.loginMyTesy = true;

                await this.loginMyTesy(data);

                if (await LocalStorageHelper.get('user.oldAppEmail') && await LocalStorageHelper.get('user.oldAppPassword')) {
                    
                    loginInfo.useTesyCloudCredentials = true;
                    global.debug("Try to login into TesyCloud");

                    try { //Успешно логване в TesyCloud (TesyCloud credentials)

                        await this.loginTesyCloud({email: `${await LocalStorageHelper.get('user.oldAppEmail')}`, password: await LocalStorageHelper.get('user.oldAppPassword')})
                        loginInfo.loginTesyCloud = true;

                    } catch (e) { //Неуспешно логване в TesyCloud (TesyCloud credentials)

                        loginInfo.loginTesyCloud = false;
                        //throw {errors: e.errors}
                    }

                } else {

                    loginInfo.useTesyCloudCredentials = false;

                    try { //Успешно логване в TesyCloud (MyTesy credentials)

                        this.restHelper = new RestHelper();

                        await this.loginTesyCloud({email: data.email, password: data.password})

                        await this.oldAppSettings({email: data.email, password: data.password});

                        loginInfo.loginTesyCloud = true;

                    } catch (e) { //Неуспешно логване в TesyCloud (MyTesy credentials)

                        loginInfo.loginTesyCloud = false;
                        //throw {errors: e.errors}
                    }
                }

            } else { //Not MyTesy user

                loginInfo.isMyTesyUser = false;
                loginInfo.useTesyCloudCredentials = false;

                try { //Успешно логване в TesyCloud, регистриране в MyTesy
                   
                    loginInfo.loginTesyCloud = true;
                    //loginInfo.loginMyTesy = true;

                    await this.loginTesyCloud({email: data.email, password: data.password})

                    await this.signup({
                        email: data.email,
                        name: data.email,
                        lastName: data.email,
                        password: data.password,
                        confirmPassword: data.password,
                        lang: 'en',
                    });

                    await this.loginMyTesy(data);

                    this.restHelper = new RestHelper();

                    await this.oldAppSettings({email: data.email, password: data.password});


                } catch (e) { //Неуспешно логване в TesyCloud

                    loginInfo.loginTesyCloud = false;
                    throw {errors: e.errors}
                }

            }

        } catch (e) {

            loginInfo.loginMyTesy = true;
            throw {errors: e.errors}
        }

        return loginInfo;
    }

    // Check user registration in MyTesy
    userHasMyTesyRegistration = async postData => {

        const {data} = await this.restHelper.post('/app-user-has-registration', postData);
        const {errors} = data;

        if (errors) {
            throw {errors: errors}
        }

        return data.success;
    }

    loginTesyCloud = async ({email, password}) => {
        
        const {data: {error, CURRENT_SESSION}} = await this.restHelper.post('/old-app-login', {email, password});

        if (error) {
            throw {errors: {global: "Wrong E-mail or Password"}}
        }

        return CURRENT_SESSION;
    }

    registerTesyCloud = async ({email, password}) => {
        
        const {data: {register}} = await this.restHelper.post('/old-app-register', {email, password, netkom_secret_code, terms: true});

        return register;
    }

    tesyCloudForgottenPassword = async email => {
        const {data: {lostpass}} = await this.restHelper.post('/old-app-lost-password', {email, code:netkom_secret_code});

        return lostpass;
    }

    tesyCloudResetPassword = async data => {
        const {data: {resetpass}} = await this.restHelper.post('/old-app-reset-password', data);

        return resetpass;
    }

    /*
        Set User credentials into local storage
        Set user data into redux state
    */
    loginMyTesy = async data => {

        const result = await this.restHelper.post('/app-user-login', data);
        const {errors} = result.data;

        if (errors) {
            throw {errors: errors}
        }

        const {userID, password, email, firstName, lastName, phone, lang, debug_menu, oldAppEmail, oldAppPassword, is_tester} = result.data;

        LocalStorageHelper.set('user.id', userID);
        LocalStorageHelper.set('user.password', password);
        LocalStorageHelper.set('user.email', email);
        LocalStorageHelper.set('user.firstName', firstName);
        LocalStorageHelper.set('user.lastName', lastName);
        LocalStorageHelper.set('user.phone', phone);
        LocalStorageHelper.set('user.lang', lang);
        LocalStorageHelper.set('user.debug_menu', debug_menu);
        LocalStorageHelper.set('user.oldAppEmail', oldAppEmail);
        LocalStorageHelper.set('user.oldAppPassword', oldAppPassword);
        LocalStorageHelper.set('user.is_tester', is_tester);

        i18n.changeLanguage(lang);
    }

     /*
        User registration
    */
    signup = async data => {
        const result = await this.restHelper.post('/app-user-registration', data); 
        const {errors} = result.data;

        if (errors) {
            throw {errors: errors}
        }  
        
        const {userID, password, email} = result.data;

        await this.loginMyTesy({
            email: data.email,
            password: data.password,
        });

        this.restHelper = new RestHelper();


        //Try to log in in tesyCloud
        try { //Успешно логване в TesyCloud (TesyCloud credentials)

            await this.loginTesyCloud({email: data.email, password: data.password})
            
            await this.oldAppSettings({email: data.email, password: data.password});

        } catch (e) { //Неуспешно логване в TesyCloud (TesyCloud credentials)
            global.debug('It`s not tesyCloud user');
        }

        //TO DO: Push Notification ID
        //this.PushNotificationsHelper.register(userID);
    }

    accountSettings =async data => {
        const result = await this.restHelper.post('/app-user-account-settings', data); 
        const {errors} = result.data;

        if (errors) {
            throw {errors: errors}
        }  
    }

    oldAppSettings = async data => {

        const result = await this.restHelper.post('/old-app-settings', data); 
        const {errors} = result.data;

        if (errors) {
            throw {errors: errors}
        }  

        LocalStorageHelper.set('user.oldAppEmail', data.email);
        LocalStorageHelper.set('user.oldAppPassword', data.password);

    }

    passwordSettings =async data => {
        const result = await this.restHelper.post('/app-user-password-settings', data); 
        const {errors} = result.data;

        if (errors) {
            throw {errors: errors}
        }  
    }

    /*
        User recover password
    */
    recoverPassword = async email => {

        const result = await this.restHelper.post('/app-lost-password', {email}); 
        const {errors} = result.data;

        if (errors) {
            throw {errors: errors}
        }     
    }

    /*
        User change password
    */
   changePassword = async data => {

        const result = await this.restHelper.post('/app-change-password', data); 
        const {errors} = result.data;

        if (errors) {
            throw {errors: errors}
        }     
    }

    saveUserLog = async log => {
        
        const deviceInfo = await Device.getInfo();

        const {build , version} = await AppHelper.getAppVersion();

        if (log === 'dashboard') {
            log = `${log} : app version: ${version} (${build})`;
        }

        this.restHelper.post('/user-device-log', {
            log: log,
            manufacturer: deviceInfo.manufacturer,
            model: deviceInfo.model,
            operatingSystem: deviceInfo.operatingSystem,
            osVersion: deviceInfo.osVersion,
        });

    }
}

export default UsersHelper;