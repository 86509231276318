import {
    CHOOSE_DEVICE,
    CHOOSE_NETWORK,
    CHOOSE_NETWORK_PASS,
    SET_MODEL_POWER,
    SET_DEVICE_MAC_ADDRESS,
    SET_DEVICE_MODEL,
    SET_DEVICE_NAME,
    SET_DEVICE_POWER,
    DELETE_NEW_DEVICE,
    SET_DEVICE_AP_PASS,
    SET_DEVICE_WIFI_ARRAY,
    CHOOSE_NETWORK_ORIGINAL,
    SET_DEVICE_VOLUME
} from '../types/NewDeviceTypes';

const newDeviceStructure = {
    deviceSSID: '',
    deviceWifiArray: '',
    apPassword: '',
    networkSSID: '',
    networkPassword: '',
    networkNoPassword: '',
    modelPower: '',
    macAddress: '',
    model: '',
    name: '',
    power: '',
    volume: '',
    ip: '',
    timezone: ''
}

const newDeviceReducer = (newDevice = newDeviceStructure, action) => {
    switch(action.type) {
        case CHOOSE_DEVICE:
            return {...newDevice, deviceSSID: action.payload.ssid}

        case SET_DEVICE_WIFI_ARRAY:
            return {...newDevice, deviceWifiArray: action.payload.wifi}

        case CHOOSE_NETWORK:
            newDevice.networkSSID = JSON.stringify(action.payload)
            return {...newDevice}

        case CHOOSE_NETWORK_ORIGINAL:
            return {...newDevice, networkSSID: action.payload.ssid}

        case CHOOSE_NETWORK_PASS:
            return {...newDevice, networkPassword: action.payload.password, networkNoPassword: action.payload.no_password}

        case SET_MODEL_POWER:
            return {...newDevice, modelPower: action.payload.power}

        case SET_DEVICE_AP_PASS:
            return {...newDevice, apPassword: action.payload.password}

        case SET_DEVICE_MAC_ADDRESS:
            return {...newDevice, macAddress: action.payload.mac}

        case SET_DEVICE_MODEL:
            return {...newDevice, model: action.payload.model} 

        case SET_DEVICE_NAME:
            return {...newDevice, name: action.payload.name} 

        case SET_DEVICE_POWER:
            return {...newDevice, power: action.payload.power} 

        case SET_DEVICE_VOLUME:
            return {...newDevice, volume: action.payload.volume} 

        case DELETE_NEW_DEVICE:
            return {...newDeviceStructure}
            
        default: 
            return newDevice;
    }
}

export default newDeviceReducer;