import React, {useEffect} from "react";
import Slider from '@mui/material/Slider';
import { Device } from '@capacitor/device';

//import './range.scss';

const formatTime = (timestamp) => {
    const d = Number(timestamp);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const hours = h > 0 ? `${h.toString().length > 1 ? `${h}` : `${0}${h}`}` : '00';
    const minutes = m > 0 ? `${m.toString().length > 1 ? `${m}` : `${0}${m}`}` : '00';

    return `${hours}:${minutes}`;
}

const RangeStartTime = (props) => {

    const [startTime, setStartTime] = React.useState( Number(props['data-start']) );
    const [platform, setPlatform] = React.useState("android")

    useEffect(() => {
        setStartTime(props['data-start'])
    }, [props['data-start']]);

    useEffect(async () => {
        const {platform} = await Device.getInfo();
        setPlatform(platform)
    }, [])

    const handleStartTimeChange = (event, value) => {

        if (platform == "ios" && event.type == "mousedown") {
            return;
        }

        setStartTime(value);
    }

    const handleStartTimeChangeComitted = (event, value) => {

        if (platform == "ios" && event.type == "mouseup") {
            return;
        }
        
        props.setTime(formatTime(value));   
    }

    return (
        <React.Fragment>
            <div className="range-slider__wrapper">
                <header className="range-slider__header">
                    <div className="range-slider__header-item">
                        <div className="range-slider__header-label">{props['data-label']}</div>
                    </div>
                    <div className="range-slider__header-item">
                        <div className="range-slider__output">{formatTime(startTime)}h</div>
                    </div>
                </header>
                <Slider
                    value={typeof startTime === 'number' ? startTime : 0}
                    min={ Number(props['data-min']) }
                    max={ Number(props['data-max']) }
                    step={ Number(props['data-step']) }
                    track="inverted"
                    onChange={handleStartTimeChange}
                    onChangeCommitted={handleStartTimeChangeComitted}
                    valueLabelDisplay="off"
                    className="range-slider range-slider--time"
                />
            </div>
        </React.Fragment>
    );
};

export default RangeStartTime;