import {
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonToggle,
    IonButton,
    IonModal,
    IonToolbar,
    IonText
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'
import Select from 'react-select';
import Slider from '@mui/material/Slider';

import {cn05uvSettings} from '../../config/App';
import Header from './components/header/Header';
import PageHeader from './components/header/PageHeader';
import PageSubHeader from './components/header/PageSubHeader';
import Footer from './components/footer/Footer';
import DeviceCN05UVMenu from './components/menu/DeviceCN05UVMenu';
import TemperatureSlider from './components/range/TemperatureSlider';
import {
    addDeviceProgram
} from '../../redux/actions/DevicesAction';
import { setAppMessage } from '../../redux/actions/AppAction';

import DeviceSettingsHelper from '../../helpers/DeviceSettingsHelper';
import ProgramHelper from '../../helpers/ProgramHelper';
import ToastMessage from './components/toast/ToastMessage';

class AddProgram extends React.Component {

    constructor(props){
        super(props);

        this.mac = null;
        this.model = null;
        this.DeviceSettingsHelper = null;

        this.state = {
            from: "00:00",
            to: "23:59",
            temp: cn05uvSettings.start_temp,
            timeOptions: [
                { value: '00:00', label: '00:00h' },
                { value: '00:30', label: '00:30h' },
                { value: '01:00', label: '01:00h' },
                { value: '01:30', label: '01:30h' },
                { value: '02:00', label: '02:00h' },
                { value: '02:30', label: '02:30h' },
                { value: '03:00', label: '03:00h' },
                { value: '03:30', label: '03:30h' },
                { value: '04:00', label: '04:00h' },
                { value: '04:30', label: '04:30h' },
                { value: '05:00', label: '05:00h' },
                { value: '05:30', label: '05:30h' },
                { value: '06:00', label: '06:00h' },
                { value: '06:30', label: '06:30h' },
                { value: '07:00', label: '07:00h' },
                { value: '07:30', label: '07:30h' },
                { value: '08:00', label: '08:00h' },
                { value: '08:30', label: '08:30h' },
                { value: '09:00', label: '09:00h' },
                { value: '09:30', label: '09:30h' },
                { value: '10:00', label: '10:00h' },
                { value: '10:30', label: '10:30h' },
                { value: '11:00', label: '11:00h' },
                { value: '11:30', label: '11:30h' },
                { value: '12:00', label: '12:00h' },
                { value: '12:30', label: '12:30h' },
                { value: '13:00', label: '13:00h' },
                { value: '13:30', label: '13:30h' },
                { value: '14:00', label: '14:00h' },
                { value: '14:30', label: '14:30h' },
                { value: '15:00', label: '15:00h' },
                { value: '15:30', label: '15:30h' },
                { value: '16:00', label: '16:00h' },
                { value: '16:30', label: '16:30h' },
                { value: '17:00', label: '17:00h' },
                { value: '17:30', label: '17:30h' },
                { value: '18:00', label: '18:00h' },
                { value: '18:30', label: '18:30h' },
                { value: '19:00', label: '19:00h' },
                { value: '19:30', label: '19:30h' },
                { value: '20:00', label: '20:00h' },
                { value: '20:30', label: '20:30h' },
                { value: '21:00', label: '21:00h' },
                { value: '21:30', label: '21:30h' },
                { value: '22:00', label: '22:00h' },
                { value: '22:30', label: '22:30h' },
                { value: '23:00', label: '23:00h' },
                { value: '23:30', label: '23:30h' },
                { value: '24:00', label: '24:00h' },
            ]
        }
    }

    componentDidMount = () => {
        this.DeviceSettingsHelper = new DeviceSettingsHelper(this.mac);
    }

    saveProgram = () => {

        const programHelper = new ProgramHelper(this.mac);

        if (programHelper.validateProgram(this.state.from, this.state.to, Number(this.props.match.params.day))) {

            this.DeviceSettingsHelper.handleAddDeviceProgram({
                day: Number(this.props.match.params.day),
                from: this.state.from,
                to: this.state.to,
                temp: this.state.temp
            });

            //this.props.history.goBack();
            this.props.history.push(`/device/program/${this.model}/${this.mac}/${this.props.match.params.day}`);
        } else {
            this.props.setAppMessage(this.props.t('invalid_program'));
        }
    }

    render = () => {

        this.mac = this.props.match.params.mac_address;
        this.model = this.props.match.params.model;

        const {t, devices} = this.props;
        const device = devices[this.mac];

        return (
            <IonPage>
                <Header />
                <IonContent className="ion-no-padding">
                    <div className="dashboard">
                        <div className="dashboard__wrapper">

                            <DeviceCN05UVMenu activeMenu="controlPanel" mac={this.props.match.params.mac_address}/>

                            <div className="dashboard__content">
                               
                                <PageHeader name={t('control_panel')}/>

                                <div className="dashboard-box">
                                    
                                    <PageSubHeader title={t('add_preference')}/>

                                    <div className="dashboard-box__body">

                                        <div className="gray-box">
                                            <div className="gray-box__body">
                                                <div className="wrapper wrapper--400">
                                                    <IonRow className="ion-justify-content-between">
                                                        <IonCol size="6" className="p-0">
                                                            <IonRow className="ion-align-items-center">
                                                                <IonCol size="5" className="p-0"><strong>{t('time_from')}:</strong></IonCol>
                                                                <IonCol size="6" className="p-0">
                                                                    <Select
                                                                        className="react-select react-select--secondary"
                                                                        options={this.state.timeOptions}
                                                                        defaultValue={{ label: '00:00h', value: '00:00' }}
                                                                        isSearchable={true}
                                                                        onChange={value => this.setState({from: value.value})}
                                                                    />
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonCol>
                                                        <IonCol size="6" className="p-0">
                                                            <IonRow className="ion-align-items-center">
                                                                <IonCol size="5" className="p-0"><strong>{t('time_to')}:</strong></IonCol>
                                                                <IonCol size="6" className="p-0">
                                                                    <Select
                                                                        className="react-select react-select--secondary"
                                                                        options={this.state.timeOptions}
                                                                        defaultValue={{ label: '00:00h', value: '00:00' }}
                                                                        isSearchable={true}
                                                                        onChange={value => this.setState({to: value.value})}
                                                                    />
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonCol>
                                                    </IonRow>
    
                                                    <div className="ion-padding-top"></div>
                                                    <div className="ion-padding-top"></div>
    
                                                    <TemperatureSlider 
                                                        label={t('target_temperature')}
                                                        disabled={false}
                                                        currentTemp={cn05uvSettings.start_temp}
                                                        minTemperature={cn05uvSettings.min_temp}
                                                        maxTemperature={cn05uvSettings.max_temp}
                                                        stepTemperature={cn05uvSettings.step_temp}
                                                        handleTemperature={temp => this.setState({temp})}
                                                    />
    
                                                    <div className="ion-padding-top"></div>
                                                    
                                                    <div className="ion-text-center">
                                                        <IonButton color="primary" onClick={this.saveProgram}>
                                                            <span className="button__text">{t('save')}</span>
                                                        </IonButton>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ToastMessage />
                </IonContent>
                <Footer />
            </IonPage>
        );
    }
};

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        addDeviceProgram,
        setAppMessage
    })
)(AddProgram);