import {
    SET_LANG,
    SET_PUSH_NOTIOFICATIONS,
    SET_PUSH_NOTIOFICATION,
    READ_PUSH_NOTIOFICATION,
    SET_APP_MESSAGE,
    HIDE_APP_MESSAGE,
    SET_APP_LOG,
    SET_APP_DOCUMENTS,
    DELETE_PUSH_NOTIOFICATION,
    DELETE_ALL_PUSH_NOTIOFICATIONS,
    CLEAR_APP_LOG,
    OLD_DEVICES_LOADER,
    SET_APP_PROCESSING,
    SET_APP_NETWORK,
    SET_APP_SETTINGS,
    SHOW_REQUEST_LOADER,
    HIDE_REQUEST_LOADER
} from '../types/AppTypes';
import UsersHelper from '../../helpers/UsersHelper';

const defaultAppSettings = {
    lang: 'en', 
    push_notifications_messages: {},
    app_message: '',
    app_message_show: false,
    log: [],
    documents: {},
    old_devices_loader: false,
    app_processing: false,
    app_network: true,
    general_settings: {
        finish_time: 30000
    },
    show_request_loader: false,
    show_request_loader_for_model: ''
}

const AppReducer = (settings = defaultAppSettings, action) => {

    switch(action.type) {
        case SET_LANG:

            return {...settings, lang: action.payload.lang};

        case SET_APP_SETTINGS:

            return {...settings, general_settings: action.payload.settings};

        case SET_PUSH_NOTIOFICATIONS:

            return {...settings, push_notifications_messages: action.payload.messages};

        case SET_PUSH_NOTIOFICATION:

            let { push_notifications_messages } = settings;
            push_notifications_messages.push(action.payload.message);
            return {...settings, push_notifications_messages};

        case READ_PUSH_NOTIOFICATION:

            for (const i in settings.push_notifications_messages) {
                if (settings.push_notifications_messages[i].id === action.payload.message_id) {
                    settings.push_notifications_messages[i].is_read = 1;
                }
             }

            return {...settings, push_notifications_messages: settings.push_notifications_messages};

        case DELETE_PUSH_NOTIOFICATION:

            for (const i in settings.push_notifications_messages) {
                if (settings.push_notifications_messages[i].id === action.payload.message_id) {
                    delete settings.push_notifications_messages[i];
                }
             }

            return {...settings, push_notifications_messages: settings.push_notifications_messages};

        case DELETE_ALL_PUSH_NOTIOFICATIONS:

            return {...settings, push_notifications_messages: {}};
            
        case SET_APP_MESSAGE:

            return {...settings, app_message: action.payload.message, app_message_show: true}

        case HIDE_APP_MESSAGE:

            return {...settings, app_message: '', app_message_show: false}

        case SET_APP_LOG:

            let userObject = new UsersHelper();
            userObject.saveUserLog(action.payload.message);

            return {...settings, log: [...settings.log, action.payload.message]}

        case CLEAR_APP_LOG:

            return {...settings, log: []}

        case SET_APP_DOCUMENTS:

            return {...settings, documents: action.payload.documents}
        
        case OLD_DEVICES_LOADER:

            return {...settings, old_devices_loader: action.payload.status}
        
        case SHOW_REQUEST_LOADER:

            return {...settings, show_request_loader: true, show_request_loader_for_model: action.payload.model}

        case HIDE_REQUEST_LOADER:

            if (settings.show_request_loader_for_model == action.payload.model) {
                return {...settings, show_request_loader: false, show_request_loader_for_model: action.payload.model}
            }
            
            return {...settings}

        case SET_APP_PROCESSING:

            return {...settings, app_processing: action.payload.status}

        case SET_APP_NETWORK:

            return {...settings, app_network: action.payload.status}
    

        default:
            return settings;
    }
}

export default AppReducer;