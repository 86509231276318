import cn05uv from '../models/cn05/cn05uv';
import {
    updateDeviceState,
    updateDeviceTime,
    deleteDeviceProgram
} from '../redux/actions/DevicesAction';
import requestToDevice from '../services/serviceFactory';
import store from '../redux/createStore';
import DeviceProgramData from '../config/DeviceProgramData.json';

class DeviceSettingsHelper
{
    constructor(mac) {

        this.mac = mac;

        const { devices } = store.getState();
        this.device = devices[mac];

        //TO DO: LOAD THE CORRECT MODEL FROM the DEVICE
        this.model = new cn05uv({
            macAddr: this.mac    
        });
    }

    handleReset = () => {

        //updateDeviceState(status, this.model.table_fields.status, this.mac)

        requestToDevice({
            device: this.device,
            macAddr: this.mac,
            payload: {},
            command: 'reset'
        })
    };

    handleOnOff = (status) => {

        //updateDeviceState(status, this.model.table_fields.status, this.mac)

        requestToDevice({
            device: this.device,
            macAddr: this.mac,
            payload: {
                status
            },
            command: 'onOff'
        })
    };

    handleUV = (status) => {

        store.dispatch(updateDeviceState(status, this.model.table_fields.uv, this.mac));

        requestToDevice({
            device: this.device,
            macAddr: this.mac,
            payload: {
                status
            },
            command: 'setUV'
        })
    };

    handleOnChangeTemp = temp => {

        //updateDeviceState(temp, this.model.table_fields.temp, this.mac)
        //this.handleMode('heating');

        requestToDevice({
            device: this.device,
            macAddr: this.mac,
            payload: {
                temp: temp
            },
            command: 'setTemp'
        })
    };

    handleLockDevice = status => {

        //updateDeviceState(status, this.model.table_fields.lockDevice, this.mac)

        requestToDevice({
            device: this.device,
            macAddr: this.mac,
            payload: {
                status
            },
            command: 'setLockDevice'
        })
    };

    handleOpenedWindow = status => {

        store.dispatch(updateDeviceState(status, this.model.table_fields.openedWindow, this.mac));

        requestToDevice({
            device: this.device,
            macAddr: this.mac,
            payload: {
                status
            },
            command: 'setOpenedWindow'
        })
    };

    handleAntiFrost = status => {

        store.dispatch(updateDeviceState(status, this.model.table_fields.antiFrost, this.mac));

        requestToDevice({
            device: this.device,
            macAddr: this.mac,
            payload: {
                status
            },
            command: 'setAntiFrost'
        })
    };

    handleTCorrection = temp => {

        //updateDeviceState(temp, this.model.table_fields.tCorrection, this.mac)

        requestToDevice({
            device: this.device,
            macAddr: this.mac,
            payload: {
                temp
            },
            command: 'setTCorrection'
        })
    };

    handlePorgramStatus = status => {
        //updateDeviceState(status, this.model.table_fields.programStatus, this.mac)

        requestToDevice({
            device: this.device,
            macAddr: this.mac,
            payload: {
                status
            },
            command: 'setProgramStatus'
        })    
    }

    handleTimezone = ({timezone, day, time, date}) => {
        //TO DO:  UPDATE DEVICE SETTINGS FOR TIME AND TIMEZONE
        //updateDeviceState(temp, this.model.table_fields.tCorrection, this.mac)
        
        store.dispatch(updateDeviceTime(timezone, date, time, this.mac))

        requestToDevice({
            device: this.device,
            macAddr: this.mac,
            payload: {
                day,
                time
            },
            command: 'timeResponse',
            requestType: 'timeResponse'
        })    
    }

    handleAdaptiveStart = status => {

        //updateDeviceState(status, this.model.table_fields.adaptive_start, this.mac)

        requestToDevice({
            device: this.device,
            macAddr: this.mac,
            payload: {
                status
            },
            command: 'setAdaptiveStart'
        })   
    }

    handleEcoTemp = ecoTemp => {

        //updateDeviceState(status, this.model.table_fields.adaptive_start, this.mac)

        requestToDevice({
            device: this.device,
            macAddr: this.mac,
            payload: ecoTemp,
            command: 'setEcoTemp'
        })   
    }

    handleComfortTemp = comfortTemp => {

        //updateDeviceState(status, this.model.table_fields.adaptive_start, this.mac)

        requestToDevice({
            device: this.device,
            macAddr: this.mac,
            payload: comfortTemp,
            command: 'setComfortTemp'
        })   
    }

    handleSleepMode = sleep => {

        //updateDeviceState(status, this.model.table_fields.adaptive_start, this.mac)

        requestToDevice({
            device: this.device,
            macAddr: this.mac,
            payload: sleep,
            command: 'setSleepTemp'
        })   
    }

    handleDelayedStart = delayedStart => {
        global.debug("delayedStart", delayedStart);
        //updateDeviceState(status, this.model.table_fields.adaptive_start, this.mac)

        requestToDevice({
            device: this.device,
            macAddr: this.mac,
            payload: delayedStart,
            command: 'setDelayedStart'
        })   
    }

    findProgramKey = ({day, from}) => {
        let programKey = 1;

        for (const key in DeviceProgramData) {
            const {day: programDay, from: programFrom} = DeviceProgramData[key];

            if (programDay === Number(day) && programFrom === from) {
                programKey = key;
                break;
            }
        }

        return programKey;
    }

    handleEditProgram = (key, program) => {

        this.handleDeleteProgram(key);

        setTimeout(() => { 
            this.handleAddDeviceProgram(program);
        }, 2000);

    }

    handleDeleteProgram = key => {

        store.dispatch(deleteDeviceProgram(this.mac, key));

        requestToDevice({
            device: this.device,
            macAddr: this.mac,
            payload: {program_number: key},
            command: 'deleteProgram'
        })  
    }

    handleAddDeviceProgram = program => {

        const programKey = this.findProgramKey(program);    
        
        //store.dispatch(addDeviceProgram(mac, programKey, {...program, program_number: programKey}));

        requestToDevice({
            device: this.device,
            macAddr: this.mac,
            payload: {...program, program_number: programKey},
            command: 'setProgram'
        })  
    }

    handleDeleteAllDeviceprograms = () => {
        //store.dispatch(deleteAllDevicePrograms(mac));    

        requestToDevice({
            device: this.device,
            macAddr: this.mac,
            payload: {},
            command: 'deleteAllPrograms'
        })  
    }

    handleMode = mode => {
        requestToDevice({
            device: this.device,
            macAddr: this.mac,
            payload: {
                name: mode
            },
            command: 'setMode'
        })      
    }

    handleGetSSID = () => {

        store.dispatch(updateDeviceState('', 'wifiInfo', this.mac));    
        
        requestToDevice({
            device: this.device,
            macAddr: this.mac,
            payload: {},
            command: 'getSSID'
        })    
    }

    handleDeviceWifi = wifiData => {

        requestToDevice({
            device: this.device,
            macAddr: this.mac,
            payload: wifiData,
            command: 'setWifiData'
        })       
    }

    handleGetStatus = () => {

        requestToDevice({
            device: this.device,
            macAddr: this.mac,
            payload: {},
            command: 'getStatus'
        })       
    }
        
}

export default DeviceSettingsHelper;