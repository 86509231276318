import MainService from './MainService';
import {
    appMqttVersion,
    modelsSettings
} from '../config/App';
import DeviceRestHelper from '../helpers/DeviceRestHelper';

class Http extends MainService {

    constructor() {
        super();
    }

    // async publishMessage(topic, payload = {}) {

    //     payload = {return_response: false, app_id: this.app_id, ...payload};

    //     const [version, macAddr, model, token, command] = topic.split("/");
    //     const deviceHost = this.devices[macAddr].httpAddr;
        
    //     const res = await axios.get(`${deviceHost}${topic}`, {
    //         params: payload
    //     });

    //     this.messageProcess([version, macAddr, model, token, command], JSON.stringify(res.data))
    // }

    publishMessage({model, token, macAddr, payload, requestType, command}) {

        global.debug('\n','\n',`---------------Publish new message: --------------`, '\n');
        payload = {app_id: this.app_id, ...payload};

        const topic = `${appMqttVersion}/${macAddr}/${requestType}/${model}/${token}/${command}`;

        const { http_api_url } = modelsSettings[model];

        const deviceRest = new DeviceRestHelper(http_api_url);

        deviceRest.post(topic, payload) 
        .then(res => {
            global.debug("HTTP Device Response: ", res.data, topic);
            this.messageProcess(topic, JSON.stringify(res.data))
        })
        .catch (error => {
            global.debug();
        })

    }
}

export default new Http();