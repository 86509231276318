import Progress from '../../pages/phoneApp/components/progress/Progress';
import { cn05uvSettings } from '../../config/App';
import HeatPumpHelper from "../HeatPumpHelper";
import E32Helper from "../E32Helper";
import { B15_Settings } from '../../config/B15';

class DashBoardRenderGroupsHelper
{
    constructor(device, mac) {

        this.device = device;
        this.mac = mac;
    }

    renderCN05UV = () => {
        let temp = this.device.state.temp;
        if (this.device.state.target > 0) {
            temp = this.device.state.target;
        }
        const percentage = (temp / cn05uvSettings.max_temp) * 100;


        return this.progress(percentage, this.device.state.target > 0 ? this.device.state.target : this.device.state.temp);
    }

    renderHPWH4_11_260 = () => {
        
        const HeatPumpHelperOBJ = new HeatPumpHelper(this.device);
        const currentModeSettings = HeatPumpHelperOBJ.getCurrentModeSettings();
        const currentMode = HeatPumpHelperOBJ.getMode();

        let { temp } = this.device.state;
        if (currentMode == "va") {
            temp = this.device.state.vacation.temp;
        }

        if (currentMode == "program") {
            const currentProgramPeriod = HeatPumpHelperOBJ.getProgramCurrentPeriod();   
            temp = currentProgramPeriod.temp;
        }
        
        const percentage = (temp / currentModeSettings.maxTemp) * 100;

        return this.progress(percentage, temp);
    }

    renderE32 = () => {
        
        const E32HelperOBJ = new E32Helper(this.device);
        const showers = E32HelperOBJ.getShowers();

        let { heat_level, mode } = this.device.state;
        if (mode == "va") {
            heat_level = this.device.state.vacation.heatLevel;
        }

        const percentage = (heat_level / showers) * 100;

        return this.progress(percentage, heat_level);
    }

    renderB15 = () => {
        

        let { set_temp, mode } = this.device.state;
        if (mode == "va") {
            set_temp = this.device.state.vacation.temp;
        }

        const percentage = (set_temp / B15_Settings.maxTemp) * 100;

        return this.progress(percentage, set_temp);
    }

    progress = (percentage, temp) => {
        return <Progress
              key={`group_device_${this.mac}`} 
              data-name={this.device.deviceName} 
              data-progress={percentage} 
              data-value={temp} 
              data-suffix="°C" 
              data-has-icon="false" 
              data-icon="heating" 
            />;
    }
}

export default DashBoardRenderGroupsHelper;