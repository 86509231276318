import {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonIcon
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import arrowNext from '../../../../../assets/images/arrow-list.svg';
import StandartHeader from '../../../components/header/StandartHeader';
import {oldModels} from '../../../../../config/App'; 
  
const DeviceSettings = props => {

    const { match: {params: {id, model_name}}, devices} = props;
    const device = devices[id];

    const settings_menu = [
        // {
        //     title: props.t('Vacation'),
        //     icon: 'palm',
        //     url: `/old/apiv2/vacation/${model_name}/${id}`
        // },
        {
            title: props.t('world_clock'),
            icon: 'world-clock',
            url: `/old/apiv2/world-clock/${model_name}/${id}`
        }
    ];

    return (
        <IonPage>

        <StandartHeader name={props.t('settings')}/>

        <IonContent className="ion-padding">        
            <IonList className="manuals-list">
            {settings_menu.map(({ title, icon, url }, i) => (
                <IonItem 
                    key={i} 
                    button 
                    className="manuals-list__item" 
                    routerLink={url} 
                    disabled={(device.DeviceOnline !== 1) ? true : false}
                >
                    <div slot="start">
                        <svg className="manuals-list__icon"><use xlinkHref={`#icon-${icon}`}></use></svg>
                    </div>
                    {title}
                    <IonIcon icon={arrowNext} slot="end"></IonIcon>
                </IonItem>
            ))}
            </IonList>
            {(device.DeviceOnline !== 1) &&
                <div className="popover__text">
                    <p>{props.t('device_settings_text')}</p>
                </div>
            }
            <div className="ion-content-push"></div>
        </IonContent>
        </IonPage>
    );
};

const mapStateToProps = state => {
    return {
        devices: state.oldDevices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(DeviceSettings);