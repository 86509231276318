import React from 'react';
import { Route, Switch} from 'react-router-dom';
import ChooseDeviceGroup from './ChooseDeviceGroup';
import AddBoiler from './AddBoiler';
import AddHeatPump from './AddHeatPump';
import AddConvector from './AddConvector';

import B15 from './B15/B15';
import ChooseDeviceB15 from './B15/ChooseDevice';
import FinishB15 from './B15/Finish';

import E32 from './E32/E32';
import ChooseDeviceE32 from './E32/ChooseDevice';
import FinishE32 from './E32/Finish';

import Bellislimo from './OldDevices/Bellislimo';
import ModecoC21 from './OldDevices/ModecoC21';
import ModecoC22 from './OldDevices/ModecoC22';
import HeatEcoConvector from './OldDevices/HeatEcoConvector';
import OldAppAccountSettings from './OldDevices/OldAppAccountSettings';
import OldAppChooseDevice from './OldDevices/ChooseDevice';

const Routes = () => {

    return (
        <Switch>
            
            {/* MANAGE PRODUCTION MODE: ROUTES*/}
            <Route exact path="/production-mode/choose-device-group" component={ChooseDeviceGroup}/>
            <Route exact path="/production-mode/add-boiler" component={AddBoiler}/>
            <Route exact path="/production-mode/add-heat-pump" component={AddHeatPump}/>
            <Route exact path="/production-mode/add-convector" component={AddConvector}/>

            {/* MANAGE PRODUCTION MODE CONNECT B15: ROUTES*/}
            <Route exact path="/production-mode/b15" component={B15}/>
            <Route exact path="/production-mode/b15/choose-device" component={ChooseDeviceB15}/>
            <Route exact path="/production-mode/b15/finish" component={FinishB15}/>

            {/* MANAGE PRODUCTION MODE CONNECT E32: ROUTES*/}
            <Route exact path="/production-mode/e32" component={E32}/>
            <Route exact path="/production-mode/e32/choose-device" component={ChooseDeviceE32}/>
            <Route exact path="/production-mode/е32/finish" component={FinishE32}/>

            {/* MANAGE PRODUCTION MODE CONNECT OldDevices: ROUTES*/}
            <Route exact path="/production-mode/modecoc21" component={ModecoC21}/>
            <Route exact path="/production-mode/modecoc22" component={ModecoC22}/>
            <Route exact path="/production-mode/bellislimo" component={Bellislimo}/>
            <Route exact path="/production-mode/heatecoconvector" component={HeatEcoConvector}/>

            <Route exact path="/production-mode/old-app-account-settings" component={OldAppAccountSettings}/>
            <Route exact path="/production-mode/choose-device" component={OldAppChooseDevice}/>

        </Switch>
    );
}

export default Routes;