import {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonIcon
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import Header from './components/header/Header';
import PageHeader from './components/header/PageHeader';
import Footer from './components/footer/Footer';
import DashboardMenu from './components/menu/DashboardMenu';
import arrowNext from '../../assets/images/arrow-list.svg';
import deviceImage_1 from '../../temp-files/CN06_new.jpg';
import deviceImage_4 from '../../temp-files/CN031_new.jpg';
import deviceImage_5 from '../../temp-files/CN051_new.jpg';
import deviceImage_6 from '../../temp-files/CN052_new.jpg';
import deviceImage_7 from '../../temp-files/CN04_new.jpg';
import deviceImage_8 from '../../temp-files/CN03_new.jpg';

const ConvectorsTutorials = props => {

    return (
        <IonPage>
            <Header />
            <IonContent className="ion-no-padding">
                <div className="dashboard">
                    <div className="dashboard__wrapper">

                        <DashboardMenu activeMenu="manuals" />

                        <div className="dashboard__content">
                            
                            <PageHeader name={props.t('choose_convector')} image="manuals"/>
                            
                            <div className="dashboard-box">
                                <div className="dashboard-box__body">
                                    <IonList className="manuals-list">
                                        <IonItem button routerLink="/model-tutorials/cn06" className="manuals-list__item">
                                            <div slot="start">
                                                <img src={deviceImage_1}/>
                                            </div>
                                            {props.t('Convector')}
                                            <IonIcon icon={arrowNext} slot="end"></IonIcon>
                                        </IonItem>
                                        <IonItem button routerLink="/model-tutorials/cn031" className="manuals-list__item">
                                            <div slot="start">
                                                <img src={deviceImage_4}/>
                                            </div>
                                            {props.t('Convector CN031')}
                                            <IonIcon icon={arrowNext} slot="end"></IonIcon>
                                        </IonItem>
                                        <IonItem button routerLink="/model-tutorials/cn051" className="manuals-list__item">
                                            <div slot="start">
                                                <img src={deviceImage_5}/>
                                            </div>
                                            {props.t('Convector CN051')}
                                            <IonIcon icon={arrowNext} slot="end"></IonIcon>
                                        </IonItem>
                                        <IonItem button routerLink="/model-tutorials/cn052" className="manuals-list__item">
                                            <div slot="start">
                                                <img src={deviceImage_6}/>
                                            </div>
                                            {props.t('Convector CN052')}
                                            <IonIcon icon={arrowNext} slot="end"></IonIcon>
                                        </IonItem>
                                        <IonItem button routerLink="/model-tutorials/cn03" className="manuals-list__item">
                                            <div slot="start">
                                                <img src={deviceImage_8}/>
                                            </div>
                                            {props.t('Convector HeatEco CN03')}
                                            <IonIcon icon={arrowNext} slot="end"></IonIcon>
                                        </IonItem>
                                        <IonItem button routerLink="/model-tutorials/cn04" className="manuals-list__item">
                                            <div slot="start">
                                                <img src={deviceImage_7}/>
                                            </div>
                                            {props.t('​​​​​​​Convector ConvEco CN04')}
                                            <IonIcon icon={arrowNext} slot="end"></IonIcon>
                                        </IonItem>
                                    </IonList>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </IonContent>
            <Footer />
        </IonPage>
    );
};

const mapStateToProps = state => {
    return {
        app: state.app
    }
}


export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(ConvectorsTutorials);
  