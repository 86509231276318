import {
    IonPage,
    IonContent,
    IonToolbar,
    IonButton,
    IonList,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonToggle,
    IonFooter,
    IonGrid,
    IonRow,
    IonCol,
    IonModal,
    IonButtons,
    IonText,
    IonAlert,
    IonItem,
    IonLabel,
    IonCheckbox
} from '@ionic/react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

import StandartHeader from '../components/header/StandartHeader';
import Week from '../components/week/Week';
import Loading from '../components/loading/Loading';
import B15MqttHelper from "../../../helpers/mqqt/B15MqttHelper";
import ProgramHelper from './helpers/ProgramHelper';
import ToastMessage from '../components/toast/ToastMessage';

import { setAppMessage } from '../../../redux/actions/AppAction';

const Program = props => {

    const { t } = useTranslation();
    const [selectedDay, setSelectedDay] = useState(0);
    const [showWeekModal, setShowWeekModal] = useState(false);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [showDeleteZoneAlert, setShowDeleteZoneAlert] = useState(false);
    const [deletedZone, setDeletedZone] = useState(null);
    const [showLoading, setShowLoading] = useState(false);
    const [weekDays, setWeekDays] = useState({
        0: {
          'name': t('monday_full'),
          'checked': true
        },
        1: {
          'name': t('tuesday_full'),
          'checked': true
        },
        2: {
          'name': t('wednesday_full'),
          'checked': true
        },
        3: {
          'name': t('thursday_full'),
          'checked': true
        },
        4: {
          'name': t('friday_full'),
          'checked': true
        },
        5: {
          'name': t('saturday_full'),
          'checked': true
        },
        6: {
          'name': t('sunday_full'),
          'checked': true
        }
    });

    const { 
        devices, 
        match: {
            params: {
                mac_address: mac, 
                day
            }
        } 
    } = props;
    const device = devices[mac];
    const {state: deviceState} = device;
    const B15MqttHelperOBJ = new B15MqttHelper(mac);
    const programHelperOBJ = new ProgramHelper(mac, props.match.params.program);

    useEffect(() => {
        let currentDay = dayjs().day();
        const daysOfWeek = [6, 0, 1, 2, 3, 4, 5];

        setSelectedDay(props.match.params.day != undefined ? props.match.params.day : daysOfWeek[currentDay])
    }, []);

    const copyProgram = () => {
        setShowLoading(true);

        const deviceProgram = deviceState[`program_${props.match.params.program}`];
        const programForTheDay = programHelperOBJ.getProgramForTheDay(selectedDay);

        const programDays = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

        for (const wDay in weekDays) {

            if (weekDays[wDay].checked === true) {
                const day =  programDays[wDay];  
                
                deviceProgram[day] = programForTheDay;
            }
        }

        B15MqttHelperOBJ.request('setWeeklyProgram', {progID: props.match.params.program, weekday: deviceProgram});
        setShowLoading(false);
        setShowWeekModal(false);
    }

    const checkDay = (selectedDay, status) => {
        setWeekDays({...weekDays, [selectedDay]:{...weekDays[selectedDay], checked: status}})
    }

    const deleteProgram = () => {

        const deviceProgram = deviceState[`program_${props.match.params.program}`];

        for (const day in deviceProgram) {
            deviceProgram[day] = programHelperOBJ.defaultProgramObject();
        }

        B15MqttHelperOBJ.request('setWeeklyProgram', {progID: props.match.params.program, weekday: deviceProgram});
        setShowDeleteAlert(false);
    }

    const addProgram = () => {
        if (!device.state[`program_${props.match.params.program}`]) {
            props.history.push(`/device/program/create-program/${device.model.toLowerCase()}/${mac}/${selectedDay}/${props.match.params.program}`);
            return;
        }

        if (programHelperOBJ.getZones(selectedDay) < 4) {
            props.history.push(`/device/program/create-program/${device.model.toLowerCase()}/${mac}/${selectedDay}/${props.match.params.program}`)
        } else {
            props.setAppMessage(t('dt2_invalid_program_allowed_4_zones'));
        }
    }

    const deleteZone = () => {
        const deviceProgram = deviceState[`program_${props.match.params.program}`];
        const programDays = programHelperOBJ.getProgramDays();
        deviceProgram[programDays[selectedDay]][deletedZone] = {
            "from":"00:00",
            "to":"00:00",
            "temp":75,
        }

        deviceProgram[programDays[selectedDay]] = programHelperOBJ.sortProgramForTheDay(deviceProgram[programDays[selectedDay]]);

        B15MqttHelperOBJ.request('setWeeklyProgram', {progID: props.match.params.program, weekday: deviceProgram});
    }

    const confirmDeleteZone = zone => {
        setShowDeleteZoneAlert(true); 
        setDeletedZone(zone);
    }

    const renderWeek = () => {

        const days = [];

        for (const day in weekDays) {

            let {checked, name} = weekDays[day];

            if (selectedDay == day) {
                checked = false;
            }

            days.push(
                <IonItem lines="full" key={day}>
                    <IonLabel className="form-checkbox">
                        <IonCheckbox color="secondary" className="form-checkbox__element" name="checkboxName" checked={checked} onIonChange={e => checkDay(day, e.detail.checked)}/>
                        <div className="form-checkbox__text">{name}</div>
                    </IonLabel>
                </IonItem>
            );
        }

        return days;
    }

    const renderProgram = () => {

        if (!device.state[`program_${props.match.params.program}`]) {
            return null;
        }

        const programList = [];
        const programForTheDay = programHelperOBJ.getProgramForTheDay(selectedDay);

        for (const key in programForTheDay) {
            const {to, from, temp} = programForTheDay[key];

            if (from != "00:00" || to != "00:00") {
                programList.push(
                    <IonItem className="preferences-list__item" lines="none" key={key}>
                        <div className="preferences-list__inner">
                            <div className="preferences-list__item-start">
                                <div className="preferences-list__time">
                                    <svg className="preferences-list__time-icon"><use xlinkHref="#icon-heating"></use></svg>
                                    {from}{t('hour_abbr')} - {to}{t('hour_abbr')}
                                </div>
                            </div>
                            <div className="preferences-list__item-middle">
                                <div className="preferences-list__temperature">
                                    <svg className="preferences-list__temperature-icon"><use xlinkHref="#icon-idle"></use></svg>
                                    {temp} °C
                                </div>
                            </div>
                            <div className="preferences-list__item-end">
                                <IonButton color="icon" onClick={() => confirmDeleteZone(key)}>
                                    <svg><use xlinkHref="#icon-delete" className="fill-red"></use></svg>
                                </IonButton>
                            </div>
                        </div>
                    </IonItem>
                );
            }
        }

        return programList;
    }

    return (
        <IonPage>
            <StandartHeader name={`${t('program')} ${props.match.params.program.toUpperCase()}`}/>
    
            <IonContent className="ion-padding">
    
                <IonCard>
                    <IonCardHeader className="card-list__header">
                        <div className="card-list__header-left">
                            <div style={{ marginTop: '0' }}>
                                <img src={device.model_image} alt="" />
                            </div>
                            <div>
                                <div className="card-list__title">{device.deviceName}</div>
                            </div>
                        </div>
                        <div className="card-list__header-right"></div>
                    </IonCardHeader>
                </IonCard>
                
                <Week setSelectedDay={day => setSelectedDay(day)} defaultDay={selectedDay}/>

                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-start">
                            <IonButton color="confirm" onClick={() => setShowWeekModal(true)}>{t('save_porgram_to_all_days')}</IonButton>
                            <IonModal isOpen={showWeekModal} onDidDismiss={() => setShowWeekModal(false)}>
                                <IonToolbar>
                                    <div slot="end">
                                    <IonButtons>
                                        <IonButton onClick={() => setShowWeekModal(false)}>
                                        <svg className="size-14"><use xlinkHref="#icon-close"></use></svg>
                                        </IonButton>
                                    </IonButtons>
                                    </div>
                                </IonToolbar>
                                <div className="ion-padding">
                                    <IonText>
                                    <h2 className="ion-text-center">{t('choose_days_to_copy_program')}</h2>

                                    <IonList className="ion-padding-top">
                                        {renderWeek()}
                                    </IonList>
                                    </IonText>
                                </div>
                                <IonFooter className="app-footer ion-no-border">
                                    <IonToolbar>
                                        <div className="buttons-list">
                                            <div className="buttons-list__item">
                                                <IonButton expand="block" shape="round" size="large" onClick={copyProgram}>{t('save')}</IonButton>
                                            </div>
                                        </div>
                                    </IonToolbar>
                                </IonFooter>
                            </IonModal>
                        </IonCol>
                        <IonCol className="ion-text-end">
                            <IonButton color="delete" onClick={() => setShowDeleteAlert(true)}>{t('delete_program')}</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
        
                <div className="preferences-list__wrapper">
                    <IonList className="preferences-list">
                        {renderProgram()}   
                    </IonList>
                </div>
                <div className="ion-content-push"></div>

                <Loading show={showLoading}/>

                <IonAlert
                    isOpen={showDeleteAlert}
                    onDidDismiss={() => setShowDeleteAlert(false)}
                    cssClass='my-custom-class'
                    header={t('confirm')}
                    message={t('confirm_programs_delete')}
                    buttons={[
                        {
                            text: t('cancel'),
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: blah => {
                                setShowDeleteAlert(false)
                            }
                        },
                        {
                            text: t('ok'),
                            handler: () => {
                                deleteProgram();
                            }
                        }
                    ]}
                />

                <IonAlert
                    isOpen={showDeleteZoneAlert}
                    onDidDismiss={() => setShowDeleteZoneAlert(false)}
                    cssClass='my-custom-class'
                    header={t('confirm')}
                    message={t('dt2_confirm_program_zone_delete')}
                    buttons={[
                        {
                            text: t('cancel'),
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: blah => {
                                setShowDeleteZoneAlert(false)
                            }
                        },
                        {
                            text: t('ok'),
                            handler: () => {
                                deleteZone();
                            }
                        }
                    ]}
                />

                <ToastMessage />
            </IonContent>
    
            <IonFooter className="app-footer ion-no-border">
                <IonToolbar>
                    <div className="buttons-list">
                        <div className="buttons-list__item">
                            <IonButton expand="block" shape="round" size="large" onClick={addProgram}>{t('add_preference')}</IonButton>
                        </div>
                    </div>
                </IonToolbar>
            </IonFooter>
        </IonPage>
    );
}

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    connect(mapStateToProps, {
        setAppMessage
    })
)(Program);