
import {
    FETCH_OLD_DEVICES,
    UPDATE_OLD_DEVICE_STATE
} from '../types/OldDevicesTypes';

const OldDevicesReducer = (devices = {}, action) => {

    switch(action.type) {

        case FETCH_OLD_DEVICES:
            return action.payload

        case UPDATE_OLD_DEVICE_STATE:

            let {state, field, DeviceSerial} = action.payload;
            devices[DeviceSerial].DeviceStatus[field]= state;

            return {...devices};

        default: 
            return devices;
    }
}

export default OldDevicesReducer;