import React from 'react';
import {
    IonContent,
    IonToolbar,
    IonPage,
    IonFooter,
    IonButtons,
    IonButton,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonAlert
} from '@ionic/react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import HeaderWithPopoverMenu from './components/HeaderWithPopoverMenu';
import ManualModeBody from './components/ManualModeBody';
import ManualModeFooter from './components/ManualModeFooter';
import ProgramModeBody from './components/ProgramModeBody';
import ProgramModeFooter from './components/ProgramModeFooter';
import VacationModeBody from './components/VacationModeBody';
import VacationModeFooter from './components/VacationModeFooter';
import PowerButton from './components/PowerButton';

import HeatPumpHelper from "../../../helpers/HeatPumpHelper";
import HeatPumpMqttHelper from "../../../helpers/mqqt/HeatPumpMqttHelper";

class ManagePage extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {
            deviceMode: null,
            deviceInitialMode: null
        }
    }

    componentDidMount = () => {

        this.HeatPumpMqttHelperOBJ = new HeatPumpMqttHelper(this.mac);

        if (this.device) {
            this.setState({
                deviceMode: this.HeatPumpHelperOBJ.getMainMode(),
                deviceInitialMode: this.HeatPumpHelperOBJ.getMainMode()
            })
        }
        
    }

    componentDidUpdate = () => {


        if (this.device) {

            const currMode = this.HeatPumpHelperOBJ.getMainMode();

            //WHEN MODE IS CHANGED FROM DEVICE
            if (this.state.deviceInitialMode != currMode) {
                this.setState({
                    deviceMode: currMode,
                    deviceInitialMode: currMode,
                })
            }
        }
    }
    
    render = () => {

        const { t } = this.props;
        const {devices} = this.props;

        this.mac = this.props.match.params.mac_address;
        this.device = devices[this.mac];

        this.HeatPumpHelperOBJ = new HeatPumpHelper(this.device);

        if (!this.device || !this.state.deviceMode) {
            return null;
        }

        const {state: deviceState} = this.device;

        let disableDevice = false;
        if (deviceState.power_status === 'off' || this.device.hasInternet == false) {
            disableDevice = true;
        }
 
        const {hasFan, hasBoost} = this.HeatPumpHelperOBJ.getCurrentModeSettings();

        return (
            <IonPage>

                <HeaderWithPopoverMenu 
                    name={this.device.deviceName} 
                    mac={this.mac}
                    device = {this.device}
                />

                <IonContent className="ion-padding">
                    
                    <IonToolbar>
                        <IonSegment value={this.state.deviceMode} onIonChange={e => this.setState({deviceMode: e.detail.value})}>
                            <IonSegmentButton value="manual">
                                <IonLabel>{t('hp_manage_page_manual_control')}</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="program">
                                <IonLabel>{t('hp_manage_page_program')}</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="vacation">
                                <IonLabel>{t('hp_manage_page_vacation')}</IonLabel>
                            </IonSegmentButton>
                        </IonSegment>
                    </IonToolbar>
                    
                    <div className="device-controls">
                        <div className="program-buttons">
                        <div className="program-buttons__item">
                                <IonButton 
                                    className={`program-button ${deviceState.boost == "on" ? "active": ""}`} 
                                    disabled={(hasBoost == false || disableDevice) ? true : false}
                                    onClick={() => this.HeatPumpMqttHelperOBJ.request('setBoost', {state: deviceState.boost == 'on' ? 'off' : 'on'})}
                                >
                                    <svg className="program-button__icon">
                                        <use xlinkHref="#icon-boost"></use>
                                    </svg>
                                    {t('hp_boost')}
                                </IonButton>
                            </div>
                            <div className="program-buttons__item">
                                <IonButton 
                                    className={`program-button ${deviceState.fan == "on" ? "active": ""}`}
                                    disabled={(hasFan == false || disableDevice) ? true : false}
                                    onClick={() => this.HeatPumpMqttHelperOBJ.request('setFan', {state: deviceState.fan == 'on' ? 'off' : 'on'})}
                                >
                                    <svg className="program-button__icon">
                                        <use xlinkHref="#icon-fan"></use>
                                    </svg>
                                    {t('hp_fan')}
                                </IonButton>
                            </div>
                        </div>

                        <div className={`device-controls__label ${this.device.hasInternet !== true ? 'active' : ''}`}> {t('offline')}</div>

                        <PowerButton mac={this.mac} power={deviceState.power_status} />
                    </div>

                    <div className="device-mode">
                        <div className="device-mode__start">
                            <svg className={`device-mode__icon fill-light-green ${disableDevice ? 'disabled' : ''}`}>
                                <use xlinkHref={`#${this.HeatPumpHelperOBJ.getModeIcon()}`}></use>
                            </svg>
                        </div>
                        <div className="device-mode__end">
                            <div className={`range-circle range-circle--alt ${deviceState.heating == "off" ? 'range-circle--ready' : ''} ${disableDevice ? 'disabled' : ''}`}> {/* Add class 'range-circle--ready' when device is ready OR 'disabled' when device is disabled */}
                                <div className="range-circle__inner">
                                    {deviceState.disinfection == "on" && 
                                        <div className="text-red text-normal range-circle__label ">{t('hp_anti_legionela')}</div>
                                    }
                                    <div>
                                        <svg className={`range-circle__icon range-circle__icon--alt ${deviceState.defrost == "on" ? "fill-blue" : "fill-gray"}`}>
                                            <use xlinkHref="#icon-desfrost"></use>
                                        </svg>
                                        <svg className={`range-circle__icon range-circle__icon--alt  ${deviceState.eHeaterInd == "on" ? "fill-red" : "fill-gray"}`}>
                                            <use xlinkHref="#icon-el-pv"></use>
                                        </svg>
                                        {/* TO DO: Show correct connectivity */}
                                        <svg className={`range-circle__icon range-circle__icon--alt ${deviceState.connectivity == "on" ? "fill-black" : "fill-gray"}`}>
                                            <use xlinkHref="#icon-solar-boiler"></use>
                                        </svg>
                                    </div>
                                    <div className="range-circle__temperature">{deviceState.current_temp}° C</div>
                                    <div className="range-circle__label">{t('hp_manage_page_current_temp')}</div>

                                    {/* TO DO: When is heating and when is ready? */}

                                    <div className="range-circle__status">
                                        <div className="range-circle__status-list">
                                            <div className="range-circle__status-list-item">
                                                <svg className={`size-16 ${deviceState.shower == "on" ? 'fill-green' : 'fill-gray'}`}>
                                                    <use xlinkHref="#icon-shower"></use>
                                                </svg>
                                            </div>
                                            <div className="range-circle__status-list-item">
                                                <svg className={`size-16 ${deviceState.heating == "on" ? "fill-red" : "fill-green"}`}>
                                                    <use xlinkHref="#icon-idle"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        {deviceState.heating == "on" ? t('heating') : t('ready')}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.deviceMode == "manual" && 
                        <ManualModeBody disableDevice={disableDevice} device={this.device} mac={this.mac}/>
                    }

                    {this.state.deviceMode == "program" && 
                        <ProgramModeBody disableDevice={disableDevice} device={this.device}/>
                    }

                    {this.state.deviceMode == "vacation" && 
                        <VacationModeBody disableDevice={disableDevice} device={this.device}/>
                    }

                    <div className="ion-content-push"></div>
                </IonContent>
                
                <IonFooter className="app-footer ion-no-border">

                    {this.state.deviceMode == "manual" && 
                        <ManualModeFooter disableDevice={disableDevice} device={this.device} mac={this.mac}/>
                    }
                    
                    {this.state.deviceMode == "program" && 
                        <ProgramModeFooter 
                            disableDevice={disableDevice} 
                            device={this.device} 
                            mac={this.mac} 
                            changeMainMode={mode => this.setState({deviceMode: mode})}
                        />
                    }

                    {this.state.deviceMode == "vacation" && 
                        <VacationModeFooter 
                            disableDevice={disableDevice} 
                            device={this.device} 
                            mac={this.mac}
                            changeMainMode={mode => this.setState({deviceMode: mode})}
                        />
                    }

                </IonFooter>
            </IonPage>
        )
    }
}

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(ManagePage);