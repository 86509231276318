import axios from 'axios';
import {
    rest_api_address,
    app_mode,
    dev_rest_api_address
} from '../config/App';
import LocalStorageHerlper from './LocalStorageHelper';
import store from '../redux/createStore';
import { logger } from '../redux/actions/AppAction';

export default class RestHelper {

    constructor() {

        this.axios_instance = axios.create({
            baseURL: app_mode === 'development' ? dev_rest_api_address : rest_api_address
        });

        // this.userData = {
        //     userID: LocalStorageHerlper.get('user.id'),
        //     userEmail: LocalStorageHerlper.get('user.email'),
        //     userPass: LocalStorageHerlper.get('user.password'),
        // }

    }

    getUserData = async() => {
        const userID = await LocalStorageHerlper.get('user.id');
        const userEmail = await LocalStorageHerlper.get('user.email');
        const userPass = await LocalStorageHerlper.get('user.password');

        return {
            userID, userEmail, userPass
        };
    }

    get = async (url, payload = {}) => {
        
        const state = store.getState();
        const userData = await this.getUserData();

        try {
            return await this.axios_instance.get(url, {
                    params: {
                        ...payload,
                        //...this.userData,
                        ...userData,
                        lang: state.app.lang
                    }
                });
        } catch (e) {
            global.debug("GET Request Failed")
            return {data: {}}
        }
    }

    post = async (url, payload) => {
        
        try {

            const state = store.getState();
            const userData = await this.getUserData();

            return await this.axios_instance.post(url , {
                ...payload,
                //...this.userData,
                ...userData,
                lang: state.app.lang
            });
        } catch (e) {
            global.debug("POST Request Failed")
            return {data: {}}
        }
    }
}