import {
    IonContent,
    IonPage,
    IonText,
} from '@ionic/react';

import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { Device } from '@capacitor/device';

import StandartHeader from './components/header/StandartHeader';
import ToastMessage from './components/toast/ToastMessage';
import TwoButtons from './components/footer/TwoButtons';
import chooseDeviceImage from '../../files/chooseDevice.svg';
import { setDeviceLocalUsage } from '../../redux/actions/DevicesAction';

class StopLocalUsage extends React.Component {

    constructor(props) {
        super(props);

        this.device = null;
        this.mac = null;
    }


    save = () => {
        this.props.setDeviceLocalUsage(false, this.mac);
        this.props.history.goBack();
    }

    render = () => {

        const { t, match: {params: {mac_address}} } = this.props;
        this.mac = mac_address;

        return (
            <IonPage>
            
                <StandartHeader name={this.props.t('stop_local_usage')} />
                <IonContent className="ion-padding">
                    <div className="circle-image circle-image--md">
                        <img src={chooseDeviceImage}/>
                    </div>
                    {/* TO DO: Ask for text */}
                    <IonText>
                        <h1>{this.props.t('local_usage')} </h1>
                        <p>{this.props.t('stop_local_control_text1')}</p>
                        <p>{this.props.t('stop_local_control_text2')}</p>
                        <p>{this.props.t('stop_local_control_text3')}</p>
                        <p>{this.props.t('stop_local_control_text4')}</p>
                    </IonText>
                    <ToastMessage />
                    <TwoButtons save={this.save} nameOfSaveButton={this.props.t('stop_local_usage')}/>
                </IonContent>
            </IonPage>
        );
    }
    
};

export default compose(
    withTranslation(),
    connect(null, {
        setDeviceLocalUsage
    })
)(StopLocalUsage);
  