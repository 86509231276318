import {
    IonAlert,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

import HeatPumpHelper from "../../../../helpers/HeatPumpHelper";
import HeatPumpMqttHelper from "../../../../helpers/mqqt/HeatPumpMqttHelper";
import {HPWH4_11_260_Settings} from '../../../../config/HPWH4_11_260';

const ChangeDeviceMode = ({device, newMode, mac, cancelChange, changeMainMode, changeMode}) => {

    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const { t } = useTranslation();
    const HeatPumpMqttHelperOBJ = new HeatPumpMqttHelper(mac);
    const HeatPumpHelperOBJ = new HeatPumpHelper(device);

    useEffect(() => {

        if (newMode) {
            setAlertIsOpen(true);
        }

    }, [newMode]);

    const closeAlert = () => {
        setAlertIsOpen(false);
        cancelChange(null)
    }

    return (
        <IonAlert
            isOpen={alertIsOpen}
            onDidDismiss={closeAlert}
            header={t('hp_change_mode_header', {mode: t(`hp_mode_${newMode}`)})}
            message={t('hp_change_mode_text', {mode: t(`hp_mode_${HeatPumpHelperOBJ.getMode()}`)})}
            buttons={
                [{
                    text: t('cancel'),
                    handler: () => {
                        closeAlert();
                    }
                },
                {
                    text: t('ok'),
                    handler: () => {

                        if (changeMode != undefined) {
                            changeMode();
                        } else {

                            if (newMode == "program") {
                                HeatPumpMqttHelperOBJ.request('timerOnOff', {state: "on"})
                            } else if (newMode == "hp" && device.state.temp > 65) {
                                HeatPumpMqttHelperOBJ.request('setMode', {mode: newMode, setTemp: HPWH4_11_260_Settings.modes.hp.maxTemp})
                            } else {
                                //HeatPumpMqttHelperOBJ.request('timerOnOff', {state: "off"})
                                HeatPumpMqttHelperOBJ.request('setMode', {mode: newMode, setTemp: device.state.temp})
                            }
                            
                            if (newMode == "hp" && changeMainMode != undefined) {
                                changeMainMode("manual")
                            }

                        }
                        
                    }
                }]
            }
        />
    )
}

export default ChangeDeviceMode;