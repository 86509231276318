import arrowBack from '../../../../assets/images/arrow-back.svg';

import {
    IonHeader,
    IonBackButton,
    IonButtons,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import React from 'react';
import { withRouter} from 'react-router-dom';

const Standartheader = props => {
    return (
        <IonHeader className="app-header">
            <IonToolbar className="ion-no-padding">
                <IonButtons slot="start">
                    <IonBackButton icon={arrowBack} text=""></IonBackButton>
                </IonButtons>
                <IonTitle>{props.name}</IonTitle>
                {(props.hideHome == undefined || !props.hideHome) && 
                    <IonButtons slot="end" onClick={() => props.history.push(`/dashboard`)}>
                        <svg className="size-18 mr-16">
                            <use xlinkHref="#icon-home"></use>
                        </svg>
                    </IonButtons>
                }
            </IonToolbar>
        </IonHeader>
    )
}

export default withRouter(Standartheader);