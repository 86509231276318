import {
      IonDatetime
} from '@ionic/react';
import React from 'react';
import { withTranslation } from 'react-i18next';

const IonDateTimeComponent = props => {

    return (
        <React.Fragment>
            <div className="preferences-list__inner">
                <header className="range-slider__header mb-0">
                    <div className="range-slider__header-item">
                        <div className="range-slider__header-label">{props['data-label']}</div>
                    </div>
                </header>
                <IonDatetime 
                    pickerOptions={{
                        buttons: [
                            {
                                text: props.t('cancel'),
                            }, {
                                text: props.t('ok'),
                                handler: (e) => {
                                    props.setTime(e.hour.text + ':' + e.minute.text);
                                }
                            }
                        ]
                    }}
                    displayFormat={props['data-format'] !== undefined ? props['data-format'] : "HH:mm"}
                    pickerFormat={props['data-format'] !== undefined ? props['data-format'] : "HH:mm"}
                    minuteValues={props['data-minutes'] !== undefined ? props['data-minutes'] : "0, 30"}
                    hourValues={props['data-hours'] !== undefined ? props['data-hours'] : undefined}
                    value={props['data-start'] !== undefined ? props['data-start'] : "00:00"}
                >
                </IonDatetime>
            </div>
        </React.Fragment>
    )    
}

export default withTranslation()(IonDateTimeComponent);

