import {
    IonContent,
    IonFooter,
    IonButton,
    IonPage,
    IonToolbar,
    IonText,
    IonList,
    IonItem,
    IonRow,
    IonLabel,
    IonInput
} from '@ionic/react';

import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { Device } from '@capacitor/device';


import {
    chooseNetwork
} from '../../redux/actions/NewDeviceAction';
import { setAppMessage } from '../../redux/actions/AppAction';

import StandartHeader from './components/header/StandartHeader';
import ToastMessage from './components/toast/ToastMessage';
import WifiHelper from '../../helpers/WifiHelper';
import connectToWifiImage from '../../files/connectToWifi.svg';

class ConnectToWiFi extends React.Component {

    state = {
        myNetwork: "",
        appInfo: {}
    }

    componentDidMount = async () => {
        
        const info = await Device.getInfo();

        this.setState({
            appInfo: info
        })

        this.setState({
            myNetwork: this.props.newDevice.networkSSID 
        })
        // try {
        //     const currentNetwork = await WifiHelper.getConnectedSSID();
        //     this.setState({
        //         myNetwork: currentNetwork
        //     });
        //     global.debug(`Current network`, currentNetwork)
        // } catch (e) {
        //     global.debug(`Can't detect current network`, e);
        // }
    }


    continue = () => {

        if (this.state.myNetwork !== '') {

            this.props.chooseNetwork(this.state.myNetwork);
            this.props.history.push("/wifi-password");

        } else {
            this.props.setAppMessage(this.props.t('required_network'));
        }
        
    }

    render = () => {

        const { t } = this.props;

        return (
            <IonPage>
            
            <StandartHeader name={t('connect_to_wifi')}/>

            <IonContent className="ion-padding">
                <div className="circle-image">
                    <img src={connectToWifiImage} />
                </div>
                <IonText>
                <p>{t('connect_to_network')}</p>
                </IonText>

                <IonList className="form-list">
                {/* {this.state.appInfo.platform === 'ios' ? 
                    <IonItem lines="full" className="form-list__item">
                        <IonLabel position="stacked" color="primary" className="form-label"></IonLabel>
                        <IonInput 
                            type="text" 
                            className="form-input" 
                            placeholder={t('select_network_ios')} 
                            onIonChange={ e => this.setState({myNetwork: e.detail.value})}
                        />
                    </IonItem> :
                    <IonItem className="form-list__item">
                        <div>
                            <IonRow className="ion-align-items-center ion-nowrap" style={{ fontSize: '16px', wordBreak: 'break-all' }}>
                            <svg className="size-24 fill-green" style={{ marginRight: '11px'}}><use xlinkHref="#icon-wifi"></use></svg>
                            <span>{this.state.myNetwork}</span>
                            </IonRow>
                        </div>
                        <div slot="end">
                            <IonButton size="small" color="link-secondary" routerLink={`/change-wifi/${this.state.myNetwork}`}>{t('change')}</IonButton>
                        </div>
                    </IonItem>
                } */}
                    <IonItem className="form-list__item">
                        <div>
                        <IonLabel position="stacked" color="primary" className="form-label"></IonLabel>
                        <IonInput 
                            type="text" 
                            className="form-input" 
                            value={this.state.myNetwork}
                            placeholder={t('select_network_ios')} 
                            onIonInput={ e => this.setState({myNetwork: e.detail.value})}
                        />
                        </div>
                        <div slot="end">
                            <IonButton size="small" color="link-secondary" routerLink={`/change-wifi/${this.state.myNetwork}`}>{t('change')}</IonButton>
                        </div>
                    </IonItem>
                </IonList>

                <ToastMessage />
            </IonContent>
            
            <IonFooter className="app-footer ion-no-border">
                <IonToolbar>
                    <div className="buttons-list">
                        <div className="buttons-list__item">
                            <IonButton expand="block" shape="round" size="large" onClick={this.continue}>{t('continue')}</IonButton>
                        </div>
                        {/* TO DO: if it's neccessary to have HotSpot */}
                        <div className="buttons-list__item">
                            <IonButton expand="block" shape="round" size="large" color="help">Don’t want to use Internet</IonButton>
                        </div>
                    </div>
                </IonToolbar>
            </IonFooter>
            </IonPage>
        );
    }
};

const mapStateToProsp = state => {
    return {
        newDevice: state.newDevice
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProsp,{
        chooseNetwork,
        setAppMessage
    })
)(ConnectToWiFi);
