import {
  IonContent,
  IonFooter,
  IonButton,
  IonItem,
  IonList,
  IonPage,
  IonToolbar,
  IonLabel,
  IonInput
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

//Import components
import StandartHeader from './components/header/StandartHeader';
import ToastMessage from './components/toast/ToastMessage';
import Loading from './components/loading/Loading';
import InitialSetttings from '../../init';

//Import helpers
import UsersHelper from '../../helpers/UsersHelper';
import RestHelper from '../../helpers/RestHelper';

//Import Redux Actions
import { setAppMessage } from '../../redux/actions/AppAction';


class Login extends React.Component {
  
  constructor (props) {

    super(props);

    this.UsersHelper = new UsersHelper();
    this.RestHelper = new RestHelper();

    this.state = {
      passwordShown: false,
      email: '',
      pass: '',
      errors: {},
      showLoading: false
    }
  }

  togglePasswordVisiblity = () => {
    this.setState({
      passwordShown: this.passwordShown ? false : true  
    })
  };

  login = async () => {
    
    this.setState({showLoading: true});

    try {

        const {isMyTesyUser, loginMyTesy, useTesyCloudCredentials, loginTesyCloud} = await this.UsersHelper.login({
            email: this.state.email,
            password: this.state.pass,
        });

        const initialSetttingsInstance = new InitialSetttings();
        initialSetttingsInstance.init();

        this.setState({showLoading: false});

        //global.debug({isMyTesyUser, loginMyTesy, useTesyCloudCredentials, loginTesyCloud});

        // const checUserHasRegistration = await this.UsersHelper.userHasRegistration({email: this.state.email});
    
        // await this.UsersHelper.login({
        //     email: this.state.email,
        //     password: this.state.pass,
        // });

        this.props.history.push('/dashboard');

    } catch (e) {
        this.setState({
            errors: e.errors,
            showLoading: false
        })

        if ('global' in e.errors) {
            this.props.setAppMessage(e.errors.global);
        }
    }
  }

  render = () => {

    const { t } = this.props;

    return (
      <IonPage>
        <StandartHeader name={t('login')} hideHome={true}/>
        <IonContent className="ion-padding">
          <IonList>
            <IonItem lines="full" className={`form-list__item ${'email' in this.state.errors ? 'is-invalid' : ''}`}>
              <IonLabel position="stacked" color="primary" className="form-label">{t('email')}</IonLabel>
              <IonInput type="email" className="form-input" onIonInput={ e => this.setState({email: e.detail.value})}/>
              {'email' in this.state.errors ? (<div className="invalid-message">{this.state.errors.email}</div>) : null}
            </IonItem>
            <IonItem lines="full" className={`form-list__item ${'password' in this.state.errors ? 'is-invalid' : ''}`}>
              <IonLabel position="stacked" color="primary" className="form-label">{t('password')}</IonLabel>
              <div className="form-password">
                <IonInput type={this.state.passwordShown ? "text" : "password"} className="form-input" onIonInput={ e => this.setState({pass: e.detail.value})}/>
                <button type="button" ion-button="true" className="form-password__button" onClick={this.togglePasswordVisiblity}>
                  <svg className="form-password__button-icon"><use xlinkHref={`#icon-eye-${this.state.passwordShown ? 'closed' : 'open'}`}></use></svg>
                </button>
              </div>
              {'password' in this.state.errors ? (<div className="invalid-message">{this.state.errors.password}</div>) : null}
            </IonItem>
            <IonItem lines="none">
              <IonButton expand="block" fill="clear" color="link" size="small" routerLink="/forgotten-password">{t('forgot_password')}</IonButton>
            </IonItem>
          </IonList>

          <ToastMessage />
          <Loading show={this.state.showLoading}/>
        </IonContent>
        
        <IonFooter className="app-footer ion-no-border">
            <IonToolbar>
              <div className="buttons-list">
                <div className="buttons-list__item">
                  <IonButton expand="block" shape="round" size="large" onClick={this.login}>{t('login')}</IonButton>
                </div>
              </div>
            </IonToolbar>
        </IonFooter>
      </IonPage>
    );
  }
};

export default compose(
  withTranslation(),
  connect(null, {setAppMessage})
)(Login)

 