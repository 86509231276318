import {
    IonAlert,
    IonRow,
    IonCol,
    IonToolbar,
    IonButtons,
    IonButton,
    IonLabel
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';

import CurrentModeInfo from './CurrentModeInfo';
import ChangeDeviceMode from './ChangeDeviceMode';

import HeatPumpHelper from "../../../../helpers/HeatPumpHelper";

const VacationMode = props => {

    const HeatPumpHelperOBJ = new HeatPumpHelper(props.device);
    const { t } = useTranslation();
    const vacInfo = HeatPumpHelperOBJ.getVacationInfo()

    const [mode, setMode] = useState(null);

    const setVacationMode = mode => {
        if (mode == "va") {
            setMode("hp");
        } else {
            props.history.push(`/device/vacation/${props.device.model.toLowerCase()}/${props.mac}`)
        }  
    }

    return (
        <React.Fragment>
            {HeatPumpHelperOBJ.getMode() != "va" &&
                <IonRow className="ion-align-items-center">
                    
                    <IonCol size='12' sizeMd='4'>
                        <CurrentModeInfo mode={HeatPumpHelperOBJ.getMode()} icon={HeatPumpHelperOBJ.getModeIcon()} disableDevice={props.disableDevice}/>
                    </IonCol>

                    <IonCol size='12' sizeMd='8'>
                        <div className="ion-text-center ion-text-md-left">
                            <div className={`device-mode__title mb-10`}>{t('hp_choose_your_vacation')}</div>
                            <IonButton color="primary" id="activate-alert" onClick={e => setVacationMode(HeatPumpHelperOBJ.getMode())}>
                                <span className="button__text">{HeatPumpHelperOBJ.getMode() == "va" ? t('deactivate') : t('activate')}</span>
                            </IonButton>
                            <IonButton color="tertiary" routerLink={`/device/vacation/${props.device.model.toLowerCase()}/${props.mac}`}>
                                <span className="button__text">{t('edit')}</span>
                            </IonButton>
                        </div>
                    </IonCol>
                </IonRow>
            }

            {HeatPumpHelperOBJ.getMode() == "va" &&
                <>
                    <div className="program-status program-status--alt">
                        <div className="program-status__col">
                            <div className="program-status__title program-status__title--primary">{t('hp_vacation_date_of_return')}</div>
                            <div className="program-status__subtitle program-status__subtitle--primary">{t(`hp_mode_${vacInfo.mode}`)}</div>
                            <div className="program-status__list">
                                <div className="program-status__list-item program-status__list-item--primary">
                                    <div className="program-status__list-top program-status__list-top">
                                        <svg className="program-status__list-icon"><use xlinkHref="#icon-idle"></use></svg> {vacInfo.temp}°
                                    </div>
                                    <div className="program-status__list-bottom">{vacInfo.dateOfReturn}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ion-text-center ion-padding-top">
                        <IonButton color="primary" id="activate-alert" onClick={e => setVacationMode(HeatPumpHelperOBJ.getMode())}>
                            <span className="button__text">{HeatPumpHelperOBJ.getMode() == "va" ? t('deactivate') : t('activate')}</span>
                        </IonButton>
                        <IonButton color="tertiary" routerLink={`/device/vacation/${props.device.model.toLowerCase()}/${props.mac}`}>
                            <span className="button__text">{t('edit')}</span>
                        </IonButton>
                    </div>
                </>
            } 

            <ChangeDeviceMode 
                device={props.device} 
                newMode={mode} 
                mac={props.mac}
                cancelChange={mode => setMode(mode)}
                changeMainMode={mode => props.changeMainMode(mode)}
            />

        </React.Fragment>
    )
}

export default withRouter(VacationMode);