import React from 'react';

import {
    IonFooter,
    IonToolbar,
} from '@ionic/react';

const Footer = props => {

    return (
        <>
            <IonFooter className="app-footer ion-no-border">
                <IonToolbar color="white">
                    {/* TO DO: Ask what text to place here */}
                    <span>&copy; Copyright 2010 - 2021 All rights reserved</span>
                </IonToolbar>
            </IonFooter>
        </>
    );
};

export default Footer;