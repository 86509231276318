import Http from './Http';
import Mqtt from './Mqtt';
import RestHelper from '../helpers/RestHelper';
import RequestLoaderHelper from '../helpers/RequestLoaderHelper';
import createModelInstance from '../models/modelsFactory';

export default ({device, macAddr, payload, command, requestType = 'request'}) => {
    
    const {
        model,
        token,
        localUsage
    } = device;

    let service = Mqtt;

    if (localUsage === true) {
        service = Http;
    }

    const object = createModelInstance(model, {macAddr, token, payload: JSON.stringify(payload), command});

    if (object.hasRequestLoader && !object.skipShowRequestLoaderForCommands.includes(command)) {
        const requestLoaderHelperOBJ = new RequestLoaderHelper(model);
        requestLoaderHelperOBJ.showRequestLoader();
    }
    
    service.publishMessage({
        model,
        token,
        macAddr,
        payload,
        requestType,
        command    
    });

    const restHelper = new RestHelper();

    if (command != "ping") {
        restHelper.post('/app-log', {
            mac: macAddr,
            command: command,
            payload: payload
        });
    }

}