import dayjs from 'dayjs';

import {HPWH4_11_260_Settings} from '../config/HPWH4_11_260';

class HeatPumpHelper
{
    constructor(device) {

        this.device = device;
    }

    getMode = () => {
        if (this.device.state.program_status == "on") {
            return "program"
        }  

        return this.device.state.mode ? this.device.state.mode : 'hp';
    }

    getModeIcon = (mode = null) => {

        const icons = {
            hp: "icon-heat-pump",
            eh: "icon-heater",
            au: "icon-auto",
            sm: "icon-smart",
            va: "icon-palm",
            program: "icon-timer-2"
        }

        if (mode) {
            return icons[mode];
        }

        return icons[this.getMode()];
    }
    
    getMainMode = () => {
        const mode = this.getMode();

        if (mode == "va") {
            return "vacation";
        }

        if (mode == "program") {
            return "program";
        }

        return "manual";
    }

    getCurrentModeSettings = (mode = null) => {

        const {modes} = HPWH4_11_260_Settings;

        if (mode) {
            return modes[mode];
        }

        return modes[this.getMode()];
    }

    getProgramCurrentPeriod = () => {
        const {timerWeekDay, timerZone, program} = this.device.state;

        return program[timerWeekDay][timerZone];
    }

    getProgramNextPeriod = () => {
        const {program} = this.device.state;

        let hasValidProgramData = false;

        for (const day in program) {
            for (const zone in program[day]) {
                const {from, to} = program[day][zone];
                if (from != "00:00" || to != "00:00") {
                    hasValidProgramData = true;
                    break;
                }
            }
        }

        if (hasValidProgramData) {
            return this.findNextPeriod();
        }

        return null;
    }

    findNextPeriod = (timerWeekDay = null, timerZone = null, program = null) => {

        if (!program) {
            timerWeekDay = this.device.state.timerWeekDay;
            timerZone = this.device.state.timerZone;
            program = this.device.state.program;
        }
        
        const weekDays = ["mon", "tue", "wen", "thu", "fri", "sat", "sun"];
        const dayZones = ["zone_1", "zone_2", "zone_3", "zone_4"];
        const currentDayIndex = weekDays.findIndex(el => el == timerWeekDay);
        const currentDayZoneIndex = dayZones.findIndex(el => el == timerZone);

        if (timerZone == "zone_4") {
            timerZone = "zone_1";

            if (timerWeekDay == "sun") {
                timerWeekDay = "mon";
            } else {
                timerWeekDay = weekDays[currentDayIndex + 1];
            }

        } else {
            timerZone = dayZones[currentDayZoneIndex+1];
        }

        
        const foundProgram = program[timerWeekDay][timerZone];

        if (foundProgram.to != "00:00" || foundProgram.from != "00:00") {
            return foundProgram;     
        }

        return this.findNextPeriod(timerWeekDay, timerZone, program);   
    }

    getVacationInfo = () => {
        if (!this.device.state.vacation) {
            return {mode: null, startDay: null, temp: null, vacDays: null, dateOfReturn:null};  
        }

        const {vacation: {mode, startDay, temp, vacDays}} = this.device.state;

        const startDateObject = new Date(startDay);
        const dateOfReturn = dayjs(startDateObject).add(vacDays, 'day').format("D.MM.YYYY");

        return {mode, startDay, temp, vacDays, dateOfReturn};
    }

    getModelSize = () => {
        const [model, number, size] = this.device.model.split("_");
        return size;
    }
}

export default HeatPumpHelper;