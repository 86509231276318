import {
    SET_LANG,
    SET_PUSH_NOTIOFICATIONS,
    SET_PUSH_NOTIOFICATION,
    READ_PUSH_NOTIOFICATION,
    SET_APP_MESSAGE,
    HIDE_APP_MESSAGE,
    SET_APP_LOG,
    SET_APP_DOCUMENTS,
    DELETE_PUSH_NOTIOFICATION,
    DELETE_ALL_PUSH_NOTIOFICATIONS,
    CLEAR_APP_LOG,
    OLD_DEVICES_LOADER,
    SET_APP_PROCESSING,
    SET_APP_NETWORK,
    SET_APP_SETTINGS,
    SHOW_REQUEST_LOADER,
    HIDE_REQUEST_LOADER
} from '../types/AppTypes';

export const setAppLanguage = lang => {
    return {
        type: SET_LANG,
        payload: {
            lang
        }
    }
}

export const setAppSettings = settings => {
    return {
        type: SET_APP_SETTINGS,
        payload: {
            settings
        }
    }
}

export const setPushNofications = messages => {
    return {
        type: SET_PUSH_NOTIOFICATIONS,
        payload: {
            messages
        }
    }
}

export const setPushNofication = message => {
    return {
        type: SET_PUSH_NOTIOFICATION,
        payload: {
            message
        }
    }
}

export const readPushNofication = message_id => {
    return {
        type: READ_PUSH_NOTIOFICATION,
        payload: {
            message_id
        }
    }
}

export const deletePushNofication = message_id => {
    return {
        type: DELETE_PUSH_NOTIOFICATION,
        payload: {
            message_id
        }
    }
}

export const deleteAllPushNofications = () => {
    return {
        type: DELETE_ALL_PUSH_NOTIOFICATIONS,
        payload: {}
    }
}

export const setAppMessage = message => {
    return {
        type: SET_APP_MESSAGE,
        payload: {
            message
        }
    }
}

export const hideAppMessage = () => {
    return {
        type: HIDE_APP_MESSAGE,
        payload: {}
    }
}

export const logger = message => {
    return {
        type: SET_APP_LOG,
        payload: {
            message
        }
    }
}

export const clearLogger = () => {
    return {
        type: CLEAR_APP_LOG,
        payload: {}
    }
}

export const setAppDocuments = documents => {
    return {
        type: SET_APP_DOCUMENTS,
        payload: {
            documents
        }
    }
}

export const setOldDevicesLoader = status => {
    return {
        type: OLD_DEVICES_LOADER,
        payload: {
            status
        }
    }
}

export const showRequestLoader = model => {
    return {
        type: SHOW_REQUEST_LOADER,
        payload: {
            model
        }
    }
}

export const hideRequestLoader = model => {
    return {
        type: HIDE_REQUEST_LOADER,
        payload: {
            model
        }
    }
}

export const setAppProcessing = status => {
    return {
        type: SET_APP_PROCESSING,
        payload: {
            status
        }
    }
}

export const setAppNetwork = status => {
    return {
        type: SET_APP_NETWORK,
        payload: {
            status
        }
    }
}