import {
    IonPage,
    IonContent,
    IonToolbar,
    IonButton,
    IonList,
    IonItem,
    IonFooter,
    IonSelect,
    IonSelectOption
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'


import RangeStartTemperature from '../../../components/range/RangeStartTemperature';
import StandartHeader from '../../../components/header/StandartHeader';
import {oldModels} from '../../../../../config/App';
import { setAppMessage } from '../../../../../redux/actions/AppAction';

import OldDevicesSettingsHelper from '../../../../../helpers/OldDevicesSettingsHelper';
import BelislimoHelper from '../../../../../helpers/BelislimoHelper';
import ProgramHelper from '../../helpers/ProgramHelper';
import ToastMessage from '../../../components/toast/ToastMessage';


class AddProgram extends React.Component{

    constructor(props){
        super(props);

        this.mac = null;
        this.model = null;
        this.OldDevicesSettingsHelper = null;

        this.state = {
            selectedDay: 0,
            from: "00:00",
            to: "23:59",
            temp: 10,
            program: null
        }
    }

    componentDidMount = async () => {
        this.OldDevicesSettingsHelper = new OldDevicesSettingsHelper(this.mac);

        const {DeviceStatus: status} = this.device;

        let programStatus = {};
        for (const pr in status) {

            if (pr.indexOf('prgP') >= 0) {
                
                programStatus = {...programStatus, [pr]: status[pr]};    
            }
        }

        this.setState({
            program: programStatus
        })
    }

    saveProgram = () => {

        if (ProgramHelper.validateProgram(this.state.from, this.state.to, this.model.program.useHaflHours)) {

            const newProgram = ProgramHelper.createApiv2Program(
                this.state.program, 
                {
                    day: this.props.match.params.day,
                    from: this.state.from,
                    to: this.state.to,
                    temp: this.state.temp,
                }, 
                this.index
            );

            this.OldDevicesSettingsHelper.setDeviceStatus(`prgP${this.index}${this.props.match.params.day}`, newProgram);
            
            //this.props.history.goBack();
            this.props.history.push(`/old/apiv2/program/${this.model_name}/${this.mac}/${this.props.match.params.programsCount}/${this.props.match.params.index}/${this.props.match.params.day}`);
        } else {
            this.props.setAppMessage(this.props.t('invalid_program'));
        }
    }

    render = () => {
        //TO DO: fix the program state when save and immediately after that try to add new program

        this.mac = this.props.match.params.id;
        this.model_name = this.props.match.params.model_name;
        this.model = oldModels[this.model_name]
        this.index = this.props.match.params.index.slice(1);
        this.device = this.props.devices[this.mac];

        const { DeviceStatus: status} = this.device;

        let minTemp = null;
        let maxTemp = null;

        if (this.model_name == '2002-v2') {
            
            const bHelper = new BelislimoHelper(this.device );
            minTemp = 0;
            maxTemp = bHelper.getShowers();

        } else {

            minTemp = this.model.program.minTemp;
            maxTemp = this.model.program.maxTemp;
        }

        const {t} = this.props;

        return (
            <IonPage>

                <StandartHeader name={`${t('program')} ${this.props.match.params.index}`}/>
                <IonContent className="ion-padding">            

                    <div className="preferences-list__wrapper">
                    <IonList className="preferences-list preferences-list--editable">

                        {/* <IonItem className="preferences-list__item" lines="none">
                            <IonDateTime 
                                data-label={t('time_from')}    
                                setTime={time => this.setState({from: time})} 
                                data-minutes={this.model.program.useHaflHours == true ? "0, 30": "0"}
                            />
                        </IonItem>

                        <IonItem className="preferences-list__item" lines="none">
                            <IonDateTime 
                                data-label={t('time_to')}    
                                setTime={time => this.setState({to: time})} 
                                data-minutes={this.model.program.useHaflHours == true ? "0, 30": "0"}
                            />
                        </IonItem> */}

                        <IonItem className="preferences-list__item" lines="none">
                            <IonSelect 
                                placeholder={t('time_from')} 
                                value={this.state.from}
                                onIonChange = {e => this.setState({from: e.detail.value})}
                            >
                                {
                                    this.model.program.useHaflHours == false ?
                                    ProgramHelper.dayHours.map((day, index)=> <IonSelectOption key={`${day}_${index}`} value={index}>{day}</IonSelectOption>) :
                                    ProgramHelper.dayHoursHalf.map((day, index)=> <IonSelectOption key={`${day}_${index}`} value={index}>{day}</IonSelectOption>)
                                }
                            </IonSelect>
                        </IonItem>

                        <IonItem className="preferences-list__item" lines="none">
                            <IonSelect 
                                placeholder={t('time_to')} 
                                value={this.state.to}
                                onIonChange = {e => this.setState({to: e.detail.value})}
                            >
                                {
                                    this.model.program.useHaflHours == false ?
                                    ProgramHelper.dayHours.map((day, index)=> <IonSelectOption key={`${day}_${index}`} value={index}>{day}</IonSelectOption>) :
                                    ProgramHelper.dayHoursHalf.map((day, index)=> <IonSelectOption key={`${day}_${index}`} value={index}>{day}</IonSelectOption>)
                                }
                            </IonSelect>
                        </IonItem>

                        <IonItem className="preferences-list__item" lines="none">
                        <div className="preferences-list__inner">
                            <div className="preferences-list__item-middle">
                                <RangeStartTemperature 
                                    model={this.props.match.params.model_name}
                                    data-min={minTemp} 
                                    data-max={maxTemp} 
                                    data-start={minTemp} 
                                    data-step={1} 
                                    data-label={t('ewh_target_temperature')} 
                                    setTemp={temp => this.setState({temp})}
                                />
                            </div>
                        </div>
                        </IonItem>
                    </IonList>
                    </div>
                    <div className="ion-content-push"></div>

                    <ToastMessage />
                </IonContent>
            
                <IonFooter className="app-footer ion-no-border">
                    <IonToolbar>
                        <div className="buttons-list">
                        <div className="buttons-list__item">
                            <IonButton expand="block" shape="round" size="large" onClick={this.saveProgram}>{t('save')}</IonButton>
                        </div>
                        <div className="buttons-list__item">
                            <IonButton 
                                expand="block" 
                                shape="round" 
                                size="large" 
                                color="help" 
                                onClick={()=>this.props.history.push(`/old/apiv2/program/${this.model_name}/${this.mac}/${this.props.match.params.programsCount}/${this.props.match.params.index}/${this.props.match.params.day}`)}
                            >
                                {t('cancel')}
                            </IonButton>
                        </div>
                        </div>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        );
    }
};

const mapStateToProps = state => {
    return {
        devices: state.oldDevices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        setAppMessage
    })
)(AddProgram);