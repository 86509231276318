import {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonIcon
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import arrowNext from '../../../assets/images/arrow-list.svg';
import StandartHeader from '../components/header/StandartHeader';
import {oldModels} from '../../../config/App'; 
  
const DeviceSettings = props => {

    const { match: {params: {id, model_name}}, devices} = props;
    const device = devices[id];
    const model = oldModels[model_name];

    const convector_settings_menu = [
        {
            title: props.t('sleep_mode'),
            icon: 'moon-outline',
            url: `/old/sleep/${model_name}/${id}`,
            show: (model_name == '1003-v1' || model_name == '1002-v1') ? true : false
        },
        {
          title: props.t('delayed_start'),
          icon: 'clock-second',
          url: `/old/delayed-start/${model_name}/${id}`,
          show: true
        },
        {
            title: props.t('t_controller'),
            icon: 'correction',
            url: `/old/t-controller/${model_name}/${id}`,
            show: true
        },
        {
            title: props.t('world_clock'),
            icon: 'world-clock',
            url: `/old/world-clock/${model_name}/${id}`,
            show: true
        }
    ];

    const boiler_settings_menu = [
        // {
        //     title: props.t('Vacation'),
        //     icon: 'palm',
        //     url: `/old/vacation/${model_name}/${id}`,
        //     show: true
        // },
        {
            title: props.t('world_clock'),
            icon: 'world-clock',
            url: `/old/world-clock/${model_name}/${id}`,
            show: true
        }
    ];

    let settings_menu = (model.type == 'boiler') ? boiler_settings_menu : convector_settings_menu;

    return (
        <IonPage>

        <StandartHeader name={props.t('settings')}/>

        <IonContent className="ion-padding">        
            <IonList className="manuals-list">
            {settings_menu.map(({ title, icon, url, show }, i) => (
                show ?
                <IonItem 
                    key={i} 
                    button 
                    className="manuals-list__item" 
                    routerLink={url} 
                    disabled={(device.DeviceOnline !== 1) ? true : false}
                >
                    <div slot="start">
                        <svg className="manuals-list__icon"><use xlinkHref={`#icon-${icon}`}></use></svg>
                    </div>
                    {title}
                    <IonIcon icon={arrowNext} slot="end"></IonIcon>
                </IonItem>
                : null
            ))}
            </IonList>
            {(device.DeviceOnline !== 1) &&
                <div className="popover__text">
                    <p>{props.t('device_settings_text')}</p>
                </div>
            }
            <div className="ion-content-push"></div>
        </IonContent>
        </IonPage>
    );
};

const mapStateToProps = state => {
    return {
        devices: state.oldDevices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(DeviceSettings);