import {
    IonButton
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { compose } from 'redux'

const FooterTwoButtons = props => {

    return (
        <div className="ion-text-center" style={{ width: '100%' }}>
            <IonButton color="tertiary" onClick={() => props.history.goBack()}>
                {props.firtsButtonName !== undefined ? props.firtsButtonName : props.t('back')}
            </IonButton>
            <IonButton color="primary" onClick={() => props.save()}>{props.secondButtonName !== undefined ? props.secondButtonName : props.t('save')}</IonButton>
        </div>
    )
}


export default compose(
    withTranslation(),
    withRouter
)(FooterTwoButtons);