import { App } from '@capacitor/app';
import { app_mode } from '../config/App';

class AppHelper {

    getAppVersion = async () => {

        let appInfo = {
            build: null,
            version: null
        };

        try {

            const {build, version} = await App.getInfo();

            appInfo = {
                build,
                version
            }

        } catch (e) {
            
            global.debug("Not supported for WEB");
        }

        return appInfo;
    }
}

export default new AppHelper();

export const debug = (...data) => {
    if (app_mode == "development") {
        console.log(...data);
    }
}