import {
    IonAlert,
    IonRow,
    IonCol,
    IonToolbar,
    IonButtons,
    IonButton,
    IonLabel
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ChangeDeviceMode from './ChangeDeviceMode';

const ProgramMode = ({disableDevice, device, mac, program, changeMainMode}) => {

    const { t } = useTranslation();

    const {state: deviceState} = device;

    const [mode, setMode] = useState(null);

    const changeMode = (program) => {
        if (deviceState.mode === program ) {
            setMode("deactivate_current_mode");
        } else {
            setMode(program);
        }
    }

    return (
        <React.Fragment>

            <div className="pt-20">
                <div className="program-status">
                    <div className="program-status__col">
                        <IonRow className="ion-text-center">
                            <IonCol>
                                <IonButton routerLink={`/device/program/${device.model.toLowerCase()}/${mac}/${program}`}>{t('edit_program')}</IonButton>
                            </IonCol>
                            <IonCol>
                                <IonButton 
                                    color="tertiary"
                                    onClick={e => changeMode(program)}
                                >
                                    <span className="button__text">{deviceState.mode === program ? t('deactivate') : t('activate')}</span>
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </div>
                </div>
            </div>

            <ChangeDeviceMode 
                device={device} 
                newMode={mode} 
                mac={mac}
                cancelChange={mode => setMode(mode)}
                changeMainMode={mode => changeMainMode(mode)}
            />

        </React.Fragment>
    )
}

export default ProgramMode;