import {
    IonContent,
    IonPage,
    IonText,
} from '@ionic/react';

import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { Device } from '@capacitor/device';

import StandartHeader from './components/header/StandartHeader';
import ToastMessage from './components/toast/ToastMessage';
import TwoButtons from './components/footer/TwoButtons';
import Loading from './components/loading/Loading';
import chooseDeviceImage from '../../files/chooseDevice.svg';
import { modelsSettings } from '../../config/App';
import WifiHelper from '../../helpers/WifiHelper';
import { setAppMessage, logger } from '../../redux/actions/AppAction';
import { setDeviceLocalUsage } from '../../redux/actions/DevicesAction';

class LocalUsage extends React.Component {

    constructor(props) {
        super(props);

        this.device = null;
        this.mac = null;

        this.state = {
            showLoading: false
        }
    }


    save = async () => {

        this.setState({showLoading: true});
        
        const macAddrParts = this.mac.split(":");
        const lastSymbols = `${macAddrParts[macAddrParts.length-2]}${macAddrParts[macAddrParts.length-1]}`;
        const newTesyNetwork = `${modelsSettings[this.device.model].ap_name}-${lastSymbols}`;

        const info = await Device.getInfo();

        global.debug(info.platform, newTesyNetwork, this.device.ap_pass);
        
        try {

            if (info.platform === 'ios') {
                await WifiHelper.connectToNetworkIOS(newTesyNetwork, this.device.ap_pass);
            } else {
                await WifiHelper.connectToNetwork(newTesyNetwork, this.device.ap_pass);
            }

            this.setState({showLoading: false});

            this.props.setDeviceLocalUsage(true, this.mac);
            this.props.history.goBack();

        } catch (e) {

            this.setState({showLoading: false});

            this.props.logger(`LocalUsage: Cannot connect to device network: ${e}`);
            this.props.setAppMessage(this.props.t('device_connection_problem'));
        }
        

        global.debug(macAddrParts, lastSymbols, newTesyNetwork);    
    }

    render = () => {

        const { t, match: {params: {mac_address}} } = this.props;
        this.mac = mac_address;

        this.device = this.props.devices[mac_address];

        return (
            <IonPage>
            
                <StandartHeader name={this.props.t('local_usage')} />
                <IonContent className="ion-padding">
                    <div className="circle-image circle-image--md">
                        <img src={chooseDeviceImage}/>
                    </div>
                    {/* TO DO: Ask for text */}
                    <IonText>
                        <h1>{this.props.t('local_usage')} </h1>
                        <p>{this.props.t('local_control_text1')}</p>
                        <p>{this.props.t('local_control_text2')}</p>
                        <p>{this.props.t('local_control_text3')}</p>
                        <p>{this.props.t('local_control_text4')}</p>
                        <p>{this.props.t('local_control_text5')}</p>
                    </IonText>
                    <ToastMessage />
                    <Loading show={this.state.showLoading}/>
                    <TwoButtons save={this.save} nameOfSaveButton={this.props.t('connect_to_device')}/>
                </IonContent>
            </IonPage>
        );
    }
    
};

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        setAppMessage,
        logger,
        setDeviceLocalUsage
    })
)(LocalUsage);
  