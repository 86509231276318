import {
    IonPage,
    IonContent,
    IonRow,
    IonCol,
    IonButton,
    IonToolbar,
    IonButtons,
    IonLabel,
    IonAlert,
    IonGrid
} from '@ionic/react';

import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import PageHeader from '../components/header/PageHeader';
import '../components/range/range.scss';
import E32Menu from './components/E32Menu';

import {E32_Settings} from '../../../config/E32';
import E32MqttHelper from '../../../helpers/mqqt/E32MqttHelper';
import E32Helper from '../../../helpers/E32Helper';
import TemperatureSlider from '../components/range/TemperatureSlider';
import DeviceTabButton from '../components/ionTabs/DeviceTabButton';
import ChangeDeviceMode from './components/ChangeDeviceMode';
import ProgramMode from './components/ProgramMode';

const ManagePage = props => {

    const mac = props.match.params.mac_address;
    const { t } = props;
    const {devices} = props;
    const device = devices[mac];
    const {state: deviceState} = device;
    const E32MqttHelperOBJ = new E32MqttHelper(mac);
    const E32HelperOBJ = new E32Helper(device);

    let disableDevice = false;
    if (deviceState.stand_by === 'yes' || device.hasInternet == false) {
        disableDevice = true;
    }

    const modes = {
        [E32_Settings.modes.manual]: 0,
        [E32_Settings.modes.p1]: 1,
        [E32_Settings.modes.p2]: 2,
        [E32_Settings.modes.p3]: 3
    }

    const [deviceMode, setDeviceMode] = useState(modes[E32HelperOBJ.getMainMode()]);
    const [deviceInitialMode, setDeviceInitialMode] = useState(E32HelperOBJ.getMainMode());
    const [changeToNewMode, setChangeToNewMode] = useState(null);

    useEffect(() => {
        
        const currMode = E32HelperOBJ.getMainMode();
        
        //WHEN MODE IS CHANGED FROM DEVICE
        if (deviceInitialMode != currMode) {
            setDeviceMode(modes[currMode])
            setDeviceInitialMode(currMode)
        }

    }, [props.devices])

    const handleDeviceMode = (status, mode) => {
        if (status === 'on') {
            setChangeToNewMode(mode);
        } else {
            setChangeToNewMode("deactivate_current_mode");
        }
    }

    return (
        <IonPage>
            <Header />
            <IonContent className="ion-no-padding">
                <div className="dashboard">
                    <div className="dashboard__wrapper">
                        
                        <E32Menu activeMenu="controlPanel" mac={mac}/>

                        <div className="dashboard__content">
                            
                            <PageHeader name={t('control_panel')}/>

                            <div className="dashboard-box">
                                <div className="dashboard-box__header">

                                    {device.hasInternet !== true && 
                                        <div className="ion-text-center pb-20">
                                            <div className="device-controls__label active">{t('offline')}</div>
                                        </div>
                                    }

                                    <IonGrid className="px-0 py-0">
                                        <IonRow className="ion-justify-content-between">
                                            <IonCol size-xs="12" size-md="auto" className="px-0 py-0">
                                                <IonRow>
                                                    <IonCol size-xs="6" size-md="auto" className="py-0 ion-padding-end">
                                                        <div className="text-bold text-black mb-10">{t('target_showers')}</div>
                                                        <div className={`device-temperature device-temperature--alt ${disableDevice ? '' : 'device-temperature--off'}`}>
                                                            {deviceState.current_heat_level}
                                                        </div>
                                                    </IonCol>
                                                    <IonCol size-xs="6" size-md="auto" className="py-0 ion-padding-end">
                                                        <div className="text-bold text-black mb-10">{t('status')}</div>
                                                        <div className={`device-temperature ${disableDevice ? '' : 'device-temperature--off'}`}>
                                                            <svg className="device-temperature__icon"><use xlinkHref="#icon-heating-alt"></use></svg>
                                                            {t(`${E32_Settings.match_modes_to_tesycloud[deviceState.mode]}_dashboard`)}
                                                        </div>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCol>
                                            <IonCol size-xs="12" size-md="auto"className="px-0 py-0">
                                                <IonRow>
                                                    
                                                    {deviceState.mode != E32_Settings.modes.vacation && 
                                                        <IonCol size-xs="6" size-md="auto" className="py-0 ion-padding-end">
                                                            <IonButton 
                                                                className={`device-button ${deviceState.boost == 'on' ? "device-button--active" : "device-button--lock"}`} 
                                                                onClick={() => E32MqttHelperOBJ.request('setBoost', {state: deviceState.boost == 'on' ? 'off' : 'on'})}
                                                                disabled= {disableDevice ? true : false}
                                                            >
                                                                <div className="device-button__inner">
                                                                    <div className="device-button__icon">
                                                                        <svg className="device-button__icon-icon"><use xlinkHref="#icon-boost"></use></svg>
                                                                        <div className="device-button__icon-label">{t('on')}</div>
                                                                    </div>
                                                                    <IonLabel className="device-button__text">{t('boost')}</IonLabel>
                                                                </div>
                                                            </IonButton>
                                                        </IonCol>
                                                    }
                                                    
                                                    <IonCol size-xs="6" size-md="auto" className="py-0 ion-padding-end">
                                                        <IonButton 
                                                            className={`device-button ${deviceState.stand_by == 'no' ? "device-button--active" : "device-button--lock"}`} 
                                                            onClick={() => E32MqttHelperOBJ.request("setStandby", {setState: deviceState.stand_by == 'no' ? 'yes' : 'no'})} 
                                                        >
                                                            <div className="device-button__inner">
                                                                <div className="device-button__icon">
                                                                    <svg className="device-button__icon-icon"><use xlinkHref="#icon-switch"></use></svg>
                                                                    <div className="device-button__icon-label">{t('on')}</div>
                                                                </div>
                                                                <IonLabel className="device-button__text">
                                                                    {deviceState.stand_by == 'no' ? t('ewh_active_status') : t('ewh_inactive_status')}    
                                                                </IonLabel>
                                                            </div>
                                                        </IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </div>
                                <div className="dashboard-box__body">

                                    <Tabs 
                                        forceRenderTabPanel 
                                        selectedIndex={deviceMode} 
                                        onSelect={(index) => setDeviceMode(index)} 
                                        className="react-tabs react-tabs--mod"
                                    >
                                        <TabList>
                                            <Tab disabled= {disableDevice} >
                                                {t('ewh_manual')}
                                            </Tab>
                                            <Tab disabled= {disableDevice} >
                                                {t('P1')}
                                            </Tab>
                                            <Tab disabled= {disableDevice} >
                                                {t('P2')}
                                            </Tab>
                                            <Tab disabled= {disableDevice} >
                                                {t('P3')}
                                            </Tab>
                                        </TabList>

                                        <TabPanel>
                                            {(deviceState.mode === E32_Settings.modes.manual || disableDevice) ?
                                                <div className="pt-20">
                                                    <div className="wrapper wrapper--xs">
                                                        <TemperatureSlider 
                                                            model={device.model}
                                                            label={t('target_showers')}
                                                            disabled={(disableDevice || deviceState.boost == 'on') ? true : false}
                                                            currentTemp={deviceState.heat_level}
                                                            minTemperature={0}
                                                            maxTemperature={E32HelperOBJ.getShowers()}
                                                            stepTemperature={1}
                                                            handleTemperature={value => E32MqttHelperOBJ.request('setMode', {mode: deviceState.mode, heatLevel: value})}
                                                            
                                                        />
                                                    </div>
                                                </div>
                                            :
                                                <div className="pt-20">
                                                    <div className="device-mode">
                                                        <div className="device-mode__start">
                                                            <svg className={`device-mode__icon fill-light-green`}>
                                                            <use xlinkHref={`#icon-${E32_Settings.modes_icons[deviceState.mode]}`}></use>
                                                            </svg>
                                                        </div>
                                                        <div className="device-mode__end">
                                                            <div className="device-mode__title">{`${t('your_device_is_in')} "${t(E32_Settings.match_modes_to_tesycloud[deviceState.mode])}"`}</div>
                                                            {/* <div className="target-list">
                                                                { status.dtime > 0 &&
                                                                    <div className="target-list__item">
                                                                        <div className="target__label">{t(`until`)}: {TimeHelper.addTimeInMinutes(this.zonename, status.dtime)}</div>
                                                                    </div>
                                                                }
                                                            </div> */}

                                                            {deviceState.mode == E32_Settings.modes.vacation &&
                                                                <div>
                                                                    <IonButton color="tertiary" routerLink={`/device/vacation/${device.model.toLowerCase()}/${mac}`}>
                                                                        <span className="button__text">{t('edit')}</span>
                                                                    </IonButton>
                                                                    <IonButton color="primary" onClick={() => handleDeviceMode('off', E32_Settings.modes.manual)}>
                                                                        <span className="button__text">{t('deactivate')}</span>
                                                                    </IonButton>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            <div className="pt-20">
                                                <IonToolbar className="bordered">
                                                    <IonButtons className="ion-justify-content-between">

                                                        {E32_Settings.ecoFunctions.map(eco => (
                                                            <DeviceTabButton 
                                                                key={eco}
                                                                name={t(E32_Settings.match_modes_to_tesycloud[eco])}
                                                                icon={E32_Settings.modes_icons[eco]} 
                                                                handleStatus={status => handleDeviceMode(status, eco)}
                                                                status={(deviceState.mode === eco) ?  'on' : 'off'}
                                                                disabled= {disableDevice}
                                                            />
                                                        ))}

                                                        <DeviceTabButton 
                                                            name={t("Vacation")}
                                                            icon={'palm'} 
                                                            //handleStatus={status => this.handleDeviceMode(status, this.model.modes['Vacation'])}
                                                            handleStatus={newStatus => {
                                                                deviceState.mode === E32_Settings.modes.vacation ? 
                                                                    handleDeviceMode(newStatus, E32_Settings.modes.vacation)
                                                                :
                                                                    props.history.push(`/device/vacation/${device.model.toLowerCase()}/${mac}`)
                                                            }}
                                                            status={(deviceState.mode === E32_Settings.modes.vacation) ?  'on' : 'off'}
                                                            disabled= {disableDevice}
                                                        />
                                                    
                                                    </IonButtons>
                                                </IonToolbar>
                                            </div>
                                        </TabPanel>

                                        <TabPanel>
                                            <ProgramMode
                                                disableDevice={disableDevice} 
                                                device={device} 
                                                mac={mac} 
                                                program={E32_Settings.modes.p1}
                                                changeMainMode={mode => setDeviceMode(modes[mode])}
                                            />
                                        </TabPanel>

                                        <TabPanel>
                                            <ProgramMode
                                                disableDevice={disableDevice} 
                                                device={device} 
                                                mac={mac} 
                                                program={E32_Settings.modes.p2}
                                                changeMainMode={mode => setDeviceMode(modes[mode])}
                                            />
                                        </TabPanel>

                                        <TabPanel>
                                            <ProgramMode
                                                disableDevice={disableDevice} 
                                                device={device} 
                                                mac={mac} 
                                                program={E32_Settings.modes.p3}
                                                changeMainMode={mode => setDeviceMode(modes[mode])}
                                            />
                                        </TabPanel>

                                        <ChangeDeviceMode 
                                            device={device} 
                                            newMode={changeToNewMode} 
                                            mac={mac}
                                            cancelChange={mode => setChangeToNewMode(null)}
                                            changeMainMode={mode => setChangeToNewMode(null)}
                                        />
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, { })
)(ManagePage);