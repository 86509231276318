import {
    IonContent,
    IonToolbar,
    IonPage,
    IonFooter,
    IonButtons,
    IonButton,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonAlert
} from '@ionic/react';
  
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import Slider from '@mui/material/Slider';
//import './components/range/range.scss';
// import HeaderWithPopoverMenu from './components/header/HeaderWithPopoverMenu';
import DeviceSettingsHelper from '../../helpers/DeviceSettingsHelper';
import {
    updateDeviceState
} from '../../redux/actions/DevicesAction';
import createModelInstance from '../../models/modelsFactory';
import DeviceTabButton from './components/ionTabs/DeviceTabButton';
import HeaderWithPopoverMenu from './components/header/HeaderWithPopoverMenu';
import {cn05uvSettings} from '../../config/App';
import { Device } from '@capacitor/device';


const anglesArray = [
    { 'temp': 0, 'angle': -49 },
    { 'temp': 0.5, 'angle': -44 },
    { 'temp': 1, 'angle': -39 },
    { 'temp': 1.5, 'angle': -34 },
    { 'temp': 2, 'angle': -31 },
    { 'temp': 2.5, 'angle': -28 },
    { 'temp': 3, 'angle': -24 },
    { 'temp': 3.5, 'angle': -20 },
    { 'temp': 4, 'angle': -15 },
    { 'temp': 4.5, 'angle': -12 },
    { 'temp': 5, 'angle': -4 },
    { 'temp': 5.5, 'angle': 0 },
    { 'temp': 6, 'angle': 4 },
    { 'temp': 6.5, 'angle': 8 },
    { 'temp': 7, 'angle': 12 },
    { 'temp': 7.5, 'angle': 18 },
    { 'temp': 8, 'angle': 23 },
    { 'temp': 8.5, 'angle': 28 },
    { 'temp': 9, 'angle': 32 },
    { 'temp': 9.5, 'angle': 35 },
    { 'temp': 10, 'angle': 40 },
    { 'temp': 10.5, 'angle': 45 },
    { 'temp': 11, 'angle': 50 },
    { 'temp': 11.5, 'angle': 55 },
    { 'temp': 12, 'angle': 60 },
    { 'temp': 12.5, 'angle': 65 },
    { 'temp': 13, 'angle': 70 },
    { 'temp': 13.5, 'angle': 75 },
    { 'temp': 14, 'angle': 80 },
    { 'temp': 14.5, 'angle': 85 },
    { 'temp': 15, 'angle': 90 },
    { 'temp': 15.5, 'angle': 95 },
    { 'temp': 16, 'angle': 100 },
    { 'temp': 16.5, 'angle': 105 },
    { 'temp': 17, 'angle': 110 },
    { 'temp': 17.5, 'angle': 115 },
    { 'temp': 18, 'angle': 120 },
    { 'temp': 18.5, 'angle': 125 },
    { 'temp': 19, 'angle': 130 },
    { 'temp': 19.5, 'angle': 135 },
    { 'temp': 20, 'angle': 140 },
    { 'temp': 20.5, 'angle': 145 },
    { 'temp': 21, 'angle': 150 },
    { 'temp': 21.5, 'angle': 155 },
    { 'temp': 22, 'angle': 160 },
    { 'temp': 22.5, 'angle': 165 },
    { 'temp': 23, 'angle': 170 },
    { 'temp': 23.5, 'angle': 174 },
    { 'temp': 24, 'angle': 178 },
    { 'temp': 24.5, 'angle': 182.5 },
    { 'temp': 25, 'angle': 185 },
    { 'temp': 25.5, 'angle': 190 },
    { 'temp': 26, 'angle': 195 },
    { 'temp': 26.5, 'angle': 200 },
    { 'temp': 27, 'angle': 205 },
    { 'temp': 27.5, 'angle': 208 },
    { 'temp': 28, 'angle': 211 },
    { 'temp': 28.5, 'angle': 216 },
    { 'temp': 29, 'angle': 221 },
    { 'temp': 29.5, 'angle': 226 },
    { 'temp': 30, 'angle': 230 },
]

class DeviceCN05UV extends React.Component 
{
    constructor(props) {
        super(props);

        this.state = {
            programState: null,
            newDeviceMode: '',
            lockState: false,
            deviceState: true,
            popoverMenu: [],
            popoverState: { showPopover: false, event: undefined },
            alert: {
                isOpen: false,
                header: '',
                message: ''
            },
            platform: 'android'
        }

        this.mac = null;
        this.device = null;
        this.DeviceSettingsHelper = null;

        this.deviceControls = [
            {
                'title' : props.t('open_window'),
                'icon' : 'open-window'
            },
            {
                'title' : props.t('sleep_mode'),
                'icon' : 'moon'
            },
            {
                'title' : props.t('delayed_stop'),
                'icon' : 'clock'
            },
            {
                'title' : props.t('anti_frost'),
                'icon' : 'snowflake'
            }
        ];
    }
    
    componentDidMount = async () => {

        this.DeviceSettingsHelper = new DeviceSettingsHelper(this.mac);

        if (this.device !== null && this.device !== undefined) {

            let mode = 'manual';

            if (this.device.state.mode && this.device.state.mode === cn05uvSettings.modes.program) {
                mode = 'program';
            }

            this.setState({
                programState: mode   
            });
        }

        const {platform} = await Device.getInfo();

        this.setState({
            platform: platform   
        });
    }
    

    handleTemperatureChange = (event, value) => {
        const modelObj = createModelInstance(this.props.devices[this.mac].model.toLowerCase(), {macAddr: this.mac});
        this.props.updateDeviceState(value, modelObj.table_fields.temp, this.mac);
    }

    handleEcoTemp = status => {
        const {state: {ecoTemp}} = this.props.devices[this.mac];

    }

    handleComfortTemp = status => {

        const {state: {comfortTemp}} = this.props.devices[this.mac];

        this.DeviceSettingsHelper.handleComfortTemp({
            temp: (comfortTemp && comfortTemp.temp !== undefined) ? comfortTemp.temp : '0',
            time: (comfortTemp && comfortTemp.time !== undefined) ? comfortTemp.time : '0',
            status: status
        }); 
    }

    handleSleepMode = status => {

        const {state: {sleepMode}} = this.props.devices[this.mac];

        this.DeviceSettingsHelper.handleSleepMode({
            temp: (sleepMode && sleepMode.temp !== undefined) ? sleepMode.temp : '0',
            time: (sleepMode && sleepMode.time !== undefined) ? sleepMode.time : '0',
            status: status
        });      
    }

    handleDelayedStart = status => {
        const {state: {delayedStart}} = this.props.devices[this.mac];

        this.DeviceSettingsHelper.handleDelayedStart({
            time: (delayedStart && delayedStart.time !== undefined) ? delayedStart.time : '0',
            status: status
        });       
    }

    changeDeviceControl = control => {

        if (control === 'manual' && this.device.state.mode === cn05uvSettings.modes.program) {
            this.handleDeviceMode('on', cn05uvSettings.modes.heating);
        } else {
            this.setState({programState: control})
        }
    }

    handleDeviceMode = (status, mode) => {

        if (status === 'on') {

            this.setState({
                newDeviceMode: mode,
                alert: {
                    isOpen: true,
                    header: this.props.t(`${mode}_mode_header`),
                    message: this.props.t(`${mode}_mode_message`)
                }
            })

        } else {

            this.handleDeviceMode('on', cn05uvSettings.modes.heating);
        }
    }

    capitalizeFirstLetter = string => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    render = () => {

        this.mac = this.props.match.params.mac_address;

        const { t } = this.props;
        const {devices} = this.props;

        this.device = devices[this.mac];
        
        if (!this.device || this.device === undefined) {
            return null;
        }

        const { state: d_state } = this.device;
        const { modes: device_modes } = cn05uvSettings;

        if (!d_state.current_temp || d_state.current_temp === undefined) {
            d_state.current_temp = 0;
        }

        if (!d_state.temp || d_state.temp === undefined) {
            d_state.temp = cn05uvSettings.min_temp;
        }

        const currentTempAngle = anglesArray.filter(x => x.temp === d_state.current_temp)[0] !== undefined ? anglesArray.filter(x => x.temp === d_state.current_temp)[0].angle : 270;

        if (!this.device || this.device === undefined || this.state.programState == null) {
            return null;
        }


        return (
            <IonPage>
                
                <HeaderWithPopoverMenu 
                    name={this.device.deviceName} 
                    mac={this.mac}
                    device = {this.device}
                />

                <IonContent className="ion-padding">
                    
                    <IonToolbar>
                        <IonSegment 
                            value={this.state.programState} 
                            onIonChange={e => this.changeDeviceControl(e.detail.value)}
                            disabled={(d_state.status === 'off' || (this.device.hasInternet === false && this.device.localUsage === false)) ? true : false}
                        >
                            <IonSegmentButton value="manual">
                                <IonLabel>{t('convector_manual')}</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="program">
                                <IonLabel>{t('program')}</IonLabel>
                            </IonSegmentButton>
                        </IonSegment>
                    </IonToolbar>
                    
                    <div className="device-controls">
                        <IonButton 
                            color="transparent" 
                            className={`button-lock ${d_state.lockedDevice === 'on' ? "button-lock--on" : "button-lock--off"}`} 
                            onClick={ e => this.DeviceSettingsHelper.handleLockDevice(d_state.lockedDevice === 'on' ? 'off' : 'on') }
                        >
                            <svg className="button-lock__icon"><use xlinkHref="#icon-locker"></use></svg>
                            <div className="button-lock__label">{t('on')}</div>
                        </IonButton>

                        <div className={`device-controls__label ${this.device.hasInternet !== true ? 'active' : ''}`}> {this.device.localUsage === true ? t('local_usage') : t('offline')}</div>
    
                        <IonButton 
                            color="transparent" 
                            className={`button-switch ${d_state.status === 'on' ? "button-switch--on" : "button-switch--off"}`} 
                            onClick={ e => this.DeviceSettingsHelper.handleOnOff(d_state.status === 'on' ? 'off' : 'on') }
                        >
                            <svg className="button-switch__icon"><use xlinkHref="#icon-switch"></use></svg>
                        </IonButton>
                    </div>

                    {d_state.mode && (d_state.mode === device_modes.heating || d_state.mode === device_modes.program || this.state.programState === 'program' || d_state.mode === device_modes.off) &&

                        <div className={`range-circle ${d_state.heating === 'on' ? 'range-circle--heating' : 'range-circle--ready'} ${(d_state.status === 'off' || (this.device.hasInternet === false && this.device.localUsage === false)) ? 'disabled' : ''}`}>
                            <div className="range-circle__inner">
                                <div><svg className="range-circle__icon"><use xlinkHref="#icon-heating-alt"></use></svg></div>
                                <div className="range-circle__temperature">{d_state.current_temp ? d_state.current_temp : 0}°C</div>
                                <div className="range-circle__label">{t('currrent_temperature')}</div>
                                {/* TO DO: Check the heating status */}
                                <div className="range-circle__status">
                                    {d_state.status === 'on' ? d_state.heating === 'on' ? t('heating') : t('ready') : t('inactive_status')}
                                </div>
                            </div>
                            <div className="range-circle__didgits">
                                <div className="range-circle__didgit range-circle__didgit--0">0</div>
                                <div className="range-circle__didgit range-circle__didgit--5">5</div>
                                <div className="range-circle__didgit range-circle__didgit--10">10</div>
                                <div className="range-circle__didgit range-circle__didgit--15">15</div>
                                <div className="range-circle__didgit range-circle__didgit--20">20</div>
                                <div className="range-circle__didgit range-circle__didgit--25">25</div>
                                <div className="range-circle__didgit range-circle__didgit--30">30</div>
                            </div>
                            {/* <div className="range-circle__dot-handle" style={{ transform: 'rotate('+ d_state.current_temp * 7.25 +'deg)' }}>
                                <div className="range-circle__dot"></div>
                            </div>      */}
                            <div className="range-circle__dot-handle" style={{ transform: 'rotate(' + currentTempAngle + 'deg)' }}>
                                <div className="range-circle__dot"></div>
                            </div>
                            <div className="range-circle__dot-handle range-circle__dot-handle--current" style={{ transform: 'rotate(' + anglesArray.filter(x => x.temp === d_state.temp)[0].angle + 'deg)' }}>
                                <div className="range-circle__dot"></div>
                            </div>           
                        </div>
                    }
                    
                    {this.state.programState === 'manual' ? 

                        (d_state.mode === device_modes.heating || d_state.mode === device_modes.off) ?

                            <div className="range-slider__wrapper">
                                <header className={`range-slider__header ${(d_state.status === 'off' || (this.device.hasInternet === false && this.device.localUsage === false)) ? 'disabled' : ''}`}>
                                    <div className="range-slider__header-item">
                                    <div className="range-slider__header-label">{t('target_temperature')}</div>
                                    </div>
                                    <div className="range-slider__header-item">
                                    <div className="range-slider__output">{typeof d_state.temp === 'number' ? d_state.temp : cn05uvSettings.min_temp}°C</div>
                                    </div>
                                </header>
                                <Slider
                                    value={typeof d_state.temp === 'number' ? d_state.temp : cn05uvSettings.min_temp}
                                    min={cn05uvSettings.min_temp}
                                    max={cn05uvSettings.max_temp}
                                    step={cn05uvSettings.step_temp}
                                    track="inverted"
                                    onChange={(event, value) => {

                                        if (this.state.platform == "ios" && event.type == "mousedown") {
                                            return;
                                        }
                                        this.handleTemperatureChange(event, value);
                                    }}
                                    onChangeCommitted={(event, value) => {

                                        if (this.state.platform == "ios" && event.type == "mouseup") {
                                            return;
                                        }
                    
                                        this.DeviceSettingsHelper.handleOnChangeTemp(value)
                                    }}
                                    disabled={ (d_state.status === 'off' || (this.device.hasInternet === false && this.device.localUsage === false)) ? true : false}
                                    valueLabelDisplay="off" 
                                    className="range-slider range-slider--temperature device-range-slider"
                                />
                            </div>
                        :

                            <React.Fragment>
                                <div className="device-mode">
                                    <div className="device-mode__start">
                                        <svg className={`device-mode__icon ${cn05uvSettings.modesInfo[d_state.mode].class}`}>
                                            <use xlinkHref={cn05uvSettings.modesInfo[d_state.mode].image}></use>
                                        </svg>
                                    </div>
                                    <div className="device-mode__end">
                                        <div className={`range-circle range-circle--alt ${d_state.heating === 'on' ? '' : 'range-circle--ready'} ${(d_state.status === 'off' || (this.device.hasInternet === false && this.device.localUsage === false)) ? 'disabled' : ''}`}> {/* Add class 'range-circle--ready' when device is ready OR 'disabled' when device is disabled */}
                                            <div className="range-circle__inner">
                                                <div>
                                                    <svg className="range-circle__icon">
                                                        <use xlinkHref="#icon-heating-alt"></use>
                                                    </svg>
                                                </div>
                                                <div className="range-circle__temperature">{d_state.current_temp ? d_state.current_temp : 0}°C</div>
                                                <div className="range-circle__label">{t('currrent_temperature')}</div>
                                                <div className="range-circle__status">
                                                    {d_state.status === 'on' ? d_state.heating === 'on' ? t('heating') : t('ready') : t('inactive_status')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="target-list">
                                    {d_state.target > 0 &&

                                        <div className="target-list__item">
                                            <div className="target__label">{t('target_temperature')} {d_state.target}°C</div>
                                        </div>
                                    }
                                    { d_state.timeRemaining > 0 &&
                                        <div className="target-list__item">
                                            <div className="target__label">{t(`${d_state.mode}_until`)}: {d_state.modeTime}</div>
                                        </div>
                                    }
                                </div>

                                <div className="range-buttons js-range-buttons">
                                    <div className="range-buttons__item">
                                        <IonButton shape="round" size="large" onClick={() => this.handleDeviceMode('on', device_modes.heating)}>{t('deactivate')}</IonButton>
                                    </div>
                                    <div className="range-buttons__item">
                                        <IonButton 
                                            shape="round" 
                                            size="large" 
                                            color="help" 
                                            onclick={() => this.props.history.push(`${cn05uvSettings.modesInfo[d_state.mode].path}/${this.mac}`)}
                                        >
                                            {t('edit')
                                        }</IonButton>
                                    </div>
                                </div>
                                
                            </React.Fragment>
                            
                    :
                        <div className="range-buttons js-range-buttons">
                            <div className="range-buttons__item">
                                <IonButton 
                                    shape="round" 
                                    size="large" 
                                    onClick={() => this.handleDeviceMode((d_state.mode &&  d_state.mode === device_modes.program) ? 'off' : 'on', device_modes.program)}
                                >
                                    {(d_state.mode &&  d_state.mode === device_modes.program) ? t('deactivate') : t('activate')}
                                </IonButton>
                            </div>
                            <div className="range-buttons__item">
                                <IonButton 
                                    shape="round" 
                                    size="large" 
                                    color="help" 
                                    onclick={() => this.props.history.push(`/device/program/${this.device.model.toLowerCase()}/${this.mac}`)}
                                >
                                    {t('edit')}
                                </IonButton>
                            </div>
                        </div>
                    }

                    <IonAlert
                        isOpen={this.state.alert.isOpen}
                        onDidDismiss={() => this.setState({alert: {...this.state.alert, isOpen: false}, newDeviceMode: ''})}
                        header={this.state.alert.header}
                        message={`"${t(d_state.mode.toLowerCase())}" ${t('switch_off_control')}`}
                        buttons={
                            [{
                                text: t('cancel'),
                                handler: () => {
                                    this.setState({
                                        alert: {...this.state.alert, isOpen: false},
                                        newDeviceMode: '',
                                    })
                                }
                            },
                            {
                                text: t('ok'),
                                handler: () => {

                                    this.setState({
                                        programState: this.state.newDeviceMode !== device_modes.program ? 'manual' : this.state.programState
                                    });

                                    this.DeviceSettingsHelper.handleMode(this.state.newDeviceMode);
                                }
                            }]
                        }
                    />
    
                    <div className="ion-content-push"></div>
                </IonContent>
                
                <IonFooter className={`app-footer ion-no-border ${this.state.programState === 'program' ? 'ion-hide' : ''}`}>
                    <IonToolbar>
                        <IonButtons className="ion-justify-content-between">

                            <DeviceTabButton 
                                name={t('eco')} 
                                icon={'eco'} 
                                handleStatus={status => this.handleDeviceMode(status, device_modes.eco)}
                                status={(d_state.mode &&  d_state.mode === device_modes.eco) ?  'on' : 'off'}
                                disabled= {(d_state.status === 'off' || (this.device.hasInternet === false && this.device.localUsage === false)) ? true : false}
                            />

                            <DeviceTabButton 
                                name={t('comfort')} 
                                icon={'comfort'} 
                                handleStatus={status => this.handleDeviceMode(status, device_modes.comfort)}
                                status={(d_state.mode &&  d_state.mode === device_modes.comfort) ?  'on' : 'off'}
                                disabled= {(d_state.status === 'off' || (this.device.hasInternet === false && this.device.localUsage === false)) ? true : false}
                            />

                            <DeviceTabButton 
                                name={t('sleep_mode')} 
                                icon={'moon'} 
                                handleStatus={status => this.handleDeviceMode(status, device_modes.sleep)}
                                status={(d_state.mode &&  d_state.mode === device_modes.sleep) ?  'on' : 'off'}
                                disabled= {(d_state.status === 'off' || (this.device.hasInternet === false && this.device.localUsage === false)) ? true : false}
                            />

                            <DeviceTabButton 
                                name={t('delayed_start')} 
                                icon={'clock'} 
                                handleStatus={status => this.handleDeviceMode(status, device_modes.delayStart)}
                                status={(d_state.mode &&  d_state.mode === device_modes.delayStart) ?  'on' : 'off'}
                                disabled= {((this.device.hasInternet === false && this.device.localUsage === false)) ? true : false}
                            />
                            
                        </IonButtons>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        );
    }
};

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        updateDeviceState
    })
)(DeviceCN05UV);