import {
    IonToolbar,
    IonButtons,
} from '@ionic/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DeviceTabButton from '../../components/ionTabs/DeviceTabButton';
import HeatPumpHelper from "../../../../helpers/HeatPumpHelper";
import ChangeDeviceMode from './ChangeDeviceMode';

const ManualModeFooter = props => {

    const HeatPumpHelperOBJ = new HeatPumpHelper(props.device);

    const deviceMode = HeatPumpHelperOBJ.getMode();
    const [deviceNewMode, setDeviceNewMode] = useState(null);

    const { t } = useTranslation();

    const handleDeviceMode = (status, mode) => {

        if (status === 'on') {
            setDeviceNewMode(mode);
        }
    }

    return (
        <IonToolbar disabled={props.disableDevice ? true : false}>
            <IonButtons className="ion-justify-content-between">

                    <DeviceTabButton 
                        name={t('hp_mode_hp')} 
                        icon={'heat-pump'} 
                        handleStatus={status => handleDeviceMode(status, "hp")}
                        status={(deviceMode == "hp") ?  'on' : 'off'}
                        disabled= {props.disableDevice ? true : false}
                    />

                    <DeviceTabButton 
                        name={t('hp_mode_eh')} 
                        icon={'heater'} 
                        handleStatus={status => handleDeviceMode(status, "eh")}
                        status={(deviceMode == "eh") ?  'on' : 'off'}
                        disabled= {props.disableDevice ? true : false}
                    />

                    <DeviceTabButton 
                        name={t('hp_mode_au')} 
                        icon={'auto'} 
                        handleStatus={status => handleDeviceMode(status, "au")}
                        status={(deviceMode == "au") ?  'on' : 'off'}
                        disabled= {props.disableDevice ? true : false}
                    />

                    {/* <DeviceTabButton 
                        name={t('hp_mode_sm')} 
                        icon={'smart'} 
                        handleStatus={status => handleDeviceMode(status, "sm")}
                        status={(deviceMode == "sm") ?  'on' : 'off'}
                        disabled= {props.disableDevice ? true : false}
                    /> */}

            </IonButtons>

            <ChangeDeviceMode 
                device={props.device} 
                newMode={deviceNewMode} 
                mac={props.mac} 
                cancelChange={mode => setDeviceNewMode(mode)}
            />
        </IonToolbar>
    )
}

export default ManualModeFooter;