import {
    IonLoading
} from '@ionic/react';
import React from 'react';
import { withTranslation } from 'react-i18next';

const Loading = props => {
    return (
        <IonLoading
            cssClass='my-custom-class'
            isOpen={props.show}
            message={props.message !== undefined ? props.message : props.t('please_wait')}
        />
    )
}

export default withTranslation()(Loading);