import {
    IonContent,
    IonPage,
    IonButton,
} from '@ionic/react';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, BarChart, CartesianGrid, Bar, LabelList } from 'recharts';

import OldDevicesSettingsHelper from '../../../helpers/OldDevicesSettingsHelper';
import ScrollContainer from 'react-indiana-drag-scroll';
import BoilerApiV2Menu from './boilers/apiv2/BoilerApiV2Menu';

import Header from '../components/header/Header';
import PageHeader from '../components/header/PageHeader';
import Footer from '../components/footer/Footer';
import {oldModels} from '../../../config/App';
import TimeZoneHelper from './helpers/TimeZoneHelper';

const chartHeight = 400;
const chartLineWidth = 130;
const ColorConfig = {
    "green": "#7cae2e",
    "gray": "#e8e8e8",
    "gray2": "#4a4a4a",
    "gray3": "#9b9b9b",
    "gray4": "#f3f4f2",
    "white": "#fff"
}

class CalculatorStatistics extends React.Component {

    state = { 
        Last12M: [],
        Today: null,
        Yesterday: null,
        ThisYear: null
    }

    componentDidMount = async () => {

        this.OldDevicesSettingsHelper = new OldDevicesSettingsHelper(this.mac);

        const {DeviceStatus: {extr}} = this.device; 
        const extrData = TimeZoneHelper.decodeExtr(extr);
        global.debug(extrData);
        const watt = extrData.watt != undefined ? extrData.watt : null;

        const {data} = await this.OldDevicesSettingsHelper.getPower();

        let {Last12M, Last24h, ThisYear, LastMonth, Yesterday, Today, ThisMonth} = data[this.mac];

        const months = {
            1: this.props.t('January_short'),
            2: this.props.t('February_short'),
            3: this.props.t('March_short'),
            4: this.props.t('April_short'),
            5: this.props.t('May_short'),
            6: this.props.t('June_short'),
            7: this.props.t('July_short'),
            8: this.props.t('August_short'),
            9: this.props.t('September_short'),
            10: this.props.t('October_short'),
            11: this.props.t('November_short'),
            12: this.props.t('December_short'),
        }

        const date = new Date();
        const currentMonth = date.getMonth() + 1;
        let nextMonth = currentMonth + 1;

        let Last12MData = [];
        for (const i in Last12M) {

            if (nextMonth > 12) {
                nextMonth = 1;
            }

            if (!watt) {
                Last12MData.push({
                    name: months[nextMonth],
                    kwH: Last12M[i]
                });
            } else {
                Last12MData.push({
                    name: months[nextMonth],
                    kwH: parseFloat(((Last12M[i] * watt) / 3600000).toFixed(2))
                });
            }
            

            nextMonth++;
        }

        if (watt) {
            Today = ((Today * watt) / 3600000).toFixed(2);
            ThisYear = ((ThisYear * watt) / 3600000).toFixed(2);
            Yesterday = ((Yesterday * watt) / 3600000).toFixed(2);
        }
    

        this.setState({
            Last12M: Last12MData,
            Today,
            ThisYear,
            Yesterday
        });

    }

    renderCustomizedLabel = (props) => {
        const { x, y, width, height, value } = props;
      
        return (
            <g transform={`translate(${x},${y})`}>
                <text 
                    x={-height + 10}
                    y={width / 2 + 5} 
                    fill="white"
                    transform="rotate(-90)"
                >
                    {value} kwH
                </text>
            </g>
        );
    };


    fixChartLabels = () => {
        const calculatorChart = document.querySelector('.calculator-chart');
        const calculatorChartLabels = calculatorChart.querySelectorAll('.recharts-label');
        
        calculatorChartLabels.forEach((calculatorChartLabel) => {
            let calculatorChartLabelX = Number(calculatorChartLabel.querySelector('tspan').getAttribute('x')) - chartLineWidth/2;
            let calculatorChartLabelY = Number(calculatorChartLabel.querySelector('tspan').getAttribute('dy').replace('em', '')) - 1.177;
            
            calculatorChartLabel.querySelector('tspan').setAttribute('x', `${calculatorChartLabelX}`);
            calculatorChartLabel.querySelector('tspan').setAttribute('dy', `${calculatorChartLabelY}em`);

        });
    };

    render = () => {

        this.mac = this.props.match.params.id;
        const { t } = this.props;
        this.device = this.props.devices[this.props.match.params.id];

        const { DeviceShortName } = this.props.devices[this.props.match.params.id];
        const chartWidth = this.state.Last12M.length * chartLineWidth;

        global.debug(this.state.Last12M);

        return (
            <IonPage>
            
                <Header />

                <IonContent className="ion-no-padding">
                    <div className="dashboard">
                        <div className="dashboard__wrapper">

                            <BoilerApiV2Menu 
                                activeMenu="calculator" 
                                model_name={this.props.match.params.model_name} 
                                device={this.props.devices[this.props.match.params.id]} 
                                model={oldModels[this.props.match.params.model_name]}
                            />

                            <div className="dashboard__content">
                               
                                <PageHeader name={t('calculator_button')} image="calc"/>

                                <div className="dashboard-box">

                                    <div className="dashboard-box__body">

                                        <div className="consumption">
                                            <div className="consumption__item">
                                                <div className="consumption__title">{t('KWh')}</div>
                                                    <div className="consumption__value">{this.state.Today}</div>
                                                <div className="consumption__title">{t('today')}</div>
                                            </div>
                                            <div className="consumption__item">
                                                <div className="consumption__title">{t('KWh')}</div>
                                                <div className="consumption__value">{this.state.Yesterday}</div>
                                                <div className="consumption__title">{t('yesterday')}</div>
                                            </div>
                                            <div className="consumption__item">
                                                <div className="consumption__title">{t('KWh')}</div>
                                                <div className="consumption__value">{this.state.ThisYear}</div>
                                                <div className="consumption__title">{t('thisyear')}</div>
                                            </div>
                                        </div>

                                        <div className="calculator-chart__wrapper">
                                            {/* <ScrollContainer vertical={false}>
                                                <AreaChart
                                                    height={chartHeight}
                                                    width={chartWidth}
                                                    data={this.state.Last12M}
                                                    margin={{ top: 20, right: 0, left: 0, bottom: 0 }} 
                                                    className="calculator-chart"
                                                >
                                                    <defs>
                                                        <linearGradient id="fillGradient" x1="0" y1="0" x2="0" y2="1">
                                                        <stop offset="50%" stopColor={ColorConfig["gray4"]} stopOpacity={1}/>
                                                        <stop offset="100%" stopColor={ColorConfig["white"]} stopOpacity={0}/>
                                                        </linearGradient>
                                                    </defs>

                                                    <Area type="stepBefore" dataKey="power" stroke={ColorConfig["green"]} strokeWidth="1" label={{ fill: ColorConfig["green"], fontSize: 13}} fill="url(#fillGradient)" onAnimationEnd={this.fixChartLabels} />

                                                    <XAxis xAxisId={0} dataKey="month" axisLine={{ stroke: ColorConfig["gray"] }} tickLine={{ stroke: ColorConfig["green"] }} tick={{fontSize: 14, fontWeight: 300, fill: ColorConfig["gray2"]}} dx={-chartLineWidth/2} dy={3} />
                                                    <Tooltip />
                                                </AreaChart>
                                            </ScrollContainer>
                                            <div className="calculator-chart__yaxis">
                                                <div className="calculator-chart__yaxis-value">KWh</div>
                                            </div> */}

                                            <ResponsiveContainer width={'100%'} height={chartHeight}>
                                                <BarChart data={this.state.Last12M} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                                    <CartesianGrid strokeDasharray="3 3" strokeWidth=".5" vertical={false} />
                                                    <XAxis
                                                        height={50}
                                                        dataKey="name"
                                                        interval={0}
                                                        angle={-45}
                                                        textAnchor="end"
                                                        fontSize={12}
                                                        dy={5}
                                                        dx={2}
                                                    />
                                                    <Bar dataKey="kwH" barSize={80} fill={ColorConfig['green']} background={{ fill: '#e6e6ec' }} textAnchor="bottom">
                                                        <LabelList
                                                            dy={-35}
                                                            dx={-4}
                                                            content={this.renderCustomizedLabel}
                                                        />
                                                    </Bar>
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </IonContent>
                <Footer />
            </IonPage>
        );
    }
}

const mapStateToProps = state => {
    return {
        devices: state.oldDevices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(CalculatorStatistics);