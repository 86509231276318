
import {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonBackButton,
    IonPopover,
    IonList,
    IonListHeader,
    IonItem,
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { compose } from 'redux'

import arrowBack from '../../../../assets/images/arrow-back.svg';

class HeaderWithPopoverMenu2 extends React.Component {

    state = {
        popoverState: { showPopover: false, event: undefined }
    }

    render = () => {
        return (
            <IonHeader className="app-header">
                <IonToolbar className="ion-no-padding">
                    <IonButtons slot="start">
                        <IonBackButton icon={arrowBack} text=""></IonBackButton>
                    </IonButtons>
                    <IonTitle>{this.props.name}</IonTitle>
                    <IonButtons slot="end" onClick={() => this.props.history.push(`/dashboard`)}>
                        <svg className="size-18 mr-16">
                            <use xlinkHref="#icon-home"></use>
                        </svg>
                    </IonButtons>
                    <div slot="end" onClick={
                        (e) => {
                            e.persist();
                            this.setState({
                                popoverState: {
                                    showPopover: true, 
                                    event: e 
                                } 
                            })
                        }}
                        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                    >
                        <svg className="size-24"><use xlinkHref="#icon-dots"></use></svg>
                        <IonPopover
                            event={this.state.popoverState.event}
                            isOpen={this.state.popoverState.showPopover}
                            onDidDismiss={() => this.setState({
                                popoverState: {
                                    showPopover: false,
                                    event: undefined 
                                }
                            })}
                        >
                            <IonList>
                                <IonListHeader>{this.props.t('more')}</IonListHeader>
                                    {this.props.popoverMenu.map(({ title, icon, href }, i) => (
                                        <IonItem 
                                            lines="full" 
                                            button 
                                            key={i} 
                                            routerLink={href}
                                        >
                                            <svg><use xlinkHref={`#icon-${icon}`}></use></svg>
                                            {title}
                                        </IonItem>
                                    ))}
                                </IonList>
                        </IonPopover>
                    </div>
                </IonToolbar>
            </IonHeader>
        )
    }
}

export default compose(
    withTranslation(),
    withRouter
)(HeaderWithPopoverMenu2);