import React from 'react';
import {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonBackButton,
    IonPopover,
    IonList,
    IonListHeader,
    IonItem,
    IonToggle,
    IonLabel,
    IonAlert
} from '@ionic/react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'

import arrowBack from '../../../../assets/images/arrow-back.svg';
import DeviceSettingsHelper from '../../../../helpers/DeviceSettingsHelper';
import RestHelper from '../../../../helpers/RestHelper';
import initialSettings from '../../../../init';
import { withRouter} from 'react-router-dom';

class HeaderWithPopoverMenu extends React.Component {

    state = {
        popoverState: { showPopover: false, event: undefined },
        showDeleteAlert: false
    }

    componentDidMount = () => {
        this.DeviceSettingsHelper = new DeviceSettingsHelper(this.props.mac);
    }

    deleteDevice = () => {

        this.restHelper = new RestHelper();
        this.initialSettings = new initialSettings();
        
        this.restHelper.post('/delete-user-device', {
            mac: this.props.mac
        })
        .then (res => {

            global.debug("Deleted");

            this.DeviceSettingsHelper.handleReset();
            this.initialSettings.init();
            this.props.history.goBack(); 
        })
        .catch(error => {
            global.debug("Cannot delete device from server", error);
        })
    }

    render = () => {

        const { mac, state: d_state, localUsage, hasInternet } = this.props.device;

        const popoverMenu = [
                {
                    'title' : this.props.t('statistics'),
                    'icon' : 'statistic',
                    'url' : `statistics/${this.props.mac}`,
                    'disabled': localUsage === true ? true : false
                },
                {
                    'title' : this.props.t('rename_device'),
                    'icon' : 'pencil',
                    'url' : `name-device/${this.props.mac}`,
                    'disabled': false
                },
                // {
                //     'title' : this.props.t('delete_device'),
                //     'icon' : 'delete',
                //     'url' : `delete-device/${this.props.mac}`
                // },
                {
                    'title' : this.props.t('add_to_group'),
                    'icon' : 'add-to-folder',
                    'url' : `add-to-group/add/${this.props.mac}`,
                    'disabled': false
                },
                {
                    'title' : this.props.t('device_info'),
                    'icon' : 'device-info',
                    'url' : `device-info/${this.props.mac}`,
                    'disabled': false
                },
                {
                    'title' : this.props.t('settings'),
                    'icon' : 'settings',
                    'url' : `device-settings/${this.props.mac}`,
                    'disabled': false
                },
                // {
                //     'title' : this.props.t('local_usage'),
                //     'icon' : 'settings',
                //     'url' : `local-usage/${this.props.mac}`
                // },
            ]

        return (
            <IonHeader className="app-header">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton icon={arrowBack} text=""></IonBackButton>
                    </IonButtons>
                    <IonTitle>{this.props.name}</IonTitle>
                    <IonButtons slot="end" onClick={() => this.props.history.push(`/dashboard`)}>
                        <svg className="size-18 mr-16">
                            <use xlinkHref="#icon-home"></use>
                        </svg>
                    </IonButtons>
                    <div slot="end" onClick={
                        (e) => {
                           
                            e.persist();
                            this.setState({popoverState: { 
                                showPopover: true, 
                                event: e 
                            }})
                        }}
                        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                    >
                        <svg className="size-24"><use xlinkHref="#icon-dots"></use></svg>
                        <IonPopover
                            event={this.state.popoverState.event}
                            isOpen={this.state.popoverState.showPopover}
                            onDidDismiss={e => this.setState({popoverState: { 
                                showPopover: false, 
                                event: e 
                            }})}
                        >
                            <IonList>
                                <IonListHeader>{this.props.t('more')}</IonListHeader>
                                <IonItem lines="full">
                                    <IonLabel className="no-margin">
                                        <svg><use xlinkHref="#icon-open-window"></use></svg>
                                        {this.props.t('open_window')}
                                    </IonLabel>
                                    <IonToggle 
                                        checked={d_state.openedWindow === 'on' ? true : false} 
                                        onIonChange={e => this.DeviceSettingsHelper.handleOpenedWindow(e.detail.checked === true ? 'on' : 'off')} 
                                        disabled= {(d_state.status === 'off' || (hasInternet === false && localUsage === false)) ? true : false}
                                    />
                                </IonItem>
                                <IonItem lines="full">
                                    <IonLabel className="no-margin">
                                        <svg><use xlinkHref="#icon-snowflake"></use></svg>
                                        {this.props.t('anti_frost')}
                                    </IonLabel>
                                    <IonToggle 
                                        checked={d_state.antiFrost === 'on' ? true : false} 
                                        onIonChange={e => this.DeviceSettingsHelper.handleAntiFrost(e.detail.checked === true ? 'on' : 'off')} 
                                        disabled= {(hasInternet === false && localUsage === false) ? true : false}
                                    />
                                </IonItem>
                                <IonItem lines="full">
                                    <IonLabel className="no-margin">
                                        <svg><use xlinkHref="#icon-air-safe"></use></svg>
                                        {this.props.t('uv')}
                                    </IonLabel>
                                    <IonToggle 
                                        checked={d_state.uv === 'on' ? true : false} 
                                        onIonChange={e => this.DeviceSettingsHelper.handleUV(e.detail.checked === true ? 'on' : 'off')}  
                                        disabled= {(d_state.status === 'off' || (hasInternet === false && localUsage === false)) ? true : false}
                                    />
                                </IonItem>
                                {popoverMenu.map(({ title, icon, url, disabled }, i) => (
                                    <IonItem 
                                        lines="full" 
                                        button 
                                        key={i}  
                                        disabled={disabled}
                                        onClick={(e) => {
                                                setTimeout(() => {
                                                    this.setState({popoverState: { 
                                                        showPopover: false, 
                                                        event: e 
                                                    }})
                                                    
                                                }, 1)

                                                setTimeout(()=>{
                                                    this.props.history.push(`/${url}`);
                                                }, 100)
                                            }

                                        }
                                    >
                                        <svg><use xlinkHref={`#icon-${icon}`}></use></svg>{title}
                                    </IonItem>
                                ))}

                                {localUsage === false ? 
                                    <IonItem 
                                        lines="full" 
                                        button 
                                        onClick={(e) => {
                                                setTimeout(() => {
                                                    this.setState({popoverState: { 
                                                        showPopover: false, 
                                                        event: e 
                                                    }})
                                                }, 1);

                                                setTimeout(()=>{
                                                    this.props.history.push(`/local-usage/${this.props.mac}`);
                                                }, 100)

                                            }
                                        }
                                    >
                                        <svg><use xlinkHref={`#icon-local-control`}></use></svg>{this.props.t('local_usage')}
                                    </IonItem>
                                :
                                    <IonItem 
                                        lines="full" 
                                        button 
                                        onClick={(e) => {
                                                setTimeout(() => {
                                                    this.setState({popoverState: { 
                                                        showPopover: false, 
                                                        event: e 
                                                    }})
                                                }, 1)

                                                setTimeout(()=>{
                                                    this.props.history.push(`/stop-local-usage/${this.props.mac}`);
                                                }, 100)
                                            }
                                        }
                                    >
                                        <svg><use xlinkHref={`#icon-local-control`}></use></svg>{this.props.t('stop_local_usage')}
                                    </IonItem>
                                }

                                <IonItem 
                                    lines="full" 
                                    button 
                                    onClick={(e) => {

                                            this.setState({
                                                showDeleteAlert: true, 
                                            })

                                            setTimeout(() => {
                                                this.setState({popoverState: { 
                                                    showPopover: false, 
                                                    event: e 
                                                }})
                                            }, 1)
                                        }
        
                                    }
                                >
                                    <svg><use xlinkHref={`#icon-delete`}></use></svg>{this.props.t('delete_device')}
                                </IonItem>
                            </IonList>
                        </IonPopover>
                    </div>
                </IonToolbar>

                <IonAlert
                    isOpen={this.state.showDeleteAlert}
                    onDidDismiss={() => this.setState({showDeleteAlert: false})}
                    cssClass='my-custom-class'
                    header={this.props.t('confirm')}
                    message={this.props.t('confirm_device_delete')}
                    buttons={[
                        {
                            text: this.props.t('cancel'),
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: blah => {
                                this.setState({showDeleteAlert: false});
                            }
                        },
                        {
                            text: this.props.t('ok'),
                            handler: () => {
                                this.deleteDevice()
                            }
                        }
                    ]}
                />
            </IonHeader>
        );
    }
}

export default compose(
    withTranslation(),
    withRouter
)(HeaderWithPopoverMenu);