import {
    IonToolbar,
    IonButton,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import ChangeDeviceMode from './ChangeDeviceMode';

const ProgramModeFooter = ({disableDevice, device, mac, changeMainMode}) => {

    const [mode, setMode] = useState(null);

    const { t } = useTranslation();

    const changeMode = program_status => {
        if (program_status == "on") {
            setMode("hp");
        } else {
            setMode("program");
        }
    }

    return (
        <IonToolbar disabled={disableDevice ? true : false}>
            <div className="range-buttons">
                <div className="range-buttons__item">
                    <IonButton expand="block" shape="round" size="large" onClick={e => changeMode(device.state.program_status)}>
                        {device.state.program_status == "on" ? t('deactivate') : t('activate')}
                    </IonButton>
                </div>
                <div className="range-buttons__item">
                    <IonButton 
                        expand="block" 
                        shape="round" 
                        size="large" 
                        color="help"
                        routerLink={`/device/program/${device.model.toLowerCase()}/${mac}`}
                    >
                        {t('edit')}
                    </IonButton>
                </div>
            </div>

            <ChangeDeviceMode 
                device={device} 
                newMode={mode} 
                mac={mac}
                cancelChange={mode => setMode(mode)}
                changeMainMode={mode => changeMainMode(mode)}
            />
        </IonToolbar>
    )
}

export default ProgramModeFooter;