import {
    IonContent,
    IonFooter,
    IonButton,
    IonItem,
    IonList,
    IonPage,
    IonToolbar,
    IonLabel,
    IonInput,
  } from '@ionic/react';
  
  import React from 'react';
  import { withTranslation } from 'react-i18next';
  import { compose } from 'redux'
  import { connect } from 'react-redux'
  
  //Import helpers
  import UsersHelper from '../../helpers/UsersHelper';
  import LocalStorageHelper from '../../helpers/LocalStorageHelper';
  
  //Import components
  import StandartHeader from './components/header/StandartHeader';
  
  class SignUp extends React.Component {
  
    constructor(props){
      super(props);
  
      this.UsersHelper = new UsersHelper();
      this.state = {
        newPasswordShown: false,
        passwordConfirmShown: false,
        newPassword: '',
        confirmPassword: '',
        errors: {}
      }
    }

    toggleConfirmPasswordVisiblity = () => {
      this.setState({
        passwordConfirmShown: this.state.passwordConfirmShown ? false : true  
      });
    };

    toggleNewPasswordVisiblity = () => {
        this.setState({
          newPasswordShown: this.state.newPasswordShown ? false : true  
        });
      };
  
    confirm = async () => {
      try {
  
        await this.UsersHelper.passwordSettings({
          newPassword: this.state.newPassword,
          confirmPassword: this.state.confirmPassword,
        });
  
        LocalStorageHelper.set('user.password', this.state.newPassword)

        this.props.history.goBack();
  
      } catch (e) {
  
        this.setState({
          errors: e.errors
        })
  
      }
    }
  
    render = () => {
  
      const { t } = this.props;
  
      return (
        <IonPage>
          
          <StandartHeader name={t('change_password')}/>
    
          <IonContent className="ion-padding">
            <IonList>
              <IonItem lines="full" className={`form-list__item ${'newPassword' in this.state.errors ? 'is-invalid' : ''}`}>
                <IonLabel position="stacked" color="primary" className="form-label">{t('new_password')}</IonLabel>
                <div className="form-password">
                  <IonInput type={this.state.newPasswordShown ? "text" : "password"} className="form-input" onIonInput={ e => this.setState({newPassword: e.detail.value})}/>
                  <button type="button" ion-button="true" className="form-password__button" onClick={this.toggleNewPasswordVisiblity}>
                    <svg className="form-password__button-icon"><use xlinkHref={`#icon-eye-${this.state.newPasswordShown ? 'closed' : 'open'}`}></use></svg>
                  </button>
                </div>
                {'newPassword' in this.state.errors ? (<div className="invalid-message">{this.state.errors.newPassword}</div>) : null}
              </IonItem>
              <IonItem lines="full" className={`form-list__item ${'confirmPassword' in this.state.errors ? 'is-invalid' : ''}`}>
                <IonLabel position="stacked" color="primary" className="form-label">{t('confirm_password')}</IonLabel>
                <div className="form-password">
                  <IonInput type={this.state.passwordConfirmShown ? "text" : "password"} className="form-input" onIonInput={ e => this.setState({confirmPassword: e.detail.value})}/>
                  <button type="button" ion-button="true" className="form-password__button" onClick={this.toggleConfirmPasswordVisiblity}>
                    <svg className="form-password__button-icon"><use xlinkHref={`#icon-eye-${this.state.passwordConfirmShown ? 'closed' : 'open'}`}></use></svg>
                  </button>
                </div>
                {'confirmPassword' in this.state.errors ? (<div className="invalid-message">{this.state.errors.confirmPassword}</div>) : null}
              </IonItem>
            </IonList>
          </IonContent>
          
          <IonFooter className="app-footer ion-no-border">
              <IonToolbar>
                <div className="buttons-list">
                  <div className="buttons-list__item">
                    <IonButton expand="block" shape="round" size="large" onClick={this.confirm}>{t('confirm')}</IonButton>
                  </div>
                </div>
              </IonToolbar>
          </IonFooter>
        </IonPage>
      );
    }
    
  };
  
  const mapStateToProps = state => {
    return {
      app: state.app
    }
  }
  
  export default compose(
    withTranslation(),
    connect(mapStateToProps)
  )(SignUp);
  