import React from 'react';
import {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonBackButton,
    IonPopover,
    IonList,
    IonListHeader,
    IonItem,
    IonToggle,
    IonLabel,
    IonAlert
} from '@ionic/react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'

import arrowBack from '../../../../assets/images/arrow-back.svg';
import DeviceSettingsHelper from '../../../../helpers/DeviceSettingsHelper';
import RestHelper from '../../../../helpers/RestHelper';
import initialSettings from '../../../../init';
import { withRouter} from 'react-router-dom';
import HeatPumpMqttHelper from "../../../../helpers/mqqt/HeatPumpMqttHelper";
import Loading from '../../components/loading/Loading';

class HeaderWithPopoverMenu extends React.Component {

    state = {
        popoverState: { showPopover: false, event: undefined },
        showDeleteAlert: false,
        showLoading: false,
    }

    componentDidMount = () => {
        this.DeviceSettingsHelper = new DeviceSettingsHelper(this.props.mac);
    }

    deleteDevice = () => {

        this.setState({showLoading: true});

        this.restHelper = new RestHelper();
        const HeatPumpMqttHelperOBJ = new HeatPumpMqttHelper(this.props.mac);
        this.initialSettings = new initialSettings();
        
        this.restHelper.post('/delete-user-device', {
            mac: this.props.mac
        })
        .then (async es => {

            HeatPumpMqttHelperOBJ.request('reset', {})
            await this.initialSettings.init();

            this.setState({showLoading: false});
            this.props.history.push('/dashboard');
            
        })
        .catch(error => {
            global.debug("Cannot delete device from server", error);
        })
    }

    render = () => {

        const { mac, state: d_state, hasInternet } = this.props.device;

        const popoverMenu = [
                {
                    'title' : this.props.t('calculator_button'),
                    'icon' : 'calc',
                    'url' : `device/hp/calculator/${this.props.device.model.toLowerCase()}/${this.props.mac}`,
                    'disabled': false
                },
                {
                    'title' : this.props.t('rename_device'),
                    'icon' : 'pencil',
                    'url' : `name-device/${this.props.mac}`,
                    'disabled': false
                },
                {
                    'title' : this.props.t('add_to_group'),
                    'icon' : 'add-to-folder',
                    'url' : `add-to-group/add/${this.props.mac}`,
                    'disabled': false
                },
                {
                    'title' : this.props.t('device_info'),
                    'icon' : 'device-info',
                    'url' : `device/hp/device-info/${this.props.device.model.toLowerCase()}/${this.props.mac}`,
                    'disabled': false
                },
                {
                    'title' : this.props.t('world_clock'),
                    'icon' : 'world-clock',
                    'url' : `world-clock/${this.props.mac}`,
                    'disabled': false
                },
            ]

        return (
            <IonHeader className="app-header">
            <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton icon={arrowBack} text=""></IonBackButton>
                    </IonButtons>
                    <IonTitle>{this.props.name}</IonTitle>
                    <IonButtons slot="end" onClick={() => this.props.history.push(`/dashboard`)}>
                        <svg className="size-18 mr-16">
                            <use xlinkHref="#icon-home"></use>
                        </svg>
                    </IonButtons>
                    <div slot="end" onClick={
                        (e) => {
                           
                            e.persist();
                            this.setState({popoverState: { 
                                showPopover: true, 
                                event: e 
                            }})
                        }}
                        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                    >
                        <svg className="size-24"><use xlinkHref="#icon-dots"></use></svg>
                        <IonPopover
                            event={this.state.popoverState.event}
                            isOpen={this.state.popoverState.showPopover}
                            onDidDismiss={e => this.setState({popoverState: { 
                                showPopover: false, 
                                event: e 
                            }})}
                        >
                            <IonList>
                                <IonListHeader>{this.props.t('more')}</IonListHeader>

                                {/* <IonItem lines="full">
                                    <IonLabel className="no-margin">
                                        <svg><use xlinkHref="#icon-locker"></use></svg>
                                        {this.props.t('hp_lock_device')}
                                    </IonLabel>
                                    <IonToggle 
                                        checked={false} 
                                        onIonChange={e => () => {}} 
                                        disabled= {(d_state.power_status === 'off' || hasInternet === false) ? true : false}
                                    />
                                </IonItem> */}

                                {popoverMenu.map(({ title, icon, url, disabled }, i) => (
                                    <IonItem 
                                        lines="full" 
                                        button 
                                        key={i}  
                                        disabled={disabled}
                                        onClick={(e) => {
                                                setTimeout(() => {
                                                    this.setState({popoverState: { 
                                                        showPopover: false, 
                                                        event: e 
                                                    }})
                                                    
                                                }, 1)

                                                setTimeout(()=>{
                                                    this.props.history.push(`/${url}`);
                                                }, 100)
                                            }

                                        }
                                    >
                                        <svg><use xlinkHref={`#icon-${icon}`}></use></svg>{title}
                                    </IonItem>
                                ))}

                                <IonItem 
                                    lines="full" 
                                    button 
                                    onClick={(e) => {

                                            this.setState({
                                                showDeleteAlert: true, 
                                            })

                                            setTimeout(() => {
                                                this.setState({popoverState: { 
                                                    showPopover: false, 
                                                    event: e 
                                                }})
                                            }, 1)
                                        }
        
                                    }
                                >
                                    <svg><use xlinkHref={`#icon-delete`}></use></svg>{this.props.t('delete_device')}
                                </IonItem>
                            </IonList>
                        </IonPopover>
                    </div>
                </IonToolbar>

                <IonAlert
                    isOpen={this.state.showDeleteAlert}
                    onDidDismiss={() => this.setState({showDeleteAlert: false})}
                    cssClass='my-custom-class'
                    header={this.props.t('confirm')}
                    message={this.props.t('confirm_device_delete')}
                    buttons={[
                        {
                            text: this.props.t('cancel'),
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: blah => {
                                this.setState({showDeleteAlert: false});
                            }
                        },
                        {
                            text: this.props.t('ok'),
                            handler: () => {
                                this.deleteDevice()
                            }
                        }
                    ]}
                />

                <Loading show={this.state.showLoading}/>
            </IonHeader>
        );
    }
}

export default compose(
    withTranslation(),
    withRouter
)(HeaderWithPopoverMenu);