import React from 'react';
import { withTranslation } from 'react-i18next';

import offlineIcon from '../../../../assets/images/webApp/offline.svg';

const Offline = props => {

    const { t } = props;

    return (
        <React.Fragment>
            <div className="offline">
                <figure>
                    <img src={offlineIcon} alt="Offline" />
                    <figcaption>
                        <p>{t('device_state')}</p>
                        <p className="text-red">
                            {props.waitingForConnection === true ? t('waiting_for_connection') : t('offline')}
                        </p>
                    </figcaption>
                </figure>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Offline);