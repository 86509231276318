
import {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonInput
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Device } from '@capacitor/device';

import OldDevicesSettingsHelper from '../../../helpers/OldDevicesSettingsHelper';
import BoilerApiV2Menu from './boilers/apiv2/BoilerApiV2Menu';
import {oldModels} from '../../../config/App';

import Header from '../components/header/Header';
import PageHeader from '../components/header/PageHeader';
import Footer from '../components/footer/Footer';

class DeviceInfoAPIV2 extends React.Component 
{
    render = () => {

        const { t, devices, match: {params: { id } } } = this.props;
        const device = this.props.devices[id];
        const {DeviceStatus: status} = device;

        return (
            <IonPage>
                <Header />
                <IonContent className="ion-no-padding">
                    <div className="dashboard">
                        <div className="dashboard__wrapper">

                            <BoilerApiV2Menu 
                                activeMenu="deviceInfo" 
                                model_name={this.props.match.params.model_name} 
                                device={this.props.devices[this.props.match.params.id]} 
                                model={oldModels[this.props.match.params.model_name]}
                            />


                            <div className="dashboard__content">
                               
                                <PageHeader name={t('device_info')} image="info"/>

                                <div className="dashboard-box">

                                    <div className="dashboard-box__body">
                            
                                        <IonList>
                                            <IonItem lines="full" className="form-list__item">
                                                <IonLabel position="stacked" color="primary" className="form-label">{t('device_id')}</IonLabel>
                                                <IonInput value={id} readonly/>
                                            </IonItem>


                                            <IonItem lines="full" className="form-list__item">
                                                <IonLabel position="stacked" color="primary" className="form-label">{t('firmware_version')}</IonLabel>
                                                <IonInput value={status.wsw} readonly/>
                                            </IonItem>
                                        </IonList>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </IonContent>
                    
                <Footer />
                    
            </IonPage>
        ); 
    }
    
}

const mapStateToProps = state => {
    return {
        devices: state.oldDevices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(DeviceInfoAPIV2);