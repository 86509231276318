import store from '../redux/createStore';
import { oldModels } from '../config/App';
import ProgramHelper from '../pages/phoneApp/oldDevices/helpers/ProgramHelper';


class BelislimoHelper
{
    constructor(device) {

        this.device = device;
        this.model_name = this.getModelName();
        this.model = oldModels[this.model_name];
        this.volume = this.getVolume();
        this.position = this.getPosition();
    }

    getVolume = () => {

        if (this.model.apiVersion == 'apiv2') {
            return this.getVolumeApiV2();
        }

        return this.getVolumeApiV1();
    }

    getVolumeApiV1 = () => {
        
        const {DeviceStatus: {volume}} = this.device;

        return this.getRealVolume(volume);
    }

    getVolumeApiV2 = () => {

        const {DeviceStatus: {parNF}} = this.device;

        const parNFData = ProgramHelper.splitProgramString(parNF);
        const volume = ProgramHelper.convertToInt(parNFData[0]) * 2;

        const realVolume = this.getRealVolume(volume);

        return this.getRealVolume(volume);
    }

    getPosition = () => {
        
        if (this.model.apiVersion == 'apiv1') {

            const {DeviceStatus: {pos}} = this.device;
            return pos;
        }

        const {DeviceStatus: {psn}} = this.device;
        return psn == 1 ? 'h' : 'v';
    }

    getModelName = () => {

        const { DeviceStatus, DeviceSerial } = this.device;
        const { wsw } = DeviceStatus;
        const [model_name, idNumber] = DeviceSerial.split("-");

        let  modelName = `${model_name}-v1`;
    
        if (wsw !== undefined) {

            modelName = `${model_name}-v2`;
        } 

        return modelName;
    }

    getRealVolume = volume => {

        const volume2002 = {
            24: 30,
            25: 30,
            40: 50,
            64: 80,
            65: 80,
            80: 100
        };

        return volume2002[volume];
    }

    getImage = () => {
        return this.position == "h" ? this.model.imageHorizontal : this.model.imageVertical
    }

    getShowers = () => {
        const showers = {
            30: {
                h: 3,
                v: 4
            },
            50: {
                h: 2,
                v: 2
            },
            80: {
                h: 3,
                v: 4
            },
            100: {
                h: 3,
                v: 4
            },
        }; 

        return showers[this.volume][this.position];
    }

    renderShowers = () => {

        const temp = this.device.DeviceStatus[this.model.commands.tempValue];
        let showers = [];

        for (let i = 1; i <= temp; i++) {
            showers.push(<svg className="shower" key={`shower_${i}`}><use xlinkHref="#icon-shower"></use></svg>);
        }

        return showers;
    }

    renderShowersProgress = (inner = false) => {

        const { DeviceStatus: status } = this.device;
        const maxShowers = this.getShowers();
        let showers = [];
        const initialClass = inner ? 'range-shower__item' : 'shower';

        for (let i = 1; i <= maxShowers; i++) {

            let progressClassName = inner ? "" : "shower--grey";

            if (status[this.model.commands.power] == this.model.powerOn) {
        
                if (status[this.model.commands.tempValueCurrent] >= i) {
                    progressClassName = 'range-shower__item--green';
                } else if (status[this.model.commands.tempValue] >= i && status.Text === 'HEATING') {
                    progressClassName = inner ? 'range-shower__item--red' : 'shower--red'
                }
            }

            showers.push(<svg className={`${initialClass} ${progressClassName}`} key={`progress_shower_${i}`}><use xlinkHref="#icon-shower"></use></svg>);
        }

        return showers;
    }

    renderSLiderDigits = () => {

        const maxShowers = this.getShowers();
        let digits = [];

        for (let i = 0; i <= maxShowers; i++) {

            digits.push(<div className={`range-circle__didgit range-circle__didgit--${i}`} key={`slider-digits-${i}`}>{i}</div>);
        }

        return digits;
    }


    getSliderAngles = () => {
        const angles = {
            2: [
                { 'temp': 0, 'angle': -49 },
                { 'temp': 1, 'angle': 45 },
                { 'temp': 2, 'angle': 137 },
            ],
            3: [
                { 'temp': 0, 'angle': -49 },
                { 'temp': 1, 'angle': 45 },
                { 'temp': 2, 'angle': 136 },
                { 'temp': 3, 'angle': 230 },
            ],
            4: [
                { 'temp': 0, 'angle': -49 },
                { 'temp': 1, 'angle': 16.5 },
                { 'temp': 2, 'angle': 90 },
                { 'temp': 3, 'angle': 164 },
                { 'temp': 4, 'angle': 230 },
            ]
        }

        return angles[this.getShowers()];
    }
}

export default BelislimoHelper;