import {
    IonContent,
    IonFooter,
    IonButton,
    IonPage,
    IonToolbar,
    IonText,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonCheckbox,
    IonRow
  } from '@ionic/react';
  
import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { Device } from '@capacitor/device';
import dayjs from 'dayjs';
import axios from 'axios';

import {
    chooseNetworkPass,
    setModelPower,
    setDeviceMacAddress,
    setDeviceModel,
    setDeviceApPassword
} from '../../../../redux/actions/NewDeviceAction';
import { setAppMessage, logger } from '../../../../redux/actions/AppAction';

import StandartHeader from '../../components/header/StandartHeader';
import ToastMessage from '../../components/toast/ToastMessage';
import Loading from '../../components/loading/Loading';
import {
    modelsSettings, 
    cloud_url, 
    development_cloud_url, 
    app_mode, 
    cn05uvSettings, 
    dev_rest_api_address, 
    rest_api_address
} from '../../../../config/App'; 
import DeviceRestHelper from '../../../../helpers/DeviceRestHelper';
import WifiHelper from '../../../../helpers/WifiHelper';
import RestHelper from '../../../../helpers/RestHelper';

class WiFiPassword extends React.Component {

    state = {
        passwordShown: false,
        password: "",
        no_password: false,
        showLoading: false,
        appInfo: {}
    }

    componentDidMount = async () => {

        const info = await Device.getInfo();
        this.setState({
            appInfo: info
        })
    }

    //Connect to device network and send data
    continue = async () => {

        if (this.state.password !== "" || this.state.no_password === true) {

            this.props.logger(dayjs().format("HH:mm:ss")+" STEP 2: Send Wifi and Pass to device \n\n");

            this.setState({
                showLoading: true
            })

            this.props.chooseNetworkPass(this.state.password, this.state.no_password);

            try {
                // if (this.state.appInfo.platform === 'ios') {
                //     await WifiHelper.connectToNetworkIOS(this.props.newDevice.deviceSSID);
                // } else {
                //     await WifiHelper.connectToNetwork(this.props.newDevice.deviceSSID);
                // }
                

                //TO DO: Check for the right model from device SSID
                let [deviceType, deviceModel, deviceMac] = this.props.newDevice.deviceSSID.split("-");
                deviceModel = 'cn06as';
               
                const currentTime = dayjs().format("HH:mm:ss");
                global.debug(`Current Time: ${currentTime}`);

                const ap_pass = Math.random().toString().substring(2, 12);
                const deviceRest = new DeviceRestHelper(modelsSettings[deviceModel].http_api_url);

                this.props.logger(`WifiPassword: Sending to device: wifi ssid: ${this.props.newDevice.networkSSID}, wifi pass: ${this.state.password}`);

                //TO DO: What we are doing with TIME. How we send it. The problem is that we need sent it here otherwise we are loosing  connection to the dvice because it's connected to WIFI
                //TO DO: Gives an AP_NAME to device

                global.debug(`cloud_url: ${app_mode === 'development' ? development_cloud_url : cloud_url}`);

                deviceRest.post('/', {
                    cloud_url: app_mode === 'development' ? development_cloud_url : cloud_url, 
                    http_api_url: modelsSettings[deviceModel].http_api_url, 
                    ap_pass,
                    wifiSSID: this.props.newDevice.networkSSID,
                    wifiPass: this.state.password,
                    time: currentTime
                })
                .then(async res => {

                    global.debug(res.data);
                    let {connected, mac, model, power} = res.data;
                    //TO DO: power is hardcoded
                    power = cn05uvSettings.power;

                    if (connected === true) {

                        const info = await Device.getInfo();
                        if (info.platform === 'ios') {
                            WifiHelper.disconnectFromNetworkIOS(this.props.newDevice.deviceSSID);
                        } else {
                            WifiHelper.disconnect(this.props.newDevice.deviceSSID);
                        }

                        //WifiHelper.disconnect(this.props.newDevice.deviceSSID);

                        setTimeout(() => {

                            try {
                                //Provision CN06 because this is the only way we can do it. (it's not available in the FW)
                                axios.post(`${app_mode === 'development' ? dev_rest_api_address : rest_api_address}/provisioning-device`, {
                                    model: "cn05uv",
                                    mac: mac,
                                    skipIpCheck: true
                                }, {
                                    headers: {
                                        Authorization: "Bearer eyJpdiI6InE1L2pnWnhnV1pPcWhmS0RrK210T1E9PSIsInZhbHVlIjoiRGowVU01Rm5rQUk0aTBKZ0xCemxtSVltWlZqQkZyVEx2OEJGYzVsVEJBaz0iLCJtYWMiOiI2YTY5MjYwMGVjNDFjNGVlOWZmZGMwMmI3NGZhN2NkZGQ0ZWQwOWRiZDA2NWI5YWJmYjIxOGNmMDcxOWJiYWYwIiwidGFnIjoiIn0="
                                    }
                                })

                                this.setState({
                                    passwordShown: false,
                                    password: "",
                                    no_password: false,
                                    showLoading: false,
                                    appInfo: {}
                                })
        
                                this.props.setDeviceMacAddress(mac);
                                this.props.setDeviceModel(model);
                                this.props.setModelPower(power);
                                this.props.setDeviceApPassword(ap_pass);
        
                                this.props.history.push('/cn06/device-settings');

                            } catch (e) {
                                this.props.history.push('/unsuccessful-regstriration');
                            }

                        }, 10000)

                        
                    } else {
                        //TO DO: what to do here
                    }
                })
                .catch (error => {

                    global.debug("FIrst catch");
                    this.setState({
                        showLoading: false
                    })
                    
                    this.props.logger(`WifiPassword: Error while sending data to device: ${error.message}`);
                    this.props.setAppMessage(this.props.t('device_connection_problem'));
                    this.props.history.push('/cn06/choose-device');
                })

            } catch (e) {

                global.debug("SEcond catch");
                this.setState({
                    showLoading: false
                })

                this.props.logger(`WifiPassword: Cannot send data to device: ${e.message}`);
                this.props.setAppMessage(this.props.t('device_connection_problem'));
                this.props.history.push('/');
            }
        } else {
            this.props.setAppMessage(this.props.t('required_wifi_password'));
        }

    }

    render = () => {

        const { t } = this.props;

        return (
            <IonPage>
            
                <StandartHeader name={t('wifi_password')}/>

                <IonContent className="ion-padding">
                    <IonText className="ion-text-center">
                        <h1>{t('enter_wifi_pass')}:</h1>
                        <IonRow className="ion-align-items-center ion-justify-content-center ion-nowrap" style={{ marginTop: '8px', fontSize: '16px', wordBreak: 'break-all' }}>
                            <svg className="size-24 fill-green" style={{ marginRight: '11px'}}><use xlinkHref="#icon-wifi"></use></svg>
                            <span>{this.props.newDevice.networkSSID}</span>
                        </IonRow>
                    </IonText>

                    <IonList>
                        <IonItem lines="full" className="form-list__item">
                            <IonLabel position="stacked" color="primary" className="form-label">{t('password')}</IonLabel>
                            <div className="form-password">
                                <IonInput 
                                    type={this.state.passwordShown ? "text" : "password"} 
                                    className="form-input" 
                                    onIonInput={e => this.setState({password: e.detail.value})}
                                    value={this.state.password}
                                />
                                <button type="button" ion-button="true" className="form-password__button" onClick={() => this.setState({passwordShown: this.state.passwordShown ? false : true})}>
                                <svg className="form-password__button-icon"><use xlinkHref={`#icon-eye-${this.state.passwordShown ? 'closed' : 'open'}`}></use></svg>
                                </button>
                            </div>
                        </IonItem>
                        <IonItem lines="none" style={{ paddingTop: '10px'}}>
                            <IonLabel className="form-checkbox">
                                <IonCheckbox 
                                    color="primary" 
                                    className="form-checkbox__element" 
                                    onIonChange={e => this.setState({no_password: e.detail.checked})}
                                />
                                <div className="form-checkbox__text">{t('network_no_pass')}</div>
                            </IonLabel>
                        </IonItem>
                    </IonList>

                    <ToastMessage />
                    <Loading show={this.state.showLoading} message={t('please_confirm')}/>
                </IonContent>
                
                <IonFooter className="app-footer ion-no-border">
                    <IonToolbar>
                        <div className="buttons-list">
                            <div className="buttons-list__item">
                                <IonButton expand="block" shape="round" size="large" onClick={this.continue}>{t('continue')}</IonButton>
                            </div>
                            {/* TO DO: Ask for these settings */}
                            {/* <div className="buttons-list__item">
                                <IonButton expand="block" shape="round" size="large" color="help">Advanced network settings</IonButton>
                            </div> */}
                        </div>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        );
    }
};

const mapStateToProps = state => {
    return {
        newDevice: state.newDevice
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        chooseNetworkPass,
        setDeviceModel,
        setDeviceMacAddress,
        setModelPower,
        setDeviceApPassword,
        setAppMessage,
        logger
    })
)(WiFiPassword);
  