import {
    IonContent,
    IonPage,
    IonButton,
    IonRow,
    IonCol,
    IonLabel,
    IonRadioGroup,
    IonRadio,
    IonAlert
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import Header from './components/header/Header';
import PageHeader from './components/header/PageHeader';
import Footer from './components/footer/Footer';
import DeviceCN05UVMenu from './components/menu/DeviceCN05UVMenu';
import ToastMessage from './components/toast/ToastMessage';
import HeatPumpMenu from './HPWH4_11_260/components/HeatPumpMenu';

import { setAppMessage } from '../../redux/actions/AppAction';


import RestHelper from '../../helpers/RestHelper';
import {detectDeviceType} from '../../helpers/FunctionsHelper';
import InitialSetttings from '../../init';
import E32Menu from './E32/components/E32Menu';
import B15Menu from './B15/components/Menu';

class AddToGroup extends React.Component {

    constructor(props) {
        super(props);

        this.groups = null;
        this.mac = null;
        this.action = null;
        this.RestHelper = null;
        this.InitialSetttings = null;
        this.selectedGroupID = 0;
        this.state = {
            messageColor: "warning",
            showAlert: false,
            groupName: "",
            groupID: 0
        }
    }

    componentDidMount = () => {
        this.RestHelper = new RestHelper();
        this.InitialSetttings = new InitialSetttings();
    }

    handleDelete = (id, name) => {

        this.setState({
            showAlert: true,
            groupName: name,
            groupID: id
        })
    }

    handleAdd = async () => {

        if (this.state.selectedGroupID > 0) {

            const resp = await this.RestHelper.post('/add-device-to-group', {
                group_id: this.state.selectedGroupID,
                mac: this.mac
            });
    
            if (resp.data.success === false) {
    
                this.setState({
                    messageColor: "warning"
                });  
    
                this.props.setAppMessage(this.props.t('cannot_add_device_to_group'));  
                
            } else {
    
                this.setState({
                    messageColor: "success"
                });
    
                this.props.setAppMessage(this.props.t('successfully_addedd_device_to_group'));  
    
                await this.InitialSetttings.getMyGroups();
            }

        } else {
            this.props.setAppMessage(this.props.t('select_group'));  
        }
        
    };

    renderGroups = () => {
        const groups = [];

        for (const i in this.groups ) {

            const { name, id, devices, image } = this.groups[i];

            groups.push(
                <div className="group-list__item" key={i}>
                    <header className="group-list__header">
                        <div className="group-list__header-start">
                        {!devices.includes(this.mac) &&
                            <IonLabel className="form-checkbox">
                                <IonRadio color="primary" className="form-checkbox__element" name="groups" value={id} />
                            </IonLabel>
                        }
                        </div>
                        <div className="group-list__header-middle">
                            <img src={image} alt="" className="group-list__header-image" />
                        </div>
                        <div className="group-list__header-end">
                            <div className="group-list__title">{name}</div>
                            <div className="group-list__sub-title">
                                {devices.length} {(devices.length > 1 || devices.length === 0)? this.props.t('g_devices') : this.props.t('g_device')} 
                            </div>
                        </div>
                        <div className="group-list__header-controls">
                            <IonButton color="transparent" style={{ marginRight: '12px' }} routerLink={`/edit-group/${id}`}>
                                <svg className="size-20 fill-green"><use xlinkHref="#icon-pencil"></use></svg>
                            </IonButton>
                            <IonButton color="transparent" onClick={ (e) => this.handleDelete(id, name) }>
                                <svg className="size-20 fill-red"><use xlinkHref="#icon-delete"></use></svg>
                            </IonButton>
                        </div>
                    </header>
                </div>
            )
        }

        return groups;
    }

    renderMenu = () => {

        const {model} = this.props.devices[this.mac];
        const deviceType = detectDeviceType(model);

        switch(deviceType) {
            case 'HeatPump': 
                return <HeatPumpMenu activeMenu="deviceGroup" mac={this.props.match.params.mac_address}/>;
            case 'Boiler_E32': 
                return <E32Menu activeMenu="deviceGroup" mac={this.props.match.params.mac_address}/>;
            case 'Boiler_B15': 
                return <B15Menu activeMenu="deviceGroup" mac={this.props.match.params.mac_address}/>;
            default:
                return <DeviceCN05UVMenu activeMenu="deviceGroup"  mac={this.props.match.params.mac_address}/>;
        }
    }


    render = () => {

        const { t, match: {params: { mac_address: mac, action }}, groups } = this.props;

        this.groups = groups;
        this.mac = mac;

        return (
            <IonPage>
                <Header />
                <IonContent className="ion-no-padding">
                    <div className="dashboard">
                        <div className="dashboard__wrapper">
                            
                            {this.renderMenu()}

                            <div className="dashboard__content">
                                <IonRow className="ion-justify-content-between ion-align-items-center mb-32">
                                    <IonCol size="auto" className="p-0">
                                        <PageHeader name={t('add_to_group')} image="add-to-folder"/>
                                    </IonCol>
                                    <IonCol size="auto" className="p-0">
                                        <IonButton color="icon" routerLink={`/create-group/${this.mac}`}>
                                            <svg className="icon"><use xlinkHref="#icon-add"></use></svg>
                                            <span className="button__text">{t('create_group')}</span>
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                                <div className="dashboard-box">
                                    <div className="dashboard-box__body">
                                        <IonRadioGroup value={this.state.selectedGroupID} onIonChange={e => this.setState({selectedGroupID: e.detail.value})}>
                                            <div className="group-list">
                                                {this.renderGroups()}
                                            </div>
                                        </IonRadioGroup>
                                        <div className="pt-20"></div>
                                        <div className="ion-text-center">
                                            <IonButton color="primary" onClick={this.handleAdd}>
                                                <span className="button__text">{t('save')}</span>
                                            </IonButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ToastMessage color={this.state.messageColor}/>

                    <IonAlert
                        isOpen={this.state.showAlert}
                        onDidDismiss={() => this.setState({showAlert: false})}
                        header={t('delete_group')}
                        message={`${t('do_you_want_to_delete')} ${this.state.groupName}`}
                        buttons={
                            [
                                {
                                    text: t('cancel'),
                                    handler: () => {
                                        this.setState({
                                            showAlert: false
                                        })
                                    }
                                },
                                {
                                    text: t('confirm'),
                                    handler: async () => {

                                        await this.RestHelper.post('/delete-group', {
                                            id: this.state.groupID    
                                        });

                                        this.InitialSetttings.getMyGroups();

                                    }
                                }
                            ]
                        }
                    />

                </IonContent>
                <Footer />
            </IonPage>
        );
    }
    
};

const mapStateToProps = state => {

    return {
        groups: state.groups,
        devices: state.devices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        setAppMessage
    })
)(AddToGroup)