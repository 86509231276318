import React  from 'react';

import {
  IonPage,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
} from '@ionic/react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import HeaderWelcome from './components/header/HeaderWelcome';
import Footer from './components/footer/Footer';
import Welcome from './components/welcome/Welcome';
import FooterTwoButtons from './components/footer/FooterTwoButtons';
import ToastMessage from './components/toast/ToastMessage';
import Loader from './components/loading/Loading';

//Import helpers
import UsersHelper from '../../helpers/UsersHelper';

//Import Redux Actions
import { setAppMessage } from '../../redux/actions/AppAction';


class ForgottenPassword extends React.Component {

    constructor (props) {

        super(props);
    
        this.UsersHelper = new UsersHelper();
        this.state = {
            showLoading: false,
            email: '',
            errors: {},
        }
    }

    recoverPassword = async () => {

        this.setState({showLoading: true});

        try {

            const response = await this.UsersHelper.recoverPassword(this.state.email);

            this.setState({showLoading: false});

            this.props.setAppMessage(this.props.t('success_recover_pass'));

            this.setState({
                errors: {}
            })

        } catch (e) {

            this.setState({showLoading: false});

            this.setState({
                errors: e.errors
            })
        }
    }

    render = () => {

        const { t } = this.props;

        return (
            <IonPage>
                <HeaderWelcome />
                <IonContent className="ion-no-padding">
                    <div className="welcome">
        
                        <Welcome />
                        
                        <div className="welcome__end">
                            <div className="welcome__end-inner" style={{ backgroundColor: 'white', borderRadius: '10px', padding: '32px' }}>
                                <IonList>
                                    <IonItem lines="none" className={`form-list__item ${'email' in this.state.errors ? 'is-invalid' : ''}`}>
                                        <IonLabel position="stacked" color="primary" className="form-label" >{t('email')}</IonLabel>
                                        <IonInput type="email" className="form-input" onIonInput={ e => this.setState({email: e.detail.value})} />
                                        {'email' in this.state.errors ? (<div className="invalid-message">{this.state.errors.email}</div>) : null}
                                    </IonItem>
                                    <IonItem lines="none" className="form-list__item">
                                        <div className="ion-text-center ion-padding-top" style={{ width: '100%' }}>
                                            <FooterTwoButtons save={this.recoverPassword} secondButtonName={this.props.t('recover_password')}/>
                                        </div>
                                    </IonItem>
                                </IonList>
                            </div>
                        </div>
                    </div>
                    <ToastMessage color={"success"}/>
                </IonContent>
                <Footer />
                <Loader show={this.state.showLoading}/>
            </IonPage>
        );
    }
    
};

export default compose(
    withTranslation(),
    connect(null, {setAppMessage})
)(ForgottenPassword);