import {
    IonPage,
    IonContent,
    IonText
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import Header from './components/header/Header';
import PageHeader from './components/header/PageHeader';
import Footer from './components/footer/Footer';
import DashboardMenu from './components/menu/DashboardMenu';

class ViewNotification extends React.Component {

    render = () => {

        const { app: { push_notifications_messages }, t } = this.props;

        let notification = {};

        for (const i in push_notifications_messages) {
            const { id } = push_notifications_messages[i];

            if (id == this.props.match.params.message_id) {
                notification = push_notifications_messages[i]; 
            }
        }

        const [model, title_key] = notification.title.split(': ');
        const [modelBody, body_key] = notification.body.split(': ');

        return (
            <IonPage>
                <Header />
                <IonContent className="ion-no-padding">
                    <div className="dashboard">
                        <div className="dashboard__wrapper">
                            
                            <DashboardMenu />

                            <div className="dashboard__content">
                            
                            <PageHeader name={t('notifications')} image="notifications"/>

                            <div className="dashboard-box">
                                <div className="dashboard-box__body">
                                    <IonText>
                                        <div className="ion-text-center">
                                            <h1>{model}: {t(title_key)}</h1>
                                            <p>{notification.date}</p>
                                        </div>
                                        <p>{model}: {t(body_key)}</p>
                                    </IonText>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </IonContent>
                <Footer />
            </IonPage>
        );
    }

};

const mapStateToProps = state => {
    return {
        app: state.app
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(ViewNotification);  