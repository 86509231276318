import store from '../redux/createStore';
import RestHelper from './RestHelper';
import DeviceSettingsHelper from './DeviceSettingsHelper';
import HeatPumpMqttHelper from "./mqqt/HeatPumpMqttHelper";
import E32MqttHelper from "./mqqt/E32MqttHelper";
import B15MqttHelper from "./mqqt/B15MqttHelper";
import { updateGroupState } from '../redux/actions/GroupsAction';

class GroupHelper {

    constructor() {
        this.RestHelper = new RestHelper();

        const state = store.getState();
        this.groups = state.groups;
        this.devices = state.devices;
    }

    handleOnOff = (groupID, status) => {

        const group = this.findAndUpdateGroup({groupID, field: "status", value: status});
        const { devices } = group;

        devices.map(mac => {
            
            const device = this.devices[mac];
            const heatPumpModels = ['HPWH4_11_260', 'HPWH4_11_200'];

            if (device.model == "cn05uv") {
                const deviceSettings = new DeviceSettingsHelper(mac);
                deviceSettings.handleOnOff(status);
            }
            
            if (heatPumpModels.includes(device.model)) {
                const HeatPumpMqttHelperOBJ = new HeatPumpMqttHelper(mac);
                HeatPumpMqttHelperOBJ.request('powerOnOff', {state: device.state.power_status == 'on' ? 'off' : 'on'})
            }

            if (device.model == "E32") {
                const E32MqttHelperOBJ = new E32MqttHelper(mac);
                E32MqttHelperOBJ.request("setStandby", {setState: device.state.stand_by == 'no' ? 'yes' : 'no'})
            }

            if (device.model == "B15") {
                const B15MqttHelperOBJ = new B15MqttHelper(mac);
                B15MqttHelperOBJ.request("setStandby", {setState: device.state.stand_by == 'no' ? 'yes' : 'no'})
            }
        });
    }

    findAndUpdateGroup = ({groupID, field, value}) => {

        let group = {};

        //UPDATE IN REST
        this.RestHelper.post('/update-group-state', {
            field,
            value,
            id: groupID
        });

        //UPDATE IN REDUX
        store.dispatch(updateGroupState(groupID, field, value));


        for (const i in this.groups) {
            const { id } = this.groups[i];

            if (id == groupID) {
                group =  this.groups[i];  
                break; 
            }
        }

        return group;
    }
    
}

export default GroupHelper;