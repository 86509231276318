import Slider from '@mui/material/Slider';
import React, {useState, useEffect} from 'react';

const TemperatureSlider = props => {

    const [selectedTemperature, setSelectedTemperature] = useState(props.currentTemp);

    useEffect(() => {
        setSelectedTemperature(props.currentTemp)
    }, [props.currentTemp]);

    const handleTemperatureChange = (event, value) => {
        setSelectedTemperature(value);
    }

    const handleTemperatureChangeComitted = (event, value) => {
        props.handleTemperature(value);
    }

    let degreeUnit = "°C";
    if (props.model != undefined && props.model.indexOf('2002') >= 0) {
        degreeUnit = '';
    }

    return (

        <div className="range-slider__wrapper">
            <header className={`range-slider__header ${props.disabled === true ? 'disabled' : ''}`}>
                <div className="range-slider__header-item">
                    <div className="range-slider__header-label">{props.label}</div>
                </div>
                <div className="range-slider__header-item">
                    <div className="range-slider__output">{selectedTemperature} {degreeUnit}</div>
                </div>
            </header>
            <Slider
                value={selectedTemperature}
                min={props.minTemperature}
                max={props.maxTemperature}
                step={props.stepTemperature !== undefined ? props.stepTemperature : 0.5} 
                track="inverted"
                onChange={handleTemperatureChange}
                onChangeCommitted={handleTemperatureChangeComitted}
                disabled={props.disabled}
                valueLabelDisplay="off"
                className="range-slider range-slider--temperature device-range-slider"
            />
        </div>
    )
}

export default TemperatureSlider;