import {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonSelect,
    IonSelectOption
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import StandartHeader from '../components/header/StandartHeader';
import TwoButtons from '../components/footer/TwoButtons';
import OldDevicesSettingsHelper from '../../../helpers/OldDevicesSettingsHelper';
import BelislimoHelper from '../../../helpers/BelislimoHelper';
import {oldModels} from '../../../config/App';
import RangeStartTemperature from '../components/range/RangeStartTemperature';
import {updateOldDeviceState} from '../../../redux/actions/OldDevicesAction';

class Vacation extends React.Component {

    constructor(props) {

        super(props);

        this.mac = null;
        this.device = null;
        this.OldDevicesSettingsHelper = null

        this.state = {
            month: 1,
            monthDay: 1,
            hour: 0,
            vacTemp: 0
        }
    
    }

    componentDidMount = () => {     
        this.OldDevicesSettingsHelper = new OldDevicesSettingsHelper(this.mac);  

        const {vacDate, vacTemp} = this.device.DeviceStatus;

        if (vacDate != undefined && vacTemp != undefined) {

            const date = new Date(vacDate);    

            
            this.setState({
                month: date.getMonth() + 1,
                monthDay: date.getDate(),
                hour: date.getHours(),
                vacTemp: vacTemp
            });

        } else {

            const currentDate = new Date();
            const nextWeekDate = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);

            this.setState({
                month: nextWeekDate.getMonth() + 1,
                monthDay: nextWeekDate.getDate(),
                hour: nextWeekDate.getHours(),
            });
        }
        
    }

    setTime = time => {
        let [hour, min] = time.split(":");
        hour = Number(hour);
        min = Number(min);

        this.setState({
            time: hour*60 + min
        })
    }

    renderMonthDays = () => {

        const currentDate = new Date();
        const monthDays = [];

        const daysInMoth = {
            1: 31,
            2: currentDate.getFullYear() % 4 == 0 ? 29 : 28,
            3: 31,
            4: 30,
            5: 31,
            6: 30,
            7: 31,
            8: 31,
            9: 30,
            10: 31,
            11: 30,
            12: 31
        }

        for (let i = 1; i <= daysInMoth[this.state.month]; i++) {
            monthDays.push(<IonSelectOption key={i} value={i}>{i}</IonSelectOption>);
        }

        return monthDays;
    }

    renderHours = () => {

        const hours = [];

        for (let i = 0; i < 24; i++) {
            hours.push(<IonSelectOption key={i} value={i}>{i}:00</IonSelectOption>);
        }

        return hours;
    }

    save = () => {

        const currentDate = new Date();

        const date = new Date(currentDate.getFullYear(), (this.state.month - 1), this.state.monthDay);
        const weekDay = date.getDay();

        this.OldDevicesSettingsHelper.setDeviceStatus(
            'setVacation', 
            `vYear%3D${currentDate.getFullYear().toString().substr(-2)}%26vMonth%3D${this.state.month}%26vMDay%3D${this.state.monthDay}%26vWDay%3D${weekDay}%26vHour%3D${this.state.hour}%26vTemp%3D${this.state.vacTemp}`
        );

        this.props.updateOldDeviceState( this.model.modes['Vacation'], this.model.commands.mode, this.device.DeviceSerial);
        
        this.OldDevicesSettingsHelper.setDeviceStatus(this.model.commands.mode, this.model.modes['Vacation']);

        this.props.history.goBack();
    }

    render = () => {

        const { t } = this.props;
        this.mac = this.props.match.params.id;

        const {devices} = this.props;

        this.device = devices[this.mac];
        this.model = oldModels[this.props.match.params.model_name];
        
        if (!this.device || this.device === undefined) {
            return null;
        }

        let minTemp = null;
        let maxTemp = null;
        

        if (this.props.match.params.model_name == '2002-v1') {

            const bHelper = new BelislimoHelper(this.device );
            minTemp = 0;
            maxTemp = bHelper.getShowers();

        } else {
            minTemp = this.model.minTemp;
            maxTemp = this.model.maxTemp;
        }
        
        let vacTemp = this.device.DeviceStatus.vacTemp != undefined ? this.device.DeviceStatus.vacTemp : minTemp;
        if (vacTemp > maxTemp) {
            vacTemp = maxTemp;
        }

        const { DeviceStatus: {dtime} } = this.device;

        return (
            <IonPage>
                
                <StandartHeader name={t('Vacation')}/>
                <IonContent className="ion-padding"> 

                    <div className="ion-text-center ">
                        <svg className="size-80 mx-auto fill-light-blue mt-20">
                            <use xlinkHref="#icon-plane"></use>
                        </svg>
                        <p style={{fontSize: '16px', color: 'var(--color-gray-19)'}}>{t('coming_from_vacation')}</p>
                    </div>

                    <div className="preferences-list__wrapper">
                        <IonList className="preferences-list preferences-list--editable">

                            <IonItem className="preferences-list__item" lines="none">
                                <div className="preferences-list__inner">
                                    <div className="preferences-list__item-middle">
                                        <IonSelect 
                                            placeholder={`${t('month')}....`}
                                            value={this.state.month}
                                            onIonChange = {e => this.setState({month:e.detail.value})}
                                        >
                                            <IonSelectOption value={1}>{t('January')}</IonSelectOption>
                                            <IonSelectOption value={2}>{t('February')}</IonSelectOption>
                                            <IonSelectOption value={3}>{t('March')}</IonSelectOption>
                                            <IonSelectOption value={4}>{t('April')}</IonSelectOption>
                                            <IonSelectOption value={5}>{t('May')}</IonSelectOption>
                                            <IonSelectOption value={6}>{t('June')}</IonSelectOption>
                                            <IonSelectOption value={7}>{t('July')}</IonSelectOption>
                                            <IonSelectOption value={8}>{t('August')}</IonSelectOption>
                                            <IonSelectOption value={9}>{t('September')}</IonSelectOption>
                                            <IonSelectOption value={10}>{t('October')}</IonSelectOption>
                                            <IonSelectOption value={11}>{t('November')}</IonSelectOption>
                                            <IonSelectOption value={12}>{t('December')}</IonSelectOption>
                                        </IonSelect>
                                    </div>
                                </div>
                            </IonItem>

                            <IonItem className="preferences-list__item" lines="none">
                                <div className="preferences-list__inner">
                                    <div className="preferences-list__item-middle">
                                        <IonSelect 
                                            placeholder={`${t('monthDay')}....`}
                                            value={this.state.monthDay}
                                            onIonChange = {e => this.setState({monthDay: e.detail.value})}
                                        >
                                            {this.renderMonthDays()}    
                                        </IonSelect>
                                    </div>
                                </div>
                            </IonItem>

                            <IonItem className="preferences-list__item" lines="none">
                                <div className="preferences-list__inner">
                                    <div className="preferences-list__item-middle">
                                        <IonSelect 
                                            placeholder={`${t('hour')}....`}
                                            value={this.state.hour}
                                            onIonChange = {e => this.setState({hour: e.detail.value})}
                                        >
                                            {this.renderHours()}    
                                        </IonSelect>
                                    </div>
                                </div>
                            </IonItem>

                            <IonItem className="preferences-list__item" lines="none">
                                <div className="preferences-list__inner">
                                    <div className="preferences-list__item-middle">
                                        <RangeStartTemperature 
                                            data-min={minTemp} 
                                            data-max={maxTemp}  
                                            model={this.props.match.params.model_name}
                                            data-start={vacTemp} 
                                            data-step="1" 
                                            data-label={this.props.match.params.model_name == '2002-v1' ? t('ewh_number_of_showers') : t('ewh_target_temperature')}
                                            setTemp={temp => this.setState({vacTemp: temp})}
                                        />
                                    </div>
                                </div>
                            </IonItem>
                        </IonList>
                    </div>
                    <div className="ion-content-push"></div>
                </IonContent>
                <TwoButtons 
                    redirect={`/device-settings/${this.mac}`}
                    save={() => this.save()}
                />
                
            </IonPage>
        );         
    }
    
};

const mapStateToProps = state => {
    return {
        devices: state.oldDevices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        updateOldDeviceState
    })
)(Vacation);