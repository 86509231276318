import {
    IonContent,
    IonFooter,
    IonButton,
    IonPage,
    IonToolbar,
    IonText
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';

import StandartHeader from './components/header/StandartHeader';

class ProvisioningError extends React.Component {

    render = () => {
        return (
            <IonPage>

            <StandartHeader name={this.props.t('ProvisioningError')} />

            <IonContent className="ion-padding">
                <IonText>
                    {/* TO DO: Ask for text*/}
                    <h1>{this.props.t('ProvisioningError_text1')} </h1>
                    <ul className="ul-list">
                        <li className="ul-list__item">{this.props.t('ProvisioningError_text2')}</li>
                    </ul>
                </IonText>
            </IonContent>
            
            <IonFooter className="app-footer ion-no-border">
                <IonToolbar>
                    <div className="buttons-list">
                        <div className="buttons-list__item">
                            <IonButton 
                                expand="block" 
                                shape="round" 
                                size="large" 
                                routerLink="/dashboard"
                            >
                                {this.props.t('ProvisioningError_button')}
                            </IonButton>
                        </div>
                    </div>
                </IonToolbar>
            </IonFooter>
            </IonPage>
        );
    }
};

export default withTranslation()(ProvisioningError);
  