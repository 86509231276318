import axios from 'axios';
import qs from "qs";
import store from '../redux/createStore';
import { logger } from '../redux/actions/AppAction';

class DeviceRestHelper {

    constructor(host) {
        
        this.axios_instance = axios.create({
            baseURL: host
        });
    }

    post = async (url, payload) => {
        try {
            return await this.axios_instance.post(url, qs.stringify({data: JSON.stringify(payload)}), {
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            });
        } catch (e) {
            store.dispatch(logger(`DeviceRestHelper: Can't make POST request to device: ${e.message}`));
            //throw `DeviceRestHelper: Can't make POST request to device: ${e.message}`;
            throw Error(`DeviceRestHelper: Can't make POST request to device: ${e.message}`);
        }
    }
}

export default DeviceRestHelper;