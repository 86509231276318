export const app_mode = 'production'; //development / production
export const rest_api_address = 'https://ad.mytesy.com/rest';
export const dev_rest_api_address = 'https://ad.staging.mytesy.com/rest';
//export const dev_rest_api_address = 'http://127.0.0.1:8001/rest';
export const cloud_url = 'mqtt.tesy.com';
export const development_cloud_url = 'staging.mqtt.tesy.com';
export const appMqttVersion = 'v1';
export const app_version = '1.1';
export const netkom_secret_code = '75335bcc-a620-4e07-87a6-9df80cb7c67d';

//TO DO: Ask for the settings
export const cn05uvSettings = {
    min_temp: 10,
    max_temp: 30,
    start_temp: 10,
    step_temp: 1,
    min_eco_temp: 10,
    max_eco_temp: 30,
    min_eco_temp_time: 1800, //0:30 hours in sec 
    max_eco_temp_time: 86400, //24 hours in sec 
    min_comfort_temp: 10,
    max_comfort_temp: 30,
    max_comfort_time: 86400,//24 hours in sec 
    min_sleep_temp: 10,
    max_sleep_temp: 30,
    max_sleep_time: 54000, //15 hours in sec 
    min_sleep_time: 9000, //2:30 hours in sec
    max_delayed_time: 345600, //96 hours in sec,
    min_delayed_time: 3600, //1 hour in sec,
    power: "500,600,1000,1400,1500,2000,2500,3000",
    modes: {
        off: 'off',
        heating: 'heating',
        comfort: 'comfort',
        eco: 'eco',
        sleep: 'sleep',
        delayStart: 'delay start',
        program: 'program' 
    },
    modesInfo: {
        'comfort': {
            'class': 'fill-light-red',
            'image': '#icon-comfort',
            'path': '/comfort-temp'
        },
        'eco': {
            'class': 'fill-light-green',
            'image': '#icon-eco',
            'path': '/eco-temp'
        },
        'sleep': {
            'class': 'fill-light-blue',
            'image': '#icon-moon',
            'path': '/sleep'
        },
        'delay start': {
            'class': 'fill-light-purple',
            'image': '#icon-clock',
            'path': '/delayed-start',
        },
        'program': {
            'class': '',
            'image': '',
            'path': ''
        },
        'off': {
            'class': '',
            'image': '',
            'path': ''
        },
        'heating': {
            'class': '',
            'image': '',
            'path': ''
        }
    },
    TController: [
        -4, -3, -2, -1, 0, 1, 2, 3, 4
    ] 
} 

export const oldModels = {
    '2000-v1' : {
        model: 'Modeco',
        image: '/assets/images/modeco.jpg',
        type: 'boiler',
        modes: {
            'Manual': "1",
            'Program P1': "2",
            'Program P2': "3",
            'Program P3': "4",
            'Eco': "5",
            'Eco1': "6",
            'Eco2': "7",
            'Vacation': "9",
        },
        modesSettings: {
            '2': {
                'class': '',
                'image': '',
                'path': ''
            },
            '3': {
                'class': '',
                'image': '',
                'path': ''
            },
            '4': {
                'class': '',
                'image': '',
                'path': ''
            },
            '5': {
                'class': 'fill-light-green',
                'image': '#icon-eco',
                'path': '',
                'path_web': '/boiler/apiv1/devices-settings'
            },
            '6': {
                'class': 'fill-light-green',
                'image': '#icon-eco-2',
                'path': '',
                'path_web': '/boiler/apiv1/devices-settings'
            },
            '7': {
                'class': 'fill-light-green',
                'image': '#icon-eco-1',
                'path': '',
                'path_web': '/boiler/apiv1/devices-settings'
            },
            '9': {
                'class': 'fill-light-green',
                'image': '#icon-palm',
                'path': '/old/vacation',
                'path_web': 'boiler/apiv1/devices-settings'
            },

        },
        minTemp: 15,
        maxTemp: 75,
        apiVersion: 'apiv1',
        powerOn: 'on',
        powerOff: 'off',
        commands: {
            temp: 'tmpT',
            power: 'power_sw',
            mode: 'mode',
            tempValue: 'ref_gradus',
            tempValueCurrent: 'gradus',
            boost: 'boost',
            err: 'err_flag'
        },
        ecoFunctions: ['Eco', 'Eco1', 'Eco2'],
        ecoIcons: {
            'Eco': 'eco',
            'Eco1': 'eco-2',
            'Eco2': 'eco-1',
        },
        functions: [],
        program: {
            useHaflHours: false,
            minTemp: 15,
            maxTemp: 75
        },
    },
    '2000-v2' : {
        model: 'Modeco',
        image: '/assets/images/modeco.jpg',
        type: 'boiler',
        modes: {
            'Manual': "0",
            'Program P1': "1",
            'Program P2': "2",
            'Program P3': "3",
            'Eco': "4",
            'Eco1': "5",
            'Eco2': "6",
            'Vacation': "10"
        },
        modesSettings: {
            '1': {
                'class': '',
                'image': '',
                'path': ''
            },
            '2': {
                'class': '',
                'image': '',
                'path': ''
            },
            '3': {
                'class': '',
                'image': '',
                'path': ''
            },
            '4': {
                'class': 'fill-light-green',
                'image': '#icon-eco',
                'path': ''
            },
            '5': {
                'class': 'fill-light-green',
                'image': '#icon-eco-2',
                'path': ''
            },
            '6': {
                'class': 'fill-light-green',
                'image': '#icon-eco-1',
                'path': ''
            },
            '10': {
                'class': 'fill-light-green',
                'image': '#icon-palm',
                'path': '/old/apiv2/vacation'
            },

        },
        minTemp: 15,
        maxTemp: 75,
        apiVersion: 'apiv2',
        powerOn: '1',
        powerOff: '0',
        commands: {
            temp: 'tmpT',
            power: 'pwr',
            mode: 'mode',
            tempValue: 'tmpR',
            tempValueCurrent: 'tmpC',
            boost: 'bst',
            err: 'err'
        },
        ecoFunctions: ['Eco', 'Eco1', 'Eco2'],
        ecoIcons: {
            'Eco': 'eco',
            'Eco1': 'eco-2',
            'Eco2': 'eco-1',
        },
        functions: [],
        program: {
            useHaflHours: false,
            minTemp: 15,
            maxTemp: 75
        },
    },
    '2004-v1' : {
        model: 'Modeco II',
        image: '/assets/images/modeco.jpg',
        type: 'boiler',
        modes: {
            'Manual': "1",
            'Program P1': "2",
            'Program P2': "3",
            'Program P3': "4",
            'Eco': "5",
            'Eco Comfort': "6",
            'Eco Night': "7",
            'Vacation': "9"
        },
        ecoFunctions: ['Eco', 'Eco Comfort', 'Eco Night'],
        ecoIcons: {
            'Eco': 'eco',
            'Eco Comfort': 'eco-2',
            'Eco Night': 'eco-1',
        },
        functions: [],
        program: {
            useHaflHours: false,
            minTemp: 15,
            maxTemp: 75
        },
        modesSettings: {
            '2': {
                'class': '',
                'image': '',
                'path': ''
            },
            '3': {
                'class': '',
                'image': '',
                'path': ''
            },
            '4': {
                'class': '',
                'image': '',
                'path': ''
            },
            '5': {
                'class': 'fill-light-green',
                'image': '#icon-eco',
                'path': '',
                'path_web': '/old/device-settings'
            },
            '6': {
                'class': 'fill-light-green',
                'image': '#icon-eco-2',
                'path': '',
                'path_web': '/old/device-settings'
            },
            '7': {
                'class': 'fill-light-green',
                'image': '#icon-eco-1',
                'path': '',
                'path_web': '/old/device-settings'
            },
            '9': {
                'class': 'fill-light-green',
                'image': '#icon-palm',
                'path': '/old/vacation',
                'path_web': '/old/device-settings'
            },

        },
        minTemp: 15,
        maxTemp: 75,
        apiVersion: 'apiv1',
        powerOn: 'on',
        powerOff: 'off',
        commands: {
            temp: 'tmpT',
            power: 'power_sw',
            mode: 'mode',
            tempValue: 'ref_gradus',
            tempValueCurrent: 'gradus',
            boost: 'boost',
            err: 'err_flag'
        }
    },
    '2004-v2' : {
        model: 'Modeco II',
        image: '/assets/images/modeco.jpg',
        type: 'boiler',
        modes: {
            'Manual': "0",
            'Program P1': "1",
            'Program P2': "2",
            'Program P3': "3",
            'Eco': "4",
            'Eco Comfort': "5",
            'Eco Night': "6",
            'Vacation': "10"
        },
        modesSettings: {
            '1': {
                'class': '',
                'image': '',
                'path': ''
            },
            '2': {
                'class': '',
                'image': '',
                'path': ''
            },
            '3': {
                'class': '',
                'image': '',
                'path': ''
            },
            '4': {
                'class': 'fill-light-green',
                'image': '#icon-eco',
                'path': ''
            },
            '5': {
                'class': 'fill-light-green',
                'image': '#icon-eco-2',
                'path': ''
            },
            '6': {
                'class': 'fill-light-green',
                'image': '#icon-eco-1',
                'path': ''
            },
            '10': {
                'class': 'fill-light-green',
                'image': '#icon-palm',
                'path': '/old/apiv2/vacation'
            },

        },
        minTemp: 15,
        maxTemp: 75,
        apiVersion: 'apiv2',
        powerOn: '1',
        powerOff: '0',
        commands: {
            temp: 'tmpT',
            power: 'pwr',
            mode: 'mode',
            tempValue: 'tmpR',
            tempValueCurrent: 'tmpC',
            boost: 'bst',
            err: 'err'
        },
        ecoFunctions: ['Eco', 'Eco Comfort', 'Eco Night'],
        ecoIcons: {
            'Eco': 'eco',
            'Eco Comfort': 'eco-2',
            'Eco Night': 'eco-1',
        },
        functions: [],
        program: {
            useHaflHours: false,
            minTemp: 15,
            maxTemp: 75
        },
    },
    '2002-v2' : {
        model: 'Bellislimo',
        imageVertical: '/assets/images/bellislimo-vertical.jpg',
        imageHorizontal: '/assets/images/bellislimo-horizontal.jpg',
        type: 'boiler',
        modes: {
            'Manual': "0",
            'Program P1': "1",
            'Program P2': "2",
            'Program P3': "3",
            'Eco Smart': "4",
            'Eco Comfort': "5",
            'Eco Night': "6",
            'Vacation': "10"
        },
        modesSettings: {
            '1': {
                'class': '',
                'image': '',
                'path': ''
            },
            '2': {
                'class': '',
                'image': '',
                'path': ''
            },
            '3': {
                'class': '',
                'image': '',
                'path': ''
            },
            '4': {
                'class': 'fill-light-green',
                'image': '#icon-eco',
                'path': ''
            },
            '5': {
                'class': 'fill-light-green',
                'image': '#icon-eco-2',
                'path': ''
            },
            '6': {
                'class': 'fill-light-green',
                'image': '#icon-eco-1',
                'path': ''
            },
            '10': {
                'class': 'fill-light-green',
                'image': '#icon-palm',
                'path': '/old/apiv2/vacation'
            },

        },
        tempState: {
            0: {
                minTemp: 0,
                maxTemp: 4,
            },
            1 : {
                minTemp: 0,
                maxTemp: 2,
            },
        },
        apiVersion: 'apiv2',
        powerOn: '1',
        powerOff: '0',
        commands: {
            temp: 'tmpT',
            power: 'pwr',
            mode: 'mode',
            tempValue: 'tmpR',
            tempValueCurrent: 'tmpC',
            boost: 'bst',
            err: 'err'
        },
        ecoFunctions: ['Eco Smart', 'Eco Comfort', 'Eco Night'],
        ecoIcons: {
            'Eco Smart': 'eco',
            'Eco Comfort': 'eco-2',
            'Eco Night': 'eco-1',
        },
        functions: [],
        program: {
            useHaflHours: false,
            minTemp: 0,
            maxTemp: 3
        },
    },
    '2002-v1' : {
        model: 'Bellislimo',
        imageVertical: '/assets/images/bellislimo-vertical.jpg',
        imageHorizontal: '/assets/images/bellislimo-horizontal.jpg',
        type: 'boiler',
        modes: {
            'Manual': "1",
            'Program P1': "2",
            'Program P2': "3",
            'Program P3': "4",
            'Eco Smart': "5",
            'Eco Comfort': "6",
            'Eco Night': "7",
            'Vacation': "9"
        },
        modesSettings: {
            '2': {
                'class': '',
                'image': '',
                'path': ''
            },
            '3': {
                'class': '',
                'image': '',
                'path': ''
            },
            '4': {
                'class': '',
                'image': '',
                'path': ''
            },
            '5': {
                'class': 'fill-light-green',
                'image': '#icon-eco',
                'path': '',
                'path_web': '/old/device-settings'
            },
            '6': {
                'class': 'fill-light-green',
                'image': '#icon-eco-2',
                'path': '',
                'path_web': '/old/device-settings'
            },
            '7': {
                'class': 'fill-light-green',
                'image': '#icon-eco-1',
                'path': '',
                'path_web': '/old/device-settings'
            },
            '9': {
                'class': 'fill-light-green',
                'image': '#icon-palm',
                'path': '/old/vacation',
                'path_web': '/old/device-settings'
            },

        },
        tempState: {
            h: {
                minTemp: 0,
                maxTemp: 3,
            },
            v: {
                minTemp: 0,
                maxTemp: 4,
            },
        },
        apiVersion: 'apiv1',
        powerOn: 'on',
        powerOff: 'off',
        commands: {
            temp: 'tmpT',
            power: 'power_sw',
            mode: 'mode',
            tempValue: 'ref_shower',
            tempValueCurrent: 'cur_shower',
            boost: 'boost',
            err: 'err_flag'
        },
        ecoFunctions: ['Eco Smart', 'Eco Comfort', 'Eco Night'],
        ecoIcons: {
            'Eco Smart': 'eco',
            'Eco Comfort': 'eco-2',
            'Eco Night': 'eco-1',
        },
        functions: [],
        program: {
            useHaflHours: false,
            minTemp: 0,
            maxTemp: 3
        },
    },
    '1006-v1' : {
        model: 'ConvEco',
        image: '/assets/images/CN04_new.jpeg',
        type: 'convector',
        modes: {
            Manual: "Manual",
            Program: "Auto",
            Delay: "Delay"
        },
        modesSettings: {
            'Delay': {
                'class': 'fill-light-purple',
                'image': '#icon-clock',
                'path': '/old/delayed-start',
                'path_web': '/old/device-settings',
            },
            'Auto': {
                'class': '',
                'image': '',
                'path': ''
            },
        },
        minTemp: 10,
        maxTemp: 30,
        apiVersion: 'apiv1',
        powerOn: 'on',
        powerOff: 'off',
        commands: {
            temp: 'tmpT',
            power: 'power_sw',
            mode: 'mode',
            tempValue: 'ref_gradus',
            tempValueCurrent: 'gradus',
            err: 'err_flag'
        },
        functions: ['Open windows', 'Lock Keys', 'Adaptive Start', 'Antifreeze', 'Delay'],
        tCorrection: ["-4", "-3", "-2", "-1", "0", "+1", "+2", "+3", "+4"],
        program: {
            useHaflHours: true,
            minTemp: 10,
            maxTemp: 30
        },
        max_sleep_time: 0, //0 hours in sec 
        min_sleep_time: 0, //0 hours in sec
        max_delayed_time: 172800, //48 hours in sec,
        min_delayed_time: 1800, //0:30 hour in sec,
    },
    '1003-v1' : {
        model: 'ConvEco',
        image: '/assets/images/CN04_new.jpeg',
        type: 'convector',
        modes: {
            Manual: "Manual",
            Program: "Auto",
            Sleep: "Sleep",
            Delay: "Delay",
        },
        modesSettings: {
            'Sleep': {
                'class': 'fill-light-blue',
                'image': '#icon-moon',
                'path': '/old/sleep',
                'path_web': '/old/device-settings'
            },
            'Delay': {
                'class': 'fill-light-purple',
                'image': '#icon-clock',
                'path': '/old/delayed-start',
                'path_web': '/old/device-settings',
            },
            'Auto': {
                'class': '',
                'image': '',
                'path': ''
            },
        },
        minTemp: 10,
        maxTemp: 30,
        apiVersion: 'apiv1',
        powerOn: 'on',
        powerOff: 'off',
        commands: {
            temp: 'tmpT',
            power: 'power_sw',
            mode: 'mode',
            tempValue: 'ref_gradus',
            tempValueCurrent: 'gradus',
            err: 'err_flag'
        },
        functions: ['Open windows', 'Lock Keys', 'Delay', 'Sleep'],
        tCorrection: ["-5", "-4", "-3", "-2", "-1", "0", "+1", "+2", "+3", "+4", "+5"],
        program: {
            useHaflHours: false,
            minTemp: 10,
            maxTemp: 30
        },
        max_sleep_time: 86400, //24 hours in sec 
        min_sleep_time: 1800, //0:30 hours in sec
        max_delayed_time: 172800, //48 hours in sec,
        min_delayed_time: 1800, //0:30 hour in sec,
    },
    '1005-v1' : {
        model: 'HeatEco',
        image: '/assets/images/CN03_new.jpeg',
        type: 'convector',
        modes: {
            Manual: "Manual",
            Program: "Auto",
            Delay: "Delay",
        },
        modesSettings: {
            'Delay': {
                'class': 'fill-light-purple',
                'image': '#icon-clock',
                'path': '/old/delayed-start',
                'path_web': '/old/device-settings',
            },
            'Auto': {
                'class': '',
                'image': '',
                'path': ''
            },
        },
        minTemp: 10,
        maxTemp: 30,
        apiVersion: 'apiv1',
        powerOn: 'on',
        powerOff: 'off',
        commands: {
            temp: 'tmpT',
            power: 'power_sw',
            mode: 'mode',
            tempValue: 'ref_gradus',
            tempValueCurrent: 'gradus',
            err: 'err_flag'
        },
        functions: ['Adaptive Start', 'Lock Keys', 'Antifreeze', 'Delay'],
        tCorrection: ["-4", "-3", "-2", "-1", "0", "+1", "+2", "+3", "+4"],
        program: {
            useHaflHours: true,
            minTemp: 10,
            maxTemp: 30
        },
        max_sleep_time: 0, //0 hours in sec 
        min_sleep_time: 0, //0 hours in sec
        max_delayed_time: 172800, //48 hours in sec,
        min_delayed_time: 1800, //0:30 hour in sec,
    },
    '1002-v1' : {
        model: 'HeatEco',
        image: '/assets/images/CN03_new.jpeg',
        type: 'convector',
        modes: {
            Manual: "Manual",
            Program: "Auto",
            Sleep: "Sleep",
            Delay: "Delay",
        },
        modesSettings: {
            'Sleep': {
                'class': 'fill-light-blue',
                'image': '#icon-moon',
                'path': '/old/sleep',
                'path_web': '/old/device-settings',
            },
            'Delay': {
                'class': 'fill-light-purple',
                'image': '#icon-clock',
                'path': '/old/delayed-start',
                'path_web': '/old/device-settings',
            },
            'Auto': {
                'class': '',
                'image': '',
                'path': ''
            },
        },
        minTemp: 15,
        maxTemp: 30,
        apiVersion: 'apiv1',
        powerOn: 'on',
        powerOff: 'off',
        commands: {
            temp: 'tmpT',
            power: 'power_sw',
            mode: 'mode',
            tempValue: 'ref_gradus',
            tempValueCurrent: 'gradus',
            err: 'err_flag'
        },
        functions: ['Delay, Sleep'],
        tCorrection: ["0", "+1", "+2", "+3", "+4"],
        program: {
            useHaflHours: false,
            minTemp: 14,
            maxTemp: 30
        },
        max_sleep_time: 86400, //24 hours in sec 
        min_sleep_time: 1800, //0:30 hours in sec
        max_delayed_time: 86400, //24 hours in sec,
        min_delayed_time: 1800, //0:30 hour in sec,
    }
}

//TO DO: think about this maybe devices will be devided by groups (convectors, boilers ....) not by model. If devided by model the  cn05uvSettings is ok
export const convectorsSettings = {
    'cn05uv': {
        min_temp: 20,
        max_temp: 38,
        start_temp: 30,
        step_temp: 1   
    }
}

//TO DO: Ask for models and their ips for http query 
export const modelsSettings = {
    cn06as: {
        http_api_url: 'http://192.168.4.1:80',
        ap_name: 'Tesy-CN06AS'
    },
    cn05uv: {
        http_api_url: 'http://192.168.4.1:80',
        ap_name: 'Tesy-CN06AS'
    },
    HPWH4_11_260: {
        http_api_url: 'http://192.168.4.1:80',
        ap_name: 'Tesy_HPWH'
    },
    E32: {
        http_api_url: 'http://192.168.4.1:80',
    },
    B15: {
        http_api_url: 'http://192.168.4.1:80',
    }
}

// export const languages = [
//     { value: 'en', label: 'EN' },
//     { value: 'bg', label: 'BG' }
// ]

//export const languages = ['en', 'bg', 'ro', 'rs', 'gr', 'hr'];
export const languages = ['bg', 'cz', 'de', 'en', 'es', 'et', 'fr', 'gr', 'hr', 'hu', 'lt', 'lv', 'mk', 'nl', 'pl', 'ro', 'rs', 'ru', 'sk', 'ua', 'sl', 'it', 'he'];
//export const languages = ['en', 'bg', 'ro', 'rs', 'gr', 'hr', 'es', 'pt', 'ru', 'de', 'it', 'fr', 'dk', 'hu', 'pl', 'ua', 'sk', 'sl', 'lt', 'lv', 'ee', 'mk', 'cn', 'nl', 'no', 'tw', 'sv', 'ar', 'he', 'am'];