import {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonInput,
    IonFooter,
    IonToolbar,
    IonButton,
    IonText,
    IonLabel
} from '@ionic/react';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import { setAppMessage } from '../../../redux/actions/AppAction';
import ToastMessage from '../components/toast/ToastMessage';
import OldDevicesSettingsHelper from '../../../helpers/OldDevicesSettingsHelper';
import {oldModels} from '../../../config/App';
import ConvectorApiV1Menu from './convectors/apiv1/ConvectorApiV1Menu';
import BoilerApiV1Menu from './boilers/apiv1/BoilerApiV1Menu';
import BoilerApiV2Menu from './boilers/apiv2/BoilerApiV2Menu';

import FooterTwoButtons from '../components/footer/FooterTwoButtons';
import Header from '../components/header/Header';
import PageHeader from '../components/header/PageHeader';
import Footer from '../components/footer/Footer';

class NameDevice extends React.Component {


    state = {
        deviceName: ''
    }

    componentDidMount = () => {
        this.setState({
            deviceName: this.props.devices[this.props.match.params.id].DeviceShortName   
        })
    }

    confirmAction = async () => {
        if (this.state.deviceName !== '') {

            if (this.state.deviceName.length < 30) {
                const OldDevicesSettingsHelperOBJ = new OldDevicesSettingsHelper(this.props.match.params.id);

                OldDevicesSettingsHelperOBJ.editDevice('devShortName', this.state.deviceName)  
            } else {
                this.props.setAppMessage(this.props.t('device_name_max_size'));
            } 

        } else {
            this.props.setAppMessage(this.props.t('required_device_name'));
        }
    }

    render = () => {

        const { t } = this.props;
        this.model_name = this.props.match.params.model_name;
        this.model = oldModels[this.model_name]

        return (
            <IonPage>
                <Header />
                <IonContent className="ion-no-padding">
                    <div className="dashboard">
                        <div className="dashboard__wrapper">

                            {this.model.type == 'boiler' ? 

                                this.model.apiVersion == 'apiv1' ? 
                                    <BoilerApiV1Menu 
                                        activeMenu="controlPanel" 
                                        model_name={this.props.match.params.model_name} 
                                        device={this.props.devices[this.props.match.params.id]} 
                                        model={oldModels[this.props.match.params.model_name]}
                                    />
                                :
                                <BoilerApiV2Menu 
                                    activeMenu="controlPanel" 
                                    model_name={this.props.match.params.model_name} 
                                    device={this.props.devices[this.props.match.params.id]} 
                                    model={oldModels[this.props.match.params.model_name]}
                                />
                            :
                                <ConvectorApiV1Menu 
                                    activeMenu="controlPanel" 
                                    model_name={this.props.match.params.model_name} 
                                    device={this.props.devices[this.props.match.params.id]} 
                                    model={oldModels[this.props.match.params.model_name]}
                                />
                            }

                            <div className="dashboard__content">
                               
                                <PageHeader name={t('rename_device')} image="pencil"/>

                                <div className="dashboard-box">

                                    <div className="dashboard-box__body">
                            
                                        <IonList>
                                            <IonItem lines="none" className="form-list__item">
                                                <IonLabel position="stacked" color="primary" className="form-label">{t('name')}</IonLabel>
                                                <IonInput 
                                                    type="text" 
                                                    className="form-input" 
                                                    onIonInput={e => this.setState({deviceName: e.detail.value})} 
                                                    value={this.state.deviceName}
                                                />
                                            </IonItem>
                                            <IonItem lines="none" className="form-list__item pt-20">
                                                <FooterTwoButtons save={this.confirmAction} />   
                                            </IonItem>
                                        </IonList>
                        
                                        <ToastMessage />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </IonContent>
                    
                <Footer />
                    
            </IonPage>
        )
    }
}

const mapStateToProps = state => {
    return {
        devices: state.oldDevices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        setAppMessage
    })
)(NameDevice);