import {
    IonPage,
    IonContent,
    IonButton,
    IonList,
    IonItem,
    IonLabel,
    IonInput
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import Header from './components/header/Header';
import PageHeader from './components/header/PageHeader';
import Footer from './components/footer/Footer';
import DashboardMenu from './components/menu/DashboardMenu';
import FooterTwoButtons from './components/footer/FooterTwoButtons';
import ToastMessage from './components/toast/ToastMessage';

import {
    setAppLanguage
  } from '../../redux/actions/AppAction';

//Import helpers
import UsersHelper from '../../helpers/UsersHelper';
import { setAppMessage } from '../../redux/actions/AppAction';


class OldAppForgottenPassword extends React.Component {

    constructor(props){
        super(props);

        this.UsersHelper = new UsersHelper();
        this.state = {
            email: '',
            errors: {},
            newPasswordFields: false,
            password: null,
            confirmPassword: null,
            verificationCode: null,
            passwordShown: false,
            confirmPasswordShown: false
        }
    }

    sendEmail = async () => {

        const {error, msg} = await this.UsersHelper.tesyCloudForgottenPassword(this.state.email);

        if (error == 0) {
            this.setState({
                newPasswordFields: true
            })
        }

        this.props.setAppMessage(msg)

    }

    changePassword = async () => {
        const {error, msg} = await this.UsersHelper.tesyCloudResetPassword({
            code: this.state.verificationCode,
            pass_new: this.state.password,
            pass_new2: this.state.confirmPassword,
            user: this.state.email
        });

        if (error == 0) {

            this.setState({
                newPasswordFields: false
            })

            this.props.history.goBack();

        } else {
            this.props.setAppMessage(msg)
        }
    }

    togglePasswordVisiblity = () => {
        this.setState({
          passwordShown: this.state.passwordShown ? false : true  
        });
    };

    toggleConfirmPasswordVisiblity = () => {
        this.setState({
            confirmPasswordShown: this.state.confirmPasswordShown ? false : true  
        });
    };

    render = () => {

        const { t } = this.props;

        return (
            <IonPage>
                <Header />
                <IonContent className="ion-no-padding">
                    <div className="dashboard">
                        <div className="dashboard__wrapper">
                            
                            <DashboardMenu />

                            <div className="dashboard__content">

                                <PageHeader name={`tesyCloud ${t('forgotten_password')}`} image="pencil"/>

                                <div className="dashboard-box">

                                    <div className="dashboard-box__body">
                                        {this.state.newPasswordFields == false ?
                                            <IonList>

                                                <IonItem lines="none" className={`form-list__item ${'email' in this.state.errors ? 'is-invalid' : ''}`}>
                                                    <IonLabel position="stacked" color="primary" className="form-label">{t('email')}</IonLabel>
                                                    <IonInput value={this.state.email} type="email" className="form-input" onIonInput={ e => this.setState({email: e.detail.value})}/>
                                                    {'email' in this.state.errors ? (<div className="invalid-message">{this.state.errors.email}</div>) : null}
                                                </IonItem>

                                                <IonItem lines="none" className="form-list__item pt-20">
                                                    <FooterTwoButtons secondButtonName={t('confirm')} save={this.state.newPasswordFields == false ? this.sendEmail : this.changePassword} />   
                                                </IonItem>

                                            </IonList>
                                        :
                                            <IonList>

                                                <IonItem lines="none" className={`form-list__item`}>
                                                    <IonLabel position="stacked" color="primary" className="form-label">{t('password')}</IonLabel>
                                                    <div className="form-password">
                                                        <IonInput type={this.state.passwordShown ? "text" : "password"} value={this.state.password} className="form-input" onIonInput={ e => this.setState({password: e.detail.value})}/>
                                                        <button type="button" ion-button="true" className="form-password__button" onClick={this.togglePasswordVisiblity}>
                                                            <svg className="form-password__button-icon"><use xlinkHref={`#icon-eye-${this.state.passwordShown ? 'closed' : 'open'}`}></use></svg>
                                                        </button>
                                                    </div>
                                                </IonItem>

                                                <IonItem lines="none" className={`form-list__item`}>
                                                    <IonLabel position="stacked" color="primary" className="form-label">{t('confirm_password')}</IonLabel>
                                                    <div className="form-password">
                                                        <IonInput type={this.state.confirmPasswordShown ? "text" : "password"} value={this.state.confirmPassword} className="form-input" onIonInput={ e => this.setState({confirmPassword: e.detail.value})}/>
                                                        <button type="button" ion-button="true" className="form-password__button" onClick={this.toggleConfirmPasswordVisiblity}>
                                                            <svg className="form-password__button-icon"><use xlinkHref={`#icon-eye-${this.state.confirmPasswordShown ? 'closed' : 'open'}`}></use></svg>
                                                        </button>
                                                    </div>
                                                </IonItem>

                                                <IonItem lines="none" className={`form-list__item`}>
                                                    <IonLabel position="stacked" color="primary" className="form-label">{t('verificationCode')}</IonLabel>
                                                    <IonInput value={this.state.verificationCode} type="email" className="form-input" onIonInput={ e => this.setState({verificationCode: e.detail.value})}/>
                                                </IonItem>

                                                <IonItem lines="none" className="form-list__item pt-20">
                                                    <FooterTwoButtons secondButtonName={t('confirm')} save={this.state.newPasswordFields == false ? this.sendEmail : this.changePassword} />   
                                                </IonItem>

                                            </IonList>
                                        }
                                    </div>

                                    <ToastMessage />
                                </div>
                            </div>
                        </div>
                    </div>
                </IonContent>
                <Footer />
            </IonPage>
        );
    }
    
};

const mapStateToProps = state => {
    return {
        app: state.app
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        setAppLanguage,
        setAppMessage
    })
)(OldAppForgottenPassword);