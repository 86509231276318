import React from 'react';
import { Route, Switch} from 'react-router-dom';
import ManagePage from './ManagePage';
import Program from './Program';
import CreateProgram from './CreateProgram';
import Vacation from './Vacation';
import DeviceInfo from './DeviceInfo';
import Calculator from './Calculator';
import CalculatorHistory from './CalculatorHistory';

import HPWH4_11_260 from '../addDevice/HPWH4_11_260/HPWH4_11_260';
import ChooseDevice from '../addDevice/HPWH4_11_260/ChooseDevice';
import ChangeWifi from '../addDevice/HPWH4_11_260/ChangeWifi';
import WifiPassword from '../addDevice/HPWH4_11_260/WifiPassword';
import DeviceSettings from '../addDevice/HPWH4_11_260/DeviceSettings';
import Finish from '../addDevice/HPWH4_11_260/Finish';

const Routes = () => {

    //MUST BE IN LOWER CASE
    const MODEL = "hpwh4_11_260";

    return (
        <Switch>'
            
            {/* MANAGE DEVICE: ROUTES*/}
            <Route exact path={`/device/${MODEL}/:mac_address`} component={ManagePage}/>
            <Route exact path={`/device/vacation/${MODEL}/:mac_address`} component={Vacation}/>
            <Route exact path={`/device/program/${MODEL}/:mac_address/:day?`} component={Program}/>
            <Route exact path={`/device/program/create-program/${MODEL}/:mac_address/:day/:zone?`} component={CreateProgram}/>
            <Route exact path={`/device/hp/device-info/${MODEL}/:mac_address`} component={DeviceInfo}/>
            <Route exact path={`/device/hp/calculator/${MODEL}/:mac_address`} component={Calculator}/>
            <Route exact path={`/device/hp/calculator-history/${MODEL}/:mac_address`} component={CalculatorHistory}/>

            {/* CONNECT DEVICE: ROUTES */}
            <Route exact path={`/connect/${MODEL}`} component={HPWH4_11_260}/>
            <Route exact path={`/connect/${MODEL}/choose-device`} component={ChooseDevice}/>
            <Route exact path={`/connect/${MODEL}/change-wifi`} component={ChangeWifi}/>
            <Route exact path={`/connect/${MODEL}/wifi-password`} component={WifiPassword}/>
            <Route exact path={`/connect/${MODEL}/device-settings`} component={DeviceSettings}/>
            <Route exact path={`/connect/${MODEL}/finish`} component={Finish}/>
        </Switch>
    );
}

export default Routes;