import cn05Basic from './cn05Basic';

export default class cn05uv extends cn05Basic{

    constructor(deviceData) {
        super(deviceData); 
        
        this.table_fields = {
            id: "id",
            macAddr: "mac_address",
            temp: "temp",
            current_temp: "current_temp",
            status: "status",
            openedWindow: 'openedWindow',
            delayedStart: 'delayedStart',
            delayedStop: 'delayedStop',
            tCorrection: 'TCorrection',
            antiFrost: 'antiFrost',
            comfortTemp: 'comfortTemp',
            ecoTemp: 'ecoTemp',
            uv: 'uv',
            lockDevice: 'lockedDevice',
            watt: 'watt',
            program: 'program',
            deviceName: 'deviceName',
            dateUpdate: "date_updated",
            adaptiveStart: 'adaptiveStart',
            programStatus: 'programStatus',
            mode: 'mode',
            heating: 'heating',
            sleepMode: 'sleepMode',
            target: 'target',
            timeRemaining: 'timeRemaining'
        }
    }
}