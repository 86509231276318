import {
    IonPage,
    IonContent,
    IonButton,
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import '../components/range/range.scss';
import HeatPumpMenu from './components/HeatPumpMenu';
import PageHeader from '../components/header/PageHeader';
import HeatPumpHelper from "../../../helpers/HeatPumpHelper";

import RestHelper from '../../../helpers/RestHelper';

const Calculator = props => {

    const { t } = useTranslation();
    const [totalKwh, setTotalKwh] = useState(0);

    const { 
        devices, 
        match: {
            params: {
                mac_address: mac
            }
        } 
    } = props;

    const device = devices[mac];
    const HeatPumpHelperOBJ = new HeatPumpHelper(device);
    const restHelperOBJ = new RestHelper();

    useEffect(async () => {

        const {data} = await restHelperOBJ.get('/get-device-power-stat', {
            model: device.model_id,
            timezone: device.timezone,
            activity: "monthly",
            mac: mac     
        });

        let total = 0;

        for (const i in data) {
            const {enEH, enHP, enPV} = data[i];
            total += (enEH + enHP +enPV);
        }

        setTotalKwh(total);
    
    }, [])

    return (
        <IonPage>
            <Header />
            <IonContent className="ion-no-padding">
                <div className="dashboard">
                    <div className="dashboard__wrapper">
                        
                        <HeatPumpMenu activeMenu="hpCalculator" mac={props.match.params.mac_address}/>

                        <div className="dashboard__content">
                            <PageHeader name={t('calculator')} image="calc"/>

                            <div className="dashboard-box">
                                <div className="dashboard-box__body">
                                    <div className="ion-text-center">
                                        <div className="range-circle">
                                            <div className="range-circle__inner">
                                                <div className="range-circle__metric">{t('hp_calc_kwh')}</div>
                                                <div className="range-circle__value">{totalKwh}</div>
                                                <div className="range-circle__label">{t('hp_calc_last_month')}</div>
                                                <div className="range-circle__value range-circle__value--alt">{HeatPumpHelperOBJ.getModelSize()}{t('hp_calc_model_size_unit')}</div>
                                            </div>
                                        </div>
                                        <IonButton 
                                            color="primary" 
                                            expand="block" 
                                            style={{ maxWidth: '253px', marginLeft: 'auto', marginRight: 'auto' }}
                                            routerLink={`/device/hp/calculator-history/${device.model.toLowerCase()}/${mac}`}
                                        >
                                            <span className="button__text">{t('hp_calc_history_btn')}</span>
                                        </IonButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </IonContent>
            <Footer />
        </IonPage>
    );
};

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    connect(mapStateToProps, {})
)(Calculator);