
import { parseJson } from '../helpers/JsonHelper';
import store from '../redux/createStore';
import { logger } from '../redux/actions/AppAction';
import { updateDeviceInternet, updateDeviceWifi } from '../redux/actions/DevicesAction';
import RequestLoaderHelper from '../helpers/RequestLoaderHelper';

export default class basicModel {

    constructor({macAddr, token = '', payload = '{}', command = '', model}) {

        this.payload = parseJson(payload);
        this.macAddr = macAddr;
        this.command = command;
        this.token = token;
        this.store = store;
        this.hasRequestLoader = false;
        this.skipHideRequestLoaderForCommands = ['ping'];
        this.skipShowRequestLoaderForCommands = ['getStatus'];
        this.model = model;

        const { devices } = store.getState();
        this.device = devices[macAddr];
    }

    executeModelCommand() {
        
        if (this.token === this.device.token) {
            try {
                const { code } = this.payload;

                //UPDATE Model internet connection
                store.dispatch(updateDeviceInternet(true, this.macAddr));

                if (code === 200) {
                    this[this.command]();
                } else {
                    //TO DO: WHEN THE CODE IS DIFFERENT SHOW ALLERT BOX
                }

                //STOP SHOWING REQUEST LOADER
                if (this.hasRequestLoader && !this.skipHideRequestLoaderForCommands.includes(this.command) ) {
                    const requestLoaderHelperOBJ = new RequestLoaderHelper(this.model);
                    requestLoaderHelperOBJ.hideRequestLoader();
                }
                
            } catch (e) {
                //store.dispatch(logger(`basicModel: Cannot execute the command: ${e.message}`));
                global.debug(`Cannot execute the command: ${e.message}`);
            }
        } else {
            store.dispatch(logger(`basicModel: Token is not correct!!!!! for mac: ${this.macAddr}`));
            global.debug(`Token is not correct!!!!! for mac: ${this.macAddr}`);
        }
    }

    setWifiData() {

        const { payload: { wifiSSID, wifiPass } } = this.payload;

        store.dispatch(updateDeviceWifi({wifiSSID, wifiPass}, this.macAddr));
        
        return {
            code: 200,
            message: 'OK'
        }
    }

}