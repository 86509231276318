import store from '../../../../redux/createStore';

class ProgramHelper
{

    constructor(mac) {

        this.mac = mac;

        const { devices } = store.getState();
        const {state: {program}} = devices[mac];
        
        this.program = program;
    }

    validateProgram = (from, to, day) => {

        if (!this.validateToAndFrom(from, to)) {
            return false;
        }
        
        if (!this.validateTimeBetweenRange(from, day)) {
            return false;
        }
       
        if (!this.validateTimeBetweenRange(to, day)) {
            return false;
        }

        if (!this.validateTimeRangeBetweenRange(from, to, day)) {
            return false;
        }

        return true;
    }

    validateToAndFrom = (from, to) => {

        const fromToMin = this.convertTimeToMinutes(from);
        const toToMin = this.convertTimeToMinutes(to);

        if (toToMin <= fromToMin) {
            return false;
        }

        return true;
    } 

    validateTimeRangeBetweenRange = (from, to, day) => {

        let status = true;

        const programForTheDay = this.getProgramForTheDay(day);;

        for (const key in programForTheDay) {
            
            const {from: program_from, to: program_to } = programForTheDay[key];

            if (program_from != "00:00" && program_to != "00:00") {

                const fromToMin = this.convertTimeToMinutes(from);
                const toToMin = this.convertTimeToMinutes(to);
                const programFromToMin = this.convertTimeToMinutes(program_from);
                const programToToMin = this.convertTimeToMinutes(program_to);

                if (fromToMin <= programFromToMin && programToToMin <= toToMin) {
                    status = false;
                    break;
                }
            }
            
        }

        return status;
    }

    validateTimeBetweenRange = (time, day) => {
       
        let status = true;

        const programForTheDay = this.getProgramForTheDay(day);;

        for (const key in programForTheDay) {
            
            const {from: program_from, to: program_to } = programForTheDay[key];

            if (program_from != "00:00" && program_to != "00:00") {

                const timeToMin = this.convertTimeToMinutes(time);
                const programFromToMin = this.convertTimeToMinutes(program_from);
                const programToToMin = this.convertTimeToMinutes(program_to);

                if (timeToMin > programFromToMin && timeToMin < programToToMin) {
                    status = false;
                    break;
                }
            }
            
            
        }

        return status;
    }

    convertTimeToMinutes = time => {

        let [hour, min] = time.split(":");
        hour = Number(hour);
        min = Number(min);

        return hour*60 + min;
    }

    getZones = day => {
        const programForTheDay = this.getProgramForTheDay(day);
        
        let count = 0;
        for (const key in programForTheDay) {
            const {from, to} = programForTheDay[key];

            if (from != "00:00" || to != "00:00") {
                count++;
            }
        }

        return count;
    }

    getProgramDays = () => {
        return ["mon", "tue", "wen", "thu", "fri", "sat", "sun"];
    }

    getProgramForTheDay = (day) => {
        const programDays = this.getProgramDays();
        return this.program[programDays[day]];
    }

    defaultProgramObject = () => {
        return {
            "zone_1":{
                "to":"00:00",
                "from":"00:00",
                "mode":"hp",
                "temp":65
             },
             "zone_2":{
                "to":"00:00",
                "from":"00:00",
                "mode":"hp",
                "temp":65
             },
             "zone_3":{
                "to":"00:00",
                "from":"00:00",
                "mode":"hp",
                "temp":65
             },
             "zone_4":{
                "to":"00:00",
                "from":"00:00",
                "mode":"hp",
                "temp":65
             }
        }
    }

    createEmptyProgram = () => {
        let program = {};

        this.getProgramDays().map(day => {
            program[day] = this.defaultProgramObject()
        })

        return program;
    }

    sortProgramForTheDay = program => {

        const sorted = Object.fromEntries(
            Object.entries(program).sort(([, a], [, b]) => {
                const x = this.convertTimeToMinutes(a.from);
                const y = this.convertTimeToMinutes(b.from);

                return x - y;
            })
        );
    
        const sortedObject = {};
        let zoneIndex = 1;
        for (const i in sorted) {
            sortedObject[`zone_${zoneIndex}`] = sorted[i];
            zoneIndex++;
        }

        return sortedObject;
    }
}

export default ProgramHelper;