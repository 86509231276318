import {
    IonContent,
    IonFooter,
    IonButton,
    IonItem,
    IonList,
    IonPage,
    IonToolbar,
    IonLabel,
    IonInput,
    IonSelect,
    IonSelectOption
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import 'react-phone-number-input/style.css'

//Import helpers
import UsersHelper from '../../../../helpers/UsersHelper';
import LocalStorageHelper from '../../../../helpers/LocalStorageHelper';
import { setAppMessage } from '../../../../redux/actions/AppAction';

//Import components
import StandartHeader from '../../components/header/StandartHeader';
import InitialSettings from '../../../../init';
import ToastMessage from '../../components/toast/ToastMessage';
import Loading from '../../components/loading/Loading';
import tesyCloudLogo from '../../../../files/TesyCloud-Logo.svg';



class oldAppAccountSettings extends React.Component {

    constructor(props){
        super(props);

        this.UsersHelper = new UsersHelper();

        this.state = {
            email: '',
            password: '',
            passwordShown: false,
            errors: {},
            showLoading: false
        }
    }

    componentDidMount = async () => {

        this.setState({
            email: await LocalStorageHelper.get('user.oldAppEmail'),
            password: await LocalStorageHelper.get('user.oldAppPassword'),
        })
    }

    togglePasswordVisiblity = () => {
        this.setState({
          passwordShown: this.state.passwordShown ? false : true  
        });
    };

    confirm = async () => {

        this.setState({showLoading: true});

        try {

            await this.UsersHelper.loginTesyCloud({
                email: this.state.email,
                password: this.state.password,
            });

            await this.UsersHelper.oldAppSettings({
                email: this.state.email,
                password: this.state.password,
            });

            const InitialSettingsOBJ = new InitialSettings();
            InitialSettingsOBJ.init();

            this.setState({showLoading: false});

            this.props.history.push('/old/choose-device');

        } catch (e) {

            this.setState({showLoading: false});

            this.setState({
                errors: e.errors
            })

            if ('global' in e.errors) {
                this.props.setAppMessage(e.errors.global);
            }
        }
    }

    register = async () => {

        const password = Math.random().toString(36).substring(6);
        const email = await  LocalStorageHelper.get('user.email');

        const register = await this.UsersHelper.registerTesyCloud({
            email: email,
            password: password,
        });

        if (register.error != undefined && register.error == 1) {

            this.props.setAppMessage(register.msg);

        } else {

            await this.UsersHelper.oldAppSettings({
                email: email,
                password: password,
            });

            this.props.history.push('/old/choose-device');
        }

    }

    render = () => {

        const { t } = this.props;

        return (
        <IonPage>
            
            <StandartHeader name="tesyCloud"/>
            

            <IonContent className="ion-padding">
                <IonList>

                    <IonItem lines="full" className={`form-list__item ${'email' in this.state.errors ? 'is-invalid' : ''}`}>
                        <IonLabel position="stacked" color="primary" className="form-label">{t('email')}</IonLabel>
                        <IonInput value={this.state.email} type="email" className="form-input" onIonInput={ e => this.setState({email: e.detail.value})}/>
                        {'email' in this.state.errors ? (<div className="invalid-message">{this.state.errors.email}</div>) : null}
                    </IonItem>

                    <IonItem lines="full" className={`form-list__item ${'password' in this.state.errors ? 'is-invalid' : ''}`}>
                        <IonLabel position="stacked" color="primary" className="form-label">{t('password')}</IonLabel>
                        <div className="form-password">
                            <IonInput type={this.state.passwordShown ? "text" : "password"} value={this.state.password} className="form-input" onIonInput={ e => this.setState({password: e.detail.value})}/>
                            <button type="button" ion-button="true" className="form-password__button" onClick={this.togglePasswordVisiblity}>
                                <svg className="form-password__button-icon"><use xlinkHref={`#icon-eye-${this.state.passwordShown ? 'closed' : 'open'}`}></use></svg>
                            </button>
                        </div>
                        {'password' in this.state.errors ? (<div className="invalid-message">{this.state.errors.password}</div>) : null}
                    </IonItem>

                    {/* <IonItem lines="none" className="form-list__item">
                        <IonButton color="link" slot="end" routerLink="/old-app-forgotten-password">{t('forgot_password')}</IonButton>
                    </IonItem> */}

                    <div style={{ float: "right" }}>
                        <IonItem lines="none">
                            <IonButton fill="clear" color="link" size="small" routerLink="/old-app-forgotten-password">{t('forgot_password')}</IonButton>
                        </IonItem>
                    </div>
                    <div style={{ clear: 'both' }}></div>
                    <div className="buttons-list" style={{ marginTop: '56px', marginBottom: '56px' }}>
                        <div className="buttons-list__item">
                            <IonButton expand="block" shape="round" size="large" onClick={this.confirm}>{t('confirm')}</IonButton>
                        </div>
                        <div className="buttons-list__item">
                            <IonButton expand="block" shape="round" size="large" onClick={this.register}>{t('register_me')}</IonButton>
                        </div>
                    </div>
                    <div className="text" style={{ marginBottom: '40px' }}>
                        {t('tesycloud_with')} <span className="text-green">MyTESY</span> {t('tesycloud_you_can')} <span className="text-purple">tesyCloud</span> {t('tesycloud_conv_boil')}.
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <img src={tesyCloudLogo} />
                    </div>

                </IonList>

                <ToastMessage/>
                <Loading show={this.state.showLoading}/>

            </IonContent>
            
            {/* <IonFooter className="app-footer ion-no-border">
                <IonToolbar>
                <div className="buttons-list">
                    <div className="buttons-list__item">
                        <IonButton expand="block" shape="round" size="large" onClick={this.confirm}>{t('confirm')}</IonButton>
                    </div>
                    <div className="buttons-list__item">
                        <IonButton expand="block" shape="round" size="large" onClick={this.register}>{t('register')}</IonButton>
                    </div>
                </div>
                </IonToolbar>
            </IonFooter> */}
        </IonPage>
        );
    }

};

const mapStateToProps = state => {
    return {
        app: state.app
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {setAppMessage})
)(oldAppAccountSettings);
  