import {
    IonContent,
    IonPage,
    IonButton,
    IonLabel
} from '@ionic/react';

import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import CalculatorChart from './components/charts/CalculatorChart';
import StatisticsChart from './components/charts/StatisticsChart';
import Header from './components/header/Header';
import PageHeader from './components/header/PageHeader';
import Footer from './components/footer/Footer';
import DeviceCN05UVMenu from './components/menu/DeviceCN05UVMenu';
import RestHelper from '../../helpers/RestHelper';
import {
    fetchDevices
} from '../../redux/actions/DevicesAction';


class Statistics extends React.Component {

    constructor(props) {

        super(props);

        this.device = null;
        this.mac = '';
        this.restHelper = null;

        this.state = {
            StatisticState: 0, //0: Statisticts, 1: calculator
            activity: 'daily',
            statisticData: {
                current: [],
                target: []
            },
            power: ''
        }
    }

    componentDidMount = () => {
        this.restHelper = new RestHelper();
        this.changeStatisticActivity(this.state.activity, 0);
    }

    changeStatistic = type => {

        this.setState({StatisticState: type});
        this.changeStatisticActivity(this.state.activity, type);
    }

    changeStatisticActivity = async (activity, type) => {

        
        this.setState({activity});

        if (type === 0) {

            try {
                
                const res = await this.restHelper.get('/get-device-temp-stat', {
                    model: this.device.model_id,
                    timezone: this.device.timezone,
                    activity: 'daily',
                    mac: this.mac     
                })

                if (this.state.activity == 'daily') {

                    this.setState({
                        statisticData: res.data
                    });

                } else {

                    this.setState({
                        statisticData: {
                            current: [],
                            target: []
                        }
                    });
                }

            } catch (e) {
                global.debug(e);
            }

        } else {

            const { data } = await this.restHelper.get('/get-device-power-stat', {
                model: this.device.model_id,
                timezone: this.device.timezone,
                activity: activity,
                mac: this.mac     
            })

            const {state: {watt}} = this.device;

            data.map(row => {
                
                if (row.working_time > 0 && watt !== null) {

                    const kwt =  watt/ 1000; //Need to convert from watt to kwt  
                    const hours = (row.working_time / 60) / 60; //Need to convert from sec to hours
                    global.debug(watt, row.working_time, hours, kwt );
                    row.consumption = parseFloat((kwt * hours).toFixed(2));
                }
            });

            global.debug('Data', data, watt);
            this.setState({
                statisticData: data
            });    
        }
    }

    updateDevicePower = async power => {

        this.setState({power});

        await this.restHelper.post('/update-device-power', {
            mac: this.mac,
            power
        });

        const { data: myDevices } = await this.restHelper.get('/get-my-devices')
        this.props.fetchDevices(myDevices);

        this.changeStatisticActivity(this.state.activity);
    }


    render = () => {

        const { t } = this.props;

        this.mac = this.props.match.params.mac_address;

        const {devices} = this.props;

        this.device = devices[this.mac];

        if (!this.device || this.device === undefined) {
            return null;
        }   

        const power = this.device.model_watt.split(",");

        return (
            <IonPage>
                <Header />
                <IonContent className="ion-no-padding">
                    <div className="dashboard">
                        <div className="dashboard__wrapper">
                            
                            <DeviceCN05UVMenu activeMenu="statistic" mac={this.props.match.params.mac_address}/>

                            <div className="dashboard__content">
                            
                            <PageHeader name={t('statistics')} image="statistic"/>

                            <Tabs forceRenderTabPanel defaultIndex={0} onSelect={index => this.changeStatistic(index)}>
                                <TabList>
                                <Tab>{t('statistics_button')}</Tab>
                                <Tab>{t('calculator_button')}</Tab>
                                </TabList>
                                <div className="react-tabs__box">
                                {this.state.StatisticState === 1 && 
                                        <div>
                                            <div className="react-tabs__box-top">
                                                <div style={{ maxWidth: '400px', margin: '0 auto' }}>
                                                    <IonLabel>{t('activity')}</IonLabel>
                                                    <Select
                                                        options={
                                                        [
                                                            { value: 'daily', label: t('daily_activity') },
                                                            { value: 'monthly', label: t('monthly_activity') },
                                                            { value: 'annual', label: t('annual_activity') }
                                                        ]
                                                        }
                                                        defaultValue={{ label: t(`${this.state.activity}_activity`), value: this.state.activity }}
                                                        isSearchable={false}
                                                        onChange={value => this.changeStatisticActivity(value.value, 1)}
                                                    />
                                                </div>
                                            </div> 
                                        
                                            <div className="react-tabs__box-top">
                                                <div style={{ maxWidth: '400px', margin: '0 auto' }}>
                                                    <IonLabel>{t('power')}</IonLabel>
                                                    <Select
                                                        options={ power.map(value => {
                                                            return { value: value, label: value+t('power_unit') }
                                                        })
                                                        }
                                                        defaultValue={{ 
                                                            label: this.state.power !== '' ? this.state.power+t('power_unit') : this.device.state.watt+t('power_unit'), 
                                                            value: this.state.power !== '' ? this.state.power : this.device.state.watt, 
                                                        }}
                                                        isSearchable={false}
                                                        onChange={value => this.updateDevicePower(value.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <TabPanel>
                                        <StatisticsChart data={this.state.statisticData}/>
                                    </TabPanel>
                                    <TabPanel>
                                        <CalculatorChart data={this.state.statisticData}/>
                                    </TabPanel>
                                </div>
                            </Tabs>
                            </div>
                        </div>
                    </div>
                </IonContent>
                <Footer />
            </IonPage>
        );
    }
    
};

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        fetchDevices
    })
)(Statistics);