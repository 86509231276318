import {
    FETCH_GROUPS,
    UPDATE_GROUP_STATE
} from '../types/GroupsTypes';

export const fetchGroups = groups => {
    return {
        type: FETCH_GROUPS,
        payload: {
            groups
        }
    }
}

export const updateGroupState = (group_id, field, value) => {
    return {
        type: UPDATE_GROUP_STATE,
        payload: {
            group_id, field, value
        }
    }
}