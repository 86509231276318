import {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonInput,
    IonFooter,
    IonToolbar,
    IonButton,
    IonText,
    IonLabel
} from '@ionic/react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Label,
    Brush,
    ResponsiveContainer
} from "recharts";
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'
import ScrollContainer from 'react-indiana-drag-scroll';

import { setAppMessage } from '../../../redux/actions/AppAction';
import RestHelper from '../../../helpers/RestHelper';
import Header from '../components/header/Header';
import Menu from './components/Menu';
import PageHeader from '../components/header/PageHeader';
import Footer from '../components/footer/Footer';

const ColorConfig = {
    "red": "#eb445a",
    "green": "#7fb439",
    "gray": "#3b3b3b",
    "gray2": "#92949c"
}

class Statistics extends React.Component {

    state = {
        statistics: {
            temp: [],
            state: []
        }
    }

    componentDidMount = async () => {

        this.restHelper = new RestHelper();
        
        const res = await this.restHelper.get('/get-device-temp-stat', {
            model: this.device.model_id,
            timezone: this.device.timezone,
            activity: 'daily',
            mac: this.mac     
        })

        const res2 = await this.restHelper.get('/get-device-state-stat', {
            model: this.device.model_id,
            timezone: this.device.timezone,
            activity: 'daily',
            mac: this.mac     
        })

        this.setState({
            statistics: {
                state: res2.data,
                temp: res.data
            }
        })
    }

    temperatureFormatter = value => {
        return value;
    };

    stateFormatter = value => {
        global.debug(value);
    }

    CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <div className="custom-tooltip">
              <div className="label">{`${label} / ${payload[0].value}`}</div>
            </div>
          );
        }
      
        return null;
    };
 
    render = () => {

        const { t } = this.props;
        this.mac = this.props.match.params.mac_address;

        const {devices} = this.props;

        this.device = devices[this.mac];

        const chartHeight = 400;
        const chartLineWidth = 60;

        return (
            <IonPage>
            
                <Header />

                <IonContent className="ion-no-padding">
                    <div className="dashboard">
                        <div className="dashboard__wrapper">

                            <Menu activeMenu="statistic" mac={this.props.match.params.mac_address}/>

                            <div className="dashboard__content">
                                
                                <PageHeader name={t('statistics')} image="statistic"/>

                                <div className="dashboard-box">

                                    <div className="dashboard-box__body">

                                        {this.state.statistics.temp.length > 0 &&
                                            <ResponsiveContainer debounce={1} width={'100%'} height={chartHeight}>
                                                <LineChart
                                                    width={500}
                                                    height={chartHeight}
                                                    data={this.state.statistics.temp}
                                                    margin={{
                                                        top: 10,
                                                        right: 0,
                                                        left: 0,
                                                        bottom: 0,
                                                    }}
                                                >
                                                    <CartesianGrid strokeDasharray="3 3" strokeWidth=".5" vertical={false} />
                                                    <XAxis 
                                                        xAxisId={0}
                                                        dataKey="name"
                                                        hide={true}
                                                    />
                                                    <XAxis 
                                                        xAxisId={1}
                                                        height={50}
                                                        dataKey="name_round"
                                                        angle={-45}
                                                        textAnchor="end"
                                                        fontSize={12}
                                                        dy={5}
                                                        dx={2}
                                                    />
                                                    <YAxis 
                                                        yAxisId="left"
                                                        width={40}
                                                        dataKey="temp" 
                                                        unit=""
                                                        allowDataOverflow={false}
                                                        tick={{ fontSize: 12, fontWeight: 300, fill: ColorConfig['gray'] }}
                                                    />
                                                    <YAxis 
                                                        hide={true}
                                                        yAxisId="right"
                                                        dataKey="uv"
                                                        orientation="right"
                                                        allowDataOverflow={false}
                                                        tick={{ fontSize: 12, fontWeight: 300, fill: ColorConfig['gray'] }}
                                                    />
                                                    <Line yAxisId="left" type="monotone" dataKey="temp" dot={false} stroke={ColorConfig['red']} />
                                                    <Brush dataKey="name_round" height={30} travellerWidth={30} stroke={ColorConfig['green']} />
                                                    <Tooltip />
                                                </LineChart>
                                            </ResponsiveContainer>
                                        }

                                        <br /> <br />

                                        {this.state.statistics.state.length > 0 &&
                                            <ResponsiveContainer debounce={1} width={'100%'} height={chartHeight}>
                                                <LineChart
                                                    width={500}
                                                    height={chartHeight}
                                                    data={this.state.statistics.state}
                                                    class="hide-brush-texts"
                                                    margin={{
                                                        top: 10,
                                                        right: 0,
                                                        left: 0,
                                                        bottom: 0,
                                                    }}
                                                >
                                                    <CartesianGrid strokeDasharray="3 3" strokeWidth=".5" vertical={false} />
                                                    <XAxis 
                                                        height={50}
                                                        dataKey="name"
                                                        angle={-45}
                                                        textAnchor="end"
                                                        fontSize={12}
                                                        dy={5}
                                                        dx={2}
                                                    />
                                                    <YAxis 
                                                        yAxisId="right"
                                                        dataKey="uv"
                                                        orientation="left"
                                                        allowDataOverflow={false}
                                                        tick={{ fontSize: 0 }}
                                                    >
                                                        <Label
                                                            style={{
                                                                fontSize: "12px",
                                                                fontWeight: 300, 
                                                                fill: ColorConfig['gray']
                                                            }}
                                                            position="insideTop"
                                                            value={"HEATING"} />
                                                        <Label
                                                            style={{
                                                                fontSize: "12px",
                                                                fontWeight: 300, 
                                                                fill: ColorConfig['gray']
                                                            }}
                                                            position="middle"
                                                            value={"READY"} />
                                                        <Label
                                                            style={{
                                                                fontSize: "12px",
                                                                fontWeight: 300, 
                                                                fill: ColorConfig['gray']
                                                            }}
                                                            position="insideBottom"
                                                            value={"OFF"} />
                                                    </YAxis>
                                                    <Line yAxisId="right" type="stepAfter" dataKey="uv" dot={false} stroke={ColorConfig['green']} />
                                                    <Brush dataKey="name" height={30} travellerWidth={30} stroke={ColorConfig['green']} />
                                                </LineChart>
                                            </ResponsiveContainer>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </IonContent>
                <Footer />
            </IonPage>
        )
    }
}

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        setAppMessage
    })
)(Statistics);