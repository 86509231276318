import {
    IonAlert
} from '@ionic/react';
import Slider from '@mui/material/Slider';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import HeatPumpHelper from "../../../../helpers/HeatPumpHelper";
import HeatPumpMqttHelper from "../../../../helpers/mqqt/HeatPumpMqttHelper";
import { Device } from '@capacitor/device';

const ManualModeBody = props => {

    const HeatPumpMqttHelperOBJ = new HeatPumpMqttHelper(props.mac);
    const HeatPumpHelperOBJ = new HeatPumpHelper(props.device);

    const {minTemp, maxTemp} = HeatPumpHelperOBJ.getCurrentModeSettings();
    const {state: deviceState} = props.device;

    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [tempAlertIsOpen, setTempAlertIsOpen] = useState(false);
    const [platform, setPlatform] = useState("android")
    const { t } = useTranslation();
    
    const [temp, setTemp] = useState(deviceState.temp);

    useEffect(() => {
        setTemp(deviceState.temp);
    }, [deviceState.temp])

    useEffect(async () => {
        const {platform} = await Device.getInfo();
        setPlatform(platform)
    }, [])

    const setTempToDevice = () => {

        if (temp > 65) {
            setTempAlertIsOpen(true);
        }
        
        HeatPumpMqttHelperOBJ.request('setMode', {mode: HeatPumpHelperOBJ.getMode(), setTemp: temp})
    }
    
    return (
        <React.Fragment>
            {HeatPumpHelperOBJ.getMode() != "program" && 
                <div className="range-slider__wrapper">
                    <header className={`range-slider__header ${props.disableDevice ? 'disabled' : ''}`}>
                        <div className="range-slider__header-item">
                            <div className="range-slider__header-label">
                                {t("hp_start_temperature")}

                                {(temp > deviceState.current_max_temp) &&
                                    <svg className="size-24 fill-orange" disabled={props.disableDevice ? true : false} onClick={() => setAlertIsOpen(true)}>
                                        <use xlinkHref="#icon-warning"></use>
                                    </svg>
                                }
                            </div>
                        </div>
                        <div className="range-slider__header-item">
                            <div className="range-slider__output">{temp}°</div>
                        </div>
                        <IonAlert
                            isOpen={alertIsOpen}
                            onDidDismiss={() => setAlertIsOpen(false)}
                            header={t('hp_temp_issue_header')}
                            message={t('hp_temp_issue_text', {temp: deviceState.current_max_temp})}
                            buttons={
                                [{
                                    text: 'OK',
                                    handler: () => {
                                        setAlertIsOpen(false)
                                    }
                                }]
                            }
                        />
                        <IonAlert
                            isOpen={tempAlertIsOpen}
                            onDidDismiss={() => setTempAlertIsOpen(false)}
                            header={t('hp_temp_watch_out_header')}
                            message={t('hp_temp_watch_out_text')}
                            buttons={
                                [{
                                    text: 'OK',
                                    handler: () => {
                                        setTempAlertIsOpen(false)
                                    }
                                }]
                            }
                        />
                    </header>
                    <Slider
                        value={temp}
                        min={minTemp}
                        max={maxTemp}
                        step={1}
                        track="inverted"
                        onChange={(event, value) => {

                            if (platform == "ios" && event.type == "mousedown") {
                                return;
                            }
                            setTemp(value)
                        }}
                        onChangeCommitted={(event, value) => {

                            if (platform == "ios" && event.type == "mouseup") {
                                return;
                            }
        
                            setTempToDevice()
                        }}
                        disabled={props.disableDevice}
                        valueLabelDisplay="off" 
                        className="range-slider range-slider--temperature device-range-slider"
                    />
                </div>
            }
        </React.Fragment>
    );
}


export default ManualModeBody;
