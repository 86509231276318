import {
    IonPage,
    IonAlert,
    IonContent
} from '@ionic/react';
import React from 'react';
import { withTranslation } from 'react-i18next';

import StandartHeader from './components/header/StandartHeader';
import RestHelper from '../../helpers/RestHelper';
import DeviceSettingsHelper from '../../helpers/DeviceSettingsHelper';
import InitialSettings from '../../init';

class NameDevice extends React.Component {

    deleteDevice = () => {

        this.restHelper = new RestHelper();
        this.initialSettings = new InitialSettings();

        const { mac_address } =  this.props.match.params;

        const DeviceSettings = new DeviceSettingsHelper(mac_address);
        
        this.restHelper.post('/delete-user-device', {
            mac: mac_address
        })
        .then (res => {

            global.debug("Deleted");

            DeviceSettings.handleReset();
            this.initialSettings.init();
            this.props.history.push('/dashboard')    
        })
        .catch(error => {
            global.debug("Cannot delete device from server", error);
        })
    }

    render = () => {
        return (
            <IonPage>
                <StandartHeader name={this.props.t('delete_device')}/>

                <IonContent className="ion-padding">
                    <IonAlert
                        isOpen={true}
                        onDidDismiss={() => this.props.history.push('/dashboard')}
                        cssClass='my-custom-class'
                        header={this.props.t('confirm')}
                        message={this.props.t('confirm_device_delete')}
                        buttons={[
                            {
                                text: this.props.t('cancel'),
                                role: 'cancel',
                                cssClass: 'secondary',
                                handler: blah => {
                                    this.props.history.push('/dashboard')
                                }
                            },
                            {
                                text: this.props.t('ok'),
                                handler: () => {
                                    this.deleteDevice()
                                }
                            }
                        ]}
                    />
                </IonContent>
            </IonPage>
        )
    }
    
}

export default withTranslation()(NameDevice);