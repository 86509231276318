import {
    IonPage,
    IonContent,
} from '@ionic/react';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import StandartHeader from '../components/header/StandartHeader';
import error_image from '../../../assets/images/error.svg';
import { InAppBrowser } from '@ionic-native/in-app-browser';

class Errors extends React.Component {


    render = () => {

        const { t } = this.props;
        
        const { deviceName } = this.props.devices[this.props.match.params.mac_address];

        return (
            <IonPage>
                <StandartHeader name={deviceName}/>
    
                <IonContent className="ion-padding">
                    <div className="vertical-center">
                        <div className="device-error">
                            <div className="device-error__icon">
                                <img src={error_image} alt="" />
                            </div>
                            <div className="device-error__text">
                                {t('device_has_error')}
                                <br /><br />
                                <a href="" onClick={()=>InAppBrowser.create('https://tesy.com/contact-us/service-network')} target="_blank">{t('find_service')}</a>
                                <div className="device-error__id ion-padding-top">{t('device_id')}: {this.props.match.params.mac_address}</div>
                            </div>
                        </div>
                    </div>
                </IonContent>
                    
            </IonPage>
        )
    }
}

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(Errors);