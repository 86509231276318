import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonCard,
    IonCardHeader,
    IonItem,
    IonSelect,
    IonSelectOption
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import CalculatorChart from './components/charts/CalculatorChart';
import StatisticsChart from './components/charts/StatisticsChart';
import RestHelper from '../../helpers/RestHelper';
import {
    fetchDevices
} from '../../redux/actions/DevicesAction';

import arrowBack from '../../assets/images/arrow-back.svg';

class Statistics extends React.Component{

    constructor(props) {

        super(props);

        this.device = null;
        this.mac = '';
        this.restHelper = null;

        this.state = {
            StatisticState: 'statistics',
            activity: 'daily',
            statisticData: {
                current: [],
                target: []
            },
            power: ''
        }
    }

    componentDidMount = () => {
        this.restHelper = new RestHelper();
        this.changeStatisticActivity(this.state.activity);
    }

    changeStatistic = type => {

        this.setState({StatisticState: type});
        this.changeStatisticActivity(this.state.activity);
    }

    changeStatisticActivity = async activity => {

        this.setState({activity});

        if (this.state.StatisticState === 'statistics') {

            try {
                
                const res = await this.restHelper.get('/get-device-temp-stat', {
                    model: this.device.model_id,
                    timezone: this.device.timezone,
                    activity: 'daily',
                    mac: this.mac     
                })
               
                if (this.state.activity == 'daily') {

                    this.setState({
                        statisticData: res.data
                    });

                } else {

                    this.setState({
                        statisticData: {
                            current: [],
                            target: []
                        }
                    });
                }

            } catch (e) {
                global.debug(e);
            }


        } else {

            const {data} = await this.restHelper.get('/get-device-power-stat', {
                model: this.device.model_id,
                timezone: this.device.timezone,
                activity: activity,
                mac: this.mac     
            })

            const {state: {watt}} = this.device;

            data.map(row => {
                
                if (row.working_time > 0 && watt !== null) {

                    const kwt =  watt/ 1000; //Need to convert from watt to kwt  
                    const hours = (row.working_time / 60) / 60; //Need to convert from sec to hours
        
                    row.consumption = parseFloat((kwt * hours).toFixed(2));
                }
            });

            this.setState({
                statisticData: data
            });    
        }
    }

    updateDevicePower = async power => {

        this.setState({power});

        await this.restHelper.post('/update-device-power', {
            mac: this.mac,
            power
        });

        const { data: myDevices } = await this.restHelper.get('/get-my-devices')
        this.props.fetchDevices(myDevices);

        this.changeStatisticActivity(this.state.activity);
    }

    render = () => {

        const { t } = this.props;
        this.mac = this.props.match.params.mac_address;

        const {devices} = this.props;

        this.device = devices[this.mac];

        if (!this.device || this.device === undefined) {
            return null;
        }  
        
        const power = this.device.model_watt.split(",");

        return (

            <IonPage>
                <IonHeader className="app-header">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton icon={arrowBack} text=""></IonBackButton>
                    </IonButtons>
                    <IonSegment value={this.state.StatisticState} onIonChange={e => this.changeStatistic(e.detail.value)}>
                        <IonSegmentButton value="statistics">
                            <IonLabel>{t('statistics_button')}</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="calculator">
                            <IonLabel>{t('calculator_button')}</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                </IonToolbar>
                </IonHeader>
        
                <IonContent className="ion-padding">
        
                <div style={{ paddingBottom: '22px' }}>
                    <IonCard>
                    <IonCardHeader className="card-list__header">
                        <div className="card-list__header-left">
                        <div>
                            <img src={this.device.model_image} alt="" />
                        </div>
                        <div>
                            <div className="card-list__title">{this.device.deviceName}</div>
                            {/* //TO DO: Ask for group
                            <div className="card-list__sub-title">Group - Vacation House</div> */}
                        </div>
                        </div>
                        <div className="card-list__header-right"></div>
                    </IonCardHeader>
                    </IonCard>
                </div>
        
                {this.state.StatisticState === 'calculator' &&

                    <div style={{ paddingBottom: '30px' }}>
                        <IonLabel color="primary">{t('activity')}</IonLabel>
                        <IonItem lines="full" className="ion-no-padding ion-no-border">
                            {/* <IonLabel hidden>{t('activity')}</IonLabel> */}
                            <IonSelect placeholder="Select activity" cancel-text={t('cancel')} value={this.state.activity} onIonChange={e => this.changeStatisticActivity(e.detail.value)}>
                                <IonSelectOption value="daily">{t('daily_activity')}</IonSelectOption>
                                <IonSelectOption value="monthly">{t('monthly_activity')}</IonSelectOption>
                                <IonSelectOption value="annual">{t('annual_activity')}</IonSelectOption>
                            </IonSelect>
                        </IonItem>

                        <IonLabel color="primary">{t('power')}</IonLabel>
                        <IonItem lines="full" className="ion-no-padding ion-no-border">
                            {/* <IonLabel hidden>{t('power')}</IonLabel> */}
                            <IonSelect 
                                placeholder={t('power')} 
                                cancel-text={t('cancel')} 
                                value={String(this.state.power !== '' ? this.state.power : this.device.state.watt)} 
                                onIonChange={e => this.updateDevicePower(e.detail.value)}
                            >
                                {power.map(value => <IonSelectOption key={value} value={String(value)}>{value}{t('power_unit')}</IonSelectOption>)}
                            </IonSelect>
                        </IonItem>
                    </div>
                }
        
                <div className={this.state.StatisticState === 'statistics' ? 'ion-hide' : ''}>
                    <CalculatorChart data={this.state.statisticData}/>
                </div>
        
                <div className={this.state.StatisticState === 'calculator' ? 'ion-hide' : ''}>
                    <StatisticsChart data={this.state.statisticData}/>
                </div>
        
                </IonContent>
            </IonPage>
        );
    }
};

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        fetchDevices
    })
)(Statistics);