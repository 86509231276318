import {
    IonContent,
    IonPage,
    IonToolbar,
    IonList,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonRow,
    IonCol,
    IonFooter,
    IonButton
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import StandartHeader from '../components/header/StandartHeader';
import OldDevicesSettingsHelper from '../../../helpers/OldDevicesSettingsHelper';
import { oldModels } from '../../../config/App';

class TemperatureController extends React.Component {

    constructor(props) {

        super(props);

        this.degrees = oldModels[this.props.match.params.model_name].tCorrection;

        this.mac = null;
        this.device = null;
        this.OldDevicesSettingsHelper = null

        this.state = {
            degree: '',
            deviceTCorrection: ''
        }
    }

    componentDidMount = async () => {
        this.OldDevicesSettingsHelper = new OldDevicesSettingsHelper(this.mac);    

        const {data: {tcVal}} = await this.OldDevicesSettingsHelper.setDeviceStatus('tcGet','');

        this.setState({
            deviceTCorrection: tcVal
        });
    }

    confirmAction = () => {
        const degree = this.state.degree !== '' ? this.state.degree : this.state.deviceTCorrection;

        this.OldDevicesSettingsHelper.setDeviceStatus('tmpCRT', degree);

        this.props.history.goBack();
    }

    render = () => {

        this.mac = this.props.match.params.id;
        
        const { t } = this.props;
        global.debug(this.state.deviceTCorrection);
        return (
            <IonPage>
    
            <StandartHeader name={this.props.t('t_controller')}/>
    
            <IonContent className="ion-padding">
                <IonList>
                <IonItem lines="full" className="form-list__item">
                    <IonRow style={{ width: '100%'}}>
                    <IonCol size="12" style={{ padding: '0' }}>
                        <IonLabel position="stacked" color="primary" className="form-label">{t('degree_celsius')}</IonLabel>
                    </IonCol>
                    <IonCol size="12" style={{ padding: '0' }}>
                        {/* <IonLabel hidden>{t('select_degree_celesius')}</IonLabel> */}
                        <IonSelect 
                            placeholder={t('select_degree_celesius')} 
                            value={Number(this.state.degree != '' ? this.state.degree : this.state.deviceTCorrection)} 
                            onIonChange={e => this.setState({degree: e.detail.value})}
                            cancel-text={t('cancel')}
                        >
                        {this.degrees.map((value, i) => (
                            <IonSelectOption value={Number(i)} key={i}>{value} {t('degree_unit')}</IonSelectOption>
                        ))}
                        </IonSelect>
                    </IonCol>
                    </IonRow>
                </IonItem>
                {/* <IonItem lines="full" className="form-list__item">
                    <IonRow style={{ width: '100%'}}>
                    <IonCol size="12" style={{ padding: '0' }}>
                        <IonLabel position="stacked" color="primary" className="form-label">{t('rated_power')}</IonLabel>
                    </IonCol>
                    <IonCol size="12" style={{ padding: '0' }}>
                        <IonLabel hidden>{t('select_rated_power')}</IonLabel>
                        <IonSelect 
                            placeholder={t('select_rated_power')} 
                            value={String(this.state.power !== '' ? this.state.power : d_state.watt)} 
                            onIonChange={e => this.setState({power: e.detail.value})}
                            cancel-text={t('cancel')}
                        >
                        {power.map((value, i) => (
                            <IonSelectOption value={String(value)} key={i}>{value} {t('power_unit')}</IonSelectOption>
                        ))}
                        </IonSelect>
                    </IonCol>
                    </IonRow>
                </IonItem> */}
                </IonList>
            </IonContent>
    
            <IonFooter className="app-footer ion-no-border">
                <IonToolbar>
                    <div className="buttons-list">
                    <div className="buttons-list__item">
                        <IonButton expand="block" shape="round" size="large" onClick={this.confirmAction}>{t('confirm')}</IonButton>
                    </div>
                    </div>
                </IonToolbar>
            </IonFooter>
            </IonPage>
        );
    }
    
};

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(TemperatureController);
