import {
    IonContent,
    IonFooter,
    IonButton,
    IonPage,
    IonToolbar,
    IonText,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonRow,
    IonCol,
    IonSelect,
    IonSelectOption
} from '@ionic/react';

import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';

import StandartHeader from '../../components/header/StandartHeader';
import ToastMessage from '../../components/toast/ToastMessage';
import Loading from '../../components/loading/Loading';
import LocalStorageHelper from '../../../../helpers/LocalStorageHelper';

import {
    setDeviceName,
    setDevicePower,
    deleteNewDevice
} from '../../../../redux/actions/NewDeviceAction';
import { setAppMessage, setAppProcessing } from '../../../../redux/actions/AppAction';
import { HTTP } from '@awesome-cordova-plugins/http';
import WifiHelper from '../../../../helpers/WifiHelper';
import InitialSettings from '../../../../init';
import { Device } from '@capacitor/device';

class NameDevice extends React.Component {

    state = {
        email: '',
        showLoading: false
    }

    componentDidMount = async () => {

        this.setState({
            email: await LocalStorageHelper.get('user.oldAppEmail'),
        })
    }


    continue = () => {

        if (this.state.email != '') {

            const {ssid, enc} = JSON.parse(this.props.newDevice.networkSSID);

            global.debug({
                ssid: ssid,
                pass: this.props.newDevice.networkPassword,
                profile: this.state.email,
                tz: 'Africa/Bamako',
            });

            HTTP.get("http://192.168.82.1/addDev", {
                ssid: ssid,
                pass: this.props.newDevice.networkPassword,
                profile: this.state.email,
                tz: '',
            }, {}).then( async ({data}) => {

                this.setState({showLoading: true});
                
                const info = await Device.getInfo();

                if (info.platform === 'ios') {
                    WifiHelper.disconnectFromNetworkIOS(this.props.newDevice.deviceSSID);
                } else {
                    WifiHelper.disconnect(this.props.newDevice.deviceSSID);
                }

                setTimeout(()=>{

                    const {set} = JSON.parse(data);
                    global.debug('DATA', JSON.parse(data), "SET", set);
                    this.setState({showLoading: false});

                    if (set != undefined && set == 'OK') {

                        this.props.setAppProcessing(false);
                        const initialSettingsODJ = new InitialSettings();
                        initialSettingsODJ.init();

                        this.props.history.push('/dashboard');
                    } else {
                        this.props.setAppMessage(this.props.t('cant_send_data_to_device'));
                    }

                }, 10000)
                
                
                
            })
            .catch(error => {
                global.debug(error);
                this.setState({showLoading: false});
                this.props.setAppMessage(this.props.t('cant_send_data_to_device'));
            });

        } else {
            this.setState({showLoading: false});
            this.props.setAppMessage(this.props.t('all_data_required'));
        }

    }

    render = () => {

        const { t } = this.props;
  
        return (
            <IonPage>

                <StandartHeader name={t('device_settings_tesycloud')} />
        
                <IonContent className="ion-padding">

                    <IonList>
                        <IonItem lines="full" className="form-list__item">
                            <IonLabel position="stacked" color="primary" className="form-label">{t('account_email')}</IonLabel>
                            <IonInput type="text" className="form-input" onIonInput={e => this.setState({email: e.detail.value})}/>
                        </IonItem>
                    </IonList>

                    <ToastMessage />
                    <Loading show={this.state.showLoading}/>
                </IonContent>
                
                <IonFooter className="app-footer ion-no-border">
                    <IonToolbar>
                    <div className="buttons-list">
                        <div className="buttons-list__item">
                        <IonButton expand="block" shape="round" size="large" onClick={this.continue}>{t('continue')}</IonButton>
                        </div>
                    </div>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        );
    }
};

const mapStateToProps = state => {
    return {
        newDevice: state.newDevice
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        setDeviceName,
        setDevicePower,
        deleteNewDevice,
        setAppMessage,
        setAppProcessing
    })
)(NameDevice);
  