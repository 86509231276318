//import ls from 'local-storage';
//import { Preferences } from '@capacitor/preferences';
import { Storage } from '@ionic/storage';

class LocalStorage {

    constructor () {
        this.key_prefix = 'tesy';

        this.createStorage();
    }

    createStorage = async () => {
        const store = new Storage();
        this.store = await store.create();
    }

    set = (key, value) => {
       // ls.set(`${this.key_prefix}.${key}`, value);

        // const realValue = {realValue: value};
        // Preferences.set({key: `${this.key_prefix}.${key}`, value: value});

        this.store.set(`${this.key_prefix}.${key}`, value);
    }

    get = async (key) =>  {
        //return ls.get(`${this.key_prefix}.${key}`);
        
        //const {value} = await Preferences.get({key: `${this.key_prefix}.${key}`});

        const value = await this.store.get(`${this.key_prefix}.${key}`);

        return value;
    }

    //TO DO: listeners when property changed (SET redux state)
}

export default new LocalStorage();