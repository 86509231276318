import {
    IonContent,
    IonPage,
    IonToolbar,
    IonList,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonRow,
    IonCol,
    IonFooter,
    IonButton,
    IonInput
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import StandartHeader from '../components/header/StandartHeader';
import OldDevicesSettingsHelper from '../../../helpers/OldDevicesSettingsHelper';
import Timezones from '../../../config/TimeZones.json';
import timezonePlugin from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/isoWeek';
import weekday from 'dayjs/plugin/weekday';
import en from 'dayjs/locale/en';
import es from 'dayjs/locale/es';
import dayjs from 'dayjs';

class WorldClock extends React.Component {

    constructor(props){
        super(props);

        this.mac = null;
        this.deviceTime = null;
        this.state = {
            selectedTimeZone: '',
            date: '',
            time: '',
            day: 0,
            deviceTimezone: ''
        }
    }

    componentDidMount = async () => {
        this.OldDevicesSettingsHelper = new OldDevicesSettingsHelper(this.mac);

        const {data: {zonename}} = await this.OldDevicesSettingsHelper.setDeviceStatus('getTZ','');

        this.handleTimzoneChange(zonename);
        this.setState({
            deviceTimezone: zonename
        });
    }

    handleTimzoneChange = timezone => {

        this.setState({
            selectedTimeZone: timezone
        });

        const timeZoneObj = this.catchTimeZoneObj(timezone);
         
        this.setState({
            date: timeZoneObj.format("MMMM D, YYYY"),
            time: timeZoneObj.format("HH:mm"),
            day: timeZoneObj.weekday()
        });
    }

    catchTimeZoneObj = timezone => {

        dayjs.Ls.en.weekStart = 1;
        dayjs.extend(utc);
        dayjs.extend(timezonePlugin);
        dayjs.extend(weekday);
        dayjs.locale('en');

        const utcTime = dayjs.utc().format("YYYY-MM-DD HH:mm");
        const localTime = dayjs().format("YYYY-MM-DD HH:mm");

        const timeZoneObj = dayjs(localTime).tz(timezone);

        return timeZoneObj;
    }

    confirmChanges = () => {
        
        if (this.state.selectedTimeZone !== '') {

            this.OldDevicesSettingsHelper.setDeviceStatus('tz', this.state.selectedTimeZone );

            this.props.history.goBack();
        } 
    }

    renderTimezones = () => {
        const timezonesList = [];
        for (const zone in Timezones) {
            for (const timezone in Timezones[zone]) {
                timezonesList.push(<IonSelectOption value={timezone} key={`${zone}_${timezone}`}>{timezone}</IonSelectOption>)
            }
        }

        return timezonesList;
    }

    render = () => {

        //TO DO: Add search in timezone select
        const { t } = this.props;
        this.mac = this.props.match.params.id;

        return (
            <IonPage>
    
            <StandartHeader name={t('world_clock')}/>

            <IonContent className="ion-padding">
                <IonList>
                <IonItem lines="full" className="form-list__item">
                    <IonRow style={{ width: '100%'}}>
                    <IonCol size="12" style={{ padding: '0' }}>
                        <IonLabel position="stacked" color="primary" className="form-label">{t('time_zone')}</IonLabel>
                    </IonCol>
                    <IonCol size="12" style={{ padding: '0' }}>
                        {/* <IonLabel hidden>{t('select_time_zone')}</IonLabel> */}
                        <IonSelect 
                            placeholder={String(this.state.selectedTimeZone !== '' ? this.state.selectedTimeZone : this.state.deviceTimezone)} 
                            value={String(this.state.selectedTimeZone !== '' ? this.state.selectedTimeZone : this.state.deviceTimezone)} 
                            onIonChange={e => this.handleTimzoneChange(e.detail.value)}
                            cancel-text={t('cancel')}
                        >
                        {this.renderTimezones()}
                        </IonSelect>
                    </IonCol>
                    </IonRow>
                </IonItem>
                <IonItem lines="full" className="form-list__item">
                    <IonLabel position="stacked" color="primary" className="form-label">{t('current_date')}</IonLabel>
                    <IonInput value={this.state.date} readonly/>
                </IonItem>
                <IonItem lines="full" className="form-list__item">
                    <IonLabel position="stacked" color="primary" className="form-label">{t('current_time')}</IonLabel>
                    <IonInput value={this.state.time} readonly/>
                </IonItem>
                </IonList>
            </IonContent>
    
            <IonFooter className="app-footer ion-no-border">
                <IonToolbar>
                    <div className="buttons-list">
                    <div className="buttons-list__item">
                        <IonButton expand="block" shape="round" size="large" onClick={this.confirmChanges}>{t('confirm')}</IonButton>
                    </div>
                    </div>
                </IonToolbar>
            </IonFooter>
            </IonPage>
        );
    }
};

const mapStateToProps = state => ({devices: state.oldDevices})

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)
(WorldClock);
  