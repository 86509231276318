import React from 'react';

import './progress.scss';

const Progress = (props) => {

  return (
    <React.Fragment>
      <div className={`progress__box ${props['data-has-icon'] ? 'progress__box--icon' : null}`}>
        {props['data-has-icon'] ? <svg className="progress__box-icon"><use xlinkHref={`#icon-${props["data-icon"]}`}></use></svg> : null}
        
        {props['data-name'] && props['data-value'] ? 
          <div className="progress__header">{}
            <div className="progress__header-item">
              <span className="progress__name">{props['data-name']}</span>
            </div>
            <div className="progress__header-item">
              <span className="progress__value">{props['data-value']}{props['data-suffix']}</span>
            </div>
          </div>
        : null}
        
        <div className="progress">
          <div className="progress__buffer" style={{ width: `${props['data-progress']}%` }}></div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Progress;