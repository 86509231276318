import {
    LOGIN_USER,
    OLD_APP_LOGIN,
} from '../types/UserTypes';

export const userLogin = (isLogged) => {
    return {
        type: LOGIN_USER,
        payload: {
            isLogged
        }
    }
}

export const userOldAppLogin = data => {
    return {
        type: OLD_APP_LOGIN,
        payload: {
            oldAppLogin: data
        }
    }
}