import {
  IonPage,
  IonCardHeader,
  IonCardContent,
  IonCard,    
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonLabel,
  IonFooter,
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import Offline from './components/offline/Offline';

// import '../../assets/css/components/dashboard/dashboard.scss';

import DashBoardHeader from './components/header/DashBoardHeader';
import Progress from './components/progress/Progress';
import DeviceSettingsHelper from '../../helpers/DeviceSettingsHelper';
import OldDevicesSettingsHelper from '../../helpers/OldDevicesSettingsHelper';
import DashBoardHelper from '../../helpers/app/DashBoardRenderDevicesHelper';
import DashBoardGroupsHelper from '../../helpers/app/DashBoardRenderGroupsHelper';
import BelislimoHelper from '../../helpers/BelislimoHelper';
import GroupHelper from '../../helpers/GroupHelper';
import { cn05uvSettings, oldModels } from '../../config/App';
import Refresher from './components/refresh/Refresher';
import InitialSettings from '../../init';
import error from '../../assets/images/error.svg';
import offlineIcon from '../../assets/images/webApp/offline.svg';

class Dashboard extends React.Component {
  

    renderDevice = () => {

        const devices = [];
        const { t } = this.props;

        for (const mac_address in this.props.devices) {

            const device = this.props.devices[mac_address];
            
            const DashBoardHelperOBJ = new DashBoardHelper(device, mac_address, t);
            devices.push(DashBoardHelperOBJ[`render${device.model.toUpperCase()}`]());
        }

        return devices;
    }

  renderOldDevices = () => {

    const devices = [];
    const { t } = this.props;

    for (const id in this.props.oldDevices) {

        const device = this.props.oldDevices[id];
        const { DeviceShortName: name, DeviceStatus: status, id: DeviceID, DeviceSerial } = device;
        const { wsw } = status;
        const [model_name, idNumber] = DeviceSerial.split("-");

        let model = {};
        let modelName = ''; 

        if (model_name == 'none') {
            continue;
        }

        if (wsw !== undefined) {

            modelName = `${model_name}-v2`;
            model = oldModels[`${model_name}-v2`];

            if (status.vac !== undefined && status.vac == "1") {
                status.mode = "10";
            }

        } else {

            modelName = `${model_name}-v1`;
            model = oldModels[`${model_name}-v1`];
        }

        if (!model) {
            continue;
        }

        const mode = Object.keys(model.modes).find(key => model.modes[key] === status[model.commands.mode]);

        let bHelper = null;
        const isBelislimo = modelName.indexOf('2002-') >= 0 ? true : false;

        if (isBelislimo) {
            bHelper = new BelislimoHelper(device);
        }

        if (status[model.commands.err] != undefined && status[model.commands.err] != "0" && status[model.commands.err] != "00") {

            devices.push(
                <div className="card-list__item" key={id}>
                    <IonCard>
                        <IonCardHeader className="card-list__header">
                            <div className="card-list__header-left">
                            <div>
                                <img src={`${process.env.PUBLIC_URL}${isBelislimo ? bHelper.getImage() : model.image}`} alt="" />
                            </div>
                                <div>
                                    <div className="card-list__title">{name ? name : id}</div>
                                    <div className="card-list__sub-title">{model.model}</div>
                                </div>
                            </div>
                            <div className="card-list__header-right">
                                <IonButton color="transparent" className="card-list__header-button" onClick={() => this.props.history.push(`/old/error/${modelName}/${id}`)}>
                                    <svg className="card-list__header-button-icon"><use xlinkHref="#icon-arrow-next"></use></svg>
                                </IonButton>
                            </div>
                        </IonCardHeader>
                        <IonCardContent className="card-list__content">
                            <div className="device-error">
                            <div className="device-error__icon">
                                <img src={error} alt="" />
                            </div>
                            <div className="device-error__text">
                                <a href="" className="device-error__link">{t('error')}</a>
                            </div>
                            </div>
                        </IonCardContent>
                    </IonCard>
                </div>
            )

        } else if (device.DeviceOnline === 1) {
            
            const modelHelper = new OldDevicesSettingsHelper(id);

            let device_status_mode_class = '';
            let device_status_mode_icon_class = '';
            let device_progress_class = '';


            if (status[model.commands.power] === model.powerOff ) {
                device_status_mode_class = 'device-status__mode--gray';
                device_status_mode_icon_class = 'device-status__mode-icon--gray';
                device_progress_class = 'device-progress--gray';
            }

            if (status[model.commands.power] === model.powerOn && status.Text === 'HEATING') {
                device_status_mode_class = 'device-status__mode--red';
                device_status_mode_icon_class = 'device-status__mode-icon--red';
                device_progress_class = 'device-progress--red';  
            }

            devices.push(
                <div className="card-list__item" key={id}>
                  <IonCard>
                    <IonCardHeader className="card-list__header">
                      <div className="card-list__header-left">
                        <div>
                          <img src={`${process.env.PUBLIC_URL}${isBelislimo ? bHelper.getImage() : model.image}`} alt="" />
                        </div>
                        <div>
                          <div className="card-list__title">{name ? name : id} </div>
                          <div className="card-list__sub-title">{model.model}</div>
                          {(status[model.commands.power] === model.powerOn && status[model.commands.tempValue] > 0) ?

                                (status[model.commands.mode] == model.modes.Vacation) ?

                                    <div className="card-list__label">
                                        <svg className="shower size-20"><use xlinkHref="#icon-palm"></use></svg>
                                    </div>
                                :

                                   (model.type == 'boiler' && status[model.commands.boost] == 1) ?

                                        <div className="card-list__label">
                                            {t('boost')}
                                        </div>
                                    :
                              
                                        (isBelislimo) ?
                                            <div className="card-list__label">
                                                {t('ewh_target_temp')} 
                                                {bHelper.renderShowers()}
                                            </div>
                                        :
                                            <div className="card-list__label">{t('ewh_target_temp')} {status[model.commands.tempValue]}°C</div>
                            :
                                null
                          }
                        </div>
                      </div>
                      <div className="card-list__header-right">
                        <IonButton color="transparent" className="card-list__header-button" onClick={() => this.props.history.push(`/${model.type}/${model.apiVersion}/${modelName}/${id}`)}>
                          <svg className="card-list__header-button-icon"><use xlinkHref="#icon-arrow-next"></use></svg>
                        </IonButton>
                      </div>
                    </IonCardHeader>
                    <IonCardContent className="card-list__content">
                      <div className="device-box">
                        <div className="device-box__left">
                          <div className={`device-progress ${device_progress_class}`}>

                            {(status[model.commands.mode] == model.modes.Vacation) ?
                            
                                <div className="device-progress__showers">
                                    <svg className={`shower size-20`}><use xlinkHref="#icon-palm"></use></svg>      
                                </div>
                            :
                                (model.type == 'boiler' && status[model.commands.boost] == 1) ?
                                    null
                                :
                                    (isBelislimo) ?  
                                        <div className="device-progress__showers">
                                            {/* {this.render2002ShowersProgress(status, model)} */}
                                            {bHelper.renderShowersProgress()}
                                        </div>
                                    :
                                        <div className="device-progress__temperature">{status[model.commands.tempValueCurrent] ? status[model.commands.tempValueCurrent] : '-'}°C</div>
                            
                            }

                            {(status[model.commands.mode] != model.modes.Vacation && status[model.commands.boost] != 1) && 
                                <div>{t('еwh_current_temp')}</div>
                            }
                            {status[model.commands.boost] == 1 && 
                                <div>{t('boost')}</div>
                            }
                            {/* onClick={() => modelHelper.handleOnOff(status[model.commands.power] === model.powerOn ? model.powerOff : model.powerOn)} */}
                            <IonButton  
                                color="transparent" 
                                onClick={() => modelHelper.setDeviceStatus(model.commands.power, status[model.commands.power] == model.powerOn ? model.powerOff : model.powerOn)} 
                                className={`device-progress__button button-switch ${status[model.commands.power] === model.powerOn ? 'button-switch--on' : 'button-switch--off'}`}
                            >
                              <svg className="button-switch__icon"><use xlinkHref="#icon-switch"></use></svg>
                            </IonButton>
                          </div>
                        </div>
                        <div className="device-box__right">
                          <div className="device-statis"> 
                            <div className="device-status__item">
                              <div className="device-status__title">{t('device')}</div>
                              <div className={`device-status__mode ${status[model.commands.power] === model.powerOff ? 'device-status__mode--red' : ''}`}>
                                <svg className={`device-status__mode-icon ${status[model.commands.power] === model.powerOff  ? 'device-status__mode-icon--red' : ''}`}>
                                  <use xlinkHref="#icon-switch"></use>
                                </svg> 
                                {status[model.commands.power] === model.powerOff ? t('ewh_inactive_status') : t('ewh_active_status')}
                              </div>
                            </div>
                            <div className="device-status__item">
                              <div className={`device-status__title ${status[model.commands.power] === model.powerOff ? 'device-status__title--gray' : ''}`}>{t('current_mode')}</div>
                              <div className={`device-status__mode ${status[model.commands.power] === model.powerOff ? 'device-status__mode--gray' : ''}`}>
                                <svg className={`device-status__mode-icon ${status[model.commands.power] === model.powerOff ? 'device-status__mode-icon--gray' : ''}`}>
                                  <use xlinkHref="#icon-mode"></use>
                                </svg> 
                                {(model.type == 'boiler' && status[model.commands.boost] == 1) ?
                                    t('boost')
                                :
                                    t(`${mode}_dashboard`)
                                }
                              </div>
                            </div>
                            <div className="device-status__item">
                              <div className={`device-status__title ${status[model.commands.power] === model.powerOff ? 'device-status__title--gray' : ''}`}>{t('status')}</div>
                              <div className={`device-status__mode ${device_status_mode_class}`}>
                                <svg className={`device-status__mode-icon ${device_status_mode_icon_class}`}>
                                  <use xlinkHref="#icon-idle"></use>
                                </svg>
                                { model.type == 'boiler' ? 
                                    status.Text === 'HEATING' ? t('ewh_heating') : t('ewh_ready')
                                :
                                    status.Text === 'HEATING' ? t('heating') : t('ready')
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </IonCardContent>
                  </IonCard>
                </div>
              );

        } else {
            devices.push(
                <div className="card-list__item" key={id}>
                  <IonCard>
                    <IonCardHeader className="card-list__header">
                      <div className="card-list__header-left">
                        <div>
                          <img src={`${process.env.PUBLIC_URL}${isBelislimo ? bHelper.getImage() : model.image}`} alt="" />
                        </div>
                        <div>
                          <div className="card-list__title">{name ? name : id} </div>
                          <div className="card-list__sub-title">{model.model}</div>
                        </div>
                      </div>
                      <div className="card-list__header-right">
                        <IonButton color="transparent" className="card-list__header-button" onClick={() => this.props.history.push(`/${model.type}/${model.apiVersion}/${modelName}/${id}`)}>
                          <svg className="card-list__header-button-icon"><use xlinkHref="#icon-arrow-next"></use></svg>
                        </IonButton>
                      </div>
                    </IonCardHeader>
                    <IonCardContent className="card-list__content">
                      <Offline waitingForConnection={false} localControl={false}/>
                    </IonCardContent>
                  </IonCard>
                </div>
              )
        } 
    }

    return devices;
  }

  renderGroupDevice = (mac, key) => {

    const device = this.props.devices[mac];

    const DashBoardGroupsHelperOBJ = new DashBoardGroupsHelper(device, mac);
    return DashBoardGroupsHelperOBJ[`render${device.model.toUpperCase()}`]()
  }

  renderGroups = () => {

    const groups = [];
    const groupObj = new GroupHelper();

    for(const i in this.props.groups) {

      const { id, name, devices, image, state, group_name} = this.props.groups[i];

      let disableGroup = true;

      devices.map( mac => {
            const device = this.props.devices[mac];

            if(device.hasInternet == true) {
                disableGroup = false;    
            }
      })

      groups.push(
        <div className="card-list__item" key={i}>
          <IonCard>
            <IonCardHeader className="card-list__header">
              <div className="card-list__header-left">
                <div>
                  <img src={image} alt="" />
                </div>
                <div>
                  <div className="card-list__title">{name}</div>
                  <div className="card-list__sub-title">{this.props.t(group_name)}</div>
                </div>
              </div>
              <div className="card-list__header-right">
                <IonButton 
                  disabled = {disableGroup}
                  color="transparent" 
                  className={`button-switch ${state && state.status !== undefined && state.status === 'on' ? 'button-switch--on' : 'button-switch--off'}`}
                  onClick={() => groupObj.handleOnOff(id, (state && state.status !== undefined && state.status === 'on') ? 'off' : 'on')}
                >
                  <svg className="button-switch__icon"><use xlinkHref="#icon-switch"></use></svg>
                </IonButton>
              </div>
            </IonCardHeader>
            <IonCardContent className="card-list__content">

              {devices.map((mac, k) => this.renderGroupDevice(mac, k))}
              
              <div className="ion-text-center">
                <IonButton shape="round" color="secondary" routerLink={`/edit-group/${id}`}>{this.props.t('edit_group')}</IonButton>
              </div>
              {/*TO DO: Ask for this 
              <div className="ion-text-center">
                <IonButton shape="round">SHOW ALL DEVICES</IonButton>
              </div> */}
            </IonCardContent>
          </IonCard>
        </div>  
      )
    }

    return groups;
  }

  renderUnreadMessages = messages => {

    let unreadMessages = 0;

    for (const i in messages) {
      if (messages[i].is_read === 0) {
        unreadMessages++;  
      }
    }

    return unreadMessages;
  }

  doRefresh = event => {

    const init = new InitialSettings();
    init.init();

    setTimeout(() => {
        event.detail.complete();
    }, 3000);
  }

  render = () => {

    // if (window.location.pathname === '/dashboard') {
    //   window.history.pushState(null, document.title, window.location.href);
    // }
    
    const { t, app: { push_notifications_messages, app_processing, app_network } } = this.props;
    const unreadMessages = this.renderUnreadMessages(push_notifications_messages);

    if (!app_network) {
        return (
            <IonPage>
                <DashBoardHeader name={t('dashboard')}/>
                <IonContent className="ion-padding ion-gray-backgroud">
                    <div className="offline">
                        <figure>
                            <img src={offlineIcon} alt="Offline" />
                            <figcaption>
                                <p className="text-red">
                                    {t('offline')}
                                </p>
                            </figcaption>
                        </figure>
                    </div>
                </IonContent>
            </IonPage>    
        )
    }

    return (
      <IonPage>
        
        <DashBoardHeader name={t('dashboard')}/>
        

        <IonContent className="ion-padding ion-gray-backgroud">      

            { Object.keys(this.props.groups).length > 0 &&  
                <IonHeader className="card-list__group-title">{t('groups')}</IonHeader>
            }
            <div className="card-list">
                {this.renderGroups()}
            </div>
    
    
            <IonHeader className="card-list__group-title">{t('devices')}</IonHeader>

            {(app_processing == false && Object.keys(this.props.oldDevices).length === 0 && Object.keys(this.props.devices).length === 0) ?
                <React.Fragment>
                    <div className="dashboard-add">
                        <div className="dashboard-add__title">{t('dont_have_devices')}</div>
                        <svg className="dashboard-add__icon" onClick={() => this.props.history.push('/setup-checklist')}><use xlinkHref="#icon-add-thin"></use></svg>
                        <div className="dashboard-add__sub-title">{t('add_device')}</div>
                        <div>{t('connect_first_device')}</div>
                    </div>
                    <div className="ion-content-push"></div>
                </React.Fragment>
            :
                <div className="card-list">
                    {this.renderDevice()}
                    {this.renderOldDevices()}
                </div>
            }

            <Refresher doRefresh={event => this.doRefresh(event)}/>
        </IonContent>
        
  
        <IonFooter className="app-footer">
          <IonToolbar>
            <IonButtons className="navigation-buttons">
              <IonButton routerLink="/dashboard" className="active">
                <div className="navigation-button">
                  <svg className="navigation-button__icon"><use xlinkHref="#icon-home"></use></svg>
                  <IonLabel className="navigation-button__label">{t('home')}</IonLabel>
                </div>
              </IonButton>
              <IonButton routerLink="/setup-checklist">
                <div className="navigation-button">
                  <svg className="navigation-button__icon"><use xlinkHref="#icon-add"></use></svg>
                  <IonLabel className="navigation-button__label">{t('add_device')}</IonLabel>
                </div>
              </IonButton>

              <IonButton routerLink="/notifications">
                <div className="navigation-button">
                  <svg className="navigation-button__icon"><use xlinkHref="#icon-notifications"></use></svg>
                  <IonLabel className="navigation-button__label">
                    {t('notifications')}
                  </IonLabel>
                  {unreadMessages > 0 ?
                    <span className="navigation-button__badge">{unreadMessages}</span>
                    : null
                  }
                </div>
              </IonButton>
              <IonButton routerLink="/settings">
                <div className="navigation-button">
                  <svg className="navigation-button__icon"><use xlinkHref="#icon-settings"></use></svg>
                  <IonLabel className="navigation-button__label">{t('settings')}</IonLabel>
                </div>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>

        {app_processing == true && 
            <div className="dashboard-preloader">
                <div className="dashboard-preloader__loader"></div>
            </div>
        }
        
      </IonPage>
    );  
  }
  
};

const mapStateToProps = state => {
  return {
    devices: state.devices,
    oldDevices: state.oldDevices,
    app: state.app,
    groups: state.groups
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(Dashboard);