
import {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonInput
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Device } from '@capacitor/device';

import StandartHeader from '../components/header/StandartHeader';
import WaitingScreen from '../components/waitingScreen/WaitingScreen';
import OldDevicesSettingsHelper from '../../../helpers/OldDevicesSettingsHelper';
import AppHelper from '../../../helpers/AppHelper';

class DeviceInfo extends React.Component 
{
    state = {
        deviceInfo: {
            MAC: "",
            wsw: ""
        },
        info: null,
        timezone: '',
        build: null,
        version: null
    }

    componentDidMount = async () => {

        this.OldDevicesSettingsHelper = new OldDevicesSettingsHelper(this.props.match.params.id);
        
        const {data: deviceData} = await this.OldDevicesSettingsHelper.setDeviceStatus('getDevInfo', '');
        const {data: {zonename}} = await this.OldDevicesSettingsHelper.setDeviceStatus('getTZ','');

        const info = await Device.getInfo();
        const {build , version} = await AppHelper.getAppVersion();

        this.setState({
            deviceInfo: deviceData,
            timezone: zonename,
            info,
            build,
            version
        })
    }

    render = () => {
        
        if (!this.state.info) {
            return <WaitingScreen />;
        }
        
        const { t, devices, match: {params: { id } } } = this.props;
        const device = this.props.devices[id];

        return (
            <IonPage>
                
                <StandartHeader name={t('device_info')}/>
                <IonContent className="ion-padding"> 
                    <IonList className="notifications-list">

                        <IonItem lines="full" className="form-list__item">
                            <IonLabel position="stacked" color="primary" className="form-label">{t('device_id')}</IonLabel>
                            <IonInput value={id} readonly/>
                        </IonItem>

                        <IonItem lines="full" className="form-list__item">
                            <IonLabel position="stacked" color="primary" className="form-label">{t('mac_address')}</IonLabel>
                            <IonInput value={this.state.deviceInfo.MAC} readonly/>
                        </IonItem>

                        <IonItem lines="full" className="form-list__item">
                            <IonLabel position="stacked" color="primary" className="form-label">{t('firmware_version')}</IonLabel>
                            <IonInput value={this.state.deviceInfo.wsw} readonly/>
                        </IonItem>

                        <IonItem lines="full" className="form-list__item">
                            <IonLabel position="stacked" color="primary" className="form-label">{t('time_zone')}</IonLabel>
                            <IonInput value={this.state.timezone} readonly/>
                        </IonItem>

                        <IonItem lines="full" className="form-list__item">
                            <IonLabel position="stacked" color="primary" className="form-label">{t('app_version')}</IonLabel>
                            <IonInput value={`${this.state.build} (${this.state.version})`} readonly/>
                        </IonItem>

                        <IonItem lines="full" className="form-list__item">
                            <IonLabel position="stacked" color="primary" className="form-label">{t('app_platform')}</IonLabel>
                            <IonInput value={this.state.info.platform} readonly/>
                        </IonItem>

                        <IonItem lines="full" className="form-list__item">
                            <IonLabel position="stacked" color="primary" className="form-label">{t('os_version')}</IonLabel>
                            <IonInput value={this.state.info.osVersion} readonly/>
                        </IonItem>

                        <IonItem lines="full" className="form-list__item">
                            <IonLabel position="stacked" color="primary" className="form-label">{t('phone')}</IonLabel>
                            <IonInput value={`${this.state.info.manufacturer} ${this.state.info.model}`} readonly/>
                        </IonItem>
                        
                    </IonList>
                    <div className="ion-content-push"></div>
                </IonContent>
            </IonPage>
        ); 
    }
    
}

const mapStateToProps = state => {
    return {
        devices: state.oldDevices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(DeviceInfo);