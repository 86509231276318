

import React from 'react';
import { connect } from 'react-redux'
import {
    IonToast
} from '@ionic/react';

import { hideAppMessage } from '../../../../redux/actions/AppAction';

class ToastMessage extends React.Component {

    hideAppMessage = () => {
        this.props.hideAppMessage();
    }

    render = () => {

        const {app_message, app_message_show} = this.props.app;

        return (
            <IonToast
                isOpen={app_message_show}
                onDidDismiss={this.hideAppMessage}
                message={app_message}
                duration={'duration' in this.props ? this.props.duration : 4000}
                keyboardClose={true}
                position="top"
                color={'color' in this.props ? this.props.color : "warning"}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
      app: state.app
    }
}

export default connect(mapStateToProps, {
    hideAppMessage
})(ToastMessage);