import {
    IonPage,
    IonContent
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';

import AddDeviceComponent from './components/add-device/AddDevice';
import StandartHeader from './components/header/StandartHeader';

const AddDevice = props => {

    return (
        <IonPage>
            <StandartHeader name={props.t('add_device')}/>

            <IonContent className="ion-gray-backgroud">
                <AddDeviceComponent />
            </IonContent>
        </IonPage>
    );
};
  
  export default withTranslation()(AddDevice); 