import {
    IonPage,
    IonContent,
    IonRow,
    IonCol,
    IonButton,
    IonSelect,
    IonSelectOption,
    IonAlert,
    IonText,
    IonLabel
} from '@ionic/react';

import React, { useState, useEffect } from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';

import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import HeatPumpMenu from './components/HeatPumpMenu';
import PageHeader from '../components/header/PageHeader';
import PageSubHeader from '../components/header/PageSubHeader';
import ToastMessage from '../components/toast/ToastMessage';
import Loader from '../components/loading/Loading';
import ChangeDeviceMode from './components/ChangeDeviceMode';

import HeatPumpHelper from "../../../helpers/HeatPumpHelper";
import HeatPumpMqttHelper from "../../../helpers/mqqt/HeatPumpMqttHelper";

import { setAppMessage } from '../../../redux/actions/AppAction';

import Slider from '@mui/material/Slider';
import '../components/range/range.scss';

const Vacation = props => {

    const { 
        devices, 
        match: {
            params: {
                mac_address: mac
            }
        } 
    } = props;
    const device = devices[mac];
    const {state: deviceState} = device;
    const HeatPumpHelperOBJ = new HeatPumpHelper(device);
    const HeatPumpMqttHelperOBJ = new HeatPumpMqttHelper(mac);
    const {minTemp, maxTemp} = HeatPumpHelperOBJ.getCurrentModeSettings("hp");

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    const { t } = useTranslation();
    const [year, setYear] = useState(currentYear);
    const [month, setMonth] = useState(currentDate.getMonth() + 1);
    const [date, setDate] = useState(currentDate.getDate());
    const [temp, setTemp] = useState(minTemp);
    const [mode, setMode] = useState("hp");
    const [modeMinTemp, setModeMinTemp] = useState(minTemp);
    const [modeMaxTemp, setModeMaxTemp] = useState(maxTemp);
    const [deviceNewMode, setDeviceNewMode] = useState(null);
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        const {vacation: vac} = deviceState;

        if (vac && Object.keys(vac).length != 0 ) {
            const vacDate = new Date(vac.startDay);
            vacDate.setDate(vacDate.getDate() + vac.vacDays);

            setYear(vacDate.getFullYear());
            setMonth(vacDate.getMonth() + 1);
            setDate(vacDate.getDate());
            setTemp(vac.temp);
            setMode(vac.mode);
            changeMode(vac.mode);
        }
        
    }, [])

    const renderYears = () => {

        const years = [];
        [currentYear, currentYear + 1, currentYear + 2].map(year => {
            years.push(<IonSelectOption key={`year-${year}`} value={year}>{year}</IonSelectOption>);
        })

        return years;
    }

    const renderMonthDays = () => {

        const monthDays = [];

        const daysInMoth = {
            1: 31,
            2: year % 4 == 0 ? 29 : 28,
            3: 31,
            4: 30,
            5: 31,
            6: 30,
            7: 31,
            8: 31,
            9: 30,
            10: 31,
            11: 30,
            12: 31
        }

        for (let i = 1; i <= daysInMoth[month]; i++) {
            monthDays.push(<IonSelectOption key={i} value={i}>{i}</IonSelectOption>);
        }

        return monthDays;
    }

    const changeMode = mode => {
        setMode(mode);

        const {minTemp: newModeMinTemp, maxTemp: newModeMaxTemp} = HeatPumpHelperOBJ.getCurrentModeSettings(mode);
        setModeMinTemp(newModeMinTemp);
        setModeMaxTemp(newModeMaxTemp);
    }

    const save = () => {

        const newDate = new Date(year, month - 1, date);
        if (newDate.getTime() > currentDate.getTime()) {

            const diffDays = getDiffDays();
            
            if (diffDays <= 99) {
                setDeviceNewMode("va");
            } else {
                props.setAppMessage(t('hp_vacation_max_days_issue'));  
            }

        } else {
            props.setAppMessage(t('hp_vacation_date_cant_be_in_pass'));
        }
    }

    const getDiffDays = () => {

        const newDate = new Date(year, month - 1, date);

        const diffTime = Math.abs(newDate - currentDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

        return diffDays;
    }

    const setVacationMode = () => {

        setShowLoading(true);

        const diffDays = getDiffDays();
        
        HeatPumpMqttHelperOBJ.request('setVacation', {
            startDay: `${currentYear}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`,
            vacDays: diffDays,
            mode,
            temp
        });

        setTimeout(() => {
            setShowLoading(true);
            props.history.goBack();
        }, 2000)
    }

    return (
        <IonPage>
            <Header />
            <IonContent className="ion-no-padding">
                <div className="dashboard">
                    <div className="dashboard__wrapper">
                    
                    <HeatPumpMenu activeMenu="controlPanel" mac={props.match.params.mac_address}/>

                        <div className="dashboard__content">
                            
                            <PageHeader name={t('control_panel')}/>

                            <div className="dashboard-box">
                        
                                <PageSubHeader title={t('coming_from_vacation')}/>

                                <div className="dashboard-box__body">
                                    
                                    <div style={{ maxWidth: '400px', margin: '0 auto' }}>
                                        <div className="ion-text-center mt-20 mb-20">
                                            <svg className="size-80 mx-auto fill-light-blue">
                                                <use xlinkHref="#icon-plane"></use>
                                            </svg>
                                        </div>
                                        <IonText className="ion-text-center text-grey text-16 pb-16">
                                            {t('coming_from_vacation')}
                                        </IonText>
                                        <div className="pb-16">
                                            <IonLabel color="primary" className="form-label">{t('hp_vacation_year')}</IonLabel>
                                            <IonSelect 
                                                placeholder={t('hp_vacation_year')}
                                                onIonChange={e => setYear(e.detail.value)}
                                                cancel-text={t('cancel')}
                                                value={year}
                                            >
                                                {renderYears()}   
                                            </IonSelect>
                                        </div>
                                        <div className="pb-16">
                                            <IonLabel color="primary" className="form-label">{t('hp_vacation_month')}</IonLabel>
                                            <IonSelect 
                                                placeholder={t('hp_vacation_month')}
                                                onIonChange={e => setMonth(e.detail.value)}
                                                cancel-text={t('cancel')}
                                                value={month}
                                            >
                                                <IonSelectOption value={1}>{t('January')}</IonSelectOption>
                                                <IonSelectOption value={2}>{t('February')}</IonSelectOption>
                                                <IonSelectOption value={3}>{t('March')}</IonSelectOption>
                                                <IonSelectOption value={4}>{t('April')}</IonSelectOption>
                                                <IonSelectOption value={5}>{t('May')}</IonSelectOption>
                                                <IonSelectOption value={6}>{t('June')}</IonSelectOption>
                                                <IonSelectOption value={7}>{t('July')}</IonSelectOption>
                                                <IonSelectOption value={8}>{t('August')}</IonSelectOption>
                                                <IonSelectOption value={9}>{t('September')}</IonSelectOption>
                                                <IonSelectOption value={10}>{t('October')}</IonSelectOption>
                                                <IonSelectOption value={11}>{t('November')}</IonSelectOption>
                                                <IonSelectOption value={12}>{t('December')}</IonSelectOption>
                                            </IonSelect>
                                        </div>
                                        <div className="pb-16">
                                            <IonLabel color="primary" className="form-label">{t('hp_vacation_date')}</IonLabel>
                                            <IonSelect 
                                                placeholder={t('hp_vacation_date')}
                                                onIonChange={e => setDate(e.detail.value)}
                                                cancel-text={t('cancel')}
                                                value={date}
                                            >
                                                {renderMonthDays()}    
                                            </IonSelect>
                                        </div>
                                        <div className="pb-16">
                                            <IonLabel color="primary" className="form-label">{t('hp_choose_mode')}</IonLabel>
                                            <IonSelect 
                                                placeholder={t('hp_choose_mode')}
                                                onIonChange={e => changeMode(e.detail.value)}
                                                cancel-text={t('cancel')}
                                                value={mode}
                                            >
                                                <IonSelectOption value="hp">{t('hp_mode_hp')}</IonSelectOption>
                                                <IonSelectOption value="eh">{t('hp_mode_eh')}</IonSelectOption>
                                                <IonSelectOption value="au">{t('hp_mode_au')}</IonSelectOption>
                                                {/* <IonSelectOption value="sm">{t('hp_mode_sm')}</IonSelectOption> */}
                                            </IonSelect>
                                        </div>
                                        <div className="pb-16">
                                        <div className="range-slider__wrapper">
                                            <header className={`range-slider__header`}>
                                                <div className="range-slider__header-item">
                                                <div className="range-slider__header-label text-purple">{t("hp_start_temperature")}</div>
                                                </div>
                                                <div className="range-slider__header-item">
                                                    <div className="range-slider__output">{temp}°</div>
                                                </div>
                                            </header>
                                            <Slider
                                                value={temp}
                                                min={modeMinTemp}
                                                max={modeMaxTemp}
                                                step={1}
                                                track="inverted"
                                                onChange={(event, value) => setTemp(value)}
                                                onChangeCommitted={(event, value) => setTemp(value)}
                                                valueLabelDisplay="off" 
                                                className="range-slider range-slider--temperature device-range-slider"
                                            />
                                        </div>
                                        </div>
                                        <div className="ion-text-center">
                                            <IonButton color="primary" id="save-alert" onClick={save}>
                                                <span className="button__text">{t('save')}</span>
                                            </IonButton>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Loader show={showLoading}/>

                <ChangeDeviceMode 
                    device={device} 
                    newMode={deviceNewMode} 
                    mac={props.mac} 
                    cancelChange={mode => setDeviceNewMode(mode)}
                    changeMode={() => setVacationMode()}
                />
            </IonContent>
            <Footer />
        </IonPage>
    );
};

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    connect(mapStateToProps, {
        setAppMessage,
    })
)(Vacation);