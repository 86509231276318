import {
    IonPage,
    IonContent
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';

import StandartHeader from '../../components/header/StandartHeader';
import deviceImage_1 from '../../../../temp-files/CN06_new.jpg';
import deviceImage_4 from '../../../../temp-files/CN031_new.jpg';
import deviceImage_5 from '../../../../temp-files/CN051_new.jpg';
import deviceImage_6 from '../../../../temp-files/CN052_new.jpg';
import deviceImage_7 from '../../../../temp-files/CN04_new.jpg';
import deviceImage_8 from '../../../../temp-files/CN03_new.jpg';

const AddDevice = props => {

    return (
        <IonPage>
            <StandartHeader name={props.t('choose_convector')}/>

            <IonContent className="ion-gray-backgroud">
                <div className="device-list">
                    
                    {/* <div className="device-list__item" onClick={() => props.history.push('/cn06/choose-device')}>
                        <div className="device-list__preview">
                            <img src={deviceImage_1} alt="photo" />
                        </div>
                        <div className="device-list__content">
                            <div className="device-list__title">{props.t('Convector')}</div>
                        </div>
                    </div>

                    <div className="device-list__item" onClick={() => props.history.push('/cn06/choose-device')}>
                        <div className="device-list__preview">
                            <img src={deviceImage_4} alt="photo" />
                        </div>
                        <div className="device-list__content">
                            <div className="device-list__title">{props.t('Convector CN031')}</div>
                        </div>
                    </div>

                    <div className="device-list__item" onClick={() => props.history.push('/cn06/choose-device')}>
                        <div className="device-list__preview">
                            <img src={deviceImage_5} alt="photo" />
                        </div>
                        <div className="device-list__content">
                            <div className="device-list__title">{props.t('Convector CN051')}</div>
                        </div>
                    </div>

                    <div className="device-list__item" onClick={() => props.history.push('/cn06/choose-device')}>
                        <div className="device-list__preview">
                            <img src={deviceImage_6} alt="photo" />
                        </div>
                        <div className="device-list__content">
                            <div className="device-list__title">{props.t('Convector CN052')}</div>
                        </div>
                    </div> */}

                    <div className="device-list__item" onClick={() => props.history.push('/production-mode/heatecoconvector')}>
                        <div className="device-list__preview">
                            <img src={deviceImage_8} alt="photo" />
                        </div>
                        <div className="device-list__content">
                            <div className="device-list__title">{props.t('Convector HeatEco CN03')}</div>
                        </div>
                    </div>

                    <div className="device-list__item" onClick={() => props.history.push('/production-mode/heatecoconvector')}>
                        <div className="device-list__preview">
                            <img src={deviceImage_7} alt="photo" />
                        </div>
                        <div className="device-list__content">
                            <div className="device-list__title">{props.t('Convector ConvEco CN04')}</div>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};
  
  export default withTranslation()(AddDevice); 