import {
    FETCH_GROUPS,
    UPDATE_GROUP_STATE
} from '../types/GroupsTypes';


const GroupsReducer = (groups = {}, action) => {

    switch(action.type) {

        case FETCH_GROUPS:

            return action.payload.groups;

        case UPDATE_GROUP_STATE:

            let group = {}
            let group_key = 0;
            const { group_id, field, value } = action.payload;

            for (const i in groups) {
                const { id } = groups[i];
    
                if (id == group_id) {
                    group =  groups[i];  
                    group_key = i;
                    break; 
                }
            }

            group.state[field] = value;
            
            return {...groups, [group_key]: group}

        default:
            return groups;
    }
}

export default GroupsReducer;