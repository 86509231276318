import {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonInput,
    IonFooter,
    IonToolbar,
    IonButton,
    IonText,
    IonLabel
} from '@ionic/react';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import { setAppMessage } from '../../redux/actions/AppAction';
import ToastMessage from './components/toast/ToastMessage';
import RestHelper from '../../helpers/RestHelper';
import {detectDeviceType} from '../../helpers/FunctionsHelper';
import InitialSettings from '../../init';
import Header from './components/header/Header';
import PageHeader from './components/header/PageHeader';
import Footer from './components/footer/Footer';
import DeviceCN05UVMenu from './components/menu/DeviceCN05UVMenu';
import HeatPumpMenu from './HPWH4_11_260/components/HeatPumpMenu';
import FooterTwoButtons from './components/footer/FooterTwoButtons';
import E32Menu from './E32/components/E32Menu';
import B15Menu from './B15/components/Menu';

class NameDevice extends React.Component {

    state = {
        deviceName: ''
    }

    componentDidMount = async () => {
        this.restHelper = new RestHelper();
        this.initialSettings = new InitialSettings();
        this.setState({
            deviceName: this.props.devices[this.mac].deviceName    
        })
    }

    confirmAction = async () => {
        if (this.state.deviceName !== '') {

            if (this.state.deviceName.length < 30) {
                try {
                    await this.restHelper.post('/update-device-settings', {
                        mac: this.mac,
                        deviceName: this.state.deviceName
                    });

                    this.initialSettings.init();
                    //this.props.history.replace("/dashboard");
                    this.props.history.goBack();
                } catch (e) {
                    global.debug("Can't update device settings", e);
                }   
            } else {
                this.props.setAppMessage(this.props.t('device_name_max_size'));
            } 

        } else {
            this.props.setAppMessage(this.props.t('required_device_name'));
        }
    }

    renderMenu = () => {

        const {model} = this.props.devices[this.mac];
        const deviceType = detectDeviceType(model);

        switch(deviceType) {
            case 'HeatPump': 
                return <HeatPumpMenu  mac={this.props.match.params.mac_address}/>;
            case 'Boiler_E32': 
                return <E32Menu  mac={this.props.match.params.mac_address}/>;
            case 'Boiler_B15': 
                return <B15Menu activeMenu="deviceGroup" mac={this.props.match.params.mac_address}/>;
            default:
                return <DeviceCN05UVMenu  mac={this.props.match.params.mac_address}/>;
        }
    }

    render = () => {

        const { t } = this.props;
        this.mac = this.props.match.params.mac_address;
        
        const { deviceName } = this.props.devices[this.mac];

        return (
            <IonPage>
                <Header />
                <IonContent className="ion-no-padding">
                    <div className="dashboard">
                        <div className="dashboard__wrapper">

                            {this.renderMenu()}

                            <div className="dashboard__content">
                               
                                <PageHeader name={t('rename_device')} image="pencil"/>

                                <div className="dashboard-box">

                                    <div className="dashboard-box__body">
                            
                                        <IonList>
                                            <IonItem lines="none" className="form-list__item">
                                                <IonLabel position="stacked" color="primary" className="form-label">{t('name')}</IonLabel>
                                                <IonInput 
                                                    type="text" 
                                                    className="form-input" 
                                                    onIonInput={e => this.setState({deviceName: e.detail.value})} 
                                                    value={this.state.deviceName}
                                                />
                                            </IonItem>
                                            <IonItem lines="none" className="form-list__item pt-20">
                                                <FooterTwoButtons save={this.confirmAction} />   
                                            </IonItem>
                                        </IonList>
                        
                                        <ToastMessage />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </IonContent>
                    
                <Footer />
                    
            </IonPage>
        )
    }
}

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        setAppMessage
    })
)(NameDevice);