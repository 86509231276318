import {
    IonButton
} from '@ionic/react';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { Link } from 'react-router-dom';
import { compose } from 'redux'

const DashboardMenu = props => {

    const menuList = [
        {
            'title': props.t('dashboard'),
            'icon': 'dashboard',
            'active': props.activeMenu === 'dashboard' ? true : false,
            'link': '/dashboard'
        },
        {
            'title': props.t('user_manual'),
            'icon': 'manuals',
            'active': props.activeMenu === 'manuals' ? true : false,
            'link': '/devices-tutorials'
        }
    ];
    

    return (
        <aside className="dashboard__aside">
            <div className="dashboard__aside-inner">
                { props.activeMenu !== 'dashboard' && 

                <IonButton color="back-secondary" onClick={() => props.history.goBack()}>
                    <svg className="icon"><use xlinkHref="#icon-arrow-back"></use></svg>
                    <span className="button__text">{props.t('back')}</span>
                </IonButton>
                
                }
                <div className="aside-nav">
                    {menuList.map(({ active, title, icon, link }, i) => (
                        <div className="aside-nav__item" key={i}>
                            <Link to={link} className={`aside-nav__button ${active ? 'aside-nav__button--active' : ''}`}>
                                <svg className="aside-nav__button-icon"><use xlinkHref={`#icon-${icon}`}></use></svg>
                                {title}
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </aside>
    )
}

export default compose(
    withTranslation(),
    withRouter,
)(DashboardMenu);