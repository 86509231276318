import store from '../redux/createStore';
import { showRequestLoader, hideRequestLoader } from '../redux/actions/AppAction';

export default class RequestLoaderHelper {

    constructor(model) {
        this.model = model;
    }

    showRequestLoader() {
        store.dispatch(showRequestLoader(this.model));

        setTimeout(() => {
            
            const { app: {show_request_loader, show_request_loader_for_model} } = store.getState();

            if (show_request_loader && show_request_loader_for_model == this.model) {
                store.dispatch(hideRequestLoader(this.model));
            }

        }, 5000);
    }

    hideRequestLoader() {
        store.dispatch(hideRequestLoader(this.model));
    }
}