import {
    FETCH_DEVICES,
    UPDATE_DEVICE_STATE,
    ADD_DEVICE_PROGRAM,
    FETCH_MY_DEVICES,
    DELETE_DEVICE_PROGRAM,
    DELETE_ALL_DEVICE_PROGRAMS,
    UPDATE_DEVICE_TIME,
    UPDATE_DEVICE_INETERNET_CONNECTION,
    UPDATE_DEVICE_WIFI,
    SET_DEVICE_LOCAL_USAGE,
    UPDATE_DEVICE_WAITING_FOR_CONNECTION,
    UPDATE_DEVICE_MODE_TIME_REMAINING
} from '../types/DeviceTypes';

export const fetchDevices = devices => {
    return {
        type: FETCH_DEVICES,
        payload: devices
    }
}

export const fetchMyDevices = devices => {
    return {
        type: FETCH_MY_DEVICES,
        payload: devices
    }
}

export const updateDeviceInternet = (status, macAddress) => {
    return {
        type: UPDATE_DEVICE_INETERNET_CONNECTION,
        payload: {
            status,
            macAddress,
        }
    }
}

export const updateDeviceWaitingForConnection = (status, macAddress) => {
    return {
        type: UPDATE_DEVICE_WAITING_FOR_CONNECTION,
        payload: {
            status,
            macAddress,
        }
    }
}

export const setDeviceLocalUsage = (status, macAddress) => {
    return {
        type: SET_DEVICE_LOCAL_USAGE,
        payload: {
            status,
            macAddress,
        }
    }
}

export const updateDeviceState = (state, field, macAddress) => {
    return {
        type: UPDATE_DEVICE_STATE,
        payload: {
            state,
            macAddress,
            field
        }
    }
}

export const updateDeviceModeTimeRemaining = (time, macAddress) => {
    return {
        type: UPDATE_DEVICE_MODE_TIME_REMAINING,
        payload: {
            time,
            macAddress,
        }
    }
}


export const updateDeviceTime = (timezone, date, time, macAddress) => {
    return {
        type: UPDATE_DEVICE_TIME,
        payload: {
            timezone,
            date,
            time,
            macAddress
        }
    }
}

export const addDeviceProgram = (macAddress, key, program) => {
    return {
        type: ADD_DEVICE_PROGRAM,
        payload: {
            key,
            program,
            macAddress
        }
    }
}

export const deleteDeviceProgram = (macAddress, key) => {
    return {
        type: DELETE_DEVICE_PROGRAM,
        payload: {
            key,
            macAddress
        }
    }
}

export const deleteAllDevicePrograms = (macAddress) => {
    return {
        type: DELETE_ALL_DEVICE_PROGRAMS,
        payload: {
            macAddress
        }
    }
}

export const updateDeviceWifi = (wifiData, macAddress) => {
    return {
        type: UPDATE_DEVICE_WIFI,
        payload: {
            wifiData,
            macAddress
        }
    }
}

