import {
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonPage,
    IonFooter,
    IonButtons,
    IonButton,
    IonBackButton
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import StandartHeader from '../components/header/StandartHeader';

import RestHelper from '../../../helpers/RestHelper';
import HeatPumpHelper from "../../../helpers/HeatPumpHelper";

const DeviceCalculator = props => {

    const { t } = useTranslation();
    const [totalKwh, setTotalKwh] = useState(0);

    const { 
        devices, 
        match: {
            params: {
                mac_address: mac
            }
        } 
    } = props;

    const device = devices[mac];
    const HeatPumpHelperOBJ = new HeatPumpHelper(device);
    const restHelperOBJ = new RestHelper();

    useEffect(async () => {

        const {data} = await restHelperOBJ.get('/get-device-power-stat', {
            model: device.model_id,
            timezone: device.timezone,
            activity: "monthly",
            mac: mac     
        });

        let total = 0;

        for (const i in data) {
            const {enEH, enHP, enPV} = data[i];
            total += (enEH + enHP +enPV);
        }

        setTotalKwh(total);
    
    }, [])


    return (
        <IonPage>
            <StandartHeader name={t('calculator')}/>

            <div className="ion-padding">
                <div className={`range-circle`}>
                    <div className="range-circle__inner">
                        <div className="range-circle__metric">{t('hp_calc_kwh')}</div>
                        <div className="range-circle__value">{totalKwh}</div>
                        <div className="range-circle__label">{t('hp_calc_last_month')}</div>

                        {/* ТО DO: How to extract the info */}
                        <div className="range-circle__option">{HeatPumpHelperOBJ.getModelSize()}{t('hp_calc_model_size_unit')}</div>
                    </div>
                </div>
            </div>
            
            <IonFooter className="app-footer ion-no-border">
                <IonToolbar>
                    <div className="range-buttons">
                        <div className="range-buttons__item">
                            <IonButton 
                                expand="block" 
                                shape="round" 
                                size="large" 
                                color="help"
                                routerLink={`/device/hp/calculator-history/${device.model.toLowerCase()}/${mac}`}
                            >
                                {t('hp_calc_history_btn')}
                            </IonButton>
                        </div>
                    </div>
                </IonToolbar>
            </IonFooter>
        </IonPage>
    );
};

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    connect(mapStateToProps, {})
)(DeviceCalculator);
