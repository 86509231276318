import {
    IonContent,
    IonPage,
    IonList,
    IonItem,
    IonIcon
} from '@ionic/react';

import React from 'react';
import arrowNext from '../../assets/images/arrow-list.svg';
import StandartHeader from './components/header/StandartHeader';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { InAppBrowser } from '@ionic-native/in-app-browser';


class Tutorials extends React.Component {


    renderDocuments = (documents, groupID, modelID) => {
       
        const list = [];    
        
        if (modelID !== undefined){

            const { documents: modelDocuments } =  documents[groupID]['models'][modelID];
            const { links, videos, files } = modelDocuments;

            if (links !== undefined) {
                for (const i in links) {

                    const { name, link } = links[i];

                    list.push(
                        <IonItem key={`links-${i}`} button href={link} target="_blank" className="manuals-list__item">
                            <div slot="start">
                                {/* TO DO: image for link */}
                                <svg className="manuals-list__icon manuals-list__icon--md"><use xlinkHref={`#icon-file-video`}></use></svg>
                            </div>
                            {name}
                            <IonIcon icon={arrowNext} slot="end"></IonIcon>
                        </IonItem>
                    )
                }
            }

            if (files !== undefined) {
                for (const i in files) {

                    const { name, link } = files[i];

                    list.push(
                        <IonItem key={`files-${i}`} button href={link} target="_blank" className="manuals-list__item">
                            <div slot="start">
                                <svg className="manuals-list__icon manuals-list__icon--md"><use xlinkHref={`#icon-file-pdf`}></use></svg>
                            </div>
                            {name}
                            <IonIcon icon={arrowNext} slot="end"></IonIcon>
                        </IonItem>
                    )
                }
            }
            
            if (videos !== undefined) {
                for (const i in videos) {

                    const { name, link } = videos[i];

                    list.push(
                        <IonItem key={`videos-${i}`} button href={link} target="_blank" className="manuals-list__item">
                            <div slot="start">
                                <svg className="manuals-list__icon manuals-list__icon--md"><use xlinkHref={`#icon-file-video`}></use></svg>
                            </div>
                            {name}
                            <IonIcon icon={arrowNext} slot="end"></IonIcon>
                        </IonItem>
                    )
                }
            }

            return list;
        }


        if (groupID !== undefined) {

            const { models } = documents[groupID];

            for (const i in models) {

                const { modelName, modelImage } = models[i];

                list.push(
                    <IonItem key={i} button routerLink={`/tutorials/${groupID}/${i}`} className="manuals-list__item">
                        <div slot="start">
                            <img src={modelImage}/>
                        </div>
                        {modelName}
                        <IonIcon icon={arrowNext} slot="end"></IonIcon>
                    </IonItem>
                )
            }

            return list;
        }

        for(const i in documents) {
            const { groupName, groupImage } = documents[i];

            list.push(
                <IonItem key={i} button routerLink={`/tutorials/${i}`} className="manuals-list__item">
                    <div slot="start">
                        <img src={groupImage}/>
                    </div>
                    {groupName}
                    <IonIcon icon={arrowNext} slot="end"></IonIcon>
                </IonItem>
            )
        }

        return list;
    }

    render = () => {

        const { t, app: { documents }, match: { params: { groupID, modelID }} } = this.props;

        return (
            <IonPage>
                <StandartHeader name={t('user_manual')}/>
                <IonContent className="ion-padding">

                    <IonList className="manuals-list">
                        {this.renderDocuments(documents, groupID, modelID)}
                    </IonList>
                    
                </IonContent>
            </IonPage>
        );
    }
};

const mapStateToProps = state => {
    return {
        app: state.app
    }
}


export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(Tutorials);
  