const PageHeader = props => {

    return (
        <header className="title">
            <svg className="icon icon-24 fill-gray">
                <use xlinkHref={`#icon-${props.image !== undefined ? props.image : 'dashboard'}`}></use>
            </svg>
            {props.name}
        </header>
    )
}

export default PageHeader;