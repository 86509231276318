import {
    IonContent,
    IonFooter,
    IonButton,
    IonPage,
    IonToolbar,
    IonText,
} from '@ionic/react';

import React, {useState} from 'react';
import { withTranslation } from 'react-i18next';

import StandartHeader from './components/header/StandartHeader';
import AndroidPermissionsHelper from '../../helpers/AndroidPermissionsHelper';
import Loading from './components/loading/Loading';
import { LocationAccuracy } from '@ionic-native/location-accuracy';
import locationServicesImage from '../../files/locationServices.svg';

const LocationServices = props => {

    const [showLoading, setShowLoading] = useState(false);

    const allowLocationServices = () => {

        setShowLoading(true);

        AndroidPermissionsHelper.enableGPSPermisssions()
        .then(async res => {

            const {hasPermission} = await AndroidPermissionsHelper.checkGPSPermissions();

            if (hasPermission === true) {
                
                LocationAccuracy.canRequest().then(canRequest => {

                    if(canRequest) {
                        // the accuracy option will be ignored by iOS
                        LocationAccuracy.request(LocationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
                            () => { 
                                setShowLoading(false);
                                props.history.push('/choose-device-group');
                            },
                            error => global.debug('Error requesting location permissions', error) //TO DO: If user don't want to activate GPS
                        );
                    }
                
                });
            } else {
                //TO DO: decide what to do if the user refuse to allow the permissions
            }
        })
        .catch(err => {
            //TO DO: maybe it will has a problem some times
        });  
    }

    return (
        <IonPage>
        
        <StandartHeader name={props.t('location_services')} />

        <IonContent className="ion-padding">
            <div className="circle-image circle-image--md">
                <img src={locationServicesImage}/>
            </div>
            {/* TO DO: Ask for text */}
            <IonText>
                <h1>{props.t('location_services')}</h1>
                <p>{props.t('local_control_text1')}</p>
                <p>{props.t('local_control_text2')}</p>
            </IonText>
            <Loading show={showLoading}/>
        </IonContent>
        
        <IonFooter className="app-footer ion-no-border">
            <IonToolbar>
                <div className="buttons-list">
                <div className="buttons-list__item">
                    <IonButton expand="block" shape="round" size="large" onClick={() => allowLocationServices(true)}>{props.t('allow_location_services')}</IonButton>
                </div>
                </div>
            </IonToolbar>
        </IonFooter>
        </IonPage>
    );
};

export default withTranslation()(LocationServices);
  