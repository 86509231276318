import {
    IonPage,
    IonContent,
    IonButton,
    IonLabel,
    IonInput,
    IonSelectOption,
    IonSelect
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import HeatPumpMenu from './components/HeatPumpMenu';
import PageHeader from '../components/header/PageHeader';
import PageSubHeader from '../components/header/PageSubHeader';

import RestHelper from '../../../helpers/RestHelper';
import DeviceSettingsHelper from '../../../helpers/DeviceSettingsHelper';

import Timezones from '../../../config/TimeZones.json';

import '../components/range/range.scss';

import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import timezonePlugin from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

class WorldClock extends React.Component {

    constructor(props){
        super(props);

        this.mac = null;
        this.deviceTime = null;
        this.state = {
            selectedTimeZone: '',
            date: '',
            time: '',
            day: 0
        }
    }

    componentDidMount = () => {
        this.DeviceSettingsHelper = new DeviceSettingsHelper(this.mac);
        this.restHelper = new RestHelper();
    }

    handleTimzoneChange = timezone => {

        this.setState({
            selectedTimeZone: timezone
        });

        const timeZoneObj = this.catchTimeZoneObj(timezone);
         
        this.setState({
            date: timeZoneObj.format("MMMM D, YYYY"),
            time: timeZoneObj.format("HH:mm"),
            day: timeZoneObj.weekday()
        });
    }

    catchTimeZoneObj = timezone => {

        dayjs.Ls.en.weekStart = 1;
        dayjs.extend(utc);
        dayjs.extend(timezonePlugin);
        dayjs.extend(weekday);
        dayjs.locale('en');

        const utcTime = dayjs.utc().format("YYYY-MM-DD HH:mm");
        const localTime = dayjs().format("YYYY-MM-DD HH:mm");

        const timeZoneObj = dayjs(localTime).tz(timezone);

        return timeZoneObj;
    }

    confirmChanges = () => {
        
        let day = this.state.day;
        let time = this.state.time;

        if (this.state.selectedTimeZone === '') {
            const { timezone } = this.props.devices[this.mac];   
            
            const timeZoneObj = this.catchTimeZoneObj(timezone);
            day = timeZoneObj.weekday();
            time = timeZoneObj.format("HH:mm");

        } else {
            //SEND THE NEW TIMEZONE TO CLOUD
            this.restHelper.post('/update-device-settings', {
                mac: this.mac,
                timezone: this.state.selectedTimeZone
            });
        }

        this.DeviceSettingsHelper.handleTimezone({
            day: day,
            time: time,
            timezone: this.state.selectedTimeZone !== '' ? this.state.selectedTimeZone : this.props.devices[this.mac].timezone,
            date: this.state.date !== '' ? this.state.date : this.deviceTime.date,
        })

        //this.props.history.push(`/device-settings/${this.mac}`);
        this.props.history.goBack();
    }

    renderTimezones = () => {
        const timezonesList = [];
        for (const zone in Timezones) {
            for (const timezone in Timezones[zone]) {
                timezonesList.push(<IonSelectOption value={timezone} key={`${zone}_${timezone}`}>{timezone}</IonSelectOption>)
            }
        }

        return timezonesList;
    }

    calculateDeviceTime = timezone => {
        const timeZoneObj = this.catchTimeZoneObj(timezone);
        return timeZoneObj.format("HH:mm");
    }

    calculateDeviceDate = timezone => {
        const timeZoneObj = this.catchTimeZoneObj(timezone);
        return timeZoneObj.format("MMMM D, YYYY");
    }

    render = () => {

        this.mac = this.props.match.params.mac_address;
        
        const { t } = this.props;
        const {devices} = this.props;

        const device = devices[this.mac];
        
        if (!device || device === undefined) {
            return null;
        }

        const { state: d_state } = device;
        try {
             this.deviceTime = JSON.parse(device.time);
        } catch (e) {
            global.debug("Cannot parse device time", e);
        }

        return (
            <IonPage>
                <Header />
                <IonContent className="ion-no-padding">
                    <div className="dashboard">
                        <div className="dashboard__wrapper">
    
                            <HeatPumpMenu activeMenu="worldClock" mac={this.mac}/>
    
                            <div className="dashboard__content">
                        
                                <PageHeader name={t('world_clock')} image="world-clock"/>
    
                                <div className="dashboard-box">
    
                                    <PageSubHeader title={t('world_clock')}/>
    
                                    <div className="dashboard-box__body">
                                    
                                        <div style={{ maxWidth: '400px', margin: '0 auto' }}>
                                            <div className="pb-16">
                                                <IonLabel position="stacked" color="primary" className="form-label">Time Zone</IonLabel>
                                                <IonSelect 
                                                    placeholder={String(this.state.selectedTimeZone !== '' ? this.state.selectedTimeZone : device.timezone)} 
                                                    value={String(this.state.selectedTimeZone !== '' ? this.state.selectedTimeZone : device.timezone)} 
                                                    onIonChange={e => this.handleTimzoneChange(e.detail.value)}
                                                    cancel-text={t('cancel')}
                                                >
                                                    {this.renderTimezones()}
                                                </IonSelect>
                                            </div>
                                            <div className="pb-16">
                                                <IonLabel position="stacked" color="primary" className="form-label">{t('current_date')}</IonLabel>
                                                <IonInput 
                                                    type="text" 
                                                    className="form-input" 
                                                    value={this.state.date !== '' ? this.state.date : this.calculateDeviceDate(device.timezone)} 
                                                    readonly={true} 
                                                    disabled={true} 
                                                />
                                            </div>
                                            <div className="pb-16">
                                                <IonLabel position="stacked" color="primary" className="form-label">{t('current_time')}</IonLabel>
                                                <IonInput 
                                                    type="text" 
                                                    className="form-input" 
                                                    value="9:43 AM" 
                                                    readonly={true} 
                                                    disabled={true}
                                                    value={this.state.time !== '' ? this.state.time : this.calculateDeviceTime(device.timezone)} 
                                                />
                                            </div>
                                            <div className="ion-text-center">
                                                <IonButton color="primary" onClick={this.confirmChanges}>{t('confirm')}</IonButton>
                                            </div>        
                                        </div> 
    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </IonContent>
                <Footer />
            </IonPage>
        );
    }
    
};
  
const mapStateToProps = state => ({devices: state.devices})

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)
(WorldClock);