import store from '../redux/createStore';
import RestHelper from './RestHelper';
import { oldModels } from '../config/App';
import {
    setOldDevicesLoader,
} from '../redux/actions/AppAction';

class OldDeviceSettingsHelper
{
    constructor(id) {

        this.id = id;

        const { oldDevices, user } = store.getState();

        this.device = oldDevices[id];
        this.user = user;
        this.restHelper = new RestHelper();

        const { DeviceStatus: {wsw}, DeviceSerial} = this.device;
        const [model_name, idNumber] = DeviceSerial.split("-");

        if (wsw !== undefined) {
            this.model = oldModels[`${model_name}-v2`];
        } else  {
            this.model = oldModels[`${model_name}-v1`];
        }
    }

    setDeviceStatus = (command, value, showLoader = true) => {

        if (showLoader) {
            store.dispatch(setOldDevicesLoader(true));
        }

        return this.restHelper.post('/old-app-set-device-status', {
            ...this.user.oldAppLogin,
            id: this.device.id,
            apiVersion: this.model.apiVersion,
            command,
            value
        });  
    }

    editDevice = (property, value) => {

        store.dispatch(setOldDevicesLoader(true));
        
        return this.restHelper.post('/old-app-edit-device', {
            ...this.user.oldAppLogin,
            id: this.device.id,
            property,
            value
        });  
    }

    deleteDevice = () => {

        store.dispatch(setOldDevicesLoader(true));
        
        return this.restHelper.post('/old-app-delete-device', {
            ...this.user.oldAppLogin,
            id: this.device.id
        });  
    }

    getStatistics = () => {

        store.dispatch(setOldDevicesLoader(true));
        
        return this.restHelper.post('/old-app-get-statistics', {
            ...this.user.oldAppLogin,
            id: this.device.id,
        });  
    }

    getPower = () => {

        store.dispatch(setOldDevicesLoader(true));
        
        return this.restHelper.post('/old-app-get-power', {
            ...this.user.oldAppLogin,
            id: this.device.id,
        });  
    }
}

export default OldDeviceSettingsHelper;