import {
    IonContent,
    IonPage,
    IonList,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonRow,
    IonCol,
    IonInput,
    IonText
} from '@ionic/react';

import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { Device } from '@capacitor/device';

import WifiHelper from '../../helpers/WifiHelper';
import StandartHeader from './components/header/StandartHeader';
import DeviceSettingsHelper from '../../helpers/DeviceSettingsHelper';
import Loading from './components/loading/Loading';
import TwoButtons from './components/footer/TwoButtons';
import Refresher from './components/refresh/Refresher';
import { setDeviceLocalUsage } from '../../redux/actions/DevicesAction';

class ChangeConnections extends React.Component {

    state = {
        selectedNetwork: '',
        selectedPass: null,
        passwordShown: false,
        appInfo: {},
        showLoading: false
    }

    componentDidMount = async () => {

        global.debug("Did mount");
        this.DeviceSettingsHelper = new DeviceSettingsHelper(this.mac);
        this.DeviceSettingsHelper.handleGetSSID();

        const info = await Device.getInfo();

        this.setState({
            appInfo: info
        })
    }

    togglePasswordVisiblity = () => {
        this.setState({passwordShown: this.state.passwordShown ? false : true});
    };

    renderNetworksList = list => {

        let ssidArray = [];

        return list.map((SSID, k) => {

            if (!ssidArray.includes(SSID)) {

                ssidArray.push(SSID);
                return <IonSelectOption value={SSID} key={k}>{SSID}</IonSelectOption>
            }
        })
    }

    doRefresh = event => {

        this.DeviceSettingsHelper.handleGetSSID();

        setTimeout(() => {
            event.detail.complete();
        }, 2000);
    }

    save = () => {
        this.DeviceSettingsHelper.handleDeviceWifi({
            wifiSSID: this.state.selectedNetwork !== '' ? this.state.selectedNetwork : this.wifi_ssid,
            wifiPass: this.state.selectedPass !== '' ? this.state.selectedPass : this.wifi_pass,
        })
        this.props.setDeviceLocalUsage(false, this.mac);

        this.props.history.goBack();
    }


    render = () => {
        const { t } = this.props;
        this.mac = this.props.match.params.mac_address;

        const { wifi_ssid, wifi_pass, state: { wifiInfo } } = this.props.devices[this.mac];
        this.wifi_ssid = wifi_ssid;
        this.wifi_pass = wifi_pass;

        global.debug('FOUND LIST OF WIFI: ', wifiInfo);
        
        if (wifiInfo === undefined || wifiInfo === '')  {
            return (
                <IonPage>      
                    <StandartHeader name={t('change_wifi')}/> 
                    <IonContent className="ion-padding">
                        <Loading show={true}/>
                    </IonContent>
                </IonPage>  
            )
        }

        const wifiInfoLisp = wifiInfo.split(',');

        return (
            <IonPage>      
                <StandartHeader name={t('change_wifi')}/>
        
                <IonContent className="ion-padding">
                    <IonText>
                        <p>{t('connect_to_network')}</p>
                    </IonText>
                    <IonList>
                        <IonItem lines="full" className="form-list__item">
                        <IonRow style={{ width: '100%'}}>
                            <IonCol size="12" style={{ padding: '0' }}>
                            <IonLabel position="stacked" color="primary" className="form-label">{t('wifi_network')}</IonLabel>
                            </IonCol>
                            <IonCol size="12" style={{ padding: '0' }}>
                            {/* <IonLabel hidden>{t('select_wifi_network')}</IonLabel> */}
                            <IonSelect 
                                placeholder={t('select_wifi_network')}
                                value={this.state.selectedNetwork !== '' ? this.state.selectedNetwork : wifi_ssid} 
                                onIonChange={e => this.setState({selectedNetwork: e.detail.value})}
                                cancel-text={t('cancel')}
                            >
                                {this.renderNetworksList(wifiInfoLisp)}
                            </IonSelect>
                            </IonCol>
                        </IonRow>
                        </IonItem>
                        <IonItem lines="full" className="form-list__item">
                        <IonLabel position="stacked" color="primary" className="form-label">{t('password')}</IonLabel>
                        <div className="form-password">
                            <IonInput 
                                type={this.state.passwordShown ? "text" : "password"} 
                                className="form-input" 
                                value={this.state.selectedPass !== null ? this.state.selectedPass : wifi_pass}
                                onIonInput={e => this.setState({selectedPass: e.detail.value})}
                            />
                            <button type="button" ion-button="true" className="form-password__button" onClick={this.togglePasswordVisiblity}>
                            <svg className="form-password__button-icon"><use xlinkHref={`#icon-eye-${this.state.passwordShown ? 'closed' : 'open'}`}></use></svg>
                            </button>
                        </div>
                        </IonItem>
                    </IonList>
                    <Refresher doRefresh={event => this.doRefresh(event)}/>
                </IonContent>
                <TwoButtons 
                    redirect={`/device-settings/${this.mac}`}
                    save={() => this.save()}
                />
            </IonPage>
        );
    }

};

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        setDeviceLocalUsage
    })
)(ChangeConnections);
  