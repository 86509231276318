import { WifiWizard2 } from '@ionic-native/wifi-wizard-2';
import store from '../redux/createStore';
import { logger } from '../redux/actions/AppAction';

class WifiHelper {

    scanForWifiNetworks = async () => {
        await WifiWizard2.startScan();
        const wifiResults = WifiWizard2.getScanResults({numLevels: 1});

        return wifiResults;
    }

    connectToNetwork = async (ssid, pass = null) => {
        try {
            return await WifiWizard2.connect(
                ssid, 
                false, 
                pass,
                "WPA"
            )
        } catch (e) {
            store.dispatch(logger(`WifiHelper: Error connection to new network: ${e.message}`));
            global.debug(`Error connection to new network: ${e.message}`);
            throw Error(`WifiHelper: Error connection to new network: ${e.message}`);
        }
    }

    getConnectedSSID = () => {
        return WifiWizard2.getConnectedSSID();
    }

    connectToNetworkIOS = (ssid, pass = false) => {
        return WifiWizard2.iOSConnectNetwork(ssid, pass);
    }

    disconnectFromNetworkIOS = async ssid => {
        return await WifiWizard2.iOSDisconnectNetwork(ssid);
    }

    disconnect = async ssid => {
        try {
            await WifiWizard2.disconnect(ssid);
            
        } catch(e) {
            global.debug("ERROR DISCONECTING WIFI", e)
        }

        try {
            await WifiWizard2.disableWifi();
            
        } catch(e) {
            global.debug("ERROR DISABLE WIFI", e)
        }
        
        setTimeout(() => {
            try{
                WifiWizard2.enableWifi();    
            } catch(e) {
                global.debug("ERROR WIFI ENABLE", e)
            }
        }, 1000);
    }

    disable = ssid => {
        return WifiWizard2.disable(ssid);
    }

}

export default new WifiHelper();