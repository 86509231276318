import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { setupIonicReact } from '@ionic/react';
import { connect } from 'react-redux';
import { isPlatform } from '@ionic/react';

//Import pages
import Welcome from './pages/webApp/Welcome';
import Login from './pages/webApp/Login';
import ForgottenPassword from './pages/webApp/ForgottenPassword';
import ChangePassword from './pages/webApp/ChangePassword';
import DashBoard from './pages/webApp/Dashboard';
import DeviceCN05UV from './pages/webApp/DeviceCN05UV';
import DeviceCN05UVProgram from './pages/webApp/DeviceCN05UVProgram';
import AddProgram from './pages/webApp/AddProgram';
import AddToGroup from './pages/webApp/AddToGroup';
import EditGroup from './pages/webApp/EditGroup';
import CreateGroup from './pages/webApp/CreateGroup';
import DeviceCN05UVSettings from './pages/webApp/DeviceCN05UVSettings';
import Notifications from './pages/webApp/Notifications';
import ViewNotification from './pages/webApp/ViewNotification';
import AccountDetails from './pages/webApp/AccountDetails';
import Tutorials from './pages/webApp/Tutorials';
import ConvectorsTutorials from './pages/webApp/ConvectorsTutorials';
import BoilersTutorials from './pages/webApp/BoilersTutorials';
import HeatPumpsTutorials from './pages/webApp/HeatPumpsTutorials';
import ModelTutorials from './pages/webApp/ModelTutorials';
import DevicesTutorials from './pages/webApp/DevicesTutorials';
import NameDevice from './pages/webApp/NameDevice';
import DeviceInfo from './pages/webApp/DeviceInfo';
import Statistics from './pages/webApp/Statistics';
import OldAppForgottenPassword from './pages/webApp/OldAppForgottenPassword';

//OLD DEVICES
import ConvectorApiV1 from './pages/webApp/oldDevices/convectors/apiv1/ConvectorApiV1';
import BoilerApiV1 from './pages/webApp/oldDevices/boilers/apiv1/BoilerApiV1';
import BoilerApiV2 from './pages/webApp/oldDevices/boilers/apiv2/BoilerApiV2';
import NameOldDevice from './pages/webApp/oldDevices/NameDevice';
import OldDeviceSettings from './pages/webApp/oldDevices/OldDeviceSettings';
import BoilerApiV1Settings from './pages/webApp/oldDevices/BoilerApiV1Settings';
import OldDeviceInfo from './pages/webApp/oldDevices/DeviceInfo';
import StatisticsOldDevice from './pages/webApp/oldDevices/Statistics';
import ErrorsOldDevice from './pages/webApp/oldDevices/Errors'
import CalculatorGraphicOldDevice from './pages/webApp/oldDevices/CalculatorGraphic';
import CalculatorStatisticsOldDevice from './pages/webApp/oldDevices/CalculatorStatistics';
import OldDeviceProgram from './pages/webApp/oldDevices/Program';
import OldDeviceAddProgram from './pages/webApp/oldDevices/AddProgram';
import OldDeviceProgramAPIV2 from './pages/webApp/oldDevices/ProgramAPIV2';
import OldDeviceAddProgramAPIV2 from './pages/webApp/oldDevices/AddProgramAPIV2';
import OldDeviceSettingsAPIV2 from './pages/webApp/oldDevices/BoilerApiV2Settings';
import OldDeviceInfoAPIV2 from './pages/webApp/oldDevices/DeviceInfoAPIV2';
import CalculatorGraphicAPIV2 from './pages/webApp/oldDevices/CalculatorGraphicAPIV2';
import CalculatorStatisticsAPIV2 from './pages/webApp/oldDevices/CalculatorStatisticsAPIV2';

//Helpers
import InitialSetttings from './init';
import LocalStorage from './helpers/LocalStorageHelper';
import Loader from './pages/webApp/components/loading/Loading';

//IMPORT ALL EXTERNAL ROUTES
import HPWH411260Routes from './pages/webApp/HPWH4_11_260/Routes';
import E32Routes from './pages/webApp/E32/Routes';
import B15Routes from './pages/webApp/B15/Routes';

setupIonicReact({
    mode: 'ios',
    swipeBackEnabled: false
});

class App extends React.Component {

    componentDidMount = () => {

        const initialSetttings = new InitialSetttings();
        initialSetttings.init();  
        initialSetttings.initAppListeners();

        // global.debug("Loading web app", this.props.user.isLogged, window.location.pathname);

        // //Start from homepage if user open another page directly from browser
        // if (isPlatform('desktop') === true && window.location.pathname !== '/dashboard' && LocalStorage.get('user.id') > 0) {
        //     window.location.href = '/dashboard';
        // }
    }

    render = () => {

        const { isLogged } = this.props.user; 

        return (
        <IonApp className="">
            <IonReactRouter>

                <HPWH411260Routes />
                <E32Routes />
                <B15Routes />

                <Switch>

                    <Route exact path="/welcome" component={Welcome}/>
                    <Route exact path="/login/:index" component={Login}/> 
                    <Route exact path="/forgotten-password" component={ForgottenPassword}/>
                    <Route exact path="/changePassword/:token" component={ChangePassword} />
                    <Route exact path="/dashboard" component={DashBoard}/> 
                    <Route exact path="/create-group/:mac_address" component={CreateGroup}/>
                    <Route exact path="/edit-group/:id" component={EditGroup}/>
                    <Route exact path="/notifications" component={Notifications}/>
                    <Route exact path="/notification/:message_id" component={ViewNotification}/>
                    <Route exact path="/account-details" component={AccountDetails}/>
                    <Route exact path="/user-manuals/:groupID?/:modelID?" component={Tutorials}/>
                    <Route exact path="/old-app-forgotten-password" component={OldAppForgottenPassword}/>
                    <Route exact path="/convectors-tutorials" component={ConvectorsTutorials}/>
                    <Route exact path="/boilers-tutorials" component={BoilersTutorials}/>
                    <Route exact path="/heat-pumps-tutorials" component={HeatPumpsTutorials}/>
                    <Route exact path="/model-tutorials/:model" component={ModelTutorials}/>
                    <Route exact path="/devices-tutorials" component={DevicesTutorials}/>

                    {/* TO DO: These routes are for model cn05uv */}
                    <Route exact path="/device/cn05uv/:mac_address" component={DeviceCN05UV}/>
                    <Route exact path="/device/program/cn05uv/:mac_address/:day?" component={DeviceCN05UVProgram}/>
                    <Route exact path="/device/program/add-program/:model/:mac_address/:day" component={AddProgram}/>
                    <Route exact path="/device-settings/cn05uv/:mac_address" component={DeviceCN05UVSettings}/>

                    {/* Routes for convector apiv1 */}
                    <Route exact path="/convector/apiv1/:model_name/:id" component={ConvectorApiV1}/>

                    {/* Routes for boiler apiv1 */}
                    <Route exact path="/boiler/apiv1/:model_name/:id" component={BoilerApiV1}/>
                    <Route exact path="/boiler/apiv1/devices-settings/:model_name/:id" component={BoilerApiV1Settings}/>

                    {/* Routes for boiler apiv2 */}
                    <Route exact path="/boiler/apiv2/:model_name/:id" component={BoilerApiV2}/>
                    <Route exact path="/old/apiv2/program/:model_name/:id/:programsCount/:index/:day?" component={OldDeviceProgramAPIV2}/>
                    <Route exact path="/old/apiv2/program/add-program/:model_name/:id/:day/:programsCount/:index" component={OldDeviceAddProgramAPIV2}/>
                    <Route exact path="/old/apiv2/device-settings/:model_name/:id" component={OldDeviceSettingsAPIV2}/>
                    <Route exact path="/old/apiv2/device-info/:model_name/:id" component={OldDeviceInfoAPIV2}/>
                    <Route exact path="/old/apiv2/calculator-graphic/:model_name/:id" component={CalculatorGraphicAPIV2}/>
                    <Route exact path="/old/apiv2/calculator-statistics/:model_name/:id" component={CalculatorStatisticsAPIV2}/>

                    {/* Routes for all old devices */}
                    <Route exact path="/old/name-device/:model_name/:id" component={NameOldDevice}/>
                    <Route exact path="/old/device-settings/:model_name/:id" component={OldDeviceSettings}/>
                    <Route exact path="/old/device-info/:model_name/:id" component={OldDeviceInfo}/>
                    <Route exact path="/old/statistics/:model_name/:id" component={StatisticsOldDevice}/>
                    <Route exact path="/old/error/:model_name/:id" component={ErrorsOldDevice}/>
                    <Route exact path="/old/calculator-graphic/:model_name/:id" component={CalculatorGraphicOldDevice}/>
                    <Route exact path="/old/calculator-statistics/:model_name/:id" component={CalculatorStatisticsOldDevice}/>
                    <Route exact path="/old/program/:model_name/:id/:programsCount/:index/:day?" component={OldDeviceProgram}/>
                    <Route exact path="/old/program/add-program/:model_name/:id/:day/:programsCount/:index" component={OldDeviceAddProgram}/>

                    {/* TO DO: These routes are for all devices maybe ? */}
                    <Route exact path="/add-to-group/:mac_address" component={AddToGroup}/>
                    <Route exact path="/name-device/:mac_address" component={NameDevice}/>
                    <Route exact path="/device-info/:mac_address" component={DeviceInfo}/>
                    <Route exact path="/statistics/:mac_address" component={Statistics}/>

                    

                </Switch>
                {isLogged === true ? <Redirect exact path="/" to="/dashboard" /> : <Redirect exact path="/" to="/welcome" />}
            </IonReactRouter>

            <Loader show={this.props.app.old_devices_loader}/>
            <Loader show={this.props.app.show_request_loader}/>
        </IonApp>
        );
    }
  
};

const mapStateToProps = state => {

  return {
    user: state.user,
    app: state.app
  }
}

export default connect(mapStateToProps)(App);
