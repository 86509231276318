import { AndroidPermissions } from '@ionic-native/android-permissions';

class AndroidPermissionsHelper {

    enableGPSPermisssions = () => {
        //ALLOW PERMISSIONS
        const permissionsList = [
            AndroidPermissions.PERMISSION.ACCESS_COARSE_LOCATION,
            AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION,
            AndroidPermissions.PERMISSION.LOCATION_HARDWARE,
        ]

        return AndroidPermissions.requestPermissions(permissionsList);
    }

    checkGPSPermissions = () => AndroidPermissions.checkPermission(AndroidPermissions.PERMISSION.ACCESS_COARSE_LOCATION);
    
}

export default new AndroidPermissionsHelper();