import {
    IonContent,
    IonFooter,
    IonButton,
    IonPage,
    IonToolbar,
    IonText,
    IonSelect,
    IonSelectOption,
    IonLabel,
    IonItem,
    IonInput,
} from '@ionic/react';

import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { Device } from '@capacitor/device';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import dayjs from 'dayjs';

import {
    chooseDevice,
    setDeviceWifiArray,
    chooseNetwork,
    setDevicePower,
    setDeviceVolume,
    setDeviceName,
    chooseNetworkOriginal,
    chooseNetworkPass,
    setDeviceMacAddress,
    setDeviceModel,
    setDeviceApPassword
} from '../../../../../redux/actions/NewDeviceAction';
import { setAppMessage, logger } from '../../../../../redux/actions/AppAction';

import StandartHeader from '../../../components/header/StandartHeader';
import ToastMessage from '../../../components/toast/ToastMessage';
import Loading from '../../../components/loading/Loading';
import WifiHelper from '../../../../../helpers/WifiHelper';
import DeviceRestHelper from '../../../../../helpers/DeviceRestHelper';
import AppHelper from '../../../../../helpers/AppHelper';
import chooseDeviceImage from '../../../../../files/chooseDevice.svg';
import { app_mode, cloud_url, development_cloud_url, modelsSettings } from '../../../../../config/App';
import ProvisioningHelper from '../../../../../helpers/ProvisioningHelper';

const SSID = "tesystand";
const SSID_PASS = "verysecret";
const DEVICE_POWER = "2000";
const DEVICE_VOLUME = "80";
const DEVICE_NAME = "B15";

class ChooseDevice extends React.Component {

    state = {
        networks: [],
        selectedNetwork: "",
        appInfo: {},
        build: null,
        version: null,
        showLoading: false
    }

    componentDidMount = async () => {

        const info = await Device.getInfo();
        const {build , version} = await AppHelper.getAppVersion();

        this.setState({
            appInfo: info,
            build,
            version,
            //selectedNetwork: this.props.newDevice.deviceSSID
            selectedNetwork: ""
        });

        document.removeEventListener('ionBackButton', (ev) => {
            document.body.classList.remove("qrscanner");
        });

        document.addEventListener('ionBackButton', (ev) => {
            document.body.classList.remove("qrscanner");
        });

        this.deviceRest = new DeviceRestHelper(modelsSettings.B15.http_api_url);
    }


    sendDataToDevice = async () => {

        const currentTime = dayjs().format("HH:mm:ss");
        const ap_pass = Math.random().toString().substring(2, 12);

        this.deviceRest.post('/', {
            cloud_url: app_mode === 'development' ? development_cloud_url : cloud_url, 
            http_api_url: modelsSettings.B15.http_api_url, 
            ap_pass,
            wifiSSID: SSID,
            wifiPass: SSID_PASS,
            time: currentTime,
            power: DEVICE_POWER,
            capacity: DEVICE_VOLUME
        })
        .then(async res => {

            let {connected, mac, model} = res.data;

            if (connected === true) {

                if (this.state.appInfo.platform === 'ios') {
                    WifiHelper.disconnectFromNetworkIOS(SSID);
                } else {
                    WifiHelper.disconnect(SSID);
                }

                //WifiHelper.disconnect(SSID);

                setTimeout(async () => {

                    this.setState({
                        showLoading: false,
                    })

                    try {
                        const isDeviceProvisioned = await ProvisioningHelper.check(mac, this.props);

                        if (isDeviceProvisioned) {
                            this.props.setDeviceMacAddress(mac);
                            this.props.setDeviceModel(model);
                            this.props.setDeviceApPassword(ap_pass);
                            this.props.chooseNetworkOriginal(SSID);
                            this.props.chooseNetworkPass(SSID_PASS, false);
                            this.props.setDeviceName(DEVICE_NAME);
                            this.props.setDevicePower(DEVICE_POWER);
                            this.props.setDeviceVolume(DEVICE_VOLUME);
    
                            this.props.history.push('/production-mode/b15/finish');
                        }
                        
                    } catch (e) {
                        this.props.history.push('/unsuccessful-regstriration');
                    }
                    
                }, 10000)

                

                

                
            } else {
                //TO DO: what to do here
            }
        })
        .catch (error => {

            global.debug("FIrst catch");
            this.setState({
                showLoading: false
            })
            
            this.props.logger(`WifiPassword: Error while sending data to device: ${error.message}`);
            this.props.setAppMessage(this.props.t('device_connection_problem'));
            //this.props.history.push('/connect/b15/choose-device');
        })

    }


    /**
     * Remember the origin app netwokr
     * Connect to static device wifi name (IOS / Android)
     * Extract wifi array from device
     * Set device data into redux state 
     */
    

    continue = async () => {

        if (this.state.selectedNetwork !== '') {

            this.props.logger(dayjs().format("HH:mm:ss")+" STEP 1: Connect to device AP (Tesy-CN06AS) \n\n");
            this.setState({
                showLoading: true
            });


            const ap_name = this.state.selectedNetwork;

            this.props.chooseDevice(ap_name);
            
            try {

                this.props.logger(`1.Trying to connect from phone: ${this.state.appInfo.manufacturer} ${this.state.appInfo.model}, os version: ${this.state.appInfo.osVersion}, app version: ${this.state.version} (${this.state.build})`);

                if (this.state.appInfo.platform === 'ios') {
                    await WifiHelper.connectToNetworkIOS(ap_name);
                } else {
                    await WifiHelper.connectToNetwork(ap_name, "");
                }

                this.deviceRest.post('/wifi', {
                    command: 'wifi'
                })
                .then(async res => {

                    let {connected, wifi} = res.data;

                    this.sendDataToDevice();
                   //this.props.setDeviceWifiArray(wifi);

                    // this.setState({
                    //     showLoading: false
                    // });

                    //this.props.history.push('/connect/b15/change-wifi');

                })
                .catch (error => {

                    this.setState({
                        showLoading: false
                    })
                    
                    this.props.logger(`ChooseDevice: Cannot take the wifi response from device: ${error.message}`);
                    this.props.setAppMessage(this.props.t('device_connection_problem'));
                })

            } catch (e) {

                this.setState({
                    showLoading: false
                })

                this.props.logger(`ChooseDevice: Cannot connect to device network: ${e}`);
                this.props.setAppMessage(this.props.t('device_connection_problem'));
            }

        } else {
            this.props.setAppMessage(this.props.t('select_network_ios'));
        }

    }

    scanQR = async () => {
        // Check camera permission
        // This is just a simple example, check out the better checks below

        const {asked, granted} = await BarcodeScanner.checkPermission({ force: true });

        if (granted !== undefined && granted === true) {


            // make background of WebView transparent
            // note: if you are using ionic this might not be enough, check below
            BarcodeScanner.hideBackground();

            document.body.classList.add("qrscanner"); 

            const result = await BarcodeScanner.startScan(); // start scanning and wait for a result

            document.body.classList.remove("qrscanner");
            // if the result has content
            if (result.hasContent) {

                const {SSID} = JSON.parse(result.content);
                this.setState({selectedNetwork: SSID});
                global.debug(SSID, "SSID");

            } else {
                this.props.setAppMessage(this.props.t("cant_scan_qr"));
            }
    
        } else {
            this.props.setAppMessage(this.props.t('camera_permissions_not_allowed'));
        }
    }

    render = () => {
        const { t } = this.props;

        return (
            <IonPage>        
                
                <StandartHeader name={t('choose_device')} />

                <IonContent className="ion-padding">
                    <div className="circle-image">
                        <img src={chooseDeviceImage} />
                    </div>                
                    
                    <IonLabel color="primary">{t('device')}</IonLabel>
                    
                    <IonItem lines="full" className="form-list__item">
                        <IonLabel position="stacked" color="primary" className="form-label"></IonLabel>
                        <IonInput 
                            type="text" 
                            className="form-input" 
                            placeholder='TCH-XXXX' 
                            value={this.state.selectedNetwork}
                            onIonInput={ e => this.setState({selectedNetwork: e.detail.value !== '' ? `${e.detail.value}` : ''})}
                        />
                    </IonItem>


                    <ToastMessage />
                    <Loading show={this.state.showLoading}/>

                </IonContent>
                
                <IonFooter className="app-footer ion-no-border">
                    <IonToolbar>
                        <div className="buttons-list">
                            <div className="buttons-list__item">
                                <IonButton expand="block" shape="round" size="large" onClick={this.continue}>{t('continue')}</IonButton>
                            </div>
                            <div className="buttons-list__item">
                                <IonButton expand="block" shape="round" size="large" onClick={this.scanQR}>{t('scanQR')}</IonButton>
                            </div>
                        </div>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        );
    }
    
};

const mapStateToProps = state => {
    return {
        newDevice: state.newDevice
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        chooseDevice,
        setAppMessage,
        setDeviceWifiArray,
        chooseNetwork,
        logger,
        setDevicePower,
        setDeviceVolume,
        setDeviceName,
        chooseNetworkOriginal,
        chooseNetworkPass,
        setDeviceMacAddress,
        setDeviceModel,
        setDeviceApPassword
    })
)(ChooseDevice);
  