import {
    IonContent,
    IonPage,
    useIonAlert,
    IonLabel,
    IonItem,
    IonSelect,
    IonSelectOption
} from '@ionic/react';

import {
    ResponsiveContainer,
    XAxis,
    YAxis,
    BarChart,
    Bar,
    Legend,
    Tooltip,
    Brush
} from 'recharts';

import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'

import StandartHeader from '../components/header/StandartHeader';
import RestHelper from '../../../helpers/RestHelper';

import '../components/charts/statistics-chart.scss';

const CalculatorHistory = props => {
    
    const { t } = useTranslation();
    const [fullStat, setFullStat] = useState(null);
    const [activity, setActivity] = useState("weekly");
    const [activityData, setActivityData] = useState(null);
    const [chartName, setChartName] = useState(t('hp_calc_weekly_chart'));
    const [presentAlert] = useIonAlert();
    const chartHeight = 310;
    const ColorConfig = {
        "red": "#EB445A",
        "green": "#2EC5A2",
        "gray": "#3b3b3b",
        "gray2": "#92949c",
        "purple": "#9360DA"
    }

    const { 
        devices, 
        match: {
            params: {
                mac_address: mac
            }
        } 
    } = props;

    const device = devices[mac];
    const restHelperOBJ = new RestHelper();

    useEffect(async () => {

        const {data} = await restHelperOBJ.get('/get-device-power-stat', {
            model: device.model_id,
            timezone: device.timezone,
            activity: "full",
            mac: mac     
        });
        setFullStat(data);

        getActivityData("weekly");
    
    }, [])

    const getActivityData = async (period) => {

        const {data} = await restHelperOBJ.get('/get-device-power-stat', {
            model: device.model_id,
            timezone: device.timezone,
            activity: period,
            mac: mac     
        });

        setActivityData(data);
    }

    const changeStatisticActivity = period => {
        setActivity(period);
        setChartName(t(`hp_calc_${period}_chart`))
        getActivityData(period);
    }

    const renderTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="custom-tooltip__label">{payload[0].payload.main_label}</p>
                    <div className="tooltip-legend">
                        {payload.map((pld, i) => (
                            <div className="tooltip-legend__item" key={i} style={{ '--color': `${pld.fill}` }}>
                                {pld.value} {t('hp_calc_kwh')}
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
              
        return null;
    };

    return (
        <IonPage>
            <StandartHeader name={t('hp_calculator_history_title')}/>

            <IonContent className="ion-padding">
                {fullStat && 
                    
                    <div className="consumption">
                        <div className="consumption__item">
                            <div className="consumption__title">{t('hp_calc_kwh')}</div>
                            <div className="consumption__value">{fullStat.daily}</div>
                            <div className="consumption__title consumption__title--sm">{t('hp_calc_history_today')}</div>
                        </div>
                        <div className="consumption__item">
                            <div className="consumption__title">{t('hp_calc_kwh')}</div>
                            <div className="consumption__value">{fullStat.monthly}</div>
                            <div className="consumption__title consumption__title--sm">{t('hp_calc_history_last_month')}</div>
                        </div>
                        <div className="consumption__item">
                            <div className="consumption__title">{t('hp_calc_kwh')}</div>
                            <div className="consumption__value">{fullStat.yearly}</div>
                            <div className="consumption__title consumption__title--sm">{t('hp_calc_history_last_year')}</div>
                        </div>
                    </div>
                
                }

                <div className="text-small" style={{ paddingBottom: '30px' }}>
                    {t('hp_calc_data_inductive')}
                    <svg className="size-24 fill-orange" onClick={() =>
                        presentAlert({
                            header: t('hp_calc_data_inductive_note'),
                            message: t('hp_calc_data_inductive_info'),
                            buttons: [
                                {
                                    text: t('ok'),
                                    handler: () => {
                                        global.debug('confirm');
                                    }
                                }
                            ],
                        })
                    }>
                        <use xlinkHref="#icon-info"></use>
                    </svg>
                </div>

                <div style={{ paddingBottom: '30px' }}>
                    <IonLabel color="primary">{t('activity')}</IonLabel>
                    <IonItem lines="full" className="ion-no-padding ion-no-border">
                        <IonSelect placeholder="Select activity" cancel-text={t('cancel')} value={activity} onIonChange={e => changeStatisticActivity(e.detail.value)}>
                            <IonSelectOption value="weekly">{t('hp_calc_weekly_activity')}</IonSelectOption>
                            <IonSelectOption value="monthly">{t('hp_calc_monthly_activity')}</IonSelectOption>
                            <IonSelectOption value="annual">{t('hp_calc_annual_activity')}</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                </div>

                {activityData &&
                    <div className="charts-list">
                        <div className="charts-list__item">
                            <div className="charts-list__title">{chartName}</div>
                            <ResponsiveContainer width={'100%'} height={chartHeight}>
                                <BarChart data={activityData} margin={{ top: 0, right: 0, left: 0, bottom: 0 }} barSize={11} barGap={1} className="hide-brush-texts">
                                    <XAxis
                                        dataKey="name"
                                        interval="preserveStart"
                                        fontSize={12}
                                        dy={2}
                                        dx={2} 
                                        padding={{ left: 8, right: 8 }}
                                    />
                                    <YAxis 
                                        width={35}
                                        orientation="left"
                                        tick={{ fontSize: 10 }}
                                    />
                                    <Tooltip content={renderTooltip} cursor={{ fill: "#F3F3F3" }} />
                                    <Legend iconType={'square'} iconSize={11} />
                                    <Brush 
                                        height={30} 
                                        travellerWidth={30} 
                                    />
                                    <Bar dataKey="enHP" fill={ColorConfig['green']} />
                                    <Bar dataKey="enEH" fill={ColorConfig['red']} />
                                    <Bar dataKey="enPV" fill={ColorConfig['purple']} />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                }

                <div className="ion-content-push"></div>
            </IonContent>
        </IonPage>
    );
        
}

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    connect(mapStateToProps, {})
)(CalculatorHistory);