import {
    IonContent,
    IonFooter,
    IonButton,
    IonPage,
    IonToolbar,
    IonText,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonRow,
    IonCol,
    IonSelect,
    IonSelectOption
} from '@ionic/react';

import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';

import StandartHeader from '../../components/header/StandartHeader';
import ToastMessage from '../../components/toast/ToastMessage';
import Loading from '../../components/loading/Loading';

import {
    setDeviceName,
    setDevicePower,
    deleteNewDevice,
    setDeviceVolume,
    setDeviceMacAddress,
    setDeviceModel,
    setDeviceApPassword
} from '../../../../redux/actions/NewDeviceAction';
import { setAppMessage, logger } from '../../../../redux/actions/AppAction';
import dayjs from 'dayjs';
import DeviceRestHelper from '../../../../helpers/DeviceRestHelper';
import { app_mode, cloud_url, development_cloud_url, modelsSettings } from '../../../../config/App';
import WifiHelper from '../../../../helpers/WifiHelper';
import ProvisioningHelper from '../../../../helpers/ProvisioningHelper';
import { Device } from '@capacitor/device';

class NameDevice extends React.Component {

    state = {
        capacity: '',
        devicePower: '',
        deviceVolume: '',
        showLoading: false
    }

    componentDidMount = () => {
        this.setState({
            devicePower: this.props.newDevice.power
        });    
    }

    continue = async () => {
        if (this.state.deviceName !== '' && this.state.devicePower !== '' && this.state.deviceVolume !== '') {

            this.props.setDeviceName(this.state.deviceName);
            this.props.setDevicePower(this.state.devicePower);
            this.props.setDeviceVolume(this.state.deviceVolume);

            this.props.logger(dayjs().format("HH:mm:ss")+" STEP 2: Send Wifi and Pass to device \n\n");

            this.setState({
                showLoading: true
            })

            try {

                const currentTime = dayjs().format("HH:mm:ss");
                const ap_pass = Math.random().toString().substring(2, 12);
                const deviceRest = new DeviceRestHelper(modelsSettings.B15.http_api_url);

                this.props.logger(`WifiPassword: Sending to device: wifi ssid: ${this.props.newDevice.networkSSID}, wifi pass: ${this.state.password}`);

                //TO DO: What we are doing with TIME. How we send it. The problem is that we need sent it here otherwise we are loosing  connection to the dvice because it's connected to WIFI
                //TO DO: Gives an AP_NAME to device

                global.debug(`cloud_url: ${app_mode === 'development' ? development_cloud_url : cloud_url}`);

                deviceRest.post('/', {
                    cloud_url: app_mode === 'development' ? development_cloud_url : cloud_url, 
                    http_api_url: modelsSettings.B15.http_api_url, 
                    ap_pass,
                    wifiSSID: this.props.newDevice.networkSSID,
                    wifiPass: this.props.newDevice.networkPassword,
                    time: currentTime,
                    power: this.state.devicePower,
                    capacity: this.state.deviceVolume
                })
                .then(async res => {

                    let {connected, mac, model} = res.data;

                    if (connected === true) {


                        const info = await Device.getInfo();
                        if (info.platform === 'ios') {
                            WifiHelper.disconnectFromNetworkIOS(this.props.newDevice.deviceSSID);
                        } else {
                            WifiHelper.disconnect(this.props.newDevice.deviceSSID);
                        }

                        //WifiHelper.disconnect(this.props.newDevice.deviceSSID);

                        setTimeout(async () => {

                            this.setState({
                                showLoading: false,
                            })

                            try {
                                const isDeviceProvisioned = await ProvisioningHelper.check(mac, this.props);

                                if (isDeviceProvisioned) {
                                    this.props.setDeviceMacAddress(mac);
                                    this.props.setDeviceModel(model);
                                    this.props.setDeviceApPassword(ap_pass);
            
                                    this.props.history.push('/connect/b15/finish');
                                }   
                            } catch (e) {
                                this.props.history.push('/unsuccessful-regstriration');
                            }
                            
                        }, 10000)
                        
                    } else {
                        //TO DO: what to do here
                    }
                })
                .catch (error => {

                    global.debug("FIrst catch", error.message, error);
                    this.setState({
                        showLoading: false
                    })
                    
                    this.props.logger(`WifiPassword: Error while sending data to device: ${error.message}`);
                    this.props.setAppMessage(this.props.t('device_connection_problem'));
                    this.props.history.push('/connect/b15/choose-device');
                })

            } catch (e) {

                global.debug("SEcond catch");
                this.setState({
                    showLoading: false
                })

                this.props.logger(`WifiPassword: Cannot send data to device: ${e.message}`);
                this.props.setAppMessage(this.props.t('device_connection_problem'));
                this.props.history.push('/');
            }
            

        } else {
            this.props.setAppMessage(this.props.t('required_device_settings'));
        }
    }

    render = () => {

        const { t } = this.props;
        let { modelPower } = this.props.newDevice;
        modelPower = modelPower.split(',');
        
        return (
            <IonPage>

                <StandartHeader name={t('device_settings')} />
        
                <IonContent className="ion-padding">
                    <IonText className="ion-text-center">
                        <h1>{t('device_name')}</h1>
                    </IonText>
        
                    <IonList>
                        <IonItem lines="full" className="form-list__item">
                            <IonLabel position="stacked" color="primary" className="form-label">{t('name')}</IonLabel>
                            <IonInput type="text" value={this.state.deviceName} className="form-input" onIonInput={e => this.setState({deviceName: e.detail.value})}/>
                        </IonItem>

                        <IonItem lines="full" className="form-list__item">
                            <IonRow style={{ width: '100%'}}>
                            <IonCol size="12" style={{ padding: '0' }}>
                                <IonLabel position="stacked" color="primary" className="form-label">{t('rated_power')}</IonLabel>
                            </IonCol>
                            <IonCol size="12" style={{ padding: '0' }}>

                                <IonSelect 
                                    placeholder={t('select_rated_power')} 
                                    value={String(this.state.devicePower)} 
                                    onIonChange={e => this.setState({devicePower: e.detail.value})}
                                    cancel-text={t('cancel')}
                                >
                                    <IonSelectOption value="2000">2000 {t('power_unit')}</IonSelectOption>
                                    <IonSelectOption value="3000">3000 {t('power_unit')}</IonSelectOption>
                                </IonSelect>
                            </IonCol>
                            </IonRow>
                        </IonItem>

                        <IonItem lines="full" className="form-list__item">
                            <IonRow style={{ width: '100%'}}>
                            <IonCol size="12" style={{ padding: '0' }}>
                                <IonLabel position="stacked" color="primary" className="form-label">{t('rated_volume')}</IonLabel>
                            </IonCol>
                            <IonCol size="12" style={{ padding: '0' }}>

                                <IonSelect 
                                    placeholder={t('select_rated_volume')} 
                                    value={String(this.state.deviceVolume)} 
                                    onIonChange={e => this.setState({deviceVolume: e.detail.value})}
                                    cancel-text={t('cancel')}
                                >
                                    <IonSelectOption value="50">50 {t('hp_calc_model_size_unit')}</IonSelectOption>
                                    <IonSelectOption value="80">80 {t('hp_calc_model_size_unit')}</IonSelectOption>
                                    <IonSelectOption value="100">100 {t('hp_calc_model_size_unit')}</IonSelectOption>
                                </IonSelect>
                            </IonCol>
                            </IonRow>
                        </IonItem>

                    </IonList>

                    <ToastMessage />
                    <Loading show={this.state.showLoading}/>
                </IonContent>
                
                <IonFooter className="app-footer ion-no-border">
                    <IonToolbar>
                    <div className="buttons-list">
                        <div className="buttons-list__item">
                        <IonButton expand="block" shape="round" size="large" onClick={this.continue}>{t('continue')}</IonButton>
                        </div>
                    </div>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        );
    }
};

const mapStateToProps = state => {
    return {
        newDevice: state.newDevice
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        setDeviceName,
        setDevicePower,
        deleteNewDevice,
        setAppMessage,
        setDeviceVolume,
        logger,
        setDeviceMacAddress,
        setDeviceModel,
        setDeviceApPassword
    })
)(NameDevice);
  