import {
    IonContent,
    IonFooter,
    IonButton,
    IonPage,
    IonToolbar,
    IonText,
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';

import StandartHeader from '../../components/header/StandartHeader';
import B15_1 from '../../../../files/B15_1.svg';
import B15_2 from '../../../../files/B15_2.svg';
import B15_3 from '../../../../files/B15_3.svg';
import B15_4 from '../../../../files/B15_4_new.svg';

class B15 extends React.Component {


    render = () => {
        const { t } = this.props;

        return (
            <IonPage>        
                
                <StandartHeader name={t('Boiler B15')} />

                <IonContent className="ion-padding">

                    <div className="text">
                        <div style={{ margin: '16px 0 32px', textAlign: 'center' }}>
                            <img src={B15_1} />
                        </div>
                        <p>1. {t('b15_text1')} <svg className="size-16 fill-green"><use xlinkHref="#icon-icons-16-px-16-001"></use></svg> {t('b15_text2')}</p>
                        {/* <p>2. {t('b15_text3')}</p> */}
                        {/* <div style={{ margin: '32px 0 16px', textAlign: 'center' }}>
                            <img src={B15_2} />
                        </div> */}
                        <p>2. {t('b15_text4')}</p>
                        <div style={{ margin: '32px 0 16px', textAlign: 'center' }}>
                            <img src={B15_4} />
                        </div>
                        <p>3. {t('b15_text5')}</p>
                        <p>&nbsp;</p>
                        <p><a href="">{t('b15_text6')}</a></p>
                        <p>{t('b15_text7')}</p>
                    </div>

                </IonContent>
                
                <IonFooter className="app-footer ion-no-border">
                    <IonToolbar>
                        <div className="buttons-list">
                            <div className="buttons-list__item">
                                <IonButton expand="block" shape="round" size="large" routerLink={`/connect/b15/choose-device`}>{t('continue')}</IonButton>
                            </div>
                        </div>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        );
    }
    
};

export default withTranslation()(B15);
  