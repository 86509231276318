import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

import translationEN from './i18n/en.json';
import translationBG from './i18n/bg.json';
import translationRO from './i18n/ro.json';
import translationHR from './i18n/hr.json';
import translationGR from './i18n/gr.json';
import translationRS from './i18n/rs.json';

import translationCZ from './i18n/cz.json';
import translationDE from './i18n/de.json';
import translationES from './i18n/es.json';
import translationET from './i18n/et.json';
import translationFR from './i18n/fr.json';
import translationLT from './i18n/lt.json';
import translationLV from './i18n/lv.json';
import translationMK from './i18n/mk.json';
import translationNL from './i18n/nl.json';
import translationPL from './i18n/pl.json';
import translationRU from './i18n/ru.json';
import translationSK from './i18n/sk.json';
import translationUA from './i18n/ua.json';
import translationHU from './i18n/hu.json';
import translationSL from './i18n/sl.json';
import translationIT from './i18n/it.json';
import translationHE from './i18n/he.json';

import LocalStorageHelper from '../helpers/LocalStorageHelper';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  bg: {
    translation: translationBG
  },
  ro: {
    translation: translationRO
  },
  hr: {
    translation: translationHR
  },
  rs: {
    translation: translationRS
  },
  gr: {
    translation: translationGR
  },
  cz: {
    translation: translationCZ
  }
  ,
  de: {
    translation: translationDE
  },
  es: {
    translation: translationES
  },
  et: {
    translation: translationET
  },
  fr: {
    translation: translationFR
  },
  lt: {
    translation: translationLT
  },
  lv: {
    translation: translationLV
  },
  mk: {
    translation: translationMK
  },
  nl: {
    translation: translationNL
  },
  pl: {
    translation: translationPL
  },
  ru: {
    translation: translationRU
  },
  sk: {
    translation: translationSK
  },
  ua: {
    translation: translationUA
  },
  hu: {
    translation: translationHU
  },
  sl: {
    translation: translationSL
  },
  it: {
    translation: translationIT
  },
  he: {
    translation: translationHE
  }
};

//const lang = LocalStorageHelper.get('user.lang');

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    //lng: (lang !== undefined && lang !== null) ? lang : "en",
    lng:"en",
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;