import React from 'react';
import { useTranslation } from 'react-i18next';
import HeatPumpHelper from "../../../../helpers/HeatPumpHelper";

const ProgramModeBody = ({disableDevice, device}) => {

    const { t } = useTranslation();
    const HeatPumpHelperOBJ = new HeatPumpHelper(device);

    let current = null;
    let next = null;

    if (device.state.program && device.state.program_status == "on") {
        
        current = HeatPumpHelperOBJ.getProgramCurrentPeriod();
        next = HeatPumpHelperOBJ.getProgramNextPeriod();
        global.debug(next)
    }

    const renderCurrentPeriod = () => {
        if (current) {
            return <div className="device-period__item active">
                        <div className="device-period__title">{t('hp_program_current_period')}</div>
                        <div className="device-period__box">
                            <div className="device-period__box-item">
                                <div className="device-period__box-title">{t('hp_program_period')}</div>
                                <div className="device-period__box-value">{current.from} - {current.to}</div>
                            </div>
                            <div className="device-period__box-item">
                                <div className="device-period__box-title">{t('hp_program_mode')}</div>
                                <div className="device-period__box-value">{t(`hp_mode_${current.mode}`)}</div>
                            </div>
                            <div className="device-period__box-item">
                                <div className="device-period__box-title">{t('hp_program_set_temp')}</div>
                                <div className="device-period__box-value">{current.temp}° C</div>
                            </div>
                        </div>
                    </div>
        }
    }

    const renderNextPeriod = () => {
        if (next) {
            return <div className="device-period__item">
                        <div className="device-period__title">{t('hp_program_next_period')}</div>
                        <div className="device-period__box">
                            <div className="device-period__box-item">
                                <div className="device-period__box-title">{t('hp_program_period')}</div>
                                <div className="device-period__box-value">{next.from} - {next.to}</div>
                            </div>
                            <div className="device-period__box-item">
                                <div className="device-period__box-title">{t('hp_program_mode')}</div>
                                <div className="device-period__box-value">{t(`hp_mode_${next.mode}`)}</div>
                            </div>
                            <div className="device-period__box-item">
                                <div className="device-period__box-title">{t('hp_program_set_temp')}</div>
                                <div className="device-period__box-value">{next.temp}° C</div>
                            </div>
                        </div>
                    </div>
        }
    }

    return (
        <React.Fragment>
            {(device.state.program_status == "on" &&
                <div className="device-period" disabled={disableDevice ? true : false}>
                    {renderCurrentPeriod()}    
                    {renderNextPeriod()}    
                </div>
            )}
        </React.Fragment>
    )
}

export default ProgramModeBody;