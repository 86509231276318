import {
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonInput,
    IonLabel,
    IonButton,
} from '@ionic/react';

import React  from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import Header from './components/header/Header';
import PageHeader from './components/header/PageHeader';
import PageSubHeader from './components/header/PageSubHeader';
import Footer from './components/footer/Footer';
import FooterTwoButtons from './components/footer/FooterTwoButtons';
import DeviceCN05UVMenu from './components/menu/DeviceCN05UVMenu';
import ToastMessage from './components/toast/ToastMessage';

import { setAppMessage } from '../../redux/actions/AppAction';

import RestHelper from '../../helpers/RestHelper';
import InitialSetttings from '../../init';
import {detectDeviceType} from '../../helpers/FunctionsHelper';
import HeatPumpMenu from './HPWH4_11_260/components/HeatPumpMenu';
import E32Menu from './E32/components/E32Menu';
import B15Menu from './B15/components/Menu';

class NewGroup extends React.Component {

    constructor(props) {
        super(props);

        this.RestHelper = null;
        this.InitialSetttings = null;

        this.state = {
            name: ''
        }
    }


    componentDidMount = () => {
        this.RestHelper = new RestHelper();
        this.InitialSetttings = new InitialSetttings();
    }
    

    saveGroup = async () => {
        if (this.state.name !== '') {

            await this.RestHelper.post('/create-group', {
                name: this.state.name
            });

            await this.InitialSetttings.getMyGroups();

            this.props.history.goBack()

        } else {
            this.props.setAppMessage(this.props.t('required_name'));
        }
    }

    renderMenu = () => {

        const {model} = this.props.devices[this.props.match.params.mac_address];
        const deviceType = detectDeviceType(model);

        switch(deviceType) {
            case 'HeatPump': 
                return <HeatPumpMenu activeMenu="deviceGroup" mac={this.props.match.params.mac_address}/>;
            case 'Boiler_E32': 
                return <E32Menu activeMenu="deviceGroup" mac={this.props.match.params.mac_address}/>;
            case 'Boiler_B15': 
                return <B15Menu activeMenu="deviceGroup" mac={this.props.match.params.mac_address}/>;
            default:
                return <DeviceCN05UVMenu activeMenu="deviceGroup"  mac={this.props.match.params.mac_address}/>;
        }
    }

    render = () => {

        const { t } = this.props;

        return (
            <IonPage>
                <Header />
                <IonContent className="ion-no-padding">
                    <div className="dashboard">
                        <div className="dashboard__wrapper">
                            
                            {this.renderMenu()}

                            <div className="dashboard__content">

                                <PageHeader name={t('add_to_group')} image="add-to-folder"/>

                                <div className="dashboard-box">

                                    <PageSubHeader title={t('create_group')}/>

                                    <div className="dashboard-box__body">
                                        <IonList style={{ maxWidth: '400px', margin: '0 auto' }}>
                                            <IonItem lines="none" className="form-list__item">
                                                <IonLabel position="stacked" color="primary" className="form-label">{t('group_name')}</IonLabel>
                                                <IonInput type="text" className="form-input" onIonInput={ e => this.setState({name: e.detail.value})}/>
                                            </IonItem>
                                            <IonItem lines="none" className="form-list__item pt-20">
                                                <FooterTwoButtons save={this.saveGroup} />      
                                            </IonItem>
                                        </IonList>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastMessage />
                </IonContent>
                <Footer />
            </IonPage>
        );
    }

    
};


const mapStateToProps = state => {

    return {
        devices: state.devices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        setAppMessage
    })
)(NewGroup); 