import {
    IonContent,
    IonFooter,
    IonButton,
    IonPage,
    IonToolbar,
    IonList,
    IonItem,
    IonRadioGroup,
    IonLabel,
    IonRadio,
  } from '@ionic/react';
  
import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';

import {
    chooseNetworkOriginal
} from '../../../../redux/actions/NewDeviceAction';
import { setAppMessage } from '../../../../redux/actions/AppAction';

import StandartHeader from '../../components/header/StandartHeader';
import ToastMessage from '../../components/toast/ToastMessage';
import WifiHelper from '../../../../helpers/WifiHelper';

class ChangeWiFi extends React.Component {
    
    state = {
        networks: [],
        myNetwork: ""
    }

    componentDidMount = () => {

        this.setState({
            myNetwork: this.props.newDevice.networkSSID
        })
    }

    confirm = () => {
        
        if (this.state.myNetwork !== '') {

            this.props.chooseNetworkOriginal(this.state.myNetwork);
            this.props.history.push("/connect/b15/wifi-password");

        } else {
            this.props.setAppMessage(this.props.t('required_network'));
        }
        
    }

    renderWifiNetworks = networks => {
        if (networks === 0) {
            return null;
        }

        let ssidArray = [];

        return networks.map((SSID, i) => {

            if (!ssidArray.includes(SSID)) {

                ssidArray.push(SSID);
                return (
                    <IonItem key={i}>
                        <IonLabel>{SSID}</IonLabel>
                        <IonRadio slot="start" color="secondary" value={SSID} />
                    </IonItem>
                )
            }
        })    
    }

    render = () => {

        const { t } = this.props;
        const { ssid } = this.props.match.params;
        let { deviceWifiArray } = this.props.newDevice;
        //deviceWifiArray = deviceWifiArray.split(",");

        return (
            <IonPage>
            
            <StandartHeader name={t('change_wifi')}/>
            <IonContent className="ion-padding">
                <IonList>
                    <IonRadioGroup 
                        onIonChange={e => this.setState({myNetwork: e.detail.value})} 
                        
                    >
                        {this.renderWifiNetworks(deviceWifiArray)}
                    </IonRadioGroup>
                </IonList>

                <ToastMessage />
            </IonContent>
            
            <IonFooter className="app-footer ion-no-border">
                <IonToolbar>
                    <div className="buttons-list">
                    <div className="buttons-list__item">
                        <IonButton expand="block" shape="round" size="large" onClick={this.confirm}>{t('confirm')}</IonButton>
                    </div>
                    {/* TO DO: Ask for refresh button */}
                    {/* TO DO: Ask for this check. You have this button only when you come from changing wifi */}
                    {this.props.match.params.ssid === undefined ? null :
                        <div className="buttons-list__item">
                            <IonButton expand="block" shape="round" size="large" color="help" routerLink="/connect-to-wifi">{t('cancel')}</IonButton>
                        </div>
                    }
                    </div>
                </IonToolbar>
            </IonFooter>
            </IonPage>
        );
    }
};

const mapStateToProps = state => {
    return {
        newDevice: state.newDevice
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        chooseNetworkOriginal,
        setAppMessage
    })
)
(ChangeWiFi);
  