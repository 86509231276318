import React from 'react';
import { Route, Switch} from 'react-router-dom';
import ManagePage from './ManagePage';
import Program from './Program';
import CreateProgram from './CreateProgram';
import Vacation from './Vacation';
import DeviceInfo from './DeviceInfo';
import Calculator from './Calculator';
import CalculatorHistory from './CalculatorHistory';
import Statistics from './Statistics';
import Errors from './Errors';

import B15 from '../addDevice/B15/B15';
import ChooseDevice from '../addDevice/B15/ChooseDevice';
import ChangeWifi from '../addDevice/B15/ChangeWifi';
import WifiPassword from '../addDevice/B15/WifiPassword';
import Finish from '../addDevice/B15/Finish';
import DeviceSettings from '../addDevice/B15/DeviceSettings';

const Routes = () => {

    //MUST BE IN LOWER CASE
    const MODEL = "b15";

    return (
        <Switch>
            
            {/* MANAGE DEVICE: ROUTES*/}
            <Route exact path={`/device/${MODEL}/:mac_address`} component={ManagePage}/>
            <Route exact path={`/device/vacation/${MODEL}/:mac_address`} component={Vacation}/>
            <Route exact path={`/device/program/${MODEL}/:mac_address/:program/:day?`} component={Program}/>
            <Route exact path={`/device/program/create-program/${MODEL}/:mac_address/:day/:program`} component={CreateProgram}/>
            <Route exact path={`/device/calculator/${MODEL}/:mac_address`} component={Calculator}/>
            <Route exact path={`/device/calculator-history/${MODEL}/:mac_address`} component={CalculatorHistory}/>
            <Route exact path={`/device/statistics/${MODEL}/:mac_address`} component={Statistics}/>
            <Route exact path={`/device/device-info/${MODEL}/:mac_address`} component={DeviceInfo}/>
            <Route exact path={`/device/error/${MODEL}/:mac_address`} component={Errors}/>
             

            {/* CONNECT DEVICE: ROUTES */}
            <Route exact path={`/connect/${MODEL}`} component={B15}/>
            <Route exact path={`/connect/${MODEL}/choose-device`} component={ChooseDevice}/>
            <Route exact path={`/connect/${MODEL}/change-wifi`} component={ChangeWifi}/>
            <Route exact path={`/connect/${MODEL}/wifi-password`} component={WifiPassword}/>
            <Route exact path={`/connect/${MODEL}/device-settings`} component={DeviceSettings}/>
            <Route exact path={`/connect/${MODEL}/finish`} component={Finish}/>
        </Switch>
    );
}

export default Routes;