import {
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonButton
} from '@ionic/react';

import Offline from '../../pages/phoneApp/components/offline/Offline';
import PowerButton from '../../pages/phoneApp/HPWH4_11_260/components/PowerButton'; 

import DeviceSettingsHelper from "../DeviceSettingsHelper";
import HeatPumpMqttHelper from "../mqqt/HeatPumpMqttHelper";
import E32MqttHelper from "../mqqt/E32MqttHelper"
import HeatPumpHelper from "../HeatPumpHelper";
import E32Helper from "../E32Helper";
import {E32_Settings} from "../../config/E32";
import {B15_Settings} from "../../config/B15";
import error from '../../assets/images/error.svg';
import B15MqttHelper from '../mqqt/B15MqttHelper';

class DashBoardRenderDevicesHelper
{
    constructor(device, mac_address, t) {

        this.device = device;
        this.mac_address = mac_address;
        this.t = t;
    }

    renderB15 = () => {
        const device = this.device;
        const mac_address = this.mac_address;
        const t = this.t;
        //const E32HelperOBJ = new E32Helper(device);
        const B15MqttHelperOBJ = new B15MqttHelper(mac_address);

        let is_active = "on";
        if (device.state.stand_by === 'yes') {
            is_active = "off";
        }

        //BY default color is green
        let device_status_mode_class = '';
        let device_status_mode_icon_class = '';
        let device_progress_class = '';

        if (is_active === 'off') {
            device_status_mode_class = 'device-status__mode--gray';
            device_status_mode_icon_class = 'device-status__mode-icon--gray';
            device_progress_class = 'device-progress--gray';
        }

        if (is_active === 'on' && device.state.heater_state === 'on') {
            device_status_mode_class = 'device-status__mode--red';
            device_status_mode_icon_class = 'device-status__mode-icon--red';
            device_progress_class = 'device-progress--red';  
        }

        if (device.hasInternet === false) {
            return this.offline();
        } else if (device.state.errorInd == "yes") {
            return this.error();

        } 

        return (
            <div className="card-list__item" key={mac_address}>
                <IonCard>
                    <IonCardHeader className="card-list__header">
                    <div className="card-list__header-left">
                        <div>
                            <img src={device.model_image_main} alt="" />
                        </div>
                        <div>
                            <div className="card-list__title">{device.deviceName}</div>
                            <div className="card-list__sub-title">{t(device.model_type)}</div>
                            {is_active === 'on'?

                                (device.state.mode == B15_Settings.modes.vacation) ?

                                    <div className="card-list__label">
                                        <svg className="shower size-20"><use xlinkHref="#icon-palm"></use></svg>
                                    </div>
                                :

                                (device.state.boost == "on") ?

                                        <div className="card-list__label">
                                            {t('boost')}
                                        </div>
                                    :
                                        <div className="card-list__label">{t('ewh_target_temp')} {device.state.set_temp}°C </div>
        
                                :
                                null
                            }
                        </div>
                    </div>
                    <div className="card-list__header-right">
                        <IonButton color="transparent" className="card-list__header-button" routerLink={`/device/${device.model.toLowerCase()}/${mac_address}`}>
                            <svg className="card-list__header-button-icon"><use xlinkHref="#icon-arrow-next"></use></svg>
                        </IonButton>
                    </div>
                    </IonCardHeader>
                    <IonCardContent className="card-list__content">
                    <div className="device-box">
                        <div className="device-box__left">
                            <div className={`device-progress ${device_progress_class}`}>
                                {(device.state.mode == B15_Settings.modes.vacation) ?
                            
                                    <div className="device-progress__showers">
                                        <svg className={`shower size-20`}><use xlinkHref="#icon-palm"></use></svg>      
                                    </div>
                                :
                                    (device.state.boost == "on") ?
                                        null
                                    :
                                        <div className="device-progress__temperature">
                                            {/* {this.render2002ShowersProgress(status, model)} */}
                                            {device.state.current_temp}°C
                                        </div>
                                }

                                {(device.state.mode != B15_Settings.modes.vacation && device.state.boost == "off") && 
                                    <div>{t('еwh_current_temp')}</div>
                                }

                                {device.state.boost == "on" && 
                                    <div>{t('boost')}</div>
                                }
                                <IonButton  
                                    color="transparent" 
                                    onClick={() => B15MqttHelperOBJ.request("setStandby", {setState: is_active == 'on' ? 'yes' : 'no'})} 
                                    className={`device-progress__button button-switch ${is_active == 'on' ? 'button-switch--on' : 'button-switch--off'}`}
                                >
                                <svg className="button-switch__icon"><use xlinkHref="#icon-switch"></use></svg>
                                </IonButton>
                            </div>
                        </div>
                        <div className="device-box__right">
                            <div className="device-statis">
                                <div className="device-status__item">
                                    <div className="device-status__title">{t('device')}</div>
                                    <div className={`device-status__mode ${is_active == "off" ? "device-status__mode--red" : ""}`}>
                                        <svg className={`device-status__mode-icon ${is_active == "off" ? "device-status__mode-icon--red" : ""}`}>
                                            <use xlinkHref="#icon-switch"></use>
                                        </svg> 
                                        {is_active === 'off' ? t('ewh_inactive_status') : t('ewh_active_status')}
                                    </div>
                                </div>
                                <div className="device-status__item">
                                    <div className={`device-status__title ${is_active == "off" ? "device-status__title--gray": ""}`}>{t('current_mode')}</div>
                                    <div className={`device-status__mode ${is_active == "off" ? "device-status__mode--gray" : ""}`}>
                                        <svg className={`device-status__mode-icon ${is_active == "off" ? "device-status__mode-icon--gray": ""}`}>
                                            <use xlinkHref="#icon-mode"></use>
                                        </svg> 
                                        {(device.state.boost == "on") ?
                                            t('boost')
                                        :
                                            t(`${B15_Settings.match_modes_to_tesycloud[device.state.mode]}_dashboard`)
                                        }
                                    </div>
                                </div>
                                {/* TO DO: When is heating and when is ready? */}
                                <div className="device-status__item">
                                    <div className={`device-status__title ${is_active == "off" ? "device-status__title--gray": ""}`}>{t('status')}</div>
                                    <div className={`device-status__mode ${device_status_mode_class}`}>
                                        <svg className={`device-status__mode-icon ${device_status_mode_icon_class}`}>
                                            <use xlinkHref="#icon-idle"></use>
                                        </svg> 
                                        {device.state.heater_state == "on" ? t('ewh_heating') : t('ewh_ready')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </IonCardContent>
                </IonCard>
            </div>
        )
    }


    renderE32 = () => {
        const device = this.device;
        const mac_address = this.mac_address;
        const t = this.t;
        const E32HelperOBJ = new E32Helper(device);
        const E32MqttHelperOBJ = new E32MqttHelper(mac_address);

        let is_active = "on";
        if (device.state.stand_by === 'yes') {
            is_active = "off";
        }

        //BY default color is green
        let device_status_mode_class = '';
        let device_status_mode_icon_class = '';
        let device_progress_class = '';

        if (is_active === 'off') {
            device_status_mode_class = 'device-status__mode--gray';
            device_status_mode_icon_class = 'device-status__mode-icon--gray';
            device_progress_class = 'device-progress--gray';
        }

        if (is_active === 'on' && device.state.heater_state === 'on') {
            device_status_mode_class = 'device-status__mode--red';
            device_status_mode_icon_class = 'device-status__mode-icon--red';
            device_progress_class = 'device-progress--red';  
        }

        if (device.hasInternet === false) {
            return this.offline(E32HelperOBJ.getImage());
        } else if (device.state.errorInd == "yes") {
            return this.error(E32HelperOBJ.getImage());

        } 

        return (
            <div className="card-list__item" key={mac_address}>
                <IonCard>
                    <IonCardHeader className="card-list__header">
                    <div className="card-list__header-left">
                        <div>
                            <img src={E32HelperOBJ.getImage()} alt="" />
                        </div>
                        <div>
                            <div className="card-list__title">{device.deviceName}</div>
                            <div className="card-list__sub-title">{t(device.model_type)}</div>
                            {is_active === 'on'?

                                (device.state.mode == E32_Settings.modes.vacation) ?

                                    <div className="card-list__label">
                                        <svg className="shower size-20"><use xlinkHref="#icon-palm"></use></svg>
                                    </div>
                                :

                                (device.state.boost == "on") ?

                                        <div className="card-list__label">
                                            {t('boost')}
                                        </div>
                                    :
                                        <div className="card-list__label">
                                            {t('ewh_target_temp')} 
                                            {E32HelperOBJ.renderShowers()}
                                        </div>
        
                                :
                                null
                            }
                        </div>
                    </div>
                    <div className="card-list__header-right">
                        <IonButton color="transparent" className="card-list__header-button" routerLink={`/device/${device.model.toLowerCase()}/${mac_address}`}>
                            <svg className="card-list__header-button-icon"><use xlinkHref="#icon-arrow-next"></use></svg>
                        </IonButton>
                    </div>
                    </IonCardHeader>
                    <IonCardContent className="card-list__content">
                    <div className="device-box">
                        <div className="device-box__left">
                            <div className={`device-progress ${device_progress_class}`}>
                                {(device.state.mode == E32_Settings.modes.vacation) ?
                            
                                    <div className="device-progress__showers">
                                        <svg className={`shower size-20`}><use xlinkHref="#icon-palm"></use></svg>      
                                    </div>
                                :
                                    (device.state.boost == "on") ?
                                        null
                                    :
                                        <div className="device-progress__showers">
                                            {/* {this.render2002ShowersProgress(status, model)} */}
                                            {E32HelperOBJ.renderShowersProgress()}
                                        </div>
                                }

                                {(device.state.mode != E32_Settings.modes.vacation && device.state.boost == "off") && 
                                    <div>{t('еwh_current_temp')}</div>
                                }

                                {device.state.boost == "on" && 
                                    <div>{t('boost')}</div>
                                }
                                <IonButton  
                                    color="transparent" 
                                    onClick={() => E32MqttHelperOBJ.request("setStandby", {setState: is_active == 'on' ? 'yes' : 'no'})} 
                                    className={`device-progress__button button-switch ${is_active == 'on' ? 'button-switch--on' : 'button-switch--off'}`}
                                >
                                <svg className="button-switch__icon"><use xlinkHref="#icon-switch"></use></svg>
                                </IonButton>
                            </div>
                        </div>
                        <div className="device-box__right">
                            <div className="device-statis">
                                <div className="device-status__item">
                                    <div className="device-status__title">{t('device')}</div>
                                    <div className={`device-status__mode ${is_active == "off" ? "device-status__mode--red" : ""}`}>
                                        <svg className={`device-status__mode-icon ${is_active == "off" ? "device-status__mode-icon--red" : ""}`}>
                                            <use xlinkHref="#icon-switch"></use>
                                        </svg> 
                                        {is_active === 'off' ? t('ewh_inactive_status') : t('ewh_active_status')}
                                    </div>
                                </div>
                                <div className="device-status__item">
                                    <div className={`device-status__title ${is_active == "off" ? "device-status__title--gray": ""}`}>{t('current_mode')}</div>
                                    <div className={`device-status__mode ${is_active == "off" ? "device-status__mode--gray" : ""}`}>
                                        <svg className={`device-status__mode-icon ${is_active == "off" ? "device-status__mode-icon--gray": ""}`}>
                                            <use xlinkHref="#icon-mode"></use>
                                        </svg> 
                                        {(device.state.boost == "on") ?
                                            t('boost')
                                        :
                                            t(`${E32_Settings.match_modes_to_tesycloud[device.state.mode]}_dashboard`)
                                        }
                                    </div>
                                </div>
                                {/* TO DO: When is heating and when is ready? */}
                                <div className="device-status__item">
                                    <div className={`device-status__title ${is_active == "off" ? "device-status__title--gray": ""}`}>{t('status')}</div>
                                    <div className={`device-status__mode ${device_status_mode_class}`}>
                                        <svg className={`device-status__mode-icon ${device_status_mode_icon_class}`}>
                                            <use xlinkHref="#icon-idle"></use>
                                        </svg> 
                                        {device.state.heater_state == "on" ? t('ewh_heating') : t('ewh_ready')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </IonCardContent>
                </IonCard>
            </div>
        )
    }

    renderCN05UV = () => {

        const device = this.device;
        const mac_address = this.mac_address;
        const t = this.t;

        if (device.hasInternet === true) {

            const modelHelper = new DeviceSettingsHelper(mac_address);

            //BY default color is green
            let device_status_mode_class = '';
            let device_status_mode_icon_class = '';
            let device_progress_class = '';

            if (device.state.status === 'off' || (device.state.heating === 'off' && device.state.target === 0 && device.state.mode === 'program' && device.state.timeRemaining === 0)) {
                device_status_mode_class = 'device-status__mode--gray';
                device_status_mode_icon_class = 'device-status__mode-icon--gray';
                device_progress_class = 'device-progress--gray';
            }

            if (device.state.status === 'on' && device.state.heating === 'on') {
                device_status_mode_class = 'device-status__mode--red';
                device_status_mode_icon_class = 'device-status__mode-icon--red';
                device_progress_class = 'device-progress--red';  
            }

            return (
                <div className="card-list__item" key={mac_address}>
                    <IonCard>
                        <IonCardHeader className="card-list__header">
                            <div className="card-list__header-left">
                                <div>
                                    <img src={device.model_image_main} alt="" />
                                </div>
                                <div>
                                    <div className="card-list__title">{device.deviceName}</div>
                                    <div className="card-list__sub-title">{t(device.model_type)}</div>
                                    {device.state.status === 'on' && 
                                        <div className="card-list__label">{t('target_temp')} {device.state.target > 0 ? device.state.target : device.state.temp}°C</div>
                                    }
                                </div>
                            </div>
                            <div className="card-list__header-right">
                                <IonButton color="transparent" className="card-list__header-button" routerLink={`/device/${device.model.toLowerCase()}/${mac_address}`}>
                                    <svg className="card-list__header-button-icon"><use xlinkHref="#icon-arrow-next"></use></svg>
                                </IonButton>
                            </div>
                        </IonCardHeader>
                        <IonCardContent className="card-list__content">
                            <div className="device-box">
                                <div className="device-box__left">
                                    <div className={`device-progress ${device_progress_class}`}>
                                        <div className="device-progress__temperature">{device.state.current_temp ? device.state.current_temp : '-'}°C</div>
                                        <div>{t('current_temp')}</div>
                                        <IonButton 
                                            color="transparent" 
                                            className={`device-progress__button button-switch ${device.state.status === 'on' ? 'button-switch--on' : 'button-switch--off'}`}
                                            onClick={() => modelHelper.handleOnOff(device.state.status === 'on' ? 'off' : 'on')}
                                        >
                                            <svg className="button-switch__icon"><use xlinkHref="#icon-switch"></use></svg>
                                        </IonButton>
                                    </div>
                                </div>
                                <div className="device-box__right">
                                    <div className="device-statis">
                                        <div className="device-status__item">
                                            <div className="device-status__title">{t('device')}</div>
                                            <div className={`device-status__mode ${device.state.status === 'off' || (device.state.heating === 'off' && device.state.target === 0 && device.state.mode === 'program' && device.state.timeRemaining === 0) ? 'device-status__mode--red' : ''}`}>
                                                <svg className={`device-status__mode-icon ${device.state.status === 'off' || (device.state.heating === 'off' && device.state.target === 0 && device.state.mode === 'program' && device.state.timeRemaining === 0) ? 'device-status__mode-icon--red' : ''}`}>
                                                    <use xlinkHref="#icon-switch"></use>
                                                </svg> 
                                                {(device.state.status === 'off' || (device.state.heating === 'off' && device.state.target === 0 && device.state.mode === 'program' && device.state.timeRemaining === 0)) ? t('inactive_status') : t('active_status')}
                                            </div>
                                        </div>
                                        <div className="device-status__item">
                                            <div className={`device-status__title ${device.state.status === 'off' ? 'device-status__title--gray' : ''}`}>{t('current_mode')}</div>
                                            <div className={`device-status__mode ${device.state.status === 'off' ? 'device-status__mode--gray' : ''}`}>
                                                <svg className={`device-status__mode-icon ${device.state.status === 'off' ? 'device-status__mode-icon--gray' : ''}`}>
                                                    <use xlinkHref="#icon-mode"></use>
                                                </svg> 
                                                {device.state.mode === 'heating' ? t('manual_dashboard') : t(`${device.state.mode}_dashboard`)}
                                            </div>
                                        </div>
                                        <div className="device-status__item">
                                            <div className={`device-status__title ${(device.state.status === 'off' || (device.state.heating === 'off' && device.state.target === 0 && device.state.mode === 'program' && device.state.timeRemaining === 0)) ? 'device-status__title--gray' : ''}`}>{t('status')}</div>
                                            <div className={`device-status__mode ${device_status_mode_class}`}>
                                                <svg className={`device-status__mode-icon ${device_status_mode_icon_class}`}>
                                                    <use xlinkHref="#icon-idle"></use>
                                                </svg> 
                                                {device.state.heating === 'on' ? t('heating') : t('ready')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </IonCardContent>
                    </IonCard>
                </div>
            );
        } 

        return this.offline();
        
    }

    renderHPWH4_11_260 = () => {

        const device = this.device;
        const mac_address = this.mac_address;
        const t = this.t;
        const HeatPumpHelperOBJ = new HeatPumpHelper(device);
        const HeatPumpMqttHelperOBJ = new HeatPumpMqttHelper(mac_address);

        let is_active = "on";
        if (device.state.power_status === 'off') {
            is_active = "off";
        }

        //BY default color is green
        let device_status_mode_class = '';
        let device_status_mode_icon_class = '';
        let device_progress_class = '';

        if (is_active === 'off') {
            device_status_mode_class = 'device-status__mode--gray';
            device_status_mode_icon_class = 'device-status__mode-icon--gray';
            device_progress_class = 'device-progress--gray';
        }

        if (is_active === 'on' && device.state.heating === 'on') {
            device_status_mode_class = 'device-status__mode--red';
            device_status_mode_icon_class = 'device-status__mode-icon--red';
            device_progress_class = 'device-progress--red';  
        }

        if (device.hasInternet === true) {

            return (
                <div className="card-list__item" key={mac_address}>
                    <IonCard>
                        <IonCardHeader className="card-list__header">
                        <div className="card-list__header-left">
                            <div>
                                <img src={device.model_image_main} alt="" />
                            </div>
                            <div>
                                <div className="card-list__title">{device.deviceName}</div>
                                <div className="card-list__sub-title">{t(device.model_type)}</div>
                                {is_active === 'on' && 
                                    <div className="card-list__label">{t('hp_target_temp')} {device.state.temp}°C</div>
                                }
                            </div>
                        </div>
                        <div className="card-list__header-right">
                            <IonButton color="transparent" className="card-list__header-button" routerLink={`/device/${device.model.toLowerCase()}/${mac_address}`}>
                                <svg className="card-list__header-button-icon"><use xlinkHref="#icon-arrow-next"></use></svg>
                            </IonButton>
                        </div>
                        </IonCardHeader>
                        <IonCardContent className="card-list__content">
                        <div className="device-box">
                            <div className="device-box__left">
                                <div className={`device-progress ${device_progress_class}`}>
                                    <div className="device-progress__temperature">{device.state.current_temp ? device.state.current_temp : '-'}°C</div>
                                    <div>{t('hp_current_temp')}</div>
                                    <PowerButton mac={mac_address} power={device.state.power_status} class="device-progress__button"/>
                                </div>
                            </div>
                            <div className="device-box__right">
                                <div className="device-statis">
                                    <div className="device-status__item">
                                        <div className="device-status__title">{t('hp_device')}</div>
                                        <div className={`device-status__mode ${is_active == "off" ? "device-status__mode--red" : ""}`}>
                                            <svg className={`device-status__mode-icon ${is_active == "off" ? "device-status__mode-icon--red" : ""}`}>
                                                <use xlinkHref="#icon-switch"></use>
                                            </svg> 
                                            {is_active === 'off' ? t('hp_inactive_status') : t('hp_active_status')}
                                        </div>
                                    </div>
                                    <div className="device-status__item">
                                        <div className={`device-status__title ${is_active == "off" ? "device-status__title--gray": ""}`}>{t('hp_current_mode')}</div>
                                        <div className={`device-status__mode ${is_active == "off" ? "device-status__mode--gray" : ""}`}>
                                            <svg className={`device-status__mode-icon ${is_active == "off" ? "device-status__mode-icon--gray": ""}`}>
                                                <use xlinkHref={`#${HeatPumpHelperOBJ.getModeIcon()}`}></use>
                                            </svg> 
                                            {t(`hp_dashboard_mode_${HeatPumpHelperOBJ.getMode()}`)}
                                        </div>
                                    </div>
                                    {/* TO DO: When is heating and when is ready? */}
                                    <div className="device-status__item">
                                        <div className={`device-status__title ${is_active == "off" ? "device-status__title--gray": ""}`}>{t('hp_status')}</div>
                                        <div className={`device-status__mode ${device_status_mode_class}`}>
                                            <svg className={`device-status__mode-icon ${device_status_mode_icon_class}`}>
                                                <use xlinkHref="#icon-idle"></use>
                                            </svg> 
                                            {device.state.heating == "on" ? t('heating') : t('ready')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </IonCardContent>
                    </IonCard>
                </div>
            )
        }

        return this.offline();
    }

    error = (image) => {
        return (
            <div className="card-list__item" key={this.mac_address}>
                <IonCard>
                    <IonCardHeader className="card-list__header">
                        <div className="card-list__header-left">
                        <div>
                            <img src={image ?? this.device.model_image_main} alt="" />
                        </div>
                            <div>
                                <div className="card-list__title">{this.device.deviceName}</div>
                                <div className="card-list__sub-title">{this.device.model_type}</div>
                            </div>
                        </div>
                        <div className="card-list__header-right">
                            <IonButton color="transparent" className="card-list__header-button" routerLink = {`/device/error/${this.device.model.toLowerCase()}/${this.mac_address}`}>
                                <svg className="card-list__header-button-icon"><use xlinkHref="#icon-arrow-next"></use></svg>
                            </IonButton>
                        </div>
                    </IonCardHeader>
                    <IonCardContent className="card-list__content">
                        <div className="device-error">
                        <div className="device-error__icon">
                            <img src={error} alt="" />
                        </div>
                        <div className="device-error__text">
                            <a href="" className="device-error__link">{this.t('error')}</a>
                        </div>
                        </div>
                    </IonCardContent>
                </IonCard>
            </div>
        )
    }

    offline = (image) => {

        return (
            <div className="card-list__item" key={this.mac_address}>
                <IonCard>
                    <IonCardHeader className="card-list__header">
                    <div className="card-list__header-left">
                        <div>
                            <img src={image ?? this.device.model_image_main} alt="" />
                        </div>
                        <div>
                            <div className="card-list__title">{this.device.deviceName}</div>
                            <div className="card-list__sub-title">{this.device.model_type}</div>
                        </div>
                    </div>
                    <div className="card-list__header-right">
                        <IonButton color="transparent" className="card-list__header-button" routerLink={`/device/${this.device.model.toLowerCase()}/${this.mac_address}`}>
                            <svg className="card-list__header-button-icon">
                                <use xlinkHref="#icon-arrow-next"></use>
                            </svg>
                        </IonButton>
                    </div>
                    </IonCardHeader>
                    <IonCardContent className="card-list__content">
                        <Offline waitingForConnection={this.device.waitingForConnection} localControl={this.device.localUsage}/>
                    </IonCardContent>
                </IonCard>
            </div>
        ); 
    }
}

export default DashBoardRenderDevicesHelper;