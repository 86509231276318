

import {
    IonHeader,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import React from 'react';

const DashBoardheader = props => {
    return (
        <IonHeader className="app-header">
            <IonToolbar className="ion-no-padding">
                <IonTitle className="ion-title--lg">{props.name}</IonTitle>
            </IonToolbar>
        </IonHeader>
    )
}

export default DashBoardheader;