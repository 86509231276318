import basicModel from '../basicModel';
import {
    updateDeviceState,
} from '../../redux/actions/DevicesAction';

class HPWH4_11_BASIC extends basicModel {

    constructor(deviceData) {

        super(deviceData);

        this.table_fields = {
            id: "id",
            dateUpdate: "updated_at",
            dateEnter: "created_at",
            macAddr: "mac",
            powerStatus: "power_status",
            mode: "mode",
            temp: "temp", //Set TEMP
            currentTemp: "current_temp",
            currentMaxTemp: "current_max_temp",
            boost: "boost",
            fan: "fan",
            vacation: "vacation",
            vacDaysCn: "vacDaysCn",
            program: "program",
            program_status: "program_status", //Timer ON / OFF
            eHeaterInd: "eHeaterInd",
            disinfection: "disinfection",
            defrost: "defrost",
            connectivity: "connectivity",
            timerWeekDay: "timerWeekDay",
            timerZone: "timerZone",
            alarmAnode: "alarmAnode",
            alarmEvap: "alarmEvap",
            error: "error",
            heating: "heating",
            shower: "shower",
        }

        this.hasRequestLoader = true;
    }

    async getStatus() {
        const {payload: { 
            powerOnOff,
            currMode,
            boost,
            fan,
            eHeaterInd,
            disinfection,
            defrost,
            connectivity,
            timerOnOff,
            timerWeekDay,
            timerZone,
            vacDaysCn,
            setTemp,
            measTemp,
            currMaxTemp,
            alarmAnode,
            alarmEvap,
            error,
            heating,
            shower,
        }} = this.payload;

        this.store.dispatch(updateDeviceState(powerOnOff, this.table_fields.powerStatus, this.macAddr));
        this.store.dispatch(updateDeviceState(currMode, this.table_fields.mode, this.macAddr));
        this.store.dispatch(updateDeviceState(boost, this.table_fields.boost, this.macAddr));
        this.store.dispatch(updateDeviceState(fan, this.table_fields.fan, this.macAddr));
        this.store.dispatch(updateDeviceState(eHeaterInd, this.table_fields.eHeaterInd, this.macAddr));
        this.store.dispatch(updateDeviceState(disinfection, this.table_fields.disinfection, this.macAddr));
        this.store.dispatch(updateDeviceState(defrost, this.table_fields.defrost, this.macAddr));
        this.store.dispatch(updateDeviceState(connectivity, this.table_fields.connectivity, this.macAddr));
        this.store.dispatch(updateDeviceState(timerOnOff, this.table_fields.program_status, this.macAddr));
        this.store.dispatch(updateDeviceState(timerWeekDay, this.table_fields.timerWeekDay, this.macAddr));
        this.store.dispatch(updateDeviceState(timerZone, this.table_fields.timerZone, this.macAddr));
        this.store.dispatch(updateDeviceState(vacDaysCn, this.table_fields.vacDaysCn, this.macAddr));
        this.store.dispatch(updateDeviceState(setTemp, this.table_fields.temp, this.macAddr));
        this.store.dispatch(updateDeviceState(currMaxTemp, this.table_fields.currentMaxTemp, this.macAddr));
        this.store.dispatch(updateDeviceState(measTemp, this.table_fields.currentTemp, this.macAddr));
        this.store.dispatch(updateDeviceState(alarmAnode, this.table_fields.alarmAnode, this.macAddr));
        this.store.dispatch(updateDeviceState(alarmEvap, this.table_fields.alarmEvap, this.macAddr));
        this.store.dispatch(updateDeviceState(error, this.table_fields.error, this.macAddr));   
        this.store.dispatch(updateDeviceState(heating, this.table_fields.heating, this.macAddr));   
        this.store.dispatch(updateDeviceState(shower, this.table_fields.shower, this.macAddr));   
    }

    powerOnOff() {
        const {payload: { state }} = this.payload;
        this.store.dispatch(updateDeviceState(state, this.table_fields.powerStatus, this.macAddr));
    }

    setMode() {
        const {payload: { mode, setTemp }} = this.payload;

        this.store.dispatch(updateDeviceState(mode, this.table_fields.mode, this.macAddr));
        this.store.dispatch(updateDeviceState(setTemp, this.table_fields.temp, this.macAddr));
    }

    setBoost() {
        const {payload: { state }} = this.payload;
        global.debug(state)
        this.store.dispatch(updateDeviceState(state, this.table_fields.boost, this.macAddr));
    }

    setFan() {
        const {payload: { state }} = this.payload;

        this.store.dispatch(updateDeviceState(state, this.table_fields.fan, this.macAddr));
    }

    setVacation() {
        const {payload: { startDay, vacDays,  mode, temp}} = this.payload;

        this.store.dispatch(updateDeviceState({startDay, vacDays,  mode, temp}, this.table_fields.vacation, this.macAddr));
    }

    setWeeklyProgram() {

        const {payload: {weeklyDay}} = this.payload;

        this.store.dispatch(updateDeviceState({...weeklyDay}, this.table_fields.program, this.macAddr));
    }

    timerOnOff() {
        const {payload: { state }} = this.payload;

        this.store.dispatch(updateDeviceState(state, this.table_fields.program_status, this.macAddr));
    }

}

export default HPWH4_11_BASIC;