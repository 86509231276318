import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import { withTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import ScrollContainer from 'react-indiana-drag-scroll';

class StatisticsChart extends React.Component {

    dateFormatter = date => {
        dayjs.extend(utc);
        return dayjs(date*1000).utc().format("ddd HH:mm");
    };

    temperatureFormatter = value => {
        return value + "°C";
    };

    render = () => {

        if (this.props.data.data === undefined) {
            return null;
        } 

        const chartHeight = 400;
        const chartLineWidth = 60;
        const chartWidth = this.props.data.data.length * chartLineWidth;

        return (
            <ScrollContainer vertical={false}>
                <LineChart
                    height={chartHeight}
                    width={chartWidth}
                    data={this.props.data.data}
                    margin={{ top: 20, right: 0, left: 0, bottom: 0 }} 
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="time"
                        tickFormatter={this.dateFormatter}
                        scale="time" // може би по-правилно utc?
                        type="number"
                        //minTickGap={50}
                        ticks={this.props.data.period}
                        domain={["auto", "auto"]}
                    />
                    <YAxis domain={['dataMin-2', 'dataMax+2']} tickFormatter={this.temperatureFormatter} />
                    <Tooltip
                        formatter={this.temperatureFormatter}
                        labelFormatter={this.dateFormatter}
                    />
                    <Legend
                        payload={[
                            // може да се превежда и тва
                            {
                                value: this.props.t('current_temp'),
                                type: "line",
                                id: "real",
                                color: "#7fb439"
                            },
                            {
                                value: this.props.t('target_temp'),
                                type: "line",
                                id: "target",
                                color: "#d6bad5"
                            }
                        ]}
                        style={{padding: '20px'}}
                    />
                    <Line
                        type="monotone"
                        dataKey="real"
                        stroke="#7fb439"
                        activeDot={{ r: 4 }}
                    />
                    <Line type="monotone" dataKey="target" stroke="#d6bad5" />
                </LineChart>
            </ScrollContainer>
        )
    }
}

export default withTranslation()(StatisticsChart);