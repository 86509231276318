
import {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonInput
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Device } from '@capacitor/device';
import AppHelper from '../../../helpers/AppHelper';

import StandartHeader from '../components/header/StandartHeader';
import WaitingScreen from '../components/waitingScreen/WaitingScreen';

class DeviceInfo extends React.Component 
{
    state = {
        info: null,
        build: null,
        version: null
    }

    componentDidMount = async () => {
        const info = await Device.getInfo();
        const {build , version} = await AppHelper.getAppVersion();

        this.setState({
            info: info,
            build,
            version
        })
    }

    render = () => {

        
        if (!this.state.info) {
            return <WaitingScreen />;
        }
        
        const { t, devices, match: {params: { mac_address } } } = this.props;
        const device = devices[mac_address];

        return (
            <IonPage>
                
                <StandartHeader name={t('device_info')}/>
                <IonContent className="ion-padding"> 
                    <IonList className="notifications-list">

                        <IonItem lines="full" className="form-list__item">
                            <IonLabel position="stacked" color="primary" className="form-label">{t('mac_address')}</IonLabel>
                            <IonInput value={mac_address} readonly/>
                        </IonItem>

                        <IonItem lines="full" className="form-list__item">
                            <IonLabel position="stacked" color="primary" className="form-label">{t('firmware_version')}</IonLabel>
                            <IonInput value={device.state.fw_version} readonly/>
                        </IonItem>


                        <IonItem lines="full" className="form-list__item">
                            <IonLabel position="stacked" color="primary" className="form-label">{t('time_zone')}</IonLabel>
                            <IonInput value={device.timezone} readonly/>
                        </IonItem>

                        <IonItem lines="full" className="form-list__item">
                            <IonLabel position="stacked" color="primary" className="form-label">{t('app_version')}</IonLabel>
                            <IonInput value={`${this.state.version} (${this.state.build})`} readonly/>
                        </IonItem>

                        <IonItem lines="full" className="form-list__item">
                            <IonLabel position="stacked" color="primary" className="form-label">{t('app_platform')}</IonLabel>
                            <IonInput value={this.state.info.platform} readonly/>
                        </IonItem>

                        <IonItem lines="full" className="form-list__item">
                            <IonLabel position="stacked" color="primary" className="form-label">{t('os_version')}</IonLabel>
                            <IonInput value={this.state.info.osVersion} readonly/>
                        </IonItem>

                        <IonItem lines="full" className="form-list__item">
                            <IonLabel position="stacked" color="primary" className="form-label">{t('phone')}</IonLabel>
                            <IonInput value={`${this.state.info.manufacturer} ${this.state.info.model}`} readonly/>
                        </IonItem>
                        
                    </IonList>
                    <div className="ion-content-push"></div>
                </IonContent>
            </IonPage>
        ); 
    }
    
}

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(DeviceInfo);