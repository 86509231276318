import React from 'react';
import { useTranslation } from 'react-i18next';
import HeatPumpHelper from "../../../../helpers/HeatPumpHelper";

const VacationModeBody = ({disableDevice, device}) => {

    const { t } = useTranslation();
    const HeatPumpHelperOBJ = new HeatPumpHelper(device);

    const vacInfo = HeatPumpHelperOBJ.getVacationInfo()

    return (
        <React.Fragment>
            {HeatPumpHelperOBJ.getMode() == "va" &&
                <div className="device-period" disabled={disableDevice ? true : false}>
                    <div className="device-period__item active">
                        <div className="device-period__title">{t('hp_vacation_date_of_return')}</div>
                        <div className="device-period__box">
                            <div className="device-period__box-item">
                                <div className="device-period__box-title">{t('hp_vacation_period')}</div>
                                <div className="device-period__box-value">{vacInfo.dateOfReturn}</div>
                            </div>
                            <div className="device-period__box-item">
                                <div className="device-period__box-title">{t('hp_vacation_mode')}</div>
                                <div className="device-period__box-value">{t(`hp_mode_${vacInfo.mode}`)}</div>
                            </div>
                            <div className="device-period__box-item">
                                <div className="device-period__box-title">{t('hp_vacation_set_temp')}</div>
                                <div className="device-period__box-value">{vacInfo.temp}° C</div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default VacationModeBody;