import {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonInput,
    IonButton
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Collapsible from 'react-collapsible';

import {oldModels} from '../../../config/App'; 
import OldDevicesSettingsHelper from '../../../helpers/OldDevicesSettingsHelper'; 

import ConvectorApiV1Menu from './convectors/apiv1/ConvectorApiV1Menu';
import Header from '../components/header/Header';
import PageHeader from '../components/header/PageHeader';
import Footer from '../components/footer/Footer';
import RangeTime from '../components/range/RangeTime';

import dayjs from 'dayjs';
import Timezones from '../../../config/TimeZones.json';
import timezonePlugin from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
  
class DeviceSettings extends React.Component 
{

    constructor(props) {

        super(props);

        this.mac = null;
        this.device = null;
        this.OldDevicesSettingsHelper = null

        this.state = {
            time: 1800,
            deviceTCorrection: '',
            degree: '',
            selectedTimeZone: '',
            date: '',
            time: '',
            day: 0,
            deviceTimezone: ''
        }
    }

    componentDidMount = () => {
        this.OldDevicesSettingsHelper = new OldDevicesSettingsHelper(this.mac);  
    }

    getTCorrection = async () => {

        const {data: {tcVal}} = await this.OldDevicesSettingsHelper.setDeviceStatus('tcGet','');

        global.debug("TCorrection", tcVal);
        this.setState({
            deviceTCorrection: tcVal
        });
    }

    getTimeZone = async () => {
        const {data: {zonename}} = await this.OldDevicesSettingsHelper.setDeviceStatus('getTZ','');

        this.handleTimzoneChange(zonename);

        this.setState({
            deviceTimezone: zonename
        });
    }

    handleTimzoneChange = timezone => {

        this.setState({
            selectedTimeZone: timezone
        });

        const timeZoneObj = this.catchTimeZoneObj(timezone);
         
        this.setState({
            date: timeZoneObj.format("MMMM D, YYYY"),
            time: timeZoneObj.format("HH:mm"),
            day: timeZoneObj.weekday()
        });
    }

    catchTimeZoneObj = timezone => {

        dayjs.Ls.en.weekStart = 1;
        dayjs.extend(utc);
        dayjs.extend(timezonePlugin);
        dayjs.extend(weekday);
        dayjs.locale('en');

        const utcTime = dayjs.utc().format("YYYY-MM-DD HH:mm");
        const localTime = dayjs().format("YYYY-MM-DD HH:mm");

        const timeZoneObj = dayjs(localTime).tz(timezone);

        return timeZoneObj;
    }

    checkTabSelection = index => {

        if (index === 1) { //TCorrection Tab
            this.getTCorrection();
        }

        if (index === 2) {// Time Zone Tab
            this.getTimeZone();
        }
    }

    setTime = time => {
        let [hour, min] = time.split(":");
        hour = Number(hour);
        min = Number(min);

        this.setState({
            time: hour*60 + min
        })
    }

    saveSleepMode = () => {
        this.OldDevicesSettingsHelper.setDeviceStatus('dtime', this.state.time); 
    }

    saveDelayStartMode = () => {
        this.OldDevicesSettingsHelper.setDeviceStatus('dtime', this.state.time); 
    }

    saveTcontroller = () => {
        const degree = this.state.degree !== '' ? this.state.degree : this.state.deviceTCorrection;

        this.OldDevicesSettingsHelper.setDeviceStatus('tmpCRT', degree);
    }

    saveTimeZoneData = () => {
        this.OldDevicesSettingsHelper.setDeviceStatus('tz', this.state.selectedTimeZone );
    }

    renderTimezones = () => {
        const timezonesList = [];
        for (const zone in Timezones) {
            for (const timezone in Timezones[zone]) {
                timezonesList.push(<IonSelectOption value={timezone} key={`${zone}_${timezone}`}>{timezone}</IonSelectOption>)
            }
        }

        return timezonesList;
    }

    render = () => 
    {
        const { match: {params: {id, model_name}}, devices} = this.props;
        this.mac = id;
        this.device = devices[id];
        this.model = oldModels[model_name];
        this.degrees = this.model.tCorrection;
        const {t} = this.props;

        const { DeviceStatus: {dtime} } = this.device;

        return (
            <IonPage>
                <Header />
                <IonContent className="ion-no-padding">
                    <div className="dashboard">
                        <div className="dashboard__wrapper">
                            
                            <ConvectorApiV1Menu 
                                activeMenu="deviceSettings" 
                                model_name={this.props.match.params.model_name} 
                                device={this.props.devices[this.props.match.params.id]} 
                                model={oldModels[this.props.match.params.model_name]}
                            />

                            <div className="dashboard__content">
                            
                                <PageHeader name={t('device_settings')} image="settings"/>

                                <Tabs forceRenderTabPanel defaultIndex={0} onSelect={index => this.checkTabSelection(index)} className="react-tabs react-tabs--alt">

                                    <TabList>
                                        <Tab>{t('modes')}</Tab>
                                        <Tab>{t('t_controller')}</Tab>
                                        <Tab>{t('world_clock')}</Tab>
                                    </TabList>

                                    <div className="react-tabs__box">

                                        <TabPanel>

                                            { (model_name == '1003-v1' || model_name == '1002-v1') && 
                                                <Collapsible
                                                    trigger={`${t('mode')}: ${t('sleep')}`}
                                                    transitionTime={300}
                                                    open={true}
                                                >
                                                    <div className="preferences-list preferences-list--sm pt-32 pb-32">
                                                        <div className="preferences-list__wrapper">
                                                            <div className="ion-text-center">
                                                                <svg className="size-80 fill-light-blue">
                                                                    <use xlinkHref="#icon-moon"></use>
                                                                </svg>
                                                            </div>
                                                            <IonList className="preferences-list preferences-list--editable">
                                                                <IonItem className="preferences-list__item" lines="none">
                                                                    <div className="preferences-list__inner">
                                                                        <div className="preferences-list__item-middle">
                                                                            <RangeTime 
                                                                                data-min={this.model.min_sleep_time} 
                                                                                data-max={this.model.max_sleep_time} 
                                                                                data-start={(dtime > 0) ? dtime * 60 : this.model.min_sleep_time}  
                                                                                data-step="1800" 
                                                                                data-label={t('stop_after')} 
                                                                                setTime={time => this.setTime(time)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </IonItem>
                                                                <IonItem className="preferences-list__item" lines="none">
                                                                    <div className="ion-text-center" style={{ width: '100%' }}>
                                                                        <IonButton color="primary" onClick={this.saveSleepMode}>{t('save')}</IonButton>
                                                                    </div>
                                                                </IonItem>
                                                            </IonList>
                                                        </div>
                                                    </div>
                                                </Collapsible>
                                            }

                                            <Collapsible
                                                trigger={`${t('mode')}: ${t('delayed_start')}`}
                                                transitionTime={300}
                                                open={true}
                                            >
                                                <div className="preferences-list preferences-list--sm pt-32 pb-32">
                                                    <div className="preferences-list__wrapper">
                                                        <div className="ion-text-center">
                                                            <svg className="size-80 fill-light-purple">
                                                                <use xlinkHref="#icon-clock"></use>
                                                            </svg>
                                                        </div>
                                                        <IonList className="preferences-list preferences-list--editable">
                                                            <IonItem className="preferences-list__item" lines="none">
                                                                <div className="preferences-list__inner">
                                                                    <div className="preferences-list__item-middle">
                                                                        <RangeTime 
                                                                            data-min={this.model.min_delayed_time} 
                                                                            data-max={this.model.max_delayed_time} 
                                                                            data-start={(dtime > 0) ? dtime * 60 : this.model.min_delayed_time} 
                                                                            data-step="1800" 
                                                                            data-label={t('start_after')} 
                                                                            setTime={time => this.setTime(time)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </IonItem>
                                                            <IonItem className="preferences-list__item" lines="none">
                                                                <div className="ion-text-center" style={{ width: '100%' }}>
                                                                    <IonButton color="primary" onClick={this.saveDelayStartMode}>{t('save')}</IonButton>
                                                                </div>
                                                            </IonItem>
                                                        </IonList>
                                                    </div>
                                                </div>
                                            </Collapsible>
                                        </TabPanel>

                                        <TabPanel>
                                            <div className="gray-box">
                                                <div className="gray-box__header gray-box__header--alt">
                                                    {t('t_controller')}
                                                </div>
                                                <div className="gray-box__body gray-box__body--transparent">
                                                    <div style={{ maxWidth: '400px', margin: '0 auto' }}>
                                                        <div className="pb-16">
                                                            <IonLabel position="stacked" color="primary" className="form-label">
                                                                {t('degree_celsius')}
                                                            </IonLabel>
                                                            <IonSelect 
                                                                placeholder={t('select_degree_celesius')} 
                                                                value={Number(this.state.degree != '' ? this.state.degree : this.state.deviceTCorrection)} 
                                                                onIonChange={e => this.setState({degree: e.detail.value})}
                                                                cancel-text={t('cancel')}
                                                            >
                                                                {this.degrees.map((value, i) => (
                                                                    <IonSelectOption value={Number(i)} key={i}>{value} {t('degree_unit')}</IonSelectOption>
                                                                ))}
                                                            </IonSelect>
                                                        </div>
                                                        <div className="ion-text-center">
                                                            <IonButton color="primary" onClick={this.saveTcontroller}>{t('save')}</IonButton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>

                                        <TabPanel>
                                            <div className="gray-box">
                                                <div className="gray-box__header gray-box__header--alt">
                                                    {t('world_clock')}
                                                </div>
                                                <div className="gray-box__body gray-box__body--transparent">
                                                    <div style={{ maxWidth: '400px', margin: '0 auto' }}>
                                                        <div className="pb-16">
                                                            <IonLabel position="stacked" color="primary" className="form-label">{t('time_zone')}</IonLabel>
                                                            <IonSelect 
                                                                placeholder={String(this.state.selectedTimeZone !== '' ? this.state.selectedTimeZone : this.state.deviceTimezone)} 
                                                                value={String(this.state.selectedTimeZone !== '' ? this.state.selectedTimeZone : this.state.deviceTimezone)} 
                                                                onIonChange={e => this.handleTimzoneChange(e.detail.value)}
                                                                cancel-text={t('cancel')}
                                                            >
                                                                {this.renderTimezones()}
                                                            </IonSelect>
                                                        </div>
                                                        <div className="pb-16">
                                                            <IonLabel position="stacked" color="primary" className="form-label">{t('current_date')}</IonLabel>
                                                            <IonInput type="text" className="form-input" value={this.state.date} readonly={true} disabled={true} />
                                                        </div>
                                                        <div className="pb-16">
                                                            <IonLabel position="stacked" color="primary" className="form-label">{t('current_time')}</IonLabel>
                                                            <IonInput type="text" className="form-input" value={this.state.time} readonly={true} disabled={true} />
                                                        </div>
                                                        <div className="ion-text-center">
                                                            <IonButton color="primary" onClick={this.saveTimeZoneData}>{t('save')}</IonButton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                    </div>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </IonContent>
                <Footer />
            </IonPage>
        );
    }
};

const mapStateToProps = state => {
    return {
        devices: state.oldDevices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(DeviceSettings);