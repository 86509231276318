import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
    IonContent,
    IonToolbar,
    IonPage,
    IonFooter,
    IonButtons,
    IonButton,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonAlert
} from '@ionic/react';
import Slider from '@mui/material/Slider';

import {oldModels} from '../../../../../config/App';
import OldDevicesSettingsHelper from '../../../../../helpers/OldDevicesSettingsHelper';
import BelislimoHelper from '../../../../../helpers/BelislimoHelper';
import TimeHelper from '../../../../../helpers/TimeHelper';
import HeaderWithPopoverMenu from './HeaderWithPopoverMenu';
import {updateOldDeviceState} from '../../../../../redux/actions/OldDevicesAction';
import DeviceTabButton from '../../../components/ionTabs/DeviceTabButton';
import TimeZoneHelper from '../../helpers/TimeZoneHelper';
import WaitingScreen from '../../../components/waitingScreen/WaitingScreen';

const anglesArray20002004 = [
    { 'temp': 0, 'angle': -49 },
    { 'temp': 0.5, 'angle': -47.1 },
    { 'temp': 1, 'angle': -45.2 },
    { 'temp': 1.5, 'angle': -43.3 },
    { 'temp': 2, 'angle': -41.4 },
    { 'temp': 2.5, 'angle': -39.5 },
    { 'temp': 3, 'angle': -37.6 },
    { 'temp': 3.5, 'angle': -35.7 },
    { 'temp': 4, 'angle': -33.8 },
    { 'temp': 4.5, 'angle': -31.9 },
    { 'temp': 5, 'angle': -30 },
    { 'temp': 5.5, 'angle': -28.1 },
    { 'temp': 6, 'angle': -26.2 },
    { 'temp': 6.5, 'angle': -24.3 },
    { 'temp': 7, 'angle': -22.4 },
    { 'temp': 7.5, 'angle': -20.5 },
    { 'temp': 8, 'angle': -18.6 },
    { 'temp': 8.5, 'angle': -16.7 },
    { 'temp': 9, 'angle': -14.8 },
    { 'temp': 9.5, 'angle': -12.9 },
    { 'temp': 10, 'angle': -11 },
    { 'temp': 10.5, 'angle': -9.1 },
    { 'temp': 11, 'angle': -7.2 },
    { 'temp': 11.5, 'angle': -5.3 },
    { 'temp': 12, 'angle': -3.4 },
    { 'temp': 12.5, 'angle': -1.5 },
    { 'temp': 13, 'angle': 0.41 },
    { 'temp': 13.5, 'angle': 1.49 },
    { 'temp': 14, 'angle': 3.39 },
    { 'temp': 14.5, 'angle': 5.29 },
    { 'temp': 15, 'angle': 8 },
    { 'temp': 15.5, 'angle': 10.03 },
    { 'temp': 16, 'angle': 12.06 },
    { 'temp': 16.5, 'angle': 14.09 },
    { 'temp': 17, 'angle': 16.12 },
    { 'temp': 17.5, 'angle': 18.15 },
    { 'temp': 18, 'angle': 20.18 },
    { 'temp': 18.5, 'angle': 22.21 },
    { 'temp': 19, 'angle': 24.24 },
    { 'temp': 19.5, 'angle': 26.27 },
    { 'temp': 20, 'angle': 28.3 },
    { 'temp': 20.5, 'angle': 30.33 },
    { 'temp': 21, 'angle': 32.36 },
    { 'temp': 21.5, 'angle': 34.39 },
    { 'temp': 22, 'angle': 36.42 },
    { 'temp': 22.5, 'angle': 38.45 },
    { 'temp': 23, 'angle': 40.48 },
    { 'temp': 23.5, 'angle': 42.51 },
    { 'temp': 24, 'angle': 44.54 },
    { 'temp': 24.5, 'angle': 46.57 },
    { 'temp': 25, 'angle':  48.6},
    { 'temp': 25.5, 'angle': 50.63 },
    { 'temp': 26, 'angle': 52.66 },
    { 'temp': 26.5, 'angle': 54.69 },
    { 'temp': 27, 'angle': 56.72 },
    { 'temp': 27.5, 'angle': 58.75 },
    { 'temp': 28, 'angle': 60.75 },
    { 'temp': 28.5, 'angle': 62.81 },
    { 'temp': 29, 'angle': 64.84 },
    { 'temp': 29.5, 'angle': 66.87 },
    { 'temp': 30, 'angle': 69 },
    { 'temp': 30.5, 'angle': 71.1 },
    { 'temp': 31, 'angle': 73.2 },
    { 'temp': 31.5, 'angle': 75.3 },
    { 'temp': 32, 'angle': 77.4 },
    { 'temp': 32.5, 'angle': 79.5 },
    { 'temp': 33, 'angle': 81.6 },
    { 'temp': 33.5, 'angle': 83.7 },
    { 'temp': 34, 'angle': 85.8 },
    { 'temp': 34.5, 'angle': 87.9 },
    { 'temp': 35, 'angle': 90 },
    { 'temp': 35.5, 'angle': 92.1 },
    { 'temp': 36, 'angle': 94.2 },
    { 'temp': 36.5, 'angle': 96.3 },
    { 'temp': 37, 'angle': 98.4 },
    { 'temp': 37.5, 'angle': 100.5 },
    { 'temp': 38, 'angle': 102.6 },
    { 'temp': 38.5, 'angle': 104.7 },
    { 'temp': 39, 'angle': 106.8 },
    { 'temp': 39.5, 'angle': 108.9 },
    { 'temp': 40, 'angle': 111 },
    { 'temp': 40.5, 'angle': 113.1 },
    { 'temp': 41, 'angle': 115.2 },
    { 'temp': 41.5, 'angle': 117.3 },
    { 'temp': 42, 'angle': 119.4 },
    { 'temp': 42.5, 'angle': 121.5 },
    { 'temp': 43, 'angle': 123.6 },
    { 'temp': 43.5, 'angle': 125.7 },
    { 'temp': 44, 'angle': 127.8 },
    { 'temp': 44.5, 'angle': 129.9 },
    { 'temp': 45, 'angle': 132 },        
    { 'temp': 45.5, 'angle': 125.73 },
    { 'temp': 46, 'angle': 127.56 },
    { 'temp': 46.5, 'angle': 129.39 },
    { 'temp': 47, 'angle': 131.22 },
    { 'temp': 47.5, 'angle': 133.05 },
    { 'temp': 48, 'angle': 134.88 },
    { 'temp': 48.5, 'angle': 136.71 },
    { 'temp': 49, 'angle': 138.54 },
    { 'temp': 49.5, 'angle': 140.37 },
    { 'temp': 50, 'angle': 142.2 },
    { 'temp': 50.5, 'angle': 144.03 },
    { 'temp': 51, 'angle': 145.86 },
    { 'temp': 51.5, 'angle': 147.69 },
    { 'temp': 52, 'angle': 149.52 },
    { 'temp': 52.5, 'angle': 151.35 },
    { 'temp': 53, 'angle': 153.18 },
    { 'temp': 53.5, 'angle': 155.01 },
    { 'temp': 54, 'angle': 156.84 },
    { 'temp': 54.5, 'angle': 158.67 },
    { 'temp': 55, 'angle': 160.5 },
    { 'temp': 55.5, 'angle': 162.33 },
    { 'temp': 56, 'angle': 164.16 },
    { 'temp': 56.5, 'angle': 165.99 },
    { 'temp': 57, 'angle': 167.82 },
    { 'temp': 57.5, 'angle': 169.65 },
    { 'temp': 58, 'angle': 171.48 },
    { 'temp': 58.5, 'angle': 173.31 },
    { 'temp': 59, 'angle': 175.14 },
    { 'temp': 59.5, 'angle': 176.97 },
    { 'temp': 60, 'angle': 179 },
    { 'temp': 60.5, 'angle': 180.7 },
    { 'temp': 61, 'angle': 182.4 },
    { 'temp': 61.5, 'angle': 184.1 },
    { 'temp': 62, 'angle': 185.8 },
    { 'temp': 62.5, 'angle': 187.5 },
    { 'temp': 63, 'angle': 189.2 },
    { 'temp': 63.5, 'angle': 190.9 },
    { 'temp': 64, 'angle': 192.6 },
    { 'temp': 64.5, 'angle': 194.3 },
    { 'temp': 65, 'angle': 196 },
    { 'temp': 65.5, 'angle': 197.7 },
    { 'temp': 66, 'angle': 199.4 },
    { 'temp': 66.5, 'angle': 201.1 },
    { 'temp': 67, 'angle': 202.8 },
    { 'temp': 67.5, 'angle': 204.5 },
    { 'temp': 68, 'angle': 206.2 },
    { 'temp': 68.5, 'angle': 207.9 },
    { 'temp': 69, 'angle': 209.6 },
    { 'temp': 69.5, 'angle': 211.3 },
    { 'temp': 70, 'angle': 213 },
    { 'temp': 70.5, 'angle': 214.7 },
    { 'temp': 71, 'angle': 216.4 },
    { 'temp': 71.5, 'angle': 218.1 },
    { 'temp': 72, 'angle': 219.8 },
    { 'temp': 72.5, 'angle': 221.5 },
    { 'temp': 73, 'angle': 223.2 },
    { 'temp': 73.5, 'angle': 224.9 },
    { 'temp': 74, 'angle': 226.6 },
    { 'temp': 74.5, 'angle': 228.3 },
    { 'temp': 75, 'angle': 230 }
]

class BoilerApiV2 extends React.Component 
{

    constructor(props) {
        super(props);

        this.state = {
            programState: null,
            newDeviceMode: '',
            popoverMenu: [],
            popoverState: { showPopover: false, event: undefined },
            alert: {
                isOpen: false,
                header: '',
                message: ''
            },
            stopUpdateSlider: false,
            sliderTemp: null
        }
        this.sliderTemp = null;
    }

    componentDidMount = async () => {

        this.OldDevicesSettingsHelper = new OldDevicesSettingsHelper(this.mac);

        if (this.device !== null && this.device !== undefined) {

            let mode = 'manual';

            if (this.device.DeviceStatus.mode === this.model.modes['Program P1']) {
                mode = 'P1';
            }

            if (this.device.DeviceStatus.mode === this.model.modes['Program P2']) {
                mode = 'P2';
            }

            if (this.device.DeviceStatus.mode === this.model.modes['Program P3']) {
                mode = 'P3';
            }

            const { DeviceStatus: {extr}} = this.device;
            const extrData = TimeZoneHelper.decodeExtr(extr);
            this.zonename = extrData.tzname;

            this.setState({
                programState: mode   
            });
        }

        this.setState({sliderTemp: this.device.DeviceStatus[this.model.commands.tempValue]})
    }

    changeDeviceControl = control => {

        if (control === 'manual' && this.programsArray.includes(this.device.DeviceStatus.mode)) {
            this.handleDeviceMode('on', this.model.modes.Manual);
        } else {
            this.setState({programState: control})
        }
    }

    handleDeviceMode = (status, mode) => {

        const modeName = Object.keys(this.model.modes).find(key => this.model.modes[key] === mode);

        if (status === 'on') {

            this.setState({
                newDeviceMode: mode,
                alert: {
                    isOpen: true,
                    header: this.props.t(`${modeName}_mode_header`),
                    message: this.props.t(`${modeName}_mode_message`)
                }
            })

        } else {

            this.handleDeviceMode('on', this.model.modes.Manual);
        }
    }

    render = () => {

        global.debug("RENDER INNER PAGE")

        this.mac = this.props.match.params.id;
        const { t } = this.props;
        const {devices} = this.props;

        this.device = devices[this.mac];

        this.model = oldModels[this.props.match.params.model_name]
        this.model_name = this.props.match.params.model_name
        this.programsArray = [this.model.modes['Program P1'], this.model.modes['Program P2'] , this.model.modes['Program P3']];
         
        if (!this.device || this.device === undefined || this.state.programState == null) {
            return <WaitingScreen />;
        }

        const { DeviceStatus: status, DeviceOnline} = this.device;

        let anglesArray = anglesArray20002004;
        let minTemp = null;
        let maxTemp = null;
        let bHelper = null;
        const isBelislimo = this.model_name.indexOf('2002-') >= 0 ? true : false;

        if (isBelislimo) {

            bHelper = new BelislimoHelper(this.device);
            anglesArray = bHelper.getSliderAngles();
            minTemp = 0;
            maxTemp = bHelper.getShowers();

        } else {

            minTemp = this.model.minTemp;
            maxTemp = this.model.maxTemp;
        }   

        if (status.vac !== undefined && status.vac == "1") {
            status.mode = "10";
        }

        const currentTempAngle = anglesArray.filter(x => x.temp == parseInt(status[this.model.commands.tempValueCurrent]))[0] !== undefined ? anglesArray.filter(x => x.temp == parseInt(status[this.model.commands.tempValueCurrent]))[0].angle : 270;
        const StatusModeName = Object.keys(this.model.modes).find(key => this.model.modes[key] === status.mode);

        if (!this.state.stopUpdateSlider) {
            this.sliderTemp = status[this.model.commands.tempValue] ? Number(status[this.model.commands.tempValue]) : minTemp;
        } else {
            this.sliderTemp = this.state.sliderTemp;
        }

        return (
            <IonPage>
                
                <HeaderWithPopoverMenu 
                    name={`${this.device.DeviceShortName ?? this.mac}`}
                    mac={this.mac}
                    device = {this.device}
                    model = {this.model}
                    model_name = {this.props.match.params.model_name}
                />

                <IonContent className="ion-padding">

                    <IonToolbar className="custom-toolbar">
                        <IonSegment 
                            scrollable={true}
                            value={this.state.programState} 
                            onIonChange={e => this.changeDeviceControl(e.detail.value)}
                            disabled={(status[this.model.commands.power] === this.model.powerOff || (DeviceOnline === 0)) ? true : false}
                        >
                            <IonSegmentButton value="manual" className={this.state.programState == 'manual' && status[this.model.commands.mode] != this.model.modes.Manual ? 'button-grey' : ''}>
                                <IonLabel>{t('ewh_manual')}</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="P1">
                                <IonLabel>{t('P1')}</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="P2">
                                <IonLabel>{t('P2')}</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="P3">
                                <IonLabel>{t('P3')}</IonLabel>
                            </IonSegmentButton>
                        </IonSegment>
                    </IonToolbar>

                    <div className="device-controls">

                        {status.mode != this.model.modes['Vacation'] && 
                            <IonButton 
                                color="transparent" 
                                className={`button-lock ${status.bst === '1' ? "button-lock--on" : "button-lock--off"}`} 
                                onClick={ e => this.OldDevicesSettingsHelper.setDeviceStatus('bst', status.bst === '1' ? '0' : '1') }
                                disabled= {(status[this.model.commands.power] == this.model.powerOff || DeviceOnline !== 1) ? true : false}
                            >
                                <svg className="button-lock__icon"><use xlinkHref="#icon-boost"></use></svg>
                                <div className="button-lock__label">{t('on')}</div>
                            </IonButton>
                        }

                        <div className={`device-controls__label ${DeviceOnline !== 1 ? 'active' : ''}`}> {this.device.localUsage === true ? t('local_usage') : t('offline')}</div>
    
                        <IonButton 
                            color="transparent" 
                            className={`button-switch ${status[this.model.commands.power] === this.model.powerOn ? "button-switch--on" : "button-switch--off"}`} 
                            onClick={ e => this.OldDevicesSettingsHelper.setDeviceStatus(this.model.commands.power, status[this.model.commands.power] == this.model.powerOn ? this.model.powerOff : this.model.powerOn) }
                        >
                            <svg className="button-switch__icon"><use xlinkHref="#icon-switch"></use></svg>
                        </IonButton>
                    </div>

                    {(status.mode === this.model.modes.Manual ||  this.programsArray.includes(status.mode) || ['P1', 'P2', 'P3'].includes(this.state.programState) || status[this.model.commands.power] == this.model.powerOff) &&

                        <div className={`range-circle ${status.Text === 'HEATING' ? 'range-circle--heating' : 'range-circle--ready'} ${(status[this.model.commands.power] == this.model.powerOff || DeviceOnline !== 1 || status[this.model.commands.boost] == 1) ? 'disabled' : ''}`}>
                            
                            {isBelislimo ? 
                                <div className="range-circle__inner">
                                    <div className="range-circle__status">{status[this.model.commands.power] === this.model.powerOn ? status.Text === 'HEATING' ? t('ewh_heating') : t('ewh_ready') : t('ewh_inactive_status')}</div>
                                    <div className="range-shower">
                                        {bHelper.renderShowersProgress(true)}
                                    </div>
                                    <div className="range-circle__timer">
                                        <svg className="range-circle__timer-icon"><use xlinkHref="#icon-timer"></use></svg>
                                        {TimeHelper.addTimeInMinutes(this.zonename, status.cdt)}
                                    </div>
                                </div>
                            :
                                <div className="range-circle__inner">
                                    <div><svg className="range-circle__icon"><use xlinkHref="#icon-heating-alt"></use></svg></div>
                                    <div className="range-circle__temperature">{status[this.model.commands.tempValueCurrent] ? status[this.model.commands.tempValueCurrent] : 0}°C</div>
                                    <div className="range-circle__label">{t('ewh_currrent_temperature')}</div>
                                    {/* TO DO: Check the heating status */}
                                    <div className="range-circle__status">
                                        {status[this.model.commands.power] === this.model.powerOn ? status.Text === 'HEATING' ? t('ewh_heating') : t('ewh_ready') : t('ewh_inactive_status')}
                                    </div>
                                </div>
                            }


                            {isBelislimo ? 

                                <div className={`range-circle__didgits range-circle__didgits--${bHelper.getShowers()}`}>
                                    {bHelper.renderSLiderDigits()}
                                </div>
                            :
                                <div className="range-circle__didgits range-circle__didgits--70">
                                    <div className="range-circle__didgit range-circle__didgit--0">0</div>
                                    <div className="range-circle__didgit range-circle__didgit--15">15</div>
                                    <div className="range-circle__didgit range-circle__didgit--30">30</div>
                                    <div className="range-circle__didgit range-circle__didgit--45">45</div>
                                    <div className="range-circle__didgit range-circle__didgit--60">60</div>
                                    <div className="range-circle__didgit range-circle__didgit--75">75</div>
                                </div>
                                
                            }

                            <div className="range-circle__dot-handle" style={{ transform: 'rotate(' + currentTempAngle + 'deg)' }}>
                                <div className="range-circle__dot"></div>
                            </div>
                            <div className="range-circle__dot-handle range-circle__dot-handle--current" style={{ transform: 'rotate(' + anglesArray.filter(x => x.temp == parseInt(status[this.model.commands.tempValue]))[0].angle + 'deg)' }}>
                                <div className="range-circle__dot"></div>
                            </div>           
                        </div>
                    }

                    
                    {this.state.programState === 'manual' ? 

                    (status.mode === this.model.modes.Manual || status[this.model.commands.power] == this.model.powerOff) ?
                        <React.Fragment>
                            <div className="range-slider__wrapper">
                                <header className={`range-slider__header ${(status[this.model.commands.power] == this.model.powerOff || DeviceOnline !== 1 || status.boost == '1') ? 'disabled' : ''}`}>
                                    <div className="range-slider__header-item">
                                    <div className="range-slider__header-label">{this.model_name != '2002-v2' ? t('ewh_target_temperature') : t('target_showers')}</div>
                                    </div>
                                    <div className="range-slider__header-item">
                                    <div className="range-slider__output">{this.sliderTemp} {this.model_name != '2002-v2' ? '°C': ''}</div>
                                    </div>
                                </header>
                                <Slider
                                    value={this.sliderTemp}
                                    min={minTemp}
                                    max={maxTemp}
                                    step={1}
                                    track="inverted"
                                    onChange={(event, value) => {
                                        this.setState({stopUpdateSlider: true, sliderTemp: value});
                                    }}
                                    onChangeCommitted={(event, value) => {
                                        this.OldDevicesSettingsHelper.setDeviceStatus(this.model.commands.temp, this.state.sliderTemp)
                                        this.props.updateOldDeviceState(value, this.model.commands.tempValue, this.device.DeviceSerial)
                                        this.setState({stopUpdateSlider: false});
                                    }}
                                    disabled={ (status[this.model.commands.power] == this.model.powerOff || DeviceOnline !== 1 || status[this.model.commands.boost] == 1) ? true : false}
                                    valueLabelDisplay="off" 
                                    className="range-slider range-slider--temperature device-range-slider"
                                />
                            </div>
                        </React.Fragment>
                    :

                        <React.Fragment>
                            <div className="device-mode">
                                <div className="device-mode__start">
                                    <svg className={`device-mode__icon ${this.model.modesSettings[status.mode].class}`}>
                                        <use xlinkHref={this.model.modesSettings[status.mode].image}></use>
                                    </svg>
                                </div>
                                {(status[this.model.commands.mode] != this.model.modes.Vacation) &&
                                    <div className="device-mode__end">
                                        <div className={`range-circle range-circle--alt ${status.Text === 'HEATING' ? '' : 'range-circle--ready'} ${(status[this.model.commands.power] == this.model.powerOff || DeviceOnline !== 1) ? 'disabled' : ''}`}> 
                                            <div className="range-circle__inner">
                                                <div>
                                                    <svg className="range-circle__icon">
                                                        <use xlinkHref="#icon-heating-alt"></use>
                                                    </svg>
                                                </div>
                                                <div className="range-circle__temperature">
                                                    {isBelislimo ?
                                                        <div>{bHelper.renderShowersProgress(true)}</div>
                                                    :
                                                         `${status[this.model.commands.tempValueCurrent] ? status[this.model.commands.tempValueCurrent] : 0}°C`
                                                    }
                                                </div>
                                                <div className="range-circle__label">{t('ewh_currrent_temperature')}</div>
                                                <div className="range-circle__status">
                                                    {status[this.model.commands.power] == this.model.powerOn ? status.Text === 'HEATING' ? t('ewh_heating') : t('ewh_ready') : t('ewh_inactive_status')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="target-list">
                                {/* {d_state.target > 0 &&

                                    <div className="target-list__item">
                                        <div className="target__label">{t('target_temperature')} {d_state.target}°C</div>
                                    </div>
                                } */}
                                {/* { status.dtime > 0 &&
                                    <div className="target-list__item">
                                        <div className="target__label">{t(`${status.mode}_until`)}: {TimeHelper.addTimeInMinutes(this.zonename, status.dtime)}</div>
                                    </div>
                                } */}
                            </div>

                            <div className="range-buttons js-range-buttons">
                                <div className="range-buttons__item">
                                    <IonButton shape="round" size="large" onClick={() => this.handleDeviceMode('on', this.model.modes.Manual)}>{t('deactivate')}</IonButton>
                                </div>

                                {this.model.modesSettings[status.mode].path != '' && 
                                    <div className="range-buttons__item">
                                        <IonButton 
                                            shape="round" 
                                            size="large" 
                                            color="help" 
                                            onclick={() => this.props.history.push(`${this.model.modesSettings[status.mode].path}/${this.model_name}/${this.mac}`)}
                                        >
                                            {t('edit')
                                        }</IonButton>
                                    </div>
                                }
                            </div>
                            
                        </React.Fragment>
                        
                    :
                        <div className="range-buttons js-range-buttons">
                            <div className="range-buttons__item">
                                <IonButton 
                                    shape="round" 
                                    size="large" 
                                    onClick={() => this.handleDeviceMode((status.mode === this.model.modes[`Program ${this.state.programState}`]) ? 'off' : 'on', this.model.modes[`Program ${this.state.programState}`])}
                                >
                                    {(status.mode === this.model.modes[`Program ${this.state.programState}`]) ? t('deactivate') : t('activate')}
                                </IonButton>
                            </div>
                            <div className="range-buttons__item">
                                <IonButton 
                                    shape="round" 
                                    size="large" 
                                    color="help" 
                                    onclick={() => this.props.history.push(`/old/apiv2/program/${this.model_name}/${this.mac}/3/${this.state.programState}`)}
                                >
                                    {t('edit')}
                                </IonButton>
                            </div>
                        </div>
                    }

                    <IonAlert
                        isOpen={this.state.alert.isOpen}
                        onDidDismiss={() => this.setState({alert: {...this.state.alert, isOpen: false}, newDeviceMode: ''})}
                        header={this.state.alert.header}
                        message={`"${t(StatusModeName)}" ${t('switch_off_control')}`}
                        buttons={
                            [{
                                text: t('cancel'),
                                handler: () => {
                                    this.setState({
                                        alert: {...this.state.alert, isOpen: false},
                                        newDeviceMode: '',
                                    })
                                }
                            },
                            {
                                text: t('ok'),
                                handler: () => {

                                    this.props.updateOldDeviceState( this.state.newDeviceMode, this.model.commands.mode, this.device.DeviceSerial);
                                    
                                    this.setState({
                                        programState: !this.programsArray.includes(this.state.newDeviceMode) ? 'manual' : this.state.programState
                                    });

                                    if (this.state.newDeviceMode == 10) {
                                        this.OldDevicesSettingsHelper.setDeviceStatus(
                                            'vac', 
                                            1
                                        ); 
                                    }
                                    
                                    this.OldDevicesSettingsHelper.setDeviceStatus(this.model.commands.mode, this.state.newDeviceMode.toUpperCase());
                                }
                            }]
                        }
                    />
    
                    <div className="ion-content-push"></div>

                </IonContent>

                <IonFooter className={`app-footer ion-no-border ${this.state.programState === 'program' ? 'ion-hide' : ''}`}>
                    <IonToolbar>
                        <IonButtons className="ion-justify-content-between">

                            {this.model.ecoFunctions.map(eco => (
                                <DeviceTabButton 
                                    key={eco}
                                    name={t(eco)}
                                    icon={this.model.ecoIcons[eco]} 
                                    handleStatus={status => this.handleDeviceMode(status, this.model.modes[eco])}
                                    status={(status.mode === this.model.modes[eco]) ?  'on' : 'off'}
                                    disabled= {(status[this.model.commands.power] == this.model.powerOff || DeviceOnline !== 1) ? true : false}
                                />
                            ))}

                            <DeviceTabButton 
                                name={t("Vacation")}
                                icon={'palm'} 
                                //handleStatus={status => this.handleDeviceMode(status, this.model.modes['Vacation'])}
                                handleStatus={newStatus => {
                                    status.mode === this.model.modes['Vacation'] ? 
                                        this.handleDeviceMode(newStatus, this.model.modes['Vacation'])
                                    :
                                        this.props.history.push(`/old/apiv2/vacation/${this.model_name}/${this.mac}`)
                                }}
                                status={(status.mode === this.model.modes['Vacation']) ?  'on' : 'off'}
                                disabled= {(status[this.model.commands.power] == this.model.powerOff || DeviceOnline !== 1) ? true : false}
                            />
                        </IonButtons>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        );
    }
}


const mapStateToProps = state => {
    return {
        devices: state.oldDevices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps,{
        updateOldDeviceState
    })
)(BoilerApiV2);