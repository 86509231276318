import cn05uv from './cn05/cn05uv';
import HPWH4_11_200 from './heatPumps/HPWH4_11_200';
import HPWH4_11_260 from './heatPumps/HPWH4_11_260';
import E32 from './boilers/E32';
import B15 from './boilers/B15';

const classes = { cn05uv, HPWH4_11_200, HPWH4_11_260, E32, B15 };

export default (modelName, deviceData) => {
  return new classes[modelName](deviceData);
}