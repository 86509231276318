import {
    IonPage,
    IonContent,
    IonLabel,
    IonGrid,
    IonRow,
    IonCol,
    IonToggle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonAlert
} from '@ionic/react';

import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import Header from './components/header/Header';
import PageHeader from './components/header/PageHeader';
import Footer from './components/footer/Footer';
import DeviceCN05UVMenu from './components/menu/DeviceCN05UVMenu';
import TemperatureSlider from './components/range/TemperatureSlider';
import DeviceTabButton from './components/ionTabs/DeviceTabButton';

import {
    updateDeviceState
} from '../../redux/actions/DevicesAction';
import {cn05uvSettings} from '../../config/App';
import DeviceSettingsHelper from '../../helpers/DeviceSettingsHelper';

class DeviceCN05UV extends React.Component {    

    constructor(props) {
        super(props);

        this.state = {
            programState: 0,
            newDeviceMode: '',
            powerChecked: true,
            selectedTemperature: 22,
            alert: {
                isOpen: false,
                header: '',
                message: ''
            }
        }

        this.mac = null;
        this.device = null;
        this.DeviceSettingsHelper = null;
    }

    componentDidMount = () => {

        this.DeviceSettingsHelper = new DeviceSettingsHelper(this.mac);

        if (this.device !== null && this.device !== undefined) {

            let mode = 0;

            if (this.device.state.mode && this.device.state.mode === cn05uvSettings.modes.program) {
                mode = 1;
            }

            this.setState({
                programState: mode   
            });
        }
    }


    // handleDeviceMode = (e) => {
    //     if (e.currentTarget.classList.contains('device-button--active')) return undefined;
        
    //     this.setState({showAlert: true});
    // }

    changeDeviceControl = control => {

        if (control === 0 && this.device.state.mode === cn05uvSettings.modes.program) {
            this.handleDeviceMode('on', cn05uvSettings.modes.heating);
        } else {
            this.setState({programState: control})
        }
    }

    handleDeviceMode = (status, mode) => {

        if (status === 'on') {

            this.setState({
                newDeviceMode: mode,
                alert: {
                    isOpen: true,
                    header: this.props.t(`${mode}_mode_header`),
                    message: this.props.t(`${mode}_mode_message`)
                }
            })

        } else {

            this.handleDeviceMode('on', cn05uvSettings.modes.heating);
        }
    }

    capitalizeFirstLetter = string => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    render = () => {
    
        const deviceControls = [
            {
                'title': 'Eco',
                'icon': 'eco',
                'active': true
            },
            {
                'title': 'Comfort',
                'icon': 'comfort',
                'active': false
            },
            {
                'title': 'Sleep mode',
                'icon': 'moon',
                'active': false
            },
            {
                'title': 'Delayed start',
                'icon': 'clock',
                'active': false
            }
        ]
    
        const minTemperature = 10;
        const maxTemperature = 34;
        const stepTemperature = .5;
    
        

        this.mac = this.props.match.params.mac_address;

        const { t } = this.props;
        const {devices} = this.props;

        this.device = devices[this.mac];
        
        if (!this.device || this.device === undefined) {
            return null;
        }

        const { state: d_state } = this.device;
        const { modes: device_modes } = cn05uvSettings;

        if (!d_state.current_temp || d_state.current_temp === undefined) {
            d_state.current_temp = 0;
        }

        if (!d_state.temp || d_state.temp === undefined) {
            d_state.temp = cn05uvSettings.min_temp;
        }

        return (
            <IonPage>
                <Header />
                <IonContent className="ion-no-padding">
                    <div className="dashboard">
                        <div className="dashboard__wrapper">
                            
                            <DeviceCN05UVMenu activeMenu="controlPanel" mac={this.props.match.params.mac_address}/>

                            <div className="dashboard__content">
                                
                                <PageHeader name={t('control_panel')}/>

                                <div className="dashboard-box">
                                    <div className="dashboard-box__header">

                                        {this.device.hasInternet !== true && 
                                            <div className="ion-text-center pb-20">
                                                <div className="device-controls__label active">{t('offline')}</div>
                                            </div>
                                        }

                                        <IonGrid className="px-0 py-0">
                                            <IonRow className="ion-justify-content-between">
                                                <IonCol size-xs="12" size-md="auto" className="px-0 py-0">
                                                    <IonRow>
                                                        <IonCol size-xs="6" size-md="auto" className="py-0 ion-padding-end">
                                                            <div className="text-bold text-black mb-10">{t('currrent_temperature')}</div>
                                                            <div className={`device-temperature device-temperature--alt ${d_state.status === 'on' ? '' : 'device-temperature--off'}`}>
                                                                {d_state.current_temp ? d_state.current_temp : 0}{t('degree_unit')}
                                                            </div>
                                                        </IonCol>
                                                        <IonCol size-xs="6" size-md="auto" className="py-0 ion-padding-end">
                                                            <div className="text-bold text-black mb-10">{t('status')}</div>
                                                            <div className={`device-temperature ${d_state.status === 'on' ? '' : 'device-temperature--off'}`}>
                                                                <svg className="device-temperature__icon"><use xlinkHref="#icon-heating-alt"></use></svg>
                                                                {t(d_state.mode)}
                                                            </div>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                                <IonCol size-xs="12" size-md="auto" className="px-0 py-0">
                                                    <IonRow>
                                                        <IonCol size-xs="6" size-md="auto" className="py-0 ion-padding-end">
                                                            <IonButton 
                                                                className={`device-button ${d_state.lockedDevice === 'on' ? "device-button--active" : "device-button--lock"}`} 
                                                                onClick={ e => this.DeviceSettingsHelper.handleLockDevice(d_state.lockedDevice === 'on' ? 'off' : 'on') }
                                                            >
                                                                <div className="device-button__inner">
                                                                    <div className="device-button__icon">
                                                                        <svg className="device-button__icon-icon"><use xlinkHref="#icon-locker"></use></svg>
                                                                        <div className="device-button__icon-label">{t('on')}</div>
                                                                    </div>
                                                                    <IonLabel className="device-button__text">{t('lock_display')}</IonLabel>
                                                                </div>
                                                            </IonButton>
                                                        </IonCol>
                                                        <IonCol size-xs="6" size-md="auto" className="py-0 ion-padding-end">
                                                            <IonButton 
                                                                className={`device-button ${d_state.status === 'on' ? "device-button--active" : "device-button--lock"}`} 
                                                                onClick={ e => this.DeviceSettingsHelper.handleOnOff(d_state.status === 'on' ? 'off' : 'on') }
                                                            >
                                                                <div className="device-button__inner">
                                                                    <div className="device-button__icon">
                                                                        <svg className="device-button__icon-icon"><use xlinkHref="#icon-switch"></use></svg>
                                                                        <div className="device-button__icon-label">{t('on')}</div>
                                                                    </div>
                                                                    <IonLabel className="device-button__text">
                                                                        {d_state.status === 'on' ? t('active_status') : t('inactive_status')}    
                                                                    </IonLabel>
                                                                </div>
                                                            </IonButton>
                                                            {/* <div className="text-bold text-black mb-10"></div>
                                                            <IonLabel className={`form-toggle ${d_state.status === 'on' ? 'form-toggle--active' : ''}`}>
                                                                <div className="form-toggle__label">{t('off_status')}</div>
                                                                <IonToggle 
                                                                    className="form-toggle__element" 
                                                                    checked={d_state.status === 'on' ? true : false} 
                                                                    onClick={e => this.DeviceSettingsHelper.handleOnOff(d_state.status === 'on' ? 'off' : 'on')} 
                                                                    disabled= {this.device.hasInternet === false ? true : false}
                                                                />
                                                                <div className="form-toggle__label">{t('on')}</div>
                                                            </IonLabel> */}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </div>
                                    <div className="dashboard-box__body">
                                        <Tabs forceRenderTabPanel defaultIndex={d_state.mode === cn05uvSettings.modes.program ? 1 : 0}>
                                            <TabList>
                                                <Tab  
                                                    disabled= {(d_state.status === 'off' || (this.device.hasInternet === false && this.device.localUsage === false)) ? true : false} 
                                                    onClick={() => this.changeDeviceControl(0)}
                                                >
                                                    {t('convector_manual')}
                                                </Tab>
                                                <Tab  
                                                    disabled= {(d_state.status === 'off' || (this.device.hasInternet === false && this.device.localUsage === false)) ? true : false} 
                                                    onClick={() => this.changeDeviceControl(1)}
                                                >
                                                    {t('program')}
                                                </Tab>
                                            </TabList>
    
                                            <TabPanel>
                                                
                                                {(d_state.mode === device_modes.heating || d_state.mode === device_modes.off) ?
                                                    <div className="pt-20">
                                                        <div className="wrapper wrapper--xs">
                                                            <TemperatureSlider 
                                                                label={t('target_temperature')}
                                                                disabled= {(d_state.status === 'off' || (this.device.hasInternet === false && this.device.localUsage === false)) ? true : false}
                                                                currentTemp={typeof d_state.temp === 'number' ? d_state.temp : cn05uvSettings.min_temp}
                                                                minTemperature={cn05uvSettings.min_temp}
                                                                maxTemperature={cn05uvSettings.max_temp}
                                                                stepTemperature={cn05uvSettings.step_temp}
                                                                handleTemperature={value => this.DeviceSettingsHelper.handleOnChangeTemp(value)}
                                                            />
                                                        </div>
                                                    </div>
                                                :
                                                    <div className="pt-20">
                                                        <div className="device-mode">
                                                            <div className="device-mode__start">
                                                                <svg className={`device-mode__icon ${cn05uvSettings.modesInfo[d_state.mode].class}`}>
                                                                    <use xlinkHref={cn05uvSettings.modesInfo[d_state.mode].image}></use>
                                                                </svg>
                                                            </div>
                                                            <div className="device-mode__end">
                                                                <div className="device-mode__title">{`${t('your_device_is_in')} ${t(d_state.mode)}`}</div>
                                                                <div className="target-list">
                                                                    {d_state.target > 0 &&

                                                                        <div className="target-list__item">
                                                                            <div className="target__label">{t('target_temperature')} {d_state.target}°C</div>
                                                                        </div>
                                                                    }
                                                                    { d_state.timeRemaining > 0 &&
                                                                        <div className="target-list__item">
                                                                            <div className="target__label">{t(`${d_state.mode}_until`)}: {d_state.modeTime}</div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <IonButton color="tertiary" routerLink={`/device-settings/${this.device.model.toLowerCase()}/${this.mac}`}>
                                                                    <span className="button__text">{t('edit')}</span>
                                                                </IonButton>
                                                                <IonButton color="primary" onClick={() => this.handleDeviceMode('on', device_modes.heating)}>
                                                                    <span className="button__text">{t('deactivate')}</span>
                                                                </IonButton>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                <div className="pt-20">
                                                    <IonToolbar className="bordered">
                                                        <IonButtons className="ion-justify-content-between">
                                                           
                                                            <DeviceTabButton 
                                                                name={t('eco')} 
                                                                icon={'eco'} 
                                                                handleStatus={status => this.handleDeviceMode(status, device_modes.eco)}
                                                                status={(d_state.mode &&  d_state.mode === device_modes.eco) ?  'on' : 'off'}
                                                                disabled= {(d_state.status === 'off' || (this.device.hasInternet === false && this.device.localUsage === false)) ? true : false}
                                                            />

                                                            <DeviceTabButton 
                                                                name={t('comfort')} 
                                                                icon={'comfort'} 
                                                                handleStatus={status => this.handleDeviceMode(status, device_modes.comfort)}
                                                                status={(d_state.mode &&  d_state.mode === device_modes.comfort) ?  'on' : 'off'}
                                                                disabled= {(d_state.status === 'off' || (this.device.hasInternet === false && this.device.localUsage === false)) ? true : false}
                                                            />

                                                            <DeviceTabButton 
                                                                name={t('sleep_mode')} 
                                                                icon={'moon'} 
                                                                handleStatus={status => this.handleDeviceMode(status, device_modes.sleep)}
                                                                status={(d_state.mode &&  d_state.mode === device_modes.sleep) ?  'on' : 'off'}
                                                                disabled= {(d_state.status === 'off' || (this.device.hasInternet === false && this.device.localUsage === false)) ? true : false}
                                                            />

                                                            <DeviceTabButton 
                                                                name={t('delayed_start')} 
                                                                icon={'clock'} 
                                                                handleStatus={status => this.handleDeviceMode(status, device_modes.delayStart)}
                                                                status={(d_state.mode &&  d_state.mode === device_modes.delayStart) ?  'on' : 'off'}
                                                                disabled= {((this.device.hasInternet === false && this.device.localUsage === false)) ? true : false}
                                                            />
                                                        
                                                        </IonButtons>
                                                       
                                                        <IonAlert
                                                            isOpen={this.state.alert.isOpen}
                                                            onDidDismiss={() => this.setState({alert: {...this.state.alert, isOpen: false}, newDeviceMode: ''})}
                                                            header={this.state.alert.header}
                                                            message={`"${t(d_state.mode.toLowerCase())}" ${t('switch_off_control')}`}
                                                            buttons={
                                                                [{
                                                                    text: t('cancel'),
                                                                    handler: () => {
                                                                        this.setState({
                                                                            alert: {...this.state.alert, isOpen: false},
                                                                            newDeviceMode: '',
                                                                        })
                                                                    }
                                                                },
                                                                {
                                                                    text: t('ok'),
                                                                    handler: () => {

                                                                        this.setState({
                                                                            programState: this.state.newDeviceMode !== device_modes.program ? 'manual' : this.state.programState
                                                                        });

                                                                        this.DeviceSettingsHelper.handleMode(this.state.newDeviceMode);
                                                                    }
                                                                }]
                                                            }
                                                        />

                                                    </IonToolbar>
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                {/* TO DO: Ask what to do here */}
                                                <div className="pt-20">
                                                    <div className="program-status">
                                                        {/* <div className="program-status__col">
                                                            <div className="program-status__title program-status__title--primary">{t('currrent_temperature')}</div>
                                                            <div className="program-status__list">
                                                                <div className="program-status__list-item program-status__list-item--primary">
                                                                    <div className="program-status__list-top program-status__list-top">
                                                                        <svg className="program-status__list-icon"><use xlinkHref="#icon-idle"></use></svg> {d_state.current_temp ? d_state.current_temp : 0}°C
                                                                    </div>
                                                                    {/* <div className="program-status__list-bottom">10:00h - 11:00h</div> 
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        <div className="program-status__col">
                                                            {/* <div className="program-status__title">Upcoming status</div>
                                                            <div className="program-status__list">
                                                                <div className="program-status__list-item">
                                                                    <div className="program-status__list-top">
                                                                        <svg className="program-status__list-icon"><use xlinkHref="#icon-idle"></use></svg> 27°C
                                                                    </div>
                                                                    <div className="program-status__list-bottom">12:00h - 13:00h</div>
                                                                </div>
                                                                <div className="program-status__list-item">
                                                                    <div className="program-status__list-top">
                                                                        <svg className="program-status__list-icon"><use xlinkHref="#icon-idle"></use></svg> 23°C
                                                                    </div>
                                                                    <div className="program-status__list-bottom">13:00h - 17:30h</div>
                                                                </div>
                                                                <div className="program-status__list-item">
                                                                    <div className="program-status__list-top">
                                                                        <svg className="program-status__list-icon"><use xlinkHref="#icon-idle"></use></svg> 26°C
                                                                    </div>
                                                                    <div className="program-status__list-bottom">17:30h - 21:00h</div>
                                                                </div>
                                                            </div> */}
                                                            <IonRow className="ion-text-center">
                                                                <IonCol>
                                                                    <IonButton routerLink={`/device/program/${this.device.model.toLowerCase()}/${this.mac}`}>{t('edit_program')}</IonButton>
                                                                </IonCol>
                                                                <IonCol>
                                                                    <IonButton 
                                                                        color="tertiary"
                                                                        onClick={() => this.handleDeviceMode((d_state.mode &&  d_state.mode === device_modes.program) ? 'off' : 'on', device_modes.program)}
                                                                    >
                                                                        <span className="button__text">{(d_state.mode &&  d_state.mode === device_modes.program) ? t('deactivate') : t('activate')}</span>
                                                                    </IonButton>
                                                                </IonCol>
                                                            </IonRow>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </IonContent>
                <Footer />
            </IonPage>
        );
    }

    
};

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        updateDeviceState
    })
)(DeviceCN05UV);