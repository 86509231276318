class TimeZoneHelper
{
    decodeExtr = value => {

        if (!value) {
            return {
                tzname: "Europe/Sofia"
            }
        }

        const extr_base64_encoded = atob(value);
        const extr_readable = unescape(extr_base64_encoded);
        
        return JSON.parse(extr_readable)
    }

    encodeExtr = extrData => {

        extrData = JSON.stringify(extrData);

        const extr_escaped = escape(extrData).replace(/\//g, '%2F');
        const extr_base64_decoded = btoa(extr_escaped);

        return extr_base64_decoded;
    }
}

export default new TimeZoneHelper();