import {
    IonPage,
    IonContent,
    IonButton,
    IonRow,
    IonCol,
    IonAlert,
} from '@ionic/react';

import { compose } from 'redux'
import { connect } from 'react-redux'

import {
    ResponsiveContainer,
    XAxis,
    YAxis,
    BarChart,
    Bar,
    Legend,
    Tooltip,
    Brush
} from 'recharts';

import '../components/charts/statistics-chart.scss';

import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';

import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import HeatPumpMenu from './components/HeatPumpMenu';
import PageHeader from '../components/header/PageHeader';
import PageSubHeader from '../components/header/PageSubHeader';

import RestHelper from '../../../helpers/RestHelper';

const CalculatorHistory = props => {

    const ColorConfig = {
        "red": "#EB445A",
        "green": "#2EC5A2",
        "gray": "#3b3b3b",
        "gray2": "#92949c",
        "purple": "#9360DA"
    }

    const chartHeight = 310;

    const { t } = useTranslation();
    const [fullStat, setFullStat] = useState(null);
    const [weeklyData, setWeeklyData] = useState(null);
    const [monthlyData, setMonthlyData] = useState(null);
    const [annualData, setAnnualData] = useState(null);

    const { 
        devices, 
        match: {
            params: {
                mac_address: mac
            }
        } 
    } = props;
    const device = devices[mac];
    const restHelperOBJ = new RestHelper();

    useEffect(async () => {

        setFullStat(await getActivityData("full"));
        setWeeklyData(await getActivityData("weekly"));
        setMonthlyData(await getActivityData("monthly"));
        setAnnualData(await getActivityData("annual"));
    
    }, [])

    const getActivityData = async (period) => {

        const {data} = await restHelperOBJ.get('/get-device-power-stat', {
            model: device.model_id,
            timezone: device.timezone,
            activity: period,
            mac: mac     
        });
        global.debug(data)
        return data;
    }

    const renderTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="custom-tooltip__label">{payload[0].payload.main_label}</p>
                    <div className="tooltip-legend">
                        {payload.map((pld, i) => (
                            <div className="tooltip-legend__item" key={i} style={{ '--color': `${pld.fill}` }}>
                                {pld.value} {t('hp_calc_kwh')}
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
              
        return null;
    };

    return (
        
        <IonPage>
            <Header />
            <IonContent className="ion-no-padding">
                <div className="dashboard">
                    <div className="dashboard__wrapper">

                        <HeatPumpMenu activeMenu="hpCalculator" mac={props.match.params.mac_address}/>

                        <div className="dashboard__content">

                            <PageHeader name={t('hp_calculator_history_title')}/>

                            <div className="dashboard-box">

                                <PageSubHeader title={t('hp_calculator_history_title')}/>

                                <div className="dashboard-box__body">
                                    {fullStat && 
                                        <div className="consumption" style={{ maxWidth: '400px', margin: '0 auto' }}>
                                            <div className="consumption__item">
                                                <div className="consumption__title">{t('hp_calc_kwh')}</div>
                                                <div className="consumption__value">{fullStat.daily}</div>
                                                <div className="consumption__title consumption__title--sm">{t('hp_calc_history_today')}</div>
                                            </div>
                                            <div className="consumption__item">
                                                <div className="consumption__title">{t('hp_calc_kwh')}</div>
                                                <div className="consumption__value">{fullStat.monthly}</div>
                                                <div className="consumption__title consumption__title--sm">{t('hp_calc_history_last_month')}</div>
                                            </div>
                                            <div className="consumption__item">
                                                <div className="consumption__title">{t('hp_calc_kwh')}</div>
                                                <div className="consumption__value">{fullStat.yearly}</div>
                                                <div className="consumption__title consumption__title--sm">{t('hp_calc_history_last_year')}</div>
                                            </div>
                                        </div>
                                    }
                                    
                                    <div className="text-14 text-grey pt-32">
                                        {t('hp_calc_data_inductive')}
                                        <svg className="size-24 fill-orange" id="data-alert" style={{ cursor: "pointer", verticalAlign: "middle" }}>
                                            <use xlinkHref="#icon-info"></use>
                                        </svg>
                                        <IonAlert
                                            trigger="data-alert"
                                            header={t('hp_calc_data_inductive_note')}
                                            message={t('hp_calc_data_inductive_info')}
                                            buttons={
                                                [{
                                                    text: 'Ok',
                                                    handler: () => {
                                                        global.debug('confirm');
                                                    }
                                                }]
                                            }
                                        />
                                    </div>

                                    <div className="charts-list pt-32">
                                        {(weeklyData && weeklyData.length > 0 ) &&
                                            <div className="charts-list__item">
                                                <div className="charts-list__title">{t('hp_calc_weekly_chart')}</div>
                                                <ResponsiveContainer debounce={1} width={'100%'} height={chartHeight}>
                                                    <BarChart 
                                                        data={weeklyData} 
                                                        margin={{ top: 0, right: 0, left: 0, bottom: 0 }} 
                                                        barSize={23} 
                                                        barGap={1} 
                                                        className="hide-brush-texts"
                                                    >
                                                        <XAxis
                                                            dataKey="name"
                                                            interval="preserveStart"
                                                            fontSize={12}
                                                            dy={2}
                                                            dx={2} 
                                                            padding={{ left: 8, right: 8 }}
                                                        />
                                                        <YAxis 
                                                            width={35}
                                                            orientation="left"
                                                            tick={{ fontSize: 10 }}
                                                        />
                                                        <Tooltip content={renderTooltip} cursor={{ fill: "#F3F3F3" }} />
                                                        <Legend iconType={'square'} iconSize={11} />
                                                        <Brush 
                                                            height={30} 
                                                            travellerWidth={30} 
                                                        />
                                                        <Bar dataKey="enHP" fill={ColorConfig['green']} />
                                                        <Bar dataKey="enEH" fill={ColorConfig['red']} />
                                                        <Bar dataKey="enPV" fill={ColorConfig['purple']} />
                                                    </BarChart>
                                                </ResponsiveContainer>
                                            </div>
                                        }

                                        {(monthlyData && monthlyData.length > 0) &&
                                            <div className="charts-list__item">
                                                <div className="charts-list__title">{t('hp_calc_monthly_chart')}</div>
                                                <ResponsiveContainer debounce={1} width={'100%'} height={chartHeight}>
                                                    <BarChart 
                                                        data={monthlyData} 
                                                        margin={{ top: 0, right: 0, left: 0, bottom: 0 }} 
                                                        barSize={45} 
                                                        barGap={1} 
                                                        className="hide-brush-texts"
                                                    >
                                                        <XAxis
                                                            dataKey="name"
                                                            interval="preserveStart"
                                                            fontSize={12}
                                                            dy={2}
                                                            dx={2} 
                                                            padding={{ left: 8, right: 8 }}
                                                        />
                                                        <YAxis 
                                                            width={35}
                                                            orientation="left"
                                                            tick={{ fontSize: 10 }}
                                                        />
                                                        <Tooltip content={renderTooltip} cursor={{ fill: "#F3F3F3" }} />
                                                        <Legend iconType={'square'} iconSize={11} />
                                                        <Brush 
                                                            height={30} 
                                                            travellerWidth={30} 
                                                        />
                                                        <Bar dataKey="enHP" fill={ColorConfig['green']} />
                                                        <Bar dataKey="enEH" fill={ColorConfig['red']} />
                                                        <Bar dataKey="enPV" fill={ColorConfig['purple']} />
                                                    </BarChart>
                                                </ResponsiveContainer>
                                            </div>
                                        }
                                        
                                        {(annualData && annualData.length > 0) &&
                                            <div className="charts-list__item">
                                                <div className="charts-list__title">{t('hp_calc_annual_chart')}</div>
                                                <ResponsiveContainer debounce={1} width={'100%'} height={chartHeight}>
                                                    <BarChart 
                                                        data={annualData} 
                                                        margin={{ top: 0, right: 0, left: 0, bottom: 0 }} 
                                                        barSize={14} 
                                                        barGap={1} 
                                                        className="hide-brush-texts"
                                                    >
                                                        <XAxis
                                                            dataKey="name"
                                                            interval="preserveStart"
                                                            fontSize={12}
                                                            dy={2}
                                                            dx={2} 
                                                            angle={-45}
                                                            padding={{ left: 8, right: 8 }}
                                                        />
                                                        <YAxis 
                                                            width={35}
                                                            orientation="left"
                                                            tick={{ fontSize: 10 }}
                                                        />
                                                        <Tooltip content={renderTooltip} cursor={{ fill: "#F3F3F3" }} />
                                                        <Legend iconType={'square'} iconSize={11} />
                                                        <Brush 
                                                            height={30} 
                                                            travellerWidth={30} 
                                                        />
                                                        <Bar dataKey="enHP" fill={ColorConfig['green']} />
                                                        <Bar dataKey="enEH" fill={ColorConfig['red']} />
                                                        <Bar dataKey="enPV" fill={ColorConfig['purple']} />
                                                    </BarChart>
                                                </ResponsiveContainer>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </IonContent>
            <Footer />
        </IonPage>
    );
};

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    connect(mapStateToProps, {})
)(CalculatorHistory);