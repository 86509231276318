import {
    IonPage,
    IonContent,
    IonToolbar,
    IonButton,
    IonList,
    IonItem,
    IonFooter,
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import RangeTime from './components/range/RangeTime';
import IonDateTime from './components/ionDateTime/IonDateTime';
import RangeStartTemperature from './components/range/RangeStartTemperature';
import StandartHeader from './components/header/StandartHeader';
import Week from './components/week/Week';
import {cn05uvSettings} from '../../config/App';
import {
    addDeviceProgram
} from '../../redux/actions/DevicesAction';
import { setAppMessage } from '../../redux/actions/AppAction';
import DeviceSettingsHelper from '../../helpers/DeviceSettingsHelper';
import ProgramHelper from '../../helpers/ProgramHelper';
import ToastMessage from './components/toast/ToastMessage';

// import '../../assets/css/mobileApp/components/day-picker/day-picker.scss';
// import '../../assets/css/mobileApp/components/preferences/preferences.scss';
// import '../../assets/css/mobileApp/components/program/program.scss';


class EditProgram extends React.Component{

    constructor(props){
        super(props);

        this.mac = null;
        this.model = null;
        this.key = null;
        this.DeviceSettingsHelper = null;

        this.state = {
            selectedDay: "",
            from: "",
            to: "",
            temp: ""
        }
    }

    componentDidMount = () => {

        this.DeviceSettingsHelper = new DeviceSettingsHelper(this.mac);
    }

    saveProgram = () => {

        const { program } = this.props.devices[this.mac].state;

        const programHelper = new ProgramHelper(this.mac);

        //TO DO: Ask for this check. First the delete method and after that add method
        if (programHelper.validateProgram(
            this.state.from !== "" ? this.state.from : program[this.key].from,
            this.state.to !== "" ? this.state.to : program[this.key].to,
            this.state.selectedDay !== "" ? Number(this.state.selectedDay) : program[this.key].day
        )) {

            this.DeviceSettingsHelper.handleEditProgram(this.key, {
                day: this.state.selectedDay !== "" ? Number(this.state.selectedDay) : program[this.key].day,
                from: this.state.from !== "" ? this.state.from : program[this.key].from,
                to: this.state.to !== "" ? this.state.to : program[this.key].to,
                temp: this.state.temp !== "" ? this.state.temp : program[this.key].temp
            }); 

            this.props.history.goBack();
            
        } else {
            this.props.setAppMessage(this.props.t('invalid_program'));
        }
    }

    formatTime = (time) => {
        const [hours, mins] = time.split(":");
        const timestampHours = Number(hours) * 60 * 60;
        const timestampMins = Number(mins) * 60;
        return timestampHours + timestampMins;
    }

    render = () => {

        this.mac = this.props.match.params.mac_address;
        this.model = this.props.match.params.model;
        this.key = this.props.match.params.key;

        const {t} = this.props;

        const device = this.props.devices[this.mac];
        
        if (!device || device === undefined) {
            return null;
        }   

        const { state : d_state } = device;
        const { program } = d_state;

        if (program[this.key] === undefined) {
            return null;
        }
       

        return (
            <IonPage>

                <StandartHeader name={t('program')}/>
                <IonContent className="ion-padding">            
                    
                    <Week 
                        setSelectedDay={day => this.setState({selectedDay: day})} 
                        defaultDay={program[this.key].day}
                    />

                    <div className="preferences-list__wrapper">
                    <IonList className="preferences-list preferences-list--editable">
                        <IonItem className="preferences-list__item" lines="none">
                            {/* <div className="preferences-list__inner">
                                <div className="preferences-list__item-middle">
                                    <RangeTime 
                                        data-min="0" 
                                        data-max="86399" 
                                        data-start={this.formatTime(program[this.key].from)} 
                                        data-step="1800" 
                                        data-label={t('time_from')}
                                        setTime={time => this.setState({from: time})} 
                                    />
                                </div>
                            </div> */}
                            <IonDateTime 
                                data-start={program[this.key].from} 
                                data-label={t('time_from')}    
                                setTime={time => this.setState({from: time})} 
                            />
                        </IonItem>
                        <IonItem className="preferences-list__item" lines="none">
                            {/* <div className="preferences-list__inner">
                                <div className="preferences-list__item-middle">
                                    <RangeTime 
                                        data-min="0" 
                                        data-max="86399" 
                                        data-start={this.formatTime(program[this.key].to)} 
                                        data-step="1800" 
                                        data-label={t('time_to')} 
                                        setTime={time => this.setState({to: time})}
                                    />
                                </div>
                            </div> */}
                            <IonDateTime 
                                data-start={program[this.key].to} 
                                data-label={t('time_to')}    
                                setTime={time => this.setState({to: time})} 
                            />
                        </IonItem>
                        <IonItem className="preferences-list__item" lines="none">
                        <div className="preferences-list__inner">
                            <div className="preferences-list__item-middle">
                                <RangeStartTemperature 
                                    data-min={cn05uvSettings.min_temp} 
                                    data-max={cn05uvSettings.max_temp} 
                                    data-start={program[this.key].temp} 
                                    data-step={cn05uvSettings.step_temp} 
                                    data-label={t('degree_unit')} 
                                    setTemp={temp => this.setState({temp})}
                                />
                            </div>
                        </div>
                        </IonItem>
                    </IonList>
                    </div>
                    <div className="ion-content-push"></div>
                    <ToastMessage />
                </IonContent>
            
                <IonFooter className="app-footer ion-no-border">
                    <IonToolbar>
                        <div className="buttons-list">
                        <div className="buttons-list__item">
                            <IonButton expand="block" shape="round" size="large" onClick={this.saveProgram}>{t('save')}</IonButton>
                        </div>
                        <div className="buttons-list__item">
                            <IonButton expand="block" shape="round" size="large" color="help" routerLink={`/device/program/${this.model}/${this.mac}`}>{t('cancel')}</IonButton>
                        </div>
                        </div>
                    </IonToolbar>
                </IonFooter>
            </IonPage>
        );
    }
};

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, {
        addDeviceProgram,
        setAppMessage
    })
)(EditProgram);