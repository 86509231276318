import {
    IonPage,
    IonContent
} from '@ionic/react';

import React from 'react';
import { withTranslation } from 'react-i18next';

import StandartHeader from '../../components/header/StandartHeader';
import heatPumps from '../../../../assets/images/Heat_pump.jpg';

const AddHeatPump = props => {

    return (
        <IonPage>
            <StandartHeader name={props.t('hp_choose_heat_pump')}/>

            <IonContent className="ion-padding">
                <div className="device-list">
                    
                    {/* TO DO: Add correct path */}
                    <div className="device-list__item" onClick={() => props.history.push('/connect/hpwh4_11_260')}>
                        <div className="device-list__preview">
                            <img src={heatPumps} alt="photo" />
                        </div>
                        <div className="device-list__content">
                            <div className="device-list__title">​​​​​​​{props.t('hp_model_AquaThermica')}</div>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};
  
  export default withTranslation()(AddHeatPump); 