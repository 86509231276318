import {
    IonPage,
    IonContent,
    IonRow,
    IonCol,
    IonButton,
    IonList,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonCheckbox,
    IonModal,
    IonText,
    IonToolbar,
    IonLabel,
    IonAlert
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import E32Menu from './components/E32Menu';
import PageHeader from '../components/header/PageHeader';
import PageSubHeader from '../components/header/PageSubHeader';

import E32MqttHelper from "../../../helpers/mqqt/E32MqttHelper";
import ProgramHelper from './helpers/ProgramHelper';
import Loading from '../components/loading/Loading';
import ToastMessage from '../components/toast/ToastMessage';
import dayjs from 'dayjs';

import { setAppMessage } from '../../../redux/actions/AppAction';

const Program = props => {

    const { t } = useTranslation();
    const [selectedDay, setSelectedDay] = useState(0);
    const [showWeekModal, setShowWeekModal] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [showDeleteZoneAlert, setShowDeleteZoneAlert] = useState(false);
    const [deletedZone, setDeletedZone] = useState(null);
    const [weekDays, setWeekDays] = useState({
        0: {
          'name': t('monday_full'),
          'checked': true
        },
        1: {
          'name': t('tuesday_full'),
          'checked': true
        },
        2: {
          'name': t('wednesday_full'),
          'checked': true
        },
        3: {
          'name': t('thursday_full'),
          'checked': true
        },
        4: {
          'name': t('friday_full'),
          'checked': true
        },
        5: {
          'name': t('saturday_full'),
          'checked': true
        },
        6: {
          'name': t('sunday_full'),
          'checked': true
        }
    });

    const [weekData, setWeekData] = useState({
        0: {
            'day': t('Monday'),
            'isSelected': true
        },
        1: {
            'day': t('Tuesday'),
            'isSelected': false
        },
        2: {
            'day': t('Wednesday'),
            'isSelected': false
        },
        3: {
            'day': t('Thursday'),
            'isSelected': false
        },
        4: {
            'day': t('Friday'),
            'isSelected': false
        },
        5: {
            'day': t('saturday'),
            'isSelected': false
        },
        6: {
            'day': t('Sunday'),
            'isSelected': false
        }
    });
    

    const { 
        devices, 
        match: {
            params: {
                mac_address: mac, 
                day
            }
        } 
    } = props;
    const device = devices[mac];
    const {state: deviceState} = device;
    const E32MqttHelperOBJ = new E32MqttHelper(mac);
    const programHelperOBJ = new ProgramHelper(mac, props.match.params.program);

    useEffect(() => {
        let currentDay = dayjs().day();
        const daysOfWeek = [6, 0, 1, 2, 3, 4, 5];

        setSelectedDay(props.match.params.day != undefined ? props.match.params.day : daysOfWeek[currentDay])

        handleDayChange(props.match.params.day != undefined ? props.match.params.day : daysOfWeek[currentDay]);
    }, []);

    const addProgram = () => {
        if (!device.state[`program_${props.match.params.program}`]) {
            props.history.push(`/device/program/create-program/${device.model.toLowerCase()}/${mac}/${selectedDay}/${props.match.params.program}`);
            return;
        }

        if (programHelperOBJ.getZones(selectedDay) < 4) {
            props.history.push(`/device/program/create-program/${device.model.toLowerCase()}/${mac}/${selectedDay}/${props.match.params.program}`)
        } else {
            props.setAppMessage(t('dt2_invalid_program_allowed_4_zones'));
        }
    }

    const renderWeekday = () => {

        const days = [];

        for (const dayIndex in weekData) {
            
            const {isSelected, day} = weekData[dayIndex];

            days.push(
                <th key={`w_day_${dayIndex}`}>
                    <IonButton 
                        color="transparent" 
                        className={`week-table__button ${isSelected ? 'active' : ''}`}
                        onClick={ () => handleDayChange(dayIndex) }
                    >
                        {day}
                    </IonButton>
                </th>
            );
        }

        return days;
    }

    const handleDayChange = selectedDay => {

        const week = weekData;

        for (const dayIndex in week) {

            if (dayIndex == selectedDay) {
                week[dayIndex].isSelected = true;
            } else {
                week[dayIndex].isSelected = false;
            }
        }

        
        setSelectedDay(selectedDay);
        setWeekData(week);
    }

    const renderWeek = () => {

        const days = [];

        for (const day in weekDays) {

            let {checked, name} = weekDays[day];

            if (selectedDay == day) {
                checked = false;
            }

            days.push(
                <IonItem lines="full" key={day}>
                    <IonLabel className="form-checkbox">
                        <IonCheckbox color="secondary" className="form-checkbox__element" name="checkboxName" checked={checked} onIonChange={e => checkDay(day, e.detail.checked)}/>
                        <div className="form-checkbox__text">{name}</div>
                    </IonLabel>
                </IonItem>
            );
        }

        return days;
    }

    const checkDay = (selectedDay, status) => {
        setWeekDays({...weekDays, [selectedDay]:{...weekDays[selectedDay], checked: status}});
    }

    const renderProgram = () => {

        if (!device.state[`program_${props.match.params.program}`]) {
            return null;
        }

        const programList = [];
        const programForTheDay = programHelperOBJ.getProgramForTheDay(selectedDay);

        for (const key in programForTheDay) {
            const {to, from, heatLevel} = programForTheDay[key];

            if (from != "00:00" || to != "00:00") {
                programList.push(
                    <IonItem className="preferences-list__item" lines="none" key={key}>
                        <div className="preferences-list__inner">
                            <div className="preferences-list__item-start">
                                <div className="preferences-list__time">
                                    <svg className="preferences-list__time-icon"><use xlinkHref="#icon-heating"></use></svg>
                                    {from}{t('hour_abbr')} - {to}{t('hour_abbr')}
                                </div>
                            </div>
                            <div className="preferences-list__item-middle">
                                <div className="preferences-list__temperature">
                                    <svg className="preferences-list__temperature-icon"><use xlinkHref="#icon-idle"></use></svg>
                                    {heatLevel}
                                </div>
                            </div>
                            <div className="preferences-list__item-end">
                                <IonButton color="icon-action" onClick={() => confirmDeleteZone(key)}>
                                    <svg className="icon"><use xlinkHref="#icon-delete" className="fill-red"></use></svg>
                                </IonButton>
                            </div>
                        </div>
                    </IonItem>
                );
            }
        }

        return programList;
    }

    const confirmDeleteZone = zone => {
        setShowDeleteZoneAlert(true); 
        setDeletedZone(zone);
    }

    const copyProgram = () => {
        setShowLoading(true);

        const deviceProgram = deviceState[`program_${props.match.params.program}`];
        const programForTheDay = programHelperOBJ.getProgramForTheDay(selectedDay);

        const programDays = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

        for (const wDay in weekDays) {

            if (weekDays[wDay].checked === true) {
                const day =  programDays[wDay];  
                
                deviceProgram[day] = programForTheDay;
            }
        }

        E32MqttHelperOBJ.request('setWeeklyProgram', {progID: props.match.params.program, weekday: deviceProgram});
        setShowLoading(false);
        setShowWeekModal(false);
    }

    const deleteProgram = () => {

        const deviceProgram = deviceState[`program_${props.match.params.program}`];

        for (const day in deviceProgram) {
            deviceProgram[day] = programHelperOBJ.defaultProgramObject();
        }

        E32MqttHelperOBJ.request('setWeeklyProgram', {progID: props.match.params.program, weekday: deviceProgram});
        setShowDeleteAlert(false);
    }

    const deleteZone = () => {
        const deviceProgram = deviceState[`program_${props.match.params.program}`];
        const programDays = programHelperOBJ.getProgramDays();
        deviceProgram[programDays[selectedDay]][deletedZone] = {
            "from":"00:00",
            "to":"00:00",
            "heatLevel":0,
        }

        deviceProgram[programDays[selectedDay]] = programHelperOBJ.sortProgramForTheDay(deviceProgram[programDays[selectedDay]]);

        E32MqttHelperOBJ.request('setWeeklyProgram', {progID: props.match.params.program, weekday: deviceProgram});
    }

    return (
        <IonPage>
            <Header />
            <IonContent className="ion-no-padding">
                <div className="dashboard">
                    <div className="dashboard__wrapper">
                        
                        <E32Menu activeMenu="controlPanel" mac={props.match.params.mac_address}/>

                        <div className="dashboard__content">
                            
                            <PageHeader name={t('control_panel')}/>

                            <div className="dashboard-box">
                                
                                <PageSubHeader title={`${t('program')} ${props.match.params.program.toUpperCase()}`}/>

                                <div className="dashboard-box__body">                                    
                                    <div className="gray-box">
                                        <div className="gray-box__header">
                                            <IonRow className="ion-align-items-center ion-justify-content-between">
                                                <IonCol size="auto" className="pl-0 py-0">
                                                    <table className="week-table">
                                                        <thead>
                                                            <tr>
                                                                {renderWeekday()}
                                                            </tr>
                                                        </thead>
                                                    </table>
                                                </IonCol>
                                                <IonCol size="auto" className="pr-0 py-0">
                                                    <IonButton color="circle" className="ion-no-margin" onClick={addProgram}>
                                                        <span className="sr">{t('add')}</span>
                                                        <svg className="icon"><use xlinkHref="#icon-plus"></use></svg>
                                                    </IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </div>
                                        <div className="gray-box__body gray-box__body--transparent">
                                            <IonRow className="ion-justify-content-between">
                                                <IonCol size="auto" className="ion-text-start p-0">
                                                    <IonButton color="confirm" onClick={() => setShowWeekModal(true)}>{t('save_porgram_to_all_days')}</IonButton>
                                                    <IonModal isOpen={showWeekModal}>
                                                        <IonToolbar style={{ paddingRight: '10px' }}>
                                                            <div slot="end">
                                                                <IonButton color="icon-only" onClick={() => setShowWeekModal(false)}>
                                                                    <svg className="size-14"><use xlinkHref="#icon-close"></use></svg>
                                                                </IonButton>
                                                            </div>
                                                        </IonToolbar>
                                                        <div className="ion-padding">
                                                            <IonText>
                                                                <h2 className="ion-text-center">{t('choose_days_to_copy_program')}</h2>

                                                                <IonList className="ion-padding-top">
                                                                    {renderWeek()}
                                                                </IonList>
                                                            </IonText>
                                                        </div>
                                                        <div className="ion-padding ion-text-center">
                                                            <IonButton onClick={copyProgram}>{t('save')}</IonButton>
                                                        </div>
                                                    </IonModal>
                                                </IonCol>
                                                <IonCol size="auto" className="ion-text-end p-0">
                                                    <IonButton color="delete" onClick={() => setShowDeleteAlert(true)}>{t('delete_program')}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                            <div className="preferences-list__wrapper">
                                                <IonList className="preferences-list">
                                                    {renderProgram()} 
                                                </IonList>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Loading show={showLoading}/>
                <ToastMessage />

                <IonAlert
                    isOpen={showDeleteAlert}
                    onDidDismiss={() => setShowDeleteAlert(false)}
                    cssClass='my-custom-class'
                    header={t('confirm')}
                    message={t('confirm_programs_delete')}
                    buttons={[
                        {
                            text: t('cancel'),
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: blah => {
                                setShowDeleteAlert(false)
                            }
                        },
                        {
                            text: t('ok'),
                            handler: () => {
                                deleteProgram();
                            }
                        }
                    ]}
                />

                <IonAlert
                    isOpen={showDeleteZoneAlert}
                    onDidDismiss={() => setShowDeleteZoneAlert(false)}
                    cssClass='my-custom-class'
                    header={t('confirm')}
                    message={t('dt2_confirm_program_zone_delete')}
                    buttons={[
                        {
                            text: t('cancel'),
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: blah => {
                                setShowDeleteZoneAlert(false)
                            }
                        },
                        {
                            text: t('ok'),
                            handler: () => {
                                deleteZone();
                            }
                        }
                    ]}
                />
            </IonContent>
            <Footer />
        </IonPage>
    );
};

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
}

export default compose(
    connect(mapStateToProps, {
        setAppMessage
    })
)(Program);