import Slider from '@mui/material/Slider';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import B15MqttHelper from "../../../../helpers/mqqt/B15MqttHelper";
import { B15_Settings } from '../../../../config/B15';
import { Device } from '@capacitor/device';

const ManualModeBody = ({mac, device, disableDevice}) => {

    const B15MqttHelperOBJ = new B15MqttHelper(mac);

    const {state: deviceState} = device;
    const minTemp = B15_Settings.minTemp;
    const maxTemp = B15_Settings.maxTemp;

    const { t } = useTranslation();
    const [temp, setTemp] = useState(deviceState.set_temp);
    const [platform, setPlatform] = useState("android")

    useEffect(() => {
        setTemp(deviceState.set_temp);
    }, [deviceState.set_temp])

    useEffect(async () => {
        const {platform} = await Device.getInfo();
        setPlatform(platform)
    }, [])

    return (
        <div className="range-slider__wrapper">
            <header className={`range-slider__header ${(disableDevice || deviceState.boost == 'on') ? 'disabled' : ''}`}>
                <div className="range-slider__header-item">
                <div className="range-slider__header-label">{t('ewh_target_temperature')}</div>
                </div>
                <div className="range-slider__header-item">
                <div className="range-slider__output">{temp} °C</div>
                </div>
            </header>
            <Slider
                value={temp}
                min={minTemp}
                max={maxTemp}
                step={1}
                track="inverted"
                onChange={(event, value) => {

                    if (platform == "ios" && event.type == "mousedown") {
                        return;
                    }
                    setTemp(value)
                }}
                onChangeCommitted={(event, value) => {

                    if (platform == "ios" && event.type == "mouseup") {
                        return;
                    }

                    setTemp(value)
                    B15MqttHelperOBJ.request('setMode', {mode: deviceState.mode, temp: value})
                }}
                disabled={ (disableDevice || deviceState.boost == 'on') ? true : false}
                valueLabelDisplay="off" 
                className="range-slider range-slider--temperature device-range-slider"
            />
        </div>
    )
}

export default ManualModeBody;