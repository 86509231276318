import React from 'react';
import {
    Link
} from 'react-router-dom';

import {
    IonHeader,
    IonSelect,
    IonSelectOption,
    IonToolbar,
    IonGrid,
    IonRow,
    IonCol
} from '@ionic/react';

import { ReactComponent as Logo } from '../../../../assets/images/webApp/logo.svg';
import { useTranslation } from 'react-i18next';
import {
    setAppLanguage
} from '../../../../redux/actions/AppAction';
import { connect } from 'react-redux'

import { languages } from '../../../../config/App';

const HeaderWelcome = props => {

    const { t, i18n } = useTranslation();

    const changeAppLang = lang => {
        
        i18n.changeLanguage(lang);
        props.setAppLanguage(lang);
    }

    return (
        <>
            <IonHeader className="app-header ion-no-border">
                <IonToolbar color="white">
                    <div slot="start">
                        <Link to="/">
                            <Logo />
                        </Link>
                    </div>
                    <div slot="end">
                        <IonGrid>
                            <IonRow className="ion-align-items-center">
                                <IonCol className="ion-padding-start">
                                    <IonSelect interface="popover" value={props.app.lang} onIonChange={e => changeAppLang(e.detail.value)} cancel-text={t('cancel')}>
                                        {languages.map(lang => {
                                            return <IonSelectOption value={lang} key={lang}>{t(`${lang}_s`)}</IonSelectOption>
                                        })}
                                    </IonSelect>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </div>
                </IonToolbar>
            </IonHeader>
        </>
    );
};

const mapStateToProps = state => {
    return {
        app: state.app
    }
}

export default connect(mapStateToProps, {
    setAppLanguage  
})(HeaderWelcome);