import mqtt from "mqtt/dist/mqtt";
import MainService from "./MainService";
import {settings, development_settings} from '../config/Mqtt-config';
import {
    appMqttVersion, app_mode
} from '../config/App';

class Mqtt extends MainService {

    constructor(app_id, token) {

        super();

        //global.debug('\n','\n',"-----Communication By MQTT-------",'\n'); 

        
    }

    connect = () => {
        global.debug("CONNECT TO MQTT SERVER");
        
        const mqttSettings = app_mode === 'development' ? development_settings : settings;

        this.mqttClient = mqtt.connect(`wss://${mqttSettings.host}:${mqttSettings.port}`, {
            clientId: 'mqttjs_' + Math.random().toString(16).substr(2, 8),
            username: mqttSettings.username,
            password: mqttSettings.password,
            reconnectPeriod: 1000, //This is the Default value (ms)
        });  
    }

    init() {

        this.connect();

        this.mqttClient.on('message', (topic, payload) => {

            const date = new Date();
            // global.debug('\n','\n','\n');
            // global.debug('-----------------NEW MESSAGE ARRIVED---------------------------');
            // global.debug('\n');
            // global.debug("recieved topics: "+topic, payload);
            // global.debug(JSON.parse(payload));
            // global.debug(`time of received topic: ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`);
            
            this.messageProcess(topic, payload)

        })  
        
        this.mqttClient.on('connect', function () {
            global.debug("App successfully connected to Cloud....");    
        })
    }

    subscribeForDevice(macAddress) {
        global.debug(`SUBSCRIBE FOR: ${macAddress}`);
        this.mqttClient.subscribe(`${appMqttVersion}/${macAddress}/response/#`);
        this.mqttClient.subscribe(`${appMqttVersion}/${macAddress}/getStatusResponse`);
        this.mqttClient.subscribe(`${appMqttVersion}/${macAddress}/timeRequest/#`);
        this.mqttClient.subscribe(`${appMqttVersion}/${macAddress}/pingRequest/#`);
    }

    unsubscribeForDevice(macAddress) {
        global.debug(`UNSUBSCRIBE FOR: ${macAddress}`);
        this.mqttClient.unsubscribe(`${appMqttVersion}/${macAddress}/response/#`);
        this.mqttClient.unsubscribe(`${appMqttVersion}/${macAddress}/getStatusResponse`);
        this.mqttClient.unsubscribe(`${appMqttVersion}/${macAddress}/timeRequest/#`);
        this.mqttClient.unsubscribe(`${appMqttVersion}/${macAddress}/pingRequest/#`);
    }

    publishMessage({model, token, macAddr, payload, requestType, command}) {

        //global.debug('\n','\n',`---------------Publish new message: --------------`, '\n');
        payload = {app_id: this.app_id, ...payload};

        const topic = `${appMqttVersion}/${macAddr}/${requestType}/${model}/${token}/${command}`;
        //global.debug(`For Topic: ${topic}`);
        this.mqttClient.publish(topic, JSON.stringify(payload));
    }
}

export default new Mqtt();