import {
    IonContent,
    IonPage,
    IonButton,
    IonModal
} from '@ionic/react';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux'
import { connect } from 'react-redux'

import OldDevicesSettingsHelper from '../../../helpers/OldDevicesSettingsHelper';
import BelislimoHelper from '../../../helpers/BelislimoHelper';
import BoilerApiV2Menu from './boilers/apiv2/BoilerApiV2Menu';

import Header from '../components/header/Header';
import PageHeader from '../components/header/PageHeader';
import Footer from '../components/footer/Footer';
import {oldModels} from '../../../config/App';
import ProgramHelper from './helpers/ProgramHelper';
import TimeZoneHelper from './helpers/TimeZoneHelper';


class CalculatorGraphic extends React.Component {

    state = {
        kwh: 0,
        resetDate: null,
        volume: null,
        newVolume: null,
        showVolumeModal: false,
        extrData: null
    }

    componentDidMount = () => {

        this.OldDevicesSettingsHelper = new OldDevicesSettingsHelper(this.mac);

        this.getCalculatorValues();
    }

    getCalculatorValues = async () => {

        await this.OldDevicesSettingsHelper.setDeviceStatus('pwc_u', 'get');

        const {DeviceStatus: {pwc_u, parNF, extr}} = this.device;

        const extrData = TimeZoneHelper.decodeExtr(extr);
        const watt = extrData.watt != undefined ? extrData.watt : 2400;

        let watt_txt = extrData.watt_txt;

        if (parNF != undefined) {
            
            const parNFData = ProgramHelper.splitProgramString(parNF);

            const utc = pwc_u.utc.split(";");
            const utc_0 = utc[0];
            const utc_1 = utc[1] ? utc[1] : 0;

            const Watt_Input_Body = ProgramHelper.convertToInt(parNFData[19]) * ProgramHelper.convertToInt('0x14');
            const Watt_Output_Body = ProgramHelper.convertToInt(parNFData[20]) * ProgramHelper.convertToInt('0x14');
            const kw = (utc_1 * Watt_Output_Body + utc_0 * Watt_Input_Body) / 3600000;

            this.setState({
                resetDate: pwc_u.date,
                kwh: kw.toFixed(3),
            })

            if (this.props.match.params.model_name == '2002-v2' && watt_txt == undefined && parNFData[0] != undefined) {

                const bHelper = new BelislimoHelper(this.device);
                const volumeValue = bHelper.getVolume();

                watt_txt = `${volumeValue}L`;
            }


        } else {
            
            this.setState({
                resetDate: pwc_u.date,
                kwh: ((pwc_u.utc * watt) / 3600000).toFixed(3),
            })
        }

        

        this.setState({
            volume: watt_txt != undefined ? watt_txt : '80L',
            newVolume: watt_txt != undefined ? watt_txt : '80L',
            extrData: extrData
        })       
    }

    reset = async () => {

        await this.OldDevicesSettingsHelper.setDeviceStatus('pwc_u', 'reset');

        setTimeout(() => {
            this.getCalculatorValues();
        }, 5000)
        
    }

    setVolume = () => {

        const extrData = this.state.extrData;
        extrData.watt_txt = this.state.newVolume;

        const extrEncoded = TimeZoneHelper.encodeExtr(extrData);
        this.OldDevicesSettingsHelper.setDeviceStatus('extr', extrEncoded);

        this.setState({
            showVolumeModal: false
        })

        setTimeout(() => {
            this.getCalculatorValues();
        }, 5000)
    }

    closeVolumeModal = () => {

        this.setState({
            showVolumeModal: false,
            newVolume: this.state.volume
        })
    }

    render = () => {

        this.mac = this.props.match.params.id;
        const { t } = this.props;
        this.device = this.props.devices[this.props.match.params.id];

        const { DeviceShortName } = this.device;

        return (
            <IonPage>
            
                <Header />

                <IonContent className="ion-no-padding">
                    <div className="dashboard">
                        <div className="dashboard__wrapper">

                            <BoilerApiV2Menu 
                                activeMenu="calculator" 
                                model_name={this.props.match.params.model_name} 
                                device={this.props.devices[this.props.match.params.id]} 
                                model={oldModels[this.props.match.params.model_name]}
                            />
                            
                            <div className="dashboard__content">
                               
                                <PageHeader name={t('calculator_button')} image="calc"/>

                                <div className="dashboard-box">

                                    <div className="dashboard-box__body">

                                        <div className={`range-circle`}>
                                            <div className="range-circle__inner">
                                                <div className="range-circle__metric">{t('kWh')}</div>
                                                <div className="range-circle__value">{this.state.kwh}</div>
                                                {this.state.resetDate != 'NONE' && this.state.resetDate != null &&
                                                    <div className="range-circle__label">{t('since')} {this.state.resetDate}</div>
                                                }
                                                
                                                {this.props.match.params.model_name != '2002-v2' ?
                                                    <div className="range-circle__option">
                                                        <button type="button" className="range-circle__option-button" onClick={() => this.setState({showVolumeModal: true})}>
                                                            {this.state.volume}
                                                            <svg className="range-circle__option-button-icon"><use xlinkHref="#icon-arrow-down"></use></svg>
                                                        </button>
                                                    </div>
                                                    :
                                                    <div className="range-circle__value range-circle__value--alt">{this.state.volume}</div>
                                                }
                                            </div>
                                        </div>
                                        
                                        <div className="range-buttons js-range-buttons">
                                            <div className="range-buttons__item">
                                                <IonButton shape="round" size="default" onClick={this.reset}>{t('reset')}</IonButton>
                                            </div> 
                                            <div className="range-buttons__item">
                                                <IonButton shape="round" size="default" fill="outline" color="medium" onClick={()=>this.props.history.push(`/old/apiv2/calculator-statistics/${this.props.match.params.model_name}/${this.props.match.params.id}`)}>{t('history')}</IonButton>
                                            </div>
                                        </div>

                                        <div className="ion-content-push"></div>

                                        <IonModal
                                            isOpen={this.state.showVolumeModal}
                                            onDidDismiss={this.closeVolumeModal}
                                        >
                                            <IonContent className="ion-padding">
                                                <div className="ion-margin-top">
                                                    <div className="modal-title">
                                                        {t('choose_volume')}
                                                    </div>

                                                    <div className="range-options">
                                                        <div className="range-options__item">
                                                            <button 
                                                                type="button" 
                                                                className={`range-options__button ${this.state.newVolume == '50L' ? 'active': ''}`} 
                                                                onClick={()=>this.setState({newVolume: '50L'})}
                                                            >
                                                                50 L
                                                            </button>
                                                        </div>
                                                        <div className="range-options__item">
                                                            <button 
                                                                type="button" 
                                                                className={`range-options__button ${this.state.newVolume == '80L' ? 'active': ''}`}
                                                                onClick={()=>this.setState({newVolume: '80L'})}
                                                            >
                                                                80 L
                                                            </button>
                                                        </div>
                                                        <div className="range-options__item">
                                                            <button 
                                                                type="button" 
                                                                className={`range-options__button ${this.state.newVolume == '100L' ? 'active': ''}`}
                                                                onClick={()=>this.setState({newVolume: '100L'})}
                                                            >
                                                                100 L
                                                            </button>
                                                        </div>
                                                        <div className="range-options__item">
                                                            <button 
                                                                type="button" 
                                                                className={`range-options__button ${this.state.newVolume == '120L' ? 'active': ''}`}
                                                                onClick={()=>this.setState({newVolume: '120L'})}
                                                            >
                                                                120 L
                                                            </button>
                                                        </div>
                                                        <div className="range-options__item">
                                                            <button 
                                                                type="button" 
                                                                className={`range-options__button ${this.state.newVolume == '150L' ? 'active': ''}`}
                                                                onClick={()=>this.setState({newVolume: '150L'})}
                                                            >
                                                                150 L
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="buttons-list">
                                                        <div className="buttons-list__item">
                                                            <IonButton shape="round" size="default" expand="block" onClick={this.setVolume}>{t('save')}</IonButton>
                                                        </div>
                                                        <div className="buttons-list__item">
                                                            <IonButton shape="round" size="default" expand="block" color="help" onClick={this.closeVolumeModal}>{t('cancel')}</IonButton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </IonContent>
                                        </IonModal>

                                        <div className="ion-content-push"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </IonContent>
                <Footer />
            </IonPage>
        );
    }
}

const mapStateToProps = state => {
    return {
        devices: state.oldDevices
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(CalculatorGraphic);