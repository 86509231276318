import {
    IonButton,
    useIonAlert,
    IonLabel,
    IonToggle
} from '@ionic/react';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { Link } from 'react-router-dom';
import { compose } from 'redux'
import { connect } from 'react-redux'

import RestHelper from '../../../../helpers/RestHelper';
import DeviceSettingsHelper from '../../../../helpers/DeviceSettingsHelper';
import InitialSettings from '../../../../init';

const DeviceCN05UVMenu = props => {

    const {devices} = props;
    const device = devices[props.mac];
    const { state: d_state, hasInternet, localUsage } = device;
    const DeviceSettings = new DeviceSettingsHelper(props.mac);
    
    const [present] = useIonAlert();


    const menuList = [
        {
            'title': props.t('dashboard'),
            'icon': 'dashboard',
            'active': props.activeMenu === 'dashboard' ? true : false,
            'link': '/dashboard'
        },
        {
            'title': props.t('control_panel'),
            'icon': 'dashboard',
            'active': props.activeMenu === 'controlPanel' ? true : false,
            'link': `/device/${device.model}/${props.mac}`
        },
        {
            'title': props.t('statistics'),
            'icon': 'statistic',
            'active': props.activeMenu === 'statistic' ? true : false,
            'link': `/statistics/${props.mac}`
        },
        {
            'title': props.t('add_to_group'),
            'icon': 'add-to-folder',
            'active': props.activeMenu === 'deviceGroup' ? true : false,
            'link': `/add-to-group/${props.mac}`
        },
        {
            'title': props.t('device_settings'),
            'icon': 'settings',
            'active': props.activeMenu === 'deviceSettings' ? true : false,
            'link': `/device-settings/${device.model}/${props.mac}`
        },
        {
            'title': props.t('device_info'),
            'icon': 'info',
            'active': props.activeMenu === 'deviceInfo' ? true : false,
            'link': `/device-info/${props.mac}`
        }
    ];

    const deleteDevice = () => {

        const rest = new RestHelper();
        const initial = new InitialSettings();
        
        rest.post('/delete-user-device', {
            mac: props.mac
        })
        .then (res => {

            global.debug("Deleted");

            DeviceSettings.handleReset();
            initial.init();
            props.history.push('/dashboard')    
        })
        .catch(error => {
            global.debug("Cannot delete device from server", error);
        })
    }


    
    if (props.mac !== undefined && (!device || device === undefined)) {
        return null;
    }
 
    return (
        <aside className="dashboard__aside">
            <div className="dashboard__aside-inner">
                <IonButton color="back-secondary" onClick={() => props.history.goBack()}>
                    <svg className="icon"><use xlinkHref="#icon-arrow-back"></use></svg>
                    <span className="button__text">{props.t('back')}</span>
                </IonButton>

                {props.mac !== undefined &&
                    <div className="device-preview">
                        <div className="device-preview__preview">
                            <img src={device.model_image_main} className="device-preview__preview-image" alt="Device name" />
                        </div>
                        <div className="device-preview__text">
                            {device.deviceName}
                            <IonButton color="icon-only-transparent ion-margin-end" onClick={() =>
                                present({
                                    header: props.t('confirm_device_delete'),
                                    buttons: [
                                        { text: props.t('no'), handler: (d) => global.debug('no pressed') },
                                        { text: props.t('yes'), handler: (d) => deleteDevice() },
                                    ],
                                    //onDidDismiss: (e) => global.debug('did dismiss'),
                                })
                                }>
                                <svg className="icon icon-18"><use xlinkHref="#icon-delete"></use></svg>
                            </IonButton>
                            <IonButton color="icon-only-transparent" routerLink={`/name-device/${props.mac}`}>
                                <svg className="icon icon-18"><use xlinkHref="#icon-pencil"></use></svg>
                            </IonButton>
                        </div>
                    </div>
                }
                    
                <div className="aside-nav">
                    {menuList.map(({ active, title, icon, link }, i) => (
                        <div className="aside-nav__item" key={i}>
                            <Link to={link} className={`aside-nav__button ${active ? 'aside-nav__button--active' : ''}`}>
                                <svg className="aside-nav__button-icon"><use xlinkHref={`#icon-${icon}`}></use></svg>
                                {title}
                            </Link>
                        </div>
                    ))}
                    <div className="aside-nav__item">
                        <div className="aside-nav__item-inner">
                            <IonLabel>
                                <svg><use xlinkHref="#icon-open-window"></use></svg>
                                {props.t('open_window')}
                            </IonLabel>
                            <IonToggle 
                                checked={d_state.openedWindow === 'on' ? true : false} 
                                onClick={e => DeviceSettings.handleOpenedWindow(d_state.openedWindow  === 'on' ? 'off' : 'on')} 
                                disabled= {(d_state.status === 'off' || (hasInternet === false && localUsage === false)) ? true : false}
                            />
                        </div>
                    </div>
                    <div className="aside-nav__item">
                        <div className="aside-nav__item-inner">
                            <IonLabel>
                                <svg><use xlinkHref="#icon-snowflake"></use></svg>
                                {props.t('anti_frost')}
                            </IonLabel>
                            <IonToggle 
                                checked={d_state.antiFrost === 'on' ? true : false} 
                                onClick={e => DeviceSettings.handleAntiFrost(d_state.antiFrost  === 'on' ? 'off' : 'on')} 
                                disabled= {(hasInternet === false && localUsage === false) ? true : false}
                            />
                        </div>
                    </div>
                    <div className="aside-nav__item">
                        <div className="aside-nav__item-inner">
                            <IonLabel>
                                <svg><use xlinkHref="#icon-air-safe"></use></svg>
                                {props.t('uv')}
                            </IonLabel>
                            <IonToggle 
                                checked={d_state.uv === 'on' ? true : false} 
                                onClick={e => DeviceSettings.handleUV(d_state.uv  === 'on' ? 'off' : 'on')} 
                                disabled= {(d_state.status === 'off' || (hasInternet === false && localUsage === false)) ? true : false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    )
}

const mapStateToProps = state => {
    return {
        devices: state.devices
    }
} 

export default compose(
    withTranslation(),
    withRouter,
    connect(mapStateToProps)
)(DeviceCN05UVMenu);