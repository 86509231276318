import {
    IonToolbar,
    IonButton,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { withRouter} from 'react-router-dom';

import HeatPumpHelper from "../../../../helpers/HeatPumpHelper";

import ChangeDeviceMode from './ChangeDeviceMode';

const VacationModeFooter = ({disableDevice, device, mac, history, changeMainMode}) => {

    const [mode, setMode] = useState(null);
    const HeatPumpHelperOBJ = new HeatPumpHelper(device);

    const { t } = useTranslation();

    const setVacationMode = mode => {
        if (mode == "va") {
            setMode("hp");
        } else {
            history.push(`/device/vacation/${device.model.toLowerCase()}/${mac}`)
        }  
    }

    return (
        <IonToolbar disabled={disableDevice ? true : false}>
            <div className="range-buttons">
                <div className="range-buttons__item">
                    <IonButton expand="block" shape="round" size="large" onClick={e => setVacationMode(HeatPumpHelperOBJ.getMode())}>
                        {HeatPumpHelperOBJ.getMode() == "va" ? t('deactivate') : t('activate')}
                    </IonButton>
                </div>
                <div className="range-buttons__item">
                    <IonButton expand="block" shape="round" size="large" color="help" routerLink={`/device/vacation/${device.model.toLowerCase()}/${mac}`}>{t('edit')}</IonButton>
                </div>
            </div>

            <ChangeDeviceMode 
                device={device} 
                newMode={mode} 
                mac={mac}
                cancelChange={mode => setMode(mode)}
                changeMainMode={mode => changeMainMode(mode)}
            />

        </IonToolbar>
    )
}

export default withRouter(VacationModeFooter);