import axios from 'axios';
import { app_mode, dev_rest_api_address, rest_api_address } from '../config/App';

class ProvisioningHelper
{

    check = async (mac, {history}) => {
        const {data: {message}} = await axios.get(`${app_mode === 'development' ? dev_rest_api_address : rest_api_address}/check-provisioned-device/${mac}?skipIpCheck=true`, {
            headers: {
                Authorization: "Bearer eyJpdiI6InE1L2pnWnhnV1pPcWhmS0RrK210T1E9PSIsInZhbHVlIjoiRGowVU01Rm5rQUk0aTBKZ0xCemxtSVltWlZqQkZyVEx2OEJGYzVsVEJBaz0iLCJtYWMiOiI2YTY5MjYwMGVjNDFjNGVlOWZmZGMwMmI3NGZhN2NkZGQ0ZWQwOWRiZDA2NWI5YWJmYjIxOGNmMDcxOWJiYWYwIiwidGFnIjoiIn0="
            }
        })
        //console.log("PROVISIONING", message);
        if (message == "ERROR") {
            history.push('/provisioning-error');
            return false;
        }

        return true;
    }
}

export default new ProvisioningHelper;